import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonKeyToLabelPipe',
})
export class JsonKeyToLabelPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.replace('_', ' ');
  }
}
