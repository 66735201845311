import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { ChartTooltipComponent, StackedAreaChartComponent } from './components';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { ChartTooltipDataListPipe } from './pipes';

@NgModule({
  imports: [EyesSharedModule.forFeature()],
  declarations: [
    /* Components */
    ChartTooltipComponent,
    StackedAreaChartComponent,
    /* Pipes */
    ChartTooltipDataListPipe,
    StackedBarChartComponent,
    PieChartComponent,
  ],
  exports: [
    /* Components */
    ChartTooltipComponent,
    StackedAreaChartComponent,
    StackedBarChartComponent,
    PieChartComponent,
    /* Pipes */
    ChartTooltipDataListPipe,
  ],
  providers: [ChartTooltipDataListPipe],
})
export class ChartsModule {}
