import { TDBCommoditiesFormSelect } from 'prosumer-app/+scenario/models';
import { TDBDataQuery, TDBDataStore } from 'prosumer-app/stores';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TDBGranularity } from '../../../shared/modules/tdb/models';

const DEFAULT_ENABLE_TDB_MODULE = false;

@UntilDestroy()
@Component({
  selector: 'prosumer-tdb-commodity-prices',
  templateUrl: './tdb-commodity-prices.component.html',
  styleUrls: ['./tdb-commodity-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdbCommodityPricesComponent implements OnInit, OnDestroy {
  @Output() filtersChanged = new EventEmitter();
  @Input() yearsList: number[];
  @Input() profileFilters: string = undefined;
  @Input() isTDBModuleOpen = DEFAULT_ENABLE_TDB_MODULE;
  @Input() initValueCommodityOption: string;

  readonly tdbCommodityPricesForm = this._initForm();
  get additionalCostsCtrl() {
    return this.tdbCommodityPricesForm.get('additionalCosts');
  }

  tdbCommodity$ = new BehaviorSubject<string | undefined>(undefined);
  isTdbModuleEnabled$ = new BehaviorSubject<boolean>(this.isTDBModuleOpen);
  isTdbFiltersDisabled$: Observable<boolean>;
  isTdbApiResponseLoading$: Observable<boolean>;
  tdbCommodityOptions$: Observable<TDBCommoditiesFormSelect[]>;
  tdbGeographyOptions$: Observable<TDBCommoditiesFormSelect[]>;
  tdbNominalScenarioOptions$: Observable<TDBCommoditiesFormSelect[]>;
  tdbUnitOptions$: Observable<TDBCommoditiesFormSelect[]>;
  tdbGranularityOptions$: Observable<TDBCommoditiesFormSelect[]>;
  isTdbAdditionalCostFiltersDisabled$: Observable<boolean>;
  isFormInvalid = signal(this.tdbCommodityPricesForm.invalid);

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly tdbDataStore: TDBDataStore,
    private readonly tdbDataQuery: TDBDataQuery,
  ) {}

  ngOnDestroy(): void {
    this.tdbDataStore.update(this.tdbDataStore.initStoreValues());
  }

  ngOnInit() {
    this._initObservables();
    this._initSubs();
  }

  toggleTDBModule(value: boolean) {
    this.isTdbModuleEnabled$.next(value);
  }

  onFetchData() {
    const formValue = this.tdbCommodityPricesForm.getRawValue();
    const criteria = this.buildParams(formValue);
    this.filtersChanged.emit(criteria);
    this.tdbDataStore.updateActiveFilters(criteria);
  }

  private _initForm() {
    return this._formBuilder.group({
      commodity: [undefined, Validators.required],
      geography: [undefined, Validators.required],
      scenario: [undefined, Validators.required],
      unit: [undefined, Validators.required],
      granularity: [undefined, Validators.required],
      additionalCosts: [
        { value: undefined, disabled: true },
        Validators.required,
      ],
    });
  }

  private _initObservables() {
    this.isTdbApiResponseLoading$ = this.tdbDataQuery
      .selectLoading()
      .pipe(untilDestroyed(this));
    this.isTdbFiltersDisabled$ = this.isTdbFiltersDisabled();
    this.tdbCommodityOptions$ = this.tdbDataQuery.selectTypes().pipe(
      untilDestroyed(this),
      map((types) => this._mapToFormSelect(types)),
    );
    this.tdbGeographyOptions$ = this.getFilters('geography');
    this.tdbNominalScenarioOptions$ = this.getFilters('scenario');
    this.tdbUnitOptions$ = this.getFilters('unit');
    this.tdbGranularityOptions$ = this.getFilters('granularity');
    combineLatest([
      this.isTdbApiResponseLoading$,
      this.granularityCtlValueIsNot(TDBGranularity.yearly),
    ])
      .pipe(map((all) => all.some((e) => e)))
      .subscribe((toDisable) => this.toggleAdditionalCostsCtrl(toDisable));
  }

  private _initSubs() {
    this._subToToggleChange();
    this._subToCommodityCtrl();
    this._subToFormChange();
  }

  private _subToFormChange() {
    this.tdbCommodityPricesForm.valueChanges.subscribe((_) => {
      this.isFormInvalid.set(this.tdbCommodityPricesForm.invalid);
    });
  }

  private _subToCommodityCtrl() {
    this.tdbCommodityPricesForm
      .get('commodity')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap(() =>
          this._clearCtrls([
            'geography',
            'scenario',
            'unit',
            'granularity',
            'additionalCosts',
          ]),
        ),
        filterNilValue(),
        map((value: string) => ({ energyVector: value })),
        mergeMap((value) => this.tdbDataStore.getTDBFilters(value)),
      )
      .subscribe();
  }

  private _subToToggleChange() {
    this.isTdbModuleEnabled$
      .asObservable()
      .pipe(
        untilDestroyed(this),
        filter((val) => val),
        take(1),
        // takeUntil(this.tdbCommodityOptions$),
        mergeMap(() => this.tdbDataStore.getTDBEnergyVectors()),
      )
      .subscribe();
  }

  private toggleAdditionalCostsCtrl(v: boolean) {
    if (v) {
      this.additionalCostsCtrl.disable();
    } else {
      this.additionalCostsCtrl.enable();
    }
  }

  private getFilters(key: string): Observable<TDBCommoditiesFormSelect[]> {
    return this.tdbDataQuery.selectFilters().pipe(
      untilDestroyed(this),
      map((filters) => filters[key] as string[]),
      map((filters) => this._mapToFormSelect(filters)),
    );
  }

  private _clearCtrls(controlNames: string[]) {
    for (const controlName of controlNames) {
      if (this.tdbCommodityPricesForm.get(controlName).getRawValue())
        this.tdbCommodityPricesForm.get(controlName).reset(undefined);
    }
  }

  private _mapToFormSelect(data: string[]): TDBCommoditiesFormSelect[] {
    return data.map((option) => ({
      name: option,
      value: option,
    }));
  }

  private buildParams(formValues): Record<string, string | number> {
    const [startYear, endYear] = this.yearsList;
    return {
      endYear,
      startYear,
      energyVector: formValues['commodity'],
      geography: formValues['geography'],
      scenario: formValues['scenario'],
      unit: formValues['unit'],
      granularity: formValues['granularity'],
      additionalCosts: formValues['additionalCosts'],
    };
  }

  private isTdbFiltersDisabled(): Observable<boolean> {
    const ctrl = this.tdbCommodityPricesForm.get('commodity');
    return combineLatest([
      this.isTdbApiResponseLoading$,
      ctrl.statusChanges.pipe(startWith(ctrl.status)),
    ]).pipe(
      delay(0),
      distinctUntilChanged(),
      map(([loading, status]) =>
        [loading, this.isCommodityInvalid(status, ctrl.status)].some(Boolean),
      ),
    );
  }

  private isCommodityInvalid(changes: string, ctrlStatus: string): boolean {
    return [this.isNotValid(changes), this.isNotValid(ctrlStatus)].every(
      Boolean,
    );
  }

  private isNotValid(status: string): boolean {
    return status.toUpperCase() !== 'VALID';
  }

  private granularityCtlValueIsNot(
    granularity: TDBGranularity,
  ): Observable<boolean> {
    return this.tdbCommodityPricesForm.get('granularity')!.valueChanges.pipe(
      startWith(null),
      map((val) => val !== granularity),
    );
  }
}
