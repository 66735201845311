import { Directive } from '@angular/core';
import { BaseFieldComponent } from '../base-field';

@Directive()
export abstract class ActionInputComponent extends BaseFieldComponent {
  /**
   * This action will be performed when the icon or button is clicked.
   */
  abstract performAction();
}
