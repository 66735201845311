import { Pipe, PipeTransform } from '@angular/core';
import { LowerCasePipe } from '@angular/common';

import { PREDEFINED_ENERGY_VECTOR_MAP } from 'prosumer-app/app.references';

@Pipe({
  name: 'energyVectorName',
})
export class EnergyVectorNamePipe implements PipeTransform {
  constructor(private lowerCasePipe: LowerCasePipe) {}

  transform(value: string): any {
    if (!!!value) {
      return value;
    }
    return PREDEFINED_ENERGY_VECTOR_MAP[value]
      ? this.lowerCasePipe.transform(PREDEFINED_ENERGY_VECTOR_MAP[value])
      : this.lowerCasePipe.transform(value);
  }
}
