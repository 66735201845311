import moment from 'moment';

export const sortByName = (compare1: any, compare2: any) => {
  if (compare1 && compare2) {
    if (compare1.name < compare2.name) {
      return -1;
    }
    if (compare1.name > compare2.name) {
      return 1;
    }
  }
  return 0;
};

export const sortByDate = (field: string, compare1: any, compare2: any) => {
  if (moment(compare1[field]).isBefore(moment(compare2[field]))) {
    return 1;
  }
  if (moment(compare1[field]).isAfter(moment(compare2[field]))) {
    return -1;
  }
  return 0;
};

export const sortByField = (
  field: string,
  compare1: any,
  compare2: any,
  descending?: boolean,
) => {
  if (compare1 && compare2 && compare1[field] && compare2[field]) {
    if (compare1[field] > compare2[field]) {
      if (descending) {
        return -1;
      }
      return 1;
    }
    if (compare1[field] < compare2[field]) {
      if (descending) {
        return 1;
      }
      return -1;
    }
  }
  return 0;
};
