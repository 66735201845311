import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-table-cell-editable',
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './table-cell-editable.component.html',
  styleUrl: './table-cell-editable.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellEditableComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<string>();
  @Input() disabled: boolean = false;
  @Input() validators: Array<ValidatorFn> = [];
  @Input() asyncValidators: Array<AsyncValidatorFn> = [];
  @Input() set value(v: string | null) {
    if (v) this.initValue(v);
  }

  readonly savedValue = signal<string>('');
  readonly showEdit = signal<boolean>(false);
  readonly ctrl = new FormControl<string>('');

  constructor() {
    this.initiateCtrlToShowError();
  }

  ngOnInit(): void {
    this.addCtrlValidators(this.validators);
    this.addCtrlAsyncValidators(this.asyncValidators);
  }

  toggleEdit(show: boolean, resetCtrl = false) {
    this.showEdit.set(show);
    if (resetCtrl) this.ctrl.patchValue(this.savedValue());
  }

  // onKeydownEnter(event: Event): void {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }

  onSaveChange(): void {
    if (!this.ctrl.invalid) {
      this.valueChanged.emit(this.ctrl.getRawValue() as string);
      this.savedValue.set(this.ctrl.getRawValue() as string);
      this.toggleEdit(false);
    }
  }

  showError(errs: Record<string, boolean>) {
    const keys = Object.keys(errs);
    return keys[0];
  }

  private initValue(v: string) {
    this.ctrl.setValue(v);
    this.savedValue.set(v);
  }

  private initiateCtrlToShowError() {
    this.ctrl.markAsTouched();
    this.ctrl.markAsDirty();
  }

  private addCtrlValidators(validators: ValidatorFn[]) {
    this.ctrl.addValidators(validators);
    this.ctrl.updateValueAndValidity();
  }

  private addCtrlAsyncValidators(asyncValidators: AsyncValidatorFn[]) {
    this.ctrl.addAsyncValidators(asyncValidators);
    this.ctrl.updateValueAndValidity();
  }
}
