import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Case } from 'prosumer-app/+case';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'prosumer-owner-resolver',
  templateUrl: './owner-resolver.component.html',
  styleUrls: ['./owner-resolver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerResolverComponent {
  readonly ownerName$ = new BehaviorSubject<string>(undefined);
  readonly entityId$ = new BehaviorSubject<string>(undefined);

  @Input() set data(details: Case) {
    this.entityId$.next(details.id);
    if (details.createdBy || details.ownerName) {
      this.ownerName$.next(details.createdBy || details.ownerName);
      return;
    }
    this.getUser(details.owner);
  }

  users$ = this._userFacade.dataMap$.pipe(
    filter((users) => !!users),
    untilDestroyed(this),
  );

  constructor(private _userFacade: UserFacadeService) {}

  getUser(userId: string) {
    this.users$
      .pipe(
        filter((users) => !!users),
        map((users) => (users[userId] || {}).fullName),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        this.ownerName$.next(data);
      });
  }
}
