import { Injectable } from '@angular/core';

class ContextualKeys {
  pageId: string;
  messageId: string;

  constructor(pageId: string, messageId: string) {
    this.pageId = pageId;
    this.messageId = messageId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TooltipContextHolderService {
  contextualData: any = undefined;
  isLoading = true;

  setContextualData = (data: any) => {
    this.contextualData = data;
    this.isLoading = false;
  };

  getContextualKeys = (combinatorial: string) => {
    const keyCombination = combinatorial.split('.');

    // keys should only have a length of 2
    // If it is not 2, it means that it is a multiple sentence string
    // treat it as raw text
    if (keyCombination.length !== 2) {
      return new ContextualKeys('rawText', combinatorial);
    }

    const pageKey = keyCombination[0];
    const messageKey = keyCombination[1];

    // If the message key is empty, then it is a single sentence only
    // Treat it as raw text
    if (messageKey === undefined || messageKey === null || messageKey === '') {
      return new ContextualKeys('rawText', pageKey);
    }

    return new ContextualKeys(pageKey, messageKey);
  };

  getMessage = (contextualKeys: ContextualKeys) => {
    if (!this.isLoading) {
      if (this.contextualData !== null && this.contextualData !== undefined) {
        try {
          // Return a raw text if it is a raw text.
          if (contextualKeys.pageId === 'rawText') {
            return contextualKeys.messageId;
          }

          const message =
            this.contextualData[contextualKeys.pageId][
              contextualKeys.messageId
            ];
          if (message === '' || message === undefined || message === null) {
            return `Contextual help for this component is not yet available.`;
          }
          return message;
        } catch {
          return `${contextualKeys.pageId}.${contextualKeys.messageId}`;
        }
      }
      return `No global contextual help data is loaded.`;
    }
    return `Contextual help is still loading... Please come back again later.`;
  };
}
