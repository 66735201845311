import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const NEGATIVE_SIGN = 45;
export const DECIMAL_POINT = 46;
export const EXPONENTIAL_CONSTANT = 69;
export const EXPONENTIAL_CONSTANT_UPPERCASE = 101;
export const ALLOWED_CHARACTERS = [
  NEGATIVE_SIGN,
  DECIMAL_POINT,
  EXPONENTIAL_CONSTANT,
  EXPONENTIAL_CONSTANT_UPPERCASE,
]; // -, ., e, E

export const ALLOWABLE_KEYS = {
  negativeSign: NEGATIVE_SIGN,
  expConst: EXPONENTIAL_CONSTANT,
  expConstUpper: EXPONENTIAL_CONSTANT_UPPERCASE,
  decimalPoint: DECIMAL_POINT,
};

/**
 * Deprecated
 */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      ((control.touched && control.dirty) || isSubmitted)
    );
  }
}

/**
 * This helps validating the key inputs for number fields
 *
 * @param event - a keyboard event
 * @param allowedCharacters - list of charactes allowed the developer can define
 */
export const isKeyPressedNumber = (
  event: KeyboardEvent,
  allowedCharacters = ALLOWED_CHARACTERS,
): boolean => {
  const charCode = event.which ? event.which : event.keyCode;
  if (allowedCharacters.indexOf(charCode) !== -1) {
    return true;
  }
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};
