import {
  MainResult,
  Result,
  EquipmentResultItem,
  EnergyBalanceResultItem,
  TopologyResultItem,
  PaybackResult,
  DispatchResultItem,
  CashFlowResultItem,
  CashFlowResultItemValue,
} from './result.model';

export interface CompareMainResult extends MainResult, Result {
  readonly totalDistributedCostPercentDiff?: number;
  readonly capexFirstYearPercentDiff?: number;
  readonly opexFirstYearPercentDiff?: number;
  readonly co2EmmissionPercentDiff?: number;
}

export interface CompareEquipmentResultItem
  extends EquipmentResultItem,
    Result {
  readonly value?: number;
}

export interface CompareCashFlowResultItemValue
  extends CashFlowResultItemValue,
    Result {}

export interface CompareCashFlowResultItem
  extends CashFlowResultItem<CompareCashFlowResultItemValue> {
  readonly data: Array<CompareCashFlowResultItemValue>;
}

export interface CompareEnergyBalanceResultItem
  extends EnergyBalanceResultItem,
    Result {}

export interface CompareTopologyResultItem extends TopologyResultItem, Result {}

export interface CompareDispatchResultItem extends DispatchResultItem, Result {}

export interface ComparePaybackResult extends PaybackResult, Result {}

export enum ComparisonTabs {
  main = 0,
  equipment,
  topology,
  cashflow,
  co2Emissions,
  energyBalance,
  dispatch,
}

export enum ComparisonTabsName {
  main = 'main',
  equipment = 'equipment',
  topology = 'topology',
  cashflow = 'cash_flow',
  co2Emissions = 'co2_emissions',
  energyBalance = 'energy_balance',
  dispatch = 'dispatch',
}
