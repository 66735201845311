import { NgxStarsModule } from 'ngx-stars';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StarRatingInputComponent } from './star-rating-input.component';

@NgModule({
  declarations: [StarRatingInputComponent],
  imports: [CommonModule, NgxStarsModule],
  exports: [StarRatingInputComponent],
})
export class StarRatingInputModule {}
