import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { MarkdownModule } from 'ngx-markdown';
import {
  ScenarioListColumn,
  ScenarioListModule,
} from 'prosumer-app/+scenario/components/scenario-list';
import { ScenarioStatusDialogModule } from 'prosumer-app/+scenario/components/scenario-status-dialog/scenario-status-dialog.module';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from '../shared';
import { CaseRoutingModule } from './case-routing.module';
import { CaseFormComponent } from './components';
import {
  CaseCreatePageComponent,
  CaseDetailsPageComponent,
  CaseListPageComponent,
} from './containers';

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    SharedModule,
    CaseRoutingModule,
    MarkdownModule.forChild(),
    ScenarioStatusDialogModule,
    ScenarioListModule.forChild({
      columns: [
        ScenarioListColumn.name,
        ScenarioListColumn.runHistory,
        ScenarioListColumn.launchDate,
        ScenarioListColumn.duration,
        ScenarioListColumn.tco,
        ScenarioListColumn.co2FinalYear,
        ScenarioListColumn.actions,
      ],
    }),
    LetDirective,
    PushPipe,
  ],
  declarations: [
    CaseFormComponent,
    CaseDetailsPageComponent,
    CaseCreatePageComponent,
    CaseListPageComponent,
  ],
  exports: [
    CaseFormComponent,
    CaseDetailsPageComponent,
    CaseCreatePageComponent,
    CaseListPageComponent,
  ],
})
export class CaseModule {}
