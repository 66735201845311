import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormFieldErrorMessageMap, FormFieldOption } from '../components';
import { getKeys } from '../utilities';
import { I18NService } from 'prosumer-app/core/services/translate-i18n';

@Injectable()
export class FormService {
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _i18n: I18NService,
  ) {}

  initForm(config: any, extra?: any): UntypedFormGroup {
    return this._formBuilder.group(config, extra);
  }

  convertToFormFieldOptions(obj: {
    [key: string]: string;
  }): Array<FormFieldOption<any>> {
    return getKeys(obj).map((key) => ({ name: obj[key], value: key }));
  }

  getErrorMessageMap(path: string): FormFieldErrorMessageMap {
    const translation = this._i18n.translate(path);
    return translation || {};
  }
}
