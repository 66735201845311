import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { tap } from 'rxjs/operators';

import { FilterService } from '../../services/index';
import { BaseComponent } from '../base-component/index';

@Component({
  selector: 'prosumer-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [FilterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterInputComponent extends BaseComponent implements OnInit {
  @Output() filter = new EventEmitter<any>();
  @Input() placeholdermsg = 'Search';
  @Input() label = 'Search';
  @Input() flex = '50%';

  filter$ = this._filterService.filter$.pipe(
    tap((filter) => {
      this.filter.emit(filter);
    }),
    this.takeUntilShare(),
  );

  constructor(private _filterService: FilterService) {
    super();
  }

  ngOnInit() {
    this.filter$.subscribe();
  }

  onFilter(value: string) {
    this._filterService.applyFilter(value);
  }
}
