import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';

import { BaseComponent } from '../base-component';

@Component({
  selector: 'prosumer-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent extends BaseComponent {
  @Input() class: any;
  @Input() appearance: MatFormFieldAppearance;
  @Input() hideRequired: boolean;
  @Input() floatLabel: FloatLabelType;
  @Input() hintLabel: string;
}
