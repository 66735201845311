<form fxLayout="column" [formGroup]="projectForm" (ngSubmit)="onSubmit()">
  <div
    *ngIf="loading; else showProjectDetails"
    fxLayoutGap="10px"
    fxLayout="column"
    [@fadeIn]
  >
    <ngx-skeleton-loader class="shorter"></ngx-skeleton-loader>
    <div fxLayout="column">
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
    </div>
  </div>

  <ng-template #showProjectDetails>
    <div fxLayout="row" fxLayoutAlign="start center">
      <h2
        *ngIf="mode === 'read'; else showInputName"
        data-testid="project-form-project-name"
        class="eyes-remove-margin"
        [@fadeIn]
      >
        {{ projectForm?.controls?.name?.value }}
      </h2>
      <ng-template #showInputName>
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label>{{ 'Project.labels.name' | translate }}</mat-label>
          <input
            matInput
            required
            formControlName="name"
            [placeholder]="'Project.placeholders.name' | translate"
            [readonly]="mode === 'read'"
          />
          <mat-icon
            aria-hidden="false"
            class="eyes-input-tooltip-anchor"
            matSuffix
            tooltipPosition="left"
            [prosumerTooltip]="'projects.project_name'"
            >help</mat-icon
          >
          <mat-error
            *ngFor="let error of getErrors(projectForm?.controls.name)"
            [innerHTML]="'Project.messages.name.' + error | translate"
          ></mat-error>
        </mat-form-field>
      </ng-template>

      <a
        [@fadeIn]
        mat-icon-button
        (click)="onEdit()"
        *ngIf="mode === 'read' && canEdit"
        class="ml-2 text-lg w-7 h-7 px-px py-1"
        color="primary"
      >
        <mat-icon
          inline
          aria-hidden="false"
          data-testid="project-form-edit-name"
          >edit</mat-icon
        >
      </a>
    </div>

    <p
      *ngIf="mode === 'read'; else showInputDescription"
      class="eyes-remove-margin description"
      [@fadeIn]
    >
      {{ projectForm?.controls?.description?.value }}
    </p>
    <ng-template #showInputDescription>
      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
      >
        <mat-label>{{ 'Project.labels.description' | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="'Project.placeholders.description' | translate"
          formControlName="description"
          rows="8"
          [readonly]="mode === 'read'"
        ></textarea>
        <mat-icon
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          matSuffix
          tooltipPosition="left"
          [prosumerTooltip]="'projects.project_description'"
          >help</mat-icon
        >
      </mat-form-field>
    </ng-template>
  </ng-template>

  <ng-content *ngIf="mode !== 'update'"></ng-content>

  <div *ngIf="mode !== 'create'" class="mat-caption">
    <span fxFlex></span>
    <ngx-skeleton-loader
      class="shorter"
      *ngIf="!owner; else showOwner"
      [@fadeIn]
    ></ngx-skeleton-loader>
    <ng-template #showOwner>
      <span [@fadeIn]
        >Created by {{ owner }} on
        {{ projectForm?.controls?.createdDate?.value | dateFormat }}</span
      >
    </ng-template>
  </div>

  <div
    *ngIf="mode !== 'read'"
    class="eyes-spacer-top-half"
    fxLayoutGap="10px"
    fxLayout="row"
    [@fadeIn]
  >
    <button
      mat-flat-button
      color="primary"
      class="eyes-button"
      data-testid="prosumer-project-form-submit"
      [disabled]="projectForm?.pristine || loading || saving"
    >
      {{ mode === 'update' ? 'Update' : 'Create' }}
    </button>
    <button
      mat-flat-button
      (click)="onCancel()"
      [disabled]="loading || saving"
      data-testid="prosumer-project-form-cancel"
      class="eyes-button"
      type="button"
    >
      Cancel
    </button>
  </div>
</form>
