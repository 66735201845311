/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { User } from 'prosumer-app/+project';
import { UserPermissions } from 'prosumer-shared/models';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'prosumer-permission-picker',
  templateUrl: './permission-picker.component.html',
  styleUrls: ['./permission-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionPickerComponent implements OnInit {
  @Input() user: User;
  @Input() showRemove = true;
  @Output() unshare = new EventEmitter<User>();
  @Output() changePermission = new EventEmitter<{
    user: any;
    permissions: UserPermissions;
  }>();
  public CAN_VIEW = UserPermissions.CAN_VIEW;
  public CAN_EDIT = UserPermissions.CAN_EDIT;

  permission$ = new BehaviorSubject<string>(this.CAN_VIEW);

  ngOnInit(): void {
    this.permission$.next(this.user?.permissions);
  }

  getUserPermissionLabel(permission) {
    return permission === this.CAN_EDIT ? 'Can Edit' : 'Can View';
  }

  onChangePermission(permissions) {
    this.changePermission.emit(permissions);
    this.permission$.next(permissions);
  }

  onUnshare() {
    this.unshare.emit(this.user);
  }
}
