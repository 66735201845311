import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import { DetailsOverlayRef } from 'prosumer-shared/modules/details-overlay';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { Utils } from '../../../../core/utils';
import { SystemVisualizationService } from '../services';

// Add your cytoscape plugins here
export const DIAGRAM_PLUGINS = [klay];
export const BUBBLE_CLASS = 'bubble';
export const BUBBLE_FADE_CLASS = 'bubble-fade';

export const KLAY_LAYOUT_OPTIONS = {
  name: 'klay',
  nodeDimensionsIncludeLabels: false,
  fit: true,
  padding: 20,
  animate: false,

  animateFilter(node, i) {
    return true;
  },
  animationDuration: 500,
  animationEasing: undefined,

  transform(node, pos) {
    return pos;
  },
  ready: undefined,
  stop: undefined,
  klay: {
    addUnnecessaryBendpoints: true,
    aspectRatio: 1.6,
    borderSpacing: 20,
    compactComponents: false,
    crossingMinimization: 'LAYER_SWEEP',
    cycleBreaking: 'GREEDY',
    direction: 'RIGHT',
    edgeRouting: 'ORTHOGONAL',
    edgeSpacingFactor: 0.5,
    feedbackEdges: false,
    fixedAlignment: 'NONE',
    inLayerSpacingFactor: 1.0,
    layoutHierarchy: false,
    linearSegmentsDeflectionDampening: 0.3,
    mergeEdges: false,
    mergeHierarchyCrossingEdges: true,
    nodeLayering: 'NETWORK_SIMPLEX',
    nodePlacement: 'LINEAR_SEGMENTS',
    randomizationSeed: 1,
    routeSelfLoopInside: false,
    separateConnectedComponents: true,
    spacing: 30,
    thoroughness: 7,
  },

  priority(edge) {
    return null;
  },
};

export const DEFAULT_LAYOUT_OPTIONS = KLAY_LAYOUT_OPTIONS;

export const DEFAULT_DIAGRAM_OPTIONS = {
  userZoomingEnabled: true,
  minZoom: 0.2,
  maxZoom: 4,
  wheelSensitivity: 0.05,
};

export const DEFAULT_DIAGRAM_PADDING = 30;

export const DIAGRAM_EVENTS = {
  mouseOver: 'mouseover',
  mouseOut: 'mouseout',
};

export const CURSOR_TYPES = {
  pointer: 'pointer',
  move: 'move',
};

@Directive()
export abstract class BaseDiagramComponent<T>
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() data: T; // The data input

  private diagramOptions: cytoscape.CytoscapeOptions; // The diagram options
  private diagram: cytoscape.Core; // The diagram
  private diagramSubject = new BehaviorSubject<cytoscape.Core>(undefined);
  readonly diagram$: Observable<cytoscape.Core> = this.selectDiagram();

  detailsPanel: DetailsOverlayRef; // The details panel instance

  cleanUpSubs: Subscription;

  constructor(
    public elementRef: ElementRef,
    public systemVisualizationService: SystemVisualizationService,
  ) {
    this.applyPlugins();
    this.initDiagramOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !!changes.data.currentValue) {
      this.handleDataChange(changes.data.currentValue);
    }
  }

  ngAfterViewInit() {
    this.initCleanupHandler();
    this.conditionallyPreloadDiagramJSON();
  }

  loadPreviousDiagramJson(): Record<string, unknown> | undefined {
    return;
  }

  private conditionallyPreloadDiagramJSON(): void {
    if (this.loadPreviousDiagramJson()) {
      this.getDiagram().json(this.loadPreviousDiagramJson());
    }
  }

  private selectDiagram(): Observable<cytoscape.Core> {
    return this.diagramSubject.pipe(filter((diagram) => !!diagram));
  }

  ngOnDestroy() {
    if (this.cleanUpSubs) {
      this.cleanUpSubs.unsubscribe();
    }
    this.cleanUp();
  }

  /**
   * Cleans up the visuals
   */
  cleanUp() {
    this.closeDetailsPanel();
  }

  /**
   * Initialize cleanup handler
   */
  initCleanupHandler() {
    this.cleanUpSubs = this.systemVisualizationService.cleanUp$.subscribe(() =>
      this.cleanUp(),
    );
  }

  /**
   * Applies plugins to use
   */
  applyPlugins() {
    DIAGRAM_PLUGINS.forEach((plugin) => cytoscape.use(plugin));
  }

  /** Initialize the diagram options */
  initDiagramOptions() {
    this.diagramOptions = {
      ...DEFAULT_DIAGRAM_OPTIONS,
      container: this.elementRef.nativeElement,
    };
  }

  initStoredPanZoom() {
    const zoom = this.getSavedViewConfig().zoom;
    const pPres = this.getSavedViewConfig().pan;
    const pCurr = this.getDiagram().pan();
    if (pPres !== undefined) {
      this.diagram.panBy({ x: pPres.x - pCurr.x, y: pPres.y - pCurr.y });
    }
    if (zoom !== undefined) {
      this.diagram.zoom(zoom);
    }
  }

  /** Initialize the diagram */
  initDiagram() {
    this.diagram = cytoscape(this.diagramOptions || DEFAULT_DIAGRAM_OPTIONS);
  }

  /**
   * Handles the change in the data input
   *
   * @param data - the data input
   */
  handleDataChange(data: T) {
    this.updateDiagramOptions(data);
    this.initDiagram();
    this.initStoredPanZoom();
    this.addEventListeners();
    this.diagramSubject.next(this.getDiagram());
  }

  /**
   * Puts focus on element by creating a fade like effect on other elements
   * in the document
   *
   * @param IDs element IDs to focus on, can be multiple
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  focusOnElement(IDs: Array<string>) {
    IDs.forEach((id) => {
      const element = document.getElementById(id);
      if (!!element) {
        this.removeElementFade(element);
      }
    });
  }

  fadeAllInClass(className: string = BUBBLE_CLASS) {
    const allDiv = Array.from(
      document.getElementsByClassName(
        className,
      ) as HTMLCollectionOf<HTMLElement>,
    );
    allDiv.forEach((element) => this.fadeElement(element));
  }

  /**
   * Removes fade effect on a set of elements
   * with the specified classname
   * Defaults to bubble class
   *
   * @param className element class to remove fade effect
   */
  removeFocusOnClass(className: string = BUBBLE_FADE_CLASS) {
    const allDiv = Array.from(
      document.getElementsByClassName(
        className,
      ) as HTMLCollectionOf<HTMLElement>,
    );
    allDiv.forEach((element) => this.removeElementFade(element));
  }

  /**
   * Grays element out
   *
   * @param element element to fade
   */
  fadeElement(element: HTMLElement) {
    element.className = BUBBLE_FADE_CLASS;
  }

  /**
   * Removes fade effect on specific element
   *
   * @param element element to remove fade
   */
  removeElementFade(element: HTMLElement) {
    element.className = BUBBLE_CLASS;
  }

  /**
   * Get the bounding box af the node.
   *
   * @param node node.
   */
  _getBoundingBox(node) {
    return node.boundingbox({});
  }

  /**
   * Gets the diagram instance
   */
  getDiagram(): cytoscape.Core {
    return this.diagram;
  }

  /**
   * Gets the diagram options
   */
  getDiagramOptions(): cytoscape.CytoscapeOptions {
    return this.diagramOptions;
  }

  /**
   * Sets the diagram
   *
   * @param diagram = the diagram to set
   */
  setDiagram(diagram: cytoscape.Core) {
    this.diagram = diagram;
    this.diagramSubject.next(this.diagram);
  }

  /**
   * Sets the diagram options
   *
   * @param options - the diagram options to set
   */
  setDiagramOptions(options: cytoscape.CytoscapeOptions) {
    this.diagramOptions = options;
  }

  /**
   * Adds listeners for events like click
   * Should be overridden in child classes if needed
   */
  addEventListeners() {
    this.addPointerHandlers();
  }

  /**
   * Adds pointer handlers when hovering to elements
   */
  addPointerHandlers() {
    this.getDiagram().on(DIAGRAM_EVENTS.mouseOver, this.applyPointerCursor);
    this.getDiagram().on(DIAGRAM_EVENTS.mouseOut, this.applyMoveCursor);
  }

  /**
   * Applies pointer cursor style over the target element
   *
   * @param event - the mouseover event
   */
  applyPointerCursor = (event) => {
    const element: cytoscape.CollectionReturnValue = event.target;
    if (!this.isBackground(element) && !element.data().hidden) {
      this.elementRef.nativeElement.style.cursor = CURSOR_TYPES.pointer;
    }
  };

  /**
   * Applies move cursor style over the target element
   *
   * @param event - the mouseout event
   */
  applyMoveCursor = (event) => {
    const element: cytoscape.CollectionReturnValue = event.target;
    if (!this.isBackground(element) && !element.data().hidden) {
      this.elementRef.nativeElement.style.cursor = CURSOR_TYPES.move;
    }
  };

  /**
   * Updates the diagram options
   *
   * @param data - the data input
   */
  updateDiagramOptions(data: T) {
    const mappedOptions = this.mapDataToDiagramOptions(data);
    this.diagramOptions = {
      ...this.diagramOptions,
      ...mappedOptions,
      layout: mappedOptions.layout || KLAY_LAYOUT_OPTIONS,
    };
  }

  /**
   * Checks if the parameter element is the background
   *
   * @param element - the element to check
   */
  isBackground(element: any) {
    return !!element && !!this.getDiagram() && element === this.getDiagram();
  }

  /**
   * Close the details panel
   */
  closeDetailsPanel() {
    if (!!this.detailsPanel) {
      this.detailsPanel.close();
    }
  }

  getPositionsConfig(): cytoscape.NodePositionMap {
    const savedPosition = Utils.resolveToEmptyObject(
      this.getSavedViewConfig().elements,
    );
    const nodePositions = Utils.resolveToEmptyArray(
      savedPosition['nodes'],
    ) as cytoscape.NodeDefinition[];
    const edgePositions = Utils.resolveToEmptyArray(
      savedPosition['edges'],
    ) as cytoscape.EdgeDefinition[];

    const output = nodePositions.concat(edgePositions).reduce((acc, curr) => {
      acc[curr.data.id] = curr.position || {};
      return acc;
    }, {});
    return output;
  }

  getDiagramJSON(): Record<string, unknown> {
    return this.getDiagram().json() as Record<string, unknown>;
  }

  abstract getSavedViewConfig(): cytoscape.CytoscapeOptions;

  /**
   * Maps the data input to the diagram options
   *
   * @param data - the data input to be mapped to diagram options
   */
  abstract mapDataToDiagramOptions(data: T): cytoscape.CytoscapeOptions;
}
