import { Coerce } from 'prosumer-app/core/utils';
import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, transaction } from '@datorama/akita';

import {
  FilterConfigNewResponse,
  FilterTypeConfigNewResponse,
  TDBDataNewProfile,
  TDBDataNewState,
  TDBDataNewSummary,
} from './tdb-data-new.state';

@Injectable({ providedIn: null })
@StoreConfig({ name: 'transversaldb_library' })
export class TDBDataStoreNew extends EntityStore<TDBDataNewState> {
  private readonly cancelOngoingRequests = new Subject<unknown>();

  constructor(
    private endpoint: EndpointProvider,
    private http: HttpClient,
  ) {
    super({ loading: false });
  }

  updateActiveFilters(activeFilters: Record<string, string | number>): void {
    this.update({ activeFilters });
  }

  getTDBEnergyVectors(): Observable<unknown> {
    const action = 'getEnergyVectors';
    return this.requestFromTDB<FilterTypeConfigNewResponse>(action, {}).pipe(
      tap(({ types }) => this.update({ types })),
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBFilters(
    params: Record<string, string>,
    action = 'getPriceFilters',
  ): Observable<FilterConfigNewResponse | HttpErrorResponse> {
    return this.requestFromTDB<FilterConfigNewResponse>(action, params).pipe(
      tap(({ filters, filtersOfTypeSlider }) =>
        this.update({ filters, filtersOfTypeSlider }),
      ),
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBDataIntervals(
    energyVector: string,
    filterCriteria: Record<string, string | number>,
  ): Observable<TDBDataNewSummary | HttpErrorResponse> {
    const action = 'getPriceIntervals';
    const params = { ...filterCriteria, energyVector };
    return this.requestFromTDB<TDBDataNewSummary>(action, params).pipe(
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBData(
    filterCriteria: Record<string, string | number>,
    action = 'getTechnologyEquipment',
  ): Observable<TDBDataNewSummary[] | HttpErrorResponse> {
    this.setLoading(true);
    this.set([]);
    const params = { ...filterCriteria };
    const type = Coerce.toString(filterCriteria.type);
    return this.requestFromTDB<TDBDataNewProfile>(action, params).pipe(
      map((record) => record['data']),
      tap((data) => this.set(data)),
      catchError((err) => this.onError(action, err)),
      finalize(() => this.setLoading(false)),
    );
  }

  private requestFromTDB<T>(
    action: string,
    filterCriteria: Record<string, string | number>,
  ): Observable<T> {
    this.markStateAsStarting();
    const params = { action, ...filterCriteria };
    return this.http.get<T>(this.endpoint.forLinkWithTDB(), { params }).pipe(
      filter((resp) => !!resp),
      finalize(() => this.setLoading(false)),
    );
  }

  @transaction()
  private markStateAsStarting(): void {
    this.cancelOngoingRequests.next(true);
    this.update({ error: undefined, loading: true });
  }

  private onError(
    action: string,
    error: HttpErrorResponse,
  ): Observable<HttpErrorResponse> {
    this.setError({
      action,
      ...error,
    });
    return of(error);
  }

  initStoreValues(): TDBDataNewState {
    return {
      entities: {},
      loading: false,
      error: null,
      activeFilters: null,
      ids: [],
      filtersOfTypeSlider: [],
      filters: null,
      types: null,
    };
  }
}
