<prosumer-overview-diagram
  [data]="data$ | async"
  [fluidConfig]="fluidConfigData"
  [ngClass]="{ optimized: showOptimized$ | async }"
  (elementTapped)="elementTapped.emit($event)"
  [style.height.px]="systemVisualization.height$ | async"
>
</prosumer-overview-diagram>

<prosumer-diagram-fluids-legend
  (selectedChange)="onSelectedFluidsChange($event)"
  [fluids]="fluids$ | async"
>
</prosumer-diagram-fluids-legend>

<div class="optimize-button-container">
  <button
    mat-fab
    [matTooltip]="showOptimized$ | async | optimizeTextToggle"
    [ngClass]="{ optimized: showOptimized$ | async }"
    (click)="onToggleOptimizedSolution()"
    data-testid="overview-container-optimize-button"
  >
    <mat-icon aria-hidden="false">{{
      showOptimized$ | async | optimizeIconToggle
    }}</mat-icon>
  </button>
</div>
