<div
  *ngIf="loading; else loaded"
  class="scenario-list-skelly"
  data-testid="skelly"
  fxLayout="column"
>
  <div fxLayout="row" fxLayoutGap="16px">
    <ngx-skeleton-loader fxFlex="10"></ngx-skeleton-loader>
    <ngx-skeleton-loader fxFlex="15"></ngx-skeleton-loader>
  </div>
  <div fxLayout="row" fxLayoutGap="16px">
    <ngx-skeleton-loader fxFlex="5" count="3"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      *ngFor="let i of [0, 0, 0, 0, 0, 0]"
      fxFlex
      count="3"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader fxFlex="5" count="3"></ngx-skeleton-loader>
  </div>
</div>

<ng-template #loaded>
  <mtx-grid
    [noResultText]="'No scenarios found'"
    [showToolbar]="true"
    [toolbarTemplate]="toolbar"
    [showColumnMenuButton]="false"
    [rowSelectable]="isRowSelectable"
    [columns]="columns"
    [data]="mergedScenarioList()"
    [headerExtraTemplate]="header"
    [rowClassFormatter]="rowClassFormater"
    [rowSelectionFormatter]="rowSelectionFormater"
    (rowSelectedChange)="onSelectedChange($event)"
    sortActive="launchDate"
    sortDirection="desc"
  ></mtx-grid>
</ng-template>

<ng-template #toolbar>
  <prosumer-scenario-batch
    data-testid="selectedScenarios"
    (actionClick)="onBatchAction($event)"
    [selectedWithDownloadState]="selected$ | ngrxPush"
  ></prosumer-scenario-batch>
</ng-template>

<ng-template #name let-scenario>
  <prosumer-scenario-name
    class="pointer-all"
    [scenario]="scenario"
    [editable]="allowEditScenarioName"
    (scenarioNameClick)="onNameClick(scenario)"
    [infoStyle]="'content'"
    [allowScenarioNameClick]="isScenarioClickable(scenario)"
    [attr.data-testid]="'scenario-list-name-' + scenario.id"
  >
  </prosumer-scenario-name>
</ng-template>

<ng-template #status let-scenario>
  <prosumer-simulation-status
    class="pointer-all"
    [attr.data-testid]="'scenario-list-status-' + scenario.id"
    [status]="scenario.run?.status"
  ></prosumer-simulation-status>
</ng-template>

<ng-template #run let-scenario>
  <prosumer-scenario-status
    class="pointer-all"
    [attr.data-testid]="'scenario-list-run-' + scenario.id"
    [scenario]="scenario"
  ></prosumer-scenario-status>
</ng-template>

<ng-template #empty>
  <span>-</span>
</ng-template>

<ng-template #launchDate let-scenario>
  <span
    [attr.data-testid]="'scenario-list-launchDate-' + scenario.id"
    (click)="$event.stopPropagation()"
  >
    {{ (scenario.launchDate | date: 'dd MMM yyyy HH:mm') || '-' }}
  </span>
</ng-template>

<ng-template #createdDate let-scenario>
  <span [attr.data-testid]="'scenario-list-createdDate-' + scenario.id">
    {{ (scenario.createdDate | date: 'dd MMM yyyy HH:mm') || '-' }}
  </span>
</ng-template>

<ng-template #duration let-scenario>
  <span [attr.data-testid]="'scenario-list-duration-' + scenario.id">
    {{ (scenario.run?.duration | minsTimeConvert) || '-' }}
  </span>
</ng-template>

<ng-template #actions let-scenario>
  <prosumer-scenario-actions-trigger
    class="pointer-all"
    [attr.data-testid]="'scenario-list-actions-' + scenario.id"
    [hideDownloadFiles]="false"
    [scenario]="scenario"
    (delete)="onDelete($event)"
  ></prosumer-scenario-actions-trigger>
</ng-template>

<ng-template #case let-scenario>
  <span
    class="pointer-all"
    [class.clickable]="!hasScenarioError(scenario)"
    [attr.data-testid]="'scenario-list-case-' + scenario.id"
    (click)="onCaseClick(scenario)"
  >
    {{ scenario.caseName }}
  </span>
</ng-template>

<ng-template #project let-scenario>
  <span
    class="pointer-all"
    [class.clickable]="!hasScenarioError(scenario)"
    [attr.data-testid]="'scenario-list-project-' + scenario.id"
    (click)="onProjectClick(scenario)"
  >
    {{ scenario.projectName }}
  </span>
</ng-template>

<ng-template #tco let-scenario>
  <prosumer-value
    [value]="scenario.run?.outputValues?.globalTotalCost"
    [attr.data-testid]="'scenario-list-tco-' + scenario.id"
    prefix="kilo"
    unit="kEUR"
  ></prosumer-value>
</ng-template>

<ng-template #co2FinalYear let-scenario>
  <prosumer-value
    [value]="scenario.run?.outputValues?.globalFinalCO2Emissions"
    [attr.data-testid]="'scenario-list-co2-' + scenario.id"
    prefix="base"
    unit="kgCO2"
  ></prosumer-value>
</ng-template>

<ng-template #log let-scenario>
  <prosumer-scenario-logs
    mode="icon"
    class="pointer-all"
    [scenario]="scenario"
    [attr.data-testid]="'scenario-list-log-' + scenario.id"
  ></prosumer-scenario-logs>
</ng-template>

<ng-template #header let-col>
  <div>
    {{ col.header }}
    <prosumer-tooltip-anchor
      *ngIf="col.description as desc"
      [attr.data-testid]="'scenario-list-tip-' + col.field"
      [message]="desc"
    ></prosumer-tooltip-anchor>
  </div>
</ng-template>
