import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { SharedModule } from 'prosumer-shared';
import { ComputationOptionComponent } from './profile-computation-option.component';

@NgModule({
  declarations: [ComputationOptionComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    EyesSharedModule.forFeature(),
    SharedModule,
  ],
  providers: [
    { provide: 'historicalYears', useClass: ManagedDataService },
    { provide: 'percentiles', useClass: ManagedDataService },
  ],
  exports: [ComputationOptionComponent],
})
export class ComputationOptionModule {}
