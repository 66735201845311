import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MIME_TYPE, RESPONSE_TYPE } from '../auth.constants';
import { authUtil } from '../auth.util';

@Injectable()
export class FileUploadInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const { url, method } = request;

    let responseType: any = RESPONSE_TYPE.JSON;
    let contentType = request.headers.get('Content-Type') || MIME_TYPE.APPLICATION.JSON;
    let nextRequest: any = null;
    
    if (authUtil.isFileUpload(url)) {
      if (authUtil.isGetMethod(method)) {
        contentType = MIME_TYPE.APPLICATION.OCTET_STREAM;
        responseType = RESPONSE_TYPE.BLOB;
      } else {
        contentType = MIME_TYPE.MULTI_PART.FORM_DATA;
      }

      nextRequest = request.clone({
        responseType,
        headers: request.headers.set('Content-Type', contentType)
      })
    } else {
      nextRequest = request.clone({
        headers: request.headers.set('Content-Type', contentType)
      })
    } 

    return next.handle(nextRequest);
  }
}
