import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'ellipsesize' })
export class EllipsesizePipe implements PipeTransform {
  transform(
    value: unknown,
    maxLength: number | undefined = undefined,
  ): unknown {
    return this.transformString(String((value as string) ?? ''), maxLength);
  }

  maxLengthBoolean = (maxLength: number | undefined, value: string): boolean =>
    !!(maxLength && value.length > maxLength);

  // eslint-disable-next-line
  private transformString = (
    value: string,
    maxLength: number | undefined,
  ): string => {
    if (this.isADate(value)) {
      const valueString: string = value;
      return moment(valueString, this.getPreferredDateFormat()).format(
        this.getPreferredDateFormat(),
      );
    } else if (this.maxLengthBoolean(maxLength, value)) {
      return value.slice(0, maxLength) + '...';
    } else {
      return value;
    }
  };

  private isADate = (value: unknown): boolean => {
    return moment(
      String((value as string) || ''),
      this.getDateFormats(),
      true,
    ).isValid();
  };

  private getPreferredDateFormat(): string {
    return 'yyyy-MM-DD';
  }

  private getDateFormats(): string[] {
    return [
      'YYYY-MM-DDTHH:mm:ssZ',
      'MMMM DD, yyyy',
      'MMM DD, yyyy',
      'yyyy-MM-DD',
    ];
  }
}
