<div *ngIf="nodeOptions?.length > 1">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'Node' }}</mat-label>
    <mat-select
      #select
      [formControl]="selectedNodeControl"
      [hideSingleSelectionIndicator]="true"
      (selectionChange)="onSelectedNodeChange($event)"
    >
      <mat-option *ngFor="let option of nodeOptions" [value]="option?.value">{{
        option?.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div
  class="actions-container"
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="16px"
>
  <button
    mat-icon-button
    class="actions-button"
    matTooltip="Reset"
    data-testid="reset-view"
    (click)="onResetViz()"
  >
    <mat-icon aria-hidden="false" color="primary">undo</mat-icon>
  </button>
  <button
    mat-icon-button
    class="actions-button"
    matTooltip="Export as PNG"
    data-testid="export-view"
    (click)="onExportViz()"
  >
    <mat-icon aria-hidden="false" color="primary">save_alt</mat-icon>
  </button>
</div>

<prosumer-node-view-diagram
  [data]="data$ | async"
  [fluidConfig]="fluidConfigData"
  [ngClass]="{ optimized: showOptimized$ | async }"
  [style.height.px]="systemVisualization.height$ | async"
  [clickReset]="clickedReset.asObservable()"
  (pngDataChange)="onPngDataChange($event)"
  (positionReset)="onPositionReset()"
>
</prosumer-node-view-diagram>

<prosumer-diagram-fluids-legend
  (selectedChange)="onSelectedFluidsChange($event)"
  [fluids]="fluids$ | async"
>
</prosumer-diagram-fluids-legend>

<div
  class="optimize-button-container"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
>
  <button
    data-testid="optimize_button"
    mat-fab
    [matTooltip]="showOptimized$ | async | optimizeTextToggle"
    [ngClass]="{ optimized: showOptimized$ | async }"
    (click)="onToggleOptimizedSolution()"
  >
    <mat-icon aria-hidden="false">{{
      showOptimized$ | async | optimizeIconToggle
    }}</mat-icon>
  </button>
</div>
