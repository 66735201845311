import { NgModule } from '@angular/core';

import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-shared';

import {
  CoordinatesMapModule,
  LatestSolarProfilesModule,
  LatestWindProfilesModule,
  SolarTabModule,
  WindTabModule,
} from './components';
import { RenenewableProfileComponent } from './containers';
import { RenewableProfileRoutingModule } from './renewable-profile-routing.module';
import { GISCoordinatesService } from './services';

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    RenewableProfileRoutingModule,
    SharedModule,
    CoordinatesMapModule,
    SolarTabModule,
    WindTabModule,
    LatestSolarProfilesModule,
    LatestWindProfilesModule,
  ],
  declarations: [RenenewableProfileComponent],
  exports: [RenenewableProfileComponent],
  providers: [GISCoordinatesService],
})
export class RenewableProfileModule {}
