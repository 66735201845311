import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CONFIG } from 'prosumer-app/libs/eyes-core';
import { ApiConfig, generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Utils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
  APIError,
  ShareTokenRsp,
  ShareViaLinkRsp,
} from './project-sharer.models';

@Injectable({
  providedIn: 'root',
})
export class ProjectSharer {
  private readonly loading = new BehaviorSubject<boolean>(false);
  private readonly shareUrls = new BehaviorSubject<Record<string, string>>({});
  private readonly shareTokenError = new BehaviorSubject<APIError>(null);

  constructor(
    private readonly http: HttpClient,
    @Inject(API_CONFIG) private readonly api: ApiConfig,
  ) {}

  requestShareToken(projectId: string): Observable<ShareTokenRsp> {
    this.beforeShareToken();
    return this.http
      .get<ShareTokenRsp>(this.buildShareTokenUrl(projectId))
      .pipe(
        tap((rsp) => this.upsert(projectId, this.extractShareUrl(rsp))),
        catchError((error) => this.onShareTokenError(error)),
        finalize(() => this.loading.next(false)),
      );
  }

  shareViaLink(
    projectUuid: string,
    token: string,
  ): Observable<ShareViaLinkRsp> {
    this.loading.next(true);
    return this.http
      .post<ShareViaLinkRsp>(this.buildShareViaLinkUrl(), {
        projectUuid,
        token,
      })
      .pipe(finalize(() => this.loading.next(false)));
  }

  selectShareUrl(id: string): Observable<string> {
    return this.shareUrls.pipe(map((urls) => urls[id]));
  }

  selectLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  selectShareTokenError(): Observable<APIError> {
    return this.shareTokenError.pipe(
      map((error) => Utils.resolveToEmptyObject(error)),
    );
  }

  private beforeShareToken(): void {
    this.loading.next(true);
    this.shareTokenError.next(null);
  }

  private onShareTokenError(
    error: HttpErrorResponse,
  ): Observable<ShareTokenRsp> {
    this.shareTokenError.next(this.extractError(error));
    return throwError(error);
  }

  private extractError(from: HttpErrorResponse): APIError {
    return { error: this.getDefaultShareTokenErrMsg(), ...from.error };
  }

  private getDefaultShareTokenErrMsg(): string {
    return 'ERROR: Failed to generate the shareable link';
  }

  private extractShareUrl(from: ShareTokenRsp): string {
    return from.shareUrl;
  }

  private upsert(id: string, shareUrl: string): void {
    this.shareUrls.next({ ...this.shareUrls.value, [id]: shareUrl });
  }

  private buildShareViaLinkUrl(): string {
    return generateEndpoint(this.baseUrl, this.getShareViaLinkTemplate());
  }

  private getShareViaLinkTemplate(): string {
    return this.endpoints['project']['shareViaLink'];
  }

  private buildShareTokenUrl(id: string): string {
    return generateEndpoint(this.baseUrl, this.getShareTokenTemplate(), id);
  }

  private getShareTokenTemplate(): string {
    return this.endpoints['project']['shareToken'];
  }

  private get baseUrl(): string {
    return this.api.baseUrl;
  }

  private get endpoints(): Record<string, unknown> {
    return this.api.endpoints;
  }
}
