import { toTitleCase } from 'prosumer-app/libs/eyes-shared';

export type GDPR = Readonly<{
  loading?: boolean;
  error?: any;
  latestVersion?: string;
  text?: string;
  acceptedVersion?: string;
}>;

export type UserInfo = Readonly<{
  loading?: boolean;
  error?: any;
  [fieldId: string]: any;
}>;

export type OldUserState = Readonly<{
  fetching?: boolean;
  userInfo?: UserInfo;
  gdpr?: GDPR;
  fetchedUser?: OldUser;
  userCache?: { [key: string]: OldUser };
}>;

export enum OldRetrievalType {
  ID,
  EMAIL,
}

export type OldUser = Readonly<{
  name?: string;
  email?: string;
  imageUrl?: string;
  uuid?: string;
  error?: boolean;
  retrievedUsing?: OldRetrievalType;
}>;

export const buildProperName = (firstName: string, lastName: string): string =>
  `${toTitleCase(firstName)} ${toTitleCase(lastName)}`;

export const mapUserToFrontend = (userDict): OldUser => ({
  uuid: userDict.id || 'something',
  email: userDict.email,
  name: buildProperName(userDict.firstName, userDict.lastName),
});
