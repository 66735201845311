import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService, HttpService } from '../../services';
import { GDPR } from './user.model';

@Injectable()
export class UserService {
  constructor(
    private _config: ConfigService,
    private _http: HttpService,
    private _translate: TranslateService,
  ) {}

  getGDPRPolicy(): Observable<GDPR> {
    return this._http
      .get(
        generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.user.getGDPRPolicy,
        ),
      )
      .pipe(
        map((response) => ({
          acceptedVersion: response.accepted_version,
          text: response.text,
          latestVersion: response.latest_version,
        })),
        catchError((response) =>
          response && response.error && response.error.error
            ? throwError(response.error.error)
            : throwError(
                this._translate.instant('User.messages.getGDPRPolicyFailure'),
              ),
        ),
      );
  }

  acceptGDPRPolicy(latestVersionToAccept: string): Observable<void> {
    return this._http
      .post(
        generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.user.acceptGDPRPolicy,
        ),
        {
          version: latestVersionToAccept,
        },
      )
      .pipe(
        catchError((response) =>
          response && response.error && response.error.error
            ? throwError(response.error.error)
            : throwError(
                this._translate.instant(
                  'User.messages.acceptGDPRPolicyFailure',
                ),
              ),
        ),
      );
  }

  getUserByEmail(emailAddress: string): Observable<any> {
    return this._http.get(
      generateEndpoint(
        this._config.api.baseUrl,
        this._config.api.endpoints.user.getUserByEmail,
        emailAddress,
      ),
    );
  }

  getUserByID(uuid: string): Observable<any> {
    return this._http.get(
      generateEndpoint(
        this._config.api.baseUrl,
        this._config.api.endpoints.user.getUserById,
        uuid,
      ),
    );
  }
}
