import { EmissionComparisonModule } from 'prosumer-app/comparison/emission-comparison';
import { EquipmentComparisonModule } from 'prosumer-app/comparison/equipment-comparison';
import { TopologyComparisonModule } from 'prosumer-app/comparison/topology-comparison';
import {
  EyesSharedModule,
  InversePipe,
  JsonKeyToLabelPipe,
  KiloPipe,
  MetricPipe,
  TooltipModule,
} from 'prosumer-app/libs/eyes-shared';
import { EntityUpsertButtonModule } from 'prosumer-app/shared/components/entity-upsert-button';
import { ScenarioUpdaterModule } from 'prosumer-app/shared/directives/scenario-updater';
import { ScenarioVariationUpdaterModule } from 'prosumer-app/shared/directives/scenario-variation-updater';
import { SharedModule } from 'prosumer-shared';
import { LineChartjsModule } from 'prosumer-shared/modules/chartjs/line-chartjs';
import { StackedAreaChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { SankeyChartModule } from 'prosumer-shared/modules/charts/components/sankey-chart';

import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { LetDirective, PushPipe } from '@ngrx/component';
import { CashflowResultsModule } from '@prosumer/results/components/cashflow-results';
import { EmissionsResultsModule } from '@prosumer/results/components/emissions-results';
import { EnergyBalanceResultsModule } from '@prosumer/results/components/energy-balance-results';
import { EquipmentResultsModule } from '@prosumer/results/components/equipment-results';
import { FlowResultsModule } from '@prosumer/results/components/flow-results';
import { MainOutputResultsModule } from '@prosumer/results/components/main-output-results';
import { ResultsPerceptionTriggerModule } from '@prosumer/results/components/results-perception-trigger';
import { TopologyResultsModule } from '@prosumer/results/components/topology-results';

import { NgxRerenderModule } from 'ngx-rerender';
import {
  ActivationTableComponent,
  CashflowContainerComponent,
  CommoditiesFormComponent,
  CompareCashflowComponent,
  CompareDailyStorageComponent,
  CompareDispatchComponent,
  CompareDispatchDailyComponent,
  CompareDispatchMonthlyComponent,
  CompareDispatchRawComponent,
  CompareDispatchWeeklyComponent,
  CompareEnergyBalanceComponent,
  CompareEquipmentComponent,
  CompareMainComponent,
  CompareMonthlyStorageComponent,
  ComparePaybackComponent,
  CompareRawStorageComponent,
  CompareSummaryComponent,
  CompareTopologyComponent,
  CompareWeeklyStorageComponent,
  CustomOptionsFormDialogComponent,
  CustomOptionsFormTableComponent,
  EnergyVectorListComponent,
  EnergyVectorSelectComponent,
  EquipmentFormComponent,
  EquipmentTableComponent,
  FrequencyControlFormComponent,
  GeneralFormComponent,
  LimitsFormComponent,
  LoadsFormComponent,
  MarginTableComponent,
  MarketTableComponent,
  MobilityComponent,
  NettingFormComponent,
  NettingFormTableComponent,
  OptimizationCockpitFormComponent,
  OptionsFormComponent,
  RegulationFormComponent,
  ReserveNetworkTableComponent,
  RoutesFormComponent,
  ScenarioUploadFormComponent,
  ScenarioVariationListComponent,
  SCEquipmentTableComponent,
  SCPowerNeededTableComponent,
  ShortCircuitFormComponent,
  SpinningReservesComponent,
  SummaryFormComponent,
  TaxSubsidyFormTableComponent,
  TdbCommodityPricesModule,
  TimeFormComponent,
  TopologyFormComponent,
  TopologyLinesTableComponent,
  TopologyNodesDialogComponent,
  TopologyNodesTableComponent,
  VehiclesDispatchFormComponent,
  VehicleStationFormComponent,
} from './components';
import { CommoditiesEmissionsModule } from './components/commodities-emissions';
import { EgcUpsertTriggerModule } from './components/egc-upsert-trigger';
import { FilledIndicatorModule } from './components/filled-indicator';
import { MarketLimitUpsertTriggerModule } from './components/market-limit-upsert-trigger';
import { VariationsLimitsFormComponent } from './components/optimization-cockpit-form/variations-limits-form';
import { ScenarioDetailsModule } from './components/scenario-details';
import { ScenarioNavigationModule } from './components/scenario-navigation';
import { ScenarioVariationOptionModule } from './components/scenario-variation-option/scenario-variation-option.module';
import { LinesDuplicateDialogModule } from './components/topology-form/lines-duplicate-dialog';
import { UploadFormListComponent } from './components/upload-form-list';
import {
  AssetTypeVisualizationComponent,
  AverageDailyProdComponent,
  CapexOpexComponent,
  CashFlowComponent,
  CO2EmissionsComponent,
  CostsComponent,
  DailyDispatchComponent,
  DispatchComponent,
  EnergyBalanceComponent,
  EquipmentComponent,
  IncrementalVisualizationComponent,
  LineInvestmentComponent,
  MainOutputComponent,
  MonthlyDispatchComponent,
  MonthlyProdComponent,
  RawDispatchComponent,
  ScenarioComparePageComponent,
  ScenarioCompareResolverService,
  ScenarioPageComponent,
  ScenarioSimulationComponent,
  ScenarioUploadPageComponent,
  ScopeTypeVisualizationComponent,
  SeriesTooltipComponent,
  SimulationResultResolverService,
  SimulationResultsComponent,
  SizingOutputComponent,
  StorageDailyDispatchComponent,
  StorageRawDispatchComponent,
  StorageWeeklyDispatchComponent,
  WeeklyDispatchComponent,
} from './containers';
import { StorageMonthlyDispatchComponent } from './containers/simulation-results/dispatch/storage-monthly-dispatch';
import { CumulativeVisualizationModule } from './containers/simulation-results/equipment/cumulative-visualization';
import { CoherenceCheckerModule } from './directives/coherence-checker';
import { DetailEntitiesGetterModule } from './directives/detail-entities-getter';
import { EgcEmissionsGetterModule } from './directives/egc-emissions-getter';
import { EgcGetterModule } from './directives/egc-getter';
import { EgcListerModule } from './directives/egc-lister';
import { FuelsGetterModule } from './directives/fuels-getter';
import { FuelsListerModule } from './directives/fuels-lister';
import { MarketLimitsGetterModule } from './directives/market-limits-getter';
import { NodesGetterModule } from './directives/nodes-getter';
import { ProjectDurationDetectorModule } from './directives/project-duration-detector';
import { VectorsGetterModule } from './directives/vectors-getter';
import { ScenarioRoutingModule } from './scenario-routing.module';

const moduleMembers = [
  /* Main Containers */
  ScenarioPageComponent,
  ScenarioUploadPageComponent,
  ScenarioComparePageComponent,
  // End

  /* Wizard Step components  */
  GeneralFormComponent,
  TopologyFormComponent,
  TopologyLinesTableComponent,
  TopologyNodesTableComponent,
  TopologyNodesDialogComponent,
  ScenarioUploadFormComponent,
  UploadFormListComponent,
  TimeFormComponent,
  LoadsFormComponent,
  RegulationFormComponent,
  SummaryFormComponent,
  EquipmentFormComponent,
  CommoditiesFormComponent,
  MobilityComponent,
  RoutesFormComponent,
  VehicleStationFormComponent,
  VehiclesDispatchFormComponent,
  FrequencyControlFormComponent,
  OptimizationCockpitFormComponent,
  LimitsFormComponent,
  VariationsLimitsFormComponent,
  OptionsFormComponent,
  CustomOptionsFormTableComponent,
  CustomOptionsFormDialogComponent,
  NettingFormComponent,
  NettingFormTableComponent,
  TaxSubsidyFormTableComponent,
  // End

  /* General Form Sub-components */
  EnergyVectorSelectComponent,
  EnergyVectorListComponent,
  ScenarioVariationListComponent,
  // End

  /* Results Sub-components */
  CashFlowComponent,
  EnergyBalanceComponent,
  LineInvestmentComponent,
  // End

  /* Results Sub-components - Equipment*/
  IncrementalVisualizationComponent,
  EquipmentComponent,

  /* Results Sub-components - CO2Emissions */
  CO2EmissionsComponent,
  AssetTypeVisualizationComponent,
  ScopeTypeVisualizationComponent,
  // End

  /* Results Sub-components - Dispatch */
  DispatchComponent, // Parent
  DailyDispatchComponent,
  MonthlyDispatchComponent,
  RawDispatchComponent,
  WeeklyDispatchComponent,
  StorageRawDispatchComponent,
  StorageDailyDispatchComponent,
  StorageWeeklyDispatchComponent,
  // End

  /* Results Sub-components - Main Output */
  MainOutputComponent, // Parent
  AverageDailyProdComponent,
  CapexOpexComponent,
  CostsComponent,
  MonthlyProdComponent,
  SizingOutputComponent,
  // End

  /* Frequency Control Sub-components - Short Circuit */
  ShortCircuitFormComponent,
  SCEquipmentTableComponent,
  SCPowerNeededTableComponent,
  SpinningReservesComponent,
  MarginTableComponent,
  ActivationTableComponent,
  EquipmentTableComponent,
  MarketTableComponent,
  ReserveNetworkTableComponent,
  // End

  /* Simulation */
  ScenarioSimulationComponent,
  SimulationResultsComponent,
  // End

  /* Compare */
  CompareSummaryComponent,
  CompareMainComponent,
  CompareEquipmentComponent,
  CompareEnergyBalanceComponent,
  CompareTopologyComponent,
  CashflowContainerComponent,
  ComparePaybackComponent,
  CompareCashflowComponent,
  CompareDispatchComponent,
  CompareDispatchRawComponent,
  CompareDispatchDailyComponent,
  CompareDispatchWeeklyComponent,
  CompareDispatchMonthlyComponent,
  CompareDailyStorageComponent,
  CompareWeeklyStorageComponent,
  CompareRawStorageComponent,
  CompareMonthlyStorageComponent,

  /* Shared */
  SeriesTooltipComponent,
  // End
];

// eslint-disable-next-line @typescript-eslint/naming-convention
const RefactoredResultModules = [
  TopologyResultsModule,
  CashflowResultsModule,
  EmissionsResultsModule,
  EnergyBalanceResultsModule,
  EquipmentResultsModule,
  MainOutputResultsModule,
  FlowResultsModule,
];
// eslint-disable-next-line @typescript-eslint/naming-convention
const ChartJSModules = [StackedAreaChartjsModule, StackedBarChartjsModule];
// eslint-disable-next-line @typescript-eslint/naming-convention
const RefactoredComparisonModules = [
  EquipmentComparisonModule,
  TopologyComparisonModule,
  EmissionComparisonModule,
];

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    ScenarioRoutingModule,
    TdbCommodityPricesModule,
    SharedModule,
    CumulativeVisualizationModule,
    ...RefactoredResultModules,
    ResultsPerceptionTriggerModule,
    SankeyChartModule, // Prototype: Remove after
    ...ChartJSModules,
    ...RefactoredComparisonModules,
    LineChartjsModule,
    StackedAreaChartjsModule,
    FilledIndicatorModule,
    LetDirective,
    PushPipe,
    ScenarioDetailsModule,
    ScenarioNavigationModule,
    TooltipModule,
    ScenarioUpdaterModule,
    ScenarioVariationUpdaterModule,
    CommoditiesEmissionsModule,
    EgcGetterModule,
    ProjectDurationDetectorModule,
    EgcListerModule,
    EgcUpsertTriggerModule,
    MarketLimitUpsertTriggerModule,
    NodesGetterModule,
    VectorsGetterModule,
    FuelsGetterModule,
    FuelsListerModule,
    EgcEmissionsGetterModule,
    MarketLimitsGetterModule,
    DetailEntitiesGetterModule,
    EntityUpsertButtonModule,
    CoherenceCheckerModule,
    LinesDuplicateDialogModule,
    ScenarioVariationOptionModule,
    FileUploadModule,
    NgxRerenderModule,
  ],
  declarations: [...moduleMembers, StorageMonthlyDispatchComponent],
  exports: [...moduleMembers],
  providers: [
    TitleCasePipe,
    JsonKeyToLabelPipe,
    SimulationResultResolverService,
    ScenarioCompareResolverService,
    KiloPipe,
    InversePipe,
    DecimalPipe,
    MetricPipe,
  ],
})
export class ScenarioModule {}
