import { Directive, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { MonoTypeOperatorFunction, Subject, Subscription, pipe } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

// TODO: Add Angular decorator.
@Directive()
export abstract class BaseComponent implements OnDestroy {
  public componentDestroyed$ = new Subject<boolean>();
  public subscriptions = new Subscription();

  addSubscription(subscription: Subscription): void {
    this.subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  /**
   * Patch control with value safely and with default value if value is null or undefined; Can mark the control as pristine
   *
   * @param control - the abstract form control
   * @param value - the value to be patched
   * @param defaultValue - the default value of value is undefined
   * @param markAsPristine - pass true if we want to mark the control as pristine
   */
  patchControlWithValue<T>(
    control: AbstractControl,
    value: T,
    defaultValue?: T,
    markAsPristine?: boolean,
  ) {
    const coercedValue = Coerce.toObject(value, defaultValue);
    if ([!!control, !!coercedValue].every(Boolean)) {
      control.patchValue(coercedValue);
      if (markAsPristine) {
        control.markAsPristine();
      }
    }
  }

  /**
   * Combination of take until and share replay operators; The observable with this pipeable operator will be closed once the component is
   * destroyed; Also the observable with this pipeable operator will share it's last emitted value
   */
  takeUntilShare<T>(): MonoTypeOperatorFunction<T> {
    return pipe(takeUntil<T>(this.componentDestroyed$), shareReplay(1));
  }

  /**
   * The observable with this pipeable operator will be closed once the component is destroyed
   */
  takeUntil<T>(): MonoTypeOperatorFunction<T> {
    return pipe(takeUntil<T>(this.componentDestroyed$));
  }
}
