/* eslint-disable @typescript-eslint/naming-convention */
import {
  FormFieldOption,
  StatusMap,
  toTitleCase,
} from 'prosumer-app/libs/eyes-shared';
import { FluidConfig } from 'prosumer-shared';

export const EXECUTION_STATUS = {
  NOT_READY: 'not_ready',
  PREPARING: 'preparing',
  READY: 'ready',
  RUNNING: 'running',
  QUEUED: 'queued',
  SUCCESS: 'succeeded',
  SUCCESS_WITH_UNSATISFIED_CONSTRAINTS:
    'succeeded_with_unsatisfied_constraints',
  SUCCESS_WITH_WARNINGS: 'succeeded_with_warnings',
  FAILED: 'failed',
  IMPOSSIBLE_SIMULATION: 'impossible_simulation',
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  SUBMITTING: 'submitting',
};

export const EXECUTION_STATUS_SUCCESS = [
  EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS,
  EXECUTION_STATUS.SUCCESS_WITH_WARNINGS,
  EXECUTION_STATUS.SUCCESS,
];

export const EXECUTION_STATUS_FINISHED = [
  ...EXECUTION_STATUS_SUCCESS,
  EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
  EXECUTION_STATUS.FAILED,
];

export const EXECUTION_STATUS_EDITABLE = [
  EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
  EXECUTION_STATUS.FAILED,
  EXECUTION_STATUS.DRAFT,
  EXECUTION_STATUS.NOT_READY,
];

export const RENEWABLE_PROFILE_STATUS = {
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
  SUCCESS: 'succeeded',
  FAILED: 'failed',
  ERROR: 'error',
};

export const SCENARIO_TYPE = {
  CREATED: 'user_created',
  UPLOADED: 'created_by_upload',
  CLONED: 'cloned_by_upload',
};

export const DER_TYPES = {
  vre: 'Renewable Generator',
  storage: 'Storage',
  generator: 'Generator',
  converter: 'Converter',
  // heat: 'Heat Pump'
};

export const PREDEFINED_ENERGY_VECTOR_MAP = {
  e0000: 'electricity',
  e0001: 'hydrogen',
  e0002: 'heating',
  e0003: 'gas',
  e0004: 'diesel',
  e0005: 'others',
  e0006: 'cold',
  e0007: 'biomass',
  e0008: 'water',
};

export const PREDEFINED_ENERGY_VECTOR_MAP_LABEL = {
  electricity: 'electricity',
  hydrogen: 'hydrogen',
  heating: 'heat',
  gas: 'natural gas',
  diesel: 'diesel',
  others: 'others',
  cold: 'cold',
  biomass: 'biomass',
  water: 'water',
};

export const PREDEFINED_ENERGY_VECTORS = [
  {
    id: 'e0000',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0000'],
    image: 'assets/icons/energy-vectors/electricity_00.svg',
  },
  {
    id: 'e0001',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0001'],
    image: 'assets/icons/energy-vectors/hydrogen_00.svg',
  },
  {
    id: 'e0002',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0002'],
    image: 'assets/icons/energy-vectors/heating_00.svg',
  },
  {
    id: 'e0003',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0003'],
    image: 'assets/icons/energy-vectors/gas_00.svg',
  },
  {
    id: 'e0004',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0004'],
    image: 'assets/icons/energy-vectors/diesel_00.svg',
  },
  {
    id: 'e0006',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0006'],
    image: 'assets/icons/energy-vectors/cooling_00.svg',
  },
  {
    id: 'e0007',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0007'],
    image: 'assets/icons/energy-vectors/biomass_00.svg',
  },
  {
    id: 'e0008',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0008'],
    image: 'assets/icons/energy-vectors/water_00.svg',
  },
  {
    id: 'e0005',
    value: PREDEFINED_ENERGY_VECTOR_MAP['e0005'],
    image: 'assets/icons/energy-vectors/others_00.svg',
  },
];

export const DASHBOARD_ICONS = (() => {
  const DASHBOARD_ICON_PATH = 'assets/icons/dashboard-icons';
  return [
    {
      name: 'info_italic',
      image: `${DASHBOARD_ICON_PATH}/info_italic.svg`,
    },
    {
      name: 'user_profile',
      image: `${DASHBOARD_ICON_PATH}/user_profile.svg`,
    },
    {
      name: 'svg_renewable_profile',
      image: `${DASHBOARD_ICON_PATH}/nature.svg`,
    },
    {
      name: 'svg_ask_support',
      image: `${DASHBOARD_ICON_PATH}/support.svg`,
    },
  ];
})();

export const SIMULATION_ICONS = (() => {
  const PATH = 'assets/icons/simulation-icons';
  return [
    {
      name: 'succeeded',
      image: `${PATH}/succeeded.svg`,
    },
    {
      name: 'succeeded_with_warnings',
      image: `${PATH}/succeeded_with_warnings.svg`,
    },
    {
      name: 'succeeded_with_unsatisfied_constraints',
      image: `${PATH}/succeeded_with_unsatisfied_constraints.svg`,
    },
    {
      name: 'failed',
      image: `${PATH}/failed.svg`,
    },
    {
      name: 'impossible_simulation',
      image: `${PATH}/impossible_simulation.svg`,
    },
  ];
})();

export const RESULTS_DEFAULT_SCHEME = {
  name: 'highContrast',
  selectable: true,
  group: 'Ordinal',
  domain: [
    '#375e97',
    '#fb6542',
    '#ffbb00',
    '#3f881c',
    '#f18d9e',
    '#34675c',
    '#1e434c',
    '#10394c',
  ],
};

export const ENGIE_DEFAULT_SCHEME = {
  name: 'engieColors',
  selectable: true,
  group: 'Ordinal',
  domain: [
    '#002F4D',
    '#005288',
    '#0195F5',
    '#784300',
    '#D57700',
    '#FF8E00',
    '#004846',
    '#007873',
    '#2C9995',
    '#52330A',
    '#785222',
    '#8F6C3E',
    '#092335',
    '#2B485C',
    '#637D8F',
    '#356A00',
    '#6AAF23',
    '#ADEF73',
    '#670024',
    '#AA2252',
    '#DF7097',
    '#9A7B00',
    '#FACA08',
    '#FFE168',
    '#1A0A6A',
    '#331AAD',
    '#6F5EC3',
    '#7F0020',
    '#CC0033',
    '#EB4F76',
  ],
};

export const COLOR_PICKER_PRESET_COLORS = [
  // from Dimitri
  '#00aaff',
  '#575f63',
  '#353A3D',
  '#B5BBB8',
  '#F0F1F1',
  '#1ED284',
  '#0015A0',
  '#B32765',
  '#FD5064',
  '#FFA101',
  // additonal colors from Engie Design Brand Colors
  '#fbdb3c',
  '#6ed2b1',
  '#007873',
  '#009934',
  '#becd00',
  '#552382',
  '#910f7d',
  '#e62b87',
];

export function generateShadedScheme(
  numberOfElements: number,
  scheme: {
    name: string;
    selectable: boolean;
    group: string;
    domain: string[];
  } = ENGIE_DEFAULT_SCHEME,
) {
  const SHADE_STEP = '303030';
  // Lambda function to simply add 2 hex color codes
  const addTwoColors = (first, second) => {
    let hexStr = (parseInt(first, 16) + parseInt(second, 16)).toString(16);
    hexStr = '#' + (hexStr.length === 6 ? hexStr : 'F' + hexStr.substr(2));
    return hexStr.toUpperCase();
  };

  // Actual logic for generating shades of the RESULTS_DEFAULT_SCHEME
  const shadeIterations = Math.ceil(
    numberOfElements / Object.keys(scheme.domain).length,
  );
  // Generate an x number of array of different shades of the MainColor
  let currentArr = Object.values(scheme.domain);
  let shadedSchemeDomain = [...currentArr];
  for (let ctr = 1; ctr < shadeIterations; ctr++) {
    // Combine all arrays
    currentArr = currentArr.map((color: string) =>
      addTwoColors(color.substr(1), SHADE_STEP),
    );
    shadedSchemeDomain = shadedSchemeDomain.concat(currentArr);
  }
  // Slice the overflown elements and return
  return {
    ...scheme,
    domain:
      shadeIterations === 1
        ? shadedSchemeDomain
        : shadedSchemeDomain.slice(0, numberOfElements),
  };
}

export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MMM';
export const MONTH_FULL_FORMAT = 'MMMM';
export const DAY_FORMAT = 'D';
export const HOUR_FORMAT = 'hA';

export const RESULTS_DISPATCH_OPTIONS = [
  'Raw',
  'Avg. Day',
  'Avg. Week',
  'Monthly',
];
export const RESULTS_DISPATCH_YEAR_FORMAT = YEAR_FORMAT;
export const RESULTS_DISPATCH_MONTH_FORMAT = MONTH_FORMAT;
export const RESULTS_DISPATCH_DAY_FORMAT = DAY_FORMAT;
export const RESULTS_DISPATCH_HOUR_FORMAT = HOUR_FORMAT;

export const DEFAULT_DEBOUNCE_TIME = 400;

export const PUBSUB_TOPIC = 'prosumerTopic';
export const REDIRECT_URL_KEY = 'RedirectUrl';

export const BINARY_LOCATIONS = {
  END_USE_LOADS: 'loads.loadProfile',
  DER: 'ders.profile',
  DER_OPERATING_COSTS: 'ders.operatingCostProfile',
  ENERGY_GRID_CONNECTION: 'energyGridConnections.energyTariff',
  ENERGY_GRID_CONNECTIONS_EMISSIONS:
    'energyGridConnectionsEmissions.emissionsRate',
  RESERVE_MARGIN: 'reserve.margin',
  MOBILITY_ROUTES: 'mobility.routes',
};

export const COHERENCE_STEP_LABELS = {
  import: 'Import',
  general: 'General',
  time: 'Time Horizon',
  topology: 'Topology',
  loads: 'Loads',
  equipment: 'Equipment',
  commodities: 'Commodities',
  regulations: 'Regulations',
  launch: 'Launch',
  global: 'Global',
  global_time: { label: 'Global', tab: 'Time Horizon' },
  global_loads: { label: 'Global', tab: 'Loads' },
  frequencyControl: 'Frequency Control',
  mobility: 'Mobility',
  optimizationCockpit: 'Optimization Cockpit',
  netting: 'Netting',
};

export const USAGE_COLORS = {
  gray: '#CFD8DC',
  green: '#69AF23',
  orange: '#F18F09',
  red: '#E10019',
};

export const USAGE_KEYS = {
  normal: 'normal',
  threshold: 'threshold',
  full: 'full',
};

export const LICENSE_PLAN_COLORS = {
  silver: '#C0C0C0',
  gold: '#D4AF37',
  unlimited: '#BB9B6A',
  eldorado: '#832121',
};

export const PROSUMER_USER_ROLES = {
  preferenceKey: 'prosumerRole',
  expert: 'expert',
  business: 'business',
};

export const RUN_STATUS_ICON_MAP: StatusMap = {
  ready: {
    class: 'eyes-status-pending',
    icon: 'watch_later',
    label: toTitleCase(EXECUTION_STATUS.READY),
  },
  preparing: {
    class: 'eyes-status-pending',
    icon: 'find_in_page',
    label: toTitleCase(EXECUTION_STATUS.PREPARING),
  },
  queued: {
    class: 'eyes-status-pending',
    icon: 'queue',
    label: toTitleCase(EXECUTION_STATUS.QUEUED),
  },
  draft: {
    class: 'eyes-status-draft',
    icon: 'assignment',
    label: toTitleCase(EXECUTION_STATUS.DRAFT),
  },
  not_ready: {
    class: 'eyes-status-draft',
    icon: 'assignment',
    label: toTitleCase(EXECUTION_STATUS.DRAFT),
  },
  running: {
    class: 'eyes-status-ongoing',
    icon: 'play_circle_filled',
    label: toTitleCase(EXECUTION_STATUS.RUNNING),
  },
  failed: {
    class: 'eyes-status-failed',
    icon: 'failed',
    label: 'Scenario.status.failed',
  },
  succeeded: {
    class: '',
    icon: 'succeeded',
    label: 'Scenario.status.succeeded',
  },
  succeeded_with_unsatisfied_constraints: {
    class: '',
    icon: 'succeeded_with_unsatisfied_constraints',
    label: 'Scenario.status.succeeded_with_constraints',
  },
  succeeded_with_warnings: {
    class: '',
    icon: 'succeeded_with_warnings',
    label: 'Scenario.status.succeeded_with_warnings',
  },
  impossible_simulation: {
    class: '',
    icon: 'impossible_simulation',
    label: 'Scenario.status.impossible_simulation',
  },
};

export const RENEWABLE_PROFILE_STATUS_MAP: StatusMap = {
  submitted: {
    class: 'eyes-status-pending',
    icon: 'watch_later',
    label: toTitleCase(RENEWABLE_PROFILE_STATUS.SUBMITTED),
  },
  processing: {
    class: 'eyes-status-ongoing',
    icon: 'play_circle_filled',
    label: toTitleCase(RENEWABLE_PROFILE_STATUS.PROCESSING),
  },
  failed: {
    class: 'eyes-status-failed',
    icon: 'error',
    label: toTitleCase(RENEWABLE_PROFILE_STATUS.FAILED),
  },
  error: {
    class: 'eyes-status-failed',
    icon: 'error',
    label: toTitleCase(RENEWABLE_PROFILE_STATUS.ERROR),
  },
  succeeded: {
    class: 'eyes-status-success',
    icon: 'check_circle',
    label: toTitleCase(RENEWABLE_PROFILE_STATUS.SUCCESS),
  },
};

export const DIRECTIONS = {
  up: 'Up',
  down: 'Down',
};

export const PARTICIPATIONS = {
  true: 'True',
  false: 'False',
};

export const DIRECTION_ICONS = {
  up: 'arrow_upward',
  down: 'arrow_downward',
};

export const BOOLEAN_ICONS = {
  true: 'check',
  false: 'close',
};

export const DIALOG_SIZES = {
  small: 640,
  medium: 960,
  large: 1280,
  xLarge: 1600,
};

export const COMPARE_EQUIPMENT_RESULTS_VALUE_OPTIONS = {
  sizekW: {
    label: 'Scenario.labels.compare.equipment.sizekW',
    context: 'output_equipment.output_equipment_size_kw',
  },
  sizekWh: {
    label: 'Scenario.labels.compare.equipment.sizekWh',
    context: 'output_equipment.output_equipment_size_kwh',
  },
  totalDiscountedCost: {
    label: 'Scenario.labels.compare.equipment.totalDiscountedCost',
    context: 'output_equipment.output_equipment_total_cost',
  },
  annualEnergyProduction: {
    label: 'Scenario.labels.compare.equipment.annualEnergyProduction',
    context: 'output_equipment.output_equipment_production',
  },
  annualEnergyConsumption: {
    label: 'Scenario.labels.compare.equipment.annualEnergyConsumption',
    context: 'output_equipment.output_equipment_consumption',
  },
};

export const COMPARE_TOPOLOGY_RESULTS_VALUE_OPTIONS = {
  sizekW: {
    label: 'Size[kW]',
    context: 'output_topology.output_topology_size_kw',
  },
  sizekWh: {
    label: 'Annual Energy Flow from Origin to Destination[kWh]',
    context: 'output_topology.output_topology_flow_o_to_d',
  },
  sizekEUR: {
    label: 'Total Discounted Cost[kEUR]',
    context: 'output_topology.output_topology_total_cost',
  },
  sizekWhBa: {
    label: 'Annual Energy Flow from Destination to Origin[kWh]',
    context: 'output_topology.output_topology_flow_d_to_o',
  },
};

export const SCENARIO_COMPARE_COLOR_REF = {
  'deep-purple-bg': '#9575cd',
  'light-blue-bg': '#4fc3f7',
  'green-bg': '#81c784',
  'red-bg': '#e57373',
  'orange-bg': '#ffb74d',
};

export const SCENARIO_RESULT_DISPATCH_OPTIONS: Array<FormFieldOption<string>> =
  [
    {
      name: 'Scenario.labels.compare.dispatch.raw',
      value: 'raw',
      tooltip: 'output_dispatch.output_dispatch_raw',
      tooltipPosition: 'center',
    },
    {
      name: 'Scenario.labels.compare.dispatch.daily',
      value: 'daily',
      tooltip: 'output_dispatch.output_dispatch_avg_day',
      tooltipPosition: 'center',
    },
    {
      name: 'Scenario.labels.compare.dispatch.weekly',
      value: 'weekly',
      tooltip: 'TBD',
      tooltipPosition: 'center',
    },
    {
      name: 'Scenario.labels.compare.dispatch.monthly',
      value: 'monthly',
      tooltip: 'output_dispatch.output_dispatch_monthly',
      tooltipPosition: 'center',
    },
  ];

export const STORAGE_TYPE = 'storage_mvts';

export const SCENARIO_RESULT_DISPATCH_TYPE_OPTIONS = {
  production: 'Scenario.labels.compare.dispatch.production',
  consumption: 'Scenario.labels.compare.dispatch.consumption',
  storage_mvts: 'Scenario.labels.compare.dispatch.storage',
  soc: 'Scenario.labels.compare.dispatch.soc',
};

export const STORAGE_COLORS = [
  { name: 'Storage SOC', value: '#58D258' },
  { name: 'Storage Charge', value: '#D27658' },
  { name: 'Storage Discharge', value: '#CAC76B' },
];

export const DEFAULT_LOAD_CHART_DEBOUNCE_TIME = 200;
export const DEFAULT_INPUT_TEXT_DEBOUNCE_TIME = 400;

export const MONTHLY_DAYS = {
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
};

export const BOOLEAN_OPTIONS: Array<FormFieldOption<boolean>> = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const EV_FLUID_CONFIG_MAP: FluidConfig = {
  electricity: { color: '#f9a825', icon: 'electricity', name: 'electricity' },
  hydrogen: { color: '#7b1fa2', icon: 'hydrogen', name: 'hydrogen' },
  heating: { color: '#d32f2f', icon: 'heating', name: 'heating' },
  gas: { color: '#303f9f', icon: 'gas', name: 'gas' },
  diesel: { color: '#00796b', icon: 'diesel', name: 'diesel' },
  cold: { color: '#0288d1', icon: 'cold', name: 'cold' },
  biomass: { color: '#388e3c', icon: 'biomass', name: 'biomass' },
  water: { color: '#0094dc', icon: 'water', name: 'water' },
};

export const CUSTOM_EV_COLOR_PALETTE = [
  '#ff5722',
  '#9575cd',
  '#4fc3f7',
  '#81c784',
  '#e57373',
  '#ffb74d',
  '#009688',
  '#4a148c',
  '#aeea00',
  '#880e4f',
  '#002F4D',
  '#5d4037',
  '#72a5cc',
  '#4caf50',
  '#ffb74d',
  '#0277bd',
  '#ba68c8',
  '#ff8a80',
  '#673ab7',
  '#ffd600',
  '#00e5ff',
  '#827717',
];

// Support
export const SUPPORT = {
  TYPE_OF_REQUESTS: [
    { name: 'Bug', value: 'TR0000' },
    { name: 'How To', value: 'TR0001' },
    { name: 'Issue Analysis', value: 'TR0002' },
    { name: 'Help to Understand Results', value: 'TR0003' },
    { name: 'Feature Request', value: 'TR0004' },
    { name: 'Sales/License Question', value: 'TR0005' },
  ],
  ISSUE_TYPES: {
    BUG: 'BUG',
    TASK: 'TASk',
    SUPPORT: 'SUPPORT',
  },
  SUPPORT_FILE_EXTENSIONS: [
    'png',
    'jpg',
    'svg',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'pdf',
    'csv',
  ],
  JIRA_BASE_URL: 'https://tractebel-et.atlassian.net/browse/',
};

export const ERROR_MESSAGES = {
  PROJECT_NOT_FOUND: 'Project not found',
  CASE_NOT_FOUND: 'Case not found',
  SCENARIO_NOT_FOUND: 'Scenario not found',
};

export const ERROR_MESSAGES_REDIRECT = [
  ERROR_MESSAGES.PROJECT_NOT_FOUND,
  ERROR_MESSAGES.CASE_NOT_FOUND,
  ERROR_MESSAGES.SCENARIO_NOT_FOUND,
];

export const USER_GROUPS = {
  T3A: 'prosumer_T3A',
  SUPER_USER: 'prosumer_superusers',
  DEV_USER: 'prosumer_DevTeam',
};
