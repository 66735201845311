<div
  fxLayout="row"
  fxLayoutGap="10px"
  [formGroup]="form"
  *ngrxLet="loadingMap$ as loading"
>
  <div fxLayout="column" class="hide-show-subscript">
    <mat-form-field
      class="hide-empty-subscript eyes-maximize-width"
      data-testid="optimization-cockpit-mip-gap"
      appearance="outline"
      [@fadeIn]
    >
      <mat-label>
        {{ 'Scenario.labels.optimizationCockpit.mipGap' | translate }}
      </mat-label>
      <input
        matInput
        required
        type="number"
        [hasSkip]="false"
        [formControlName]="mipGapKey"
        [readonly]="isViewOnly || loading[mipGapKey]"
        [placeholder]="'Scenario.labels.optimizationCockpit.mipGap' | translate"
        scenarioUpdater
        [key]="mipGapKey"
        [view]="updateView"
        [mapType]="mapTypeString"
        [nextValue]="mipGap$ | ngrxPush"
        (statusChange)="setOptionLoading($event)"
        [spin]="false"
        data-testid="mipGap"
      />
      <mat-icon
        aria-hidden="false"
        class="eyes-input-tooltip-anchor"
        matSuffix
        [prosumerTooltip]="'wizard_optimization_cockpit.wizard_options_mip_gap'"
        data-testid="prosumer-options-mip-gap-tooltip"
      >
        help
      </mat-icon>
      <!-- <mat-error
        *ngFor="let error of form.get(mipGapKey)?.errors | keyvalue"
        [ngSwitch]="error.key"
      >
        <div
          *ngSwitchCase="'required'"
          [innerHtml]="'Scenario.messages.mipGap.required' | translate"
        ></div>
        <div
          *ngSwitchCase="'negativeValue'"
          [innerHtml]="'Scenario.messages.mipGap.negativeValue' | translate"
        ></div>
      </mat-error> -->
      <mat-error
        *ngFor="let error of form.get(mipGapKey)?.errors | keyvalue"
        [innerHtml]="'Scenario.messages.mipGap.' + error?.key | translate"
      ></mat-error>
    </mat-form-field>
    <mat-progress-bar
      color="accent"
      mode="indeterminate"
      *ngIf="loading[mipGapKey]"
    >
    </mat-progress-bar>
  </div>
  <div fxLayout="column" class="hide-show-subscript">
    <mat-form-field
      class="hide-empty-subscript eyes-maximize-width"
      data-testid="optimization-cockpit-limit-run-hour"
      appearance="outline"
      [@fadeIn]
    >
      <mat-label>
        {{ 'Scenario.labels.optimizationCockpit.limitRunHour' | translate }}
      </mat-label>
      <input
        matInput
        required
        type="number"
        [formControlName]="limitRunHourKey"
        [readonly]="isViewOnly || loading[limitRunHourKey]"
        [placeholder]="
          'Scenario.labels.optimizationCockpit.limitRunHour' | translate
        "
        scenarioUpdater
        [hasSkip]="false"
        [key]="limitRunHourKey"
        [view]="updateView"
        [mapType]="mapTypeString"
        [nextValue]="limitRunHour$ | ngrxPush"
        (statusChange)="setOptionLoading($event)"
        [spin]="false"
        data-testid="limitRunHour"
      />
      <mat-icon
        aria-hidden="false"
        class="eyes-input-tooltip-anchor"
        matSuffix
        [prosumerTooltip]="
          'wizard_optimization_cockpit.wizard_options_limit_run_hour'
        "
        data-testid="prosumer-options-limit-run-hour-tooltip"
      >
        help
      </mat-icon>
      <!-- <mat-error
        *ngFor="let error of form.get(limitRunHourKey)?.errors | keyvalue"
        [ngSwitch]="error.key"
      >
        <div
          *ngSwitchCase="'required'"
          [innerHtml]="'Scenario.messages.limitRunHour.required' | translate"
        ></div>
        <div
          *ngSwitchCase="'negativeValue'"
          [innerHtml]="
            'Scenario.messages.limitRunHour.negativeValue' | translate
          "
        ></div>
      </mat-error> -->
      <mat-error
        *ngFor="let error of form.get(limitRunHourKey)?.errors | keyvalue"
        [innerHtml]="'Scenario.messages.limitRunHour.' + error?.key | translate"
      ></mat-error>
    </mat-form-field>
    <mat-progress-bar
      color="accent"
      mode="indeterminate"
      *ngIf="loading[limitRunHourKey]"
    >
    </mat-progress-bar>
  </div>
  <prosumer-slide-toggle
    scenarioUpdater
    fxFlex="100%"
    class="show-subscript-margin"
    data-testid="preventSimultaneousCharge"
    data-testid="optimization-cockpit-prevent-simultaneous-charge"
    label="Scenario.labels.optimizationCockpit.preventSimultaneousCharge"
    [tooltipMessage]="
      'wizard_optimization_cockpit.wizard_options_simultaneous_charge'
    "
    [isViewOnly]="isViewOnly || loading[preventSimultaneousChargeKey]"
    [formControlName]="preventSimultaneousChargeKey"
    [nextValue]="preventSimultaneousCharge$ | ngrxPush"
    [key]="preventSimultaneousChargeKey"
    [mapType]="mapTypeBit"
    [view]="updateView"
    [spin]="false"
    (statusChange)="setOptionLoading($event)"
  ></prosumer-slide-toggle>
  <prosumer-slide-toggle
    scenarioUpdater
    fxFlex="100%"
    class="show-subscript-margin"
    data-testid="lcox"
    data-testid="optimization-cockpit-lcox"
    label="Scenario.labels.optimizationCockpit.lcox"
    [tooltipMessage]="'wizard_optimization_cockpit.wizard_options_lcox'"
    [isViewOnly]="isViewOnly || loading[lcoxKey]"
    [formControlName]="lcoxKey"
    [nextValue]="lcox$ | ngrxPush"
    [key]="lcoxKey"
    [mapType]="mapTypeBit"
    [view]="updateView"
    [spin]="false"
    (statusChange)="setOptionLoading($event)"
  ></prosumer-slide-toggle>
</div>
