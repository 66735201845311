import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Coerce } from 'prosumer-app/core/utils';

export class GeneralValidators {
  static get uniqueValueKey(): string {
    return 'valueNotUnique';
  }

  static get nonNegativeKey(): string {
    return 'negativeValue';
  }

  static uniqueValue<T>(existing: T[], key: keyof T): ValidatorFn {
    return (control: AbstractControl) =>
      GeneralValidators.isValueUnique(existing, control.value, key)
        ? null
        : { [GeneralValidators.uniqueValueKey]: true };
  }

  private static isValueUnique<T>(
    existing: T[],
    value: string,
    key: keyof T,
  ): boolean {
    return !Coerce.toArray(existing).find((existing) =>
      GeneralValidators.areValuesEqual(existing, value, key),
    );
  }

  private static areValuesEqual<T>(a: T, b: string, key: keyof T): boolean {
    return GeneralValidators.caseInsensitiveCompare(String(a[key]), b);
  }

  private static caseInsensitiveCompare(a: string, b: string): boolean {
    return a.toLowerCase() === b.toLowerCase();
  }

  static nonNegativeValue(): ValidatorFn {
    return (control: AbstractControl) =>
      control.value < 0 ? { [GeneralValidators.nonNegativeKey]: true } : null;
  }
}
