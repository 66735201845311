<div
  class="chart-controls"
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="16px"
>
  <button
    mat-icon-button
    class="mat-button hover-opacity"
    color="primary"
    (click)="handleToggle($event)"
  >
    <mat-icon
      aria-hidden="false"
      color="primary"
      *ngIf="legendShowing; else toggled"
      >legend_toggle</mat-icon
    >
    <ng-template #toggled>
      <mat-icon aria-hidden="false" color="">legend_toggle</mat-icon>
    </ng-template>
  </button>
  <button
    mat-icon-button
    class="mat-button hover-opacity"
    [matMenuTriggerFor]="menu"
    [attr.data-testid]="id + '-export-button'"
  >
    <mat-icon aria-hidden="false" mat-icon-button type="button" color="primary"
      >save_alt</mat-icon
    >
  </button>
  <mat-menu #menu="matMenu" [attr.data-testid]="id + '-export-menu'">
    <button
      mat-menu-item
      [attr.data-testid]="id + '-export-png'"
      [exportChart]="chartInstance"
      format="png"
      name="{{ scenarioName + '_' + resultsName }}"
      showLegend="{{ legendShowing }}"
    >
      Export as png
    </button>
    <button
      mat-menu-item
      [attr.data-testid]="id + '-export-svg'"
      [exportChart]="chartInstance"
      format="svg"
      name="{{ scenarioName + '_' + resultsName }}"
      showLegend="{{ legendShowing }}"
    >
      Export as svg
    </button>
    <button
      *ngIf="enableXlsxExport"
      mat-menu-item
      [attr.data-testid]="id + '-export-xlsx'"
      [exportChart]="chartInstance"
      [allData]="allData"
      format="xlsx"
      name="{{ scenarioName + '_' + resultsName }}"
      showLegend="{{ legendShowing }}"
      data-testid="export-xlsx"
    >
      Export as xlsx
    </button>
  </mat-menu>
</div>
