import { EventEmitter, OnInit, Output, Directive } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FilterLegendMarkers } from './filterable-legend.tokens';

type LegendStatus = { [name: string]: boolean };
@Directive()
export abstract class FilterableLegend implements OnInit {
  private legendStatus = new BehaviorSubject<LegendStatus>({});

  @Output() legendSelect = new EventEmitter<string>();

  filteredData$: Observable<unknown>;

  abstract getChartDataStream(): Observable<unknown[]>;
  abstract getLegendNames(data: unknown[]): string[];
  abstract mutateSingleChartDataBasedOnLegend<T>(single: T): T;

  ngOnInit(): void {
    this.filteredData$ = this.getLegendFilteredChartDataStream();
  }

  getLegendFilteredChartDataStream(): Observable<unknown[]> {
    return combineLatest([this.getChartDataStream(), this.legendStatus]).pipe(
      filter(([data]) => !!data),
      map(([data]) =>
        data.map((single) => this.mutateSingleChartDataBasedOnLegend(single)),
      ),
    );
  }

  onLegendSelect(data: unknown): void {
    if (typeof data === 'string') {
      this.toggleLegendStatus(data);
      this.legendSelect.emit(this.sanitizeNameFromMarkers(data));
    }
  }

  mutateNameBasedOnShowOrHide(name: string): string {
    const { show, hide } = FilterLegendMarkers;
    return `${this.shouldShowData(name) ? show : hide} ${name}`;
  }

  shouldShowData(name: string): boolean {
    const currValue = this.legendStatus.value;
    return !(name in currValue) || currValue[name];
  }

  private toggleLegendStatus(name: string): void {
    const currentValue = this.legendStatus.value;
    const sanitized = this.sanitizeNameFromMarkers(name);

    currentValue[sanitized] =
      sanitized in currentValue && !currentValue[sanitized];
    this.legendStatus.next(currentValue);
  }

  private sanitizeNameFromMarkers(name: string): string {
    const { REGEX } = FilterLegendMarkers;
    return name.replace(REGEX, '').trim();
  }
}
