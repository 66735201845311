import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
} from 'rxjs';

@Injectable()
export class GISCoordinatesService {
  private latitude = new BehaviorSubject<number | undefined>(undefined);
  private longitude = new BehaviorSubject<number | undefined>(undefined);
  private altitude = new BehaviorSubject<number | undefined>(undefined);

  latitude$: Observable<number>;
  longitude$: Observable<number>;
  altitude$: Observable<number>;
  coordinates$: Observable<[number, number]>;

  setLatitude(latitude: number): void {
    this.latitude.next(latitude);
  }

  setLongitude(longitude: number): void {
    this.longitude.next(longitude);
  }

  setAltitude(altitude: number): void {
    this.altitude.next(altitude);
  }

  constructor() {
    this.latitude$ = this.latitude.asObservable();
    this.longitude$ = this.longitude.asObservable();
    this.altitude$ = this.altitude.asObservable();
    this.coordinates$ = this.selectCoords();
  }

  private selectCoords(): Observable<[number, number]> {
    return combineLatest([this.longitude$, this.latitude$]).pipe(
      map(([long, lat]) => [long, lat]),
      filter((coords) => coords.every(Boolean)),
      distinctUntilChanged(),
      map((coords) => coords.map(Number) as [number, number]),
    );
  }
}
