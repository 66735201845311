import { BREAKPOINT, BreakPoint } from '@angular/flex-layout';

const CUSTOM_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'gt-cm1',
    suffix: 'GtCm1',
    mediaQuery: '(min-width: 1882px)',
    overlapping: true,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true,
};
