export const SECURITY_TOKEN = 'X-Amz-Security-Token';

export const HTTP_METHOD = {
  GET: 'GET',
};

export const MIME_TYPE = {
  APPLICATION: {
    JSON: 'application/json',
    OCTET_STREAM: 'application/octet-stream',
  },
  MULTI_PART: {
    FORM_DATA: 'multipart/form-data',
  },
};

export const RESPONSE_TYPE = {
  BLOB: 'blob',
  ARRAY_BUFFER: 'arraybuffer',
  JSON: 'json',
  TEXT: 'text',
};

export const AUTH_TYPE = {
  BEARER: 'Bearer',
};
