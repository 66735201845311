import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AUTH_CONFIG_TOKEN } from './auth.tokens';
import { LoginCallbackComponent } from './components/login-callback';
import { AuthGuard } from './guards';
import { AuthInterceptor, FileUploadInterceptor } from './interceptors';
import { AuthConfig } from './models';
import { AuthService } from './services';

@NgModule({
  declarations: [LoginCallbackComponent],
  exports: [LoginCallbackComponent],
})
export class AuthModule {
  static forRoot(config?: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AUTH_CONFIG_TOKEN, useValue: config },
        AuthService,
        AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: FileUploadInterceptor,
          multi: true,
        },
      ],
    };
  }
}
