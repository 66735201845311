import { Pipe, PipeTransform } from '@angular/core';
import { Color, ColorHelper, ScaleType } from '@swimlane/ngx-charts';

import { ChartSeriesData, ChartScheme, ChartTooltipData } from '../models';

@Pipe({
  name: 'chartTooltipDataList',
})
export class ChartTooltipDataListPipe<Y, X, V> implements PipeTransform {
  transform(
    seriesDataList: Array<ChartSeriesData<Y, X, V>>,
    currentTooltipData: ChartTooltipData<Y, X, V>,
    scheme?: Color,
  ): Array<ChartTooltipData<Y, X, V>> {
    if (!seriesDataList || !currentTooltipData) {
      return [];
    }

    const tooltipDataList: Array<ChartTooltipData<Y, X, V>> = [];

    seriesDataList.forEach((seriesData) => {
      if (!seriesData.series) {
        return;
      }
      const dataLookup = seriesData.series.find(
        (data) => data.name === currentTooltipData.name,
      );
      if (!!dataLookup) {
        tooltipDataList.push({
          series: seriesData.name,
          name: undefined,
          value: dataLookup.value,
          current: seriesData.name === currentTooltipData.series,
          color: scheme
            ? new ColorHelper(
                scheme,
                ScaleType.Ordinal,
                seriesDataList.map((d) => d.name) as string[],
              ).getColor(seriesData.name as any)
            : undefined,
        });
      }
    });

    return tooltipDataList;
  }
}
