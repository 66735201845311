import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective, PushPipe } from '@ngrx/component';
import { SharedModule } from 'prosumer-shared';
import { ScenarioStatusComponent } from './scenario-status.component';

@NgModule({
  declarations: [ScenarioStatusComponent],
  imports: [
    CommonModule,
    LetDirective,
    PushPipe,
    MatIconModule,
    MatButtonModule,
    SharedModule,
  ],
  exports: [ScenarioStatusComponent],
})
export class ScenarioStatusModule {}
