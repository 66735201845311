import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { FileInput } from '../models/index';
import {
  collectItemsWithKey,
  compareArrays,
  contains,
  getKeys,
  matchArrays,
} from './object.util';

const matchingFields =
  (controlName1: string, controlName2: string) => (group) => {
    const errorMessage = {
      matchingFieldsError: {
        isValid: false,
      },
    };
    if (!group.controls[controlName1] || !group.controls[controlName2]) {
      return errorMessage;
    }
    const value1 = group.controls[controlName1].value;
    const value2 = group.controls[controlName2].value;
    if (value1 !== value2) {
      return errorMessage;
    }
    return null;
  };

/**
 * Form control validator for text area that checks if the value exceeds the maximum lines or minimum lines
 *
 * @param maxLines the maximum number of lines
 * @param minLines the minimum number of lines (optional; default value: 1)
 *
 */
const textAreaLinesValidator =
  (maxLines: number, minLines: number = 1): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value && typeof control.value === 'string') {
      const values = control.value
        .split('\n')
        .filter((data) => data && data.trim() !== '');
      if (values && values.length > maxLines) {
        return { maxLines: { value: control.value } };
      }
      if (values && values.length < minLines) {
        return { minLines: { value: control.value } };
      }
    }
    return null;
  };

/**
 * Checks if any of the data from the source data list has a field with the control's value
 * Can provide other field names with corresponding form controls to be part of the validation checks
 *
 * @param sourceListData$ an observable of list data
 * @param dataField the field name of the data
 * @param skipValues a map of fields with values that will be skipped in the validation checks
 * @param otherFieldControls a list of field name-form control pairs
 *
 */
const dataExistValidator =
  <T>(
    sourceListData$: Observable<Array<T>>,
    dataField: string,
    skipValues?: { [field: string]: any },
    otherFieldControls?: { [field: string]: UntypedFormControl },
  ): AsyncValidatorFn =>
  (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
    if (!sourceListData$) {
      return of(null);
    }
    return sourceListData$.pipe(
      take(1),
      mergeMap((sourceData) => {
        if (control && control.value && typeof control.value === 'string') {
          let exist = false;
          if (otherFieldControls) {
            const fields = getKeys(otherFieldControls);
            if (skipValues) {
              sourceData = [...sourceData].filter(
                (data) =>
                  !(
                    data[dataField] === skipValues[dataField] &&
                    fields.every((field) => data[field] === skipValues[field])
                  ),
              );
            }
            exist = sourceData.some(
              (data) =>
                data[dataField] === control.value &&
                fields.every(
                  (field) =>
                    otherFieldControls[field] &&
                    data[field] === otherFieldControls[field].value,
                ),
            );
          } else {
            if (skipValues) {
              sourceData = [...sourceData].filter(
                (data) => !(data[dataField] === skipValues[dataField]),
              );
            }
            exist = sourceData.some(
              (data) =>
                data[dataField].toLowerCase() ===
                control.value.toLowerCase().trim(),
            );
          }
          if (exist) {
            return of({ dataExist: { value: control.value } });
          }
        }
        return of(null);
      }),
    );
  };

/**
 * Pairing checker for a source field and a node list field
 *
 * @param sourceListData$ - source form data observable
 * @param dataField - string name of source field data to check
 * @param skipValues a map of fields with values that will be skipped in the validation checks
 * @param nodeControl - node form control
 *
 */
const allNodeExistsValidator =
  <T>(
    sourceListData$: Observable<Array<T>>,
    dataField: string,
    skipValues?: { [field: string]: any },
    nodeControl?: { [field: string]: UntypedFormControl },
  ): AsyncValidatorFn =>
  (control: AbstractControl): Observable<{ [key: string]: any } | null> =>
    sourceListData$.pipe(
      take(1),
      mergeMap((sourceData) => {
        if (sourceData) {
          if (skipValues) {
            // on edit mode, source data is not part of validation
            sourceData = sourceData.filter((data) => data !== skipValues);
          }
          let exists = false;
          if (control && control.value && typeof control.value === 'string') {
            const fieldDict = {};
            for (const element of sourceData) {
              const key = element[dataField];
              fieldDict[key] = {
                nodes: element['nodes'],
              };
            }
            // when a source field has an existing paired node, the user
            // is no longer allowed to select All Nodes
            const sourceField = collectItemsWithKey(sourceData, dataField);
            if (nodeControl) {
              if (
                nodeControl.nodes.value.nodes &&
                nodeControl.nodes.value.nodes.length === 1 &&
                nodeControl.nodes.value.nodes[0] === 'ALL' &&
                contains(sourceField, control.value)
              ) {
                exists = true;
              }
            }
            // when a source field is already paired with All nodes, the user
            // is no longer allowed to add any node pair to it
            if (contains(sourceField, control.value)) {
              if (
                fieldDict &&
                fieldDict[control.value] &&
                fieldDict[control.value].nodes.length === 1 &&
                fieldDict[control.value].nodes[0] === 'ALL'
              ) {
                exists = true;
              }
              // when a certain node is already part of a source field's list of nodes
              if (
                fieldDict &&
                fieldDict[control.value] &&
                nodeControl.nodes.value.nodes &&
                matchArrays(
                  fieldDict[control.value].nodes,
                  nodeControl.nodes.value.nodes,
                )
              ) {
                exists = true;
              }
              // for single node
              if (
                fieldDict &&
                fieldDict[control.value] &&
                nodeControl.nodes.value &&
                !nodeControl.nodes.value.nodes &&
                matchArrays(
                  fieldDict[control.value].nodes,
                  nodeControl.nodes.value,
                )
              ) {
                exists = true;
              }
              // when a pair of field and node already exists
              if (
                fieldDict &&
                fieldDict[control.value] &&
                nodeControl.nodes.value.nodes &&
                compareArrays(
                  fieldDict[control.value].nodes,
                  nodeControl.nodes.value.nodes,
                )
              ) {
                exists = true;
              }
            }
          }
          if (exists) {
            return of({ allNodesApplied: { value: control.value } });
          }
        }
        return of(null);
      }),
    );

const mustBePositiveNumber = (control) => {
  const errorMessage = {
    mustBePositiveNumber: {
      valid: false,
    },
  };
  if (parseFloat(control.value) >= 0) {
    return null;
  }
  return errorMessage;
};

const mustBeInteger = (control) => {
  const errorMessage = {
    mustBeInteger: {
      valid: false,
    },
  };

  const villages = parseFloat(control.value);

  if (Number.isInteger(villages) && villages !== 0) {
    return null;
  }
  return errorMessage;
};

const mustBeNonNegativeInteger = (control) => {
  const errorMessage = {
    mustBeInteger: {
      valid: false,
    },
  };

  const num = parseFloat(control.value);

  if (Number.isInteger(num) && num >= 0) {
    return null;
  }
  return errorMessage;
};

const betweenZeroAndOne =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value) {
      const num = parseFloat(control.value);
      if (!(0 <= num && num <= 1)) {
        return { betweenZeroAndOne: { value: control.value } };
      }
    }
    return null;
  };

const maxCountReachedValidator =
  (maxCount: number, otherSourceCount: number = 0): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value && control.value instanceof Array) {
      const count = control.value.length;
      if (count + otherSourceCount > maxCount) {
        return { maxCount: { value: control.value } };
      }
    }
    return null;
  };

const invalidUserEmailUsage =
  (userEmail: string, inputEmail: any): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    inputEmail = [];
    control.value.forEach((data) => {
      inputEmail.push(data.email);
    });
    if (control && control.value && control.value instanceof Array) {
      if (inputEmail.includes(userEmail)) {
        return { emailUsageError: { value: inputEmail } };
      }
    }
    return null;
  };

const emailsValidator =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    // eslint-disable-next-line max-len
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control && control.value && control.value instanceof Array) {
      const invalid = control.value.find((email) => !emailRegex.test(email));
      if (invalid) {
        return { invalidEmails: { value: control.value } };
      }
    }
    return null;
  };

const phoneNumberValidator =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    if (control && control.value) {
      const invalid = !phoneRegex.test(control.value);
      if (invalid) {
        return { invalidPhoneNumber: { value: control.value } };
      }
    }
    return null;
  };

const numberValidator = (control) => {
  if (control && control.value) {
    const value = control.value.trim();
    if (Number(value) || parseFloat(value) === 0) {
      return null;
    }
    return { notANumber: { value: control.value } };
  }
};

const vatNumberValidator = (control) => {
  const vatNumRegex = /^[a-zA-Z][a-zA-Z][a-zA-Z0-9]+$/;
  if (control && control.value) {
    const invalid = !vatNumRegex.test(control.value);
    if (invalid) {
      return { invalidVATNumber: { value: control.value } };
    }
  }
  return null;
};

const singleEmailValidator = (control) => {
  // eslint-disable-next-line max-len
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (control && control.value) {
    const invalid = !emailRegex.test(control.value);
    if (invalid) {
      return { invalidEmail: { value: control.value } };
    }
  }
  return null;
};

const whiteSpaceValidator = (control) => {
  if (control && control.value) {
    const invalid = control.value.trim().length === 0;
    if (invalid) {
      return { required: { value: control.value } };
    }
  }
  return null;
};

/**
 * Checks if the load input is valid
 *
 * @param nodes list of node ids
 */
const isYearlyLoadValid =
  (nodes: string[]): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value) {
      nodes.map((n) => `n:${n.toLowerCase().trim()}`);
      const ctrl = control.value.toLowerCase().trim();
      // checks when load is a number
      if (parseFloat(ctrl) === 0) {
        return null;
      }

      if (!!parseFloat(ctrl)) {
        // when number is negative, return error
        if (parseFloat(ctrl) < 0) {
          return { mustBePositiveNumber: { value: control.value } };
        }
        return null;
      }
      // checks when load is string

      // if in id format, ignore
      if (String(ctrl).startsWith('n:')) {
        return null;
      }

      // if not found in list of node ids, return error
      if (nodes.includes(ctrl)) {
        return null;
      }
      return { invalidNode: { value: control.value } };
    }
    return null;
  };

const fileValidator =
  (
    validExtensions?: Array<string>,
    minFileSize?: number,
    maxFileSize?: number,
  ): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value) {
      const fileInput: FileInput = control.value;

      let invalidFile = false;
      let invalidExtension = false;
      let isLessMinFileSize = false;
      let isMoreMaxFileSize = false;

      fileInput.files.forEach((file) => {
        if (file.size <= 0) {
          invalidFile = true;
        }

        if (validExtensions && validExtensions.length) {
          const split_ends = file.name.split('.');
          const ext = split_ends[split_ends.length - 1];
          if (validExtensions.indexOf(ext) <= -1) {
            invalidExtension = true;
          }
        }

        if (minFileSize && file.size < minFileSize) {
          isLessMinFileSize = true;
        }

        if (maxFileSize && file.size > minFileSize) {
          isMoreMaxFileSize = true;
        }
      });

      if (invalidFile) {
        return {
          invalidFile: {
            value: control.value,
          },
        };
      }

      if (invalidExtension) {
        return {
          invalidExtension: {
            value: control.value,
            validExtensions,
          },
        };
      }

      if (isLessMinFileSize) {
        return {
          minFileSize: {
            value: control.value,
            minFileSize,
          },
        };
      }

      if (isMoreMaxFileSize) {
        return {
          maxFileSize: {
            value: control.value,
            maxFileSize,
          },
        };
      }
    }
    return null;
  };

const minArrayValueLength =
  (min: number): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control &&
      control.value &&
      control.value instanceof Array &&
      control.value.length < min
    ) {
      return { min: control.value };
    }
    return null;
  };

/**
 * Validator that is invalid if the the control value is the same as the comparison control parameter's value. The value should be defined
 * (not undefined, null, or false).
 *
 * @param comparisonControl - the control to compare the value
 */
const sameValue =
  (comparisonControl: AbstractControl): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (
      !!comparisonControl &&
      !!control &&
      !!control.value &&
      comparisonControl.value === control.value
    ) {
      return { sameValue: control.value };
    }
    return null;
  };

/**
 * Checks for invalid input character value. Return invalid if char is found in stringToBeChecked.
 *
 */
const invalidCharacterValidator =
  (char: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    let isInvalid = false;
    if (control && control.value) {
      const i = control.value.indexOf(char);
      if (i !== -1) {
        isInvalid = true;
      }
    }
    return isInvalid ? { invalidCharacter: { value: control.value } } : null;
  };

/**
 * Checks for an invalid value of string
 *
 * @param strings - list of invalid strings
 */
const invalidStringValidator =
  (strings: Array<string>): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    let isInvalid = false;
    if (control && control.value) {
      strings.forEach((str) => {
        if (
          control.value.toLowerCase().trim() === str.toLocaleLowerCase().trim()
        ) {
          isInvalid = true;
          return;
        }
      });
    }
    return isInvalid ? { invalidString: { value: control.value } } : null;
  };

export const CustomValidators = {
  textAreaLines: textAreaLinesValidator,
  dataExist: dataExistValidator,
  matchingFields,
  mustBePositiveNumber,
  mustBeInteger,
  mustBeNonNegativeInteger,
  betweenZeroAndOne,
  file: fileValidator,
  maxCountReached: maxCountReachedValidator,
  emails: emailsValidator,
  emailUsage: invalidUserEmailUsage,
  minArrayValueLength,
  phoneNumber: phoneNumberValidator,
  numberValidator,
  vatNumberValidator,
  whiteSpaceValidator,
  singleEmailValidator,
  sameValue,
  invalidCharacterValidator,
  allNodeExistsValidator,
  invalidStringValidator,
  isYearlyLoadValid,
};
