import { Component, Input, OnInit } from '@angular/core';

type Position = 'left' | 'center' | 'right';

@Component({
  selector: 'prosumer-tooltip-anchor',
  templateUrl: './tooltip-anchor.component.html',
  styleUrls: ['./tooltip-anchor.component.scss'],
})
export class TooltipAnchorComponent implements OnInit {
  @Input() message: string;
  @Input() tooltipPosition: Position = 'right';
  @Input() tooltipIconSize = '14px';
  @Input() marginTop = 8;
  @Input() outlined: boolean;
  @Input() color: string;
  _message: any;
  constructor() {}

  ngOnInit() {}
}
