import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type JSONLike = Record<string, unknown>;
type Manifest = Record<string, JSONLike>;

@Injectable({ providedIn: 'root' })
export class DiagramPositioner {
  private overviews: BehaviorSubject<Manifest> = new BehaviorSubject(
    this.getOverviewsFromLocalStorage(),
  );
  private nodeViews: BehaviorSubject<Manifest> = new BehaviorSubject(
    this.getNodeViewsFromLocalStorage(),
  );

  constructor() {
    this.subscribeToOverviewsForLocalStorageSync();
    this.subscribeToNodeViewsForLocalStorageSync();
  }

  storeOverview(
    scenario: string,
    variationId: string,
    position: JSONLike,
  ): void {
    this.overviews.next({
      ...this.overviews.value,
      [`${scenario}-${variationId}`]: position,
    });
  }

  getOverview(scenarioId: string, variationId: string): JSONLike {
    return this.overviews.value[`${scenarioId}-${variationId}`];
  }

  storeNodeView(
    scenario: string,
    variationId: string,
    node: string,
    position: JSONLike,
  ): void {
    this.nodeViews.next({
      ...this.nodeViews.value,
      [this.buildNodeViewKey(scenario, variationId, node)]: position,
    });
  }

  getNodeView(
    scenarioId: string,
    variationId: string,
    nodeId: string,
  ): JSONLike {
    return this.nodeViews.value[
      this.buildNodeViewKey(scenarioId, variationId, nodeId)
    ];
  }

  private getOverviewsFromLocalStorage(): Manifest {
    return this.resolveToEmptyObj(
      JSON.parse(localStorage.getItem('overviewPositions')),
    );
  }

  private getNodeViewsFromLocalStorage(): Manifest {
    return this.resolveToEmptyObj(
      JSON.parse(localStorage.getItem('nodeViewPositions')),
    );
  }

  private resolveToEmptyObj(alleged: Manifest): Manifest {
    return alleged || {};
  }

  private buildNodeViewKey(
    scenario: string,
    variationId: string,
    node: string,
  ): string {
    return `${scenario}-${variationId}-${node}`;
  }

  private subscribeToOverviewsForLocalStorageSync(): void {
    this.overviews.subscribe((overviews) => {
      localStorage.setItem('overviewPositions', JSON.stringify(overviews));
    });
  }

  private subscribeToNodeViewsForLocalStorageSync(): void {
    this.nodeViews.subscribe((nodeViews) => {
      localStorage.setItem('nodeViewPositions', JSON.stringify(nodeViews));
    });
  }
}
