import { Utils } from 'prosumer-core';

import {
  EmissionResult,
  MAIN_OUTPUT_KEYS,
  MainCapexResult,
  MainOpexResult,
  MainOutputResult,
  SizingAndCostResult,
  SizingResult,
  YearlyCapexOpexResult,
  YearlyCapexResult,
  YearlyOpexResult,
} from '../models/main-output-results.model';
import { BaseExtractor } from './';

export class MainOutputExtractor implements BaseExtractor<MainOutputResult> {
  extract(resultsJson: unknown): MainOutputResult[] {
    return [
      this.extractMainOutput(
        Utils.resolveToEmptyObject(resultsJson['Main_outputs']),
      ),
    ];
  }

  private extractMainOutput(json: unknown): MainOutputResult {
    const capexOpex = this.extractAllYearCost(
      Utils.resolveToEmptyObject(json[MAIN_OUTPUT_KEYS.ALL_YEARS]),
    );
    const output = {
      opex: capexOpex.opex,
      capex: capexOpex.capex,
      emissions: this.extractAllYearsEmission(
        Utils.resolveToEmptyObject(json[MAIN_OUTPUT_KEYS.ALL_YEARS]),
      ),
      firstYear: this.exractSizingAndCost(
        Utils.resolveToEmptyObject(json[MAIN_OUTPUT_KEYS.FIRST_YEAR]),
      ),
      lastYear: this.exractSizingAndCost(
        Utils.resolveToEmptyObject(json[MAIN_OUTPUT_KEYS.LAST_YEAR]),
      ),
      total: this.exractSizingAndCost(
        Utils.resolveToEmptyObject(json[MAIN_OUTPUT_KEYS.TOTAL]),
      ),
    };
    return output;
  }

  private extractAllYearCost(json: unknown): YearlyCapexOpexResult {
    const result = {} as YearlyCapexOpexResult;
    const capex = {};
    const opex = {};
    if (!json['total_cost']) {
      return result;
    }
    for (const [yearKey, costObj] of Object.entries(
      Utils.resolveToEmptyObject(json['total_cost']) || {},
    )) {
      capex[yearKey] = costObj['CAPEX'];
      opex[yearKey] = costObj['OPEX'];
    }
    result.capex = capex as YearlyCapexResult;
    result.opex = opex as YearlyOpexResult;
    return result;
  }

  private extractAllYearsEmission(json: unknown): EmissionResult[] {
    const result = [];
    for (const [key, value] of Object.entries(json['total_emissions'])) {
      result.push({
        year: key,
        values: Object.entries(value).map(([scope, data]) => ({
          name: scope,
          value: data,
        })),
      });
    }
    return result;
  }

  private exractSizingAndCost(json: unknown): SizingAndCostResult {
    const sizing = this.createSizing(json);
    const opex = this.createOpex(json);
    const capex = this.createCapex(json);
    return { sizing, opex, capex };
  }

  private createSizing(json: unknown): SizingResult[] {
    const result = [];
    for (const [key, value] of Object.entries(
      Utils.resolveToEmptyObject(json['sizes']),
    )) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      result.push({
        name: value['name'],
        size_kw: value['size_kw'],
      } as SizingResult);
    }
    return result;
  }

  private createCapex(json: unknown): MainCapexResult[] {
    const result = [];
    for (const [key, value] of Object.entries(
      Utils.resolveToEmptyObject(json['capex']),
    )) {
      result.push({
        name: value['name'],
        capex: value['capex'],
      } as MainCapexResult);
    }
    return result;
  }

  private createOpex(json: unknown): MainOpexResult[] {
    const result = [];
    for (const [key, value] of Object.entries(
      Utils.resolveToEmptyObject(json['opex']),
    )) {
      result.push({
        name: value['name'],
        opex: value['opex'],
      } as MainOpexResult);
    }
    return result;
  }
}
