<prosumer-page>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header
      collapsedHeight="35px"
      expandedHeight="35px"
      [attr.data-testid]="'wind-profile-expansion-panel'"
    >
      <mat-panel-title data-testid="wind-profile-title">
        {{ 'RenewableProfile.labels.windProfile' | translate }}
        <prosumer-tooltip-anchor
          [message]="'renewable_profile.wind_profile_section'"
          [attr.data-testid]="'renewable-profile-wind-profile-tooltip'"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
        [formGroup]="windForm"
      >
        <div
          fxFlex="70%"
          [ngClass]="{
            'error-class': windForm?.controls.turbineName.errors
          }"
        >
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{ 'Wind Turbine' }}</mat-label>
            <input
              type="text"
              matInput
              [formControl]="windForm?.controls.turbineName"
              [matAutocomplete]="auto"
              [attr.data-testid]="'wind-profile-turbine-input'"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="'renewable_profile.wind_turbine'"
              [attr.data-testid]="'renewable-profile-wind-turbine-tooltip'"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-error
            *ngIf="customErrorMessage$ | async"
            [innerHtml]="customErrorMessage$.getValue()"
          ></mat-error>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let data of turbineOptions$ | async"
            [value]="data?.name || data"
            >{{ data.name }}</mat-option
          >
        </mat-autocomplete>

        <prosumer-input
          fxFlex="30%"
          type="number"
          [placeholder]="'RenewableProfile.labels.height' | translate"
          [label]="'RenewableProfile.labels.height' | translate"
          [control]="windForm?.controls?.hubHeight"
          [errorMessageMap]="errorMessages['hubHeight']"
          [inputTooltip]="'renewable_profile.wind_height'"
          [attr.data-testid]="'wind-profile-height'"
        >
        </prosumer-input>
      </div>

      <p class="mat-caption">
        Click
        <a
          class="eyes-href-bold"
          [href]="TURBINES_LIST_URL"
          target="_blank"
          [attr.data-testid]="'wind-turbine-list'"
          >here</a
        >
        to see available turbines list.
      </p>

      <prosumer-profile-computation-option
        (computation)="computationHandler($event)"
      ></prosumer-profile-computation-option>

      <mat-error *ngIf="clickedSubmitted$ | async">
        <div
          *ngIf="
            !windForm?.controls?.longitude.valid ||
            !windForm?.controls?.latitude.valid
          "
          [innerHtml]="
            'RenewableProfile.messages.location.required' | translate
          "
        ></div>
        <div
          *ngIf="!windForm?.controls?.historicalYears.valid"
          [innerHtml]="
            'RenewableProfile.messages.listOfYears.required' | translate
          "
        ></div>
        <div
          *ngIf="!windForm?.controls?.numberOfYears.valid"
          [innerHtml]="
            'RenewableProfile.messages.numberOfYears.required' | translate
          "
        ></div>
        <div
          *ngIf="!windForm?.controls?.lastHistoricalYear.valid"
          [innerHtml]="
            'RenewableProfile.messages.lastHistoricalYear.required' | translate
          "
        ></div>
        <div
          *ngIf="!windForm?.controls?.percentiles.valid"
          [innerHtml]="
            'RenewableProfile.messages.listOfPercentiles.required' | translate
          "
        ></div>
      </mat-error>

      <div fxLayout="column" fxLayoutAlign="end end" fxLayoutGap="10px">
        <button
          class="eyes-button"
          color="primary"
          mat-flat-button
          (click)="onSubmit()"
          [disabled]="windForm?.pristine"
          [@fadeIn]
          [attr.data-testid]="'wind-profile-submit'"
        >
          {{ 'Submit' }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</prosumer-page>
