import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseDialogComponent,
  contains,
  getDeletedItems,
} from 'prosumer-app/libs/eyes-shared';
import {
  cascadeFilterLimitsByConnections,
  cascadeFilterNettingByEquipment,
  cascadeFilterTaxAndSubsidiesByNetting,
  getAffectedEnergyGridEmissions,
  getAffectedEnergyGridLimits,
  getAffectedTaxAndSubsidies,
  getBinsToDeleteOnDelete,
  getUpdatedStationVehicleAssoc,
} from 'prosumer-shared';
import { Observable, of } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  Converter,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  EnergyVector,
  Equipment,
  EquipmentCircuitInfo,
  EquipmentReserve,
  EQUIPS_WITH_BIN,
  Fuel,
  Generator,
  Line,
  Load,
  MarketReserve,
  Metering,
  Netting,
  RenewableEnergy,
  Routes,
  Scenario,
  Station,
  StationVehicleAssoc,
  Storage,
  TaxAndSubsidies,
  Vehicle,
  VehiclesDispatch,
} from '../models';

@Injectable()
export class NodeCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}
  // prettier-ignore
  generateAffectedMessage([
    equipments, fuels, energyGrids, lines, loads, nodeName, energyVectors, selectedNettings, selectedEnergyGrids, routes, vehicles,
    stationVehicleAssocs, connectionsEmissions, energyGridLimits, nettings, taxAndSubsidies
  ]: [
      Array<Equipment>,
      Array<Fuel>,
      Array<EnergyGridConnection>,
      Array<Line>,
      Array<Load>,
      string,
      Array<EnergyVector>,
      Array<Netting>,
      Array<EnergyGridConnection>,
      Array<Routes>,
      Array<VehiclesDispatch>,
      Array<StationVehicleAssoc>,
      Array<EnergyGridConnectionsEmissions>,
      Array<EnergyGridLimit>,
      Array<Netting>,
      Array<TaxAndSubsidies>,
    ]) {
    const affectedEquipments = equipments
      .map((equipment) => equipment.name)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedFuels = fuels
      .map((fuel) => {
        const energyVectorName = energyVectors.find((energyVector) => energyVector.value === fuel.fuel).name;
        return `Energy Vector: ${this._titleCase.transform(energyVectorName)}`;
      })
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedEnergyGrids = energyGrids
      .map((energyGrid) => energyGrid.marketName)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedConnectionsEmissions = getAffectedEnergyGridEmissions(connectionsEmissions, selectedEnergyGrids);
    const affectedEnergyGridLimits = getAffectedEnergyGridLimits(energyGridLimits, selectedEnergyGrids);
    const affectedLines = lines.map((line) => line.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedLoads = loads.map((load) => load.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');

    const affectedRoutes = routes
      .map((route) => route.name)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedVehicles = vehicles
      .map((vehicle) => vehicle.vehicleName)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    // eslint-disable-next-line max-len
    const affectedStationVehicleAssocs = (stationVehicleAssocs || []).length > 0 ? `<li>${stationVehicleAssocs.length} Station/Vehicle Associations </li>` : '';
    const affectedNettings = nettings.map((netting) => netting.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedtaxAndSubsidies = getAffectedTaxAndSubsidies(taxAndSubsidies, selectedNettings);

    if (
      affectedEquipments.length === 0 &&
      affectedFuels.length === 0 &&
      affectedEnergyGrids.length === 0 &&
      affectedLines.length === 0 &&
      affectedLoads.length === 0 &&
      affectedRoutes.length === 0 &&
      affectedVehicles.length === 0 &&
      affectedStationVehicleAssocs.length === 0 &&
      affectedConnectionsEmissions.length === 0 &&
      affectedEnergyGridLimits.length === 0 &&
      affectedNettings.length === 0 &&
      affectedtaxAndSubsidies.length === 0
    ) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data - ${nodeName}</b>` +
      `${affectedEquipments.length > 0 ? `<br>Equipments:<br><ul>${affectedEquipments}</ul>` : ''}` +
      `${affectedFuels.length > 0 ? `<br>Commodities - Fuels:<br><ul>${affectedFuels}</ul>` : ''}` +
      `${affectedEnergyGrids.length > 0
        ? `<br>Commodities - Energy Grid Connections:<br><ul>${affectedEnergyGrids}</ul>`
        : ''
      }` +
      `${affectedConnectionsEmissions.length > 0
        ? `<br>Commodities - Energy Grid Connections Emissions:<br><ul>${affectedConnectionsEmissions}</ul>`
        : ''
      }` +
      `${affectedEnergyGridLimits.length > 0 ? `<br>Commodities - Market Limits:<br><ul>${affectedEnergyGridLimits}</ul>` : ''}` +
      `${affectedLines.length > 0 ? `<br>Topology - Lines:<br><ul>${affectedLines}</ul>` : ''}` +
      `${affectedLoads.length > 0 ? `<br>Loads:<br><ul>${affectedLoads}</ul>` : ''}` +
      `${affectedRoutes.length > 0 ? `<br>Routes:<br><ul>${affectedRoutes}</ul>` : ''}` +
      `${affectedVehicles.length > 0 ? `<br>Mobility - Vehicles Dispatch:<br><ul>${affectedVehicles}</ul>` : ''
      }` +
      `${affectedStationVehicleAssocs.length > 0
        ? `<br>Mobility - Station/Vehicle Association:<br><ul>${affectedStationVehicleAssocs}</ul>`
        : ''
      }` +
      `${affectedNettings.length > 0 ? `<br>Netting:<br><ul>${affectedNettings}</ul>` : ''}` +
      `${affectedtaxAndSubsidies.length > 0 ? `<br>Tax & Subsidies:<br><ul>${affectedtaxAndSubsidies}</ul>` : ''}` +
      `</div>`;
    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );

    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  showDeletedDialog(
    deletedNodeNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.node.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.node.message',
        ),
        deletedNodeNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  cascadeNodeDeletion(
    scenario: Scenario,
    deletedNodeIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }

    const equipments = ((scenario || {}).equipments || {}).equipments;
    const fuels = ((scenario || {}).commodities || {}).fuels;
    const energyGridConnections = ((scenario || {}).commodities || {}).grids;
    const connectionsEmissions = ((scenario || {}).commodities || {})
      .connectionsEmissions;
    const energyGridLimits = ((scenario || {}).commodities || {}).limits;
    const lines = ((scenario || {}).topology || {}).lines;
    const loads = ((scenario || {}).loads || {}).loads;
    const meterings = ((scenario || {}).regulations || {}).meterings;
    const _frequencyControl = (scenario || {}).frequencyControl || {};
    const shortEquipments =
      (_frequencyControl.shortCircuit || {}).equipments || [];
    const _spinningReserve = _frequencyControl.spinningReserve || {};
    const equipmentReserves = _spinningReserve.equipments || [];
    const marketReserves = _spinningReserve.markets || [];
    const routes =
      (((scenario || {}).mobility || {}).routes || {}).routes || [];
    const vehicles = ((scenario || {}).mobility || {}).vehicles;
    const stationVehiclesAssoc = ((scenario || {}).mobility || {})
      .stationVehicleAssoc;
    const nettings = ((scenario || {}).netting || {}).netting;
    const taxAndSubsidies = ((scenario || {}).netting || {}).taxAndSubsidies;

    const cascadedEquipments = this.filterEquipments(
      equipments,
      deletedNodeIds,
    );
    const cascadedFuels = this.filterFuels(fuels, deletedNodeIds);
    const cascadedEnergyGridConnections = this.filterEnergyGridConnections(
      energyGridConnections,
      deletedNodeIds,
    );
    const cascadedConnectionsEmissions = this.filterConnectionsEmissions(
      connectionsEmissions,
      deletedNodeIds,
    );
    let cascadedEnergyGridLimits = this.filterEnergyGridLimits(
      energyGridLimits,
      deletedNodeIds,
    );
    cascadedEnergyGridLimits = cascadeFilterLimitsByConnections(
      cascadedEnergyGridLimits,
      cascadedEnergyGridConnections.map((connection) => connection.id),
    );
    const cascadedLines = this.filterLines(lines, deletedNodeIds);
    const cascadedLoads = this.filterLoads(loads, deletedNodeIds);
    const cascadedMeterings = this.filterMeterings(
      meterings,
      cascadedEquipments,
    );
    const cascadedEquipServes = this.filterEquipmentReserves(
      equipmentReserves,
      cascadedEquipments,
    );
    const cascadedMarketServes = this.filterMarketReserves(
      marketReserves,
      cascadedEnergyGridConnections,
    );
    const cascadedShortEquipments = this.filterShortEquips(
      shortEquipments,
      cascadedEquipments,
    );
    const cascadedRoutes = this.filterRoutes(routes, deletedNodeIds);
    const cascadedVehicles = this.filterVehicles(
      vehicles,
      cascadedEquipments,
      cascadedRoutes,
    );
    const cascadedStationVehiclesAssoc = this.filterStationVehiclesAssocs(
      stationVehiclesAssoc,
      vehicles,
      cascadedVehicles,
      cascadedEquipments,
      deletedNodeIds,
    );
    let cascadedNettings = this.filterNettings(nettings, deletedNodeIds);
    cascadedNettings = cascadeFilterNettingByEquipment(
      cascadedNettings,
      cascadedEquipments.map((equipment) => equipment.id),
    );
    let cascadedTaxAndSubsidies = this.filterTaxAndSubsidies(
      taxAndSubsidies,
      deletedNodeIds,
    );
    cascadedTaxAndSubsidies = cascadeFilterTaxAndSubsidiesByNetting(
      cascadedTaxAndSubsidies,
      cascadedNettings.map((netting) => netting.id),
    );

    return {
      ...scenario,
      equipments: {
        ...scenario.equipments,
        equipments: cascadedEquipments,
        binToDelete: [].concat(
          ...equipments
            .filter((equipment) => !contains(cascadedEquipments, equipment))
            .map((equipment) => {
              let toDelete = [];
              if (EQUIPS_WITH_BIN.includes(equipment.type)) {
                toDelete.push(
                  getBinsToDeleteOnDelete(undefined, equipment.profiles),
                );
              }
              toDelete = toDelete.concat(
                [].concat(
                  ...(equipment['operatingCostProfiles'] || []).map(
                    (costProfile) => costProfile.profiles,
                  ),
                ),
              );
              return toDelete;
            }),
        ),
      },
      commodities: {
        ...scenario.commodities,
        fuels: cascadedFuels,
        grids: cascadedEnergyGridConnections,
        connectionsEmissions: cascadedConnectionsEmissions,
        limits: cascadedEnergyGridLimits,
        binToDelete: [].concat.apply(
          [],
          [
            energyGridConnections
              .filter((grid) => !contains(cascadedEnergyGridConnections, grid))
              .map((grid) => getBinsToDeleteOnDelete(undefined, grid.profiles)),
            connectionsEmissions
              .filter(
                (emission) => !contains(cascadedConnectionsEmissions, emission),
              )
              .map((emission) =>
                getBinsToDeleteOnDelete(undefined, emission.profiles),
              ),
          ],
        ),
      },
      topology: {
        ...scenario.topology,
        lines: cascadedLines,
      },
      loads: {
        ...scenario.loads,
        loads: cascadedLoads,
        binToDelete: [].concat(
          ...loads
            .filter((load) => !contains(cascadedLoads, load))
            .map((load) => getBinsToDeleteOnDelete(undefined, load.profiles)),
        ),
      },
      regulations: {
        ...scenario.regulations,
        meterings: cascadedMeterings,
      },
      frequencyControl: {
        shortCircuit: {
          ...scenario.frequencyControl.shortCircuit,
          equipments: cascadedShortEquipments,
        },
        spinningReserve: {
          ...scenario.frequencyControl.spinningReserve,
          equipments: cascadedEquipServes,
          markets: cascadedMarketServes,
        },
      },
      mobility: {
        ...scenario.mobility,
        routes: {
          routes: cascadedRoutes,
          binToDelete: [].concat(
            ...routes
              .filter((route) => !contains(cascadedRoutes, route))
              .map((route) =>
                getBinsToDeleteOnDelete(undefined, route.profileRoutes),
              ),
          ),
        },
        vehicles: cascadedVehicles,
        stationVehicleAssoc: cascadedStationVehiclesAssoc,
      },
      netting: {
        netting: cascadedNettings,
        taxAndSubsidies: cascadedTaxAndSubsidies,
      },
    };
  }

  /** Return the nettings with remaining nodes that are not part of the deletedNodeIds */
  filterNettings(nettings: Array<Netting>, deletedNodeIds: Array<string>) {
    return nettings
      ? this.removeAssociationToDeletedNodes(nettings, deletedNodeIds)
      : undefined;
  }

  filterTaxAndSubsidies(
    taxAndSubsidies: Array<TaxAndSubsidies>,
    deletedNodeIds: Array<string>,
  ) {
    return taxAndSubsidies
      ? this.removeAssociationToDeletedNodes(taxAndSubsidies, deletedNodeIds)
      : undefined;
  }

  filterShortEquips = (
    equipments: EquipmentCircuitInfo[],
    savedEquips: Equipment[],
  ) =>
    equipments.filter((equip) =>
      contains(
        savedEquips.map((_e) => _e.id),
        equip.equipmentId,
      ),
    );

  filterEquipmentReserves = (
    reserves: EquipmentReserve[],
    savedEquips: Equipment[],
  ) =>
    (reserves || []).filter((reserve) =>
      contains(
        savedEquips.map((equip) => equip.id),
        reserve.equipmentId,
      ),
    );

  filterMarketReserves = (
    reserves: MarketReserve[],
    savedMarkets: EnergyGridConnection[],
  ) =>
    reserves.filter((reserve) =>
      contains(
        savedMarkets.map((market) => market.id),
        reserve.energyGridConnectionId,
      ),
    );

  filterMeterings = (
    meterings: Array<Metering>,
    savedEquips: Array<Equipment>,
  ) =>
    (meterings || []).filter((metering) =>
      savedEquips.map((equip) => equip.id).includes(metering.technologyId),
    );

  filterEquipments(
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    deletedNodeIds: Array<string>,
  ) {
    if (!!!equipments) {
      return undefined;
    }
    return equipments.filter((equipment) => {
      /* Vehicle equipment does not have nodes */
      if (!!!equipment.nodes) {
        return true;
      }
      return equipment.nodes.some((node) => !contains(deletedNodeIds, node));
    });
  }

  filterFuels(fuels: Array<Fuel>, deletedNodeIds: Array<string>) {
    if (!!!fuels) {
      return undefined;
    }
    return fuels.filter(
      (fuel) =>
        getDeletedItems(
          fuel.nodes.map((node) => this.transformToObj(node)),
          deletedNodeIds.map((delNode) => this.transformToObj(delNode)),
        ).length > 0,
    );
  }

  /**
   * Transform the given node to { id: node}.
   *
   * @param node given node id,
   */
  transformToObj = (node: string) => {
    const nodeO = {};
    nodeO['id'] = node;
    return nodeO;
  };

  /**
   * This method retrieves all the remaining EnergyGridConnection data based on the given selected nodes.
   *
   * @param energyGridConnections list of be filtered.
   * @param deletedNodeIds list deleted nodes.
   */
  filterEnergyGridConnections(
    energyGridConnections: Array<EnergyGridConnection>,
    deletedNodeIds: Array<string>,
  ) {
    if (!!!energyGridConnections) {
      return undefined;
    }

    return energyGridConnections.filter(
      (energyGridConnection) =>
        getDeletedItems(
          energyGridConnection.nodes.map((node) => this.transformToObj(node)),
          deletedNodeIds.map((delNode) => this.transformToObj(delNode)),
        ).length > 0,
    );
  }

  filterConnectionsEmissions(
    connectionsEmissions: Array<EnergyGridConnectionsEmissions>,
    deletedNodeIds: Array<string>,
  ) {
    if (!!!connectionsEmissions) {
      return undefined;
    }

    const conn = connectionsEmissions.filter(
      (emission) =>
        getDeletedItems(
          [emission.node].map((node) => this.transformToObj(node)),
          deletedNodeIds.map((deletedNode) => this.transformToObj(deletedNode)),
        ).length > 0,
    );
    return conn;
  }

  filterEnergyGridLimits(
    energyGridLimits: Array<EnergyGridLimit>,
    deletedNodeIds: Array<string>,
  ) {
    return energyGridLimits
      ? this.removeAssociationToDeletedNodes(energyGridLimits, deletedNodeIds)
      : undefined;
  }

  filterLines(lines: Array<Line>, deletedNodeIds: Array<string>) {
    if (!!!lines) {
      return undefined;
    }
    return lines.filter(
      (line) =>
        !contains(deletedNodeIds, line.originNodeId) &&
        !contains(deletedNodeIds, line.destinationNodeId),
    );
  }

  filterLoads(loads: Array<Load>, deletedNodeIds: Array<string>) {
    if (!!!loads) {
      return loads;
    }
    return loads.filter((load) =>
      load.nodes.some((node) => !contains(deletedNodeIds, node)),
    );
  }

  filterRoutes(routes: Array<Routes>, deletedNodeIds: Array<string>) {
    if (!!!routes) {
      return routes;
    }
    return routes.filter(
      (route) =>
        route.profileRoutes.filter(
          (profile) =>
            !profile.nodes.some((node) => contains(deletedNodeIds, node)),
        ).length > 0,
    );
  }

  filterVehicles(
    vehicles: Array<VehiclesDispatch>,
    cascadedEquipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Equipment
      | Vehicle
      | Station
    >,
    cascadedRoutes: Array<Routes>,
  ) {
    if ((!!!cascadedEquipments && !!!cascadedRoutes) || !!!vehicles) {
      return undefined;
    }
    const filteredVehicle = vehicles.filter((vehicle) =>
      cascadedEquipments.map((delEq) => delEq.id).includes(vehicle.vehicleId),
    );
    return filteredVehicle.filter(
      (vehicle) =>
        vehicle.routeIds.filter(
          (routeId) =>
            !cascadedRoutes.map((detRts) => detRts.id).includes(routeId),
        ).length === 0,
    );
  }

  filterStationVehiclesAssocs(
    stationVehicleAssocs: Array<StationVehicleAssoc>,
    currentVehicles: Array<VehiclesDispatch>,
    remainingVehicles: Array<VehiclesDispatch>,
    cascadedEquipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Equipment
      | Vehicle
      | Station
    >,
    deletedNodeIds: Array<string>,
  ) {
    if (!!!cascadedEquipments || !!!stationVehicleAssocs || !!!deletedNodeIds) {
      return undefined;
    }

    const remainingVehiclesIds = remainingVehicles.map((vehicle) => vehicle.id);
    const updatedAssoc: StationVehicleAssoc[] = getUpdatedStationVehicleAssoc(
      stationVehicleAssocs,
      currentVehicles,
      remainingVehicles,
    );

    const remainingEquipments = cascadedEquipments.map((eq) => eq.id);

    return updatedAssoc
      .filter(
        (assoc) =>
          assoc.vehicleNames.filter(
            (vehicleName) => !contains(remainingVehiclesIds, vehicleName),
          ).length === 0,
      )
      .filter((assoc) => contains(remainingEquipments, assoc.stationId))
      .filter(
        (assoc) =>
          assoc.stationNodes.filter((node) => contains(deletedNodeIds, node))
            .length === 0,
      );
  }

  filterNettingByEquipmentNodes(
    nettingArr: Array<Netting>,
    equipments: Array<Equipment>,
  ): Array<Netting> {
    if (!nettingArr || !equipments) {
      return;
    }

    const updatedNettingArr = nettingArr
      .map((netting) => {
        let updatedEquipmentList = netting.equipments;
        // get associated equipment
        netting.equipments.forEach((equipmentId) => {
          const equipment = equipments.find(
            (equipment) => equipment.id === equipmentId,
          );
          if (!equipment) {
            return;
          }

          const equipmentNodes = equipment.nodes || [];
          if (
            !netting.nodes.includes('ALL') &&
            equipmentNodes.every(
              (node) => !netting.nodes.includes(node) && node !== 'ALL',
            )
          ) {
            updatedEquipmentList = netting.equipments.filter(
              (nettingEquipment) => nettingEquipment !== equipment.id,
            );
          }
        });

        return { ...netting, equipments: updatedEquipmentList };
      })
      .filter(
        (netting) => !!netting.equipments && netting.equipments.length > 0,
      );
    return updatedNettingArr;
  }

  isNettingAffected(
    equipmentId: string,
    currentNodeIds: Array<string>,
    netting: Array<Netting> = [],
  ) {
    const associatedNetting = netting.filter((netting) =>
      netting.equipments.includes(equipmentId),
    );
    if (
      !equipmentId ||
      equipmentId === '' ||
      !currentNodeIds ||
      currentNodeIds.includes('ALL') ||
      currentNodeIds.some((node) => !node || node === '') ||
      !netting ||
      !associatedNetting ||
      associatedNetting.length < 1
    ) {
      return false;
    }

    const commonNodes =
      currentNodeIds.filter((node) =>
        associatedNetting.some(
          (assocNetting) =>
            assocNetting.nodes.includes('ALL') ||
            assocNetting.nodes.includes(node) ||
            node === 'ALL',
        ),
      ) || [];
    if (commonNodes.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  showCascadingWarningDialog(affectedData: Array<boolean>): Observable<any> {
    if (affectedData.every((data) => !data)) {
      return of(true);
    }

    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant('Scenario.dialog.nodes.updateTitle'),
      message: this._translate.instant(
        'Scenario.dialog.nodes.affectedNettingWarning',
      ),
      confirm: this._translate.instant('Generic.labels.ok'),
      disableClose: true,
      width: 400,
    });
  }

  cascadeNodeNettingUpdate(scenario: Scenario): Scenario {
    const filteredNettings = this.filterNettingByEquipmentNodes(
      scenario.netting.netting,
      scenario.equipments.equipments,
    );
    const filteredTaxAndSubsidies = cascadeFilterTaxAndSubsidiesByNetting(
      scenario.netting.taxAndSubsidies,
      filteredNettings.map((netting) => netting.id),
    );

    return {
      ...scenario,
      netting: {
        netting: filteredNettings,
        taxAndSubsidies: filteredTaxAndSubsidies,
      },
    };
  }

  removeAssociationToDeletedNodes(
    data: Array<any>,
    deletedNodeIds: Array<string>,
  ) {
    return data
      .filter((item) =>
        item.nodes.some((nodeId) => !contains(deletedNodeIds, nodeId)),
      )
      .map((item) => {
        const filteredItem = {
          ...item,
          nodes: item.nodes.filter((node) => !deletedNodeIds.includes(node)),
        };
        return filteredItem;
      });
  }
}
