import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplicationSettingsKey,
  ApplicationTheme,
  UserPreferenceKey,
  UserPreferences,
  UserPreferencesUpdateRequest,
  UserPreferencesUpdateResponseSuccess,
} from 'prosumer-app/+user-preferences/models';
import { Utils } from 'prosumer-app/core';
import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _endpoints: EndpointProvider,
  ) {
    this.initializeTheme();
  }

  setApplicationTheme(theme: ApplicationTheme): void {
    localStorage.setItem(ApplicationSettingsKey.theme, theme);
    this.themeSetterFns()[theme]();
  }

  getApplicationTheme(): ApplicationTheme {
    return (localStorage.getItem(ApplicationSettingsKey.theme) ??
      ApplicationTheme.LIGHT) as ApplicationTheme;
  }

  getAll(): Observable<UserPreferences> {
    return this._http.get<UserPreferences>(
      this._endpoints.forUserPreferences(),
    );
  }

  update(
    key: UserPreferenceKey,
    value: unknown,
  ): Observable<UserPreferencesUpdateResponseSuccess> {
    const body = this.resolveBody(key, value);
    return this._http.patch<UserPreferencesUpdateResponseSuccess>(
      this._endpoints.forUserPreferences(),
      body,
    );
  }

  private initializeTheme(): void {
    this.themeSetterFns()[this.getApplicationTheme()]();
  }

  private themeSetterFns(): Record<ApplicationTheme, () => void> {
    return {
      [ApplicationTheme.LIGHT]: Utils.disableDarkMode,
      [ApplicationTheme.DARK]: Utils.enableDarkMode,
      [ApplicationTheme.SYSTEM]: Utils.followSystemTheme,
    };
  }

  private resolveBody(
    key: UserPreferenceKey,
    value: unknown,
  ): UserPreferencesUpdateRequest {
    if (key === UserPreferenceKey.feedbackDateScheduledInDays) {
      return {
        requestCode: key,
        dayModifier: value as number,
      };
    }
    return {
      requestCode: key,
      value,
    };
  }
}
