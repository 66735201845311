import {
  RouterCancelAction,
  RouterErrorAction,
  RouterNavigationAction,
  RouterNavigationPayload,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { Action } from '@ngrx/store';

import { type } from 'prosumer-app/libs/eyes-shared';

import { RouterData, RouterStateUrl } from './router.model';

export const routerFeatureName = 'deprecated_router';

export const RouterActionTypes = {
  GO: type(`[${routerFeatureName}] Go`),
  BACK: type(`[${routerFeatureName}] Back`),
  FORWARD: type(`[${routerFeatureName}] Forward`),
};

export class RouterGoAction implements Action {
  readonly type = RouterActionTypes.GO;
  constructor(public payload: RouterData) {}
}

export class RouterBackAction implements Action {
  readonly type = RouterActionTypes.BACK;
}

export class RouterForwardAction implements Action {
  readonly type = RouterActionTypes.FORWARD;
}

export type RouterActions =
  | any
  | RouterGoAction
  | RouterBackAction
  | RouterForwardAction;

/*
 * Extended
 */
export const ROUTER_ACTIVE = 'ROUTER_ACTIVE';

export class RouterActiveAction<T = SerializedRouterStateSnapshot> {
  readonly type = ROUTER_ACTIVE;
  constructor(public payload: RouterNavigationPayload<RouterStateUrl>) {}
}

export type RouterActionsExtended<T = SerializedRouterStateSnapshot> =
  | RouterNavigationAction
  | RouterCancelAction<T>
  | RouterErrorAction<T>
  | RouterActiveAction<T>;
