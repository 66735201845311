<div
  fxLayoutGap="10px"
  [fxLayout]="flexLayout"
  [fxLayoutAlign]="flexLayoutAlign"
>
  <button
    mat-flat-button
    type="button"
    [color]="previousColor"
    [disabled]="previousDisabled"
    (click)="onClickPrevious()"
  >
    {{ previousLabel }}
  </button>
  <button
    mat-flat-button
    type="submit"
    [color]="nextColor"
    [disabled]="nextDisabled"
    (click)="onClickNext()"
  >
    {{ nextLabel }}
  </button>
</div>
