import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Profile } from 'prosumer-app/+scenario';
import { BaseComponent, generateShortUID } from 'prosumer-app/libs/eyes-shared';
import { YearlyInterval } from 'prosumer-shared/models';

import { YearlyLoadsIntervalDialogModel } from './yearly-loads-interval-dialog.model';

@Component({
  selector: 'prosumer-yearly-loads-interval-dialog',
  templateUrl: './yearly-loads-interval-dialog.component.html',
  styleUrls: ['./yearly-loads-interval-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyLoadsIntervalDialogComponent
  extends BaseComponent
  implements OnInit
{
  form: UntypedFormGroup;
  disableAdd: boolean;

  get intervalsFormArray(): UntypedFormArray {
    return this.form.get('intervals') as UntypedFormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YearlyLoadsIntervalDialogModel,
    public dialogRef: MatDialogRef<YearlyLoadsIntervalDialogModel>,
    public formBuilder: UntypedFormBuilder,
  ) {
    super();

    // Initialize the form using the data intervals if there's any
    if (this.data.intervals && this.data.intervals.length > 0) {
      this.initForm(this.data.intervals);
      // Else if the start and end years are present, initialize the form using these
    } else if (this.data.startYear && this.data.endYear) {
      this.initForm([
        {
          startYear: this.data.startYear,
          endYear: this.data.endYear,
        },
      ]);
      // Else initialize the form without any params
    } else {
      this.initForm();
    }

    // Generate the interval options after initializing the form
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.intervalsFormArray.getRawValue(),
        this.data.startYear,
        this.data.endYear,
      ),
    );
  }

  ngOnInit() {
    // Check last interval if the 'To' date is the end year to disable the add button
    this.checkLastIfEndYear();
  }

  /**
   * Initialize the form using the intervals parameter
   *
   * @param intervals - the array of intervals
   */
  initForm(intervals?: Array<YearlyInterval>): void {
    this.form = this.createMainForm(intervals);
  }

  /**
   * Create the main form representing the form group definition
   *
   * @param intervals - the array of intervals
   */
  createMainForm(intervals?: Array<YearlyInterval>): UntypedFormGroup {
    return this.formBuilder.group({
      intervals: this.createIntervalsFormArray(intervals),
    });
  }

  /**
   * Create the intervals form array based on the parameter; Each of the intervals will create a child form group that will be pushed in the
   * intervals form array (based on the interval's start year, end year, and profile)
   *
   * @param intervals - the array of intervals
   */
  createIntervalsFormArray(
    intervals?: Array<YearlyInterval>,
  ): UntypedFormArray {
    const intervalsFormArray = this.formBuilder.array([]);

    if (!!intervals) {
      intervals.forEach((interval) =>
        intervalsFormArray.push(
          this.createIntervalForm(
            interval.startYear,
            interval.endYear,
            interval.profile,
          ),
        ),
      );
    }

    return intervalsFormArray;
  }

  /**
   * Create an interval form group representing the interval based on the passed parameters
   *
   * @param startYear - the start year
   * @param endYear   - the end year
   * @param profile   - the profile
   */
  createIntervalForm(
    startYear: number,
    endYear: number,
    profile?: Profile,
  ): UntypedFormGroup {
    const intervalForm = this.formBuilder.group({
      startYear: this.formBuilder.control({
        value: '',
        disabled: startYear === this.data.startYear,
      }),
      endYear: this.formBuilder.control({ value: '', disabled: true }),
      yearOptions: this.formBuilder.control(''),
      profile: this.formBuilder.control(''),
    });

    intervalForm.patchValue({
      startYear,
      endYear,
      profile: this.mapProfile(profile),
    });

    return intervalForm;
  }

  /**
   * Regenerates the interval to create a field to represent the year options; These year options are the list of values that will be the
   * options of the drop-down component (material select)
   *
   * @param intervals - the array of intervals
   * @param startYear - the start year
   * @param endYear   - the end year
   */
  generateIntervalsWithOptions(
    intervals: Array<YearlyInterval>,
    startYear: number,
    endYear: number,
  ) {
    const intervalsWithOptions: Array<YearlyInterval> = [];
    intervals.forEach((interval, index) => {
      const refStartYear =
        index > 0 ? intervals[index - 1].startYear + 1 : startYear;
      intervalsWithOptions.push({
        ...interval,
        yearOptions: this.generateOptions(refStartYear, interval.endYear),
      });
    });
    return intervalsWithOptions;
  }

  /**
   * Regenerates the interval to adjust the previous end dates used when there are changes in  any of the interval start date; This will
   * ensure that the intervals have continuous years even after deleting or editing the interval
   *
   * @param intervals         - the array of intervals
   * @param adjustedInterval  - the adjusted interval
   * @param adjustedIndex     - the index of the adjusted interval
   */
  adjustPreviousEndDate(
    intervals: Array<YearlyInterval>,
    adjustedInterval: YearlyInterval,
    adjustedIndex: number,
  ) {
    if (adjustedIndex > 0) {
      const previousIndex = adjustedIndex - 1;
      return intervals.map((interval, index) => {
        if (index === previousIndex) {
          return {
            ...interval,
            endYear: adjustedInterval.startYear - 1,
          };
        }
        return interval;
      });
    }
    return intervals;
  }

  /**
   * Generates the options based on the start and end year parameters
   * Ex: 2010 to 2015 will generate an array of [2010, 2011, 2012, 2013, 2014, 2015]
   *
   * @param startYear - the start year
   * @param endYear   - the end year
   */
  generateOptions(startYear: number, endYear: number): Array<number> {
    return Array.from(Array(endYear - startYear + 1)).map(
      (_, index) => startYear + index,
    );
  }

  /**
   * Check if the last interval in the intervals form array has a 'To' date equal to the end year; This will disable the add button to avoid
   * generating intervals greater than the end year
   */
  checkLastIfEndYear(): void {
    if (!!this.intervalsFormArray) {
      const currentInterval = this.intervalsFormArray.at(
        this.intervalsFormArray.length - 1,
      );
      if (!!currentInterval) {
        this.disableAdd =
          this.intervalsFormArray.at(this.intervalsFormArray.length - 1).value
            .startYear === this.data.endYear;
      }
    }
  }

  /**
   * Event handler when clicking the add interval button; This will push a generated interval at the end of the list and will be based on
   * the previous interval where the 'To' year of the previous interval will be equal to its 'From' year. The newly added interval's 'From'
   * year will be the previous interval's 'To' year + 1. The 'To' year of the newly added interval will be the end year of the horizon.
   *
   * Each of the intervals will be re-generated so that the respective drop-down options will be updated. The form will also be
   * marked as dirty to enable the OK button; then checks if the last interval's 'To' year is the end year to disable the add button.
   */
  onAddInterval(): void {
    const intervals: Array<YearlyInterval> =
      this.intervalsFormArray.getRawValue();
    const lastInterval = [...intervals].pop();
    const newIntervalFormGroup = this.createIntervalForm(
      lastInterval.startYear + 1,
      lastInterval.endYear,
    );
    const newInterval = newIntervalFormGroup.getRawValue();
    intervals.push(newInterval);

    this.intervalsFormArray.push(newIntervalFormGroup);
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          intervals,
          newInterval,
          intervals.length - 1,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.intervalsFormArray.markAsDirty();
    this.checkLastIfEndYear();
  }

  /**
   * Event handler when changing the drop-down (material select) 'To' date of the intervals. This will just adjust the 'To' year of the
   * previous interval to retain the continuous years of the intervals. Options available for change are determined by the regenerated
   * options everytime the array of intervals in the form change.
   *
   * Each of the intervals will be re-generated so that the respective drop-down options will be updated. The form will also be
   * marked as dirty to enable the OK button; then checks if the last interval's 'To' year is the end year to disable the add button.
   *
   * @param changedInterval - the modified interval
   * @param index           - the index of the modified interval
   */
  onChangeInterval(changedInterval: YearlyInterval, index: number): void {
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          this.intervalsFormArray.getRawValue(),
          changedInterval,
          index,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.checkLastIfEndYear();
  }

  /**
   * Event handler when deleting an interval. This will set the previous interval's 'To' date to the deleted interval's 'To' date and the
   * next interval's 'From' date to the deleted interval's 'To' date plus 1 to ensure the continous years throughout the intervals. If the
   * deleted interval is the last interval, the 'To' year of the previous interval will only be adjusted to be the horizon end year.
   *
   * Each of the intervals will be re-generated so that the respective drop-down options will be updated. The form will also be
   * marked as dirty to enable the OK button; then checks if the last interval's 'To' year is the end year to disable the add button.
   *
   * @param toDeleteInterval - the interval to be removed
   * @param index            - the index of the interval to be removed
   */
  onDeleteInterval(toDeleteInterval: YearlyInterval, index: number): void {
    const intervals: Array<YearlyInterval> =
      this.intervalsFormArray.getRawValue();

    let refInterval: YearlyInterval;
    if (index !== this.intervalsFormArray.length - 1) {
      refInterval = intervals[index + 1];
    } else {
      refInterval = { ...toDeleteInterval, startYear: this.data.endYear + 1 };
    }

    this.intervalsFormArray.removeAt(index);
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          this.intervalsFormArray.getRawValue(),
          refInterval,
          index,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.intervalsFormArray.markAsDirty();
    this.checkLastIfEndYear();
  }

  /**
   * Maps the profile given the start and end years
   *
   * @param profile   - the profile
   * @param startYear - the start year
   * @param endYear   - the end year
   */
  mapProfile(profile: Profile, startYear?: number, endYear?: number): Profile {
    if (!!profile) {
      return profile;
    }

    return {
      startYear: startYear || null,
      endYear: endYear || null,
      forSaving: true,
      library: null,
      loadProfile: null,
      loadType: this.data.defaultLoadType,
      localId: generateShortUID(),
      location: this.data.defaultLocation,
      yearlyLoad: 1,
    };
  }

  /**
   * Closes the dialog passing the current value of the interval form array
   */
  onConfirm(): void {
    if (this.intervalsFormArray.valid) {
      this.dialogRef.close(
        this.intervalsFormArray.getRawValue().map((interval) => ({
          ...interval,
          profile: {
            ...interval.profile,
            startYear: interval.startYear,
            endYear: interval.endYear,
          },
        })),
      );
    }
  }

  /**
   * Closes the dialog
   */
  onClose(): void {
    this.dialogRef.close();
  }
}
