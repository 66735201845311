import { Utils } from 'prosumer-app/core/utils/utils';
import { FuelInfo, FuelBE } from 'prosumer-app/stores/fuel';
import {
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared';

export class FuelMapper {
  static mapToBackend = (fuel: FuelInfo): FuelBE => {
    const item = {
      fuelId: fuel.id,
      fuelEnergyVectorId: fuel.fuel,
      scenarioUuid: fuel.scenarioUuid,
      nodeId: { nodeIds: Utils.resolveToEmptyArray(fuel.nodes) },
      fuelCost: mapYearlyValuesToBackend(fuel.fuelCost),
      co2Rate: mapYearlyValuesToBackend(fuel.co2Rate),
      co2Scope3EmissionFactor: mapYearlyValuesToBackend(
        fuel.co2Scope3EmissionFactor,
      ),
    };

    return item;
  };

  static mapToFrontend = (
    fuel,
    startYear: number,
    endYear: number,
  ): FuelInfo => {
    const item = {
      id: fuel.fuelId,
      fuel: fuel.fuelEnergyVectorId,
      scenarioUuid: fuel.scenarioUuid,
      nodes: Utils.resolveToEmptyObject(
        Utils.parseYearlyValuesFromBE(fuel.nodeId),
      ).nodeIds,
      fuelCost: mapYearlyValuesToFrontend(
        Utils.parseYearlyValuesFromBE(fuel.fuelCost),
        startYear,
        endYear,
      ),
      co2Rate: mapYearlyValuesToFrontend(
        Utils.parseYearlyValuesFromBE(fuel.co2Rate),
        startYear,
        endYear,
      ),
      co2Scope3EmissionFactor: mapYearlyValuesToFrontend(
        Utils.parseYearlyValuesFromBE(fuel.co2Scope3EmissionFactor),
        startYear,
        endYear,
      ),
    };
    return item;
  };
}
