import {
  compareArrays,
  contains,
  doNothing,
} from 'prosumer-app/libs/eyes-shared';

const isSameEnergyVectorId = (id, source, target) => {
  const sourceEQ = source.filter((item) => item['id'] === id)[0];
  const targetEQ = target.filter((item) => item['id'] === id)[0];

  const sourceEVId = mapEnergyVector(sourceEQ);
  const targetEVId = mapEnergyVector(targetEQ);
  return (
    sourceEVId.length === targetEVId.length &&
    sourceEVId.every((ev, index) => ev === targetEVId[index])
  );
};

const mapEnergyVector = (equipment) => {
  const {
    energyVector,
    outputEnergyVector,
    inputEnergyVector,
    efficiencyMatrix,
  } = (equipment || {}) as any;
  const { mainEnergyVectorInput, mainEnergyVectorOutput } = (efficiencyMatrix ||
    {}) as any;
  const output = [
    energyVector,
    outputEnergyVector,
    inputEnergyVector,
    mainEnergyVectorInput,
    mainEnergyVectorOutput,
  ].filter((ev) => !!ev);
  return Array.from(new Set(output));
};

const isSameNodeIds = (id, source, target) => {
  // since vehicle has no nodes, exit
  if (
    source.filter((item) => item['id'] === id)[0].type.toLowerCase() ===
    'vehicle'
  ) {
    return true;
  }
  const sourceNodeIds = source.filter((item) => item['id'] === id)[0]['nodes'];
  const targetNodeIds = target.filter((item) => item['id'] === id)[0]['nodes'];
  return compareArrays(sourceNodeIds, targetNodeIds);
};

/**
 * returns the list of editted items from the source array
 *
 * @param source - the original array
 * @param target - the array after deletion of an entry
 * @param idField - the indicator of the field to check from the object in the array
 * @param fields - the array of field names that needs to check for changes
 */

export const getEdittedEquipments = <T>(
  source: Array<T>,
  target: Array<T>,
  idField: string = 'id',
) => {
  if (!source || source.length === 0) {
    return [];
  }

  if (!target || target.length === 0 || source.length !== target.length) {
    return [...source];
  }

  const edittedIds = [];
  const sourceIds = source.map((item) => item[idField]);

  sourceIds.forEach((id) =>
    isSameEnergyVectorId(id, source, target) &&
    isSameNodeIds(id, source, target)
      ? doNothing()
      : edittedIds.push(id),
  );
  return source.filter((item) => contains(edittedIds, item[idField]));
};
