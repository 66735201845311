import { VariationFinder } from 'prosumer-app/+scenario/services';
import { RegulationsCompletion } from 'prosumer-app/+scenario/services/completion-strategies/regulations-completion.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  containsSubstring,
  FormFieldOption,
  StepFormComponent,
  toObject,
} from 'prosumer-app/libs/eyes-shared';
import { SparklineTableColumnDefinition } from 'prosumer-app/shared/components/sparkline-table/sparkline-table.model';
import { convertToYearlyValues, isVariation } from 'prosumer-shared';
import { map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ReferencesBuilder,
  TABLE_REF_KEYS,
  TableRefs,
} from 'prosumer-app/services/references-builder';
import { Regulation } from 'prosumer-app/stores/regulation';
import { Observable } from 'rxjs';
import { Equipment, Metering, RegulationsForm } from '../../models';
import { MeteringDialogData } from './metering-dialog/metering-dialog-model';
import { MeteringDialogComponent } from './metering-dialog/metering-dialog.component';
import { RegulationFormService } from './regulation-form.service';

@UntilDestroy()
@Component({
  selector: 'prosumer-regulation-form',
  templateUrl: './regulation-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegulationFormComponent
  extends StepFormComponent
  implements OnInit
{
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;

  readonly nettingOptions: Array<FormFieldOption<string>> = [
    { name: 'Instantaneous', value: 'instantaneous' },
    { name: 'Separate', value: 'separate' },
    { name: 'Yearly', value: 'yearly' },
  ];
  readonly regulations$: Observable<Regulation[]> =
    this.service.selectRegulations();

  _equipments: Array<Equipment>;
  @Input() set equipments(equipments: Array<Equipment>) {
    this._equipments = equipments;
  }
  get equipments() {
    return this._equipments;
  }

  columnsDef: SparklineTableColumnDefinition = {
    technologyId: {
      name: 'Technology',
      type: 'reference',
      referenceKey: TABLE_REF_KEYS.equipments,
      cellTooltipReferenceKey: 'technologyVariationAssociation',
      sortable: true,
    },
    netting: {
      name: 'Netting',
      type: 'reference',
      referenceKey: 'nettings',
      sortable: true,
      flex: '0 0 120px',
    },
    yearlyAutoConsumptionTax: {
      name: 'Autoconsumption Tax [EUR/kWh]',
      type: 'chart',
      alignment: 'center',
    },
    yearlyGenerationTariff: {
      name: 'Generation Tariff [EUR/kWh]',
      type: 'chart',
      alignment: 'center',
    },
    yearlyFeedInTariff: {
      name: 'Feed-in Tariff [EUR/kWh]',
      type: 'chart',
      alignment: 'center',
    },
    yearlyFeedInPremium: {
      name: 'Feed-in Premium [EUR/kWh]',
      type: 'chart',
      alignment: 'center',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      alignment: 'center',
      flex: '0 0 115px',
    },
  };

  readonly references$ = this.selectReferences();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public dialog: DialogService,
    public completion: ScenarioCompletionService,
    private readonly varFinder: VariationFinder,
    private readonly service: RegulationFormService,
    private readonly refBuilder: ReferencesBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToCompletionTracking();
  }

  writeValue(value: RegulationsForm) {
    super.writeValue(value);
  }

  defineForm() {
    return {
      meterings: [],
    };
  }

  searchPredicate = (data: Metering, filter: string) => {
    const filteredEquipments = this.equipments.filter((equipment) =>
      containsSubstring(equipment.name, filter),
    );
    return !!filteredEquipments
      ? filteredEquipments.some(
          (equipment) => data.technologyId === equipment.id,
        )
      : false;
  };

  onAdd() {
    this.dialog.openDialog(
      MeteringDialogComponent,
      this.generateInitialLoadData(),
    );
  }

  onEdit(data: Metering) {
    if (!!!data) {
      return;
    }

    this.dialog.openDialog(MeteringDialogComponent, {
      ...this.generateInitialLoadData(),
      ...data,
      meteringData: data,
      mode: 'edit',
      isViewOnly: this.isViewOnly || this.mode === 'read',
      startYear: this.startYear,
      endYear: this.endYear,
    });
  }

  onDelete(data: Metering) {
    this.service.deleteMetering(data.id).pipe(take(1)).subscribe();
  }

  getEquipmentOptions = (): Array<FormFieldOption<string>> => {
    if (this.equipments) {
      return this.equipments.map((equipment) => ({
        name: equipment.name,
        value: equipment.id,
        object: equipment,
      }));
    } else {
      return [];
    }
  };

  generateInitialLoadData = (): MeteringDialogData => ({
    width: 700,
    mode: 'add',
    technologyId: '',
    netting: 'instantaneous',
    id: undefined,
    disableClose: true,
    // Yearly Loads
    yearlyAutoConsumptionTax: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    yearlyGenerationTariff: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    yearlyFeedInTariff: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    yearlyFeedInPremium: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    startYear: this.startYear,
    endYear: this.endYear,
    ...this.service.prepForDialog(),
  });

  subscribeToCompletionTracking(): void {
    const strategy = new RegulationsCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.regulation,
        strategy.determineStatus(form),
      );
    });
  }

  private selectReferences(): Observable<TableRefs> {
    return this.refBuilder.selectRefs().pipe(
      map((refs) => ({
        ...refs,
        nettings: toObject(this.nettingOptions, 'value', 'name'),
      })),
    );
  }

  private createTechnologyVariationAssociation(technologies: Array<Equipment>) {
    const technologyVariationAssociation = {};
    technologies.forEach(
      (technology) =>
        (technologyVariationAssociation[technology.id] =
          this.getVariationNameFromId(technology.scenarioVariation)),
    );

    return technologyVariationAssociation;
  }

  private getVariationNameFromId(variationId: string): string {
    return this.varFinder.getVariationName(variationId);
  }

  private createTechnologiesReference(
    equipments: Equipment[],
  ): Record<string, string> {
    const refObject = {};
    equipments.forEach((equipment) => {
      let equipmentName = equipment.name;
      if (isVariation(equipment.scenarioVariation)) {
        equipmentName += ` (${this.getVariationNameFromId(
          equipment.scenarioVariation,
        )})`;
      }
      refObject[equipment.id] = equipmentName;
    });
    return refObject;
  }
}
