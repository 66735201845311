import { Injectable } from '@angular/core';
import { LinesFormDialogData } from 'prosumer-app/+scenario/components';
import { NodesFormDialogData } from 'prosumer-app/+scenario/models';
import { BOOLEAN_OPTIONS } from 'prosumer-app/app.references';
import { PipeUtils } from 'prosumer-app/core';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TopologyDialogPrepper {
  private startYear = 0;
  private endYear = 0;

  constructor(private readonly scenarios: ScenarioGenericQuery) {
    this.subscribeToProjectDuration();
  }

  prepLineData(): LinesFormDialogData {
    return {
      ...this.getFixedOptions(),
      ...this.getFixedUIFields(),
      ...this.getEmptyLine(),
      ...this.getFixedAdvanceFields(),
      ...this.getActiveScenarioYears(),
    } as LinesFormDialogData;
  }

  prepNodeData(): NodesFormDialogData {
    return {
      width: 650,
      mode: 'add',
      disableClose: true,
    };
  }

  private getActiveScenarioYears(): Pick<
    LinesFormDialogData,
    'startYear' | 'endYear'
  > {
    return {
      startYear: this.startYear,
      endYear: this.endYear,
    };
  }

  private subscribeToProjectDuration(): void {
    this.scenarios.projectDuration$
      .pipe(PipeUtils.filterOutUndefined, distinctUntilChanged())
      .subscribe((projectDuration) => {
        this.startYear = projectDuration.startYear;
        this.endYear = this.startYear + projectDuration.duration - 1;
      });
  }

  private getFixedOptions(): Partial<LinesFormDialogData> {
    return {
      profileOptions: [
        { name: 'Library', value: 'library' },
        { name: 'Custom', value: 'custom' },
      ],
      bidirectionalOptions: BOOLEAN_OPTIONS,
    };
  }

  private getFixedUIFields(): Partial<LinesFormDialogData> {
    return {
      width: 1024,
      disableClose: true,
      mode: 'add',
      sourceType: 'custom',
    };
  }

  private getEmptyLine(): Partial<LinesFormDialogData> {
    return {
      energyVectorId: '',
      originNodeId: '',
      destinationNodeId: '',
      name: '',
      distance: 0,
      bidirectional: true,
      forcedInvestment: false,
    };
  }

  private getFixedAdvanceFields(): Partial<LinesFormDialogData> {
    return {
      yearlyEfficiency: {},
      yearlyMinSize: {},
      yearlyMaxSize: {},
      yearlyBuildCost: {},
      yearlyIndivisibleCost: {},
      yearlyFomCharge: {},
      yearlyFomInstall: {},
      yearlyTechnicalLife: {},
      yearlyDepreciationTime: {},
    };
  }
}
