import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AceEditorModule } from 'prosumer-app/shared/modules/ace-editor';
import { FlowResultsEditorBasicComponent } from '../flow-results-editor-basic/flow-results-editor-basic.component';

@Component({
  selector: 'prosumer-flow-results-editor-container',
  standalone: true,
  imports: [
    MatTabsModule,
    AceEditorModule,
    CommonModule,
    FlowResultsEditorBasicComponent,
  ],
  templateUrl: './flow-results-editor-container.component.html',
  styleUrl: './flow-results-editor-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowResultsEditorContainerComponent {
  @Output() save = new EventEmitter();
  @Input() data;

  onSave(event) {
    this.save.emit(event);
  }
}
