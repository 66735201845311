<div class="question-container" [formGroup]="form">
  <p
    class="question"
    [ngClass]="{
      required: required
    }"
  >
    <span *ngIf="required" class="asterisk">*</span>{{ question }}
  </p>
  <ng-container [ngSwitch]="fieldType">
    <ng-container *ngSwitchCase="'rating'">
      <star-rating-input
        formControlName="answer"
        (rateChange)="onChangeRatings($event)"
      ></star-rating-input>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <textarea
        [placeholder]="placeholder"
        [formControl]="answerField"
      ></textarea>
    </ng-container>
    <ng-container *ngSwitchCase="'yes-no'">
      <input
        type="radio"
        [name]="name"
        (click)="handleYesNoChange(true)"
        [checked]="defaultYes && !defaultNo"
        data-testid="option-yes"
      />
      <label class="yes-label" [for]="name">{{
        'User.labels.yes' | translate
      }}</label>
      <input
        type="radio"
        [name]="name"
        (click)="handleYesNoChange(false)"
        [checked]="defaultNo"
        data-testid="option-no"
      />
      <label class="no-label" [for]="name">{{
        'User.labels.no' | translate
      }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'option'">
      <mat-button-toggle-group
        name="optionsToggle"
        aria-label="Options Toggle"
        appearance="legacy"
        formControlName="answer"
        (change)="onSelectButton($event)"
      >
        <mat-button-toggle
          *ngFor="let option of options"
          [value]="option?.value"
          [attr.data-testid]="'feedback-' + option?.value"
          >{{ option?.key }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </ng-container>
  </ng-container>
</div>
