import { Inject, Injectable, Optional } from '@angular/core';
import { ApiConfig, SupportConfig, Version } from '../config';
import {
  SHARED_API_TOKEN,
  SHARED_SUPPORT_TOKEN,
  SHARED_VERSION_TOKEN,
} from '../eyes-shared.tokens';

@Injectable()
export class SupportConfigService {
  constructor(
    @Optional() @Inject(SHARED_API_TOKEN) public api: ApiConfig,
    @Optional() @Inject(SHARED_VERSION_TOKEN) public version: Version,
    @Optional() @Inject(SHARED_SUPPORT_TOKEN) public support: SupportConfig,
  ) {}
}
