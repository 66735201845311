import {
  expandYearlyValuesIfApplicable,
  mapYearlyValuesToFrontend,
  YearlyValues,
} from 'prosumer-app/shared';
import { YearValues } from 'prosumer-app/stores/scenario-generic';
import {
  IncomingEmissions,
  OutgoingEmissions,
} from './commodities-emissions.models';

export class CommoditiesEmissionsFormMapper {
  static toFE(
    from: OutgoingEmissions,
    period: [number, number],
  ): IncomingEmissions {
    const { co2Price, co2Scope2Price, co2Scope3Price } = from;
    return {
      ...from,
      co2Price: CommoditiesEmissionsFormMapper.toSanitizedYearlyValues(
        co2Price,
        period,
      ),
      co2Scope2Price: CommoditiesEmissionsFormMapper.toSanitizedYearlyValues(
        co2Scope2Price,
        period,
      ),
      co2Scope3Price: CommoditiesEmissionsFormMapper.toSanitizedYearlyValues(
        co2Scope3Price,
        period,
      ),
    };
  }

  private static toSanitizedYearlyValues(
    from: YearValues,
    period: [number, number],
  ): YearlyValues {
    if (!from) {
      return null;
    }
    if (typeof from == 'string') {
      from = { values: [from] };
    }
    if (!from.year) {
      from = { ...from, year: period[0] };
    }
    let yearlyValues = mapYearlyValuesToFrontend(from);
    yearlyValues = expandYearlyValuesIfApplicable(yearlyValues, period);
    return yearlyValues;
  }
}
