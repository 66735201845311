import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metric',
})
/**
 * @input - number to convert
 * @args - number of decimals
 * Implementation:
 * {{ model | metric  }} <!-- 0 decimals -->
 * {{ model | metric : 2  }}  <!-- X decimals -->
 */
export class MetricPipe extends DecimalPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    let decimalPlacing = '1.0-0';
    try {
      if (args) {
        decimalPlacing = `1.${args}-${args}`;
      }
      if (input) {
        return super.transform((input / 1000).toFixed(args), decimalPlacing);
      }
      return super.transform(input.toFixed(args), decimalPlacing);
    } catch {
      return input;
    }
  }
}
