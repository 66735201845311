import { EquipmentCompletion } from 'prosumer-app/+scenario/services/completion-strategies';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { BINARY_LOCATIONS, DER_TYPES } from 'prosumer-app/app.references';
import { PipeUtils, Utils } from 'prosumer-app/core/utils';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  Button,
  ColumnDefinition,
  containsSubstring,
  doNothing,
  fadeInAnimation,
  FilterConfig,
  FormFieldOption,
  generateShortUID,
  generateUuid,
  getKeys,
  StepFormComponent,
  toObject,
} from 'prosumer-app/libs/eyes-shared';
import { DuplicationStarter } from 'prosumer-app/services/duplication-starter/duplication.starter';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import {
  EquipmentInfo,
  EquipmentQuery,
  EquipmentStore,
} from 'prosumer-app/stores/equipment';
import { NodeQuery } from 'prosumer-app/stores/node';
import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation';
import {
  convertToYearlyValues,
  getBinsToCloneOnEdit,
  getBinsToDeleteOnEdit,
  mapProfilesToDuplicateAndPatchToCloneControl,
} from 'prosumer-shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  Equipment,
  EquipmentForm,
  EQUIPS_WITH_BIN,
  Netting,
  Profile,
} from '../../models';
import { EquipmentDialogComponent } from './equipment-dialog';
import { AuthService } from 'prosumer-app/core/services';

@UntilDestroy()
@Component({
  selector: 'prosumer-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss'],
  animations: [fadeInAnimation],
  providers: [ManagedDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() hasMobilityFeature = false; // flag if need to display mobility in the selection.
  @Input() isViewOnly: boolean;
  @Input() loadFormData: ScenarioWizardStep;
  @Input() isMultiNode: boolean;

  tableMetaData: ColumnDefinition;
  references = {};
  references$ = this.selectActiveReferences();

  filters: Array<FilterConfig> = [
    { field: 'name' },
    {
      type: 'select',
      field: 'type',
      label: 'Equipment Type',
      flex: '20%',
      value: '',
      options: [
        { name: 'All', value: '' },
        ...getKeys(DER_TYPES).map(
          (key) =>
            ({
              name: DER_TYPES[key],
              value: key,
            }) as FormFieldOption<string>,
        ),
      ],
    },
  ];
  persistedBinaries: Array<Profile> = [];

  addEmitter: EventEmitter<any> = new EventEmitter<any>();
  editEmitter: EventEmitter<any> = new EventEmitter<any>();
  deleteEmitter: EventEmitter<any> = new EventEmitter<any>();
  showGraphEmitter: EventEmitter<any> = new EventEmitter<any>();

  _evOptions: Array<FormFieldOption<string>> = [
    { name: 'Electricity', value: 'e0000' },
  ];
  @Input() set evOptions(evOptions: Array<FormFieldOption<string>>) {
    this._evOptions = evOptions;
    // this.setReferences();
  }
  get evOptions(): Array<FormFieldOption<string>> {
    return this._evOptions;
  }

  _currentNetting: Array<Netting> = [];
  @Input() set currentNetting(netting: Array<Netting>) {
    this._currentNetting = netting;
  }
  get currentNetting(): Array<Netting> {
    return this._currentNetting;
  }

  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  private readonly _hasTdbAcces = new BehaviorSubject<boolean>(false);
  equipmentList$ = this.equipmentQuery.selectActiveEquipments();
  scenarioVariationOptions: Array<FormFieldOption<string>> = [];

  tableActionButtons: Array<Button> = [
    {
      label: 'New',
      color: 'primary',
      behavior: 'button',
      iconPrefix: 'add',
      eyesClick: this.addEmitter,
    },
  ];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private manageData: ManagedDataService<Equipment>,
    private dialog: DialogService,
    private completion: ScenarioCompletionService,
    private equipmentStore: EquipmentStore,
    private equipmentQuery: EquipmentQuery,
    private energyVectorQuery: EnergyVectorQuery,
    private nodeQuery: NodeQuery,
    private variationQuery: ScenarioVariationQuery,
    private readonly notificationService: NotificationsService,
    private readonly dupStarter: DuplicationStarter,
    private readonly _authService: AuthService,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  ngOnInit() {
    super.ngOnInit();
    this.generateMetaData();
    this.deduceTDBAccess();
  }

  ngAfterViewInit() {
    this.addEmitter.subscribe((data: any) => this.onAddEvent(data));
    this.editEmitter.subscribe((data: any) => this.onEditEvent(data));
    this.deleteEmitter.subscribe((data: any) => this.onDeleteEvent(data));

    this.equipmentList$.pipe(this.takeUntilShare()).subscribe((data) => {
      this.form.controls.equipments.patchValue(data);
      this.form.markAsDirty();
      this.changeDetector.markForCheck();
    });

    this.variationQuery
      .selectActiveVariation()
      .pipe(untilDestroyed(this))
      .subscribe(
        (variationOptions) =>
          (this.scenarioVariationOptions = variationOptions),
      );
  }

  writeValue(equipment: EquipmentForm) {
    if (!equipment) {
      return;
    }
    this.persistedBinaries = [].concat(
      ...equipment.equipments
        .filter((e) => e.type === 'vre')
        .map((e) => (e.forSaving ? doNothing() : e.profiles)),
    );
    const operatingCostProfiles = [].concat(
      ...equipment.equipments.map((e) =>
        (e['operatingCostProfiles'] || []).map((profile) => profile.profiles),
      ),
    );
    const costProfiles = [].concat(
      ...operatingCostProfiles.map((profile) => profile),
    );
    this.persistedBinaries = this.persistedBinaries.concat(costProfiles);
    this.manageData.setListData([...equipment.equipments]);
    super.writeValue(equipment);
  }

  defineForm() {
    return {
      equipments: [[]],
      binToDelete: [[]],
      binToClone: [[]],
    };
  }

  _spreadBinaryProperties = (binaryData?: Equipment) =>
    !!binaryData && EQUIPS_WITH_BIN.includes(binaryData.type)
      ? {
          localId: binaryData.localId || generateUuid().substring(-5, 5),
          location: BINARY_LOCATIONS.DER,
          loadType: 'custom',
        }
      : {};

  onAddEvent(data: any): void {
    let dialogData = { ...this.generateInitialLoadData() };

    if (!!data) {
      dialogData = { ...dialogData, ...this.generateDuplicateLoadData(data) };
      return;
    }

    this.dialog
      .openDialog(EquipmentDialogComponent, dialogData)
      .pipe(take(1))
      .subscribe();
  }

  onEditEvent(data: any): void {
    if (!data) {
      return;
    }
    this.equipmentStore
      .getEquipment(data.equipmentId, data.type)
      .subscribe((eq) => {
        this.equipmentQuery
          .selectEntity(data.equipmentId)
          .pipe(take(1), filterNilValue())
          .subscribe((mappedEquipment) => {
            this.dialog
              .openDialog(EquipmentDialogComponent, {
                ...this.generateInitialLoadData(),
                ...data,
                mode: 'edit',
                equipment: mappedEquipment,
                isViewOnly: this.isViewOnly || this.mode === 'read',
              } as EquipmentDialogComponent)
              .pipe(take(1), PipeUtils.filterOutUndefined)
              .subscribe((equipment: Equipment) => {
                this.manageData.edit(data, {
                  ...equipment,
                  startYear: this.startYear,
                  endYear: this.endYear,
                });
                if (!!equipment) {
                  const persistedItems = this.persistedBinaries.filter((bin) =>
                    (data.profiles || [])
                      .map((prof) => prof.localId)
                      .includes(bin.localId),
                  );
                  // get deleted operatingCostProfile bindata and include for deletion
                  const updatedCostProfiles = [].concat(
                    ...(equipment['operatingCostProfiles'] || []).map(
                      (costProfile) => costProfile.profiles,
                    ),
                  );
                  const persistedCostProfiles = [].concat(
                    ...(data.operatingCostProfiles || []).map(
                      (costProfile) => costProfile.profiles,
                    ),
                  );
                  const persistedProfiles = this.persistedBinaries.filter(
                    (bin) =>
                      persistedCostProfiles
                        .map((costProfile) => costProfile.localId)
                        .includes(bin.localId),
                  );
                  const deletedItems = getBinsToDeleteOnEdit(
                    persistedItems.concat(persistedProfiles),
                    (equipment.profiles || []).concat(updatedCostProfiles),
                  );
                  if (!!deletedItems) {
                    const toBeDeletedControl = this.form.get('binToDelete');
                    toBeDeletedControl.patchValue([
                      ...toBeDeletedControl.value,
                      ...deletedItems,
                    ]);
                  }

                  const toCloneControl = this.form.get('binToClone');
                  toCloneControl.patchValue(
                    getBinsToCloneOnEdit(
                      toCloneControl.value,
                      updatedCostProfiles,
                    ),
                  );
                  toCloneControl.patchValue(
                    getBinsToCloneOnEdit(
                      toCloneControl.value,
                      equipment.profiles,
                    ),
                  );
                }
              });
          });
      });
  }

  onDuplicate(data: EquipmentInfo): void {
    this.dupStarter
      .start(ScenarioDetailType.equipment, data)
      .pipe(PipeUtils.filterOutNullUndefined)
      .subscribe(() => {});
  }

  onDeleteEvent(equipment: EquipmentInfo): void {
    this.equipmentStore.eradicateEquipment(equipment.equipmentId).subscribe({
      error: (error) => {
        const errObj = Utils.resolveToEmptyObject(error.error);
        this.notificationService.showError(errObj['error'] ?? error.message);
      },
    });
  }

  generateMetaData(): void {
    this.tableMetaData = {
      name: {
        name: 'Name',
        type: 'reference',
        referenceKey: 'name',
        toolTip: 'wizard_equipment.wizard_equipment_name',
      },
      type: {
        name: 'Type',
        type: 'reference',
        referenceKey: 'types',
        toolTip: 'wizard_equipment.wizard_equipment_type',
      },
      nodes: {
        name: 'Nodes',
        type: 'referenceList',
        referenceKey: 'nodes',
        toolTip: 'wizard_equipment.wizard_equipment_node',
        sortable: true,
      },
      energyVector: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: 'energyVectors',
        toolTip: 'wizard_equipment.wizard_equipment_fueled_by',
        sortable: true,
      },
      outputEnergyVector: {
        name: 'Delivering <br/> (primary)',
        type: 'reference',
        referenceKey: 'energyVectors',
        toolTip: 'wizard_equipment.wizard_equipment_delivering_primary',
        sortable: true,
      },
      scenarioVariation: {
        name: 'Variation',
        type: 'reference',
        referenceKey: 'scenarioVariations',
        sortable: true,
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }

  generateActionButtons(): void {
    this.tableActionButtons = [
      {
        label: 'New',
        color: 'primary',
        behavior: 'button',
        iconPrefix: 'add',
        eyesClick: this.addEmitter,
      },
    ];
  }

  getTypes(): Array<FormFieldOption<string>> {
    const types = [
      ...getKeys(DER_TYPES).map(
        (key) =>
          ({
            name: DER_TYPES[key],
            value: key,
          }) as FormFieldOption<string>,
      ),
    ];

    if (this.hasMobilityFeature) {
      types.push({ name: 'Vehicle', value: 'vehicle' });
      types.push({ name: 'Station', value: 'station' });
    }
    return types;
  }

  generateInitialLoadData() {
    const data = {
      mode: 'add',
      width: '90%',
      disableClose: true,
      profileType: 'custom',
      profileOptions: [
        { name: 'Library', value: 'library' },
        { name: 'Custom', value: 'custom' },
      ],
      equipment: {
        name: '',
        nodes: this.resolveNodesInitialValue(),
        type: 'vre',
        data: [],
        profiles: [
          {
            startYear: this.startYear,
            endYear: this.endYear,
            forSaving: true,
            library: null,
            loadProfile: null,
            loadType: 'custom',
            localId: generateShortUID(),
            location: BINARY_LOCATIONS.DER,
            yearlyLoad: 1,
          },
        ],
        outputEnergyVector: '',
        loadName: 'VRE Profile',
        forcedInvestment: false,
        library: '',
        yearlyCapacityLoss: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyMinPower: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyMaxPower: convertToYearlyValues(
          '100000.0',
          this.startYear,
          this.endYear,
        ),
        yearlyBuildCost: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyIndivisibleCost: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyFOAndMCharge: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyFOAndMInstall: convertToYearlyValues(
          '0.0',
          this.startYear,
          this.endYear,
        ),
        yearlyTechnicalLife: convertToYearlyValues(
          '20',
          this.startYear,
          this.endYear,
        ),
        yearlyDepreciationTime: convertToYearlyValues(
          '20',
          this.startYear,
          this.endYear,
        ),
      },
      isMultiNode: this.isMultiNode,
      equipment$: this.equipmentList$,
      outputEnergyVectorOptions: this.evOptions,
      currentNetting: this.currentNetting,
      scenarioIdentity: this.scenarioIdentity,
      startYear: this.startYear,
      endYear: this.endYear,
      hasMobilityFeature: this.hasMobilityFeature,
      equipmentTypeOptions: this.references['types']
        ? this.references['types']
        : this.getTypes(),
      scenarioVariationOptions: this.scenarioVariationOptions,
      hasTdbAcces: this._hasTdbAcces.value,
    };
    this.injectTDBAsProfileOption(data);
    return data;
  }

  generateDuplicateLoadData(data: any) {
    return {
      mode: 'duplicate',
      equipment: {
        ...data,
        operatingCostProfiles: this.duplicateOperatingCost(
          data.operatingCostProfiles,
        ),
      },
    };
  }

  duplicateOperatingCost(costs) {
    if (!costs) {
      return [];
    }

    return costs.map((cost) => ({
      ...cost,
      profiles: mapProfilesToDuplicateAndPatchToCloneControl(
        cost.profiles,
        this.form.get('binToClone'),
      ),
    }));
  }

  private resolveNodesInitialValue(): string[] {
    const nodes = this.nodeQuery.getActiveOptions();
    return nodes.length > 0 ? [nodes[0].value] : [];
  }

  /**
   * For table searh filter, find matching energy vector from list
   */
  equipmentsSearchPredicate = (data: Equipment, filter: string) =>
    containsSubstring(data.name, filter);

  private subscribeToFormForCompletionTracking(): void {
    const strategy = new EquipmentCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForEquipment(strategy.determineStatus(form));
    });
  }

  private selectActiveReferences() {
    return combineLatest([
      this.energyVectorQuery.selectActiveVectors(),
      this.nodeQuery.selectActiveNodes(),
      this.variationQuery.selectActiveVariation(),
    ]).pipe(
      map(([vectors, nodes, variations]) => {
        const output = {
          energyVectors: this.objectify(vectors),
          nodes: this.objectify(nodes),
          types: this.objectify(this.getTypes()),
          scenarioVariations: this.objectify(variations),
        };
        return output;
      }),
    );
  }

  private objectify(data: unknown[]) {
    return toObject(data, 'value', 'name');
  }

  private injectTDBAsProfileOption(data) {
    if (this._hasTdbAcces.value) {
      data.profileOptions.push({ name: 'Transversal DB', value: 'tdb' });
    }
  }

  private deduceTDBAccess() {
    this._authService
      .hasT3AAccess()
      .subscribe((has) => this._hasTdbAcces.next(has));
  }
}
