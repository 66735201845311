import { StateFacadeService } from 'prosumer-app/libs/eyes-shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { RenewableProfile, UserRenewableProfile } from '../models';
import { RenewableProfileState } from './renewable-profile-state.model';
import * as RenewableProfileActions from './renewable-profile.actions';
import { renewableProfileStateFactory } from './renewable-profile.factory';
import { renewableProfileQueries } from './renewable-profile.selectors';

@Injectable()
export class RenewableProfileFacadeService extends StateFacadeService<
  RenewableProfileState,
  RenewableProfile
> {
  userProfileList$ = this.store.pipe(
    select(renewableProfileQueries.userProfilesList),
  );
  renewableProfiles$ = this.store.pipe(
    select(renewableProfileQueries.renewableProfiles),
  );
  downloading$ = this.store.pipe(select(renewableProfileQueries.downloading));
  submitting$ = this.store.pipe(select(renewableProfileQueries.submitting));

  loadingProfileList$: Observable<boolean> = combineLatest([
    this.userProfileList$,
    this.renewableProfiles$,
  ]).pipe(
    map(([profileList, profiles]) => {
      if (!profileList || !profiles) {
        return true;
      }
      return profileList.length > 0 && profiles.length === 0;
    }),
  );

  constructor(
    public store: Store<RenewableProfileState>,
    public actionSubject$: ActionsSubject,
  ) {
    super(store, renewableProfileStateFactory, actionSubject$);
  }

  createRenewableProfile(data: RenewableProfile) {
    this.store.dispatch(
      new RenewableProfileActions.CreateRenewableProfile({ data }),
    );
  }

  getUserRenewableProfileList() {
    this.store.dispatch(
      new RenewableProfileActions.GetUserRenewableProfileList(),
    );
  }

  getRenewableProfileFromList(list: Array<UserRenewableProfile>) {
    list.forEach((userProfile) => {
      this.store.dispatch(
        new RenewableProfileActions.GetRenewableProfile({ data: userProfile }),
      );
    });
  }

  downloadRenewableProfile(data: RenewableProfile) {
    this.store.dispatch(
      new RenewableProfileActions.DownloadRenewableProfile({ data }),
    );
  }

  updateRenewableProfile(data: UserRenewableProfile) {
    this.store.dispatch(
      new RenewableProfileActions.UpdateRenewableProfile({ data }),
    );
  }
}
