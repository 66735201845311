import { AllActions } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { RenewableProfile, UserRenewableProfile } from '../models';

export enum ActionTypes {
  CREATE_RENEWABLE_PROFILE = '[Renewable Profile API] Create Renewable Profile',
  CREATE_RENEWABLE_PROFILE_SUCCESS = '[Renewable Profile API] Create Renewable Profile Success',
  CREATE_RENEWABLE_PROFILE_FAILURE = '[Renewable Profile API] Create Renewable Profile Fail',
  GET_USER_RENEWABLE_PROFILE_LIST = '[Renewable Profile API] Get User Renewable Profile List',
  GET_USER_RENEWABLE_PROFILE_LIST_SUCCESS = '[Renewable Profile API] Get User Renewable Profile List Success',
  GET_USER_RENEWABLE_PROFILE_LIST_FAILURE = '[Renewable Profile API] Get User Renewable Profile List Fail',
  GET_RENEWABLE_PROFILE = '[Renewable Profile API] Get Renewable Profile',
  GET_RENEWABLE_PROFILE_SUCCESS = '[Renewable Profile API] Get Renewable Profile Success',
  GET_RENEWABLE_PROFILE_FAILURE = '[Renewable Profile API] Get Renewable Profile Fail',
  DOWNLOAD_RENEWABLE_PROFILE = '[Renewable Profile API] Download',
  DOWNLOAD_RENEWABLE_PROFILE_SUCCESS = '[Renewable Profile API] Download Profile Success',
  DOWNLOAD_RENEWABLE_PROFILE_FAILURE = '[Renewable Profile API] Download Profile Fail',
  UPDATE_RENEWABLE_PROFILE = '[Renewable Profile API] Update Renewable Profile',
  UPDATE_RENEWABLE_PROFILE_SUCCESS = '[Renewable Profile API] Update Renewable Profile Success',
  UPDATE_RENEWABLE_PROFILE_FAILURE = '[Renewable Profile API] Update Renewable Profile Failure',
}

export class CreateRenewableProfile implements Action {
  readonly type = ActionTypes.CREATE_RENEWABLE_PROFILE;
  constructor(public payload?: { data?: RenewableProfile }) {}
}

export class CreateRenewableProfileFailure implements Action {
  readonly type = ActionTypes.CREATE_RENEWABLE_PROFILE_FAILURE;
  constructor(public payload?: { message?: string }) {}
}

export class CreateRenewableProfileSuccess implements Action {
  readonly type = ActionTypes.CREATE_RENEWABLE_PROFILE_SUCCESS;
  constructor(public payload?: { data?: RenewableProfile; message?: string }) {}
}

export class GetUserRenewableProfileList implements Action {
  readonly type = ActionTypes.GET_USER_RENEWABLE_PROFILE_LIST;
  constructor(public payload?: { message?: string }) {}
}

export class GetUserRenewableProfileListFailure implements Action {
  readonly type = ActionTypes.GET_USER_RENEWABLE_PROFILE_LIST_FAILURE;
  constructor(public payload?: { message?: string }) {}
}

export class GetUserRenewableProfileListSuccess implements Action {
  readonly type = ActionTypes.GET_USER_RENEWABLE_PROFILE_LIST_SUCCESS;
  constructor(
    public payload?: { data?: Array<RenewableProfile>; message?: string },
  ) {}
}

export class GetRenewableProfile implements Action {
  readonly type = ActionTypes.GET_RENEWABLE_PROFILE;
  constructor(public payload?: { data?: UserRenewableProfile }) {}
}

export class GetRenewableProfileFailure implements Action {
  readonly type = ActionTypes.GET_RENEWABLE_PROFILE_FAILURE;
  constructor(public payload?: { message?: string }) {}
}

export class GetRenewableProfileSuccess implements Action {
  readonly type = ActionTypes.GET_RENEWABLE_PROFILE_SUCCESS;
  constructor(public payload?: { data?: RenewableProfile; message?: string }) {}
}

export class DownloadRenewableProfile implements Action {
  readonly type = ActionTypes.DOWNLOAD_RENEWABLE_PROFILE;
  constructor(public payload?: { data?: RenewableProfile }) {}
}

export class DownloadProfileFailure implements Action {
  readonly type = ActionTypes.DOWNLOAD_RENEWABLE_PROFILE_FAILURE;
  constructor(public payload?: { message?: string }) {}
}

export class DownloadProfileSuccess implements Action {
  readonly type = ActionTypes.DOWNLOAD_RENEWABLE_PROFILE_SUCCESS;
  constructor(public payload?: { message?: string }) {}
}

export class UpdateRenewableProfile implements Action {
  readonly type = ActionTypes.UPDATE_RENEWABLE_PROFILE;
  constructor(public payload?: { data?: UserRenewableProfile }) {}
}

export class UpdateRenewableProfileFailure implements Action {
  readonly type = ActionTypes.UPDATE_RENEWABLE_PROFILE_FAILURE;
  constructor(public payload?: { message?: string }) {}
}

export class UpdateRenewableProfileSuccess implements Action {
  readonly type = ActionTypes.UPDATE_RENEWABLE_PROFILE_SUCCESS;
  constructor(public payload?: { data?: RenewableProfile; message?: string }) {}
}

/* Actions Union */
export type All =
  | AllActions<RenewableProfile>
  | CreateRenewableProfile
  | CreateRenewableProfileFailure
  | CreateRenewableProfileSuccess
  | GetUserRenewableProfileList
  | GetUserRenewableProfileListFailure
  | GetUserRenewableProfileListSuccess
  | GetRenewableProfile
  | GetRenewableProfileFailure
  | GetRenewableProfileSuccess
  | DownloadRenewableProfile
  | DownloadProfileFailure
  | DownloadProfileSuccess
  | UpdateRenewableProfile
  | UpdateRenewableProfileSuccess
  | UpdateRenewableProfileFailure;
