import { Coerce } from 'prosumer-app/core/utils';
import { RegulationBE, RegulationBERsp } from 'prosumer-app/stores/regulation';
import { YearValues } from 'prosumer-app/stores/scenario-generic';
import {
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
  YearlyValues,
} from 'prosumer-shared';
import { Metering, RegulationsForm } from '../../models';

export class RegulationsMapper {
  static mapToBackend = (regulationsForm: RegulationsForm) =>
    !!regulationsForm
      ? {
          meterings: RegulationsMapper.meteringsToBE(regulationsForm.meterings),
        }
      : {};

  static mapToFrontend = (
    scenario: { meterings: Array<any> },
    startYear?: number,
    endYear?: number,
  ): RegulationsForm =>
    !!scenario
      ? {
          meterings: RegulationsMapper.meteringsToFE(scenario.meterings),
        }
      : {};

  static meteringToBE(metering: Metering): RegulationBE {
    return {
      id: metering.id,
      technologyId: metering.technologyId,
      netting: metering.netting,
      autoConsumptionTax: mapYearlyValuesToBackend(
        metering.yearlyAutoConsumptionTax,
      ),
      generationTariff: mapYearlyValuesToBackend(
        metering.yearlyGenerationTariff,
      ),
      feedInTariff: mapYearlyValuesToBackend(metering.yearlyFeedInTariff),
      feedInPremium: mapYearlyValuesToBackend(metering.yearlyFeedInPremium),
    };
  }

  static meteringToFE(metering: RegulationBERsp): Metering {
    return {
      id: metering.id,
      technologyId: metering.technologyId,
      netting: metering.netting,

      yearlyAutoConsumptionTax: RegulationsMapper.parseAndMapYearValuesToFE(
        metering.autoConsumptionTax,
      ),
      yearlyGenerationTariff: RegulationsMapper.parseAndMapYearValuesToFE(
        metering.generationTariff,
      ),
      yearlyFeedInTariff: RegulationsMapper.parseAndMapYearValuesToFE(
        metering.feedInTariff,
      ),
      yearlyFeedInPremium: RegulationsMapper.parseAndMapYearValuesToFE(
        metering.feedInPremium,
      ),
    };
  }

  private static parseAndMapYearValuesToFE(inString: string): YearlyValues {
    return mapYearlyValuesToFrontend(
      RegulationsMapper.parseYearValues(inString),
    );
  }

  private static parseYearValues(alleged: string): YearValues {
    return JSON.parse(Coerce.toString(alleged));
  }

  private static meteringsToFE(meterings: RegulationBERsp[]): Metering[] {
    return Coerce.toArray(meterings).map((metering) =>
      RegulationsMapper.meteringToFE(metering),
    );
  }

  private static meteringsToBE(
    meterings: Array<Metering>,
  ): Array<RegulationBE> {
    return Coerce.toArray(meterings).map((metering) =>
      RegulationsMapper.meteringToBE(metering),
    );
  }
}
