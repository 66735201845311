import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  FormFieldOption,
  contains,
  sortByName,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Asset, AssetLink, FluidConfig, NodeViewData } from '../../models';
import { SystemVisualizationService } from '../../services';
import {
  CUSTOM_FLUID_COLOR_PALETTE,
  PREDEFINED_FLUID_LABEL_CONFIG,
  SYS_VIS_FLUID_CONFIG,
} from '../../system-visualization.token';
import { BaseContainerComponent } from '../base-container.component';

@Component({
  selector: 'prosumer-node-view-container',
  templateUrl: './node-view-container.component.html',
  styleUrls: ['./node-view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NodeViewContainerComponent
  extends BaseContainerComponent<NodeViewData>
  implements AfterViewInit
{
  private pngData: string;

  clickedReset = new Subject<void>();
  positionReset = new BehaviorSubject<void>(void 0);
  @Input() set data(value: NodeViewData) {
    this._data = value;
    this.setNodeOptions(this.data);
    this.setDefaultSelectedNode();

    if (!!this.data && !!this.data.assetLinks) {
      this.updateFluidConfigData(this.data.assetLinks);
    }
  }
  get data() {
    return this._data;
  }

  nodeOptions: Array<FormFieldOption<string>> = [];

  selectedNodeControl = new UntypedFormControl('');

  data$ = combineLatest([
    this.selectedNodeControl.valueChanges.pipe(
      startWith(this.selectedNodeControl.value),
    ),
    this.showOptimized$,
    this.selectedFluids$,
    this.positionReset,
  ]).pipe(
    map(([selectedNode, showOptimized, fluids]) => {
      const filteredData = this.filterBySelectedNode(selectedNode, this.data);
      const toggledData = this.toggleOptimizedElements(
        filteredData,
        showOptimized,
        'assets',
      );
      const updatedData = this.hideLinksWithHiddenNodes(toggledData);
      if (!!fluids) {
        return this.toggleElementsFromSelectedFluids(updatedData, fluids);
      }
      return updatedData;
    }),
    this.takeUntilShare(),
  );

  fluids$ = this.data$.pipe(
    filter((data) => !!data),
    map((data) =>
      this.filterFluids(
        data.assetLinks.filter(
          (links) =>
            !(links.hideable && this.showOptimized$.value && links.hidden),
        ),
        'fluid',
      ),
    ),
    this.takeUntilShare(),
  );

  constructor(
    public systemVisualization: SystemVisualizationService,
    @Optional() @Inject(SYS_VIS_FLUID_CONFIG) public fluidConfig: FluidConfig,
    @Optional()
    @Inject(PREDEFINED_FLUID_LABEL_CONFIG)
    public fluidLabelConfig: { [key: string]: string },
    @Optional()
    @Inject(CUSTOM_FLUID_COLOR_PALETTE)
    public customFluidColorPalette: Array<string>,
  ) {
    super(fluidConfig, fluidLabelConfig, customFluidColorPalette);
  }

  ngAfterViewInit() {
    this.initSelectedNodeHandler();
  }

  filterBySelectedNode(selectedNode: string, nodesViewData: NodeViewData) {
    if (!!selectedNode && !!nodesViewData) {
      const assets: Array<Asset> = (nodesViewData.assets || []).filter(
        (asset) => asset.node === selectedNode,
      );
      const assetLinks: Array<AssetLink> = (
        nodesViewData.assetLinks || []
      ).filter((assetLink) =>
        assets.find(
          (asset) =>
            asset.id === assetLink.sourceId || asset.id === assetLink.targetId,
        ),
      );

      return { assets, assetLinks };
    }
    return nodesViewData;
  }

  hideLinksWithHiddenNodes(nodeViewData: NodeViewData) {
    if (!nodeViewData || !nodeViewData.assets || !nodeViewData.assetLinks) {
      return nodeViewData;
    }

    const hiddenNodes = nodeViewData.assets
      .filter((asset) => asset.hidden)
      .map((item) => item.id);

    const linksWithHiddenNodes = nodeViewData.assetLinks
      .filter(
        (link) =>
          contains(hiddenNodes, link.sourceId) ||
          contains(hiddenNodes, link.targetId),
      )
      .map((connection) => ({ ...connection, hidden: true }));

    const linksWithVisibleNodes = nodeViewData.assetLinks.filter(
      (link) =>
        !contains(
          linksWithHiddenNodes.map((item) => item.id),
          link.id,
        ),
    );

    const combinedLinks = [...linksWithVisibleNodes, ...linksWithHiddenNodes];

    return { ...nodeViewData, assetLinks: combinedLinks };
  }

  setNodeOptions(nodesViewData: NodeViewData) {
    this.nodeOptions = [];
    if (!!nodesViewData) {
      const nodeSet = new Set<any>();
      nodesViewData.assets.forEach((asset) => nodeSet.add(asset.node));
      nodeSet.forEach((node) =>
        this.nodeOptions.push({ name: node, value: node }),
      );
      this.nodeOptions.sort(sortByName);
    }
  }

  setDefaultSelectedNode() {
    if (this.nodeOptions && this.nodeOptions.length > 0) {
      this.selectedNodeControl.patchValue(this.nodeOptions[0].value);
      this.systemVisualization.setDefaultNode(this.nodeOptions[0].value);

      if (!!this.systemVisualization.getTappedNode()) {
        this.systemVisualization.setSelectedNode(
          this.systemVisualization.getTappedNode(),
        );
        this.systemVisualization.setTappedNode('');
      } else {
        this.systemVisualization.setSelectedNode(
          this.systemVisualization.getDefaultNode(),
        );
      }
    }
  }

  onSelectedNodeChange(change: MatSelectChange) {
    this.systemVisualization.setSelectedNode(change.value);
  }

  initSelectedNodeHandler() {
    this.systemVisualization.selectedNode$
      .pipe(this.takeUntil())
      .subscribe((node) => {
        if (!!node) {
          this.selectedNodeControl.setValue(node);
        } else {
          this.setDefaultSelectedNode();
        }
      });
  }

  toggleEdgeElements(edges: Array<AssetLink>, fluids: Array<string>) {
    return [
      ...edges.map((edge) => ({
        ...(edge as any),
        hidden: edge.hidden || !fluids.includes(edge.fluid),
      })),
    ];
  }

  toggleNodeElements(
    assets: Array<Asset>,
    links: Array<AssetLink>,
  ): Array<Asset> {
    const enabledNodes = Array.from(
      new Set(
        [].concat(
          ...links
            .filter((edge) => !edge.hidden)
            .map((edge) => [edge.rawData.origin, edge.rawData.destination]),
        ),
      ),
    );
    return assets.map((node) => ({
      ...node,
      hidden: !enabledNodes.includes(node.id),
    }));
  }

  toggleElementsFromSelectedFluids(
    data: NodeViewData,
    fluids: Array<string>,
  ): NodeViewData {
    const links = this.toggleEdgeElements(data.assetLinks, fluids);
    const assets = this.toggleNodeElements(data.assets, links);
    const newData = { ...(data as any) };
    newData.assets = assets;
    newData.assetLinks = links;
    return { ...newData };
  }

  onPngDataChange(pngData: string): void {
    this.pngData = pngData;
  }

  onPositionReset(): void {
    this.positionReset.next();
  }

  onExportViz(): void {
    this.systemVisualization.exportNodeView(this.pngData);
  }

  onResetViz(): void {
    this.clickedReset.next();
  }
}
