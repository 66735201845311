import { Coerce } from 'prosumer-app/core/utils';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  TDBDataQuery,
  TDBDataStore,
  TDBFilterActions,
  TDBProfileActions,
} from 'prosumer-app/stores';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  EmissionFactorsListResponseModel,
  TdbActiveFilters,
} from '../../models';

const DEFAULT_ENABLE_TDB_MODULE = false;
export const DEFAULT_EMISSION_SCOPE = 'Scope 2';

@UntilDestroy()
@Component({
  selector: 'prosumer-tdb-emission-factors',
  templateUrl: './tdb-emission-factors.component.html',
  styleUrls: ['./tdb-emission-factors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdbEmissionFactorsComponent implements OnInit, OnDestroy {
  @Output() profileDataFetched = new EventEmitter();
  @Input() yearsList: number[];
  @Input() profileFilters: TdbActiveFilters | undefined;
  @Input() isTDBModuleOpen = DEFAULT_ENABLE_TDB_MODULE;
  @Input() filterAction = TDBFilterActions.emission;
  @Input() profileAction = TDBProfileActions.emission;

  readonly tdbFiltersForm = this._initForm();

  tdbCommodity$ = new BehaviorSubject<string | undefined>(undefined);
  isTdbModuleEnabled = new BehaviorSubject<boolean>(this.isTDBModuleOpen);
  tdbCategoryOptions$: Observable<Record<string, string>[]>;
  // TODO: improve into dynamic observable multi filters (instead of 1 observable per filter)
  tdbGeographyOptions$: Observable<Record<string, string>[]>;
  tdbItemOptions$: Observable<Record<string, string>[]>;
  tdbScenarioOptions$: Observable<Record<string, string>[]>;
  tdbScopeOptions$: Observable<Record<string, string>[]>;
  tdbUnitOptions$: Observable<Record<string, string>[]>;
  isTdbApiResponseLoading$: Observable<boolean>;
  isTdbFiltersLoading$: Observable<boolean>;

  constructor(
    private readonly tdbDataStore: TDBDataStore,
    private readonly tdbDataQuery: TDBDataQuery,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _notificationService: NotificationsService,
  ) {}

  ngOnDestroy(): void {
    this.tdbDataStore.update(this.tdbDataStore.initStoreValues());
  }

  ngOnInit() {
    this._initObservables();
    this._initSubs();
    // this._formatReference();
  }

  toggleTDBModule(value: boolean) {
    this.isTdbModuleEnabled.next(value);
  }

  private _initForm() {
    return this._formBuilder.group({
      category: [undefined, Validators.required],
      item: [undefined, Validators.required],
      geography: [undefined, Validators.required],
      scenario: [undefined, Validators.required],
      scope: [undefined, Validators.required],
      unit: [undefined, Validators.required],
    });
  }

  private _initObservables() {
    this.isTdbApiResponseLoading$ = this.tdbDataQuery
      .selectLoading()
      .pipe(untilDestroyed(this));
    this.isTdbFiltersLoading$ = this.isTdbApiResponseLoading$.pipe(
      map((loading) =>
        [loading, !!this.tdbFiltersForm.controls.scope.value].every(Boolean),
      ),
    );
    this.tdbCategoryOptions$ = this.getFilters('emissionCategory');
    this.tdbItemOptions$ = this.getFilters('item');
    this.tdbGeographyOptions$ = this.getFilters('geography');
    this.tdbScenarioOptions$ = this.getFilters('scenario');
    this.tdbScopeOptions$ = this.getFilters('scope');
    this.tdbUnitOptions$ = this.getFilters('unit');
  }

  private _initSubs() {
    this.subToToggleChange();
    this.subToScopeFilter();
    this.subToCompletedFilters();
  }

  private subToScopeFilter(): void {
    this.tdbFiltersForm.controls.scope.valueChanges
      .pipe(mergeMap(() => this.requestTDBFilters()))
      .subscribe();
  }

  private subToCompletedFilters(): void {
    this.tdbFiltersForm.valueChanges
      .pipe(
        filter(() => this.tdbFiltersForm.valid),
        tap((f) => this.tdbDataStore.updateActiveFilters(f)),
        map((f) => this.injectYearsToData(f, this.yearsList)),
        mergeMap((filters) =>
          this.tdbDataStore.getTDBData(filters, this.profileAction),
        ),
        map((r) => this.handleProfileDataResponse(r as any)),
        filter((r) => !!r),
      )
      .subscribe({
        next: (d) => this.profileDataFetched.emit(d),
      });
  }

  private handleProfileDataResponse(res: EmissionFactorsListResponseModel) {
    const singleData = res.data[0];
    if (!singleData) {
      this._notificationService.showError(
        'No data found for the selected filters',
      );
      return;
    }
    const yearlyValues = singleData.values;
    return yearlyValues;
  }

  private subToToggleChange() {
    this.isTdbModuleEnabled
      .asObservable()
      .pipe(
        untilDestroyed(this),
        filter((val) => !!val),
        takeUntil(this.tdbDataQuery.selectFilters()),
        mergeMap(() => this.requestTDBFilters()),
      )
      .subscribe((res) => this._checkErrorResToResetToggle(res));
  }

  private _checkErrorResToResetToggle(res) {
    if (!res.error) return;
    this.isTdbModuleEnabled.next(false);
  }

  private requestTDBFilters(): Observable<unknown> {
    return this.tdbDataStore.getTDBFilters(
      { scope: this.getScope() },
      this.filterAction,
    );
  }

  private getFilters(key: string): Observable<Record<string, string>[]> {
    return this.tdbDataQuery.selectFilters().pipe(
      untilDestroyed(this),
      map((filters) => Coerce.toArray<unknown>(filters[key]) as string[]),
      map((filters) => this._mapToFormSelect(filters)),
    );
  }

  private _mapToFormSelect(data: string[]): Record<string, string>[] {
    return data.map((option) => ({
      name: option,
      value: option,
    }));
  }

  private getScope(): string {
    const controls = Coerce.toObject(this.tdbFiltersForm.controls);
    return Coerce.toNullishCoalescedString(
      controls.scope.value,
      DEFAULT_EMISSION_SCOPE,
    );
  }

  private injectYearsToData(data, years: number[]) {
    return {
      ...data,
      startYear: years[0],
      endYear: years[1],
    };
  }
}
