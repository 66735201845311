import { Profile } from 'prosumer-app/+scenario';
import { mapSickProfiles } from 'prosumer-app/shared/utils';

import { AbstractControl, ValidatorFn } from '@angular/forms';

export class YearlyLoadsIntevalValidators {
  /**
   * Checks if the profile is a valid yearly loads interval profile
   *
   * @param profile - the load profile
   * @param yearlyLoadEnabled - defines if the yearly load field is displayed or not (default: true)
   * @param alwaysCustom - defines if the load type is always 'custom' disabling the load type field (default: false)
   * @param useLibraryPanel - defines if the library panel will be displayed which includes the load type and the
   * library table (default: true)
   */
  static isInvalid(
    profile: Profile,
    yearlyLoadEnabled = true,
    alwaysCustom = false,
    useLibraryPanel = true,
  ) {
    const invalid =
      !!profile &&
      // Load type validation
      (!profile.loadType ||
        // Yearly load validation - must be positive
        (yearlyLoadEnabled &&
          (!profile.yearlyLoad || Number(profile.yearlyLoad) <= 0)) ||
        // Library validation
        (!alwaysCustom &&
          useLibraryPanel &&
          profile.loadType === 'library' &&
          !profile.library) ||
        // Load profile (binary data) validation
        (profile.forSaving &&
          (!profile.loadProfile || profile.loadProfile.length === 0)));
    return invalid;
  }

  /**
   * Form validator to check if the profiles are valid yearly loads interval profiles
   *
   * @param yearlyLoadEnabled - defines if the yearly load field is displayed or not (default: true)
   * @param alwaysCustom - defines if the load type is always 'custom' disabling the load type field (default: false)
   * @param useLibraryPanel - defines if the library panel will be displayed which includes the load type and the
   * library table (default: true)
   */
  static yearlyLoadsValid(
    yearlyLoadEnabled = true,
    alwaysCustom = false,
    useLibraryPanel = true,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { profiles: value } = mapSickProfiles({ profiles: control.value });
      if (!control || !value || value.length === 0) {
        return { yearlyLoadsInvalid: null };
      }

      if (
        value[0] &&
        value[0]['loadError'] &&
        value[0]['loadError'] !== undefined
      ) {
        return { yearlyLoadsInvalid: null };
      }

      const profiles = value as Array<Profile>;
      if (profiles && profiles.length && profiles.some((profile) => !profile)) {
        return { yearlyLoadsInvalid: profiles };
      }

      if (
        profiles &&
        profiles.length &&
        profiles.some((profile) =>
          YearlyLoadsIntevalValidators.isInvalid(
            profile,
            yearlyLoadEnabled,
            alwaysCustom,
            useLibraryPanel,
          ),
        )
      ) {
        return { yearlyLoadsInvalid: profiles };
      }
      return null;
    };
  }
}
