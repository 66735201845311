import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { NotificationToolbarComponent } from './notification-toolbar.component';

@NgModule({
  declarations: [NotificationToolbarComponent],
  imports: [CommonModule, EyesSharedModule.forFeature()],
  exports: [NotificationToolbarComponent],
})
export class NotificationToolbarModule {}
