<div fxLayoutGap="16px" fxLayoutAlign="start center">
  <div fxLayout="column" fxFlex>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{
        'Project.labels.sharing.shareViaLink' | translate
      }}</mat-label>
      <input
        *ngIf="generating; else displayLink"
        data-testid="generatingLink"
        matInput
        readonly
        [value]="'Project.placeholders.sharing.generatingLink' | translate"
      />
      <ng-template #displayLink>
        <input
          *ngIf="!!error; else allGood"
          class="error-link"
          data-testid="errorLink"
          matInput
          readonly
          [value]="error"
        />
      </ng-template>
      <ng-template #allGood>
        <input matInput [value]="link" data-testid="link" readonly />
      </ng-template>
    </mat-form-field>
  </div>
  <button
    *ngIf="!copied; else hasCopied"
    mat-flat-button
    color="primary"
    (click)="onCopy()"
    [disabled]="generating || !!error"
    data-testid="copyLink"
  >
    {{ 'Generic.labels.copyLink' | translate | uppercase }}
  </button>
  <ng-template #hasCopied>
    <button fxFlex="108px" mat-flat-button disabled data-testid="copied">
      {{ 'Generic.labels.copied' | translate | uppercase }}!
    </button>
  </ng-template>
</div>
