export type Receiver = Readonly<{
  name: string;
  id: string;
}>;

export type Sender = Readonly<{
  name: string;
  id: string;
}>;

export type NotifData = Readonly<{
  type: string;
  code: string;
  projectId: string;
  caseId: string;
  scenarioId: string;
  generated_by: string;
}>;

export type Notification = Readonly<{
  id?: string;
  type?: string;
  subject?: string;
  body?: string;
  receiver?: Receiver | Array<Receiver>;
  sender?: Sender;
  dateTimeSent?: string;
  dateTimeReceived?: string;
  attachments?: any | Array<any>;
  status?: string;
  code?: number;
  data?: Array<NotifData>;
  // Important flattened data
  projectId?: string;
  projectName?: string;
  caseId?: string;
  caseName?: string;
  scenarioId?: string;
  scenarioName?: string;
  runStatus?: string;
  generatedBy?: string;
  exclusionCount?: number;
}>;

export type NotificationStoreState = Readonly<{
  loading?: boolean;
  loaded?: boolean;
  notifications?: Array<Notification>;
  error?: any;
  nonSilentNotifications?: Array<Notification>;
}>;

export const mapNotificationToFrontend = (notification: any): Notification => ({
  id: notification.uuid,
  type: notification.type,
  subject: notification.subject,
  body: notification.body,
  receiver: {
    name: (notification.receiver || {}).name,
    id: (notification.receiver || {}).userUuid,
  },
  sender: {
    name: (notification.sender || {}).name,
    id: (notification.sender || {}).userUuid,
  },
  dateTimeSent: notification.sent,
  dateTimeReceived: notification.received,
  attachments: notification.attachments,
  status: notification.status,
  code: (notification.data || {}).code,
  data: notification.data,
  projectId: (notification.data || {}).projectUuid,
  projectName: (notification.data || {}).projectName,
  caseId: (notification.data || {}).caseUuid,
  caseName: (notification.data || {}).caseName,
  scenarioId: (notification.data || {}).scenarioUuid,
  scenarioName: (notification.data || {}).scenarioName,
  runStatus: ((notification.body || ':').split(':')[1] || '')
    .toLowerCase()
    .trim(),
  generatedBy: (notification.data || {}).generated_by,
  exclusionCount: (notification.data || {}).exclusion_count,
});

export const mapNotificationToBackend = (notification: Notification): any => ({
  uuid: notification.id,
});
