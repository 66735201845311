import { ResultsData } from '@prosumer/results/models';
import { Utils } from 'prosumer-core';
import {
  ResultNameValue,
  ResultsVisualizerService,
  VisualizerData,
} from '../results-visualizer';
import {
  ReducedResultNameValues,
  ReducedSliceData,
} from './bar-and-area-results.models';

export abstract class YearOptimizerStrategy<
  T extends ResultsData = ResultsData,
> extends ResultsVisualizerService<T> {
  optimizeYearsForIncremental(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[] {
    return data.map((single) => ({
      ...single,
      series: single.series.filter((siri) => years.includes(Number(siri.name))),
    }));
  }

  optimizeYearsForCumulative(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[] {
    return data.map((single) => ({
      ...single,
      series: single.series.filter((siri) => years.includes(Number(siri.name))),
    }));
  }
}

export abstract class GroupedSlicesStrategy<
  T extends ResultsData = ResultsData,
> extends YearOptimizerStrategy<T> {
  abstract buildSliceData(raw: T[]): ReducedSliceData;

  mapRawDataToIncremental(raw: T[], slice: string): VisualizerData[] {
    const sliceData = this.buildSliceData(raw);
    return this.mapReducedNameValuesToCumulative(sliceData[slice]);
  }

  mapRawDataToCumulative(raw: T[], slice: string): VisualizerData[] {
    const sliceData = this.buildSliceData(raw);
    return this.mapReducedNameValuesToCumulative(sliceData[slice]);
  }

  private mapReducedNameValuesToCumulative(
    reduced: ReducedResultNameValues,
  ): VisualizerData[] {
    return Object.entries(reduced).map(([name, series]) => {
      const reducedSeries = series.reduce((acc, { name, value }) => {
        acc[name] = acc[name] || { name, value: 0 };
        acc[name].value += value;
        return acc;
      }, {});
      return { name, series: Object.values(reducedSeries) };
    });
  }
}

export abstract class FlattenedSlicesStrategy<
  T extends ResultsData = ResultsData,
> extends GroupedSlicesStrategy<T> {
  buildSliceData(raw: T[]): ReducedSliceData {
    return this.getSlices().reduce((acc, curr) => {
      acc[curr.name] = this.buildReducedResultNameValues(raw, curr.name);
      return acc;
    }, {});
  }
  private buildReducedResultNameValues(
    list: T[],
    slice: string,
  ): ReducedResultNameValues {
    return list.reduce((acc, curr) => {
      const resultValue = this.buildResultNameValues(list, curr.name, slice);
      if ((resultValue || []).every((value) => !!!value.value)) {
        return acc;
      }

      acc[curr.name] = resultValue;
      return acc;
    }, {});
  }

  private buildResultNameValues(
    list: T[],
    name: string,
    slice: string,
  ): ResultNameValue[] {
    const respectiveList = list.filter((l) => l.name === name);
    const years = this.collectYears(list);
    return years.map((year) => ({
      name: String(year),
      value: this.findEquipmentResult(respectiveList, name, year)[slice],
    }));
  }

  private findEquipmentResult(list: T[], name: string, year: number): T {
    return Utils.resolveToEmptyObject(
      list.find((l) => l.name === name && l.year === year),
    );
  }

  private collectYears(from: T[]): number[] {
    return Array.from(
      new Set(Utils.resolveToEmptyArray(from).map((f) => f.year)),
    );
  }
}
