import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewLogsComponent } from './view-logs.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ViewLogsComponent],
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
  ],
  exports: [ViewLogsComponent],
})
export class ViewLogsModule {}
