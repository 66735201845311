import { TooltipModule } from 'prosumer-app/libs/eyes-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { SelectNewComponent } from './select-new.component';

@NgModule({
  declarations: [SelectNewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatIconModule,
    TooltipModule,
  ],
  exports: [SelectNewComponent, TranslateModule, LetDirective, PushPipe],
})
export class SelectNewModule {}
