import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { InputComponent } from '../input.component';

@Component({
  selector: 'prosumer-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent extends InputComponent {
  @Input() hasDatePicker: boolean;

  datepickerDate: UntypedFormControl = this._formBuilder.control(
    this.control.value,
  );

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();

    this.addSubscription(
      this.datepickerDate.valueChanges.subscribe((value) => {
        if (value && this.control) {
          this.control.patchValue(value);
        }
      }),
    );
  }
}
