<div fxLayout="row">
  <ngx-stars
    [readonly]="readonly"
    [wholeStars]="wholeStars"
    [size]="starSize"
    [maxStars]="maxStars"
    [initialStars]="initialStars"
    [color]="starColor"
    (ratingOutput)="handleRating($event)"
    data-testid="feedback-stars"
  ></ngx-stars>
</div>
