import { Netting } from 'prosumer-app/+scenario';
import { contains } from 'prosumer-app/libs/eyes-shared';

/** Return the nettings after filtering all equipment affected by cascading delete
 *
 * @param nettings  the remaining/latest nettings list
 * @param remainingEquipmentIds the remaining equipment ids of a scenario
 * @return filtered netting based on remaining equipment
 */
export const cascadeFilterNettingByEquipment = (
  nettings: Array<Netting>,
  remainingEquipmentIds: Array<string>,
) => {
  if (!!!nettings || !!!remainingEquipmentIds) {
    return undefined;
  }

  return nettings
    .filter((netting) =>
      netting.equipments.some((eqptId) =>
        contains(remainingEquipmentIds, eqptId),
      ),
    )
    .map((netting) => {
      const updatedItem = {
        ...netting,
        equipments: netting.equipments.filter((eqptId) =>
          contains(remainingEquipmentIds, eqptId),
        ),
      };
      return updatedItem;
    });
};
