import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    './assets/i18n/generic_',
    './assets/i18n/auth_',
    './assets/i18n/user_',
    './assets/i18n/project_',
    './assets/i18n/case_',
    './assets/i18n/scenario_',
    './assets/i18n/dashboard_',
    './assets/i18n/about_',
    './assets/i18n/result_',
    './assets/i18n/billing_',
    './assets/i18n/notification_',
    './assets/i18n/library_',
    './assets/i18n/scenario_messages_',
    './assets/i18n/support_',
    './assets/i18n/renewable_profile_',
    './assets/i18n/user_preferences_',
    './assets/i18n/tdb_',
  ]);
}
