import { Pipe, PipeTransform } from '@angular/core';

export enum MetricPrefix {
  base = 'base',
  kilo = 'kilo',
  mega = 'mega',
  giga = 'giga',
}

const METRIC_MULTIPLIER_MAP = {
  base: 1,
  kilo: 1000,
  mega: 1000000,
  giga: 1000000000,
};
@Pipe({
  name: 'prosumerMetric',
})
export class TheMetricPipe implements PipeTransform {
  transform(value: number | string, prefix: MetricPrefix): string {
    return this.getValues(value, prefix);
  }

  private getValues = (value: number | string, prefix: MetricPrefix) => {
    const multiplier = METRIC_MULTIPLIER_MAP[prefix];
    const numValue = parseFloat(String(value));
    let displayValue: string;
    if (!isNaN(numValue)) {
      displayValue = String(numValue / multiplier);
    }
    return displayValue;
  };
}
