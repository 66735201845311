/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HasRole, Project, ProjectRole } from 'prosumer-app/+project/models';
import { Scenario } from 'prosumer-app/+scenario/models';
import {
  PermissionCheckerService as EyesPermissionCheckerService,
  User,
} from 'prosumer-app/libs/eyes-core';
import { Permissions } from './permission-checker.types';
@Injectable({
  providedIn: 'root',
})
export class PermissionCheckerService extends EyesPermissionCheckerService {
  isUserPermitted(
    project: Project,
    user: User,
    scenario?: Scenario,
  ): Permissions {
    return {
      CAN_DELETE: this.canDelete(project),
      CAN_UPDATE: this.canUpdate(project),
      CAN_SHARE: this.canShare(project),
    };
  }

  private canDelete(project: HasRole): boolean {
    return this.getEditingRoles().includes(project.role);
  }

  private canUpdate(project: HasRole): boolean {
    return this.getEditingRoles().includes(project.role);
  }

  private canShare(project: HasRole): boolean {
    return project.role === ProjectRole.owner;
  }

  private getEditingRoles(): ProjectRole[] {
    return [ProjectRole.edit, ProjectRole.owner, null];
  }
}
