import { Utils } from 'prosumer-app/core';
import { GeneralForm } from 'prosumer-scenario/models';
import { CompletionStrategy } from './completion-strategy';

export class GeneralCompletion extends CompletionStrategy<GeneralForm> {
  isFull(form: GeneralForm): boolean {
    return this.getRequired(form).every(Boolean);
  }
  isHalf(form: GeneralForm): boolean {
    return this.getRequired(form).some(Boolean);
  }

  private getRequired(form: GeneralForm): boolean[] {
    return [!!form.name, this.hasVectors(form), !!form.wacc];
  }

  private hasVectors(form: GeneralForm): boolean {
    const vectors = form.energyVectors;
    return [
      Utils.resolveToEmptyArray(vectors.others).length,
      Utils.resolveToEmptyArray(vectors.predefined).length,
    ].some(Boolean);
  }
}
