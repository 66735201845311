import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  transform(seriesData: Array<any>, mappedField?: any): any {
    if (!seriesData || seriesData.length === 0) {
      return 0;
    }

    let transformedSeriesData = seriesData;
    if (mappedField) {
      transformedSeriesData = seriesData.map(
        (series) => series[mappedField] || 0,
      );
    }

    return transformedSeriesData.reduce(
      (previousValue, currentValue) =>
        (previousValue || 0) + (currentValue || 0),
    );
  }
}
