import { Injectable } from '@angular/core';

@Injectable()
export class PermissionCheckerService {
  public predicateScope = {
    CAN_UPDATE: true,
    CAN_DELETE: true,
    CAN_SHARE: true,
  };

  constructor() {}

  isPermitted(users: any, data: any) {
    if (users[data.owner] && users[data.owner].userSessionId === data.owner) {
      return {
        CAN_UPDATE: true,
        CAN_DELETE: true,
        CAN_SHARE: true,
      };
    }
    return {
      CAN_UPDATE: false,
      CAN_DELETE: false,
      CAN_SHARE: false,
    };
  }

  grant(data: any) {
    if (!data || data.isOwned === undefined) {
      return this.predicateScope;
    }
    if (!data.isOwned) {
      this.predicateScope = {
        CAN_UPDATE: false,
        CAN_DELETE: false,
        CAN_SHARE: false,
      };
    } else {
      this.predicateScope = {
        CAN_UPDATE: true,
        CAN_DELETE: true,
        CAN_SHARE: true,
      };
    }

    return this.predicateScope;
  }

  combiningPredicates() {
    // construct an arbitrarily complex Boolean expression.
    // You may combine multiple privileges against the same object based on well-defined rules.
  }

  restrictingScope() {
    // apply a predicate to selected users, groups, or roles
    // without the administrator needing to introduce complicated logic into the predicate.
  }
}
