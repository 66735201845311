import { Action } from '@ngrx/store';
import { type } from 'prosumer-app/libs/eyes-shared';
import { OldRetrievalType, OldUser, mapUserToFrontend } from './user.model';

export const userFeatureName = 'user';

export const UserActionTypes = {
  CLEAR_FETCHED_USER: type(
    `[${userFeatureName} Clear User] Clear Fetched User`,
  ),
  FETCH_BY_ID: type(
    `[${userFeatureName} Fetch User] Get ${userFeatureName} by ID`,
  ),
  FETCH_BY_EMAIL: type(
    `[${userFeatureName} Fetch User] Get ${userFeatureName} by email`,
  ),
  FETCH_USER_SUCCESS: type(
    `[${userFeatureName} Fetch User] Get ${userFeatureName} SUCCESS`,
  ),
  FETCH_USER_FAILURE: type(
    `[${userFeatureName} Fetch User] Get ${userFeatureName} FAILURE`,
  ),

  GET_GDPR: type(
    `[${userFeatureName} GDPR Global] Get ${userFeatureName} GDPR Policy`,
  ),
  GET_GDPR_FAILURE: type(
    `[${userFeatureName} GDPR API] Get ${userFeatureName} GDPR Policy Failure`,
  ),
  GET_GDPR_SUCCESS: type(
    `[${userFeatureName} GDPR API] Get ${userFeatureName} GDPR Policy Success`,
  ),
  ACCEPT_GDPR: type(
    `[${userFeatureName} GDPR Policy Dialog] Accept ${userFeatureName} GDPR Policy`,
  ),
  ACCEPT_GDPR_FAILURE: type(
    `[${userFeatureName} GDPR API] Accept ${userFeatureName} GDPR Policy Failure`,
  ),
  ACCEPT_GDPR_SUCCESS: type(
    `[${userFeatureName} GDPR API] Accept ${userFeatureName} GDPR Policy Success`,
  ),
  CLEAR: type(`[${userFeatureName} Clear ${userFeatureName} Data`),
};

export class UserClearAction implements Action {
  readonly type = UserActionTypes.CLEAR;
}

export class UserGetGDPRAction implements Action {
  readonly type = UserActionTypes.GET_GDPR;
}

export class UserGetGDPRFailureAction implements Action {
  readonly type = UserActionTypes.GET_GDPR_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class UserGetGDPRSuccessAction implements Action {
  readonly type = UserActionTypes.GET_GDPR_SUCCESS;
  readonly payload: {
    latestVersion: string;
    text: string;
    acceptedVersion: string;
  };
  constructor(latestVersion: string, text: string, acceptedVersion: string) {
    this.payload = { latestVersion, text, acceptedVersion };
  }
}

export class UserAcceptGDPRAction implements Action {
  readonly type = UserActionTypes.ACCEPT_GDPR;
  readonly payload: { latestVersionToAccept: string };
  constructor(latestVersionToAccept: string) {
    this.payload = { latestVersionToAccept };
  }
}

export class UserAcceptGDPRFailureAction implements Action {
  readonly type = UserActionTypes.ACCEPT_GDPR_FAILURE;
  readonly payload: { latestVersionToAccept: string; error: string };
  constructor(latestVersionToAccept: string, error: string) {
    this.payload = { latestVersionToAccept, error };
  }
}

export class UserAcceptGDPRSuccessAction implements Action {
  readonly type = UserActionTypes.ACCEPT_GDPR_SUCCESS;
  readonly payload: { acceptedVersion: string };
  constructor(acceptedVersion: string) {
    this.payload = { acceptedVersion };
  }
}

export class FetchUserByEmailAction implements Action {
  readonly type = UserActionTypes.FETCH_BY_EMAIL;
  readonly payload: { emailAddress: string };
  constructor(emailAddress: string) {
    this.payload = { emailAddress };
  }
}

export class FetchUserByIDAction implements Action {
  readonly type = UserActionTypes.FETCH_BY_ID;
  readonly payload: { uuid: string };
  constructor(uuid: string) {
    this.payload = { uuid };
  }
}

export class ClearFetchedUser implements Action {
  readonly type = UserActionTypes.CLEAR_FETCHED_USER;
  constructor() {}
}

export class FetchUserSuccess implements Action {
  readonly type = UserActionTypes.FETCH_USER_SUCCESS;
  readonly payload: { data: OldUser; message: string };
  constructor(data: any, retrievalType: OldRetrievalType, message: string) {
    this.payload = {
      data: { ...mapUserToFrontend(data), retrievedUsing: retrievalType },
      message,
    };
  }
}

export class FetchUserFailure implements Action {
  readonly type = UserActionTypes.FETCH_USER_FAILURE;
  readonly payload: { faultyKey: string; error: string };
  constructor(faultyKey: string, error: string) {
    this.payload = { faultyKey, error };
  }
}

export type UserActions =
  | any
  | UserClearAction
  | UserGetGDPRAction
  | UserGetGDPRFailureAction
  | UserGetGDPRSuccessAction
  | UserAcceptGDPRAction
  | UserAcceptGDPRFailureAction
  | UserAcceptGDPRSuccessAction
  | FetchUserByEmailAction
  | FetchUserSuccess
  | ClearFetchedUser;
