import { Profile } from 'prosumer-app/+scenario/models';
import { AuthService } from 'prosumer-app/core/services';
import { Coerce, Utils } from 'prosumer-app/core/utils';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  contains,
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { TdbAdditionalCostsHelperService } from 'prosumer-app/shared/modules/tdb/services';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  FilterCriteria,
  ScenarioBinStore,
  TDBDataProfile,
  TDBDataQuery,
  TDBDataStore,
  TDBDataSummary,
} from 'prosumer-app/stores';
import {
  EnergyGridConnection,
  EnergyGridConnectionsDialogData,
  LibraryLoads,
} from 'prosumer-scenario';
import {
  convertToYearlyValues,
  getProfilesFromTDBData,
  getReferenceStr,
  getUpdatedListOfProfiles,
  getVariation,
  NameValidator,
  ProfileFormHelperService,
  YearlyLoadsIntevalValidators,
  YearlyValues,
} from 'prosumer-shared';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  takeLast,
  tap,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

const PROFILE_LOCATION = 'energyGridConnections.energyTariff';
const MAXIMUM_MARKET_NAME_LENGTH = 63;

type EGCWithCancerousNodes = EnergyGridConnection & {
  nodes: { nodes: string[] };
};

@UntilDestroy()
@Component({
  selector: 'prosumer-energy-grid-connections-form-dialog',
  templateUrl: './energy-grid-connections-dialog.component.html',
  styleUrls: ['./energy-grid-connections-dialog.component.scss'],
  providers: [ProfileFormHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyGridConnectionsFormDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  scenarioName: string;
  scenarioId: string;
  caseId: string;
  projectId: string;
  profileFilters: string;

  isMultiNode: boolean;
  energyGridConnectionsForm: UntypedFormGroup = this._formBuilder.group({
    marketName: ['', [Validators.required, NameValidator.validWithCore()]],
    nodes: [],
    delivery: '',
    type: '',
    scenarioVariation: '',
    profiles: [],
    capacityTariff: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    connectionTariff: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    co2Rate: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    startYear: this.data.startYear,
    endYear: this.data.endYear,
  });
  marketName: UntypedFormControl = this.energyGridConnectionsForm.get(
    'marketName',
  ) as UntypedFormControl;
  nodes: UntypedFormControl = this.energyGridConnectionsForm.get(
    'nodes',
  ) as UntypedFormControl;
  delivery: UntypedFormControl = this.energyGridConnectionsForm.get(
    'delivery',
  ) as UntypedFormControl;
  type: UntypedFormControl = this.energyGridConnectionsForm.get(
    'type',
  ) as UntypedFormControl;
  capacityTariff: UntypedFormControl = this.energyGridConnectionsForm.get(
    'capacityTariff',
  ) as UntypedFormControl;
  connectionTariff: UntypedFormControl = this.energyGridConnectionsForm.get(
    'connectionTariff',
  ) as UntypedFormControl;
  scenarioVariation: UntypedFormControl = this.energyGridConnectionsForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  co2Rate: UntypedFormControl = this.energyGridConnectionsForm.get(
    'co2Rate',
  ) as UntypedFormControl;
  deliveryOptions: Array<FormFieldOption<any>> = [];
  nodeOptions: Array<FormFieldOption<any>> = [];
  typeOptions: Array<FormFieldOption<any>> = [];
  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  energyTariffOptions: Array<FormFieldOption<any>> = [];
  submitted$ = new BehaviorSubject<boolean>(false);

  activeIndex = 0;
  errorMessages: FormFieldErrorMessageMap = {
    // marketName: {
    //   invalid: this._translate.instant('Scenario.messages.marketName.invalid'),
    //   required: this._translate.instant(
    //     'Scenario.messages.marketName.required',
    //   ),
    //   dataExist: this._translate.instant(
    //     'Scenario.messages.marketName.dataExist',
    //   ),
    //   combinationExist: this._translate.instant(
    //     'Scenario.messages.marketName.combinationExist',
    //   ),
    //   invalidCharacter: this._translate.instant(
    //     'Scenario.messages.marketName.invalidCharacter',
    //   ),
    //   maxLength: this._translate
    //     .instant('Scenario.messages.marketName.maxLength')
    //     .split('$n')
    //     .join(MAXIMUM_MARKET_NAME_LENGTH),
    // },
    nodes: {
      invalid: this._translate.instant('Scenario.messages.nodes.name.invalid'),
      required: this._translate.instant(
        'Scenario.messages.nodes.name.required',
      ),
    },
    delivery: {
      invalid: this._translate.instant('Scenario.messages.delivery.invalid'),
      required: this._translate.instant('Scenario.messages.delivery.required'),
    },
    scenarioVariation: {
      combinationExist: this._translate.instant(
        'Scenario.messages.energyGridConnections.marketName.combinationExist',
      ),
    },
    energyTariffLoads: {
      invalid: this._translate.instant('Scenario.messages.loads.invalid'),
      required: this._translate.instant('Scenario.messages.loads.required'),
      maxLines: this._translate.instant('Scenario.messages.loads.maxLines'),
    },
    capacityTariff: {
      invalid: this._translate.instant(
        'Scenario.messages.capacityTariff.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.capacityTariff.required',
      ),
    },
    connectionTariff: {
      invalid: this._translate.instant(
        'Scenario.messages.connectionTariff.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.connectionTariff.required',
      ),
    },
    co2Rate: {
      invalid: this._translate.instant(
        'Scenario.messages.connectionTariff.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.connectionTariff.required',
      ),
    },
  };

  yearlyLoadMessage: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.energyGridConnections.energyTariff',
    loadTypeTooltip: 'wizard_commodities.wizard_commodities_energy_tariff',
    loadsDataPlaceholder:
      'Scenario.placeholders.energyGridConnections.energyTariff.custom.loads',
    loadsDataLabelCustom: 'Scenario.labels.energyGridConnections.energyTariff',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };
  binaryLoading$: Observable<boolean>;

  startYear = 0;
  endYear = 0;

  updateValueFromEVSelection$: Observable<string>;
  hasTdbAccess$: Observable<boolean>;

  readonly mappedValue$ = this.selectMappedValue();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnergyGridConnectionsDialogData,
    public dialogRef: MatDialogRef<EnergyGridConnectionsFormDialogComponent>,
    public _scenarioBinStore: ScenarioBinStore,
    public tdbDataStore: TDBDataStore,
    public tdbDataQuery: TDBDataQuery,
    public profileFormHelperService: ProfileFormHelperService,
    private _formBuilder: UntypedFormBuilder,
    public dialog: DialogService,
    private _translate: TranslateService,
    private _authService: AuthService,
    private readonly detector: ChangeDetectorRef,
    private readonly _notification: NotificationsService,
    private readonly tdbAddCostsHelper: TdbAdditionalCostsHelperService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.tdbDataStore.reset();
    this.initScenarioEntity();
    this.initData();
    this.initOptions();
    this._subToEvForTDBModule();
    this._subToUserTDBAccess();
    this.subscribeToNotifyOnIssue();
  }

  ngAfterViewInit(): void {
    this.addProfilesCtrlValidator();
  }

  initOptions() {
    if (this.data.deliveryOptions) {
      this.data.deliveryOptions.forEach((option) =>
        this.deliveryOptions.push(option),
      );
    }

    if (this.data.nodeOptions) {
      this.data.nodeOptions.forEach((option) => this.nodeOptions.push(option));
    }

    if (this.data.typeOptions) {
      this.data.typeOptions.forEach((option) => this.typeOptions.push(option));
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioName = this.data.scenarioVariationOptions[0].value;
      this.data.scenarioVariationOptions.forEach((option) =>
        this.scenarioVariationOptions.push(option),
      );
    }

    if (this.data.energyTariffOptions) {
      this.data.energyTariffOptions.forEach((option) =>
        this.energyTariffOptions.push(option),
      );
    }
  }

  initData() {
    // For loading indicator to disable submit
    this.binaryLoading$ = this.getBinaryLoading();
    this.isMultiNode = this.data.isMultiNode;
    this.startYear = this.data.startYear;
    this.endYear = this.data.endYear;
    this.profileFilters = getReferenceStr(
      this.data.tdbFilters as Record<string, string>,
    );
    combineLatest([
      this.energyGridConnectionsForm.get('marketName').valueChanges,
      this.energyGridConnectionsForm.get('scenarioVariation').valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.validateMarketAndScenarioVariationCombination();
        this.energyGridConnectionsForm.controls.marketName.clearAsyncValidators();
        this.energyGridConnectionsForm.controls.scenarioVariation.clearAsyncValidators();
      });

    this.energyGridConnectionsForm.patchValue({
      marketName: this.data.marketName,
      nodes: this.data.nodes,
      delivery: this.data.delivery,
      type: this.data.type,
      scenarioVariation: this.initVariationFormInput(),
      capacityTariff: this.data.capacityTariff,
      connectionTariff: this.data.connectionTariff,
    });

    if (this.shouldLoadProfile() && this.data.profiles !== undefined) {
      this.energyGridConnectionsForm.controls.profiles.patchValue(
        this.data.profiles.map((profile) => ({
          ...profile,
          loadProfile: profile.loadProfile || [],
        })),
      );
      if (
        !this.data.profiles[0].loadProfile ||
        this.data.profiles[0].loadProfile.length === 0
      ) {
        this.loadBinaryData(this.data.profiles[0]);
      }
    }
  }

  onFilterChanged(filters: FilterCriteria): void {
    const { energyVector, additionalCosts, ...criteria } = filters;
    this.tdbDataStore
      .getTDBDataIntervals(String(energyVector), criteria)
      .pipe(take(1))
      .subscribe((data: TDBDataSummary) => {
        const profiles = getProfilesFromTDBData(
          [this.startYear, this.endYear],
          PROFILE_LOCATION,
          data.values,
        );
        // [Alternative 1] Load everything on select
        this.loadAllTDBData(profiles, additionalCosts as any);
      });
  }

  // TODO: Optimize & apply single responsibility
  // [Alternative 1] Load everything on select
  // [Alternative 2] TODO: modify to load all unloaded profiles before save
  private loadAllTDBData(
    profiles: Partial<Profile>[],
    additionalCosts: Record<string, YearlyValues>,
  ): void {
    const criteria = this.getLoadTDBDataParameters();
    const profilesItr = [...profiles];

    from(profilesItr)
      .pipe(
        mergeMap((profile: Partial<Profile>) => {
          criteria['year'] = profile.startYear;
          this.tdbAddCostsHelper.injectAdditionalCostsForYear(
            criteria,
            additionalCosts,
          );
          return this.tdbDataStore.getTDBData(criteria).pipe(
            filter(({ values }: TDBDataProfile) => !!values),
            tap(({ year, values }: TDBDataProfile) => {
              profiles = getUpdatedListOfProfiles(year, values, profiles);
            }),
          );
        }, 10),
        takeLast(1),
      )
      .subscribe(() =>
        this.energyGridConnectionsForm.controls.profiles.patchValue(profiles),
      );
  }

  private getLoadTDBDataParameters(): FilterCriteria {
    const { energyVector, geography, scenario, unit, granularity } =
      this.tdbDataQuery.getActiveFilters();
    return {
      energyVector,
      geography,
      scenario,
      unit,
      granularity,
    };
  }

  private subscribeToNotifyOnIssue(): void {
    this.tdbDataQuery
      .selectError()
      .pipe(
        filter((error) => !!error),
        filter(({ action }) => action === 'getPrices'),
      )
      .subscribe(({ error }) => this._notification.showInfo(error.message));
  }

  _subToEvForTDBModule() {
    this.updateValueFromEVSelection$ = this.delivery.valueChanges.pipe(
      filter((d) => !!d),
      map((d) => this.deliveryOptions.find((ev) => ev.value == d)['name']),
    );
  }

  private _subToUserTDBAccess() {
    this.hasTdbAccess$ = this._authService.hasT3AAccess();
  }

  private initVariationFormInput() {
    if (this.isEditMode()) {
      return this.data.scenarioVariation;
    }
    if (this.isAddMode() && this.data.scenarioVariationOptions.length > 1) {
      return this.data.scenarioVariationOptions[0].value;
    }
    return undefined;
  }

  validateMarketAndScenarioVariationCombination() {
    let hasDuplicate = false;
    const variation = getVariation(this.scenarioVariation.value);
    this.data.existingEnergyGridConnections$.forEach((connections) => {
      hasDuplicate = connections.some(
        ({ marketName, scenarioVariation, id }) =>
          variation === scenarioVariation &&
          this.marketName.value === marketName &&
          this.data.id !== id,
      );
    });

    if (hasDuplicate && this.scenarioVariationOptions.length <= 1) {
      this.marketName.setErrors({ dataExist: true });
    } else if (hasDuplicate && this.scenarioVariation?.value) {
      this.marketName.setErrors({ combinationExist: true });
      this.scenarioVariation.setErrors({ combinationExist: true });
    }
    // else if (!this.marketName.value) {
    //   this.marketName.setErrors({ required: true });
    //   this.scenarioVariation.setErrors(null);
    // } else if (this.marketName.value.length > MAXIMUM_MARKET_NAME_LENGTH) {
    //   this.marketName.setErrors({ maxLength: true });
    // } else if (!this.marketName.errors?.invalidCharacter) {
    //   this.marketName.setErrors(null);
    //   this.scenarioVariation.setErrors(null);
    // }
    else {
      this.resetErrors(this.marketName);
      this.resetErrors(this.scenarioVariation);
    }
  }
  private resetErrors(control: FormControl): void {
    const combinationErrors = ['dataExist', 'combinationExist'];
    // const nonCombinationErrors = combinationErrors.filter((error) =>
    //   control.hasError(error),
    // ).length;
    const nonCombinationErrors = Object.keys(
      Coerce.toObject(control.errors),
    ).filter((key) => !combinationErrors.includes(key)).length;
    if (nonCombinationErrors === 0) {
      control.setErrors(null);
    }
  }

  initScenarioEntity() {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isEmissionsAffected(deletedNodes: Array<string>): boolean {
    const emissionsToDelete = this.data.connectionsEmissions.filter(
      (emission) =>
        emission.marketName === this.data.id &&
        deletedNodes.includes(emission.node),
    );

    return emissionsToDelete.length > 0;
  }

  isLimitsAffected(deletedNodes: Array<string>): boolean {
    const limitsToDelete = this.data.energyGridLimits.filter(
      (limit) =>
        limit.market === this.data.id &&
        deletedNodes.some((node) => contains(limit.nodes, node)),
    );

    return limitsToDelete.length > 0;
  }

  loadBinaryData(selectedProfile: Profile) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1), filter(Boolean))
      .subscribe((loads: LibraryLoads) => {
        const profile = loads.data;
        this.profileFormHelperService.setProfile({
          index: this.activeIndex,
          profiles: profile,
          type: 'bin',
        });
      });
  }

  onSaveSuccess(): void {
    this.dialogRef.close('success');
  }

  onSaveAttempt(): void {
    // [Alternative 2] load all unloaded profiles before save
    // this.loadAllTDBData(this.energyGridConnectionsForm.controls.profiles.value);
    // [Alternative 2] TODO: need to perform below actions after loadAllTDBData has finished
    this.energyGridConnectionsForm.controls.profiles.updateValueAndValidity();
    this.profileFormHelperService.setSubmitted();
    this.submitted$.next(true);
    this.detector.detectChanges();
  }

  loadBinaryData$(selectedProfile: Profile) {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this._scenarioBinStore
        .get(
          this.projectId,
          this.caseId,
          this.scenarioId,
          selectedProfile.location,
          selectedProfile.originalLocalId || selectedProfile.localId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }) {
    this.activeIndex = value.index;
    if (
      this.shouldLoadProfile() &&
      this.profileFormHelperService.forBinDownload(
        this.data.profiles,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }

    // [Alternative 2] For Procedural loading (load data for tab when selected)
    // const activeFilters = this.tdbDataQuery.getActiveFilters();
    // if (
    //   value.selectedProfile.loadType === 'library' &&
    //   value.selectedProfile.isCustom === true &&
    //   Object.keys(activeFilters).length > 0
    // ) {
    //   const year = this.getYearOfProfileIndex(this.activeIndex);
    //   this.loadTDBData(activeFilters, year);
    // }
  }

  // TODO: we could probably use isEditMode instead
  shouldLoadProfile(): boolean {
    const isEditOrDuplicateMode = this.data.mode !== 'add';
    return isEditOrDuplicateMode;
  }

  // [Alternative 2] For Procedural loading (load data for tab when selected)
  // private loadTDBData(activeFilters: FilterCriteria, year: number): void {
  //   const { energyVector, geography, scenario, unit } = activeFilters;
  //   const criteria = { year, geography, scenario, unit };
  //   this.tdbDataStore
  //     .getTDBData(energyVector as string, criteria)
  //     .subscribe((data: TDBDataProfile) => {
  //       const dYear = Number(data.year);
  //       const profileIdx = this.getProfileIndexOfYear(dYear);
  //       const values = data.values as unknown[] as string[];
  //       this.profileFormHelperService.setProfile({
  //         index: profileIdx,
  //         profiles: values,
  //         type: 'lib',
  //       });
  //     });
  // }

  private selectMappedValue(): Observable<EnergyGridConnection> {
    return this.selectFormValue().pipe(
      map((patient) => this.cureCancerousNodes(patient)),
    );
  }

  private selectFormValue(): Observable<EGCWithCancerousNodes> {
    return this.energyGridConnectionsForm.valueChanges.pipe(
      startWith(this.energyGridConnectionsForm.value),
    );
  }

  private cureCancerousNodes(
    patient: EGCWithCancerousNodes,
  ): EnergyGridConnection {
    if (Array.isArray(patient.nodes)) {
      return patient;
    }
    return {
      ...patient,
      nodes: Utils.resolveToEmptyArray(
        (Utils.resolveToEmptyObject(patient.nodes) as any).nodes,
      ),
    };
  }

  // For loading indicator to disable submit
  private getBinaryLoading(): Observable<boolean> {
    return combineLatest([
      this._scenarioBinStore.loading$,
      this.tdbDataQuery.selectLoading(),
    ]).pipe(map((states) => states.some(Boolean)));
  }

  private isEditMode = () => this.data.mode === 'edit';

  private isAddMode = () => this.data.mode === 'add';

  private addProfilesCtrlValidator() {
    this.energyGridConnectionsForm.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true),
    ]);
    this.energyGridConnectionsForm.controls.profiles.updateValueAndValidity();
  }
}
