<div
  class="eyes-radio-button-container"
  fxLayout="row"
  fxLayoutGap="15px"
  fxLayoutAlign="start center"
>
  <label *ngIf="label">
    {{ label }}
    <prosumer-tooltip-anchor
      *ngIf="labelTooltip"
      [message]="labelTooltip"
      [tooltipPosition]="labelTooltipPosition"
    >
    </prosumer-tooltip-anchor>
  </label>
  <mat-radio-group
    fxLayout="row"
    fxLayoutGap="15px"
    [disabled]="isViewOnly"
    [formControl]="control"
  >
    <mat-radio-button
      *ngFor="let option of options"
      [value]="option?.value"
      [prosumerTooltip]="option?.tooltip"
      [tooltipPosition]="option?.tooltipPosition"
    >
      {{ option?.name }}
    </mat-radio-button>
  </mat-radio-group>
</div>
