import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { TdbCommodityOptionalFiltersComponent } from './tdb-commodity-optional-filters.component';
import { SharedModule } from 'prosumer-app/shared/shared.module';

@NgModule({
  declarations: [TdbCommodityOptionalFiltersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    EyesSharedModule,
    SharedModule,
  ],
  exports: [TdbCommodityOptionalFiltersComponent],
})
export class TdbCommodityFiltersModule {}
