import { Injectable } from '@angular/core';

import {
  exponentialBackoff,
  generateUuid,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { Observable, Subscription, interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

import { LoggerService } from './logger.service';

const DEFAULT_KEEP_ALIVE_INTERVAL = 480000;
const DEFAULT_RETRY_TIMES = 2;
const DEFAULT_RETRY_SCALING_DURATION_MS = 1000;
const LOG_ID_FIELD = 'x-correlation-id';

export type WebSocketServiceConfig = Readonly<{
  params?: { [key: string]: string };
  /* Handlers */
  handler?: any;
  errorHandler?: any;
  closeHandler?: any;
  /* Keep Alive */
  keepAlive?: boolean;
  keepAliveIntervalMs?: number;
  /* Retry */
  retryOnError?: boolean;
  retryOnClose?: boolean;
  maxRetryAttempts?: number;
  retryScalingDuration?: number;
}>;

@Injectable()
export class WebSocketService {
  /* WebSocket Observable */
  public wsObservable$: Observable<any>;

  /* Subscriptions */
  public wsSubscription: Subscription;
  public keepAliveSubscription: Subscription;
  public refreshSubscription: Subscription;

  /* WebSocket Subject */
  private wsSubject$: WebSocketSubject<MessageEvent>;

  /* Identifier */
  private id: string;

  /* Parameters */
  private url: string;
  private config: WebSocketServiceConfig;

  /* Retry */
  private retryAttempt = 0;

  constructor(private _logger: LoggerService) {}

  send(message: any): void {
    if (this.wsSubject$) {
      this.wsSubject$.next(message);
    }
  }

  connect(
    url: string,
    config: WebSocketServiceConfig = {
      keepAliveIntervalMs: DEFAULT_KEEP_ALIVE_INTERVAL,
      maxRetryAttempts: DEFAULT_RETRY_TIMES,
      retryScalingDuration: DEFAULT_RETRY_SCALING_DURATION_MS,
      handler: this.websocketHandler,
      errorHandler: this.websocketErrorHandler,
      closeHandler: this.websocketClosedHandler,
    },
  ): void {
    this.disconnect();
    this.initialize(url, config);
    if (!this.wsSubscription || this.wsSubscription.closed) {
      this.wsSubscription = this.wsSubject$.subscribe(
        this.config.handler || this.websocketHandler,
        this.config.errorHandler || this.websocketErrorHandler,
        this.config.closeHandler || this.websocketClosedHandler,
      );
    }
    if (this.config.keepAlive) {
      this.keepAlive();
    }
  }

  retryConnect() {
    this.increaseRetryAttempts();
    if (this.config.maxRetryAttempts) {
      if (this.retryAttempt <= this.config.maxRetryAttempts) {
        this.logRetry();
        setTimeout(
          () => this.connect(this.url, this.config),
          this.getRetryTimeoutSeconds(),
        );
      }
      return;
    }
    this.logRetry();
    setTimeout(
      () => this.connect(this.url, this.config),
      this.getRetryTimeoutSeconds(),
    );
  }

  retryConnectOnError() {
    if (this.config.retryOnError) {
      this.retryConnect();
    }
  }

  retryConnectOnClose() {
    if (this.config.retryOnClose) {
      this.retryConnect();
    }
  }

  disconnect(): void {
    if (this.wsSubject$) {
      this.wsSubject$.complete();
    }
    this.unsubscribeKeepAlive();
    this.unsubscribeWebSocket();
    this.unsubscribeRefresh();
  }

  isConnected(): boolean {
    return this.wsSubscription ? !this.wsSubscription.closed : false;
  }

  logConnected() {
    this._logger.debug(
      `Successfully connected to web socket: ${this.url} (id=${this.id})`,
    );
  }

  logDisconnected() {
    this._logger.debug(
      `Disconnected to web socket: ${this.url} (id=${this.id})`,
    );
  }

  logClose() {
    this._logger.debug(`Connection to web socket has been closed...`);
  }

  logError(error: any) {
    this._logger.debug(`Connection to web socket encountered an error... `);
    this._logger.debug(error);
  }

  logRetry() {
    this._logger.debug(
      `Attempt ${this.retryAttempt}: retrying in ${
        this.getRetryTimeoutSeconds() / 1000
      }s`,
    );
  }

  private initialize(url: string, config: WebSocketServiceConfig): void {
    this.url = url;
    this.config = config;
    this.id = this.config.params[LOG_ID_FIELD] || generateUuid();
    this.wsSubject$ = webSocket({
      url: `${url}${this.parseParams(this.config.params)}`,
      openObserver: {
        next: () => {
          this.logConnected();
          this.resetRetryAttempts();
        },
      },
      closeObserver: {
        next: (event: CloseEvent) => {
          this.logDisconnected();
          if (event.code === 1000) {
            this._logger.debug(`WebSocket connection closed normally...`);
          } else {
            this._logger.debug(
              `Reason: ${event.reason || 'Unknown'} (${event.code || 0})`,
            );
            if (!navigator.onLine) {
              this._logger.debug(`You are not connected to the internet...`);
            }
          }
        },
      },
    });

    this.wsObservable$ = this.wsSubject$.asObservable();
  }

  private keepAlive(message?: any): void {
    if (!this.keepAliveSubscription || this.keepAliveSubscription.closed) {
      this.keepAliveSubscription = interval(this.config.keepAliveIntervalMs)
        .pipe(
          tap((times) => {
            this._logger.debug(
              `Keeping web socket connection alive (${times}): ${this.url} (id=${this.id})`,
            );
            this.send(message || { action: 'keep' });
          }),
        )
        .subscribe();
    }
  }

  private websocketHandler = (result) => this._logger.debug(result);

  private websocketErrorHandler = (error) => {
    this.logError(error);
    this.retryConnectOnError();
  };

  private websocketClosedHandler = () => {
    this.logClose();
    this.retryConnectOnClose();
  };

  private increaseRetryAttempts() {
    this.retryAttempt += 1;
  }

  private resetRetryAttempts() {
    this.retryAttempt = 0;
  }

  private getRetryTimeoutSeconds(): number {
    return exponentialBackoff(
      this.retryAttempt - 1,
      this.config.retryScalingDuration,
      1,
      1000,
    );
  }

  private unsubscribeKeepAlive(): void {
    if (this.keepAliveSubscription && !this.keepAliveSubscription.closed) {
      this.keepAliveSubscription.unsubscribe();
    }
  }

  private unsubscribeWebSocket(): void {
    if (this.wsSubscription && !this.wsSubscription.closed) {
      this.wsSubscription.unsubscribe();
    }
  }

  private unsubscribeRefresh(): void {
    if (this.refreshSubscription && !this.refreshSubscription.closed) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private parseParams(params: { [key: string]: string }): string {
    if (!params || getKeys(params).length === 0) {
      return '';
    }
    let strParam = '?';
    getKeys(params).forEach((key, index) => {
      if (index) {
        strParam += '&';
      }
      strParam += `${key}=${params[key]}`;
    });
    return strParam;
  }
}
