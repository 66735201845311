import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule, TooltipModule } from 'prosumer-app/libs/eyes-shared';

import { DetailsOverlayModule } from '../details-overlay';
import {
  DiagramFluidsLegendComponent,
  NodeViewContainerComponent,
  NodeViewDiagramComponent,
  OverviewContainerComponent,
  OverviewDiagramComponent,
  SystemVisualizationComponent,
} from './components';
import { OptimizeIconTogglePipe, OptimizeTextTogglePipe } from './pipes';
import { SystemVisualizationService } from './services';
import { SelectNewModule } from 'prosumer-app/shared/components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DetailsOverlayModule,
    TooltipModule,
    SelectNewModule,
  ],
  declarations: [
    DiagramFluidsLegendComponent,
    NodeViewContainerComponent,
    NodeViewDiagramComponent,
    OverviewContainerComponent,
    OverviewDiagramComponent,
    SystemVisualizationComponent,
    OptimizeIconTogglePipe,
    OptimizeTextTogglePipe,
  ],
  exports: [
    DetailsOverlayModule,
    DiagramFluidsLegendComponent,
    NodeViewContainerComponent,
    NodeViewDiagramComponent,
    OverviewContainerComponent,
    SystemVisualizationComponent,
    OptimizeIconTogglePipe,
    OptimizeTextTogglePipe,
  ],
  providers: [SystemVisualizationService],
})
export class SystemVisualizationModule {}
