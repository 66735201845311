import { Inject, Injectable, Optional } from '@angular/core';

import {
  ApiConfig,
  AuthConfig,
  EnvConfig,
  Features,
  HttpConfig,
  PubSubConfig,
  SupportConfig,
  Version,
  WebSocketConfig,
} from 'prosumer-app/libs/eyes-shared';

import {
  API_CONFIG,
  AUTH_CONFIG,
  ENV_CONFIG,
  FEATURES_CONFIG,
  HTTP_CONFIG,
  PUBSUB_CONFIG,
  SUPPORT_CONFIG,
  VERSION_CONFIG,
  WEBSOCKET_CONFIG,
} from '../eyes-core.tokens';

@Injectable()
/**
 * DEPRECATED: DO NOT USE
 * Use eyes-shared/config.service.ts instead
 */
export class ConfigService {
  constructor(
    @Optional() @Inject(API_CONFIG) public api: ApiConfig,
    @Optional() @Inject(AUTH_CONFIG) public auth: AuthConfig,
    @Optional() @Inject(ENV_CONFIG) public environment: EnvConfig,
    @Optional() @Inject(FEATURES_CONFIG) public features: Features,
    @Optional() @Inject(HTTP_CONFIG) public http: HttpConfig,
    @Optional() @Inject(PUBSUB_CONFIG) public pubsub: PubSubConfig,
    @Optional() @Inject(VERSION_CONFIG) public version: Version,
    @Optional() @Inject(WEBSOCKET_CONFIG) public websocket: WebSocketConfig,
    @Optional() @Inject(SUPPORT_CONFIG) public support: SupportConfig,
  ) {}
}
