import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { fadeInAnimation } from 'prosumer-app/libs/eyes-shared/animations/fade.animation';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared/components/form-field';
import { BehaviorSubject } from 'rxjs';
import { FormService, SupportConfigService } from '../../services';
import { SupportApiService } from './services';
import { RouteHistoryService } from 'prosumer-app/shared/services/route-history';

@Component({
  selector: 'prosumer-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EyesSupportPageComponent implements OnInit {
  typeOfRequests: Array<FormFieldOption<string>> =
    this._config.support.TYPE_OF_REQUESTS;

  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Support.message');

  success$ = new BehaviorSubject<boolean>(false);

  loading$ = this._supportService.loading$;

  ticket: string;

  formDirective: FormGroupDirective;

  supportForm: UntypedFormGroup = this._formBuilder.group({
    summary: ['', Validators.required],
    description: ['', Validators.required],
    typeOfRequest: ['', Validators.required],
    currentUrl: '',
    issueType: '',
    prosumerVersion: '',
  });

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _supportService: SupportApiService,
    private _config: SupportConfigService,
    private routeHistory: RouteHistoryService,
  ) {}

  ngOnInit() {
    this.prefillCurrentUrl();
  }

  createNewTicket() {
    this.formDirective.resetForm();
    this.supportForm.reset();
    this.success$.next(false);
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.supportForm.valid) {
      this._supportService
        .create(this.supportForm.value)
        .subscribe((response) => {
          if (response && response.key) {
            this.ticket = response.key;
            this.success$.next(true);
            this.formDirective = formDirective;
          }
        });
    }
  }

  private prefillCurrentUrl() {
    this.supportForm.patchValue({
      currentUrl: this.routeHistory.previousRoute,
    });
  }
}
