import {
  Injectable,
  InjectionToken,
  Injector,
  ComponentRef,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DetailsOverlayComponent } from './details-overlay.component';
import { DetailsOverlayRef } from './details-overlay-ref';
import {
  DETAILS_OVERLAY_DATA,
  Data,
  DetailsOverlayConfig,
  DEFAULT_CONFIG,
} from './details-overlay.tokens';

@Injectable()
export class DetailsOverlayService {
  // Inject overlay service
  currentOverlayRef: DetailsOverlayRef;
  constructor(
    public overlay: Overlay,
    public injector: Injector,
  ) {}

  open(config: DetailsOverlayConfig = {}) {
    if (!!this.currentOverlayRef) {
      this.currentOverlayRef.close();
    }
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(dialogConfig);
    this.currentOverlayRef = new DetailsOverlayRef(overlayRef);
    const overlayComponent = this.attachDialogContainer(
      overlayRef,
      dialogConfig,
      this.currentOverlayRef,
    );
    overlayRef.backdropClick().subscribe((_) => this.currentOverlayRef.close());

    return this.currentOverlayRef;
  }

  public createOverlay(config: DetailsOverlayConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  public attachDialogContainer(
    overlayRef: OverlayRef,
    config: DetailsOverlayConfig,
    dialogRef: DetailsOverlayRef,
  ) {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(
      DetailsOverlayComponent,
      null,
      injector,
    );
    const containerRef: ComponentRef<DetailsOverlayComponent> =
      overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  public getOverlayConfig(config: DetailsOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy,
    });

    return overlayConfig;
  }

  public createInjector(
    config: DetailsOverlayConfig,
    dialogRef: DetailsOverlayRef,
  ): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(DetailsOverlayRef, dialogRef);
    injectionTokens.set(DETAILS_OVERLAY_DATA, config.data);

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }
}
