import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { TooltipMessageComponent } from 'prosumer-shared/components/tooltip-message/tooltip-message.component';
import {
  TooltipPosition,
  TooltipPositionOrigins,
} from 'prosumer-shared/models/tooltip.model';
import { TooltipContextHolderService } from './services/tooltip-context-holder.service';
/**
 * Defines the metadata of the tooltip
 */
@Directive({ selector: '[prosumerTooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef,
    private _contextHolderService: TooltipContextHolderService,
  ) {}

  overlayRef: OverlayRef;
  positions: TooltipPositionOrigins[];
  private _tooltipPositions: TooltipPosition[] = ['right', 'left'];
  @Input() prosumerTooltip;
  @Input() tooltipPanelClass = 'eyes-panel-class';
  @Input() withDefaultOffsetY = 0;
  @Input() withDefaultOffsetX = 0;
  @Input() prosumerTooltipText = '';

  @Input() set tooltipPosition(value: TooltipPosition | TooltipPosition[]) {
    if (Array.isArray(value)) {
      this._tooltipPositions = Array.from(new Set(value)) as TooltipPosition[];
      return;
    }
    this._tooltipPositions.push(value);
  }

  get tooltipPositions() {
    return this._tooltipPositions;
  }

  isTop = false;

  ngOnInit() {
    this.positions = this._tooltipPositions.map((position) => {
      switch (position) {
        case 'right':
          return {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          };
        case 'center':
          return {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          };
        case 'left':
          return {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          };
        case 'top-right':
          this.isTop = true;
          return {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          };
        case 'top-left':
          this.isTop = true;
          return {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          };
      }
    });
  }

  @HostListener('mouseenter')
  show() {
    if (this.prosumerTooltip || this.prosumerTooltipText) {
      const positionStrategy = this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef.nativeElement)
        .withPositions(
          this.positions.map(
            ({ originX, originY, overlayX, overlayY }) =>
              new ConnectionPositionPair(
                { originX, originY },
                { overlayX, overlayY },
              ),
          ),
        )
        .withDefaultOffsetY(this.withDefaultOffsetY)
        .withDefaultOffsetX(this.withDefaultOffsetX)
        .withPush(true);

      this.overlayRef = this._overlay.create({
        positionStrategy,
        panelClass: this.tooltipPanelClass,
        scrollStrategy: this._overlay.scrollStrategies.reposition(),
      });

      const tooltipPortal = new ComponentPortal(TooltipMessageComponent);
      const tooltipRef: ComponentRef<TooltipMessageComponent> =
        this.overlayRef.attach(tooltipPortal);
      tooltipRef.instance.text = this.prosumerTooltip
        ? this._contextHolderService.getMessage(
            this._contextHolderService.getContextualKeys(this.prosumerTooltip),
          )
        : this.prosumerTooltipText;
      tooltipRef.instance.isTop = this.isTop;
    }
  }

  @HostListener('mouseout')
  hide() {
    // this.overlayRef.detach();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  ngOnDestroy() {
    this.hide();
  }
}
