import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Breadcrumb } from '../../models';

@Component({
  selector: 'prosumer-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[];
  @Input() reference: any = {};

  @Output() eyesClick: EventEmitter<string> = new EventEmitter();

  show = true;
  customCrumbs = [];

  constructor() {}

  ngOnInit() {}
}
