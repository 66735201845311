import { EnergyVector, Node } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
} from 'prosumer-app/libs/eyes-shared';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { FuelStore } from 'prosumer-app/stores/fuel';
import { NodeQuery } from 'prosumer-app/stores/node';
import { convertToYearlyValues, GenericExistsValidator } from 'prosumer-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { FuelsFormDialogData } from './fuels-form-dialog.model';

@UntilDestroy()
@Component({
  selector: 'prosumer-fuels-form-dialog',
  templateUrl: './fuels-form-dialog.component.html',
  styleUrls: ['./fuels-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuelsFormDialogComponent extends BaseComponent implements OnInit {
  fuelForm: UntypedFormGroup = this._formBuilder.group({
    id: [''],
    fuel: ['', Validators.required],
    generics: [undefined, Validators.required],
    fuelCost: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    co2Rate: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    co2Scope3EmissionFactor: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
  });
  // options
  energyVectorOptions$: Observable<Array<EnergyVector>>;
  nodeOptions$: Observable<Array<Node>>;

  submitted$ = new BehaviorSubject<boolean>(false);
  errorMessages: FormFieldErrorMessageMap = {
    fuel: {
      invalid: this._translate.instant('Scenario.messages.fuel.invalid'),
      required: this._translate.instant('Scenario.messages.fuel.required'),
      allOptionsApplied: this._translate.instant(
        'Scenario.messages.fuel.allOptionsApplied',
      ),
    },
    node: {
      invalid: this._translate.instant('Scenario.messages.nodes.name.invalid'),
      required: this._translate.instant(
        'Scenario.messages.nodes.name.required',
      ),
    },
    fuelCost: {
      invalid: this._translate.instant('Scenario.messages.fuelCost.invalid'),
      required: this._translate.instant('Scenario.messages.fuelCost.required'),
    },
    co2Rate: {
      invalid: this._translate.instant('Scenario.messages.co2Rate.invalid'),
      required: this._translate.instant('Scenario.messages.co2Rate.required'),
    },
    co2Scope3EmissionFactor: {
      invalid: this._translate.instant(
        'Scenario.messages.co2Scope3EmissionFactor.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.co2Scope3EmissionFactor.required',
      ),
    },
  };
  private readonly saving = new BehaviorSubject<boolean>(false);
  readonly saving$ = this.saving.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuelsFormDialogData,
    public dialogRef: MatDialogRef<FuelsFormDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    private evQuery: EnergyVectorQuery,
    private nodeQuery: NodeQuery,
    private store: FuelStore,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.initFormData();
    this.setValidator();
    this.fuelForm.controls.generics.markAsPristine();
    this.initOptions();
    this.subscribeToGenericsControlForGenericsPristination();
    this.take1FromFirstFormChangeForManualChangeDetection();
  }

  private take1FromFirstFormChangeForManualChangeDetection(): void {
    this.fuelForm.valueChanges
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => {
        this._changeDetector.detectChanges();
      });
  }

  initFormData() {
    const genericNodes = {
      generics: this.data.nodes,
    };
    this.fuelForm.patchValue(
      {
        id: this.data.id,
        fuel: this.data.fuel,
        generics: genericNodes,
        fuelCost: this.data.fuelCost,
        co2Rate: this.data.co2Rate,
        co2Scope3EmissionFactor: this.data.co2Scope3EmissionFactor,
      },
      { emitEvent: false },
    );
  }

  initOptions() {
    this.energyVectorOptions$ = this.evQuery
      .selectActiveVectors()
      .pipe(untilDestroyed(this));
    this.nodeOptions$ = this.nodeQuery
      .selectActiveNodes()
      .pipe(untilDestroyed(this));
  }

  setValidator(): void {
    this.fuelForm.controls.fuel.setAsyncValidators(
      GenericExistsValidator.filterchipDataExistsValidator(
        this.data.existingFuels$,
        'fuel',
        this.data.fuelData,
        { nodes: this.fuelForm.get('generics') as UntypedFormControl },
        'nodes',
        'nodes',
      ),
    );
  }

  private subscribeToGenericsControlForGenericsPristination(): void {
    this.getGenericsControl()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((generics) => {
        this.fuelForm.get('fuel').updateValueAndValidity();
        this.markGenericsControlAsPristineIfEmpty(generics);
      });
  }

  private markGenericsControlAsPristineIfEmpty(something: unknown[]): void {
    if (!!Utils.resolveToEmptyArray(something).length) {
      this.getGenericsControl().markAsPristine();
    }
  }

  private getGenericsControl(): UntypedFormControl {
    return this.fuelForm.get('generics') as UntypedFormControl;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this._changeDetector.detectChanges();
    this.submitted$.next(true);
    if (this.hasValidChanges()) {
      this.saveLoad();
    }
  }

  hasValidChanges(): boolean {
    const form = Utils.resolveToEmptyObject(this.fuelForm);
    return [!form.pristine, form.valid].every(Boolean);
  }

  private saveLoad(): void {
    this.saving.next(true);
    if (this.isEditMode()) {
      this.editFuel();
    } else {
      this.createLoad();
    }
  }

  private createLoad(): void {
    this.store
      .createFuel(this.getRawData())
      .pipe(
        untilDestroyed(this),
        finalize(() => this.saving.next(false)),
      )
      .subscribe(() => this.dialogRef.close());
  }

  private isEditMode(): boolean {
    return this.data.mode === 'edit';
  }

  private editFuel(): void {
    const rawData = this.getRawData();
    this.store
      .edit(rawData.id, rawData)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.saving.next(false)),
      )
      .subscribe(() => this.dialogRef.close());
  }

  private getRawData() {
    const { generics, ...rawValue } = this.fuelForm.getRawValue();
    return { ...rawValue, nodes: generics.generics };
  }
}
