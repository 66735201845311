import { Chart } from 'chart.js';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'prosumer-chart-exporter',
  templateUrl: './chart-exporter.component.html',
  styleUrls: ['./chart-exporter.component.scss'],
})
export class ChartExporterComponent {
  @Input() id: string;
  @Input() chartInstance: Chart;
  @Input() allData: any;
  @Input() scenarioName: string;
  @Input() resultsName: string;
  @Input() legendShowing: boolean;
  @Input() enableXlsxExport = false;

  @Output() legendToggle = new EventEmitter<boolean>();

  constructor() {}

  handleToggle(event: any) {
    this.legendShowing = !this.legendShowing;
    this.legendToggle.emit(event);
  }
}
