import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

import { TooltipMeasurementsService } from './services/index';

@Component({
  selector: 'prosumer-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('150ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TooltipComponent implements OnInit {
  @Input() text = '';
  @Input() isTop = false;
  splitted = [];
  maxWidth = `350px`;

  constructor(
    private _tooltipMeasurementsService: TooltipMeasurementsService,
  ) {}

  ngOnInit() {
    const max =
      350 +
      this._tooltipMeasurementsService.getWidthAdjustment(this.text.length);
    this.maxWidth = `${max}px`;
    this.splitted = this.text.split('\n');
  }
}
