import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import {
  collectItemsWithKey,
  compareArrays,
  contains,
  matchArrays,
} from 'prosumer-app/libs/eyes-shared';

export class GenericExistsValidator {
  /**
   * Pairing checker for a source field and a node list field
   *
   * @param sourceListData$ - source form data observable
   * @param dataField - string name of source field data to check
   * @param skipValues a map of fields with values that will be skipped in the validation checks
   * @param genericControl - generic form control
   * @param ctrlName - generic form control name
   */
  static filterchipDataExistsValidator = <T>(
    sourceListData$: Observable<Array<T>>,
    dataField: string,
    skipValues?: { [field: string]: any },
    genericControl: { [field: string]: UntypedFormControl } = {},
    dataName: string = 'generics',
    ctrlName: string = 'generics',
  ): AsyncValidatorFn => {
    {
      return (
        control: AbstractControl,
      ): Observable<{ [key: string]: any } | null> => {
        {
          const ctrlValue = (control || { value: '' }).value;
          return sourceListData$.pipe(
            take(1),
            mergeMap((sourceData = []) => {
              {
                if (skipValues) {
                  // on edit mode, source data is not part of validation
                  sourceData = sourceData.filter((data) => data !== skipValues);
                }
                const fieldDict = {};
                for (const item of sourceData) {
                  const key = item[dataField];
                  const values = fieldDict[key] ? fieldDict[key][ctrlName] : [];
                  fieldDict[key] = {
                    [ctrlName]: values.concat(item[dataName]),
                  };
                }
                const generics = GenericExistsValidator.getValue(
                  genericControl,
                  [ctrlName, 'value', 'generics'],
                );
                const fieldDictValue = GenericExistsValidator.getValue(
                  fieldDict,
                  [ctrlValue, ctrlName],
                );
                const sourceField = collectItemsWithKey(sourceData, dataField);
                if (
                  contains(sourceField, ctrlValue) &&
                  GenericExistsValidator.isFilterchipInvalid(
                    fieldDictValue,
                    generics,
                  )
                ) {
                  return of({ allOptionsApplied: { value: ctrlValue } });
                }
              }
              return of(null);
            }),
          );
        }
      };
    }
  };
  /**
   * Pairing checker for a source field and a node list field
   *
   * @param sourceListData$ - source form data observable
   * @param fields - a dictionary of fields that is the basis of uniqueness
   * @param filterchipField a generic filterchip filed that is the basis of uniqueness
   * @param skipValues - an item that would be skipped when found in sourceListData$
   */
  static filterchipMultiDataExistsValidator = <T>(
    sourceListData$: Observable<Array<T>>,
    fields: {
      [field: string]: { control: UntypedFormControl; default?: string };
    },
    filterchipField: { control: UntypedFormControl; dataKey: string },
    skipValues?: T,
  ): AsyncValidatorFn => {
    {
      return (
        target: AbstractControl,
      ): Observable<{ [key: string]: any } | null> => {
        {
          const { control, dataKey } = filterchipField;
          return sourceListData$.pipe(
            take(1),
            mergeMap((sourceData = []) => {
              {
                if (skipValues) {
                  // on edit mode, source data is not part of validation
                  sourceData = sourceData.filter(
                    (data) => data['id'] !== skipValues['id'],
                  );
                }
                const fieldDict = {};
                const keyArr = Object.keys(fields);
                for (const item of sourceData) {
                  const srcKey = keyArr
                    .map((key) => item[key] || fields[key].default)
                    .join('-');
                  const values = GenericExistsValidator.getValue(fieldDict, [
                    srcKey,
                    dataKey,
                  ]);
                  fieldDict[srcKey] = values.concat(item[dataKey]);
                }
                const currentKey = keyArr
                  .map((key) => fields[key]['control']['value'])
                  .join('-');
                const filterchipValue = GenericExistsValidator.getValue(
                  control,
                  ['value', 'generics'],
                );
                if (
                  !!fieldDict[currentKey] &&
                  GenericExistsValidator.isFilterchipInvalid(
                    fieldDict[currentKey],
                    filterchipValue,
                  )
                ) {
                  return of({
                    allOptionsApplied: {
                      value: `[${filterchipValue.join(',')}]`,
                    },
                  });
                }
              }
              return of(null);
            }),
          );
        }
      };
    }
  };

  private static isFilterchipInvalid(dictArrayValue, ctrlArrayValue) {
    // when a source field has an existing paired node, the user
    // is no longer allowed to select All Nodes
    if (ctrlArrayValue.length === 1 && ctrlArrayValue[0] === 'ALL') {
      return true;
    }
    // when a source field is already paired with All nodes, the user
    // is no longer allowed to add any node pair to it
    if (dictArrayValue.length === 1 && dictArrayValue[0] === 'ALL') {
      return true;
    }
    // when a certain node is already part of a source field's list of nodes
    if (matchArrays(dictArrayValue, ctrlArrayValue)) {
      return true;
    }
    // when a pair of field and node already exists
    if (compareArrays(dictArrayValue, ctrlArrayValue)) {
      return true;
    }
    return false;
  }

  private static getValue(object, keys, defaultValue = []) {
    try {
      for (const item of keys) {
        object = object[item];
      }
      return object || defaultValue;
    } catch {
      return defaultValue;
    }
  }
}
