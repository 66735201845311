import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import moment from 'moment';
import { Subject, Subscription } from 'rxjs';

import { DateInput } from './date-input-control.component.model';

const selector = 'prosumer-date-input-control';

@Component({
  selector,
  templateUrl: './date-input-control.component.html',
  styleUrls: ['./date-input-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: DateInputControlComponent },
  ],
})
export class DateInputControlComponent
  implements
    MatFormFieldControl<moment.Moment>,
    ControlValueAccessor,
    OnInit,
    OnDestroy
{
  static nextId = 0;

  @ViewChild('month') monthRef: ElementRef;
  @ViewChild('day') dayRef: ElementRef;
  @ViewChild('year') yearRef: ElementRef;

  @Input()
  get value(): moment.Moment {
    return this.getValue();
  }
  set value(date: moment.Moment) {
    this.writeValue(date);
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  get empty() {
    const dateValue = this.dateForm.value as DateInput;
    return !dateValue.month && !dateValue.day && !dateValue.year;
  }

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = selector;

  dateForm: UntypedFormGroup;
  month: UntypedFormControl;
  day: UntypedFormControl;
  year: UntypedFormControl;

  private _subscriptions: Subscription = new Subscription();

  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  private _onChange: (_: any) => void;
  private _onTouched: (_?: any) => void;

  @HostBinding() id = `${selector}-${DateInputControlComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  @HostListener('change')
  onChange() {
    if (this._onChange) {
      this._onChange(this.getValue());
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.dateForm = this._formBuilder.group({
      month: '',
      day: '',
      year: '',
    });

    this.month = this.dateForm.get('month') as UntypedFormControl;
    this.day = this.dateForm.get('day') as UntypedFormControl;
    this.year = this.dateForm.get('year') as UntypedFormControl;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      if (this.year.value) {
        this.focusYear();
      } else if (this.day.value) {
        this.focusYear();
      } else if (this.month.value) {
        this.focusDay();
      } else {
        this.focusMonth();
      }

      if (this.ngControl) {
        this.ngControl.control.markAsTouched();
      }
    }
  }

  writeValue(value: moment.Moment): void {
    const newValue = value
      ? {
          month: `${value.month() + 1}`.padStart(2, '0'),
          day: `${value.date()}`.padStart(2, '0'),
          year: `${value.year()}`.padStart(4, '0'),
        }
      : new DateInput('', '', '');
    this.dateForm.setValue(newValue);
    this.stateChanges.next();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = coerceBooleanProperty(disabled);

    if (disabled) {
      this.dateForm.get('month').disable();
      this.dateForm.get('day').disable();
      this.dateForm.get('year').disable();
    } else {
      this.dateForm.get('month').enable();
      this.dateForm.get('day').enable();
      this.dateForm.get('year').enable();
    }

    this.stateChanges.next();
  }

  focusMonth(): void {
    this.focusElement(this.monthRef);
  }

  focusDay(): void {
    this.focusElement(this.dayRef);
  }

  focusYear(): void {
    this.focusElement(this.yearRef);
  }

  focusElement(elementRef: ElementRef): void {
    if (elementRef && elementRef.nativeElement) {
      setTimeout(() => elementRef.nativeElement.focus(), 0);
    }
  }

  getValue(): moment.Moment {
    const dateValue = this.dateForm.value as DateInput;

    if (
      dateValue.month.length === 0 &&
      dateValue.day.length === 0 &&
      dateValue.year.length === 0
    ) {
      return null;
    }

    if (dateValue.month.length < 2 && dateValue.month.length > 0) {
      this.month.patchValue(`${dateValue.month}`.padStart(2, '0'));
    }
    if (dateValue.day.length < 2 && dateValue.day.length > 0) {
      this.day.patchValue(`${dateValue.day}`.padStart(2, '0'));
    }
    if (dateValue.year.length < 4 && dateValue.year.length > 0) {
      this.year.patchValue(`${dateValue.year}`.padStart(4, '0'));
    }

    const newDate: moment.Moment = moment([
      Number.parseInt(dateValue.year, 10),
      Number.parseInt(dateValue.month, 10) - 1,
      Number.parseInt(dateValue.day, 10),
    ]);

    return newDate;
  }

  ngOnInit() {
    this._subscriptions.add(
      this.month.valueChanges.subscribe((value) => {
        if (value && value.length >= 2) {
          this.focusDay();
        }
      }),
    );

    this._subscriptions.add(
      this.day.valueChanges.subscribe((value) => {
        if (value && value.length >= 2) {
          this.focusYear();
        }
        if (this.month.value && (!value || value.length === 0)) {
          this.focusMonth();
        }
      }),
    );

    this._subscriptions.add(
      this.year.valueChanges.subscribe((value) => {
        if (this.day.value && (!value || value.length === 0)) {
          this.focusDay();
        }
      }),
    );

    if (this.ngControl != null) {
      this._subscriptions.add(
        this._focusMonitor
          .monitor(this._elementRef.nativeElement, true)
          .subscribe((origin) => {
            this.focused = !!origin;
            if (!origin && this.ngControl && this.ngControl.touched) {
              this.ngControl.control.updateValueAndValidity();
            }
            this.stateChanges.next();
          }),
      );

      this._subscriptions.add(
        this.ngControl.statusChanges.subscribe((status) =>
          status === 'INVALID'
            ? (this.errorState = true)
            : (this.errorState = false),
        ),
      );

      this._subscriptions.add(
        this._focusMonitor
          .monitor(this.monthRef.nativeElement, true)
          .subscribe((origin) => {
            if (origin) {
              this.ngControl.control.markAsTouched();
            }
          }),
      );

      this._subscriptions.add(
        this._focusMonitor
          .monitor(this.dayRef.nativeElement, true)
          .subscribe((origin) => {
            if (origin) {
              this.ngControl.control.markAsTouched();
            }
          }),
      );

      this._subscriptions.add(
        this._focusMonitor
          .monitor(this.yearRef.nativeElement, true)
          .subscribe((origin) => {
            if (origin) {
              this.ngControl.control.markAsTouched();
            }
          }),
      );
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    this._focusMonitor.stopMonitoring(this.monthRef.nativeElement);
    this._focusMonitor.stopMonitoring(this.dayRef.nativeElement);
    this._focusMonitor.stopMonitoring(this.yearRef.nativeElement);
    this._subscriptions.unsubscribe();
  }
}
