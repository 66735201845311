import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kiloPipe',
})
export class KiloPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return value / 1000;
  }
}

@Pipe({
  name: 'inversePipe',
})
export class InversePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return value * -1;
  }
}

@Pipe({
  name: 'absoluteValuePipe',
})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return Math.abs(value);
  }
}
