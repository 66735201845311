import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'prosumer-stepper-nav',
  templateUrl: './stepper-nav.component.html',
  styleUrls: ['./stepper-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperNavComponent {
  @Input() set disabled(disabled: boolean) {
    this.previousDisabled = disabled;
    this.nextDisabled = disabled;
  }

  flexLayout: 'row' | 'row-reverse' = 'row';
  flexLayoutAlign: 'start' | 'end' = 'start';
  @Input() set reverseButtons(reverseButtons: boolean) {
    if (reverseButtons) {
      this.flexLayout = 'row-reverse';
      this.flexLayoutAlign = 'end';
    } else {
      this.flexLayout = 'row';
      this.flexLayoutAlign = 'start';
    }
  }

  @Input() previousDisabled: boolean;
  @Input() nextDisabled: boolean;
  @Input() previousLabel: string;
  @Input() nextLabel: string;
  @Input() previousColor: ThemePalette;
  @Input() nextColor: ThemePalette;

  @Output() previous = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  onClickPrevious() {
    this.previous.emit();
  }

  onClickNext() {
    this.next.emit();
  }
}
