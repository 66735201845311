import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ExportChartDirectiveModule } from 'prosumer-shared/directives/export-chart';

import { ChartExporterComponent } from './chart-exporter.component';

@NgModule({
  declarations: [ChartExporterComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ExportChartDirectiveModule,
  ],
  exports: [ChartExporterComponent],
})
export class ChartExporterModule {}
