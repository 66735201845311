import { Profile } from 'prosumer-app/+scenario';
import { Coerce } from 'prosumer-app/core/utils';
import { HasLegacyProfiles } from 'prosumer-app/stores/profile';
import { v4 as uuid } from 'uuid';

const noOfHInYear = 8760;

export const getUpdatedListOfProfiles = (
  year: string,
  dataValues: number[],
  profiles: Partial<Profile>[],
): Partial<Profile>[] => {
  const dYear = Number(year);
  const profileIdx = getProfileIndexOfYear(dYear, profiles);
  const values = dataValues.map((value) =>
    Coerce.toNullishCoalescedString(value),
  );
  profiles[profileIdx] = {
    ...profiles[profileIdx],
    loadProfile: values,
  };
  return profiles;
};

export const getProfileIndexOfYear = (
  year: number,
  profiles: Partial<Profile>[],
): number => profiles.findIndex((profile) => profile.startYear === year);

export const getYearOfProfileIndex = (
  idx: number,
  profiles: Partial<Profile>[],
): number => profiles[idx].startYear;

export const getProfilesFromTDBData = (
  yearRange: [number, number],
  location: string,
  map: Record<number, string>,
): Partial<Profile>[] => {
  const tdbValues = { [yearRange[0]]: undefined, ...map };
  return Object.keys(tdbValues)
    .map((year) => Number(year))
    .map((year, idx, array) => {
      let maxYear = yearRange[1];
      if (array.length - 1 > idx) {
        maxYear = array[idx + 1] - 1;
      }
      const values = [Number(tdbValues[year])].filter((value) => !isNaN(value));
      return mapTDBDataToProfile([year, maxYear], location, values);
    });
};

export const mapYearlyDataToProfiles = (
  yearlyData: { [year: string]: string | number | number[] },
  startYear: number,
  endYear: number,
  location: string,
  yearlyLoad = 1,
): Profile[] => {
  const arr: Profile[] = [];
  getYearsInRange(startYear, endYear).forEach((year) => {
    if (!yearlyData[year] && !arr.length) {
      arr.push({
        localId: generateShortUID(),
        loadProfile: [],
        startYear: year,
        endYear: year,
        loadType: 'custom',
        forSaving: true,
        isCustom: true,
        location,
        yearlyLoad,
      });
    } else if (
      !yearlyData[year] ||
      checkForConsecutiveSameValueYear(yearlyData, year)
    ) {
      arr[arr.length - 1] = {
        ...arr[arr.length - 1],
        endYear: year,
      };
    } else {
      arr.push({
        localId: generateShortUID(),
        loadProfile: Array.isArray(yearlyData[year])
          ? (yearlyData[year] as number[])
          : generateHourlyListFromValue(yearlyData[year]),
        startYear: year,
        endYear: year,
        loadType: 'custom',
        forSaving: true,
        isCustom: true,
        location,
        yearlyLoad,
      });
    }
  });

  return arr;
};

function checkForConsecutiveSameValueYear(yearlyData: any, year: number) {
  return (
    yearlyData[year] &&
    yearlyData[year - 1] &&
    yearlyData[year] === yearlyData[year - 1]
  );
}

export function getYearsInRange(startYear, endYear) {
  return Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index,
  );
}

export function generateHourlyListFromValue(val: any): any[] {
  return Array.from({ length: noOfHInYear }).fill(val);
}

export const mapTDBDataToProfile = (
  yearRange: [number, number],
  location: string,
  values: number[],
): Partial<Profile> => {
  return {
    localId: generateShortUID(),
    loadProfile: values.map(String),
    startYear: yearRange[0],
    endYear: yearRange[1],
    loadType: 'custom',
    forSaving: true,
    isCustom: true,
    location,
  };
};

// TODO: place generateShortUID here since it would only be used by profiles
export const generateShortUID = (): string => uuid().substring(5, -5);

// TODO: finalize code with unit test
export const mapSickProfiles = <T extends Partial<HasLegacyProfiles>>(
  data: T,
): T => {
  return {
    ...data,
    profiles:
      !!data &&
      !!data.profiles &&
      !!data.profiles['intervals'] &&
      data.profiles['intervals'].length
        ? data.profiles['intervals'].map(({ profile }) => ({
            ...profile,
          }))
        : Coerce.toObject(data).profiles,
  };
};

export const hasProfilesForSaving = (arr: Partial<Profile>[]): boolean =>
  arr.map((item) => !!item.forSaving).some(Boolean);
