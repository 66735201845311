import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { CoordinatesMapModule } from '../coordinates-map-form';
import { ComputationOptionModule } from '../profile-computation-option';
import { WindTabComponent } from './wind-tab.component';

@NgModule({
  declarations: [WindTabComponent],
  exports: [WindTabComponent],
  imports: [
    EyesSharedModule.forFeature(),
    CommonModule,
    CoordinatesMapModule,
    ComputationOptionModule,
  ],
})
export class WindTabModule {}
