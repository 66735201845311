import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ShareLinkComponent } from './share-link.component';

@NgModule({
  declarations: [ShareLinkComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
  ],
  exports: [ShareLinkComponent],
})
export class ShareLinkModule {}
