import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, NgControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { BaseControlComponent } from '../base-control';

@Directive()
export abstract class BaseFieldComponent
  extends BaseControlComponent
  implements OnInit
{
  @Input() fontSize: string;
  @Input() hint: string;
  @Input() iconPrefix: string;
  @Input() iconSuffix: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() textAlign: string;

  private _loading: boolean;
  get loading(): boolean {
    return this._loading;
  }
  @Input() set loading(value: boolean) {
    if (value !== this._loading) {
      this._loading = value;
      this.stateChanges.next();
    }
  }

  private _placeholder: string;
  get placeholder(): string {
    return this._placeholder;
  }
  @Input() set placeholder(value: string) {
    if (value !== this._placeholder) {
      this._placeholder = value;
      this.stateChanges.next();
    }
  }

  private _label: string;
  get label(): string {
    return this._label;
  }
  @Input() set label(value: string) {
    if (value !== this._label) {
      this._label = value;
      this.stateChanges.next();
    }
  }

  private _type = 'text';
  get type(): string {
    return this._type;
  }
  @Input() set type(value: string) {
    if (value !== this._type) {
      this._type = value;
      this.stateChanges.next();
    }
  }

  @Output() errorsChange = new EventEmitter();
  @Output() clickIconSuffix = new EventEmitter();

  control: AbstractControl;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(ngControl, changeDetector);
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.control = this.ngControl.control;
      this.control.statusChanges
        .pipe(filter((status) => status === 'INVALID' || status === 'VALID'))
        .subscribe(() => this.errorsChange.emit(this.control.errors));
    }
  }

  onClickIconSuffix(event: MouseEvent): void {
    this.clickIconSuffix.emit(event);
  }
}
