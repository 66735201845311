import { HttpErrorResponse } from '@angular/common/http';

import { HTTP_METHOD, SECURITY_TOKEN } from './auth.constants';

const DEFAULT_REFRESH_TOKEN_STATUS_CODES = [401];

/**
 * Checks if the request method is GET.
 * @param method - the method to check
 * @returns true if GET; otherwise false
 */
const isGetMethod = (method: string) => {
  return method === HTTP_METHOD.GET;
};

/**
 * Checks if the request url has a security token.
 * @param url - the url to check
 * @returns true if the url contains a security token; otherwise false
 */
const hasSecurityToken = (url: string) => {
  return url.indexOf(SECURITY_TOKEN) !== -1;
};

/**
 * Checks if the request url is a file upload.
 * @param url - the url to check
 * @returns true if file upload; otherwise false
 */
const isFileUpload = (url: string) => {
  return hasSecurityToken(url);
};

/**
 * Checks if the status code is for refresh.
 * @param statusCode - the current status code to check
 * @param refreshTokenStatusCodes - the refresh status codes (default: DEFAULT_REFRESH_TOKEN_STATUS_CODES)
 */
const isStatusCodeForRefreshToken = (
  statusCode: number,
  refreshTokenStatusCodes = DEFAULT_REFRESH_TOKEN_STATUS_CODES,
): boolean => {
  return !!refreshTokenStatusCodes.find(
    (statusCodeItem) => statusCodeItem === statusCode,
  );
};

/**
 * Checks if the error parameter is a http error response.
 * @param error - the error object
 */
const isHttpErrorResponse = (error: unknown): boolean => {
  return error instanceof HttpErrorResponse;
};

/**
 * Checks if the error is token expiration.
 * @param error - the http error response
 */
const isErrorTokenExpired = (error: HttpErrorResponse): boolean => {
  return error?.error?.message === 'The incoming token has expired';
};

export const authUtil = {
  DEFAULT_REFRESH_TOKEN_STATUS_CODES,
  isGetMethod,
  hasSecurityToken,
  isFileUpload,
  isStatusCodeForRefreshToken,
  isErrorTokenExpired,
  isHttpErrorResponse,
};
