import { random } from '@ctrl/tinycolor';

export enum EngieColor {
  cyan = '#00aaff',
  navy = '#001fa0',
  green = '#1ED284',
  berry = '#B32765',
  coral = '#FD5064',
  gold = '#FFA101',
  gray = '#F5F5F5',
}

export class ResultsPerceptionScheme {
  static getColor(index: number): EngieColor | string {
    return this.isEngieable(index) ? this.engieColors[index] : this.randomize();
  }

  private static randomize(): string {
    return random({
      count: this.engieColors.length,
    })
      .filter((color) => !this.engieColors.includes(color.toHexString()))[0]
      .toHexString();
  }

  private static isEngieable(index: number): boolean {
    return index < this.engieColors.length;
  }

  private static get engieColors(): string[] {
    return Object.values(EngieColor);
  }
}
