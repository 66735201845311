import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { PipeUtils } from 'prosumer-app/core';
import { fadeInAnimation } from 'prosumer-app/libs/eyes-shared';
import {
  GeneralValidators,
  mapYearlyValuesToBackend,
} from 'prosumer-app/shared';
import { UpdateStatus } from 'prosumer-app/shared/directives/scenario-updater';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { ScenarioVariationInfo } from 'prosumer-app/stores/scenario-variation/scenario-variation.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { LimitsFormMapper } from '../limits-form/limits-form.mapper';
import {
  BEYearlyModel,
  FormYearlyModel,
  LimitsForm,
  LimitsLoadingMap,
  OptionLimits,
  OutgoingLimits,
} from '../limits-form/limits-form.models';

const MIN_VALUE_DEFAULT = 0;

@Component({
  selector: 'prosumer-variations-limits-form',
  templateUrl: './variations-limits-form.component.html',
  styleUrls: ['./variations-limits-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariationsLimitsFormComponent implements OnInit {
  @Input() isViewOnly: boolean;
  @Input() set scenarioVariation(scenarioVariation: ScenarioVariationInfo) {
    this._scenVar.next(scenarioVariation);
  }
  private readonly _scenVar = new BehaviorSubject<ScenarioVariationInfo | null>(
    null,
  );
  readonly scenVar$ = this._scenVar.asObservable();
  readonly scenVarName$ = this.scenVar$.pipe(
    map((variation) => variation.name),
  );

  readonly minValue = MIN_VALUE_DEFAULT;
  readonly limitsForm = new UntypedFormGroup(this.initLimitsForm());
  private readonly _limitsFormCache = new BehaviorSubject<LimitsForm>(
    undefined,
  );

  private readonly limitKeyLoading = new BehaviorSubject<LimitsLoadingMap>(
    this.initLoadingMap(),
  );
  readonly loadingMap$ = this.limitKeyLoading.asObservable();
  readonly period$ = this.scenarioQuery.selectActivePeriod();

  readonly yearlyMaxEmissions$ = this.selectYearlyMaxEmissions();
  readonly yearlyMaxCosts$ = this.selectYearlyMaxCosts();
  readonly globalMaxEmissions$ = this.selectGlobalEmissions();
  readonly globalMaxCosts$ = this.selectGlobalMaxCosts();

  get yearlyMaximumEmissionsCtr() {
    return this.limitsForm.controls.yearlyMaximumEmissions;
  }

  get yearlyMaximumCostsCtrl() {
    return this.limitsForm.controls.yearlyMaximumCosts;
  }

  get globalMaximumEmissionsCtrl() {
    return this.limitsForm.controls.globalMaximumEmissions;
  }

  get globalMaximumCostsCtrl() {
    return this.limitsForm.controls.globalMaximumCosts;
  }

  constructor(private readonly scenarioQuery: ScenarioGenericQuery) {}

  ngOnInit(): void {
    this.waitForYearValuesToInitialize();
  }

  onYearlyEmissionsChange(change: UpdateStatus): void {
    this.setLoadingFor('yearlyMaximumEmissions', change);
  }

  onYearlyCostsChange(change: UpdateStatus): void {
    this.setLoadingFor('yearlyMaximumCosts', change);
  }

  onGlobalEmissionsChange(change: UpdateStatus): void {
    this.setLoadingFor('globalMaximumEmissions', change);
  }

  onGlobalCostsChange(change: UpdateStatus): void {
    this.setLoadingFor('globalMaximumCosts', change);
  }

  private initLimitsForm() {
    return {
      yearlyMaximumEmissions: new UntypedFormControl(),
      yearlyMaximumCosts: new UntypedFormControl(),
      globalMaximumEmissions: new UntypedFormControl(
        undefined,
        GeneralValidators.nonNegativeValue(),
      ),
      globalMaximumCosts: new UntypedFormControl(
        undefined,
        GeneralValidators.nonNegativeValue(),
      ),
    };
  }

  private initLoadingMap() {
    return {
      globalMaximumCosts: false,
      globalMaximumEmissions: false,
      yearlyMaximumCosts: false,
      yearlyMaximumEmissions: false,
    };
  }

  private waitForYearValuesToInitialize() {
    this.period$
      .pipe(
        PipeUtils.filterOutEmpty,
        concatMap((years: [number, number]) =>
          this.mapLimitsWithIncomingYears(years),
        ),
      )
      .subscribe((limits) => {
        this._limitsFormCache.next(limits);
        this.limitsForm.patchValue(limits, { emitEvent: false });
      });
  }

  private mapLimitsWithIncomingYears(
    years: [number, number],
  ): Observable<LimitsForm> {
    return this.selectTruthyLimits().pipe(
      map((incoming) => this.mapToForm(incoming, years)),
    );
  }

  private selectTruthyLimits(): Observable<Partial<OptionLimits>> {
    return this.scenVar$.pipe(
      map((variation) => variation.limits),
      filterNilValue(),
    );
  }

  private mapToForm(
    from: Partial<OptionLimits>,
    years: [number, number],
  ): LimitsForm {
    return LimitsFormMapper.toFE(from as OptionLimits, years);
  }

  private selectYearlyMaxEmissions(): Observable<
    Partial<ScenarioVariationInfo>
  > {
    return this.selectYearlyMaxEmissionsValueChanges();
  }

  private selectYearlyMaxCosts(): Observable<Partial<ScenarioVariationInfo>> {
    return this.selectYearlyMaxCostsValueChanges();
  }

  private selectGlobalEmissions(): Observable<Partial<ScenarioVariationInfo>> {
    return this.selectGlobalEmissionsChanges().pipe(
      tap(
        (variation) =>
          variation?.limits?.globalMaximumEmissions &&
          this.globalMaximumEmissionsCtrl.markAsTouched({ onlySelf: true }),
      ),
    );
  }

  private selectGlobalMaxCosts(): Observable<Partial<ScenarioVariationInfo>> {
    return this.selectGlobalMaxCostsValueChanges().pipe(
      tap(
        (variation) =>
          variation?.limits?.globalMaximumCosts &&
          this.globalMaximumCostsCtrl.markAsTouched({ onlySelf: true }),
      ),
    );
  }

  private selectYearlyMaxEmissionsValueChanges(): Observable<
    Partial<ScenarioVariationInfo>
  > {
    return this.selectControlValueChanges('yearlyMaximumEmissions');
  }

  private selectYearlyMaxCostsValueChanges(): Observable<
    Partial<ScenarioVariationInfo>
  > {
    return this.selectControlValueChanges('yearlyMaximumCosts');
  }

  private selectGlobalEmissionsChanges(): Observable<
    Partial<ScenarioVariationInfo>
  > {
    return this.selectControlValueChanges('globalMaximumEmissions');
  }

  private selectGlobalMaxCostsValueChanges(): Observable<
    Partial<ScenarioVariationInfo>
  > {
    return this.selectControlValueChanges('globalMaximumCosts');
  }

  private selectControlValueChanges(
    controlName: string,
  ): Observable<Partial<ScenarioVariationInfo>> {
    return this.limitsForm.get(controlName).valueChanges.pipe(
      map((_) => this.injectFormLimitValues(controlName)),
      map((limits) => this.constructVaritionBody(limits)),
    );
  }

  private constructVaritionBody(
    limits: OutgoingLimits,
  ): Partial<ScenarioVariationInfo> {
    return {
      variationUuid: this._scenVar.value['variationUuid'],
      limits,
    };
  }

  private injectFormLimitValues(controlName: string): OutgoingLimits {
    const limitsFormValues: LimitsForm =
      this.fillLatestValidValues(controlName);
    return {
      ...(limitsFormValues.yearlyMaximumEmissions && {
        yearlyMaximumEmissions: this.toOutgoingYearlyValues(
          limitsFormValues.yearlyMaximumEmissions,
        ),
      }),
      ...(limitsFormValues.yearlyMaximumCosts && {
        yearlyMaximumCosts: this.toOutgoingYearlyValues(
          limitsFormValues.yearlyMaximumCosts,
        ),
      }),
      ...(!this.isNullOrUndefined(limitsFormValues.globalMaximumEmissions) && {
        globalMaximumEmissions: this.toPartialGlobalValue(
          limitsFormValues.globalMaximumEmissions,
        ),
      }),
      ...(!this.isNullOrUndefined(limitsFormValues.globalMaximumCosts) && {
        globalMaximumCosts: this.toPartialGlobalValue(
          limitsFormValues.globalMaximumCosts,
        ),
      }),
    };
  }

  private fillLatestValidValues(controlName: string) {
    return {
      ...this._limitsFormCache.value,
      [controlName]: this.limitsForm.getRawValue()[controlName],
    };
  }

  private toOutgoingYearlyValues(from: FormYearlyModel): BEYearlyModel {
    return mapYearlyValuesToBackend(from) as BEYearlyModel;
  }

  private toPartialGlobalValue(value: unknown): string {
    return !this.isNullOrUndefined(value) ? String(value) : null;
  }

  private isNullOrUndefined(val: any): boolean {
    // eslint-disable-next-line
    return val == undefined || val == null;
  }

  private setLoadingFor(
    key: keyof LimitsLoadingMap,
    change: UpdateStatus,
  ): void {
    this.setLimitLoading(key, this.isLoading(change));
  }

  private setLimitLoading(key: keyof LimitsLoadingMap, value: boolean): void {
    this.limitKeyLoading.next({
      ...this.limitKeyLoading.getValue(),
      [key]: value,
    });
  }

  private isLoading(change: UpdateStatus): boolean {
    return change.status === 'loading';
  }
}
