import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'star-rating-input',
  templateUrl: './star-rating-input.component.html',
  styleUrls: ['./star-rating-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingInputComponent),
      multi: true,
    },
  ],
})
export class StarRatingInputComponent implements ControlValueAccessor {
  @Input() readonly = false;
  @Input() wholeStars = false;
  @Input() initialStars = 0;
  @Input() starSize = 2;
  @Input() maxStars = 5;
  @Input() starColor = '#00aaff';
  @Output() rateChange = new EventEmitter<number>();

  constructor() {}

  onChange = (value: number) => {};
  onTouch = () => {};
  writeValue(value: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  handleRating(rating: number): void {
    this.rateChange.emit(rating * 2);
  }
}
