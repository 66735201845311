import { toTitleCase } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-shared';
import { BinaryDelete, Profile } from './binary-data.model';

export interface EnergyVectorCircuitInfo {
  readonly energyVectorId?: string;
  readonly neededPower?: YearlyValues; // in float
}

export interface EquipmentCircuitInfo {
  readonly equipmentId?: string;
  readonly shortCircuitFactor?: YearlyValues; // in float
  readonly shortCircuitThreshold?: YearlyValues; // in float
}

export interface ShortCircuit {
  readonly energyVectors?: Array<EnergyVectorCircuitInfo>;
  readonly equipments?: Array<EquipmentCircuitInfo>;
}

export type ReserveDirection = 'up' | 'down';
export type ReserveNature =
  | 'margin'
  | 'activation'
  | 'equipment'
  | 'market'
  | 'network';

export interface CommonReserve {
  readonly nature?: ReserveNature;
  readonly direction?: ReserveDirection;
  readonly participation?: boolean;
  readonly requirement?: string;
}

export interface MarginReserve extends CommonReserve {
  readonly energyVectorId: string;
  readonly profiles?: Array<Profile>;
}

export interface ActivationReserve extends CommonReserve {
  readonly energyVectorId: string;
  readonly rate: string; // in float
  readonly duration: string; // in float
}

export interface EquipmentReserve extends CommonReserve {
  readonly equipmentId: string;
  readonly energyVectorId: string;
}

export interface MarketReserve extends CommonReserve {
  readonly energyGridConnectionId: string;
  readonly energyVectorId: string;
}

export interface NetworkReserve extends CommonReserve {
  readonly energyVectorId: string;
}

export interface SpinningReserve extends BinaryDelete {
  readonly margins?: MarginReserve[];
  readonly activations?: ActivationReserve[];
  readonly equipments?: EquipmentReserve[];
  readonly markets?: MarketReserve[];
  readonly networks?: NetworkReserve[];
}

export const reserveToString = (reserve: CommonReserve) =>
  `Reserve ${toTitleCase(reserve.nature)} (${reserve.direction.toUpperCase()})`;

export interface FrequencyControlForm {
  readonly shortCircuit?: ShortCircuit;
  readonly spinningReserve?: SpinningReserve;
}
