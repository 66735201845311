import { Injectable } from '@angular/core';

import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

export const DEFAULT_DEBOUNCE = 400;

@Injectable()
export class FilterService {
  private _filter$: Subject<string> = new Subject<string>();

  filter$ = this._filter$.asObservable().pipe(
    debounceTime(DEFAULT_DEBOUNCE),
    distinctUntilChanged(),
    switchMap((value) => of(value)),
  );

  applyFilter(value: string): Observable<string> {
    this._filter$.next(value);
    return this.filter$;
  }

  applyFilters(filters: { [key: string]: string }): Observable<string> {
    this._filter$.next(JSON.stringify(filters));
    return this.filter$;
  }
}
