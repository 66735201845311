import { EquipmentReserve } from 'prosumer-app/+scenario/models';
import {
  BOOLEAN_ICONS,
  DIALOG_SIZES,
  DIRECTION_ICONS,
} from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { ReserveEquipment } from 'prosumer-app/stores/reserve-equipment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FrequencyControlFormService } from '../../frequency-control-form.service';
import { ReserveEquipmentFormDialogComponent } from '../equipment-form-dialog';

type TableSource = MatTableDataSource<ReserveEquipment>;

@Component({
  selector: 'prosumer-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class EquipmentTableComponent
  extends ManagedTableComponent<EquipmentReserve>
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DIRECTION_ICONS = { ...DIRECTION_ICONS };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly BOOLEAN_ICONS = { ...BOOLEAN_ICONS };

  readonly references$ = this.referenceBuilder.selectRefs();

  @Input() energyVectors = [];
  @Input() equipments = [];

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  source$: Observable<TableSource>;
  hasData$: Observable<boolean>;

  get commonData() {
    return {
      width: DIALOG_SIZES.small,
      disableClose: true,
      ...this.service.preOptsForReserveEquipmentDialog(),
    };
  }

  constructor(
    readonly ngControl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    readonly managedData: ManagedDataService<EquipmentReserve>,
    private readonly dialogService: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly referenceBuilder: ReferencesBuilder,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.source$ = this.selectSource();
    this.hasData$ = this.selectHasData();
  }

  defineColumns(): ColumnDefinition {
    return {
      energyVectorId: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: 'energyVectors',
        sortable: true,
        toolTip: 'wizard_frequency_control.reserve_energy_vector',
      },
      equipmentId: {
        name: 'Equipment',
        type: 'reference',
        referenceKey: 'equipments',
        sortable: true,
        toolTip: 'wizard_frequency_control.equipment',
      },
      direction: {
        name: 'Direction',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.direction',
      },
      participation: {
        name: 'Participation [-]',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.equipment_participation',
      },
      requirement: {
        name: 'Requirement [p.u.]',
        sortable: true,
        toolTip: 'wizard_frequency_control.equipment_requirement',
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }

  onAdd() {
    this.dialogService.openDialog(ReserveEquipmentFormDialogComponent, {
      ...this.commonData,
      mode: 'add',
    });
  }

  onViewEdit(data: EquipmentReserve) {
    this.dialogService.openDialog(ReserveEquipmentFormDialogComponent, {
      ...this.commonData,
      mode: this.getViewEditMode(),
      currentReserveEquipment: data,
    });
  }

  onDelete(data: ReserveEquipment) {
    this.service.deleteReserveEquipment(data.id).pipe(take(1)).subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');

  private selectHasData(): Observable<boolean> {
    return this.service
      .selectReserveEquipments()
      .pipe(map((data) => data.length > 0));
  }

  private selectSource(): Observable<TableSource> {
    return this.service
      .selectReserveEquipments()
      .pipe(map((data) => this.toSource(data)));
  }

  private toSource(data: ReserveEquipment[]): TableSource {
    return new MatTableDataSource(data);
  }
}
