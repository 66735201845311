import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

import { FieldType } from './models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionComponent),
      multi: true,
    },
  ],
})
export class QuestionComponent implements OnInit, ControlValueAccessor {
  @Input() question: string;
  @Input() fieldType: FieldType = 'textarea';
  @Input() required = false;
  @Input() placeholder: string;
  @Input() name = 'question';
  @Input() defaultYes = false;
  @Input() defaultNo = false;
  @Input() options: Array<Record<string, string>> = [];

  form = this.formBuilder.group({
    answer: [null],
  });

  answerField = this.form.get('answer');

  constructor(private formBuilder: UntypedFormBuilder) {}

  onChange = (value: any) => {};
  onTouch = () => {};

  ngOnInit(): void {
    if (this.required) {
      this.answerField.setValidators(Validators.required);
    }
    this.answerField.valueChanges.subscribe((value) => {
      this.onChange(value);
    });
  }

  writeValue(value: any): void {
    this.answerField.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  handleYesNoChange(answer) {
    this.answerField.setValue(answer);
  }

  onSelectButton(event: any) {
    this.answerField.setValue(event.value);
  }

  onChangeRatings(rating: number) {
    this.answerField.setValue(rating);
  }
}
