import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

export const DEFAULT_LONG_DURATION = 7000;
export const DEFAULT_DURATION = 2500;
export const ACTION_DURATION = 4000;

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private _snackBar: MatSnackBar) {}

  show(
    message: string,
    action?: string,
    panelClass?: string | string[],
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    return this._snackBar.open(message, action, {
      duration: duration || this.getDuration(message, action),
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass,
    });
  }

  showSuccess(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const successPanelClasses = ['eyes-snackbar-success'];
    return this.show(message, action, successPanelClasses, duration);
  }

  showError(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const errorPanelClasses = ['eyes-snackbar-error'];
    return this.show(message, action, errorPanelClasses, duration);
  }

  showInfo(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const infoPanelClasses = ['eyes-snackbar-info'];
    return this.show(message, action, infoPanelClasses, duration);
  }

  private getDuration(message: string, action: string = undefined): number {
    const idx = [!!action, message.length > 35, true].indexOf(true);
    const durations = [
      ACTION_DURATION,
      DEFAULT_LONG_DURATION,
      DEFAULT_DURATION,
    ];
    return durations[idx];
  }
}
