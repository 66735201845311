import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  SystemVisualizationData,
  YearlySystemVisualizationData,
} from '../models';
import { FormFieldOption } from 'prosumer-app/shared/components/select-new';

@Injectable()
export class SystemVisualizationService {
  private _height$ = new BehaviorSubject(600);
  private _width$ = new BehaviorSubject(800);
  private _selectedNode$ = new BehaviorSubject(null);
  private _defaultNode = '';
  private _tappedNode = '';
  private _cleanUp = new Subject<boolean>();
  private sysVizData = new BehaviorSubject<SystemVisualizationData>(undefined);

  height$ = this._height$.asObservable();
  width$ = this._width$.asObservable();
  selectedNode$ = this._selectedNode$.asObservable();
  cleanUp$ = this._cleanUp.asObservable();
  vizData$ = this.selectSystemVizData();

  dimensions$ = combineLatest([this.height$, this.width$]).pipe(
    map(([height, width]) => ({ height, width })),
  );

  setHeight(value: number) {
    this._height$.next(value);
  }

  setWidth(value: number) {
    this._width$.next(value);
  }

  setSelectedNode(value: string) {
    this._selectedNode$.next(value);
  }

  setDefaultNode(value: string) {
    this._defaultNode = value;
  }

  setTappedNode(value: string) {
    this._tappedNode = value;
  }

  cleanUp() {
    this._cleanUp.next(true);
  }

  setSystemVizData(data: SystemVisualizationData): void {
    this.sysVizData.next(data);
  }

  getSelectedNode(): string {
    return this._selectedNode$.value;
  }

  getDefaultNode(): string {
    return this._defaultNode;
  }

  getTappedNode(): string {
    return this._tappedNode;
  }

  getSystemVizData(): SystemVisualizationData {
    return this.sysVizData.value;
  }

  exportOverview(data: string): void {
    this.downloadBase64InPNG(data, this.buildExportedOverviewName());
  }

  exportNodeView(data: string): void {
    this.downloadBase64InPNG(data, this.buildExportedNodeViewName());
  }

  private buildExportedOverviewName(): string {
    return `overview`;
  }

  private buildExportedNodeViewName(): string {
    return `${this.getScenarioID()}_${this.getSelectedNode()}`;
  }

  getScenarioID(): string {
    return this.sysVizData.value.scenarioId;
  }

  getVariationID(): string {
    return this.sysVizData.value.variationId;
  }

  private downloadBase64InPNG(base64: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = base64;
    link.download = `${fileName}.png`;
    link.click();
  }

  private selectSystemVizData(): Observable<SystemVisualizationData> {
    return this.sysVizData.asObservable().pipe(filter((data) => !!data));
  }

  mapListToSelectOptions(list: string[]): FormFieldOption<string>[] {
    return list.map((v) => ({ value: v, name: v }));
  }

  extractSelectedYearDataFromYearlyData(
    data: YearlySystemVisualizationData,
    selectedYear: string,
  ): SystemVisualizationData {
    return {
      overviewData: data[selectedYear].overviewData,
      nodeViewData: data[selectedYear].nodeViewData,
      scenarioId: data.scenarioId,
      variationId: data.variationId,
    };
  }
}
