import { NgModule, ModuleWithProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { userFeatureName } from './user.actions';
import { OldUserEffects } from './user.effects';
import { UserFacade } from './user.facade';
import { oldUserReducer } from './user.reducer';
import { UserService } from './user.service';

@NgModule({
  imports: [
    StoreModule.forFeature(userFeatureName, oldUserReducer),
    EffectsModule.forFeature([OldUserEffects]),
  ],
})
export class OldUserModule {
  static forRoot(): ModuleWithProviders<OldUserModule> {
    return {
      ngModule: OldUserModule,
      providers: [UserService, UserFacade],
    };
  }
}
