import { CaseFacadeService } from 'prosumer-app/+case';
import { ScenarioFacadeService } from 'prosumer-app/+scenario';
import {
  DialogService,
  LoggerService,
  UserFacadeService,
} from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  BaseDialogComponent,
  contains,
  fadeInAnimation,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ProsumerRoutePathService } from 'prosumer-core';
import {
  DuplicateDialogComponent,
  ShareDialogComponent,
} from 'prosumer-shared';
import { PermissionCheckerService } from 'prosumer-shared/services/permission-checker';
import { Subject } from 'rxjs';
import { map, mergeMap, mergeMapTo, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Project } from '../../models';
import { ProjectFacadeService } from '../../state';

@Component({
  selector: 'prosumer-project-list-page',
  templateUrl: './project-list-page.component.html',
  styleUrls: ['./project-list-page.component.scss'],
  animations: [rowAnimation, fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListPageComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public routePath: ProsumerRoutePathService,
    private _dialogService: DialogService,
    private _permissionChecker: PermissionCheckerService,
    private _logger: LoggerService,
    private _projectFacade: ProjectFacadeService,
    private _caseFacade: CaseFacadeService,
    private _scenarioFacade: ScenarioFacadeService,
    private _userFacade: UserFacadeService,
  ) {
    super();
  }

  error$ = this._projectFacade.error$.pipe(this.takeUntilShare());

  loadingList$ = this._projectFacade.loadingList$.pipe(this.takeUntilShare());
  loadedList$ = this._projectFacade.loadedList$.pipe(this.takeUntilShare());

  projects$ = this._projectFacade.dataList$.pipe(this.takeUntilShare());

  projectDataSource$ = this.projects$.pipe(
    map((projects) => {
      const datasource = new MatTableDataSource(projects);
      datasource.paginator = this.paginator;
      datasource.sort = this.sort;
      datasource.filterPredicate = (data: any, filter: string = '') =>
        contains(
          data['name'].trim().toLowerCase(),
          filter.trim().toLowerCase(),
        );
      return datasource;
    }),
    this.takeUntilShare(),
  );

  projectDataLength$ = this.projectDataSource$.pipe(
    map((dataSource) => dataSource.data.length),
    this.takeUntilShare(),
  );

  filter$ = new Subject<string>().pipe(
    mergeMap((filter) =>
      this.projectDataSource$.pipe(
        map((dataSource) => (dataSource.filter = filter)),
      ),
    ),
    this.takeUntilShare(),
  ) as Subject<string>;

  filteredDataLength$ = this.filter$.pipe(
    mergeMapTo(this.projectDataSource$),
    map((dataSource) => dataSource.filteredData.length),
    this.takeUntilShare(),
  );

  /* Current User */
  currentUser$ = this._userFacade.clientUser$.pipe(this.takeUntilShare());

  hasAccess$ = (project: Project, method: 'CAN_DELETE' | 'CAN_SHARE') =>
    this.currentUser$.pipe(
      map(
        (user) =>
          this._permissionChecker.isUserPermitted(project, user)[method],
      ),
      this.takeUntilShare(),
    );

  ngOnInit() {
    this._projectFacade.selectId(undefined);
    this._caseFacade.selectId(undefined);
    this._scenarioFacade.selectId(undefined);
    this.filter$.subscribe();

    this.loadedList$
      .pipe(
        take(1),
        map((loadedList) => !loadedList),
      )
      .subscribe(
        (hasLoaded) => hasLoaded && this._projectFacade.getListWithDetails(),
      );
  }

  onCopy(project: Project) {
    this._dialogService
      .openDialog(DuplicateDialogComponent, {
        duplicateName: project.name,
        entityArchetype: 'project',
        width: 500,
        disableClose: true,
        existingData$: this._projectFacade.dataList$,
        project,
      })
      .subscribe((data) =>
        data && data.project && data.duplicateName
          ? this._projectFacade.copy(data.project, data.duplicateName)
          : this._logger.debug('Duplicate cancelled'),
      );
  }

  onDelete(project: Project) {
    this._dialogService
      .openDialog(BaseDialogComponent, {
        title: 'Delete Project',
        message: `Do you wish to delete "${project.name}" project along with all
      its cases and scenarios?`,
        confirm: 'Delete',
        close: 'Cancel',
        project,
      })
      .subscribe((data) =>
        data && data.project
          ? this._projectFacade.delete(data.project)
          : this._logger.debug('Delete cancelled'),
      );
  }

  onShare(project: Project) {
    this._dialogService
      .openDialog(ShareDialogComponent, {
        width: 600,
        disableClose: true,
        project,
      })
      .subscribe((data) =>
        data && data.project && data.userIds
          ? this._projectFacade.share(data.project, data.userIds)
          : this._logger.debug('Share cancelled'),
      );
  }

  onUnshare(project: Project) {
    this.currentUser$.pipe(take(1)).subscribe((user) => {
      this._projectFacade.unshare(project, [user.id]);
    });
  }

  onFilter(value: string) {
    this.filter$.next(value);
  }
}
