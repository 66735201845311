import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'prosumer-renewable-profile',
  templateUrl: './renewable-profile.component.html',
  styleUrls: ['./renewable-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenenewableProfileComponent extends BaseComponent {
  constructor(private _userFacade: UserFacadeService) {
    super();
  }

  hasMeteomatic$ = this._userFacade.clientUserPrefsFeatures$.pipe(
    filter((userPrefs) => !!userPrefs),
    map((userPrefs) => (userPrefs || {}).meteomatics === 1),
    take(1),
  );
}
