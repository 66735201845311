import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/* Angular Core Modules */
@NgModule({
  exports: [
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class AngularModule {}
