import { Store, select, ActionsSubject } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { filter } from 'rxjs/operators';

import { BaseEntityState, BaseEntity } from '../models';
import { StateFactory } from './state.factory';
import * as Actions from './state.actions';

export class StateFacadeService<
  T extends BaseEntityState<U>,
  U extends BaseEntity,
> {
  constructor(
    public store: Store<T>,
    public stateFactory: StateFactory<T, U>,
    public actionSubject$: ActionsSubject,
  ) {}

  state$ = this.store.pipe<T>(
    select(this.stateFactory.queries.getFeatureState),
  );
  dataList$ = this.store.pipe<U[]>(
    select(this.stateFactory.queries.getDataList),
  );
  dataMap$ = this.store.pipe<Dictionary<U>>(
    select(this.stateFactory.queries.getDataMap),
  );
  dataIds$ = this.store.pipe<string[] | number[]>(
    select(this.stateFactory.queries.getDataIds),
  );
  dataTotal$ = this.store.pipe<number>(
    select(this.stateFactory.queries.getDataTotal),
  );
  loadingList$ = this.store.pipe<boolean>(
    select(this.stateFactory.queries.isLoadingList),
  );
  loadedList$ = this.store.pipe<boolean>(
    select(this.stateFactory.queries.isLoadedList),
  );
  creating$ = this.store.pipe<boolean>(
    select(this.stateFactory.queries.isCreating),
  );
  error$ = this.store.pipe<string>(
    select(this.stateFactory.queries.getGeneralError),
  );

  selectedId$ = this.store.pipe<string>(
    select(this.stateFactory.queries.getSelectedId),
  );
  selectedData$ = this.store.pipe<U>(
    select(this.stateFactory.queries.getSelectedData),
  );

  dataById$ = (id: string) =>
    this.store.pipe<U>(select(this.stateFactory.queries.getDataById, id));

  clear() {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.CLEAR_STATE),
    );
  }

  create(data: U) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.CREATE, { data }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          Actions.ActionTypes(this.stateFactory.feature).CREATE_SUCCESS,
      ),
    );
  }

  get(id: string, params?: Dictionary<string>, forced?: boolean) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.GET, { id, params, forced }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          Actions.ActionTypes(this.stateFactory.feature).GET_SUCCESS,
      ),
    );
  }

  getList(id?: string, params?: Dictionary<string>) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.GET_LIST, { id, params }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          Actions.ActionTypes(this.stateFactory.feature).GET_LIST_SUCCESS,
      ),
    );
  }

  update(data: U) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.UPDATE, { data }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          Actions.ActionTypes(this.stateFactory.feature).UPDATE_SUCCESS,
      ),
    );
  }

  delete(data: U) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.DELETE, { data }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          Actions.ActionTypes(this.stateFactory.feature).DELETE_SUCCESS,
      ),
    );
  }

  selectId(id: string) {
    this.store.dispatch(
      this.stateFactory.createAction(Actions.Type.SELECT_ID, { id }),
    );
  }
}
