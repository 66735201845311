import { HttpService } from 'prosumer-app/libs/eyes-core';

import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Dialog will appear at least after a day
const ASK_ME_LATER_DAY_MODIFIER = 1;
const DONT_ASK_AGAIN_DAY_MODIFIER = 90;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-survey-form',
  templateUrl: './user-survey-form.component.html',
  styleUrls: ['./user-survey-form.component.scss'],
})
export class UserSurveyFormComponent {
  form = this.formBuilder.group({
    rate: [null, Validators.required],
    appreciate: [null, Validators.required],
    missing: [null, Validators.required],
    comment: [null],
    expertise: [null, Validators.required],
    includeUserInfo: [true],
  });
  isSubmitting = false;

  expertiseOptions: Array<Record<string, string>> = [
    {
      key: 'Beginner',
      value: 'beginner',
    },
    {
      key: 'Intermediate',
      value: 'intermediate',
    },
    {
      key: 'Advanced',
      value: 'advanced',
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UserSurveyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _http: HttpService,
  ) {}

  submit() {
    this.isSubmitting = true;
    this._http.post(this.data.endPoint, this.form.value).subscribe(() => {
      this.dialogRef.close();
    });
  }

  onClickAskMeLater() {
    this.dialogRef.close();
    this._http
      .patch(this.data.userPrefEndpoint, {
        requestCode: 'updateFeedbackDate',
        dayModifier: ASK_ME_LATER_DAY_MODIFIER,
      })
      .subscribe(() => {});
  }

  onClickDontAsk() {
    this.dialogRef.close();
    this._http
      .patch(this.data.userPrefEndpoint, {
        requestCode: 'updateFeedbackDate',
        dayModifier: DONT_ASK_AGAIN_DAY_MODIFIER,
      })
      .subscribe(() => {});
  }
}
