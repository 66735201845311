import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AuthActions, AuthActionTypes, authFeatureName } from './auth.actions';
import { AuthState } from './auth.model';

export const authInitialState: AuthState = {
  authenticated: false,
  loading: false,
  redirectUrl: 'home',
};

export const authReducer = (
  state: AuthState = authInitialState,
  action: AuthActions,
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        data: action.payload,
      };
    case AuthActionTypes.SET_AS_LOGGED_IN:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.SET_AS_LOGGED_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AuthActionTypes.SET_AS_LOGGED_IN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        data: action.payload,
      };
    case AuthActionTypes.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload || 'home',
      };
    case AuthActionTypes.REFRESH_TOKEN:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const authFeature: MemoizedSelector<
  Record<string, unknown>,
  AuthState
> = createFeatureSelector<AuthState>(authFeatureName);

export const authSelectors: {
  [key: string]: MemoizedSelector<Record<string, unknown>, any>;
} = {
  state: authFeature,
  authenticated: createSelector(
    authFeature,
    (state: AuthState) => state.authenticated,
  ),
  loading: createSelector(authFeature, (state: AuthState) => state.loading),
  data: createSelector(authFeature, (state: AuthState) => state.data),
  error: createSelector(authFeature, (state: AuthState) => state.error),
  redirectUrl: createSelector(
    authFeature,
    (state: AuthState) => state.redirectUrl,
  ),
};
