<button
  class="action-menu"
  data-testid="actions_menu"
  type="button"
  mat-button
  [matMenuTriggerFor]="menu"
>
  {{ getUserPermissionLabel(permission$ | async) }}
  <mat-icon aria-hidden="false">expand_more</mat-icon>
</button>
<mat-menu class="action-menu-list" #menu="matMenu">
  <button
    mat-menu-item
    *ngIf="user?.permissions !== CAN_VIEW"
    (click)="onChangePermission(CAN_VIEW)"
  >
    Can View
  </button>
  <button
    mat-menu-item
    *ngIf="user?.permissions !== CAN_EDIT"
    (click)="onChangePermission(CAN_EDIT)"
  >
    Can Edit
  </button>
  <ng-container *ngIf="showRemove">
    <mat-divider></mat-divider>
    <button class="remove" mat-menu-item (click)="onUnshare()">Remove</button>
  </ng-container>
</mat-menu>
