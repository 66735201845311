import { Library, Profile } from 'prosumer-app/+scenario/models';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseFormComponent,
  contains,
  FormFieldOption,
  generateShortUID,
} from 'prosumer-app/libs/eyes-shared';
import { mapYearlyDataToProfiles } from 'prosumer-app/shared/utils';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { LibraryFilter, YearlyInterval } from 'prosumer-shared/models';
import { ProfileFormHelperService } from 'prosumer-shared/services/profile-form-helper';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  NgControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import {
  YearlyLoadsIntervalDialogComponent,
  YearlyLoadsIntervalDialogModel,
} from './yearly-loads-interval-dialog';
import { YearlyLoadsIntevalValidators } from './yearly-loads-interval.validators';

export const INITIAL_TAB_INDEX = 0;
export const NO_VALUES_IN_YEAR = 8760;

@Component({
  selector: 'prosumer-yearly-loads-interval',
  templateUrl: './yearly-loads-interval.component.html',
  styleUrls: ['./yearly-loads-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyLoadsIntervalComponent
  extends BaseFormComponent
  implements AfterViewInit
{
  // Defines if the library panel will be displayed which includes the load type and the library table
  @Input() useLibraryPanel = true;

  @Input() alwaysCustom = false; // Defines if the load type is always 'custom' disabling the load type field
  @Input() yearlyLoadEnabled = true; // Defines if the yearly load field is displayed or not

  @Input() libraries: Array<Library>; // Defines the libraries to display as option for selection
  @Input() defaultLocation = BINARY_LOCATIONS.END_USE_LOADS; // Defines the default location of the profile
  @Input() xAxisLabel = 'Hours';
  @Input() yAxisLabel = 'kW';

  // Defines the messages for each of the fields, contextual helpers, labels, placeholders, etc.
  @Input() yearlyLoadMessages: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.loadType',
    loadTypeTooltip: 'wizard_loads.wizard_loards_load_type',
    libraryNoRecordMessage:
      'Scenario.messages.library.noLibraryForEnergyVector',
    libraryNoRecordMessage2: 'Scenario.messages.library.noEnergyVectorSelected',
    libraryRequiredMessage: 'Scenario.messages.der.library.required',
    yearlyLoadPlaceholder: 'Scenario.placeholders.yearlyLoad.container',
    yearlyLoadLabel: 'Scenario.labels.yearlyLoad.container',
    yearlyLoadTooltip: 'wizard_loads.wizard_loads_yearly_load',
    yearlyLoadRequired: 'Scenario.messages.yearlyLoad.required',
    yearlyLoadGreaterThanZero:
      'Scenario.messages.yearlyLoad.mustBeGreaterThanZero',
    loadsDataPlaceholder: 'Scenario.placeholders.yearlyLoad.input',
    loadsDataLabelCustom: 'Scenario.labels.yearlyLoad.input',
    loadsDataLabelLibrary: 'Scenario.labels.normalizedLoadProfile',
    loadsDataTooltip: 'wizard_loads.wizard_loards_load_profile',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };
  @Input() allowNegativeInput = false;

  _loading = false;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
  }
  get loading(): boolean {
    return this._loading;
  }

  // The start year of the horizon
  _startYear: number;
  @Input() set startYear(value: number) {
    this._startYear = value;
  }
  get startYear(): number {
    return this._startYear;
  }

  // The end year of the horizon
  _endYear: number;
  @Input() set endYear(value: number) {
    this._endYear = value;
  }
  get endYear(): number {
    return this._endYear;
  }

  // The intervals form array control
  get intervalsFormArray(): UntypedFormArray {
    return this.form.controls.intervals as UntypedFormArray;
  }

  nodes$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  @Input() set nodeOptions(nodeOptions: FormFieldOption<string>[]) {
    this.nodes$.next(nodeOptions);
  }

  @Input() allowStringInput = false;

  /**
   * Emits the selected profile and its corresponding index
   */
  @Output() selectedTabChange = new EventEmitter<{
    selectedProfile: Profile;
    index: number;
  }>();

  /**
   * Emits the selected library
   */
  @Output() selectedLibraryChange = new EventEmitter<Library>();

  /**
   * Emits the library filters
   */
  @Output() libraryFiltersChange = new EventEmitter<LibraryFilter>();

  /**
   * Emits the intervals every change in the form array
   */
  @Output() intervalsChange = new EventEmitter<Array<Profile>>();

  written: boolean; // Determines if the profile is already initalized to prevent re-initializing when start / end year inputs are updated
  selected = new UntypedFormControl(INITIAL_TAB_INDEX); // The control representing the selected or active tab
  profiles: Array<Profile>; // The raw profiles before converting to intervals

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public profileFormHelperService: ProfileFormHelperService,
    private _dialogService: DialogService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  /**
   * Defines the form structure
   */
  defineForm() {
    return {
      intervals: this.formBuilder.array([]),
    };
  }

  /**
   * Overriden control value accessor's write value that is executed when the parent form initializes the control or update the control's
   * value
   *
   * @param profiles - the array of profiles
   */
  writeValue(profiles: Array<Profile>): void {
    if (!!profiles) {
      this.profiles = profiles;
      this.initForm(this.mapToIntervals(profiles));
      this.written = true;
    } else {
      this.addInterval(null, this.startYear, this.endYear);
    }
  }

  /**
   * Overriden to avoid parent disabling effects
   *
   * @param disabled - true if disabled
   */
  setDisabledState(disabled: boolean) {}

  ngAfterViewInit() {
    this.initIntervalsChangeHandler();
    this.initSelectedTabHandler();
    this.initSubmitHandler();
  }

  /**
   * Initialize the selected tab handler representing the index of the intervals; Every change in the value will be re-emitted in the
   * selected tab change output as a dictionary of the selected profile at the specified index and the index itself
   */
  initSelectedTabHandler() {
    this.selected.valueChanges.pipe(this.takeUntil()).subscribe((index) => {
      const selectedInterval = this.intervalsFormArray.at(index);
      if (!!selectedInterval) {
        const selectedIntervalValue: YearlyInterval = selectedInterval.value;
        if (!!selectedIntervalValue) {
          this.selectedTabChange.emit({
            selectedProfile: this.mapToProfile(selectedIntervalValue),
            index,
          });
        }
      }
    });
  }

  /**
   * Initialize the changes in the intervals form array while checking if there are changes in the previous and current value before
   * triggering the on change that will propagate the value to the parent; The intervals change output will also emit the change
   */
  initIntervalsChangeHandler(): void {
    this.intervalsFormArray.valueChanges
      .pipe(this.takeUntil())
      .subscribe((value) => {
        const profiles = this.mapToProfiles(
          this.intervalsFormArray.getRawValue(),
        );
        this.markProfileValidity(profiles);
        this.onChange(profiles);
      });
  }

  /**
   * Marks the tab for specific profile as invalid or not (asterisk and warn color)
   *
   * @param profiles - array of profiles
   */
  markProfileValidity(profiles: Array<Profile>) {
    if (!profiles) {
      return;
    }
    profiles.forEach((profile, index) => {
      if (
        YearlyLoadsIntevalValidators.isInvalid(
          profile,
          this.yearlyLoadEnabled,
          this.alwaysCustom,
          this.useLibraryPanel,
        )
      ) {
        this.profileFormHelperService.addInvalidProfile(index);
      } else {
        this.profileFormHelperService.removeInvalidProfile(index);
      }
    });
  }

  /**
   * Initialize the handler when the the profile form helper's submitted observable emit changes
   * This will trigger the hidden button's click event to trigger the validations
   */
  initSubmitHandler() {
    this.profileFormHelperService.submitted$
      .pipe(this.takeUntil())
      .subscribe((sub) => {
        const element: HTMLElement = document.getElementById(
          'btn',
        ) as HTMLElement;
        element.click();
        if (!this.profiles && this.form.pristine) {
          this.profileFormHelperService.addInvalidProfile(0);
        }
      });
  }

  /**
   * Initialize the form by clearing the intervals then adding/re-adding the parameters as form groups in the intervals form array;
   * Selected is also set to determine the active index
   *
   * @param intervals - the array of intervals
   * @param selected  - the selected index as the active tab
   */
  initForm(
    intervals?: Array<YearlyInterval>,
    selected: number = INITIAL_TAB_INDEX,
  ): void {
    this.clearIntervals();
    intervals.forEach((interval) => this.addInterval(interval));
    this.selected.patchValue(selected);
  }

  /**
   * Creates a form group based on the parameter
   *
   * @param interval - the interval
   * @param startYear - the startYear default value
   * @param endYear - the endYear default value
   */
  createLoadFormGroup(
    interval: YearlyInterval,
    startYear?: number,
    endYear?: number,
  ): UntypedFormGroup {
    const loadFormGroup = this.formBuilder.group({
      startYear: this.formBuilder.control(startYear || null),
      endYear: this.formBuilder.control(endYear || null),
      profile: this.formBuilder.control({
        startYear: this.startYear,
        endYear: this.endYear,
        forSaving: this.mode === 'create' || this.mode === 'add',
        library: null,
        loadProfile: null,
        loadType: this.alwaysCustom ? 'custom' : 'library',
        localId: generateShortUID(),
        location: this.defaultLocation,
        yearlyLoad: 1,
      }),
    });
    if (!!interval) {
      loadFormGroup.patchValue(
        {
          ...interval,
        },
        { emitEvent: false },
      );
    }
    return loadFormGroup;
  }

  /**
   * Adds an interval form group to the intervals form array based on the parameter
   *
   * @param interval - the interval
   * @param startYear - the startYear default value
   * @param endYear - the endYear default value
   */
  addInterval(interval: YearlyInterval, startYear?: number, endYear?: number) {
    this.intervalsFormArray.push(
      this.createLoadFormGroup(interval, startYear, endYear),
    );
  }

  /**
   * Clears the interval array via loop since reset does not do this in this version of Angular
   */
  clearIntervals(): void {
    while (this.intervalsFormArray.length) {
      this.intervalsFormArray.removeAt(0);
    }
  }

  /**
   * Calculates the index that is to be activated based on the previous intervals and the updated intervals
   *
   * @param prevIntervals - the previous intervals
   * @param nextIntervals - the modified intervals
   */
  calculateActiveIndex(
    prevIntervals: Array<Profile>,
    nextIntervals: Array<YearlyInterval>,
  ) {
    return nextIntervals.length > prevIntervals.length
      ? nextIntervals.filter(
          (interval) =>
            !contains(
              prevIntervals.map((profile) => profile.localId),
              interval.profile.localId,
            ),
        ).length -
          (nextIntervals.length - prevIntervals.length)
      : nextIntervals.length - 1;
  }

  /**
   * Maps a profile to an interval
   *
   * @param profile - a profile
   */
  mapToInterval(profile: Profile): YearlyInterval {
    return {
      startYear: profile.startYear,
      endYear: profile.endYear,
      profile,
    };
  }

  /**
   * Maps the profile list to an array of intervals
   *
   * @param profiles - the array of profiles
   */
  mapToIntervals(profiles: Profile[]): Array<YearlyInterval> {
    return profiles.map(this.mapToInterval);
  }

  /**
   * Maps an interval to a profile
   *
   * @param interval - an interval
   */
  mapToProfile(interval: YearlyInterval): Profile {
    return {
      ...interval.profile,
      startYear: interval.startYear,
      endYear: interval.endYear,
    };
  }

  /**
   * Maps the interval list to an array of profiles
   *
   * @param intervals - the array of intervals
   */
  mapToProfiles(intervals: Array<YearlyInterval>): Array<Profile> {
    return intervals.map(this.mapToProfile);
  }

  /**
   * Event handler when the edit interval button is clicked. This will open the yearly intervals dialog to manage the intervals represented
   * by tabs. This will recalculate the active index to improve the user experience then re-initialize the form.
   */
  onEditInterval(): void {
    this._dialogService
      .openDialog(YearlyLoadsIntervalDialogComponent, {
        startYear: this.startYear,
        endYear: this.endYear,
        intervals: this.intervalsFormArray.value,
        defaultLoadType: this.alwaysCustom ? 'custom' : 'library',
        defaultLocation: this.defaultLocation,
        width: 800,
        disableClose: true,
      } as YearlyLoadsIntervalDialogModel)
      .pipe<Array<YearlyInterval>>(take(1))
      .subscribe((intervals) => {
        if (!!intervals) {
          const activeIndex = this.calculateActiveIndex(
            this.intervalsFormArray.value,
            intervals,
          );
          this.initForm(intervals, activeIndex);
        }
        this.markForCheck();
      });
  }

  /**
   * Event handler when active tab of the interval is changed; This will just patch the selected control by the index emitted
   *
   * @param index - the index of the selected tab
   */
  onChangeSelectedTab(index: number) {
    this.selected.patchValue(index);
  }

  /**
   * Event handler when library is selected; Just re-emits the library being selected
   *
   * @param library - the library emitted when selecting a library
   */
  onSelectLibrary(library: Library) {
    this.selectedLibraryChange.emit(library);
  }

  /**
   * Event handler when library filters change; Just re-emits the filters via output emitter
   *
   * @param filters - the filters emitted when filters in the library selection list are changed
   */
  onChangeLibraryFilters(filters: LibraryFilter) {
    this.libraryFiltersChange.emit(filters);
  }

  /**
   * Checks if the interval form at specified index is valid via profile form helper
   *
   * @param index - the index of the interval form being checked
   */
  isInvalid(index: number) {
    return this.profileFormHelperService.isInvalid(index);
  }

  get dataframeOpts() {
    return {
      startYear: this.startYear,
      endYear: this.endYear,
      columnLength: NO_VALUES_IN_YEAR,
    };
  }

  handleDataframe(yl: { [year: string]: number[] }) {
    const profiles = mapYearlyDataToProfiles(
      yl,
      this.startYear,
      this.endYear,
      this.defaultLocation,
    );
    this.writeValue(profiles);
  }
}
