import { StateFacadeService } from 'prosumer-app/libs/eyes-shared';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { Case } from '../models';
import { CaseState } from './case-state.model';
import * as CaseActions from './case.actions';
import { caseStateFactory } from './case.factory';
import { caseQueries } from './case.selectors';

@Injectable()
export class CaseFacadeService extends StateFacadeService<CaseState, Case> {
  filterCases$ = (projectId: string) =>
    this.store.pipe<Array<Case>>(
      select(caseQueries.filterCases, { projectId }),
    );

  constructor(
    public store: Store<CaseState>,
    public actionSubject$: ActionsSubject,
  ) {
    super(store, caseStateFactory, actionSubject$);
  }

  copy(data: Case, name: string) {
    this.store.dispatch(new CaseActions.CopyCase({ data, name }));
  }

  remove(id: string) {
    this.store.dispatch(new CaseActions.RemoveCase({ id }));
  }

  getCaseListByNodeType(nodetype: 'single' | 'multinode') {
    this.store.dispatch(new CaseActions.GetCaseListByNodeType({ nodetype }));
  }

  updateChartColors(data: Case): void {
    this.store.dispatch(new CaseActions.UpdateChartColors({ data }));
  }

  getListWithDetails(id: string) {
    this.store.dispatch(new CaseActions.GetListWithDetails({ id }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === CaseActions.ActionTypes.GET_LIST_WITH_DETAILS_SUCCESS,
      ),
    );
  }
}
