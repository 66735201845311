import { environment } from 'prosumer-env';

export const redirect = (url: string) => {
  window.location.assign(url);
  return false;
};

export const openToNewTab = (url: string) => {
  window.open(url, '_blank');
  return false;
};

export const getRedirectLoginUrl = (): string => {
  const identityProvider = environment.cognito.Auth.oauth.identityProvider;
  const domain = environment.cognito.Auth.oauth.domain;
  const clientId = environment.cognito.Auth.userPoolWebClientId;
  const redirectSignIn = environment.cognito.Auth.oauth.redirectSignIn;
  const responseType = environment.cognito.Auth.oauth.responseType;

  let url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;
  if (identityProvider) {
    url =
      `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}` +
      `&identity_provider=${identityProvider}`;
  }
  return url;
};

export const redirectLogin = () => redirect(getRedirectLoginUrl());

export const redirectHome = () => redirect(environment.homeUrl);

export const redirectFAQ = () => redirect(environment.faqUrl);

export const redirectTraining = () => openToNewTab(environment.trainingUrl);

export const redirectUpdates = () => openToNewTab(environment.updatesUrl);
