<mat-form-field
  appearance="outline"
  class="hide-empty-subscript eyes-maximize-width"
  [floatLabel]="hasMultipleValues ? 'always' : 'auto'"
>
  <mat-label>{{ inputLabel | translate }}</mat-label>
  <input
    matInput
    [type]="'number'"
    [required]="!notRequired && !isNullable"
    [placeholder]="placeholder | translate"
    [formControl]="inputValueControl"
    [readonly]="isViewOnly"
    data-testid="input-value"
  />
  <prosumer-yearly-sparkline
    *ngIf="hasMultipleValues"
    class="sparkline-class"
    [yearlyValuesMap]="control?.value"
    [smallerChart]="smallerChart"
    (click)="performAction()"
  >
  </prosumer-yearly-sparkline>

  <mat-icon
    aria-hidden="false"
    class="prosumer-yearly-input-tooltip-anchor"
    matSuffix
    *ngIf="!hasMultipleValues"
    color="primary"
    (click)="performAction()"
  >
    {{ iconPrefix }}
  </mat-icon>

  <mat-icon
    *ngIf="hasMultipleValues"
    matSuffix
    class="prosumer-yearly-input-tooltip-anchor-delete"
    aria-hidden="false"
    color="warn"
    (click)="clearValues()"
  >
    cancel
  </mat-icon>
  <mat-icon
    aria-hidden="false"
    *ngIf="contextHelpMsg"
    class="eyes-input-tooltip-anchor"
    matSuffix
    tooltipPosition="left"
    [prosumerTooltip]="contextHelpMsg"
    >help
  </mat-icon>
  <mat-error
    *ngFor="let error of inputValueControl?.errors | keyvalue"
    [innerHtml]="errorMessage + '.' + error?.key | translate"
  >
  </mat-error>
  <!-- <mat-error *ngIf="inputValueControl?.errors?.required" [innerHtml]="errorMessage | translate"></mat-error> -->
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>
<mat-progress-bar color="accent" mode="indeterminate" *ngIf="processing">
</mat-progress-bar>
