import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  UserAcceptGDPRFailureAction,
  UserActions,
  UserActionTypes,
  userFeatureName,
  UserGetGDPRFailureAction,
  UserGetGDPRSuccessAction,
} from './user.actions';
import { OldUser, OldUserState } from './user.model';

export const userInitialState: OldUserState = {
  userInfo: undefined,
  gdpr: undefined,
  fetchedUser: undefined,
  fetching: false,
  userCache: undefined,
};

export const oldUserReducer = (
  state: OldUserState = userInitialState,
  action: UserActions,
): OldUserState => {
  switch (action.type) {
    case UserActionTypes.CLEAR:
      return {
        ...state,
        userInfo: undefined,
        gdpr: undefined,
        fetchedUser: undefined,
        fetching: false,
        userCache: undefined,
      };
    case UserActionTypes.ACCEPT_GDPR:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: true,
        },
      };
    case UserActionTypes.ACCEPT_GDPR_FAILURE:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: false,
          error: (action as UserAcceptGDPRFailureAction).payload.error,
        },
      };
    case UserActionTypes.ACCEPT_GDPR_SUCCESS:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: false,
          acceptedVersion: (action as UserAcceptGDPRFailureAction).payload
            .latestVersionToAccept,
        },
      };
    case UserActionTypes.GET_GDPR:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: true,
        },
      };
    case UserActionTypes.GET_GDPR_FAILURE:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: false,
          error: (action as UserGetGDPRFailureAction).payload.error,
        },
      };
    case UserActionTypes.GET_GDPR_SUCCESS:
      return {
        ...state,
        gdpr: {
          ...state.gdpr,
          loading: false,
          latestVersion: (action as UserGetGDPRSuccessAction).payload
            .latestVersion,
          text: (action as UserGetGDPRSuccessAction).payload.text,
          acceptedVersion: (action as UserGetGDPRSuccessAction).payload
            .acceptedVersion,
        },
      };
    case UserActionTypes.FETCH_BY_EMAIL:
    case UserActionTypes.FETCH_BY_ID:
      return {
        ...state,
        fetching: true,
        fetchedUser: undefined,
      };
    case UserActionTypes.FETCH_USER_SUCCESS:
      const userData: OldUser = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetchedUser: userData,
        userCache: {
          ...state.userCache,
          [userData.uuid]: userData,
        },
      };
    case UserActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchedUser: {
          ...state.fetchedUser,
          email: action.payload.faultyKey,
          uuid: action.payload.faultyKey,
          error: true,
        },
      };
    case UserActionTypes.CLEAR_FETCHED_USER:
      return {
        ...state,
        fetchedUser: undefined,
      };
    default:
      return state;
  }
};

export const oldUserFeature: MemoizedSelector<
  Record<string, unknown>,
  OldUserState
> = createFeatureSelector<OldUserState>(userFeatureName);

export const userSelectors = {
  getUserState: oldUserFeature,
  getUserInfo: createSelector(
    oldUserFeature,
    (state: OldUserState) => state.userInfo,
  ),
  getGDPR: createSelector(oldUserFeature, (state: OldUserState) => state.gdpr),
  isUserInfoLoading: createSelector(oldUserFeature, (state: OldUserState) =>
    state.userInfo ? state.userInfo.loading : false,
  ),
  isGDPRLoading: createSelector(oldUserFeature, (state: OldUserState) =>
    state.gdpr ? state.gdpr.loading : false,
  ),
  getGDPRText: createSelector(oldUserFeature, (state: OldUserState) =>
    state.gdpr ? state.gdpr.text : undefined,
  ),
  getGDPRAcceptedVersion: createSelector(
    oldUserFeature,
    (state: OldUserState) =>
      state.gdpr ? state.gdpr.acceptedVersion : undefined,
  ),
  getGDPRLatestVersion: createSelector(oldUserFeature, (state: OldUserState) =>
    state.gdpr ? state.gdpr.latestVersion : undefined,
  ),
  isFetching: createSelector(
    oldUserFeature,
    (state: OldUserState) => state.fetching,
  ),
  fetchUser: createSelector(
    oldUserFeature,
    (state: OldUserState) => state.fetchedUser,
  ),
};
