import {
  HostListener,
  Input,
  AfterViewInit,
  OnInit,
  Directive,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../base-component';

@Directive()
export abstract class BaseChartComponent<T, U>
  extends BaseComponent
  implements OnInit
{
  private chartWidth = new BehaviorSubject(0);
  private chartHeight = new BehaviorSubject(0);

  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;
  @Input() showLegend: boolean;

  // The chart-compatible data used for drawing the chart
  chartData: T;

  chartWidth$ = this.chartWidth.asObservable();
  chartHeight$ = this.chartHeight.asObservable();

  @Input() chartXAxisFormat = (value: any) => value;
  @Input() chartTooltipTitleFormat = (value: any) => value;

  /**
   * Maps a value to chart-compatible data
   *
   * @param value - the value of type U that will be transformed to type T
   */
  abstract mapToChartData(value: U): T;

  abstract calculateWidth(): number;
  abstract calculateHeight(): number;

  @HostListener('window:resize') onResize() {
    this.setChartWidth(this.calculateWidth());
    this.setChartHeight(this.calculateHeight());
  }

  setChartWidth(value: number) {
    this.chartWidth.next(value);
  }

  setChartHeight(value: number) {
    this.chartHeight.next(value);
  }

  ngOnInit() {
    this.onResize();
  }
}
