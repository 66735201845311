<mat-icon
  aria-hidden="false"
  *ngIf="isCustomIcon(); else fontIcon"
  tooltipPosition="center"
  [svgIcon]="statusObj.icon"
  [prosumerTooltip]="statusObj.label | translate"
  [attr.aria-label]="statusObj.label"
  [attr.data-testid]="'status-' + status"
  [ngClass]="[statusObj.class, 'status-icon']"
></mat-icon>
<ng-template #fontIcon>
  <mat-icon
    aria-hidden="false"
    tooltipPosition="center"
    [prosumerTooltip]="statusObj.label | translate"
    [attr.aria-label]="statusObj.label"
    [attr.data-testid]="'status-' + status"
    [ngClass]="[statusObj.class, 'status-icon']"
  >
    {{ statusObj.icon }}
  </mat-icon>
</ng-template>
