import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import {
  ActionTypes,
  Get,
  GetFailure,
  GetSuccess,
  StateEffects,
} from 'prosumer-app/libs/eyes-shared';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ConfigService } from '../../../services/index';
import { User } from '../models';
import { UserAPIService } from '../services';
import { UserFacadeService } from './user-facade.service';
import { UserState } from './user-state.model';
import { userFeature, userStateFactory } from './user.factory';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects extends StateEffects<UserState, User> {
  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _userAPI: UserAPIService,
    private _userFacade: UserFacadeService,
    private _notificaiton: NotificationsService,
    private _translate: TranslateService,
  ) {
    super(
      _actions$,
      _userAPI,
      _notificaiton,
      _userFacade,
      userStateFactory,
      _translate,
    );
  }

  // Get user by your standard UUID
  getUserSuccess$ = createEffect(() => this.getSuccess$, { dispatch: false });
  getUserFailure$ = createEffect(() => this.getFailure$, { dispatch: false });

  getByEmail$: Observable<UserActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<UserActions.GetByEmail>(UserActions.ActionTypes.GET_BY_EMAIL),
      withLatestFrom(this._userFacade.dataList$, (action, fetchedUsers) => ({
        ...action,
        matchedUser: fetchedUsers.find(
          (user) => user.email === action.payload.email,
        ),
      })),
      mergeMap((action) =>
        action.matchedUser
          ? of(new UserActions.GetByEmailSuccess(action.matchedUser))
          : this._userAPI.getByEmail(action.payload.email).pipe(
              map((response) => new UserActions.GetByEmailSuccess(response)),
              catchError((response) =>
                of(
                  new UserActions.GetByEmailFailure({
                    faultyEmail: action.payload.email,
                    message: response,
                  }),
                ),
              ),
            ),
      ),
    ),
  );

  getById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<Get>(ActionTypes(userFeature).GET),
      withLatestFrom(this._userFacade.dataList$, (action, fetchedUsers) => ({
        ...action,
        matchedUser: fetchedUsers.find(
          (user) =>
            user.id === action.payload.id && user.retrievedUsing !== undefined,
        ),
      })),
      mergeMap((encapsulatedAction) =>
        encapsulatedAction.matchedUser
          ? of(
              new GetSuccess(userFeature, {
                id: encapsulatedAction.payload.id as string,
                resultData: encapsulatedAction.matchedUser,
              }),
            )
          : this._userAPI.get(encapsulatedAction.payload.id as string).pipe(
              map(
                (response) =>
                  new GetSuccess(userFeature, {
                    id: response.id,
                    resultData: response,
                  }),
              ),
              catchError((response) =>
                of(new GetFailure(userFeature, { message: response })),
              ),
            ),
      ),
    ),
  );

  // TODO: Implement this if needed!
  // WORKAROUND: since translateService treats the following as keys,
  // a string literal with a valid key value from en.json should be specified
  // here
  getMessageMap() {
    return {
      createSuccess: 'Project.messages.saveSuccess',
      createFailure: 'Project.messages.saveFailure',
      getSuccess: 'Project.messageas.getSuccess',
      getFailure: 'Project.messages.getFailure',
      getListSuccess: 'Project.messages.getAllSuccess',
      getListFailure: 'Project.messages.getAllFailure',
      updateSuccess: 'Project.messages.updateSuccess',
      updateFailure: 'Project.messages.updateFailure',
      deleteSuccess: 'Project.messages.deleteSuccess',
      deleteFailure: 'Project.messages.deleteFailure',
      retry: 'Project.messages.getFailure',
    };
  }
}
