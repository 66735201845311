import {
  BaseComponent,
  FormFieldErrorMessageMap,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Coerce } from 'prosumer-app/core';
import { GeneralValidators, NameValidator } from 'prosumer-app/shared';
import { provideUpserter } from 'prosumer-app/stores';
import {
  VehiclesDispatch,
  VehiclesDispatchStore,
} from 'prosumer-app/stores/vehicles-dispatch';
import { VehiclesDispatchFormDialogData } from './vehicles-dispatch-form-dialog.model';

@Component({
  selector: 'prosumer-vehicles-dispatch-form-dialog',
  templateUrl: './vehicles-dispatch-form-dialog.component.html',
  styleUrls: ['./vehicles-dispatch-form-dialog.component.scss'],
  providers: [provideUpserter(VehiclesDispatchStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclesDispatchFormDialogComponent
  extends BaseComponent
  implements OnInit
{
  vehiclesDispatchForm = this.formbuilder.group({
    vehicleName: [
      '',
      [
        Validators.required,
        NameValidator.validWithCore(),
        GeneralValidators.uniqueValue(
          this.getExistingListWithoutCurrent(),
          'vehicleName',
        ),
      ],
    ],
    vehicleId: [this.getFirstTechnologyOption(), Validators.required],
    routeIds: [[], Validators.required],
    startYear: [this.data.startYear, Validators.required],
    endYear: [this.data.endYear, Validators.required],
    numberOfVehicles: [1, Validators.required],
    v2g: [false, Validators.required],
  });

  routeIds: UntypedFormControl = this.vehiclesDispatchForm.get(
    'routeIds',
  ) as UntypedFormControl;

  vehicleTechnologyOptions = [];
  routeIdsOptions = [];
  yearsOptions = [];
  v2g = false;
  submitted$ = new BehaviorSubject<boolean>(false);
  isViewOnly: boolean;

  errorMessages: FormFieldErrorMessageMap = {
    vehicleName: {
      [GeneralValidators.uniqueValueKey]: this._translate.instant(
        `Scenario.messages.mobility.vehiclesRoutes.vehicleName.alreadyExists`,
      ),
      invalidCharacter: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.vehicleName.invalidCharacter',
      ),
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.vehicleName.required',
      ),
    },
    vehicleId: {
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.vehicleId.required',
      ),
    },
    routeIds: {
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.routeIds.required',
      ),
    },
    startYear: {
      invalid: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.startYear.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.startYear.required',
      ),
    },
    endYear: {
      invalid: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.endYear.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.endYear.required',
      ),
    },
    numberOfVehicles: {
      invalid: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.numberOfVehicles.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.mobility.vehiclesRoutes.numberOfVehicles.required',
      ),
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VehiclesDispatchFormDialogData,
    public dialogRef: MatDialogRef<VehiclesDispatchFormDialogComponent>,
    private formbuilder: UntypedFormBuilder,
    private _translate: TranslateService,
    private readonly detector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initOptions();
    this.setFormValidators();

    this.isViewOnly = this.data.isViewOnly || false;
  }

  initOptions() {
    if (this.data.vehicles) {
      this.vehiclesDispatchForm.controls.vehicleId.patchValue(
        this.getFirstTechnologyOption(),
      );
      this.data.vehicles.forEach((option) =>
        this.vehicleTechnologyOptions.push(option),
      );
    }
    if (this.data.routes) {
      this.routeIdsOptions = this.data.routes;
    }
    if (this.data.scenarioStartYear && this.data.scenarioEndYear) {
      this.yearsOptions = Array.from(
        { length: this.data.scenarioEndYear - this.data.scenarioStartYear + 1 },
        (_, year) => ({
          name: this.data.scenarioStartYear + year,
          value: this.data.scenarioStartYear + year,
        }),
      );
    }

    if (this.data.vehicleName) {
      this.vehiclesDispatchForm.controls.vehicleName.patchValue(
        this.data.vehicleName,
      );
    }
    if (this.data.vehicleId) {
      this.vehiclesDispatchForm.controls.vehicleId.patchValue(
        this.data.vehicleId,
      );
    }
    if (this.data.routeIds) {
      this.vehiclesDispatchForm.controls.routeIds.patchValue({
        generics: this.data.routeIds,
      });
    }
    if (this.data.startYear) {
      this.vehiclesDispatchForm.controls.startYear.patchValue(
        this.data.startYear,
      );
    }
    if (this.data.endYear) {
      this.vehiclesDispatchForm.controls.endYear.patchValue(this.data.endYear);
    }
    if (this.data.numberOfVehicles) {
      this.vehiclesDispatchForm.controls.numberOfVehicles.patchValue(
        this.data.numberOfVehicles,
      );
    }
    this.vehiclesDispatchForm.controls.v2g.patchValue(this.data.v2g);
    this.v2g = this.data.v2g;
  }

  setFormValidators(): void {
    this.vehiclesDispatchForm.controls.numberOfVehicles.setAsyncValidators(
      this.numberOfVehiclesValidator(),
    );

    this.vehiclesDispatchForm.controls.startYear.setAsyncValidators(
      this.startYearValidator(this.vehiclesDispatchForm.value.endYear),
    );
    this.vehiclesDispatchForm.controls.endYear.setAsyncValidators(
      this.endYearValidator(this.vehiclesDispatchForm.value.startYear),
    );

    combineLatest([
      this.vehiclesDispatchForm.get('startYear').valueChanges,
      this.vehiclesDispatchForm.get('endYear').valueChanges,
    ]).subscribe(([startYear, endYear]) => {
      this.vehiclesDispatchForm.controls.startYear.clearAsyncValidators();
      this.vehiclesDispatchForm.controls.endYear.clearAsyncValidators();
      if (startYear > endYear) {
        this.vehiclesDispatchForm.controls.startYear.setErrors({
          invalid: startYear,
        });
        this.vehiclesDispatchForm.controls.endYear.setErrors({
          invalid: endYear,
        });
      } else {
        this.vehiclesDispatchForm.controls.startYear.setErrors(null);
        this.vehiclesDispatchForm.controls.endYear.setErrors(null);
      }
    });
  }

  startYearValidator(endYear$: Observable<number>): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      if (control.value > endYear$) {
        return of({ invalid: control.value });
      }
      return of(null);
    };
  }

  endYearValidator(startYear$: Observable<number>): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      if (control.value < startYear$) {
        return of({ invalid: control.value });
      }
      return of(null);
    };
  }

  numberOfVehiclesValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      if (control.value >= 1) {
        return of(null);
      }
      return of({ invalid: control.value });
    };
  }

  getFormValues() {
    const vehiclesDispatchFormValue = this.vehiclesDispatchForm.getRawValue();
    return {
      ...vehiclesDispatchFormValue,
      routeIds: vehiclesDispatchFormValue['routeIds']['generics'],
      numberOfVehicles: parseInt(
        vehiclesDispatchFormValue.numberOfVehicles,
        10,
      ),
    };
  }

  onSaveAttempt(): void {
    this.submitted$.next(true);
    this.detector.detectChanges();
  }

  onClose() {
    this.dialogRef.close();
  }

  updateV2g(event): void {
    this.vehiclesDispatchForm.controls.v2g.patchValue(
      !this.vehiclesDispatchForm.value.v2g,
    );
    this.vehiclesDispatchForm.controls.v2g.markAsDirty();
    this.v2g = !this.data.v2g;
  }

  getExistingListWithoutCurrent(): VehiclesDispatch[] {
    return Coerce.toArray(this.data.existingList).filter(
      (dispatch) => dispatch.id !== this.getCurrentId(),
    );
  }

  private getCurrentId(): string {
    return Coerce.toObject(this.data.currentData).id;
  }

  private getFirstTechnologyOption(): string {
    return Coerce.toObject(this.data.vehicles[0]).value;
  }
}
