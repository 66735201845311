import { Pipe, PipeTransform } from '@angular/core';

export const OPTIMIZE_TOGGLE_HIDE_TEXT = 'Show All Solution';
export const OPTIMIZE_TOGGLE_SHOW_TEXT = 'Show Only Optimized Solution';

@Pipe({
  name: 'optimizeTextToggle',
})
export class OptimizeTextTogglePipe implements PipeTransform {
  transform(value: boolean): any {
    return value ? OPTIMIZE_TOGGLE_HIDE_TEXT : OPTIMIZE_TOGGLE_SHOW_TEXT;
  }
}
