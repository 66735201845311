import {
  EnergyVector,
  EnergyVectorOption,
  EnergyVectorReq,
  EnergyVectorTemporaryModel,
  EVPredefinedReferenceModel,
} from 'prosumer-app/+scenario/models';
import {
  PREDEFINED_ENERGY_VECTOR_MAP,
  PREDEFINED_ENERGY_VECTOR_MAP_LABEL,
  PREDEFINED_ENERGY_VECTORS,
} from 'prosumer-app/app.references';
import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { Utils } from 'prosumer-app/core/utils/utils';
import { Observable, of, pipe } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { generateShortUID } from './profile.utils';

/**
 * Get the selected energy vectors with handling for custom ones where the typeId will be used
 *
 * @param initialEnergyVector   the initial value of the energy vector
 * @param selectedEnergyVector$ the selected energy vector observable source
 */
export const getSelectedEnergyVector = (
  initialEnergyVector: string,
  selectedEnergyVector$: Observable<Array<EnergyVectorOption>>,
) =>
  pipe(
    startWith(Coerce.toString(initialEnergyVector, 'none')),
    switchMap((energyVector: any) => {
      // Checks if the selected energy vector exist in the predefined map to know if custom or not
      if (PREDEFINED_ENERGY_VECTOR_MAP[energyVector]) {
        return of(energyVector);
      }
      // If custom, use match the type with the predefined energy vectors
      return selectedEnergyVector$.pipe(
        take(1),
        map((energyVectors) => {
          const predefined = getPredefeinedVectorAsObject();
          const availableEVs = reduceVectorOptionsAsObject(energyVectors);
          const vectorObj = { ...availableEVs, ...predefined };
          const selectedEnergyVector = vectorObj[energyVector];
          return Coerce.toString(vectorObj[selectedEnergyVector], 'none');
        }),
      );
    }),
  );

const getPredefeinedVectorAsObject = (): Record<string, string> =>
  Object.entries(PREDEFINED_ENERGY_VECTOR_MAP).reduce(
    (prev, [key, value]) => ({ ...prev, [normalizeName(value)]: key }),
    {},
  );

const reduceVectorOptionsAsObject = (
  evOptions: EnergyVectorOption[],
): Record<string, string> =>
  evOptions.reduce(
    (prev, { type, name, value }) => ({
      ...prev,
      [value]: normalizeName(Coerce.toString(type, name)),
    }),
    {},
  );

const normalizeName = (value: string): string => `_${value.toUpperCase()}`;

// mapper to turn the custom id 'asre' into 'e0000' (example)
export const mapCustomEvIdToPredifinedId = (vector: EnergyVector) => {
  if (vector.custom) {
    Object.entries(PREDEFINED_ENERGY_VECTOR_MAP).map((entry) => {
      if (entry[1] === vector.type) {
        vector = { ...vector, value: entry[0] };
      }
    });
  }
  return vector;
};

// return only list of IDs from list of EVs
export const mapEVsToOnlyIds = (vectors: EnergyVector[]) =>
  Utils.resolveToEmptyArray(vectors).map((vector) => vector.value);

export const mapTempToEnergyVectorReq = (
  tempEV: EnergyVectorTemporaryModel,
): EnergyVectorReq => {
  return {
    name: tempEV.custom ? tempEV.name : tempEV.name.toLowerCase(),
    energyVectorId: tempEV.value,
    isCustom: tempEV.custom,
    vectorType: tempEV.type,
  };
};

export const mapEVNameToTemporaryEV = (
  evName: string,
  isUnsaved = true,
): EnergyVectorTemporaryModel => {
  const predefinedEV = _resolvePredefinedEV(evName);
  return {
    custom: _isEVCustom(predefinedEV),
    name: evName,
    type: _resolveEVType(predefinedEV),
    value: _determineEvId(predefinedEV),
    isUnsaved: isUnsaved,
  };
};

function _resolvePredefinedEV(
  evName: string,
): EVPredefinedReferenceModel | undefined {
  return PREDEFINED_ENERGY_VECTORS.find(
    (ev) => ev.value === evName.toLocaleLowerCase(),
  );
}

function _resolveEVType(ev: EVPredefinedReferenceModel): string {
  return ev?.value ?? PREDEFINED_ENERGY_VECTOR_MAP_LABEL.others;
}

function _isEVCustom(ev: EVPredefinedReferenceModel): boolean {
  return !ev;
}

function _determineEvId(ev: EVPredefinedReferenceModel): string {
  return ev?.id ?? generateShortUID();
}
