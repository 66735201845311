import { ScenarioVariation } from 'prosumer-app/+scenario';
import { VariationInfoService } from 'prosumer-app/+scenario/services';
import { Utils } from 'prosumer-app/core';
import {
  BaseFormComponent,
  ColumnDefinition,
  fadeInAnimation,
  FormFieldErrorMessageMap,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { NameValidator } from 'prosumer-app/shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  ScenarioVariationInfo,
  ScenarioVariationQuery,
} from 'prosumer-app/stores/scenario-variation';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  NgControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export const MAX_SCENARIO_VARIATION_LENGTH = 5;
const SPINNER_COLOR = 'accent';
const SPINNER_DIAMETER = 21;

@Component({
  selector: 'prosumer-scenario-variation-list',
  templateUrl: './scenario-variation-list.component.html',
  styleUrls: ['./scenario-variation-list.component.scss'],
  providers: [ManagedDataService],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioVariationListComponent
  extends BaseFormComponent
  implements AfterViewInit
{
  @Input() isViewOnly: boolean;
  waitingResponse$ = new BehaviorSubject<Record<string, boolean>>({});
  @Input() scenarioName: string;

  readonly onLoading$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(false);

  scenarioVariationsErrorMessages: FormFieldErrorMessageMap = {
    alreadyExists: this.translate.instant(
      'Scenario.messages.scenarioVariations.alreadyExists',
    ),
    maxLength: this.translate.instant(
      'Scenario.messages.scenarioVariations.maxLength',
    ),
    maxCount: this.translate.instant(
      'Scenario.messages.scenarioVariations.maxCount',
    ),
    invalidCharacter: this.translate.instant(
      'Scenario.messages.scenarioVariations.invalidCharacter',
    ),
    scenarioNameConflict: this.translate.instant(
      'Scenario.messages.scenarioVariations.scenarioNameConflict',
    ),
  };
  scenarioVariationsForm: UntypedFormGroup = this.formBuilder.group({
    name: [
      '',
      [
        this.variationNameConflictValidator(),
        this.maxLengthValidator(),
        NameValidator.validWithCore(),
      ],
    ],
  });

  scenarioVariationsList$: Observable<ScenarioVariationInfo[]>;
  variationsColumnDef: ColumnDefinition = {
    name: {
      name: 'Variation Name',
      sortable: true,
      flex: '80%',
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public managedData: ManagedDataService<ScenarioVariation>,
    private translate: TranslateService,
    private variationInfo: VariationInfoService,
    private notifications: NotificationsService,
    private readonly query: ScenarioVariationQuery,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.scenarioVariationsList$ = this.query.scenarioVariations$;
  }

  defineForm() {
    return {
      name: [],
    };
  }

  ngAfterViewInit(): void {
    this.scenarioVariationsList$.subscribe((data) => {
      this.managedData.setListData(Utils.resolveToEmptyArray(data));
      this.onChange(data);
    });
  }

  writeValue(value: ScenarioVariation[]) {
    if (!!value) {
      super.writeValue(value);
      this.managedData.setListData(value || []);
    }
  }

  onDeleteVariation(variant) {
    this.loading$.next(true);
    this.variationInfo.deleteVariation(variant).subscribe(
      () => {
        this.scenarioVariationsForm.controls.name.setErrors({ maxCount: null });
        this.scenarioVariationsForm.controls.name.updateValueAndValidity();
        this.variationInfo
          .getVariations()
          .subscribe(() => this.loading$.next(false));
      },
      (error: HttpErrorResponse) => {
        this.loading$.next(false);
        this.notifications.showError(error.message);
      },
    );
  }

  onAddVariation() {
    if (
      this.managedData.getListData().length >= MAX_SCENARIO_VARIATION_LENGTH
    ) {
      return;
    }
    if (this.scenarioVariationsForm.invalid) {
      return;
    }
    const variantName: string = this.scenarioVariationsForm.controls.name.value;
    this.loading$.next(true);

    if (variantName) {
      this.variationInfo.addVariation(variantName).subscribe(
        () => {
          this.scenarioVariationsForm.controls.name.setValue('');
          this.variationInfo
            .getVariations()
            .subscribe(() => this.loading$.next(false));
        },
        (error: HttpErrorResponse) => {
          this.loading$.next(false);
          this.notifications.showError(error.message);
        },
      );
    }
  }

  /**
   * Collects form error
   *
   * @param errorObj - form control error objects
   */
  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }

  variationNameConflictValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        !!this.scenarioVariationsForm &&
        !!(this.scenarioVariationsForm.controls.name.value || '').trim()
      ) {
        const name =
          this.scenarioVariationsForm.controls.name.value.toLowerCase();
        for (const variant of this.managedData.getListData()) {
          const variantName = variant.name.toLowerCase();
          if (variantName === name) {
            return { alreadyExists: { value: control.value } };
          }
        }
        if (name === this.scenarioName.toLowerCase()) {
          return { scenarioNameConflict: { value: control.value } };
        }
      }
      return null;
    };
  }

  maxLengthValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        !!this.scenarioVariationsForm &&
        !!this.scenarioVariationsForm.controls.name.value
      ) {
        return this.managedData.getListData().length >=
          MAX_SCENARIO_VARIATION_LENGTH
          ? {
              maxCount: { value: true },
            }
          : null;
      }
      return null;
    };
  }

  get spinnerColor(): string {
    return SPINNER_COLOR;
  }

  get spinnerDiameter(): number {
    return SPINNER_DIAMETER;
  }
}
