import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserAPIService } from './services';
import {
  UserEffects,
  UserFacadeService,
  userFeature,
  userReducer,
} from './state';

@NgModule({
  imports: [
    StoreModule.forFeature(userFeature, userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [UserAPIService, UserFacadeService],
})
export class UserModule {}
