import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Utils } from 'prosumer-app/core/utils/utils';
import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiService } from 'prosumer-app/libs/eyes-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RenewableProfile, UserRenewableProfile } from '../models';

@Injectable()
export class RenewableProfileApiService extends ApiService<RenewableProfile> {
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
  ) {
    super(_config.api.baseUrl);
  }

  create(data: RenewableProfile): Observable<RenewableProfile> {
    const request = this.mapDataToBackend(data);
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.renewableProfile.create),
        request,
      )
      .pipe(
        map((response) => this.mapSubmitDataToFrontend(request, response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  get(id: string, params?: Dictionary<string>): Observable<RenewableProfile> {
    return this._http
      .get<any>(
        this.endpoint(
          this._config.api.endpoints.renewableProfile.get,
          id,
          params,
        ),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getList(): Observable<Array<string>> {
    return this._http
      .get<
        Array<string>
      >(this.endpoint(this._config.api.endpoints.renewableProfile.list))
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  getUserRenewableProfileList(): Observable<Array<RenewableProfile>> {
    return this._http
      .get<
        Array<RenewableProfile>
      >(this.endpoint(this._config.api.endpoints.renewableProfile.list))
      .pipe(
        map((response) => response.map(this.mapDataToFrontend)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getRenewableProfile(
    data: UserRenewableProfile,
  ): Observable<RenewableProfile> {
    return this._http
      .get<RenewableProfile>(
        this.endpoint(
          this._config.api.endpoints.renewableProfile.get,
          data.recordId,
          {
            recordId: data.recordId,
            uniqueId: data.uniqueId,
          },
        ),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  // Custom services
  downloadRenewableProfile(data: RenewableProfile): Observable<any> {
    return this._http
      .get(
        this.endpoint(
          this._config.api.endpoints.renewableProfile.getSigned,
          data.uniqueId,
          {
            uniqueId: data.uniqueId,
          },
        ),
        {
          params: {
            status: Utils.resolveToEmptyObject(data.run).status,
          },
        },
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this._http
            .get(downloadSignedUrl.signed_url, { responseType: 'blob' })
            .pipe(
              map((response: any) => {
                const blob = new Blob([response], {
                  type: 'application/octet-stream',
                });
                const resUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = downloadSignedUrl.filename;
                anchor.href = resUrl;
                anchor.click();
                return response;
              }),
              catchError((error) => throwError(this.handleError(error))),
            ),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  // not implemented
  update(profile: RenewableProfile): Observable<RenewableProfile> {
    return undefined;
  }
  // not implemented
  delete(profile: RenewableProfile): Observable<any> {
    return undefined;
  }

  mapDataToBackend(data: RenewableProfile): any {
    return {
      ...data,
      computeType: data.computeType.toLowerCase(),
      trackingType: data.trackingType
        ? data.trackingType.toLowerCase().replace(/ /g, '-')
        : undefined,
      sites: [data.coordinates],
    };
  }

  mapDataToFrontend(response: any): RenewableProfile {
    const responseDetails = Utils.resolveToEmptyObject(response.details);
    const responsePercentiles = Utils.resolveToEmptyObject(
      responseDetails.percentiles,
    );
    const responseHistoricalYears = Utils.resolveToEmptyObject(
      responseDetails.historicalYears,
    );
    return {
      ...response,
      ...responseDetails,
      run: { status: response.status },
      coordinates: Utils.resolveToEmptyArray(responseDetails.sites)[0],
      percentiles: Utils.resolveToEmptyArray(responsePercentiles.values).join(
        ', ',
      ),
      historicalYears: Utils.resolveToEmptyArray(
        responseHistoricalYears.values,
      ).join(', '),
    };
  }

  mapSubmitDataToFrontend(request: any, response: any): RenewableProfile {
    return {
      ...request,
      ...response,
      uniqueId: response['batchId'],
      updatedAt: response['createdAt'],
      percentiles: request['percentiles'],
      historicalYears: request['historicalYears'],
      details: { ...request, ...response },
      run: { status: response['status'] },
    };
  }
}
