import moment from 'moment';
import { LoadFormDialogType } from 'prosumer-app/+scenario/components';
import { Library, Profile } from 'prosumer-app/+scenario/models';
import {
  BINARY_LOCATIONS,
  DEFAULT_DEBOUNCE_TIME,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_HOUR_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
} from 'prosumer-app/app.references';
import {
  BaseFormComponent,
  collectItemsWithKey,
  ColumnDefinition,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  generateShortUID,
  isKeyPressedNumber,
} from 'prosumer-app/libs/eyes-shared';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { LibraryFilter } from 'prosumer-shared/models';
import { DateFilterService } from 'prosumer-shared/modules/charts/services';
import { ProfileFormHelperService } from 'prosumer-shared/services/profile-form-helper';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

import { YearlyLoadsValidator } from '../../validators';

@Component({
  selector: 'prosumer-yearly-loads',
  templateUrl: './yearly-loads.component.html',
  styleUrls: ['./yearly-loads.component.scss'],
  providers: [DateFilterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyLoadsComponent
  extends BaseFormComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() index: number; // The index of the interval being modified for load profile patching purposes
  @Input() yearlyLoadEnabled = true; // Defines if the yearly load field should be shown
  @Input() useLibraryPanel = true; // Defines if the library table and library selection should be shown
  @Input() libraryError = false; // Defines if there is an error when using the library
  @Input() libraryLoading = false; // Defines if the library is loading
  @Input() librarySelectionDisabled = false; // Defines if the selection of library is disabled
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() allowNegativeInput = false;

  @Input() errorMessages: FormFieldErrorMessageMap;
  @Input() library: Array<Library> = [];

  library$ = new BehaviorSubject<Library[]>([]);
  // custom enhanced load validator
  loadCustomValidators;

  // Defines if the library type should be disabled and the value is always custom
  _alwaysCustom = false;
  @Input() set alwaysCustom(value: boolean) {
    this._alwaysCustom = value;
  }
  get alwaysCustom() {
    return this._alwaysCustom;
  }

  // Messages used throughout the yearly loads
  @Input() yearlyLoadMessage: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.loadType',
    loadTypeTooltip: 'wizard_loads.wizard_loards_load_type',
    libraryNoRecordMessage:
      'Scenario.messages.library.noLibraryForEnergyVector',
    libraryNoRecordMessage2: 'Scenario.messages.library.noEnergyVectorSelected',
    libraryRequiredMessage: 'Scenario.messages.der.library.required',
    yearlyLoadPlaceholder: 'Scenario.placeholders.yearlyLoad',
    yearlyLoadLabel: 'Scenario.labels.yearlyLoad',
    yearlyLoadTooltip: 'wizard_loads.wizard_loads_yearly_load',
    yearlyLoadRequired: 'Scenario.messages.yearlyLoad.required',
    yearlyLoadGreaterThanZero:
      'Scenario.messages.yearlyLoad.mustBeGreaterThanZero',
    loadsDataPlaceholder: 'Scenario.placeholders.loads',
    loadsDataLabelCustom: 'Scenario.labels.loads',
    loadsDataLabelLibrary: 'Scenario.labels.normalizedLoadProfile',
    loadsDataTooltip: 'wizard_loads.wizard_loards_load_profile',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };

  nodes$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  @Input() set nodeOptions(nodeOptions: FormFieldOption<string>[]) {
    this.nodes$.next(nodeOptions);
  }

  @Input() allowStringInput = false;

  /**
   * Selected library from the list of library profile.
   */
  @Output() selectedLibraryChange: EventEmitter<Library> =
    new EventEmitter<Library>();

  /**
   * Selected library filters. E.g {'vectorType': [], 'businessType': ['B2B', 'B@C'], 'buildingType': [],
   *     'buildingCategory': [], 'location': []})
   */
  @Output() libraryFiltersChange: EventEmitter<any> = new EventEmitter<any>();

  currentProfile: Profile; // The current value of the profile from the write value

  loadTypeOptions: Array<FormFieldOption<LoadFormDialogType>> = [
    { name: 'Library', value: 'library' },
    { name: 'Custom', value: 'custom' },
  ];

  selectedLibraryId: any; // The selected library id used to display the initial library to be selected in the UI

  sliderInitMinValue = 1;
  sliderInitMaxValue = 7;

  // The filter form containing the filters selected
  filterForm: UntypedFormGroup = this.formBuilder.group({
    vectorType: '',
    businessType: '',
    buildingType: '',
    buildingCategory: '',
    location: '',
  });

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    vectorType: {
      name: 'Type',
      flex: 'calc(30% - 50px)',
      sortable: true,
    },
    businessType: {
      name: 'B2B / B2C',
      flex: '15',
      sortable: true,
    },
    buildingType: {
      name: 'Building Type',
      flex: '20',
      sortable: true,
    },
    buildingCategory: {
      name: ' Building Category',
      flex: '15',
      sortable: true,
    },
    location: {
      name: 'Country / Region',
      flex: '20',
      sortable: true,
    },
  };

  loadProfile$ = new Subject<string>(); // The load profile used to emit values in the chart

  // The observable of the multiplied load profile
  multipliedLoads$ = combineLatest([
    this.loadProfile$.pipe(
      debounceTime(DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged(),
      map((profile) => this.parseLoadTo(profile)),
    ),
    this.controls.yearlyLoad.valueChanges.pipe(
      startWith(this.controls.yearlyLoad.value || 1),
      debounceTime(DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged(),
    ),
  ]).pipe(map(([data, yearlyLoad]) => this.multiplyLoads(data, yearlyLoad)));

  // The loads to be used in displaying the chart based on the multiplied loads and the date filter applied
  loads$ = combineLatest([
    this.multipliedLoads$,
    this.dateFilter.dateChange$,
  ]).pipe(this.dateFilter.filter$('Load Profile'), this.takeUntil());

  // Format of the chart's x-axis
  chartXAxisFormat = (value: any) =>
    moment(value).format(
      `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`,
    );

  // Format of the chart's tooltip title
  chartTooltipTitleFormat = (obj: any) =>
    moment((obj || {}).name).format(
      `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`,
    );

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.controls?.energyVector?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public dateFilter: DateFilterService,
    public profileFormHelperService: ProfileFormHelperService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.library &&
      changes.library?.currentValue &&
      changes.library.currentValue.length > 0
    ) {
      this.library$.next(changes.library.currentValue);
    }
  }

  /**
   * Defines the form structure
   */
  defineForm() {
    return {
      startYear: null,
      endYear: null,
      localId: generateShortUID(),
      location: BINARY_LOCATIONS.END_USE_LOADS,
      loadProfile: null,
      forSaving: this.mode === 'create' || this.mode === 'add',
      loadType: 'library',
      library: null,
      yearlyLoad: 1,
      loadError: false,
      nodes: [],
    };
  }

  /**
   * Overriden control value accessor's write value that is executed when the parent form initializes the control or
   * update the control's value
   *
   * @param profile - the profile to set as initial value of the control
   */
  writeValue(profile: Profile): void {
    if (!!profile) {
      this.currentProfile = profile;
    }
  }

  /**
   * Overriden to avoid parent disabling effects
   *
   * @param disabled - true if disabled
   */
  setDisabledState(disabled: boolean) {}

  ngOnInit() {
    // consider if string input is allowed
    this.applyValidators();
  }

  ngAfterViewInit(): void {
    this.initFormChangeHandler();
    this.initLoadTypeChangeHandler();
    this.initFilterChangeHandler();
    this.initForSavingHandler();
    this.initSubmitHandler();
    this.initLoadProfileHandler();
    this.initLibrarySelectionHandler();
    this.initForm(this.currentProfile);
    this.markAsPristine();
  }

  /**
   * Sets appropriate validators for when string input is allowed
   */
  applyValidators(): void {
    if (this.allowStringInput) {
      const nodes = this.nodeOptions
        ? collectItemsWithKey(this.nodeOptions, 'value')
        : [];
      this.loadCustomValidators = [CustomValidators.isYearlyLoadValid(nodes)];
    } else if (this.allowNegativeInput) {
      this.loadCustomValidators = [
        CustomValidators.numberValidator,
        YearlyLoadsValidator.commaExistance(),
      ];
    } else {
      this.loadCustomValidators = [
        CustomValidators.mustBePositiveNumber,
        CustomValidators.numberValidator,
        YearlyLoadsValidator.commaExistance(),
      ];
    }
    this.controls.yearlyLoad.setValidators((control) => {
      const errorMessage = {
        mustBeGreaterThanZero: {
          valid: false,
        },
      };
      if (parseFloat(control.value) > 0) {
        return null;
      }
      return errorMessage;
    });
  }

  /**
   * Initializes the form using the profile parameter
   *
   * @param profile - the profile object where the form's value will be based
   */
  initForm(profile: Profile): void {
    if (!!profile) {
      this.controls.startYear.patchValue(profile.startYear, {
        emitEvent: false,
      });
      this.controls.endYear.patchValue(profile.endYear, { emitEvent: false });
      this.controls.localId.patchValue(profile.localId, { emitEvent: false });
      this.controls.location.patchValue(profile.location, { emitEvent: false });
      this.controls.forSaving.patchValue(profile.forSaving, {
        emitEvent: false,
      });
      this.controls.loadError.patchValue(profile.loadError, {
        emitEvent: false,
      });
      this.handleAlwaysCustom(profile.loadType);
      this.handleLoadType(profile.loadType, profile.library);
      this.controls.yearlyLoad.patchValue(profile.yearlyLoad, {
        emitEvent: false,
      });
      const parsedLoadProfile = this.parseLoadFrom(profile.loadProfile);
      const forSaving = profile.forSaving || false;
      this.controls.loadProfile.patchValue(parsedLoadProfile, {
        emitEvent: forSaving,
      });
      // this.controls.loadProfile.patchValue(parsedLoadProfile, { emitEvent: true });
      if (!forSaving) {
        this.loadProfile$.next(parsedLoadProfile);
      }
    }
  }

  /**
   * Initialize the handler when the form changes
   * This will propagate the changes in the form as a control to the parent form
   */
  initFormChangeHandler() {
    this.form.valueChanges.pipe(this.takeUntil()).subscribe(() => {
      const yearlyLoad = {
        ...this.form.getRawValue(),
        loadProfile: this.parseLoadTo(this.controls.loadProfile.value),
      };

      // Propagate the change in the parent form where the control is used
      this.onChange(yearlyLoad);
    });
  }

  /**
   * Initialize the handler when 'For Saving' field changes
   * This will set the load profile behavior subject to trigger an emission and patches the 'For Saving' field to true
   */
  initForSavingHandler() {
    this.controls.loadProfile.valueChanges
      .pipe(this.takeUntil())
      .subscribe((value) => {
        this.loadProfile$.next(value);
        this.controls.forSaving.patchValue(true, {
          emitEvent: false,
          onlySelf: true,
        });
      });
  }

  /**
   * Initialize the handler when load type field changes
   * This will handle the load type as described in the handleLoadType() function
   */
  initLoadTypeChangeHandler() {
    this.controls.loadType.valueChanges
      .pipe(this.takeUntil())
      .subscribe((value) => this.handleLoadType(value));
  }

  /**
   * Initialize the handler when the library filters change
   * This will emit the filters selected via the libraryFiltersChange output
   */
  initFilterChangeHandler() {
    combineLatest([
      this.filterForm.controls.vectorType.valueChanges.pipe(startWith([])),
      this.filterForm.controls.businessType.valueChanges.pipe(startWith([])),
      this.filterForm.controls.buildingType.valueChanges.pipe(startWith([])),
      this.filterForm.controls.buildingCategory.valueChanges.pipe(
        startWith([]),
      ),
      this.filterForm.controls.location.valueChanges.pipe(startWith([])),
    ])
      .pipe(
        switchMap(
          ([
            vectorType,
            businessType,
            buildingType,
            buildingCategory,
            location,
          ]) =>
            of({
              vectorType,
              businessType,
              buildingType,
              buildingCategory,
              location,
            } as LibraryFilter),
        ),
        this.takeUntil(),
      )
      .subscribe((data) => {
        this.libraryFiltersChange.emit(data);
      });
  }

  /**
   * Initialize the handler when the the profile form helper's submitted observable emit changes
   * This will trigger the hidden button's click event to trigger the validations
   */
  initSubmitHandler() {
    this.profileFormHelperService.submitted$
      .pipe(this.takeUntil())
      .subscribe((sub) => {
        const element: HTMLElement = document.getElementById(
          'btn',
        ) as HTMLElement;
        element.click();
      });
  }

  /**
   * Initialize the handler when the the profile form helper's profile observable emit changes
   * This will patch the bin data to the load profile field with checking if binary data is emitted via library select
   * (lib) or from s3 (bin)
   */
  initLoadProfileHandler() {
    this.profileFormHelperService.profile$
      .pipe(this.takeUntil())
      .subscribe((value) => {
        if (
          value.index === this.index &&
          !!value.profiles &&
          value.profiles.length > 0
        ) {
          const parsedLoadProfile = this.parseLoadFrom(value.profiles);
          const isBin = value.type === 'bin';
          this.controls.loadProfile.patchValue(parsedLoadProfile, {
            emitEvent: !isBin,
          });
          if (isBin) {
            this.loadProfile$.next(parsedLoadProfile);
          }
        }
      });
  }

  /**
   * Initialize the handler when the library selection changes in the library table
   * This will set the selected library id variable
   */
  initLibrarySelectionHandler() {
    this.controls.library.valueChanges
      .pipe(this.takeUntil())
      .subscribe((value) =>
        !!value
          ? (this.selectedLibraryId = value)
          : (this.selectedLibraryId = null),
      );
  }

  /**
   * Handles always custom to patch load type and disable load type
   *
   * @param value - the load type value
   */
  handleAlwaysCustom(value: 'library' | 'custom') {
    if (this.alwaysCustom) {
      this.controls.loadType.patchValue('custom', { emitEvent: false });
      this.controls.loadType.disable({ emitEvent: false });
    } else {
      this.controls.loadType.patchValue(value, { emitEvent: false });
      this.controls.loadType.enable({ emitEvent: false });
    }
  }

  /**
   * Apply necessary load type handling where a required validator should be in place if the type is 'library'; else
   * there should be no validators and the library field should be null
   *
   * @param loadType  - the load type if library or custom
   * @param library   - the value of the library
   */
  handleLoadType(loadType: 'library' | 'custom', library?: any) {
    this.selectedLibraryId = library;
    if (loadType === 'library') {
      this.controls.library.setValidators([Validators.required]);
      this.controls.library.patchValue(library, { emitEvent: false });
    } else {
      this.controls.library.clearValidators();
      this.controls.library.patchValue(null, { emitEvent: false });
    }
  }

  /**
   * Updates the filter control as it changes
   *
   * @param data - the data of the filter
   * @param formControl - the control representing the filter
   */
  updateFilter(data: any, formControl: UntypedFormControl) {
    formControl.patchValue(data);
  }

  /**
   * Multiplies each load in the load profile array by the yearly load multiplier
   * This will return a list of updated load profile that were multiplied
   *
   * @param loads - the load profile array of string
   * @param yearlyLoad - the yearly load as multipilier
   */
  multiplyLoads(loads: Array<string>, yearlyLoad: number) {
    if (this.controls.yearlyLoad && this.controls.yearlyLoad.value) {
      yearlyLoad = this.controls.yearlyLoad.value;
    }
    if (!!loads) {
      return loads.map((data) => String(Number(data) * yearlyLoad));
    }
    return [];
  }

  /**
   * Parses the array of loads to a carriage-return (enter) delimited loads
   *
   * @param listData - list of loads as string
   */
  parseLoadFrom(listData: Array<string>): string {
    let loadValue = '';
    if (!!listData && listData instanceof Array && listData.length > 0) {
      listData.forEach((data) => (loadValue += `${data}\n`));
      return loadValue;
    }
    return loadValue;
  }

  /**
   * Parses the string of carriage-return (enter) delimeted loads to an array of loads
   *
   * @param loadValue - the string of carriage-return (enter) delimeted loads
   */
  parseLoadTo(loadValue: string): Array<string> {
    if (!!loadValue && typeof loadValue === 'string') {
      return loadValue.split('\n').filter((data) => data && data.trim() !== '');
    }
    return [];
  }

  /**
   * Updates the date filter used in the stacked-area chart where it will filter the min date of the chart shown
   *
   * @param value - the minimum date range
   */
  onMinChange(value: number) {
    this.dateFilter.minDate$.next(value);
  }

  /**
   * Updates the date filter used in the stacked-area chart where it will filter the max date of the chart shown
   *
   * @param value - the maximum date range
   */
  onMaxChange(value: number) {
    this.dateFilter.maxDate$.next(value);
  }

  /**
   * Updates the library field when selecting a library from the table and emit the selected library via
   * selectedLibraryChange output
   *
   * @param libraryId - the library id selected
   */
  onSelect(libraryId: string) {
    if (!!this.library && this.library.length > 0 && !this.loading) {
      this.controls.library.patchValue(libraryId);
      this.controls.library.markAsDirty();
      this.selectedLibraryChange.emit(
        this.library.find(({ id }) => id === libraryId),
      );
    }
  }

  /**
   * Handles key press events for the load profile field to prevent non-number values
   *
   * @param event - the keyboard event
   */
  onKeyPress(event: KeyboardEvent) {
    if (!isKeyPressedNumber(event)) {
      event.preventDefault();
    }
  }

  /**
   * Changes from the enhanced load input.
   *
   * @param data new data.
   */
  onLoadChanges(data: Array<string>) {
    this.controls.loadError.patchValue(false);
    this.controls.loadProfile.patchValue(this.parseLoadFrom(data));
    this.loadProfile$.next(this.parseLoadFrom(data));
  }

  onLoadErrors(data: Array<string>) {
    this.controls.loadError.patchValue(true);
    /* Even if value is invalid, still need to patch it
     * because onLoadChanges() will not be triggered once the user
     * reverts the value back to default because the load
     * will not be considered "changed" hence the loadError
     * will not be set back to false.
     */
    this.controls.loadProfile.patchValue(this.parseLoadFrom(data));
    this.loadProfile$.next(this.parseLoadFrom(data));
  }

  onEmittedNodes(data: string[]) {
    if (data) {
      this.controls.nodes.patchValue(data);
    }
  }
}
