import { Action } from '@ngrx/store';

import { AllActions } from 'prosumer-app/libs/eyes-shared';

import { User } from '../models';

export enum ActionTypes {
  GET_BY_EMAIL = '[User API] Get User By Email Address',
  GET_BY_EMAIL_SUCCESS = '[User API] Get User By Email Address - Success',
  GET_BY_EMAIL_FAILURE = '[User API] Get User By Email Address - Failure',
}

export class GetByEmail implements Action {
  readonly type = ActionTypes.GET_BY_EMAIL;
  constructor(public payload?: { email: string }) {}
}

export class GetByEmailSuccess implements Action {
  readonly type = ActionTypes.GET_BY_EMAIL_SUCCESS;
  constructor(public payload?: User) {}
}

export class GetByEmailFailure implements Action {
  readonly type = ActionTypes.GET_BY_EMAIL_FAILURE;
  constructor(public payload?: { faultyEmail: string; message: string }) {}
}

export type All =
  | AllActions<User>
  | GetByEmail
  | GetByEmailSuccess
  | GetByEmailFailure;
