import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-shared';
import { CoordinatesMapModule } from '../coordinates-map-form';
import { ComputationOptionModule } from '../profile-computation-option';
import { SolarTabComponent } from './solar-tab.component';

@NgModule({
  declarations: [SolarTabComponent],
  exports: [SolarTabComponent],
  imports: [
    EyesSharedModule.forFeature(),
    CommonModule,
    CoordinatesMapModule,
    ComputationOptionModule,
    SharedModule,
    MarkdownModule.forChild(),
  ],
})
export class SolarTabModule {}
