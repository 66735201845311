import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { RUN_STATUS_ICON_MAP } from 'prosumer-app/app.references';
import { ProsumerRoutePathService, Utils } from 'prosumer-app/core';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { Scenario, ScenarioVariationMap } from 'prosumer-scenario';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck, take, tap, withLatestFrom } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TranslateService } from '@ngx-translate/core';
import { Project } from 'prosumer-app/+project';
import { ProjectFacadeService } from 'prosumer-app/+project/state';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { PermissionCheckerService } from 'prosumer-app/shared';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { StatusIconThing } from './scenario-details.types';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-details',
  templateUrl: './scenario-details.component.html',
  styleUrls: ['./scenario-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProsumerRoutePathService],
})
export class ScenarioDetailsComponent implements OnInit {
  private selectedScenario: Scenario | undefined;
  isViewOnly$: Observable<boolean>;

  readonly scenario$: Observable<Scenario> = this.selectScenario();
  readonly loading$: Observable<boolean> = this.selectScenarioLoading();
  readonly statusIconThing$ = this.selectStatusIconThing();
  readonly project$: Observable<Project> = this.selectProject();
  scenarioDetails$ = new BehaviorSubject<Scenario>({});
  mergedScenarioAndVariationDetails$ = new BehaviorSubject<Scenario>({});
  variationDetails$ = new BehaviorSubject<ScenarioVariationMap>({});
  status$ = new BehaviorSubject<string>('');
  scenarioInfo$: Observable<Scenario> = this.subscribeToScenarioInfo();

  readonly scenarioOwner$ = this.userFacade.dataMap$.pipe(
    map((user) => user[this.getScenario().owner]),
    untilDestroyed(this),
  );

  readonly currentUser$ = this.userFacade.clientUser$.pipe(
    untilDestroyed(this),
  );

  constructor(
    private scenarioFacade: ScenarioFacadeService,
    private userFacade: UserFacadeService,
    public translate: TranslateService,
    private projectFacade: ProjectFacadeService,
    private permissionService: PermissionCheckerService,
    private keeper: ActiveKeeperService,
    private scenarioQuery: ScenarioGenericQuery,
  ) {}

  ngOnInit(): void {
    this.subscribeToSelectedScenarioForLocalStoring();
    this.getUserPermission();
    this.subscribeToSelectedScenarioVariation();
    this.subscribeToSetEditPermission();
  }

  subscribeToScenarioInfo(): Observable<Scenario> {
    return this.scenarioQuery
      .selectActiveScenarioMapped()
      .pipe(filter(Boolean));
  }

  private getUserPermission() {
    this.isViewOnly$ = this.selectProject().pipe(
      take(1),
      withLatestFrom(this.currentUser$),
      map(
        ([project, user]) =>
          !this.permissionService.isUserPermitted(project, user)['CAN_UPDATE'],
      ),
    );
  }

  private subscribeToSetEditPermission(): void {
    this.isViewOnly$
      .pipe(
        tap((permission) =>
          this.keeper.setActive({ hasEditPermission: !permission }),
        ),
      )
      .subscribe();
  }

  private subscribeToSelectedScenarioForLocalStoring(): void {
    this.selectScenario()
      .pipe(untilDestroyed(this))
      .subscribe((scenario) => {
        this.selectedScenario = scenario;
        this.setScenarioDetails(scenario);
      });
  }

  private selectScenarioLoading(): Observable<boolean> {
    return this.selectScenario().pipe(pluck('loading'));
  }

  private selectStatusIconThing(): Observable<StatusIconThing> {
    return this.selectScenario().pipe(
      pluck('status'),
      map((status) => RUN_STATUS_ICON_MAP[status] as StatusIconThing),
      filter((statusIconThing) => !!statusIconThing),
    );
  }

  private selectScenario(): Observable<Scenario> {
    return this.scenarioFacade.selectedData$.pipe(filter(Boolean));
  }

  private getScenario(): Scenario {
    return this.selectedScenario;
  }

  private selectProject(): Observable<Project> {
    return this.projectFacade.selectedData$.pipe(filter(Boolean));
  }

  private selectedScenarioVariation() {
    return this.scenarioFacade.selectedScenarioVariationId$.pipe(
      filter(Boolean),
    );
  }

  private subscribeToSelectedScenarioVariation(): void {
    this.selectedScenarioVariation()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((id) => {
        const selectedVariation = this.getScenario().scenarioVariations.filter(
          (variation) => variation.variationId === id,
        )[0];
        this.variationDetails$.next(selectedVariation);
        this.setScenarioDetails(this.getScenario(), selectedVariation);
      });
  }

  setScenarioDetails(scenario?: Scenario, variation?: ScenarioVariationMap) {
    this.scenarioDetails$.next(scenario || this.scenarioDetails$.value);
    this.variationDetails$.next(variation || this.variationDetails$.value);
    this.mergedScenarioAndVariationDetails$.next(
      this.mergeVariationAndScenarioDetails(),
    );
    this.status$.next(this.mergedScenarioAndVariationDetails$.value.status);
  }

  private mergeVariationAndScenarioDetails() {
    const { name, status } = this.getScenario();
    const { name: variationName, run: variationRun } =
      this.variationDetails$.value;
    return {
      ...this.getScenario(),
      status: Utils.resolveToEmptyObject(variationRun).status || status,
      name: variationName || name,
    };
  }
}
