import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

export const DEFAULT_DATE = 'dd MMM yyyy';
export const DMY_SLASH_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_SLASHED_FORMAT = 'yyyy/MM/dd HH:mm';
export const DEFAULT_WITH_TIME = 'dd MMM yyyy HH:mm';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DEFAULT_DATE);
  }
}

@Pipe({
  name: 'dmySlash',
})
export class DMYSlashPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DMY_SLASH_FORMAT);
  }
}

@Pipe({
  name: 'dateTimeSlashed',
})
export class DateTimeSlashedPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DATE_TIME_SLASHED_FORMAT);
  }
}

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DEFAULT_WITH_TIME);
  }
}
