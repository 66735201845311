import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthModule } from './auth/index';
import { NotificationStoreModule } from './notification/index';
import { PageModule } from './page/index';
import { RouterModule } from './router/index';
import { effects } from './stores.reducer';
import { UserModule } from './user/index';
import { OldUserModule } from './user_old/index';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot(effects),
    AuthModule.forRoot(),
    RouterModule.forRoot(),
    PageModule.forRoot(),
    NotificationStoreModule.forRoot(),
    UserModule,
    OldUserModule.forRoot(),
  ],
})
export class StoresModule {
  constructor(@Optional() @SkipSelf() parentModule?: StoresModule) {
    if (parentModule) {
      throw new Error(
        'StoresModule is already loaded. Import it in the AppModule or CoreModule only',
      );
    }
  }

  static forRoot(): ModuleWithProviders<StoresModule> {
    return {
      ngModule: StoresModule,
    };
  }
}
