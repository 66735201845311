import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';

import { DetailsOverlayComponent } from './details-overlay.component';
import { DetailsOverlayService } from './details-overlay.service';

@NgModule({
  imports: [CommonModule, OverlayModule, MatButtonModule],
  exports: [DetailsOverlayComponent, MatButtonModule],
  declarations: [DetailsOverlayComponent],
  providers: [DetailsOverlayService],
})
export class DetailsOverlayModule {}
