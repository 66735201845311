import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Response } from 'prosumer-app/libs/eyes-shared';

import { HttpService, LoggerService } from '../../services/index';
import { RouterStore } from '../router/index';
import { AuthStore } from './auth-store.service';
import {
  AuthActionTypes,
  AuthActions,
  AuthLoginFailureAction,
  AuthLoginSuccessAction,
  AuthSetAsLoggedInAction,
  AuthSetAsLoggedInSuccessAction,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  login$: Observable<AuthActions> = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      mergeMap((action) =>
        this._http.mock('/assets/mocks/login-success.mock.json').pipe(
          map((response: Response) => {
            if (response && response.status) {
              this._logger.info(response.message);
              return new AuthLoginSuccessAction(response.data);
            }
            return new AuthLoginFailureAction(response.error);
          }),
          catchError((response: HttpErrorResponse) =>
            of(
              new AuthLoginFailureAction({
                code: 'ERR001',
                message: 'Sample error only',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  setAsLoggedIn$: Observable<AuthActions> = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActionTypes.SET_AS_LOGGED_IN),
      mergeMap((action: AuthSetAsLoggedInAction) =>
        of(new AuthSetAsLoggedInSuccessAction(action.payload)),
      ),
    ),
  );

  loginSuccess$: Observable<AuthActions> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        withLatestFrom(this._authStore.redirectUrl$),
        tap(([action, redirectUrl]) => {
          this._routerStore.go([redirectUrl]);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _http: HttpService,
    private _logger: LoggerService,
    private _routerStore: RouterStore,
    private _authStore: AuthStore,
  ) {}
}
