/* eslint-disable @typescript-eslint/naming-convention */
import { BaseEntity } from 'prosumer-app/libs/eyes-shared';

export enum TrackingType {
  FIXED = 'Fixed',
  AZIMUTH_TRACKING = 'Azimuth Tracking',
  TILTED_NS_TRACKING = 'Tilted north-south tracking',
  FULL_TRACKING = 'Full Tracking',
}

export enum ProfileComputationType {
  HISTORICAL = 'Historical',
  TMY = 'TMY',
  PERCENTILE = 'Percentile',
}

export interface RenewableProfileComputation {
  type: ProfileComputationType;
  numberOfYears: number;
  lastHistoricalYear: number;
  historicalYears: string;
  percentiles: string;
}

export interface Coordinates {
  readonly longitude: string;
  readonly latitude: string;
}

export interface RenewableProfile extends BaseEntity {
  readonly id?: string;
  readonly uniqueId?: string;
  readonly category?: string;

  readonly parameterType: string;
  readonly parameterUnit: string;

  readonly coordinates?: Coordinates;
  readonly sites?: Coordinates[];

  readonly run?: {
    duration?: number;
    status?: string;
  };

  readonly computeType: string;
  readonly numberOfYears?: string;
  readonly lastHistoricalYear?: string;
  readonly historicalYears?: string;
  readonly percentiles?: string;

  // Wind specific
  readonly turbineName?: string;
  readonly hubHeight?: string;

  // Solar specific
  readonly panelTilt?: string;
  readonly trackingType?: string;
  readonly panelOrientation?: string;

  // Others
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface UserRenewableProfile {
  recordId: string;
  uniqueId: string;
  createdAt: string;
  parameterType: string;
}
