import {
  ProfileComputationType,
  RenewableProfileComputation,
} from 'prosumer-app/+renewableprofile/models';
import { GISCoordinatesService } from 'prosumer-app/+renewableprofile/services/gis-coordinates.service';
import {
  TurbineData,
  TurbineListHolderService,
} from 'prosumer-app/+renewableprofile/services/turbine-list-holder.service';
import { RenewableProfileFacadeService } from 'prosumer-app/+renewableprofile/state';
import { createRenewableProfileSubmitData } from 'prosumer-app/+renewableprofile/utils/helpers';
import {
  BaseComponent,
  fadeInAnimation,
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-wind-tab',
  templateUrl: './wind-tab.component.html',
  styleUrls: ['./wind-tab.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindTabComponent extends BaseComponent implements OnInit {
  location$: Observable<{ longitude: number; latitude: number }>;
  constructor(
    public coordinates: GISCoordinatesService,
    public formBuilder: UntypedFormBuilder,
    public turbineList: TurbineListHolderService,
    private translate: TranslateService,
    public renewableProfileFacade: RenewableProfileFacadeService,
  ) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TURBINES_LIST_URL =
    'https://www.meteomatics.com/en/api/available-parameters/power-and-energy/available-turbines/';
  clickedSubmitted$ = new BehaviorSubject<boolean>(false);

  windForm: UntypedFormGroup;
  masterTurbineOptions: Array<TurbineData> = [];
  turbineOptions$ = new BehaviorSubject<Array<FormFieldOption<any>>>([]);
  customErrorMessage$ = new BehaviorSubject<string>('');
  errorMessages: FormFieldErrorMessageMap = {
    turbineName: {
      required: this.translate.instant(
        'RenewableProfile.messages.turbine.required',
      ),
    },
    hubHeight: {
      required: this.translate.instant(
        'RenewableProfile.messages.height.required',
      ),
      invalid: this.translate.instant(
        'RenewableProfile.messages.height.invalid',
      ),
      min: this.translate.instant('RenewableProfile.messages.height.min'),
      max: this.translate.instant('RenewableProfile.messages.height.max'),
    },
  };

  ngOnInit(): void {
    this.windForm = this.formBuilder.group(this.defaultForm());

    this.location$ = combineLatest([
      this.coordinates.longitude$,
      this.coordinates.latitude$,
    ]).pipe(
      filter(([longitude, latitude]) => !!longitude || !!latitude),
      map(
        ([longitude, latitude]) => ({
          longitude: Number(longitude),
          latitude: Number(latitude),
        }),
        this.takeUntilShare(),
      ),
    );

    this.location$.subscribe(({ longitude, latitude }) => {
      this.windForm.controls.longitude.patchValue(longitude);
      this.windForm.controls.latitude.patchValue(latitude);
    });

    this.windForm.valueChanges.subscribe((v) => {
      this.clickedSubmitted$.next(false);
      this.windForm.markAsDirty();
    });

    const list = this.turbineList.getTurbineList();
    if (list.length > 0) {
      this.windForm.controls.turbineName.patchValue(list[0].name);
      this.windForm.controls.hubHeight.setValue(Number(list[0].hubHeight));
    }
    this.masterTurbineOptions = list;
    this.turbineOptions$.next(list);
    this.windForm.controls.turbineName.valueChanges.subscribe((data) => {
      if (this.windForm.controls.turbineName.errors) {
        this.customErrorMessage$.next(
          this.errorMessages.turbineName['required'],
        );
      } else {
        this.customErrorMessage$.next('');
        const turbineData = this.masterTurbineOptions.find(
          (option) => option.value === data,
        );
        this.windForm.controls.hubHeight.setValue(
          turbineData ? Number(turbineData.hubHeight) : 50,
        );
      }
      this.turbineOptions$.next(this.updateFilter(data));
    });
    this.windForm.markAsPristine();
  }

  computationHandler(computation: RenewableProfileComputation) {
    if (computation.type === ProfileComputationType.HISTORICAL) {
      this.windForm.controls.historicalYears.setValidators([
        Validators.required,
      ]);
      this.windForm.controls.percentiles.clearValidators();
      this.windForm.controls.numberOfYears.clearValidators();
      this.windForm.controls.lastHistoricalYear.clearValidators();
    }
    if (computation.type === ProfileComputationType.PERCENTILE) {
      this.windForm.controls.historicalYears.clearValidators();
      this.windForm.controls.percentiles.setValidators([Validators.required]);
      this.windForm.controls.numberOfYears.clearValidators();
      this.windForm.controls.lastHistoricalYear.clearValidators();
    }
    if (computation.type === ProfileComputationType.TMY) {
      this.windForm.controls.historicalYears.clearValidators();
      this.windForm.controls.percentiles.clearValidators();
      this.windForm.controls.numberOfYears.setValidators([Validators.required]);
      this.windForm.controls.lastHistoricalYear.setValidators([
        Validators.required,
      ]);
    }
    this.windForm.controls.computeType.patchValue(computation.type);
    this.windForm.controls.numberOfYears.patchValue(computation.numberOfYears);
    this.windForm.controls.lastHistoricalYear.patchValue(
      computation.lastHistoricalYear,
    );
    this.windForm.controls.historicalYears.setValue(
      !!!computation.historicalYears ? undefined : computation.historicalYears,
    );
    this.windForm.controls.percentiles.setValue(
      !!!computation.percentiles ? undefined : computation.percentiles,
    );
  }

  onSubmit() {
    this.clickedSubmitted$.next(true);
    if (this.windForm.valid) {
      const submitData = createRenewableProfileSubmitData(
        this.windForm,
        'wind',
        'MW',
      );
      this.renewableProfileFacade.createRenewableProfile(submitData);
      this.windForm.markAsPristine();
    }
  }

  updateFilter(value: string): any[] {
    const filterValue = value.toLowerCase();
    const output = this.masterTurbineOptions.filter((data) =>
      data.name.toLowerCase().includes(filterValue),
    );
    return output;
  }

  private defaultForm() {
    return {
      turbineName: [undefined, Validators.required],
      hubHeight: [
        50,
        [Validators.min(10), Validators.max(200), Validators.required],
      ],
      computeType: [undefined, Validators.required],
      numberOfYears: undefined,
      lastHistoricalYear: undefined,
      historicalYears: [undefined, Validators.required],
      percentiles: undefined,
      longitude: [undefined, Validators.required],
      latitude: [undefined, Validators.required],
    };
  }
}
