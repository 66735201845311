import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ShareLinkModule } from '../share-link';
import { ProjectShareComponent } from './project-share.component';

@NgModule({
  declarations: [ProjectShareComponent],
  imports: [CommonModule, ShareLinkModule, LetDirective, PushPipe],
  exports: [ProjectShareComponent],
})
export class ProjectShareModule {}
