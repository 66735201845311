/**
 * Returns a random value based on the min and max values
 *
 * @param min - the start range of random value to be returned
 * @param max - the end range of random value to be returned
 */
export const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

/**
 * Returns an exponential computation for retry attempts.
 * This helper also uses min max random number to add to the total number returned.
 *
 * @param attempts - the retry attempt
 * @param scaling - the multiplier
 * @param min - the start range of random value to be returned
 * @param max - the end range of random value to be returned
 */
export const exponentialBackoff = (
  attempts: number,
  scaling: number,
  min: number = 1,
  max: number = 1000,
): number =>
  Math.round(Math.pow(2, attempts)) * scaling + randomIntFromInterval(min, max);
