import { RenewableProfileFacadeService } from 'prosumer-app/+renewableprofile/state';
import {
  transformComputationData,
  transformCoordinates,
} from 'prosumer-app/+renewableprofile/utils/helpers';
import { RENEWABLE_PROFILE_STATUS_MAP } from 'prosumer-app/app.references';
import {
  BaseTableComponent,
  ColumnDefinition,
  rowAnimation,
  sortByField,
  StatusMap,
} from 'prosumer-app/libs/eyes-shared';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from 'prosumer-app/core/utils/utils';
import { RenewableProfile } from '../../models';

@Component({
  selector: 'prosumer-latest-wind-profiles',
  templateUrl: './latest-wind-profiles.component.html',
  styleUrls: ['./latest-wind-profiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rowAnimation],
})
export class LatestWindProfilesComponent
  extends BaseTableComponent<RenewableProfile>
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly TABLE_COLUMNS = [
    'turbineName',
    'hubHeight',
    'coordinates',
    'computeType',
    'status',
    'output',
  ];
  statusMap: StatusMap = RENEWABLE_PROFILE_STATUS_MAP;
  error$ = this.renewableProfileFacade.error$.pipe(this.takeUntilShare());

  loadingList$ = this.renewableProfileFacade.loadingProfileList$.pipe(
    this.takeUntilShare(),
  );

  inProgress$ = combineLatest([
    this.renewableProfileFacade.loadingProfileList$,
    this.renewableProfileFacade.submitting$,
    this.renewableProfileFacade.downloading$,
  ]).pipe(map((criterias) => criterias.some(Boolean)));

  renewableProfiles$ = this.renewableProfileFacade.renewableProfiles$.pipe(
    this.takeUntilShare(),
  );

  renewableProfilesDataSource$ = this.renewableProfiles$.pipe(
    map((renewableProfiles) => this.mapProfiles(renewableProfiles)),
    this.takeUntilShare(),
  );

  renewableProfileDataLength$ = this.renewableProfilesDataSource$.pipe(
    map((dataSource) => dataSource.data.length),
    this.takeUntilShare(),
  );

  constructor(public renewableProfileFacade: RenewableProfileFacadeService) {
    super(null, null);
  }

  ngOnInit() {
    this.renewableProfiles$
      .pipe(
        take(1),
        filter((list) => list.length === 0),
      )
      .subscribe(() =>
        this.renewableProfileFacade.getUserRenewableProfileList(),
      );
  }

  defineColumns(): ColumnDefinition {
    return {};
  }

  onDownload(data: RenewableProfile) {
    this.renewableProfileFacade.downloadRenewableProfile(data);
  }

  private mapProfiles(
    renewableProfiles: RenewableProfile[],
  ): MatTableDataSource<any> {
    const datasource = new MatTableDataSource(
      renewableProfiles
        .filter((profile) => profile.parameterType === 'wind')
        .sort((a, b) => sortByField('updatedAt', a, b, true))
        .map((profile) => ({
          ...profile,
          status: Utils.resolveToEmptyObject(profile.run).status,
          coordinates: transformCoordinates(profile.sites[0]),
          computeType: profile.computeType.toLocaleUpperCase(),
          computationData: transformComputationData(profile),
        })),
    );
    datasource.paginator = this.paginator;
    datasource.sort = this.sort;
    return datasource;
  }
}
