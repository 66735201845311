<prosumer-page>
  <div class="map-padding-top">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          {{ 'RenewableProfile.labels.location' | translate }}
          <prosumer-tooltip-anchor
            [message]="'renewable_profile.location_section'"
            data-testid="location-tooltip"
          >
          </prosumer-tooltip-anchor>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
          <prosumer-input
            fxFlex
            type="string"
            data-testid="location-longitude"
            [placeholder]="
              'RenewableProfile.labels.coordinates.longitude' | translate
            "
            [label]="
              'RenewableProfile.labels.coordinates.longitude' | translate
            "
            [control]="coordinatesForm?.controls?.longitude"
          >
          </prosumer-input>
          <prosumer-input
            fxFlex
            type="string"
            data-testid="location-latitude"
            [placeholder]="
              'RenewableProfile.labels.coordinates.latitude' | translate
            "
            [label]="'RenewableProfile.labels.coordinates.latitude' | translate"
            [control]="coordinatesForm?.controls?.latitude"
          >
          </prosumer-input>
        </div>
        <gis-map
          class="coordinates-map-div"
          (dataChange)="dataChanges($event)"
          [data]="coordinates$ | async"
          [currentView]="currentView$ | async"
          data-testid="renewable-profile-target-location"
        >
        </gis-map>
      </div>
    </mat-expansion-panel>
  </div>
</prosumer-page>
