import {
  ConfigService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-app/libs/eyes-core';
import {
  ActionTypes,
  GetListSuccess,
  GetSuccess,
  StateEffects,
  doNothing,
} from 'prosumer-app/libs/eyes-shared';
import { EffectsExt, ProsumerRoutePathService } from 'prosumer-core';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  mergeMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { Case } from '../models';
import { CaseApiService } from '../services';
import { CaseFacadeService } from './case-facade.service';
import { CaseState } from './case-state.model';
import * as CaseActions from './case.actions';
import { caseFeature, caseStateFactory } from './case.factory';

@Injectable()
export class CaseEffects extends StateEffects<CaseState, Case> {
  // Base Effects (Workaround)
  getListSuccess$: Observable<GetListSuccess> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetListSuccess>(
          ActionTypes(this.stateFactory.feature).GET_LIST_SUCCESS,
        ),
        mergeMap((action) =>
          this._caseFacade.filterCases$(action.payload.id).pipe(
            take(1),
            mergeMap((dataList) =>
              from(
                (dataList || [])
                  .filter((data) => !data.loaded)
                  .map((data) => data.id),
              ).pipe(
                mergeMap((id) =>
                  this._caseFacade.get(id, { projectId: action.payload.id }),
                ),
                toArray(),
              ),
            ),
            mapTo(action),
          ),
        ),
        tap((action) =>
          action.payload.notify
            ? this.notification.showSuccess(action.payload.message)
            : doNothing(),
        ),
      ),
    { dispatch: false },
  );

  getListWithDetails$: Observable<CaseActions.All> = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseActions.GetListWithDetails>(
        CaseActions.ActionTypes.GET_LIST_WITH_DETAILS,
      ),
      mergeMap((action) =>
        this._caseApi.getListWithDetails(action.payload.id).pipe(
          map(
            (data) =>
              new CaseActions.GetListWithDetailsSuccess({
                id: action.payload.id,
                datalist: data,
                message: this._translate.instant(
                  this.getMessageMap().getListSuccess,
                ),
              }),
          ),
          catchError((messages) =>
            of(
              new CaseActions.GetListWithDetailsFailure({
                message:
                  messages ||
                  this._translate.instant(this.getMessageMap().getListFailure),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListWithDetailsSuccess$: Observable<CaseActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseActions.GetListWithDetailsSuccess>(
          CaseActions.ActionTypes.GET_LIST_WITH_DETAILS_SUCCESS,
        ),
        mergeMap((action) => {
          let userIds = [];
          this._userFacade.dataIds$.subscribe((ids) => (userIds = ids));
          return this._caseFacade.filterCases$(action.payload.id).pipe(
            take(1),
            map((dataList) => EffectsExt.withoutCreatedBy(dataList)),
            mergeMap((dataList) =>
              from(dataList || []).pipe(
                mergeMap((item) =>
                  of({ ...item, loading: false, loaded: true }),
                ),
                tap((item) => {
                  if (!userIds.includes(item['owner'])) {
                    this._userFacade.get(item['owner']);
                  }
                }),
                toArray(),
              ),
            ),
            mapTo(action),
          );
        }),
        tap((action) =>
          action.payload.notify
            ? this.notification.showSuccess(action.payload.message)
            : doNothing(),
        ),
      ),
    { dispatch: false },
  );

  getListWithDetailsFailure$: Observable<CaseActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseActions.GetListWithDetailsFailure>(
          CaseActions.ActionTypes.GET_LIST_WITH_DETAILS_FAILED,
        ),
        tap((action) => this._notification.showError(action.payload.message)),
      ),
    { dispatch: false },
  );

  createCaseSuccess$ = createEffect(
    () =>
      this.createSuccess$.pipe(
        tap((action) => {
          const createdCase: Case = action.payload.data;
          this._routerFacade.go(
            this._routePath.caseDetail(createdCase.projectId, createdCase.id),
          );
        }),
      ),
    { dispatch: false },
  );

  // Copy

  copyCase$: Observable<CaseActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<CaseActions.CopyCase>(CaseActions.ActionTypes.COPY_CASE),
      mergeMap((action) =>
        this._caseApi.copy(action.payload.data, action.payload.name).pipe(
          map(
            (response) =>
              new CaseActions.CopyCaseSuccess({
                name: action.payload.name,
                data: action.payload.data,
                message:
                  response.message ||
                  this._translate.instant('Case.messages.copySuccess'),
              }),
          ),
          catchError((message) =>
            of(
              new CaseActions.CopyCaseFailure({
                data: action.payload.data,
                name: action.payload.name,
                message:
                  message ||
                  this._translate.instant('Case.messages.copyFailure'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateChartColors$: Observable<CaseActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<CaseActions.UpdateChartColors>(
        CaseActions.ActionTypes.UPDATE_CHART_COLORS,
      ),
      mergeMap((action) =>
        this._caseApi.updateChartColors(action.payload.data),
      ),
      map((data) => new CaseActions.UpdateChartColorsOK({ data })),
    ),
  );

  getCaseSuccess$: Observable<CaseActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ActionTypes(caseFeature).GET_SUCCESS),
        tap((action: GetSuccess<Case>) =>
          this._userFacade.get(action.payload.resultData.owner),
        ),
      ),
    { dispatch: false },
  );

  copyCaseFailure$: Observable<CaseActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CaseActions.CopyCaseFailure>(
          CaseActions.ActionTypes.COPY_CASE_FAILURE,
        ),
        mergeMap((action) =>
          this._notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() =>
                this._caseFacade.copy(action.payload.data, action.payload.name),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  copyCaseSuccess$: Observable<CaseActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CaseActions.CopyCaseSuccess>(
          CaseActions.ActionTypes.COPY_CASE_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  getCaseListByNodeType$: Observable<CaseActions.All> = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseActions.GetCaseListByNodeType>(
        CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE,
      ),
      mergeMap((action) =>
        this._caseApi.getCaseListByNodeType(action.payload.nodetype).pipe(
          map(
            (data) =>
              new CaseActions.GetCaseListByNodeTypeSuccess({
                nodetype: action.payload.nodetype,
                message: this._translate.instant(
                  'Case.messages.getCaseListByNodeTypeSuccess',
                ),
                data,
              }),
          ),
          catchError((messages) =>
            of(
              new CaseActions.GetCaseListByNodeTypeFailure({
                message: messages,
                nodetype: action.payload.nodetype,
                notify: true,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getCaseListByNodeTypeFailure$: Observable<CaseActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseActions.GetCaseListByNodeTypeFailure>(
          CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE_FAILURE,
        ),
        tap((action) => this._notification.showError(action.payload.message)),
      ),
    { dispatch: false },
  );

  getCaseListByNodeTypeSuccess$: Observable<CaseActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseActions.GetCaseListByNodeTypeSuccess>(
          CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _notification: NotificationsService,
    private _caseApi: CaseApiService,
    private _caseFacade: CaseFacadeService,
    private _routerFacade: RouterStore,
    private _routePath: ProsumerRoutePathService,
    private _translate: TranslateService,
    private _userFacade: UserFacadeService,
  ) {
    super(
      _actions$,
      _caseApi,
      _notification,
      _caseFacade,
      caseStateFactory,
      _translate,
    );
    createEffect(() => this.get$);
    createEffect(() => this.getFailure$, { dispatch: false });
    createEffect(() => this.getList$);
    createEffect(() => this.getListFailure$, { dispatch: false });
    createEffect(() => this.create$);
    createEffect(() => this.createFailure$, { dispatch: false });
    createEffect(() => this.update$);
    createEffect(() => this.updateFailure$, { dispatch: false });
    createEffect(() => this.updateSuccess$, { dispatch: false });
    createEffect(() => this.delete$);
    createEffect(() => this.deleteFailure$, { dispatch: false });
    createEffect(() => this.deleteSuccess$, { dispatch: false });
  }

  getMessageMap() {
    return {
      createSuccess: 'Case.messages.saveSuccess',
      createFailure: 'Case.messages.saveFailure',
      getSuccess: 'Case.messages.getSuccess',
      getFailure: 'Case.messages.getFailure',
      getListSuccess: 'Case.messages.getAllSuccess',
      getListFailure: 'Case.messages.getAllFailure',
      updateSuccess: 'Case.messages.updateSuccess',
      updateFailure: 'Case.messages.updateFailure',
      deleteSuccess: 'Case.messages.deleteSuccess',
      deleteFailure: 'Case.messages.deleteFailure',
      retry: 'Generic.messages.retry',
    };
  }
}
