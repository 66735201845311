import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  Optional,
} from '@angular/core';

import * as Sentry from '@sentry/browser';
import { EnvConfig } from 'prosumer-app/libs/eyes-shared';
import { ENV_CONFIG } from '../eyes-core.tokens';
import { LoggerService } from './logger.service';

@Injectable()
export class ErrorService implements ErrorHandler {
  sentryDsn: string;

  constructor(
    @Inject(ENV_CONFIG) environment: EnvConfig,
    private _injector: Injector,
    @Optional() private _logger: LoggerService,
  ) {
    if (environment && environment.sentryDsn) {
      this.sentryDsn = environment.sentryDsn;
      Sentry.init({
        dsn: environment.sentryDsn,
        release: environment.sentryRelease,
      });
    }

    if (!this._injector) {
      this._logger = this._injector.get(LoggerService);
    }
  }

  handleError(error: any): void {
    if (this.sentryDsn) {
      Sentry.captureException(error.originalError || error);
    }

    if (error instanceof HttpErrorResponse) {
      return;
    }
  }
}
