import {
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
} from 'prosumer-shared';

import { LoadsForm } from '../../models';

const LOAD_PROFILE_NAME = 'Load Profile';

export class LoadsMapper {
  static mapToBackend = (loadsForm: LoadsForm): any => ({
    loads: (loadsForm.loads || []).map((load) => {
      const item = {
        id: load.id,
        name: load.name,
        energyVectorId: load.energyVector,
        nodeId: { nodeIds: load.nodes },
        loadProfile: null,
        scenarioVariation: mapVariationToBackend(load),
      };
      const loadProfile = { name: LOAD_PROFILE_NAME, profiles: [] };
      loadProfile.profiles = (load.profiles || []).map((profile) =>
        mapYearlyProfileToBackend(profile),
      );
      item.loadProfile = loadProfile;
      return item;
    }),
  });

  static mapToFrontend = (scenario: { loads: Array<any> }): LoadsForm => ({
    loads: (scenario.loads || []).map<any>((load) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const loads_profile = {
        id: load.id,
        name: load.name,
        energyVector: load.energyVectorId,
        nodes:
          typeof load.nodeId === 'string' ? load.nodeId : load.nodeId.nodeIds,
        scenarioVariation: mapVariationToFrontend(load),
        profiles: [],
      };
      loads_profile.profiles = load.loadProfile.profiles.map((profile) =>
        mapYearlyProfileToFrontend(profile, 'END_USE_LOADS'),
      );
      return loads_profile;
    }),
    binToDelete: [],
    binToClone: [],
  });
}
