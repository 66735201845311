<mat-icon
  aria-hidden="false"
  [color]="color"
  [style.marginTop.px]="marginTop"
  [prosumerTooltip]="message"
  [tooltipPosition]="tooltipPosition"
  [style.fontSize]="tooltipIconSize"
>
  {{ outlined ? 'help_outline' : 'help' }}
</mat-icon>
