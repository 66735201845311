import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { Paging } from '../models';

export enum Type {
  CLEAR_STATE,
  CREATE,
  CREATE_FAILURE,
  CREATE_SUCCESS,
  GET,
  GET_FAILURE,
  GET_SUCCESS,
  GET_LIST,
  GET_LIST_FAILURE,
  GET_LIST_SUCCESS,
  UPDATE,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  SELECT_ID,
}

/** ACTION TYPES */
export const ActionTypes = (feature: string) => ({
  CLEAR_STATE: `[${feature}] Clear State`,
  // CRUD (Entity)
  CREATE: `[${feature} Create Page] Create ${feature}`,
  CREATE_FAILURE: `[${feature} API] Create ${feature} Failure`,
  CREATE_SUCCESS: `[${feature} API] Create ${feature} Success`,
  GET: `[${feature} Detail Page] Get ${feature}`,
  GET_FAILURE: `[${feature} API] Get ${feature} Failure`,
  GET_SUCCESS: `[${feature} API] Get ${feature} Success`,
  GET_LIST: `[${feature} List Page] Get ${feature} List`,
  GET_LIST_FAILURE: `[${feature} API] Get ${feature} List Failure`,
  GET_LIST_SUCCESS: `[${feature} API] Get ${feature} List Success`,
  UPDATE: `[${feature} Detail Page] Update ${feature}`,
  UPDATE_FAILURE: `[${feature} API] Update ${feature} Failure`,
  UPDATE_SUCCESS: `[${feature} API] Update ${feature} Success`,
  DELETE: `[${feature} List Page] Delete ${feature}`,
  DELETE_FAILURE: `[Projec API] Delete ${feature} Failure`,
  DELETE_SUCCESS: `[${feature} API] Delete ${feature} Success`,
  SELECT_ID: `[${feature}] Select ${feature} ID`,
});

/** ACTIONS */

/* State */
// Clear State
export class ClearState implements Action {
  readonly type = ActionTypes(this.feature).CLEAR_STATE;
  constructor(public feature: string, public payload?: any) {}
}

// CRUD (Entity)
// Create
export class Create<U> implements Action {
  readonly type = ActionTypes(this.feature).CREATE;
  constructor(
    public feature: string,
    public payload?: { params?: Dictionary<string>; data?: U },
  ) {}
}

export class CreateFailure<U> implements Action {
  readonly type = ActionTypes(this.feature).CREATE_FAILURE;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
    },
  ) {}
}

export class CreateSuccess<U> implements Action {
  readonly type = ActionTypes(this.feature).CREATE_SUCCESS;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Get
export class Get implements Action {
  readonly type = ActionTypes(this.feature).GET;
  constructor(
    public feature: string,
    public payload: {
      id?: string;
      params?: Dictionary<string>;
      forced?: boolean;
    },
  ) {}
}

export class GetFailure implements Action {
  readonly type = ActionTypes(this.feature).GET_FAILURE;
  constructor(
    public feature: string,
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetSuccess<U> implements Action {
  readonly type = ActionTypes(this.feature).GET_SUCCESS;
  constructor(
    public feature: string,
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      message?: string;
      notify?: boolean;
      resultData?: U;
    },
  ) {}
}

// Get List
export class GetList implements Action {
  readonly type = ActionTypes(this.feature).GET_LIST;
  constructor(
    public feature: string,
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
    },
  ) {}
}

export class GetListFailure implements Action {
  readonly type = ActionTypes(this.feature).GET_LIST_FAILURE;
  constructor(
    public feature: string,
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetListSuccess implements Action {
  readonly type = ActionTypes(this.feature).GET_LIST_SUCCESS;
  constructor(
    public feature: string,
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
      message?: string;
      notify?: boolean;
      resultIds?: Array<string>;
    },
  ) {}
}

// Update
export class Update<U> implements Action {
  readonly type = ActionTypes(this.feature).UPDATE;
  constructor(
    public feature: string,
    public payload?: { params?: Dictionary<string>; data?: U },
  ) {}
}

export class UpdateFailure<U> implements Action {
  readonly type = ActionTypes(this.feature).UPDATE_FAILURE;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class UpdateSuccess<U> implements Action {
  readonly type = ActionTypes(this.feature).UPDATE_SUCCESS;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Delete
export class Delete<U> implements Action {
  readonly type = ActionTypes(this.feature).DELETE;
  constructor(
    public feature: string,
    public payload?: { params?: Dictionary<string>; data?: U },
  ) {}
}

export class DeleteFailure<U> implements Action {
  readonly type = ActionTypes(this.feature).DELETE_FAILURE;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class DeleteSuccess<U> implements Action {
  readonly type = ActionTypes(this.feature).DELETE_SUCCESS;
  constructor(
    public feature: string,
    public payload?: {
      params?: Dictionary<string>;
      data?: U;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Select
export class SelectId implements Action {
  readonly type = ActionTypes(this.feature).SELECT_ID;
  constructor(public feature: string, public payload?: { id: string }) {}
}

/** ACTIONS UNION */

export type AllActions<U> =
  | ClearState
  | Create<U>
  | CreateFailure<U>
  | CreateSuccess<U>
  | Get
  | GetFailure
  | GetSuccess<U>
  | GetList
  | GetListFailure
  | GetListSuccess
  | Update<U>
  | UpdateFailure<U>
  | UpdateSuccess<U>
  | Delete<U>
  | DeleteFailure<U>
  | DeleteSuccess<U>
  | SelectId;
