/* eslint-disable @typescript-eslint/naming-convention */
import { DialogData, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { HasId } from 'prosumer-app/stores/scenario-detail';

import { Profile } from './binary-data.model';

export enum ParameterType {
  PDOC = 'PDOC',
  FDOC = 'FDOC',
}

export type OperatingCostDialogMode = 'add' | 'edit';

export interface OperatingCostDialogData extends DialogData {
  mode: OperatingCostDialogMode;
  startYear?: number;
  endYear?: number;
  formType: string;
  inputEnergyVectorsOptions: FormFieldOption<string>[];
  outputEnergyVectorsOptions: FormFieldOption<string>[];
  operatingCost: OperatingCost & Partial<HasId>;
  scenarioIdentity?: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
}

export interface OperatingCost {
  readonly parameterType?: string;
  readonly energyVectorName?: string;
  readonly energyVectorId?: string;
  readonly profiles?: Array<Profile>;
}
