import { User } from 'prosumer-app/+project/models';
import { StateFacadeService } from 'prosumer-app/libs/eyes-shared';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { Project } from '../models';
import { ProjectState } from './project-state.model';
import * as ProjectActions from './project.actions';
import { projectStateFactory } from './project.factory';
import { projectQueries } from './project.selectors';

@Injectable()
export class ProjectFacadeService extends StateFacadeService<
  ProjectState,
  Project
> {
  sample$ = this.store.pipe(select(projectQueries.getSample));

  constructor(
    public store: Store<ProjectState>,
    public actionSubject$: ActionsSubject,
  ) {
    super(store, projectStateFactory, actionSubject$);
  }

  copy(data: Project, name: string) {
    this.store.dispatch(new ProjectActions.CopyProject({ data, name }));
  }

  share(data: Project, userIds: Array<User>) {
    this.store.dispatch(new ProjectActions.ShareProject({ data, userIds }));
  }

  unshare(data: Project, userIds: Array<string>) {
    this.store.dispatch(new ProjectActions.UnshareProject({ data, userIds }));
  }

  remove(id: string) {
    this.store.dispatch(new ProjectActions.RemoveProject({ id }));
  }

  getListWithDetails() {
    this.store.dispatch(new ProjectActions.GetProjectListWithDetails());
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_SUCCESS,
      ),
    );
  }
}
