import { YearlyValues } from 'prosumer-app/shared/models';

export const INPUT_TYPE = 'INPUT';
export const OUTPUT_TYPE = 'OUTPUT';
export type EV_TYPE = typeof INPUT_TYPE | typeof OUTPUT_TYPE;

export interface EfficiencyMatrixControlModel {
  mainEnergyVectorInput?: string;
  mainEnergyVectorOutput?: string;
  efficiencies: EfficiencyEfficiencyMatrixModel[];
}

export interface EfficiencyEfficiencyMatrixModel {
  energyVectorIn: string;
  energyVectorOut: string;
  value: YearlyValues;
}

export type EfficiencyMatrixEnergyVectorMetadata = {
  energyVectorId: string;
  type: 'input' | 'output';
};

export interface ReplacementEVEventModel {
  newId: string;
  oldId: string;
  type: EV_TYPE;
}

export const MainEVTypeMatcher = {
  [INPUT_TYPE]: 'mainEnergyVectorInput',
  [OUTPUT_TYPE]: 'mainEnergyVectorOutput',
};

export const EfficienciesEVTypeMatcher = {
  [INPUT_TYPE]: 'energyVectorIn',
  [OUTPUT_TYPE]: 'energyVectorOut',
};
