import { NgModule, ModuleWithProviders } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { authFeatureName } from './auth.actions';
import { authReducer } from './auth.reducer';
import { AuthStore } from './auth-store.service';
import { AuthEffects } from './auth.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(authFeatureName, authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthStore],
    };
  }
}
