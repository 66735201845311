import { MarketReserve } from 'prosumer-app/+scenario/models';
import {
  BOOLEAN_ICONS,
  DIALOG_SIZES,
  DIRECTION_ICONS,
} from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { SparklineTableColumnDefinition } from 'prosumer-app/shared/components/sparkline-table/sparkline-table.model';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  ReferencesBuilder,
  TABLE_REF_KEYS,
} from 'prosumer-app/services/references-builder';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { SpinningReserveMarket } from 'prosumer-app/stores/spinning-reserve-market';
import { FrequencyControlFormService } from '../../frequency-control-form.service';
import {
  ReserveMarketFormDialogComponent,
  ReserveMarketFormDialogData,
} from '../market-form-dialog';

type TableSource = MatTableDataSource<MarketReserve>;
@Component({
  selector: 'prosumer-market-table',
  templateUrl: './market-table.component.html',
  styleUrls: ['./market-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class MarketTableComponent
  extends ManagedTableComponent<MarketReserve>
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DIRECTION_ICONS = { ...DIRECTION_ICONS };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly BOOLEAN_ICONS = { ...BOOLEAN_ICONS };

  @Input() markets = [];
  @Input() energyVectors = [];

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  source$: Observable<TableSource>;
  hasData$: Observable<boolean>;

  get commonData() {
    return {
      width: DIALOG_SIZES.small,
      disableClose: true,
      ...this.service.prepForReserveMarket(),
    };
  }

  readonly refs$ = this.refs.selectRefs();

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public managedData: ManagedDataService<MarketReserve>,
    public dialogService: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly refs: ReferencesBuilder,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.source$ = this.selectSource();
    this.hasData$ = this.selectHasData();
  }

  defineColumns(): SparklineTableColumnDefinition {
    return {
      energyVectorId: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: TABLE_REF_KEYS.energyVectors,
        sortable: true,
        toolTip: 'wizard_frequency_control.reserve_energy_vector',
      },
      energyGridConnectionId: {
        name: 'Market',
        type: 'reference',
        referenceKey: TABLE_REF_KEYS.marketName,
        sortable: true,
        toolTip: 'wizard_frequency_control.market',
      },
      direction: {
        name: 'Direction',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.direction',
      },
      participation: {
        name: 'Participation [-]',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.market_participation',
      },
      requirement: {
        name: 'Requirement [p.u.]',
        sortable: true,
        toolTip: 'wizard_frequency_control.market_requirement',
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }

  onAdd() {
    this.dialogService.openDialog(ReserveMarketFormDialogComponent, {
      ...this.commonData,
      mode: 'add',
    } as ReserveMarketFormDialogData);
  }

  onViewEdit(data: MarketReserve) {
    this.dialogService.openDialog(ReserveMarketFormDialogComponent, {
      ...this.commonData,
      mode: this.getViewEditMode(),
      currentReserveMarket: data,
    } as ReserveMarketFormDialogData);
  }

  onDelete(data: SpinningReserveMarket) {
    this.service.deleteReserveMarket(data.id).pipe(take(1)).subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');

  private selectHasData(): Observable<boolean> {
    return this.service
      .selectSpinningReserveMarkets()
      .pipe(map((data) => data.length > 0));
  }

  private selectSource(): Observable<TableSource> {
    return this.service
      .selectSpinningReserveMarkets()
      .pipe(map((data) => this.toSource(data)));
  }

  private toSource(data: MarketReserve[]): TableSource {
    return new MatTableDataSource(data);
  }
}
