import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  AutocompleteInputComponent,
  BaseDialogComponent,
  BreadcrumbComponent,
  ButtonComponent,
  DateInputComponent,
  DateInputControlComponent,
  FilterInputComponent,
  FluxBarComponent,
  FormFieldComponent,
  InputComponent,
  PageComponent,
  SearchInputComponent,
  ProgressCardComponent,
  RadioButtonComponent,
  SelectComponent,
  SparklineComponent,
  StepperNavComponent,
} from './components/index';
import { TableMessageComponent } from './components/table-message';
import {
  AngularModule,
  MaterialModule,
  NgxModuleModule,
  TooltipModule,
} from './modules/index';
import {
  AbsoluteValuePipe,
  CoerceBooleanPipe,
  DMYSlashPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  DateTimeSlashedPipe,
  FirstValuePipe,
  InversePipe,
  JsonKeyToLabelPipe,
  KeysPipe,
  KiloPipe,
  MetricPipe,
  ReferencePipe,
  SeriesDataListPipe,
  SumPipe,
  TableDataLinkPipe,
} from './pipes/index';
import { FormService, SupportConfigService } from './services';
import {
  CustomBreakPointsProvider,
  CustomErrorStateMatcher,
} from './utilities/index';
import { I18NService } from 'prosumer-app/core/services/translate-i18n';

const moduleMembers = [
  /* Components */
  AutocompleteInputComponent,
  BaseDialogComponent,
  ButtonComponent,
  BreadcrumbComponent,
  DateInputComponent,
  DateInputControlComponent,
  FluxBarComponent,
  FilterInputComponent,
  FormFieldComponent,
  InputComponent,
  PageComponent,
  ProgressCardComponent,
  RadioButtonComponent,
  SearchInputComponent,
  SelectComponent,
  SparklineComponent,
  StepperNavComponent,
  TableMessageComponent,
  /* Pipes */
  AbsoluteValuePipe,
  CoerceBooleanPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  DateTimeSlashedPipe,
  DMYSlashPipe,
  InversePipe,
  JsonKeyToLabelPipe,
  KiloPipe,
  SeriesDataListPipe,
  SumPipe,
  TableDataLinkPipe,
  KeysPipe,
  MetricPipe,
  ReferencePipe,
  FirstValuePipe,
];

@NgModule({
  imports: [
    /* Modules */
    AngularModule,
    MaterialModule,
    NgxModuleModule,
    TooltipModule,
  ],
  declarations: [...moduleMembers],
  exports: [
    /* Modules */
    AngularModule,
    MaterialModule,
    NgxModuleModule,
    TooltipModule,
    /* Module Members */
    ...moduleMembers,
  ],
  providers: [SupportConfigService, FormService, I18NService],
})
export class EyesSharedModule {
  static forRoot(): ModuleWithProviders<EyesSharedModule> {
    return {
      ngModule: EyesSharedModule,
      providers: [
        { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
        CustomBreakPointsProvider,
      ],
    };
  }

  static forFeature(): ModuleWithProviders<EyesSharedModule> {
    return {
      ngModule: EyesSharedModule,
      providers: [
        { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
      ],
    };
  }
}
