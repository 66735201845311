export interface HasCreatedBy {
  readonly createdBy?: string;
  readonly ownerName?: string;
}

export class EffectsExt {
  static withoutCreatedBy<T extends HasCreatedBy>(from: T[]): T[] {
    return from.filter((item) => !item.createdBy);
  }
}
