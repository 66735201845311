import { GisMapModule, Line, MapData, Node } from '@eyes/eyes-gis';
import { FeatureCollection, Point } from 'geojson';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { environment } from '../../../../environments';
import { CoordinatesMapComponent } from './coordinates-map-form.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Cesium: any;
Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');

export const MAPBOX_API_KEY =
  'pk.eyJ1IjoiYmFyYWx1Z2EiLCJhIjoiY2s4emV6ZTRzMXNheDNqcXNmeWc3enljbiJ9.UPKRRujhZCLKrRfVQ0RHAg';

export const CESIUM_API_KEY =
  // eslint-disable-next-line max-len
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmMTkwNGU5NS01NDBiLTQzMjItYjQ5OC1mNjQyYzllNWJmNWEiLCJpZCI6MjcyMzAsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciLCJnYyIsInByIl0sImlhdCI6MTU4OTE2ODM3Nn0.lsuJaBqwGz0O04ASNVQerTkJ18jAASbApfFs-2QQZE0';

@NgModule({
  declarations: [CoordinatesMapComponent],
  imports: [
    CommonModule,
    EyesSharedModule.forFeature(),
    SharedModule,
    GisMapModule.forRoot({
      cesiumApiKey: CESIUM_API_KEY,
      imageryOptions: {
        provider: 'mapbox',
        apiKey: MAPBOX_API_KEY,
      },
      initialView: {
        position: {
          longitude: 2.3109482581347196,
          latitude: 48.81274143235991,
          altitude: 7127.701687626628,
        },
      },
      production: environment.production,
      debugOptions: {
        showApiLogs: false,
        showCameraLogs: false,
        showFPS: false,
      },
      controlOptions: {
        showToolbox: true,
        showPanelOverlay: true,
        showStyleSelector: true,
        showSearch: true,
        showPin: true,
        styleSelectorConfig: {
          options: [
            {
              id: 'mapbox-satellite',
              name: 'Mapbox Satellite',
              url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}?access_token=',
              imageUrl: 'assets/gis/map-styles/satellite-icon.png',
            },
            {
              id: 'mapbox-satellite-streets',
              name: 'Mapbox Satellite - Streets',
              url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/512/{z}/{x}/{y}?access_token=',
              imageUrl: 'assets/gis/map-styles/satellite-streets-icon.png',
            },
            {
              id: 'mapbox-streets',
              name: 'Mapbox Classic - Streets',
              url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}?access_token=',
              imageUrl: 'assets/gis/map-styles/streets-icon.png',
            },
          ],
          defaultStyleId: 'mapbox-satellite',
        },
        importConfig: {
          extensions: [
            {
              extension: 'gismap',
              mapFn: (data: MapData) => data,
            },
            {
              extension: 'geojson',
              mapFn: (sourceData: FeatureCollection) => {
                const nodes: Node[] = [];
                const lines: Line[] = [];
                sourceData.features.forEach((feature) => {
                  if (feature.geometry.type === 'LineString') {
                    lines.push({
                      id: feature.id + '',
                      lineTypeId: feature.properties.lineTypeId,
                      name: feature.properties.name,
                      sourceNodeId: feature.properties.sourceNodeId,
                      targetNodeId: feature.properties.targetNodeId,
                      properties: feature.properties,
                    });
                  }
                  if (feature.geometry.type === 'Point') {
                    nodes.push({
                      id: feature.id + '',
                      name: feature.properties.name,
                      nodeTypeId: feature.properties.nodeTypeId,
                      position: {
                        longitude: (feature.geometry as any).coordinates[0][1],
                        latitude: (feature.geometry as any).coordinates[0][0],
                        altitude: 0,
                      },
                      properties: feature.properties,
                    });
                  }
                });
                return { lines, nodes };
              },
            },
          ],
        },
        historyConfig: {
          maxActionsHistory: 10,
        },
      },
      geocodingOptions: {
        url: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
        apiKey: MAPBOX_API_KEY,
        mapFn: ({ features }: FeatureCollection<Point>) =>
          features.map((feature) => ({
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1],
            name: (feature as any).place_name,
          })),
      },
    }),
  ],
  exports: [CoordinatesMapComponent],
})
export class CoordinatesMapModule {}
