<div class="tooltip" fxLayout="column" fxLayoutGap="5px">
  <div class="tooltip-title">{{ titleFormat(currentTooltipData) }}</div>
  <div class="tooltip-content" fxLayout="column">
    <div
      *ngFor="let tooltipData of tooltipDataList"
      class="tooltip-item"
      fxLayout="row"
      fxLayoutAlign="start center"
      [ngClass]="{ current: tooltipData?.current }"
    >
      <div
        class="tooltip-item-color"
        [style.background-color]="tooltipData?.color"
      ></div>
      <div class="tooltip-item-series">{{ tooltipData?.series }}:</div>
      <div fxFlex></div>
      <div class="tooltip-item-value">
        {{ tooltipData?.value | number: '1.2-2' }} {{ unit }}
      </div>
    </div>
  </div>
  <div
    class="tooltip-item tooltip-total"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div class="tooltip-item-series">
      {{ 'Result.labels.total' | translate | uppercase }}:
    </div>
    <div fxFlex></div>
    <div class="tooltip-item-value">
      {{ tooltipDataList | sum: 'value' | number: '1.2-2' }} {{ unit }}
    </div>
  </div>
</div>
