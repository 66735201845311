import { Button, IconButton } from '../components/button/index';
import { FormFieldOption } from '../components/form-field/index';

export type StatusDetail = Readonly<{
  class?: string;
  icon?: string;
  label?: string;
}>;

export type StatusMap = Readonly<{
  [status: string]: StatusDetail;
}>;

export type FilterConfig = Readonly<{
  field: string;
  type?: 'text' | 'select';
  label?: string;
  placeholder?: string;
  options?: Array<FormFieldOption<string>>;
  value?: string;
  flex?: string;
  fieldReference?: Array<any>;
  referenceKey?: string;
  referenceValue?: string;
}>;

export class SpecialTextData {
  type: 'text' | 'link' | 'fieldValue' | 'statusField';
  text?: string;
  textField?: string;
  linkEnabledPredicate?: () => boolean;
  linkBase?: string;
  linkParamsFields?: Array<string>;
  class?: any;
  style?: any;
  statusClassMap?: { [fieldValue: string]: any }; // { fieldValue: 'styleClass' }
}

export class ConditionalSpecialText {
  condition: () => boolean;
  specialText: Array<SpecialTextData>;
}

export type ColumnDefinitions = Readonly<{
  name: string;

  headerTooltip?: string;
  headerTooltipPosition?: string;
  headerTooltipColor?: string;

  forceValue?: string; // To force the value of the field
  /* For Links */
  isLink?: boolean;
  linkRoute?: string | string[]; // The route to redirect when clicking the link
  linkParamKey?: string; // The data field where to get the parameter passed to the redirection link
  linkParamKeys?: string[];
  /* For References */
  isReference?: boolean;
  reference?: Array<any>;
  referenceKey?: string;
  referenceVal?: string;
  /* For Data with Formatting */
  isDate?: boolean;
  isSlashedDate?: boolean;
  isDateTime?: boolean;
  isDefaultDateTime?: boolean;
  /* For Actions */
  isAction?: boolean;
  actionButtons?: Array<IconButton>;
  /* For Colored Status */
  isStatus?: boolean;
  statusField?: string; // The data field where to get the status
  statusMap?: StatusMap; // The status map with respective attributes
  isRightAlign?: boolean;
  // DIRTY WORKAROUND FOR THE DER_INVESTMENTS LOL
  isCurrency?: boolean;
  currencyHandler?: { pipe: 'decipipe' | 'metricpipe'; format: string };

  /* For Flex Formatting */
  flex?: string;
  flexAlign?: string;
  /* For Special Texts */
  specialText?: Array<SpecialTextData>;
  conditionalSpecialTexts?: Array<ConditionalSpecialText>;
}>;

export type TableMetadata = Readonly<{
  [key: string]: ColumnDefinitions;
}>;

export type ExpandableRowsData = Readonly<{
  metadata: TableMetadata;
  tableTitle?: string;
  buttons?: Array<Button>;
  filters?: FilterConfig | Array<FilterConfig>;
  dataSource: Array<any>;
}>;
