import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { TableExportType } from './table-exporter.service.model';

@Injectable()
export class TableExporterService {
  exportTableAs(data: unknown, fileName: string, bookType: TableExportType) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(data);
    this.writeToFile(ws, fileName, bookType);
  }

  exportJsonAs(data: unknown[], fileName: string, bookType: TableExportType) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    this.writeToFile(ws, fileName, bookType);
  }

  private writeToFile(
    ws: XLSX.WorkSheet,
    fileName: string,
    bookType: TableExportType,
  ) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName.substring(0, 30)); // max sheet char is 30
    XLSX.writeFile(wb, `${fileName}.${bookType}`, { bookType });
  }
}
