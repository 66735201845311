import { Action } from '@ngrx/store';

import { Dimensions, Feature, type } from 'prosumer-app/libs/eyes-shared';

export const pageFeatureName = 'page';

export const PageActionTypes = {
  SET_BACKGROUND: type(`[${pageFeatureName}] Set Background`),
  SET_NAME: type(`[${pageFeatureName}] Set Name`),
  SET_SIDENAV_LINKS: type(`[${pageFeatureName}] Set Sidenav Links`),
  SET_TITLE: type(`[${pageFeatureName}] Set Title`),
  SET_TOOLBAR_LINKS: type(`[${pageFeatureName}] Set Toolbar Links`),
  SET_DIMENSIONS: type(`[${pageFeatureName}] Set Content Dimensions`),
};

export class PageSetBackgroundAction implements Action {
  readonly type = PageActionTypes.SET_BACKGROUND;
  constructor(public payload: string) {}
}

export class PageSetNameAction implements Action {
  readonly type = PageActionTypes.SET_NAME;
  constructor(public payload: string) {}
}
export class PageSetSidenavLinksAction implements Action {
  readonly type = PageActionTypes.SET_SIDENAV_LINKS;
  constructor(public payload: Array<Feature>) {}
}

export class PageSetTitleAction implements Action {
  readonly type = PageActionTypes.SET_TITLE;
  constructor(public payload: string) {}
}

export class PageSetToolbarLinksAction implements Action {
  readonly type = PageActionTypes.SET_TOOLBAR_LINKS;
  constructor(public payload: Array<Feature>) {}
}

export class PageSetDimensionsAction implements Action {
  readonly type = PageActionTypes.SET_DIMENSIONS;
  constructor(public payload: Dimensions) {}
}

export type PageActions =
  | any
  | PageSetBackgroundAction
  | PageSetNameAction
  | PageSetSidenavLinksAction
  | PageSetTitleAction
  | PageSetToolbarLinksAction
  | PageSetDimensionsAction;
