<form [formGroup]="form">
  <div
    fxLayout="column"
    formArrayName="intervalList"
    *ngFor="let interval of intervalList.controls; let i = index"
  >
    <div [formGroupName]="i" fxLayout="row" class="interval">
      <prosumer-select
        label="Start Year"
        fxFlex="calc(30%-10px)"
        (eyesSelectionChange)="updateRange(i, $event)"
        [options]="getStartYearOptions(interval)"
        [value]="getStartYearControl(interval)"
        [disabled]="!!!i"
      >
      </prosumer-select>
      <prosumer-select
        label="End Year"
        fxFlex="calc(30%-10px)"
        [options]="getEndYearOptions(interval)"
        [value]="getEndYearControl(interval)"
        [disabled]="true"
      >
      </prosumer-select>

      <mat-form-field
        [@fadeIn]
        appearance="outline"
        fxFlex="calc(30%-10px)"
        class="eyes-maximize-width"
      >
        <mat-label>Value</mat-label>
        <input
          matInput
          type="number"
          placeholder="0.0"
          class="eyes-input"
          formControlName="value"
          [readonly]="isViewOnly"
        />
        <mat-error
          *ngIf="interval?.controls?.value?.errors?.required"
          [innerHtml]="'Project.messages.requiredField' | translate"
        ></mat-error>
        <mat-error
          *ngIf="interval?.controls?.value?.errors?.min"
          [innerHtml]="'Project.messages.positiveOnly' | translate"
        >
        </mat-error>
      </mat-form-field>

      <button
        mat-icon-button
        type="button"
        color="primary"
        class="remove-interval"
        data-testid="remove-interval"
        (click)="deleteRange(i)"
        *ngIf="i && !isViewOnly"
      >
        <mat-icon aria-hidden="false" color="warn">cancel</mat-icon>
      </button>
      <div *ngIf="!i" fxFlex="40px"></div>
    </div>
  </div>
  <button
    mat-button
    type="button"
    color="primary"
    class="add-interval"
    data-testid="add-interval"
    [disabled]="isDisabled()"
    (click)="addRange()"
    *ngIf="!isViewOnly && !isEnd()"
  >
    <mat-icon aria-hidden="false">add</mat-icon>
    <span>Interval</span>
  </button>
</form>
