export class Coerce {
  static toNullishCoalescedString(value: unknown, defaultValue = ''): string {
    return String(value ?? defaultValue);
  }
  static toNullishCoalescedObject<T>(value: unknown, defaultValue: T): T {
    return (value ?? defaultValue) as T;
  }

  static toString(value: unknown, defaultValue = ''): string {
    return (value as string) || defaultValue;
  }

  static toNumber(value: unknown, defaultValue = 0): number {
    return (value as number) || defaultValue;
  }

  static toObject<T>(value: T, defaultValue = {} as T): T {
    return (value as T) || defaultValue;
  }

  static toArray<T>(
    value: T[] | undefined | null,
    defaultValue = [] as T[],
  ): T[] {
    return (value as T[]) || defaultValue;
  }
}
