import { Inject, Injectable } from '@angular/core';
import { EnvConfig, findKey } from 'prosumer-app/libs/eyes-shared';
import { ENV_CONFIG } from '../eyes-core.tokens';

enum LogType {
  DEBUG = '#4CC355',
  INFO = '#10ADED',
  ERROR = '#FA113D',
  WARN = '#FB1',
}

@Injectable()
export class LoggerService {
  isProduction: boolean;

  constructor(@Inject(ENV_CONFIG) environment: EnvConfig) {
    if (environment) {
      this.isProduction = environment.production;
    }
  }

  debug(message: any, ...params): void {
    if (this.isProduction) {
      return;
    }
    this.log(LogType.DEBUG, message, params);
  }

  error(message: any, ...params): void {
    this.log(LogType.ERROR, message, params);
  }

  info(message: any, ...params): void {
    this.log(LogType.INFO, message, params);
  }

  warn(message: any, ...params): void {
    this.log(LogType.WARN, message, params);
  }

  private log(type: LogType, message: any, params): void {
    const key = findKey(LogType, type);
    if (params && params.length > 0) {
      console.group(`%c ${key}:`, `color: ${type};`, message);
      (params as Array<any>).forEach((data) => console.log(data));
      console.groupEnd();
    } else {
      console.log(`%c ${key}:`, `color: ${type}; font-weight: bold`, message);
    }
  }
}
