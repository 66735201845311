<mat-form-field
  appearance="outline"
  class="eyes-maximize-width"
  [fxFlex]="flex"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="placeholdermsg"
    (keyup)="onFilter($event?.target?.value)"
  />
  <mat-icon aria-hidden="false" matSuffix>search</mat-icon>
</mat-form-field>
