import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ActionTypes, StateFacadeService } from 'prosumer-app/libs/eyes-shared';
import { filter, map } from 'rxjs/operators';
import { RetrievalType, User } from '../models';
import { UserState } from './user-state.model';
import * as UserActions from './user.actions';
import { userFeature, userStateFactory } from './user.factory';
import { userQueries } from './user.selectors';

@Injectable()
export class UserFacadeService extends StateFacadeService<UserState, User> {
  // Check if any of the entities are currently loading
  entityFetching$ = this.store.pipe(select(userQueries.isEntityFetching));
  // Listen to all getUser success
  getUserIDSuccess$ = this.actionSubject$.pipe(
    filter((action) => action.type === ActionTypes(userFeature).GET),
  );
  // Listen to all getByEmail success
  getUserEmailSuccess$ = this.actionSubject$.pipe(
    filter(
      (action) => action.type === UserActions.ActionTypes.GET_BY_EMAIL_SUCCESS,
    ),
  );
  // Pipe all users fetched by ID
  idUsers$ = this.dataList$.pipe(
    map((users) =>
      users.filter((user) => user.retrievedUsing === RetrievalType.ID),
    ),
  );
  // Pipe all users fetched by email
  emailUsers$ = this.dataList$.pipe(
    map((users) =>
      users.filter((user) => user.retrievedUsing === RetrievalType.EMAIL),
    ),
  );
  clientUser$ = this.dataList$.pipe(
    map((users) => users.filter((user) => user.isClient)),
    filter((clientUsers) => !!clientUsers && clientUsers.length > 0),
    map((clientUsers) => clientUsers[0]),
  );
  clientUserPrefs$ = this.clientUser$.pipe(
    map((clientUser) => clientUser.preferences),
  );

  clientUserPrefsFeatures$ = this.clientUser$.pipe(
    map((clientUser) =>
      clientUser.preferences && clientUser.preferences.prosumerFeatures
        ? JSON.parse(clientUser.preferences.prosumerFeatures as string) || {}
        : {},
    ),
  );

  constructor(store: Store<UserState>, actionSubject$: ActionsSubject) {
    super(store, userStateFactory, actionSubject$);
  }

  getByEmail(email: string) {
    this.store.dispatch(new UserActions.GetByEmail({ email }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === UserActions.ActionTypes.GET_BY_EMAIL_SUCCESS,
      ),
    );
  }
}
