import { HttpErrorResponse } from '@angular/common/http';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';

import { Paging } from '../models';
import { generateEndpoint, getValues } from '../utilities';

export interface ApiCRUD<U> {
  create(data: U, params?: Dictionary<string>): Observable<U>;
  get(id: string, params?: Dictionary<string>): Observable<U>;
  getList(
    id?: string,
    params?: Dictionary<string>,
    paging?: Paging,
  ): Observable<Array<string>>;
  update(data: U, params?: Dictionary<string>): Observable<U>;
  delete(data: U, params?: Dictionary<string>): Observable<any>;
}

export interface ApiMappable<U> {
  mapDataToBackend(data: U): any;
  mapDataToFrontend(data: any): U;
}

export abstract class ApiHandler {
  constructor(public baseUrl: string) {}

  endpoint(endpoint: string, id?: string, params?: Dictionary<string>): string {
    return generateEndpoint(this.baseUrl, endpoint, ...getValues(params), id);
  }

  handleError(response: HttpErrorResponse): string {
    return ((response || ({} as HttpErrorResponse)).error || {}).error;
  }
}

export abstract class ApiService<U>
  extends ApiHandler
  implements ApiCRUD<U>, ApiMappable<U>
{
  constructor(public baseUrl: string) {
    super(baseUrl);
  }

  abstract create(data: U, params?: Dictionary<string>): Observable<U>;
  abstract get(id: string, params?: Dictionary<string>): Observable<U>;
  abstract getList(
    id?: string,
    params?: Dictionary<string>,
    paging?: Paging,
  ): Observable<Array<string>>;
  abstract update(data: U, params?: Dictionary<string>): Observable<U>;
  abstract delete(data: U, params?: Dictionary<string>): Observable<any>;
  abstract mapDataToBackend(data: U): any;
  abstract mapDataToFrontend(data: any): U;
}
