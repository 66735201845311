import {
  BinaryDataToClone,
  Profile,
  ProfileRoutes,
} from 'prosumer-app/+scenario';

/**
 * Gets the bin to delete from edited profiles.
 * Must be of type profile to ensure that localId and location is present.
 *
 * @param persistedItems remaining items. MUST NOT BE NULL
 * @param profiles profile to be checked
 */
export const getBinsToDeleteOnEdit = (
  persistedItems: Array<Profile | ProfileRoutes>,
  profiles: Array<Profile | ProfileRoutes>,
): Array<Profile | ProfileRoutes> => {
  if (!!!persistedItems || !!!profiles) {
    return;
  }
  const changedIDs = profiles.map((_) => _.localId);
  return persistedItems.filter(
    (profile) => !changedIDs.includes(profile.localId),
  );
};

/**
 * Gets the bin to delete.
 * Must be of type profile to ensure that localId and location is present.
 * If persistedItems is undefined, it is determined that all profiles passed must be deleted.
 *
 * @param persistedItems remaining items
 * @param profiles profile to be checked
 */
export const getBinsToDeleteOnDelete = (
  persistedItems: Array<Profile | ProfileRoutes>,
  profiles: Array<Profile | ProfileRoutes>,
): Array<Profile | ProfileRoutes> => {
  if (!!!persistedItems) {
    return profiles;
  }
  const persistedIDs = persistedItems.map((_) => _.localId);
  return profiles.filter((profile) => persistedIDs.includes(profile.localId));
};

/**
 * Filters bindata that was edited and returns array of profiles to be cloned
 *
 * @param savedItemsToClone persisted items to be cloned
 * @param profiles profiles to be checked
 */
export const getBinsToCloneOnEdit = (
  savedItemsToClone: Array<BinaryDataToClone>,
  profiles: Array<Profile | ProfileRoutes>,
): Array<BinaryDataToClone> => {
  const editedItems = (profiles || [])
    .filter((profile) => profile.loadProfile && profile.loadProfile.length > 0)
    .map((data) => data.localId);
  return (savedItemsToClone || []).filter(
    (profile) => !editedItems.includes(profile.newLocalId),
  );
};
