import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';


import { AuthService } from '../services';

@Injectable()
export class AuthGuard  {
  constructor(private readonly authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.checkAuth$.pipe(
      mergeMap((loggedIn) => {
        if (loggedIn) {
          return of(true);
        }
        return this.redirectToLogin();
      }),
      catchError(() => this.redirectToLogin()),
    );
  }

  protected redirectToLogin(): Observable<boolean> {
    this.authService.login();
    return of(false);
  }
}
