export enum DayOfWeek {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
  inv = 'invalid',
}

const HOURS_IN_DAY = 24;
const DAYS_OF_WEEK: DayOfWeek[] = Object.values(DayOfWeek);

const whatDayOfWeek = (hours: number): DayOfWeek =>
  DAYS_OF_WEEK[calculateIndex(hours)];

const calculateIndex = (hours: number): number =>
  Math.ceil(hours / HOURS_IN_DAY) - getStartingHour();

const getStartingHour = (): number => 1;

const prependDay = (hours: number): string =>
  `${whatDayOfWeek(hours)} - ${hours}`;

export const dayUtils = {
  whatDayOfWeek,
  getStartingHour,
  prependDay,
};
