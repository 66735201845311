import cytoscape from 'cytoscape';
import { fadeInAnimation } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  SystemVisualizationData,
  YearlySystemVisualizationData,
} from '../../models';
import { SystemVisualizationService } from '../../services';

export const OVERVIEW_OPTION = {
  label: 'Overview',
  value: 0,
  tooltip: 'system_overview.system_overview',
};
export const NODE_VIEW_OPTION = {
  label: 'Node View',
  value: 1,
  tooltip: 'system_overview.node_overview',
};

@UntilDestroy()
@Component({
  selector: 'prosumer-system-visualization',
  templateUrl: './system-visualization.component.html',
  styleUrls: ['./system-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class SystemVisualizationComponent implements OnInit {
  loaded$ = new BehaviorSubject<boolean>(false);
  yearsOption$ = new BehaviorSubject<{ value: string; name: string }[]>([]);
  selectedYear$ = new BehaviorSubject<any>(undefined);
  // The system visualization data
  private _yearlyData = new BehaviorSubject<YearlySystemVisualizationData>(
    undefined,
  );
  private _data = new BehaviorSubject<SystemVisualizationData>(undefined);
  get data(): SystemVisualizationData {
    return this._data.getValue();
  }
  @Input() set data(
    value: SystemVisualizationData | YearlySystemVisualizationData,
  ) {
    this.resolveDataModel(value);
  }

  @Input() set dimensions(value: { height: number; width: number }) {
    if (!!value) {
      this.helperService.setHeight(value.height);
      this.helperService.setWidth(value.width);
    }
  }

  constructor(
    public elementRef: ElementRef,
    public helperService: SystemVisualizationService,
  ) {}

  ngOnInit(): void {
    this.subToDataChange();
    this.subToYearlyDataChange();
  }

  private resolveDataModel(
    data: SystemVisualizationData | YearlySystemVisualizationData,
  ) {
    if (data && data['yearsList']) {
      this._yearlyData.next(data as YearlySystemVisualizationData);
    } else {
      this._data.next(data as SystemVisualizationData);
    }
  }

  private subToDataChange() {
    this._data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.updateMultiNode();
      this.helperService.setSystemVizData(data);
      this.loaded$.next(Object.keys(data || {}).length > 1);
    });
  }

  private subToYearlyDataChange() {
    this._yearlyData
      .pipe(untilDestroyed(this), filterNilValue())
      .subscribe((yearlyData) => {
        const yearsList = yearlyData['yearsList'];
        const initialYear = yearsList[0];
        this.updateYearsOption(yearsList);
        this.updateSelectedYear(initialYear);
        this.updateDataDisplayed(yearlyData, initialYear);
      });
  }

  handleSelectedYearChange(selectedYear: string) {
    this.updateSelectedYear(selectedYear);
    this.updateDataDisplayed(this._yearlyData.getValue(), selectedYear);
  }

  private updateDataDisplayed(
    yearlyData: YearlySystemVisualizationData,
    year: string,
  ) {
    const data = this.helperService.extractSelectedYearDataFromYearlyData(
      yearlyData,
      year,
    );
    this._data.next(data);
  }

  private updateSelectedYear(year: string) {
    this.selectedYear$.next(year);
  }

  private updateYearsOption(yearList: string[]) {
    this.yearsOption$.next(this.helperService.mapListToSelectOptions(yearList));
  }

  // Flag that determines if single or multi-node
  _isMultiNode: boolean;
  @Input() set isMultiNode(value: boolean) {
    this._isMultiNode = coerceBooleanProperty(value);
    this.handleOptions();
  }
  get isMultiNode() {
    return this._isMultiNode;
  }

  @Input() options: Array<{ label: string; value: number; tooltip?: string }>; // The view options

  activeView = new UntypedFormControl(0); // The control that determines the active view

  /**
   * Updates the multi-node flag based on the data
   */
  updateMultiNode() {
    this.isMultiNode =
      !!this.data &&
      !!this.data.overviewData &&
      !!this.data.overviewData.nodes &&
      this.data.overviewData.nodes.length > 1;
  }

  /**
   * Handles the available view options depending if single or multi-node
   */
  handleOptions() {
    let activeView, options;
    if (this.isMultiNode) {
      if (this.activeView.touched) return;
      options = [OVERVIEW_OPTION, NODE_VIEW_OPTION];
      activeView = 0;
    } else {
      options = [NODE_VIEW_OPTION];
      activeView = 1;
    }
    this.options = options;
    this.activeView.setValue(activeView);
  }

  /**
   * Handles element tapped event to set the active view and selected node
   *
   * @param data - the data definition of the tapped element
   */
  onElementTap(
    data: cytoscape.EdgeDataDefinition | cytoscape.NodeDataDefinition,
  ) {
    if (data.type === 'node') {
      this.activeView.setValue(1);
      this.helperService.setTappedNode(data.name);
    }
  }
}
