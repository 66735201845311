import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { ButtonBehavior } from './button.model';

@Component({
  selector: 'prosumer-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() link: string;
  @Input() color: ThemePalette;
  @Input() behavior: ButtonBehavior = 'submit';
  @Input() tooltip: string;
  @Input() tooltipDelay = 500;

  @Output() eyesClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(event: UIEvent): void {
    this.eyesClick.emit(event);
  }
}
