import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

import { Position } from '../../models/index';
import { FormFieldOption } from '../form-field/index';

@Component({
  selector: 'prosumer-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent implements OnInit {
  @Input() options: Array<FormFieldOption<any>>;
  @Input() label: string;
  @Input() labelTooltip: string;
  @Input() labelTooltipPosition: Position = 'right';
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() value: any;
  @Input() isViewOnly = false;

  @Output() emitter: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.value) {
      this.control.patchValue(this.value);
    }
  }

  onChange(event: MatRadioChange) {
    this.emitter.emit(event.value);
  }
}
