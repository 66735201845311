<div
  *ngIf="isSubmitting"
  fxLayoutAlign="center center"
  fxLayout="column"
  class="spinner-container"
>
  <spinner></spinner>
  <p class="message">
    {{ 'User.messages.submittingFeedback' | translate }} <br />
    {{ 'User.messages.appreciateFeedback' | translate }}
  </p>
</div>

<div class="form" [formGroup]="form">
  <h2 class="title" data-testid="feedback-title">
    {{ 'User.messages.feedbackHeader' | translate }}
  </h2>
  <span class="border"></span>
  <question
    formControlName="rate"
    [question]="'User.feedback.questions.rate' | translate"
    fieldType="rating"
    [required]="true"
    data-testid="feedback-rate"
  ></question>
  <question
    formControlName="appreciate"
    [question]="'User.feedback.questions.appreciateMost' | translate"
    [placeholder]="'User.feedback.placeholders.appreciateMost' | translate"
    [required]="true"
    data-testid="feedback-appreciate"
  ></question>
  <question
    formControlName="missing"
    [question]="'User.feedback.questions.missing' | translate"
    [placeholder]="'User.feedback.placeholders.missing' | translate"
    [required]="true"
    data-testid="feedback-missing"
  ></question>
  <question
    formControlName="comment"
    [question]="'User.feedback.questions.comment' | translate"
    [placeholder]="'User.feedback.placeholders.comment' | translate"
    data-testid="feedback-comment"
  ></question>
  <question
    formControlName="expertise"
    [question]="'User.feedback.questions.expertise' | translate"
    fieldType="option"
    [options]="expertiseOptions"
    [required]="true"
    data-testid="feedback-expertise"
  ></question>
  <question
    formControlName="includeUserInfo"
    [question]="'User.feedback.questions.includeUserInfo' | translate"
    fieldType="yes-no"
    [defaultYes]="true"
    data-testid="feedback-includeUserInfo"
  ></question>
  <button
    (click)="submit()"
    class="submit"
    [disabled]="form.invalid"
    data-testid="feedback-submit"
  >
    {{ 'User.labels.submit' | translate }}
  </button>
  <div class="action-container">
    <a
      href="javascript:void(0)"
      (click)="onClickAskMeLater()"
      data-testid="feedback-askMeLater"
    >
      {{ 'User.labels.askMeLater' | translate }}
    </a>
    <a
      href="javascript:void(0)"
      (click)="onClickDontAsk()"
      data-testid="feedback-doNotAskAgain"
    >
      {{ 'User.labels.doNotAskAgain' | translate }}
    </a>
  </div>
</div>
