import { CookieService } from 'ngx-cookie';
import {
  finalize,
  interval,
  Observable,
  skipUntil,
  Subject,
  take,
  throwError,
  timer,
} from 'rxjs';
import { filter, mergeMap, retryWhen, takeUntil } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { v4 as generateUuid } from 'uuid';

import {
  inject,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WebSocketConfig, WSMessage } from '@oculus/utils/models';
import {
  ENV_PRODUCTION,
  WEB_SOCKET_CONFIG,
  WEB_SOCKET_URL,
  WS_CONNECTION_TTL,
} from '@oculus/utils/tokens';

// import { NONCE_TOKEN } from '@oculus/auth/okta';
import { AuthStateService } from '../auth-state/auth-state.service';

export const NONCE_TOKEN = new InjectionToken<string | undefined>('nonce', {
  factory: () => {
    const cookie = inject(CookieService);
    return cookie.get('okta-oauth-nonce');
  },
});

const KEEP_ALIVE_INTERVAL_MS = 480000;
const MAX_RETRY_ATTEMPTS = 10;
const SCALING_DURATION_MS = 2000;

@UntilDestroy()
@Injectable()
export class WSNotificationService<T = NonNullable<unknown>> {
  protected webSocketSubject$: WebSocketSubject<T> | undefined;
  protected webSocketClosed$ = new Subject<boolean>();

  public messages$ = new Subject<T>();

  constructor(
    @Optional()
    @Inject(WEB_SOCKET_CONFIG)
    private webSocketConfig: WebSocketConfig,
    @Inject(WEB_SOCKET_URL) private webSocketUrl: string,
    @Inject(ENV_PRODUCTION) private production: boolean,
    @Inject(WS_CONNECTION_TTL) private ttl: number,
    @Inject(NONCE_TOKEN) private nonce: string,
    private authRepository: AuthStateService,
  ) {
    this.initWebSocketConnection();
    this.keepWebSocketConnectionAlive();
  }

  public sendMessage(message: T): void {
    this.authRepository.session$
      .pipe(
        filter((bool) => !!bool),
        take(1),
      )
      .subscribe(() => {
        if (!this.webSocketSubject$) {
          return;
        }
        this.logDebug('sendMessage');
        this.webSocketSubject$.next(message);
      });
  }

  protected initWebSocketConnection(): void {
    this.authRepository.tokens$
      .pipe(
        skipUntil(this.authRepository.session$),
        filter((auth) => !!auth?.accessToken && !!auth.idToken),
        mergeMap((auth) => {
          this.logDebug('initWebSocketConnection');
          this.webSocketSubject$ = webSocket<T>({
            url: this.generateAuthUrl(
              this.webSocketUrl,
              auth?.idToken as string,
              auth?.accessToken as string,
              (auth?.nonce as string) ?? this.nonce,
            ),
          });
          return this.webSocketSubject$;
        }),
        retryWhen(this.genericRetryStrategy()),
        untilDestroyed(this),
      )
      .subscribe((message) => {
        this.logDebug(message);
        this.messages$.next(message as T);
      }),
      (err: unknown) => console.error(err),
      () => {
        this.webSocketClosed$.next(true);
        this.webSocketClosed$.complete();
      };
  }

  protected generateAuthUrl(
    webSocketUrl: string,
    idToken: string,
    accessToken: string,
    nonce: string,
    ttl: number = this.ttl,
  ): string {
    return (
      `${webSocketUrl}?idToken=${idToken},${nonce}` +
      `&accessToken=${accessToken}` +
      `&x-correlation-id=${generateUuid()}` +
      `&ttl=${ttl}`
    );
  }

  protected keepWebSocketConnectionAlive(
    message: Partial<WSMessage> = { action: 'keep' },
  ): void {
    this.logDebug('keepWebSocketConnectionAlive');
    const keepAlive =
      this.webSocketConfig.keepAliveIntervalMs ?? KEEP_ALIVE_INTERVAL_MS;

    interval(keepAlive)
      .pipe(takeUntil(this.webSocketClosed$), untilDestroyed(this))
      .subscribe(() => this.sendMessage(message as T));
  }

  protected genericRetryStrategy =
    ({
      maxRetryAttempts = this.webSocketConfig?.maxRetryAttempts ??
        MAX_RETRY_ATTEMPTS,
      scalingDuration = this.webSocketConfig?.scalingDuration ??
        SCALING_DURATION_MS,
    }: {
      maxRetryAttempts?: number;
      scalingDuration?: number;
    } = {}) =>
    (attempts: Observable<unknown>) =>
      attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = ++i;
          if (retryAttempt > maxRetryAttempts) {
            return throwError(() => error);
          }

          this.logDebug(
            `Attempt ${retryAttempt}: retrying to connect with Web Socket ` +
              `in ${retryAttempt * scalingDuration}ms`,
          );

          return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => this.logDebug('Done retrying connection')),
      );

  protected logDebug(message: unknown) {
    if (!this.production) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug('Notification:', message);
    }
  }

  disconnect(): void {
    if (this.webSocketSubject$) {
      this.logDebug('DisconnectWebSocketConnection');
      this.webSocketSubject$.complete();
      this.webSocketClosed$.next(true);
      this.webSocketClosed$.complete();
    }
  }
}
