import {
  InputFile,
  LogFile,
  RunMap,
  Scenario,
} from 'prosumer-app/+scenario/models';

export type ScenarioState = Readonly<{
  loading?: boolean;
  data?: Scenario;
  error?: any;
  downloading?: boolean;

  latestSimulationList?: Array<any>;
  latestSimulationListLoading?: boolean;
  latestSimulationListError?: any;

  latestModifiedList?: Array<any>;
  latestModifiedListLoading?: boolean;
  latestModifiedListError?: any;

  listDrafts?: Array<any>;
  draftsError?: string;
  draftsLoading?: boolean;
}>;

export const mapToInputFile = (inputFile: any): InputFile => ({
  name: inputFile.name,
  size: inputFile.size,
  status: inputFile.status,
  url: inputFile.url,
  created: inputFile.createdAt,
  updated: inputFile.updatedAt,
});

export const mapToLogFile = (logFile: any): LogFile => ({
  name: logFile.name,
  key: logFile.key,
});

export const mapRunMaptoFrontend = (run: any): RunMap => ({
  status: run?.status || 'draft',
  launchDate: run?.launchDate,
  duration: run?.actualDuration,
  inputFile: run?.inputFile ? mapToInputFile(run.inputFile) : undefined,
  logFile: run?.logFile ? mapToLogFile(run.logFile) : undefined,
  messageFile: run?.messageFile ? mapToLogFile(run.messageFile) : undefined,
  systemFile: run?.systemFile ? mapToLogFile(run.systemFile) : undefined,
  outputValues: run?.outputValues,
  outputJsonVersion: run?.outputJsonVersion,
});

export const mapLatestScenarioToFrontEnd = (payload: any): Scenario => ({
  name: payload.name,
  id: payload.scenarioUuid,
  projectName: payload.projectName,
  projectId: payload.projectUuid,
  caseName: payload.caseName,
  caseId: payload.caseUuid,
  // createdDate: payload.createdAt,
  updatedDate: payload.updatedAt,
  launchDate: payload.launchDate,
  duration: payload.run?.actualDuration,
  run: mapRunMaptoFrontend(payload.run),
  outputFile: payload.run?.outputFile,
  logFile: payload.run?.logFile,
  simulationId: payload.run?.simulationId,
  scenarioType: payload.scenarioType,
  status: payload.run?.status || 'draft',
  globalTotalCost: payload.run?.outputValues?.globalTotalCost,
  globalFinalCO2Emissions: payload.run?.outputValues?.globalFinalCO2Emissions,
  mergeResults: payload.mergedResults,
});

export const mapCaseDetailsScenarioListToFrontEnd = (
  payload: any,
): Scenario => ({
  name: payload.name,
  id: payload.scenarioUuid,
  projectId: payload.projectUuid,
  caseId: payload.caseUuid,
  owner: payload.owner,
  updatedDate: payload.updatedAt,
  updatedBy: payload.updatedBy,
  createdDate: payload.createdAt,
  launchDate: payload.launchDate,
  outputFile: payload.run?.outputFile,
  run: mapRunMaptoFrontend(payload.run),
  scenarioType: payload.scenarioType,
  status: payload.run?.status || 'draft',
  inputFileGenerated: payload.inputFileGenerated,
  hasScenarioVariations: !!payload.hasScenarioVariations,
  duration: payload.run?.actualDuration,
  globalTotalCost: payload.run?.outputValues?.globalTotalCost,
  globalFinalCO2Emissions: payload.run?.outputValues?.globalFinalCO2Emissions,
  mergeResults: payload.mergedResults,
});
