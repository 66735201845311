import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TurbineListHolderService {
  turbinesData: TurbineData[] = undefined;

  setTurbineListData = (data: any) => {
    if (!!!data) {
      return;
    }

    this.turbinesData = data.WindTurbines.map(
      ({ name, capacity, hubHeight }) => ({
        name,
        value: name,
        capacity,
        hubHeight,
      }),
    );
  };

  getTurbineList = (): TurbineData[] => {
    if (!!!this.turbinesData) {
      return [];
    }
    return this.turbinesData;
  };
}

export interface TurbineData {
  name: string;
  value: string;
  capacity: string;
  hubHeight: string;
}
