import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { randomIntFromInterval } from 'prosumer-app/libs/eyes-shared';
import { PipeUtils, Utils } from 'prosumer-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ChartJSChart } from '../chartjs.base';
import { ChartOrientation, ColorShade } from '../chartjs.model';
import { CustomLegend } from '../custom-legend/custom-legend.model';
import { LineData } from '../line-chartjs';
import { BarDatum, StackedBarMeta } from './stacked-bar-chartjs.model';

@Component({
  selector: 'prosumer-stacked-bar-chartjs',
  templateUrl: './stacked-bar-chartjs.component.html',
  styleUrls: ['./stacked-bar-chartjs.component.scss'],
})
export class StackedBarChartjsComponent
  extends ChartJSChart<StackedBarMeta>
  implements OnInit, OnChanges
{
  private legendDataSubject = new BehaviorSubject<CustomLegend>(undefined);
  private lineDataSubject = new BehaviorSubject<LineData>(undefined);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly LINE_Y_AXIS_ID = 'lineYAxis';

  get legendDetails() {
    return this.legendDataSubject.getValue();
  }
  legendDetails$ = this.selectDelayedLegendDetails();

  @Input() enableXlsxExport = false;
  @Input() allData: any;
  @Input() xAxisName: string;
  @Input() yAxisName: string;
  @Input() set line(line: LineData) {
    this.lineDataSubject.next(line);
  }

  ngOnInit(): void {
    this.isCustomLegend = true;
  }

  ngOnChanges(): void {
    this.refresh();
  }

  initializeCustomLegend(barChart): void {
    this.legendDataSubject.next({
      legendItems:
        barChart.options.plugins.legend.labels.generateLabels(barChart),
      canvasId: barChart.canvas.id,
    });
  }

  getDemoData(): StackedBarMeta {
    const YEARS = ['2020', '2021', '2022'];
    return {
      axisTicks: YEARS,
      data: [
        {
          name: 'Sample Data',
          values: YEARS.reduce((acc, year) => {
            acc[year] = randomIntFromInterval(100, 1000);
            return acc;
          }, {}),
        },
      ],
    };
  }
  getDefaultID(): string {
    return 'stackedBarChart';
  }
  getChartType(): ChartType {
    return 'bar';
  }
  mapToChartJSData(data: StackedBarMeta): ChartData {
    return {
      labels: data.axisTicks,
      datasets: [...this.createBarDatasets(data), ...this.createLineDatasets()],
    };
  }
  getAdditionalChartOptions(data: StackedBarMeta): Partial<ChartOptions> {
    return {
      ...this.resolveIndexAxisOptions(data?.orientation),
      ...this.getScalesOptions(data),
    };
  }

  additionalAfterViewInit(): void {
    this.subscribeToLineDataForRefreshTrigerring();
  }

  onLegendDataToggle({ index }) {
    this.chartInstance.setDatasetVisibility(
      index,
      !this.isDatasetVisible(index),
    );
    this.chartInstance.update();
  }

  private selectDelayedLegendDetails(): Observable<CustomLegend> {
    return this.legendDataSubject.pipe(delay(0));
  }

  private createLineDatasets(): ChartDataset[] {
    const data = this.lineDataSubject.value;
    return data
      ? [
          {
            type: 'line',
            label: data.name,
            data: data.points,
            fill: false,
            borderColor: this.resolveColor(data.name),
            yAxisID: this.LINE_Y_AXIS_ID,
          },
        ]
      : [];
  }

  private subscribeToLineDataForRefreshTrigerring(): void {
    this.lineDataSubject
      .pipe(PipeUtils.filterOutUndefined, takeUntil(this.justDestroyed$))
      .subscribe(() => this.refresh());
  }

  private createBarDatasets(data: StackedBarMeta): ChartDataset[] {
    return data.data.map((d, idx) =>
      this.mapSingleDataToChartJSDataset(data, d, idx),
    );
  }

  private mapSingleDataToChartJSDataset(
    barMeta: StackedBarMeta,
    data: BarDatum,
    idx: number,
  ): ChartDataset {
    const ticks = Utils.removeDuplicates(barMeta.axisTicks);
    return {
      label: data.name,
      data: ticks.map((tick) => data.values[tick]),
      stack: data.group,
      backgroundColor: this.resolveBackgroundColor(barMeta, data, idx),
    };
  }

  private resolveBackgroundColor(
    barMeta: StackedBarMeta,
    data: BarDatum,
    idx: number,
  ): string {
    const color =
      barMeta.colors && barMeta.colors[data.name]
        ? barMeta.colors[data.name]
        : this.resolveBackgroundColorByIndex(idx);
    return !!color
      ? this.resolveWhetherShadedOrUnshadedColor(color, data.shade)
      : this.translucinizeColor(color);
  }

  private resolveBackgroundColorByIndex(idx: number): string {
    return this.resolveColorByIdx(idx);
  }

  private resolveWhetherShadedOrUnshadedColor(
    color: string,
    shade: ColorShade,
  ): string {
    return !!shade
      ? this.resolveColorShade(shade, color)
      : this.translucinizeColor(color);
  }

  private resolveColorShade(shade: ColorShade, color: string): string {
    const colorDict = {
      [ColorShade.DARKEN]: this.darkenColor(color),
      [ColorShade.LIGHTEN]: this.lightenColor(color),
    };
    return colorDict[shade];
  }

  private resolveIndexAxisOptions(
    orientation: ChartOrientation,
  ): Partial<ChartOptions> {
    return {
      indexAxis: orientation === ChartOrientation.HORIZONTAL ? 'y' : 'x',
    };
  }

  private getScalesOptions(data: StackedBarMeta): Partial<ChartOptions> {
    return {
      scales: {
        x: {
          stacked: true,
          title: { display: true, text: data.xAxisName || this.xAxisName },
        },
        y: {
          stacked: true,
          title: { display: true, text: data.yAxisName || this.yAxisName },
        },
        [this.LINE_Y_AXIS_ID]: {
          position: 'right',
          display: !!this.lineDataSubject.value,
          grid: { display: false },
          title: { display: true, text: data.rightYAxisName },
        },
      },
    };
  }
}
