import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMapTo } from 'rxjs/operators';

import { PageMode } from '../../models';
import { BaseFormComponent } from '../base-form';

export const LAST_STEP_INDEX = 8;
export const FIRST_STEP_INDEX = 0;
export const BUTTON_LABELS = {
  previous: 'Generic.labels.previous',
  cancel: 'Generic.labels.cancel',
  back: 'Generic.labels.back',
  next: 'Generic.labels.next',
  save: 'Generic.labels.save',
  create: 'Generic.labels.create',
  simulate: 'Generic.labels.simulate',
  yes: 'Generic.labels.yes',
  no: 'Generic.labels.no',
};

@Directive()
export abstract class StepperFormComponent<T>
  extends BaseFormComponent
  implements AfterViewInit
{
  @ViewChild(MatStepper) stepper: MatStepper;

  private _mode$ = new BehaviorSubject<PageMode>('read');
  private _validated$ = new BehaviorSubject<boolean>(false);
  private _selectedStepIndex$ = new BehaviorSubject<number>(FIRST_STEP_INDEX);

  private _saved$ = new BehaviorSubject<boolean>(true);
  private _dirty$ = new BehaviorSubject<boolean>(false);

  private _nextLabel$ = new BehaviorSubject<string>(BUTTON_LABELS.next);
  private _previousLabel$ = new BehaviorSubject<string>(BUTTON_LABELS.cancel);

  private _viewInitialized$ = new BehaviorSubject<boolean>(false);

  firstStepIndex = FIRST_STEP_INDEX;
  lastStepIndex = this.getLastStepIdx();
  buttonLabels = BUTTON_LABELS;

  /* Page and Stepper Observables */
  mode$ = this._mode$.asObservable().pipe(this.takeUntilShare());
  selectedStepIndex$ = this._selectedStepIndex$.pipe(this.takeUntilShare());
  viewInitialized$ = this._viewInitialized$
    .asObservable()
    .pipe(this.takeUntilShare());

  /* Form-related Observables */
  formValue$: Observable<T>;
  saved$ = this._saved$.asObservable().pipe(this.takeUntilShare());
  dirty$ = this._dirty$.asObservable().pipe(this.takeUntilShare());

  /* Nav Labels Observables */
  nextLabel$ = this._nextLabel$.asObservable().pipe(this.takeUntilShare());
  previousLabel$ = this._previousLabel$
    .asObservable()
    .pipe(this.takeUntilShare());

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  abstract defineForm(): any;

  abstract getLastStepIdx(): number;

  ngAfterViewInit() {
    this.formValue$ = this.form.valueChanges.pipe(this.takeUntilShare());
    this.dirty$
      .pipe(mergeMapTo(this.mode$))
      .subscribe((mode) => this.handleButtonLabels(mode));
    this.selectedStepIndex$.subscribe((stepIndex) =>
      this.handleStepIndex(stepIndex),
    );
    this.mode$.subscribe((mode) => this.handleButtonLabels(mode));
    // this.lastStepIndex = (this.stepper._steps || []).length - 1;
  }

  abstract onPrevious(data: any);
  abstract onNext(data: any);
  abstract mapToFormData(data: T): T;

  startFormValueListener() {
    this.formValue$
      .pipe(filter(() => !this.isDirty()))
      .subscribe(() => this.markAsDirty());
  }

  updateFormValue(data: T) {
    this.form.patchValue(this.mapToFormData(data), { emitEvent: false });
    this.markAsPristine();
    this.markAsSaved();
    if (!this.isViewInitialized()) {
      this.markViewAsInitialized();
      this.startFormValueListener();
    }
  }

  resetFormValue(data: T) {
    this.form.reset(this.mapToFormData(data));
    this.markAsPristine();
    this.markAsSaved();
  }

  goNext() {
    if (this.stepper) {
      setTimeout(() => this.stepper.next());
    }
  }

  goPrevious() {
    if (this.stepper) {
      setTimeout(() => this.stepper.previous());
    }
  }

  canChangeSteps(mode: PageMode): boolean {
    return mode === 'read' || (mode === 'update' && this.isPristine());
  }

  onChangeStep(change: StepperSelectionEvent) {
    this.changeSelectedStepIndex(change.selectedIndex);
  }

  onValidate(data: any) {
    if (!data.valid) {
      return;
    }
  }

  changeMode(mode: PageMode) {
    this._mode$.next(mode);
  }

  changeSelectedStepIndex(index: number) {
    this._selectedStepIndex$.next(index);
  }

  changeNextLabel(label: string) {
    this._nextLabel$.next(label);
  }

  changePreviousLabel(label: string) {
    this._previousLabel$.next(label);
  }

  getMode() {
    return this._mode$.value;
  }

  getSelectedStepIndex() {
    return this._selectedStepIndex$.value;
  }

  isDirty() {
    return this.form.dirty && this._dirty$.value;
  }

  isPristine() {
    return this.form.pristine && !this._dirty$.value;
  }

  isLastStep() {
    return this._selectedStepIndex$.value === this.lastStepIndex;
  }

  isSaved() {
    return this._saved$.value;
  }

  isViewInitialized() {
    return this._viewInitialized$.value;
  }

  isValid() {
    return this.form.valid;
  }

  markAsSaved() {
    if (!this.isSaved()) {
      this._saved$.next(true);
    }
  }

  markAsUnsaved() {
    if (this.isSaved()) {
      this._saved$.next(false);
    }
  }

  markAsDirty() {
    if (this.isPristine) {
      this.form.markAsDirty();
      this._dirty$.next(true);
    }
  }

  markAsPristine() {
    if (this.isDirty) {
      this.form.markAsPristine();
      this._dirty$.next(false);
    }
  }

  markViewAsInitialized() {
    if (!this.isViewInitialized()) {
      this._viewInitialized$.next(true);
    }
  }

  markAsValidated = () => this._validated$.next(true);

  markAsInvalidated = () => this._validated$.next(false);

  wasValidated = () => this._validated$.value;

  handleButtonLabels(mode: PageMode) {
    switch (mode) {
      case 'create':
        this.changeNextLabel(BUTTON_LABELS.create);
        return;
      case 'update':
        if (this.isDirty()) {
          this.changePreviousLabel(BUTTON_LABELS.cancel);
          this.changeNextLabel(BUTTON_LABELS.save);
        } else {
          if (this.getSelectedStepIndex() > this.firstStepIndex) {
            this.changePreviousLabel(BUTTON_LABELS.previous);
          } else {
            this.changePreviousLabel(BUTTON_LABELS.back);
          }
          this.changeNextLabel(
            this.getSelectedStepIndex() === this.lastStepIndex
              ? BUTTON_LABELS.simulate
              : BUTTON_LABELS.next,
          );
        }
        return;
      default:
        this.changeNextLabel(BUTTON_LABELS.next);
    }
  }

  handleStepIndex(stepIndex: number) {
    if (stepIndex > this.firstStepIndex) {
      this.changePreviousLabel(BUTTON_LABELS.previous);
    } else {
      this.changePreviousLabel(BUTTON_LABELS.back);
    }
    if (stepIndex === this.lastStepIndex) {
      this.changeNextLabel(BUTTON_LABELS.simulate);
    } else {
      this.handleButtonLabels(this.getMode());
    }
  }
}
