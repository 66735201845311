import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  Efficiency,
  EfficiencyMatrix,
  EnergyVector,
} from 'prosumer-app/+scenario';
import {
  BaseFormComponent,
  contains,
  fadeInAnimation,
  toList,
} from 'prosumer-app/libs/eyes-shared';
import { convertToYearlyValues } from 'prosumer-shared/utils';
import { BehaviorSubject, Observable, combineLatest, iif, of } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { MenuButtonOption } from '../menu-button/menu-button.model';
import {
  EV_TYPE,
  EfficiencyMatrixEnergyVectorMetadata,
  INPUT_TYPE,
  OUTPUT_TYPE,
  ReplacementEVEventModel,
} from './efficiency-matrix.model';

@Component({
  selector: 'prosumer-efficiency-matrix',
  templateUrl: './efficiency-matrix.component.html',
  styleUrls: ['./efficiency-matrix.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfficiencyMatrixComponent
  extends BaseFormComponent
  implements OnInit
{
  @Output() replaceEvSelection = new EventEmitter<ReplacementEVEventModel>();
  @Input() cellFlex = '170px';
  @Input() startYear;
  @Input() endYear;
  @Input() isViewOnly = false;

  /* Energy Vector Options (Selected) */
  _energyVectorOptions: Array<EnergyVector>;
  @Input() set energyVectorOptions(energyVectorOptions: Array<EnergyVector>) {
    if (!!energyVectorOptions) {
      this._energyVectorOptions = energyVectorOptions;
      this.generateMatrix();
    }
  }

  get energyVectorOptions() {
    return this._energyVectorOptions;
  }

  /**
   * Flag to override default delete energy vector functionality.
   */
  @Input() overrideDeleteEnergyVector = false;

  /**
   * Input for overriding the delete energy vector; if the override delete energy vector flag is false, do nothing.
   * The type of the value will determine the energy vector to delete.
   */
  @Input() set confirmDeleteEnergyVector(
    value: EfficiencyMatrixEnergyVectorMetadata,
  ) {
    const { type, energyVectorId } = value || {};
    if (!this.overrideDeleteEnergyVector) {
      return;
    }

    if (type === 'input') {
      this.removeInputEnergyVector(energyVectorId);
    }

    if (type === 'output') {
      this.removeOutputEnergyVector(energyVectorId);
    }
  }

  /**
   * The emitter that emits when input energy vector is deleted.
   */
  @Output() inputEnergyVectorDeleted = new EventEmitter<string>();

  /**
   * The emitter that emits when output energy vector is deleted.
   */
  @Output() outputEnergyVectorDeleted = new EventEmitter<string>();

  /* Managed Energy Vectors */
  inputEnergyVectors$ = new BehaviorSubject<Array<EnergyVector>>([]);
  outputEnergyVectors$ = new BehaviorSubject<Array<EnergyVector>>([]);

  inputEVOptions$ = new BehaviorSubject<Array<MenuButtonOption<string>>>([]);
  outputEVOptions$ = new BehaviorSubject<Array<MenuButtonOption<string>>>([]);

  rawEfficiencyMatrixValue: EfficiencyMatrix;

  allInputEVOptionSelected$ = this.inputEnergyVectors$.pipe(
    filter(() => !!this.energyVectorOptions),
    map(
      (selectedInputEVs) =>
        selectedInputEVs.length >=
        this.filterOurIsUnsaved(this.energyVectorOptions).length,
    ),
    this.takeUntilShare(),
  );

  allOutputEVOptionSelected$ = this.outputEnergyVectors$.pipe(
    filter(() => !!this.energyVectorOptions),
    map(
      (selectedOutputEVs) =>
        selectedOutputEVs.length >=
        this.filterOurIsUnsaved(this.energyVectorOptions).length,
    ),
    this.takeUntilShare(),
  );

  insufficientEVSelected$ = combineLatest([
    this.inputEnergyVectors$,
    this.outputEnergyVectors$,
  ]).pipe(
    map(
      ([inputEnergyVectors, outputEnergyVectors]) =>
        inputEnergyVectors.length + outputEnergyVectors.length <= 1,
    ),
    this.takeUntilShare(),
  );

  get inputEnergyVectorsLength() {
    return this.inputEnergyVectors$.value.length;
  }

  get outputEnergyVectorsLength() {
    return this.outputEnergyVectors$.value.length;
  }

  /* Form Controls */
  get efficienciesControl() {
    return (this.controls.efficiencies || {}) as UntypedFormGroup;
  }

  set efficienciesControl(value) {
    this.controls.efficiencies = value;
  }

  get mainEVInputControl() {
    return this.controls.mainEnergyVectorInput as UntypedFormControl;
  }

  get mainEVOutputControl() {
    return this.controls.mainEnergyVectorOutput as UntypedFormControl;
  }

  get energyVectorInArray() {
    return ((this.efficienciesControl.controls || {}).energyVectorInArray ||
      {}) as UntypedFormArray;
  }

  combinedEnergyVectorChange$ = combineLatest([
    this.inputEnergyVectors$,
    this.outputEnergyVectors$,
  ]).pipe(this.takeUntilShare());

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  startEnergyVectorSubscription(initValue?: EfficiencyMatrix) {
    let transformedInitValue;
    if (!!initValue) {
      transformedInitValue = {
        ...initValue,
        efficiencies: this.transformFromEfficiencyModel(initValue.efficiencies),
      };
      // this._logger.debug(
      //   'Transformed Value (From EfficiencyMatrix)',
      //   transformedInitValue,
      // );
    }

    this.combinedEnergyVectorChange$.subscribe(
      ([inputEnergyVectors, outputEnergyVectors]) => {
        const previousValue = transformedInitValue || this.form.value;
        transformedInitValue = undefined; // Clear initial value after applying
        this.createMainForm(
          inputEnergyVectors,
          outputEnergyVectors,
          previousValue,
        );
        this.changeDetector.markForCheck();
      },
    );
  }

  defineForm() {
    return this.defineFormWithEnergyVectors();
  }

  defineFormWithEnergyVectors(
    inputEnergyVectors: Array<EnergyVector> = [],
    outputEnergyVectors: Array<EnergyVector> = [],
  ) {
    const efficiencies =
      inputEnergyVectors.length &&
      inputEnergyVectors[0] &&
      outputEnergyVectors &&
      outputEnergyVectors[0]
        ? this.createEnergyVectorInGroup(
            inputEnergyVectors,
            outputEnergyVectors,
          )
        : this.formBuilder.group({ energyVectorInArray: [] });

    return {
      mainEnergyVectorInput: this.formBuilder.control(''),
      mainEnergyVectorOutput: this.formBuilder.control(''),
      efficiencies,
    };
  }

  evType: Record<string, EV_TYPE> = { input: INPUT_TYPE, output: OUTPUT_TYPE };

  ngOnInit() {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValidators([this.validate.bind(this)]);
      this.ngControl.control.updateValueAndValidity();
    }
  }

  createMainForm(
    inputEnergyVectors: Array<EnergyVector>,
    outputEnergyVectors: Array<EnergyVector>,
    value?: any,
  ) {
    this.form = this.formBuilder.group(
      this.defineFormWithEnergyVectors(inputEnergyVectors, outputEnergyVectors),
    );

    this.form.setAsyncValidators(
      this.validateSelectedEnergyVectors(
        this.inputEnergyVectors$,
        this.outputEnergyVectors$,
      ),
    );

    if (!!value) {
      const mainEnergyVectorInput = inputEnergyVectors.find(
        (v) => v && v.value === value.mainEnergyVectorInput,
      )
        ? value.mainEnergyVectorInput
        : '';
      const mainEnergyVectorOutput = outputEnergyVectors.find(
        (v) => v && v.value === value.mainEnergyVectorOutput,
      )
        ? value.mainEnergyVectorOutput
        : '';

      this.form.patchValue({
        mainEnergyVectorInput,
        mainEnergyVectorOutput,
        efficiencies: this.getValuesOfPreviousEfficiencies(
          value.efficiencies,
          this.form.value.efficiencies,
        ),
      });
    }

    this.form.valueChanges
      .pipe(startWith(this.form.value), this.takeUntil())
      .subscribe((formValue) => {
        const matrixValue = {
          ...formValue,
          efficiencies: this.transformToEfficiencyModel(
            (formValue.efficiencies || {}).energyVectorInArray || [],
          ),
        };
        this.onChange(matrixValue);
      });
  }

  private getValuesOfPreviousEfficiencies(prevEff: any, newEff: any) {
    if (!!!newEff.energyVectorInArray) {
      return newEff;
    }
    const copiedNew = JSON.parse(JSON.stringify(newEff));
    copiedNew.energyVectorInArray = copiedNew.energyVectorInArray.map((e) => {
      const { energyVectorIn } = e;
      let { energyVectorOutArray } = e;
      const previousInArray = !!!prevEff.energyVectorInArray
        ? undefined
        : prevEff.energyVectorInArray.find(
            (p) => p.energyVectorIn === energyVectorIn,
          );
      if (previousInArray) {
        const prevEnergyVectorOutArray = previousInArray.energyVectorOutArray;
        energyVectorOutArray = energyVectorOutArray.map((vectorOut) => {
          const prevOutValueIndex = prevEnergyVectorOutArray.find(
            (v) =>
              v.energyVectorIn === vectorOut.energyVectorIn &&
              v.energyVectorOut === vectorOut.energyVectorOut,
          );
          if (prevOutValueIndex) {
            return { ...prevOutValueIndex };
          }
          return { ...vectorOut };
        });
      }
      return {
        energyVectorIn,
        energyVectorOutArray,
      };
    });

    return copiedNew;
  }

  validateSelectedEnergyVectors(
    inputEnergyVectors$: Observable<Array<EnergyVector>>,
    outputEnergyVectors$: Observable<Array<EnergyVector>>,
  ): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      if (!inputEnergyVectors$ || !outputEnergyVectors$) {
        return of(null);
      }

      return combineLatest([inputEnergyVectors$, outputEnergyVectors$]).pipe(
        take(1),
        mergeMap(([inputEnergyVectors, outputEnergyVectors]) =>
          iif(
            () =>
              !!inputEnergyVectors &&
              !!outputEnergyVectors &&
              inputEnergyVectors.length > 0 &&
              outputEnergyVectors.length > 0 &&
              !!control.value.mainEnergyVectorInput &&
              !!control.value.mainEnergyVectorOutput,
            of(null),
            of({ required: control.value }),
          ),
        ),
      );
    };
  }

  createEnergyVectorInGroup(
    inputEnergyVectorOptions: Array<EnergyVector>,
    ouputEnergyVectorOptions: Array<EnergyVector>,
  ) {
    return this.formBuilder.group({
      energyVectorInArray: this.createEnergyVectorInArray(
        inputEnergyVectorOptions,
        ouputEnergyVectorOptions,
      ),
    });
  }

  createEnergyVectorInArray(
    inputEnergyVectorOptions: Array<EnergyVector>,
    ouputEnergyVectorOptions: Array<EnergyVector>,
  ) {
    const energyVectorInArray = this.formBuilder.array([]);
    inputEnergyVectorOptions.forEach((option) => {
      if (!option) return;
      energyVectorInArray.push(
        this.createEnergyVectorOutGroup(ouputEnergyVectorOptions, option.value),
      );
    });
    return energyVectorInArray;
  }

  createEnergyVectorOutGroup(
    ouputEnergyVectorOptions: Array<EnergyVector>,
    energyVectorIn: string,
  ) {
    return this.formBuilder.group({
      energyVectorIn: this.formBuilder.control(energyVectorIn),
      energyVectorOutArray: this.createEnergyVectorOutArray(
        ouputEnergyVectorOptions,
        energyVectorIn,
      ),
    });
  }

  createEnergyVectorOutArray(
    ouputEnergyVectorOptions: Array<EnergyVector>,
    energyVectorIn: string,
  ) {
    const energyVectorOutArray = this.formBuilder.array([]);
    ouputEnergyVectorOptions.forEach((option) =>
      energyVectorOutArray.push(
        this.createEfficiency({
          energyVectorIn,
          energyVectorOut: option.value,
        }),
      ),
    );
    return energyVectorOutArray;
  }

  createEfficiency(value?: any): UntypedFormGroup {
    const efficiency = this.formBuilder.group({
      energyVectorIn: this.formBuilder.control(null),
      energyVectorOut: this.formBuilder.control(null),
      value: this.formBuilder.control(
        convertToYearlyValues('0.00', this.startYear, this.endYear),
        [Validators.required],
      ),
    });

    if (!!value) {
      efficiency.patchValue(value);
    }
    return efficiency;
  }

  /**
   * Deletes the energy vector from the efficiency matrix.
   *
   * @param energyVectorId - the energy vector id to delete
   * @param energyVectorField - the energy vector field from the form
   * @param energyVectorsSubject - the energy vector subject to update for the efficiency matrix
   */
  removeEnergyVector(
    energyVectorId: string,
    energyVectorField: string,
    energyVectorsSubject: BehaviorSubject<EnergyVector[]>,
  ) {
    const mainEnergyVectorField = this.form.get(energyVectorField);
    if (mainEnergyVectorField?.value === energyVectorId) {
      mainEnergyVectorField?.patchValue('');
    }
    energyVectorsSubject?.next([
      //eslint-disable-next-line
      ...energyVectorsSubject?.value?.filter(
        ({ value }) => value !== energyVectorId,
      ),
    ]);
  }

  /**
   * Deletes the input energy vector from the efficiency matrix.
   *
   * @param energyVectorId - the input energy vector id to delete
   */
  removeInputEnergyVector(energyVectorId: string): void {
    this.removeEnergyVector(
      energyVectorId,
      'mainEnergyVectorInput',
      this.inputEnergyVectors$,
    );
    this.autoSelectMainInputEV();
    this.updateInputEVOptions();
  }

  /**
   * Deletes the output energy vector from the efficiency matrix.
   *
   * @param energyVectorId - the output energy vector id to delete
   */
  removeOutputEnergyVector(energyVectorId: string) {
    this.removeEnergyVector(
      energyVectorId,
      'mainEnergyVectorOutput',
      this.outputEnergyVectors$,
    );
    this.autoSelectMainOutputEV();
    this.updateOutputEVOptions();
  }

  /**
   * Event to bind when delete button for input energy vector is clicked from the efficiency matrix.
   *
   * @param energyVectorId - the input energy vector id to delete
   */
  onRemoveInputEnergyVector(energyVectorId: string): void {
    this.inputEnergyVectorDeleted.emit(energyVectorId);
    if (!this.overrideDeleteEnergyVector) {
      this.removeInputEnergyVector(energyVectorId);
    }
  }

  /**
   * Event to bind when delete button for ouput energy vector is clicked from the efficiency matrix.
   *
   * @param energyVectorId - the output energy vector id to delete
   */
  onRemoveOutputEnergyVector(energyVectorId: string) {
    this.outputEnergyVectorDeleted.emit(energyVectorId);
    if (!this.overrideDeleteEnergyVector) {
      this.removeOutputEnergyVector(energyVectorId);
    }
  }

  onSelectMainInput(id: string) {
    this.form.controls.mainEnergyVectorInput.patchValue(id);
  }

  onSelectMainOutput(id: string) {
    this.form.controls.mainEnergyVectorOutput.patchValue(id);
  }

  onSelectInputEVOption(selectedEV: EnergyVector) {
    this.inputEnergyVectors$.next([
      ...this.inputEnergyVectors$.value,
      selectedEV,
    ]);
    this.autoSelectMainInputEV(selectedEV.value);
    this.updateInputEVOptions();
  }

  onSelectOutputEVOption(selectedEV: EnergyVector) {
    this.outputEnergyVectors$.next([
      ...this.outputEnergyVectors$.value,
      selectedEV,
    ]);
    this.autoSelectMainOutputEV(selectedEV.value);
    this.updateOutputEVOptions();
  }

  transformToEfficiencyModel(
    energyVectorInArray: Array<any>,
  ): Array<Efficiency> {
    if (!!!energyVectorInArray || energyVectorInArray.length === 0) {
      return [];
    }
    return (energyVectorInArray || [])
      .map(
        (energyVectorIn) =>
          (energyVectorIn.energyVectorOutArray as Array<any>) || [],
      )
      .reduce((prev, next) => prev.concat(next));
  }

  transformFromEfficiencyModel(efficiencies: Array<Efficiency>) {
    if (!!!efficiencies) {
      return [];
    }

    const energyVectorInArray = toList(
      efficiencies.reduce((prev, next) => {
        prev[next.energyVectorIn] = {
          energyVectorIn: next.energyVectorIn,
          energyVectorOutArray: [
            ...((prev[next.energyVectorIn] || {}).energyVectorOutArray || []),
            {
              energyVectorIn: next.energyVectorIn,
              energyVectorOut: next.energyVectorOut,
              value: next.value,
            },
          ],
        };
        return prev;
      }, {}),
    );

    return {
      energyVectorInArray,
    };
  }

  writeValue(value: EfficiencyMatrix) {
    if (!value) {
      return;
    }

    this.rawEfficiencyMatrixValue = value;

    super.writeValue({
      ...value,
      efficiencies: this.transformFromEfficiencyModel(value.efficiencies),
    });

    this.generateMatrix();
  }

  getSelectedInputEnergyVectors(
    value: EfficiencyMatrix,
    energyVectorOptions: Array<EnergyVector> = this.energyVectorOptions,
  ) {
    const energyVectorInArray = Array.from(
      new Set(
        (value.efficiencies || []).map(
          (efficiency) => efficiency.energyVectorIn,
        ),
      ),
    );
    return energyVectorInArray.map((ev) =>
      energyVectorOptions.find((evOption) => ev === evOption.value),
    );
  }

  getSelectedOutputEnergyVectors(
    value: EfficiencyMatrix,
    energyVectorOptions: Array<EnergyVector> = this.energyVectorOptions,
  ) {
    const energyVectorOutArray = Array.from(
      new Set(
        (value.efficiencies || []).map(
          (efficiency) => efficiency.energyVectorOut,
        ),
      ),
    );
    return energyVectorOutArray.map((ev) =>
      energyVectorOptions.find((evOption) => ev === evOption.value),
    );
  }

  updateInputEVOptions(
    energyVectorOptions: Array<EnergyVector> = this.energyVectorOptions,
  ) {
    const options = this.filterEVOptions(
      energyVectorOptions,
      this.inputEnergyVectors$.value,
    );
    this.inputEVOptions$.next(this.filterOurIsUnsaved(options));
  }

  updateOutputEVOptions(
    energyVectorOptions: Array<EnergyVector> = this.energyVectorOptions,
  ) {
    const options = this.filterEVOptions(
      energyVectorOptions,
      this.outputEnergyVectors$.value,
    );
    this.outputEVOptions$.next(this.filterOurIsUnsaved(options));
  }

  autoSelectMainInputEV(value?: string) {
    if (this.inputEnergyVectors$.value.length === 1 && !!value) {
      this.mainEVInputControl.patchValue(value);
    } else if (!!!this.mainEVInputControl.value) {
      this.mainEVInputControl.patchValue(
        ([...this.inputEnergyVectors$.value].shift() || ({} as EnergyVector))
          .value,
      );
    }
  }

  autoSelectMainOutputEV(value?: string) {
    if (this.outputEnergyVectors$.value.length === 1 && !!value) {
      this.mainEVOutputControl.patchValue(value);
    } else if (!!!this.mainEVOutputControl.value) {
      this.mainEVOutputControl.patchValue(
        ([...this.outputEnergyVectors$.value].shift() || ({} as EnergyVector))
          .value,
      );
    }
  }

  filterEVOptions(
    sourceEVOptions: Array<EnergyVector>,
    filterEVOptions: Array<MenuButtonOption<string>>,
  ) {
    return sourceEVOptions
      .filter((option) =>
        filterEVOptions.length
          ? !contains(
              filterEVOptions
                .filter((d) => d)
                .map((filterEVOption) => filterEVOption.value),
              option.value,
            )
          : true,
      )
      .map<MenuButtonOption<string>>((filteredOption) => ({
        ...filteredOption,
        iconColor: 'primary',
        icon: this.getEnergyVectorIcon(filteredOption),
        svgIcon: true,
      }));
  }

  getEnergyVectorIcon(energyVector: EnergyVector) {
    return (
      energyVector.custom ? energyVector.type : energyVector.name
    ).toLowerCase();
  }

  generateMatrix(
    energyVectorOptions: Array<EnergyVector> = this.energyVectorOptions,
    rawValue: EfficiencyMatrix = this.rawEfficiencyMatrixValue,
  ) {
    if (!!energyVectorOptions && energyVectorOptions.length > 0 && !!rawValue) {
      const inputEnergyVectors = this.getSelectedInputEnergyVectors(rawValue);
      const outputEnergyVectors = this.getSelectedOutputEnergyVectors(rawValue);

      if (inputEnergyVectors.length && inputEnergyVectors[0]) {
        this.inputEnergyVectors$.next(inputEnergyVectors);
      }
      if (outputEnergyVectors.length && outputEnergyVectors[0]) {
        this.outputEnergyVectors$.next(outputEnergyVectors);
      }
      this.startEnergyVectorSubscription(rawValue);
    } else {
      this.startEnergyVectorSubscription();
    }

    this.updateInputEVOptions(energyVectorOptions || []);
    this.updateOutputEVOptions(energyVectorOptions || []);
  }

  private filterOurIsUnsaved(evList: any[]): EnergyVector[] {
    return evList.filter((ev) => !ev.isUnsaved);
  }

  onReplaceEV(data, type: EV_TYPE) {
    this.replaceEvSelection.emit({
      newId: data.newEv.value,
      oldId: data.oldEv.value,
      type: type,
    });
  }
}
