import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  BarVertical2DComponent,
  LegendPosition,
  ScaleType,
} from '@swimlane/ngx-charts';

@Component({
  selector: 'prosumer-charts-bar-vertical-2d-stacked',
  templateUrl: './bar-vertical-2d-stacked.component.html',
  styleUrls: ['./bar-vertical-2d-stacked.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*',
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
/**
 * Combined the following ngx-charts:
 * 1. Vertical Bar Grouped chart
 * 2. Vertical Bar Stacked chart
 * Sample data format:
 * [
 *  {
 *   'name': '2024',
 *   'series': [
 *       {'name': 'BAU',
 *         'series': [
 *             {
 *               'name': 'capex',
 *               'value': 4
 *             },
 *           {
 *             'name': 'opex',
 *             'value': 30
 *           }
 *           ]
 *       },
 *     ],
 *   },
 * {
 *   'name': '2025',
 *   'series': [
 *       {'name': 'BAU',
 *         'series': [
 *             {
 *               'name': 'capex',
 *               'value': 4
 *             },
 *           {
 *             'name': 'opex',
 *             'value': 30
 *           }
 *           ]
 *       },
 *     ],
 *   }
 *  ]
 */
export class BarVertical2dStackedComponent extends BarVertical2DComponent {
  // @Input() results: any;
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition = LegendPosition.Right;
  @Input() tooltipDisabled = false;
  @Input() scaleType = ScaleType.Ordinal;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() groupPadding = 16;
  @Input() barPadding = 8;
  @Input() roundDomains = false;
  @Input() roundEdges = false;
  @Input() showDataLabel = false;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();
  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  margin = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: any;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };

  /**
   * Retrieves the inner domain.
   */
  getInnerDomain() {
    const domain = [];
    Array.from(this.results || []).forEach((group) => {
      Array.from(group['series'] || []).forEach((subseries) => {
        const subSeriesName = subseries['name'] || '';
        if (!domain.includes(subSeriesName)) {
          domain.push(subSeriesName);
        }
      });
    });
    return domain;
  }

  /**
   * Retrieves the value domain of the inner series.
   */
  getValueDomain(): [number, number] {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    Array.from(this.results || []).forEach((group) => {
      Array.from(group['series'] || []).forEach((subseries) => {
        let smallestSum = 0;
        let biggestSum = 0;
        Array.from(subseries['series'] || []).forEach((d) => {
          const dValue = d['value'] || 0;
          if (dValue < 0) {
            smallestSum += dValue;
          } else {
            biggestSum += dValue;
          }
          smallest = dValue < smallest ? dValue : smallest;
          biggest = dValue > biggest ? dValue : biggest;
        });
        domain.push(smallestSum);
        domain.push(biggestSum);
      });
    });

    domain.push(smallest);
    domain.push(biggest);

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax
      ? Math.max(this.yScaleMax, ...domain)
      : Math.max(...domain);
    return [min, max];
  }

  /**
   * Tranform the inner group.
   *
   * @param group to be transformed
   */
  innerGroupTransform(group) {
    const retVal = `translate(${this.innerScale(group.name)}, 0)`;
    return retVal;
  }

  /**
   * Retrieves the list of inner series to include the group name and parent group name.
   *
   * @param group the parent of the series e.g {name: 'BAU', series[{name: 'opex', value: 15},
   * {name: 'capex', value: 20}]}
   * @param parentGroupName the parent name of the group e.g 2015
   * @return list of series, eg. [{name: 'BAU:opex', value:15, groupName: 'BAU', parentGroupName: '2015'},
   *                              {name: 'BAU:capex', value: 20, groupName: 'BAU', parentGroupName: '2015'}]
   */
  getInnerSeries(group: any, parentGroupName?: any) {
    const series = [];
    if (group) {
      const groupName = group.name;
      Array.from(group.series || []).forEach((inSeries: any) => {
        const newName = groupName + ':' + inSeries['name'];
        series.push({ ...inSeries, groupName, name: newName, parentGroupName });
      });
    }
    return series;
  }
}
