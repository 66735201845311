import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';

export const SCENARIO_NAME_DEFAULT_ID = 'basecase';

export interface EnergyVector extends FormFieldOption<string> {
  readonly selected?: boolean;
  readonly custom?: boolean;
  readonly type?: string;
  readonly typeId?: string;
}

export interface EnergyVectors {
  readonly predefined?: Array<EnergyVector>;
  readonly others?: Array<EnergyVector>;
}

export interface ScenarioVariation {
  readonly name?: string;
  readonly value?: string;
}

export interface GeneralForm {
  readonly name?: string;
  readonly wacc?: string;
  readonly energyVectors?: EnergyVectors;
  readonly scenarioVariations?: Array<ScenarioVariation>;
}

export interface EnergyVectorReq {
  readonly energyVectorId: string;
  readonly name: string;
  readonly isCustom: boolean;
  readonly vectorType?: string;
}
