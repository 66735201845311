import { NgModule, ModuleWithProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NOTIFICATION_STORE_FEATURE } from './notification-store.actions';
import { notificationStoreReducer } from './notification-store.reducer';
import { NotificationStoreService } from './notification-store.service';
import { NotificationStoreEffects } from './notification-store.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(
      NOTIFICATION_STORE_FEATURE,
      notificationStoreReducer,
    ),
    EffectsModule.forFeature([NotificationStoreEffects]),
  ],
})
export class NotificationStoreModule {
  static forRoot(): ModuleWithProviders<NotificationStoreModule> {
    return {
      ngModule: NotificationStoreModule,
      providers: [NotificationStoreService],
    };
  }
}
