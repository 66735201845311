import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipMeasurementsService {
  getWidthAdjustment(count: number) {
    let adj = 0;
    if (count > 600) {
      for (let c = count - 600; c > 0; c -= 50) {
        adj += 35;
        if (adj === 350) {
          return adj;
        }
      }
    }
    return adj;
  }
}
