import * as ace from 'ace-builds';
import { Coerce } from 'prosumer-app/core';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';

const DEFAULT_VALUE = '{}';
const ACE_ASSETS = '/assets/ace-builds/';

@Component({
  selector: 'prosumer-ace-editor',
  templateUrl: './ace-editor.component.html',
  styleUrls: ['./ace-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AceEditorComponent implements AfterViewInit {
  @ViewChild('editor') private editor: ElementRef<HTMLElement>;
  @Output() saveFile = new EventEmitter<unknown>();

  @Input() aceTheme = 'clouds';
  @Input() set aceValue(val: string) {
    val = Coerce.toString(val, DEFAULT_VALUE);
    this.value.update(() => this.formatValue(val));
  }
  private aceMode = 'json';
  private aceSearch = 'searchbox';

  private value = signal(DEFAULT_VALUE);
  private canInitValue = signal(false);
  private aceEditor: ace.Ace.Editor;

  constructor() {
    this.notifySetValue();
  }

  ngAfterViewInit(): void {
    const search = `ace/ext/${this.aceSearch}`;
    const theme = `ace/theme/${this.aceTheme}`;
    const mode = `ace/mode/${this.aceMode}`;
    ace.config.set('fontSize', '14px');
    // ace.config.set('basePath', ACE_ASSETS);
    ace.config.set('workerPath', ACE_ASSETS);
    ace.config.setModuleUrl(mode, `${ACE_ASSETS}mode-${this.aceMode}.js`);
    ace.config.setModuleUrl(theme, `${ACE_ASSETS}theme-${this.aceTheme}.js`);
    ace.config.setModuleUrl(search, `${ACE_ASSETS}ext-${this.aceSearch}.js`);
    this.aceEditor = ace.edit(this.editor.nativeElement, {
      displayIndentGuides: true,
      foldStyle: 'markbegin',
    });
    this.aceEditor.setTheme(theme);
    this.aceEditor.session.setMode(mode);
    this.aceEditor.renderer.attachToShadowRoot();
    this.aceEditor.execCommand('find');
    this.canInitValue.set(true);
  }

  onSubmitClick(): void {
    const editorValue = this.getEditorValue();
    this.saveFile.emit(editorValue);
  }

  onCancelClick(): void {
    this.setValue();
  }

  private notifySetValue(): void {
    effect(() => this.setValue());
  }

  private setValue(): void {
    if (!!this.canInitValue()) {
      this.aceEditor.setValue(this.value());
      this.aceEditor.selection.clearSelection();
    }
  }

  private getEditorValue(): unknown {
    const valueStr = this.aceEditor.getValue();
    const jsonObj = JSON.parse(valueStr);
    return jsonObj;
  }

  private formatValue(jsonObj: unknown): string {
    return JSON.stringify(jsonObj, null, '\t');
  }
}
