import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base-component';

type OnChangeFn = (value: unknown) => void;
type OnTouchedFn = () => void;

@Directive()
export abstract class BaseControlComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  static nextUniqueId = 0;

  private _value: unknown;
  get value(): unknown {
    return this._value;
  }
  @Input() set value(newValue: unknown) {
    if (newValue !== this._value) {
      this._value = newValue;
      this.stateChanges.next();
    }
  }

  private _id: string;
  get id(): string {
    return this._id;
  }
  @Input() set id(value: string) {
    this._id = Coerce.toString(value, this.uid);
    this.stateChanges.next();
  }

  private _required = false;
  get required(): boolean {
    return this._required;
  }
  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _focused = false;
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
  }

  private _disabled = false;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _readonly = false;
  get readonly(): boolean {
    return this._readonly;
  }
  @Input() set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  uid = `prosumer-base-control-${BaseControlComponent.nextUniqueId++}`;
  stateChanges = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
  ) {
    super();

    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  onChange: OnChangeFn = (value: unknown) => {};
  onTouched: OnTouchedFn = () => {};

  ngOnInit() {
    this.setDisabledState(this.disabled);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  writeValue(value: unknown): void {
    this.value = value;
    this.markForCheck();
    this.stateChanges.next();
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
    this.stateChanges.next();
  }

  markForCheck() {
    if (this.changeDetector) {
      this.changeDetector.markForCheck();
    }
  }
}
