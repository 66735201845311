import { Scenario } from 'prosumer-app/+scenario';
import {
  EXECUTION_STATUS_SUCCESS,
  EXECUTION_STATUS_EDITABLE,
  SCENARIO_TYPE,
} from 'prosumer-app/app.references';
import { Utils } from 'prosumer-app/core/utils/utils';

export const hasResultFile = (scenario: Scenario): boolean =>
  hasMergedOutputFile(scenario) ||
  Object.keys(Utils.resolveToEmptyObject(scenario.outputFile)).length > 0;

export const isResultsDownloadable = (scenario: Scenario): boolean =>
  [
    hasResultFile(scenario),
    scenario.copyStatus === undefined,
    scenario.downloading === undefined,
  ].every(Boolean);

export const isSimulationSuccessful = ({ status }) =>
  EXECUTION_STATUS_SUCCESS.includes(status);

export const isScenarioEditable = ({ status, scenarioType }) =>
  EXECUTION_STATUS_EDITABLE.includes(status) &&
  scenarioType !== SCENARIO_TYPE.UPLOADED;

export const hasMergedOutputFile = (scenario: Scenario): boolean =>
  Object.keys(Utils.resolveToEmptyObject(scenario.mergeResults?.outputFile))
    .length > 0;
