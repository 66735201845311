<ng-container *ngIf="!hasCustomTemplate">
  <button
    mat-button
    color="primary"
    [matMenuTriggerFor]="menu"
    [disabled]="disabled"
  >
    <mat-icon
      aria-hidden="false"
      *ngIf="svgIcon; else showDefaultIcon"
      [svgIcon]="icon"
      [color]="iconColor"
    ></mat-icon>
    <ng-template #showDefaultIcon>
      <mat-icon aria-hidden="false" *ngIf="icon" [color]="iconColor">{{
        icon
      }}</mat-icon>
    </ng-template>
    <span>{{ label }}</span>
  </button>
</ng-container>

<ng-container *ngIf="hasCustomTemplate">
  <span id="menuLocationPlaceHolder" [matMenuTriggerFor]="menu"></span>
  <span #customMenuTrigger data-testid="customMenuTrigger">
    <ng-content></ng-content>
  </span>
</ng-container>

<mat-menu
  #menu="matMenu"
  class="prosumer-menu-button-panel"
  yPosition="below"
  data-testid="menu-button-panel"
>
  <button
    mat-menu-item
    *ngFor="let option of options"
    (click)="onClick(option)"
    [attr.data-testid]="'prosumer-menu-button-' + option.name.toLowerCase()"
  >
    <mat-icon
      aria-hidden="false"
      *ngIf="option.svgIcon; else showDefaultOptionIcon"
      [svgIcon]="option.icon"
      [color]="option.iconColor"
    ></mat-icon>
    <ng-template #showDefaultOptionIcon>
      <mat-icon
        aria-hidden="false"
        *ngIf="option.icon"
        [color]="option.iconColor"
        >{{ option.icon }}</mat-icon
      >
    </ng-template>
    <span>{{ option.name }}</span>
  </button>
</mat-menu>
