import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class YearlyLoadsValidator {
  static commaExistance =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const hasComma = control.value.indexOf(',') >= 0;
      return hasComma ? { commaExist: hasComma } : null;
    };

  static yearlyValuesMin =
    (minValue: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let valid = true;
      if (typeof control.value === 'object' && !!control.value) {
        valid = Object.values(control.value).every(
          (val) => Number(val) >= minValue,
        );
      } else {
        valid = control.value >= 0;
      }
      return valid ? null : { min: true };
    };

  static yearlyValuesIsAnyNull =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let valid = true;
      if (typeof control.value === 'object' && !!control.value) {
        valid = Object.values(control.value).every(
          (val) => !(val === undefined || val === null),
        );
      } else {
        valid = !(control.value === undefined || control.value === null);
      }
      return valid ? null : { required: true };
    };
}
