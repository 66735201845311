import { curveStep } from 'd3-shape';
import { Coerce } from 'prosumer-app/core/utils';
import { BaseChartComponent, getKeys } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-shared/models';

import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { YearlySparklineData } from './yearly-sparkline.model';

const DEFAULT_CHART_WIDTH = 95;
const DEFAULT_CHART_HEIGHT = 20;
const DEFAULT_SMALL_CHART_WIDTH = 75;

@Component({
  selector: 'prosumer-yearly-sparkline',
  templateUrl: './yearly-sparkline.component.html',
  styleUrls: ['./yearly-sparkline.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearlySparklineComponent
  extends BaseChartComponent<Array<YearlySparklineData>, YearlyValues>
  implements OnChanges
{
  @Input() floatPrecisionDisplayed = 2;
  lineCurve = curveStep; // The line chart curve
  showLegend = false;
  displayedValues: Array<number | string> = [0, 0];
  /**
   * maybe have an option/button to switch between the options
   * of showing the first-last values and the min-max values
   */
  // displayedValuesType: string = 'minMax' //or 'firstLast';

  /**
   * The dictionary of number values where the keys represent unique years
   */
  @Input() set yearlyValuesMap(value: YearlyValues) {
    this.chartData = this.mapToChartData(value);
  }

  // turn TRUE when called in sparkline-table.component to decrease the size of the chart and add class
  @Input() smallerChart = false;
  @HostBinding('class') get class() {
    if (this.smallerChart) {
      return 'smaller-sparkline-component';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // can put condition to toggle between findMinMaxValues()
    this.displayedValues = this.findFirstLastValues();
  }

  /**
   * Maps the yearly values dictionary to a sparkline compatible data
   *
   * @param value - the yearly values
   */
  mapToChartData(value: YearlyValues): Array<YearlySparklineData> {
    if (!value) {
      return [];
    }
    const segments = this.getSegments(value);
    return segments.map((series) => ({
      name: this.yAxisLabel,
      series: series.map((year) => ({
        value: this.getValue(value, year),
        name: year,
      })),
    }));
    // return [
    //   {
    //     name: this.yAxisLabel,
    //     series: getKeys(value)?.map((year) => ({
    //       name: year,
    //       value: this.getValue(value, year),
    //     })),
    //   },
    // ];
  }

  private getSegments(values: YearlyValues): [string, string][] {
    const sortedYearKeys = getKeys(values).sort();
    return sortedYearKeys
      .map((item, index): [string, string] => {
        if (index === 0) {
          return ['', ''];
        }
        return [sortedYearKeys[index - 1], item];
      })
      .slice(1);
  }

  calculateWidth() {
    return this.smallerChart ? DEFAULT_SMALL_CHART_WIDTH : DEFAULT_CHART_WIDTH;
  }

  calculateHeight() {
    return DEFAULT_CHART_HEIGHT;
  }

  findFirstLastValues(): Array<number | string> {
    return this.chartData
      .filter((datum, index) => [0, this.chartData.length - 1].includes(index))
      .map((datum, index) => datum.series[index].value);
    // const data = this.chartData[0]?.series;
    // if (data && data.length > 0) {
    //   return [data[0].value, data[data.length - 1].value];
    // }

    // return [];
  }

  private getValue(
    yearlyData: Record<string, string>,
    year: string,
  ): number | string {
    const yearData = yearlyData[year];
    const value = Number.parseFloat(yearData);
    if (Number.isNaN(value)) {
      return Coerce.toString(yearData);
    }
    return value;
  }

  // NOT USED ATM
  /*   findMinMaxValues(): Array<number> {
    const data = this.chartData[0].series;
    let min: number = data[0].value;
    let max: number = data[0].value;
    data.forEach((item) => {
      max = item.value > max ? item.value : max;
      min = item.value < min ? item.value : min;
    });
    return [min, max];
  } */
}
