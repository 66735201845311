import { UntypedFormGroup } from '@angular/forms';

import { ProfileComputationType, RenewableProfile } from '../models';

export function createRenewableProfileSubmitData(
  form: UntypedFormGroup,
  parameterType: string,
  parameterUnit: string,
) {
  const controls = form.controls;
  return {
    ...form.value,
    parameterType,
    parameterUnit,
    coordinates: {
      longitude: controls.longitude.value,
      latitude: controls.latitude.value,
    },
    historicalYears:
      controls.computeType.value === ProfileComputationType.HISTORICAL
        ? controls.historicalYears.value
        : undefined,
    lastHistoricalYear:
      controls.computeType.value === ProfileComputationType.TMY
        ? controls.lastHistoricalYear.value
        : undefined,
    numberOfYears:
      controls.computeType.value === ProfileComputationType.TMY
        ? controls.numberOfYears.value
        : undefined,
    percentiles:
      controls.computeType.value === ProfileComputationType.PERCENTILE
        ? controls.percentiles.value
        : undefined,
  };
}

export function transformCoordinates(coordinates: {
  longitude: string;
  latitude: string;
}) {
  return `Longitude: ${coordinates.longitude}\nLatitude: ${coordinates.latitude}`;
}

export function transformComputationData(profile: RenewableProfile) {
  return profile.computeType === 'historical'
    ? `List of Years : ${profile.historicalYears}`
    : profile.computeType === 'tmy'
      ? `Number of Years: ${profile.numberOfYears}\nLast Historical Year: ${profile.lastHistoricalYear}`
      : `List of Percentiles: ${profile.percentiles}`;
}
