import { InjectionToken } from '@angular/core';

import { FluidConfig } from './models';

// The injection token for system visualization color map for the fluids (energy vector)
export const SYS_VIS_FLUID_CONFIG = new InjectionToken<FluidConfig>(
  'sys-vis-fluid-config',
);

// The injection token for pre-defined label map for the fluids (energy vector)
export const PREDEFINED_FLUID_LABEL_CONFIG = new InjectionToken<{
  [key: string]: string;
}>('predefined-ev-label-config');

// The injection token for custom color pallet for the fluids (energy vector)
export const CUSTOM_FLUID_COLOR_PALETTE = new InjectionToken<Array<string>>(
  'custom-fluid-color-palette',
);

/*
 * Defaults (injection default value and unit testing usage)
 */
export const DEFAULT_SYS_VIS_FLUID_CONFIG: FluidConfig = {
  electricity: {
    color: '#f9a825',
    icon: 'electricity',
    name: 'electricity',
  },
  hydrogen: {
    color: '#7b1fa2',
    icon: 'hydrogen',
    name: 'hydrogen',
  },
  heating: {
    color: '#d32f2f',
    icon: 'heating',
    name: 'heating',
  },
  gas: {
    color: '#303f9f',
    icon: 'gas',
    name: 'gas',
  },
  diesel: {
    color: '#00796b',
    icon: 'diesel',
    name: 'diesel',
  },
  cold: {
    color: '#0288d1',
    icon: 'cold',
    name: 'cold',
  },
  biomass: {
    color: '#388e3c',
    icon: 'biomass',
    name: 'biomass',
  },
};

export const DEFAULT_PREDEFINED_FLUID_LABEL_CONFIG = {
  electricity: 'electricity',
  hydrogen: 'hydrogen',
  heating: 'heat',
  gas: 'natural gas',
  diesel: 'diesel',
  others: 'others',
  cold: 'cold',
  biomass: 'biomass',
};

export const DEFAULT_CUSTOM_FLUID_COLOR_PALETTE = [
  '#ff5722',
  '#9575cd',
  '#4fc3f7',
  '#81c784',
  '#e57373',
  '#ffb74d',
  '#009688',
  '#4a148c',
  '#aeea00',
  '#880e4f',
  '#002F4D',
  '#5d4037',
  '#72a5cc',
  '#4caf50',
  '#ffb74d',
  '#0277bd',
  '#ba68c8',
  '#ff8a80',
  '#673ab7',
  '#ffd600',
  '#00e5ff',
  '#827717',
];
