import { Dictionary, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BaseEntity, BaseEntityState } from '../models';
import { contains } from '../utilities';
import * as Actions from './state.actions';

/**
 * T = State Class
 * U = Entity Class
 */
export class StateFactory<T extends BaseEntityState<U>, U extends BaseEntity> {
  public featureState = createFeatureSelector<T>(this.feature);
  public adapter = this.createAdapter(this.idField, this.compareField);
  public queries = this.createBaseSelectors();
  public initialState = this.createInitialState(this._initialState);

  constructor(
    public feature: string,
    public idField: string,
    public compareField: string,
    private _initialState: T,
  ) {}

  createReducer(state: T, action: Actions.AllActions<U> | any) {
    switch (action.type) {
      case Actions.ActionTypes(this.feature).CLEAR_STATE:
        return this.initialState;

      // Create
      case Actions.ActionTypes(this.feature).CREATE:
        return Object.assign({}, state, { creating: true, error: undefined });
      case Actions.ActionTypes(this.feature).CREATE_FAILURE:
        return Object.assign({}, state, {
          creating: false,
          error: action.payload.message,
        });
      case Actions.ActionTypes(this.feature).CREATE_SUCCESS:
        return this.adapter.upsertOne(
          Object.assign({}, action.payload.data, { loaded: true }),
          Object.assign({}, state, { creating: false }),
        );

      // Get
      case Actions.ActionTypes(this.feature).GET:
        return this.adapter.upsertOne(
          { id: action.payload.id, loading: true, error: undefined } as U,
          state,
        );
      case Actions.ActionTypes(this.feature).GET_FAILURE:
        return this.adapter.updateOne(
          {
            id: action.payload.id,
            changes: { loading: false, error: action.payload.message } as U,
          },
          state,
        );
      case Actions.ActionTypes(this.feature).GET_SUCCESS:
        return this.adapter.updateOne(
          {
            id: action.payload.id,
            changes: Object.assign({}, action.payload.resultData, {
              loading: false,
              loaded: true,
            } as U),
          },
          state,
        );

      // Get List
      case Actions.ActionTypes(this.feature).GET_LIST:
        return Object.assign({}, state, { loading: true, error: undefined });
      case Actions.ActionTypes(this.feature).GET_LIST_FAILURE:
        return Object.assign({}, state, {
          loading: false,
          error: action.payload.message,
        });
      case Actions.ActionTypes(this.feature).GET_LIST_SUCCESS:
        return this.adapter.upsertMany(
          action.payload.resultIds
            .filter((resultId) => !contains(state.ids, resultId))
            .map((id) => ({ id, loading: true, error: undefined } as U)),
          Object.assign({}, state, { loading: false, loaded: true }),
        );
      // return Object.assign({}, state, { loading: false, loaded: true });
      // Update
      case Actions.ActionTypes(this.feature).UPDATE:
        return this.adapter.updateOne(
          {
            id: action.payload.data.id,
            changes: { updating: true, error: undefined } as U,
          },
          state,
        );
      case Actions.ActionTypes(this.feature).UPDATE_FAILURE:
        return this.adapter.updateOne(
          {
            id: action.payload.data.id,
            changes: { updating: false } as U,
          },
          state,
        );
      case Actions.ActionTypes(this.feature).UPDATE_SUCCESS:
        return this.adapter.updateOne(
          {
            id: action.payload.data.id,
            changes: Object.assign({}, action.payload.data, {
              updating: false,
            }),
          },
          state,
        );

      // Delete
      case Actions.ActionTypes(this.feature).DELETE:
        return this.adapter.updateOne(
          {
            id: action.payload.data.id,
            changes: { deleting: true, error: undefined } as U,
          },
          state,
        );
      case Actions.ActionTypes(this.feature).DELETE_FAILURE:
        return this.adapter.updateOne(
          {
            id: action.payload.data.id,
            changes: { deleting: false, error: action.payload.message } as U,
          },
          state,
        );
      case Actions.ActionTypes(this.feature).DELETE_SUCCESS:
        return this.adapter.removeOne(action.payload.data.id, state);

      // Select ID
      case Actions.ActionTypes(this.feature).SELECT_ID:
        return Object.assign({}, state, { selectedId: action.payload.id });

      default:
        return state;
    }
  }

  createAction(type: Actions.Type, payload?: any) {
    switch (type) {
      case Actions.Type.CLEAR_STATE:
        return new Actions.ClearState(this.feature);
      case Actions.Type.CREATE:
        return new Actions.Create<U>(this.feature, payload);
      case Actions.Type.CREATE_FAILURE:
        return new Actions.CreateFailure<U>(this.feature, payload);
      case Actions.Type.CREATE_SUCCESS:
        return new Actions.CreateSuccess<U>(this.feature, payload);
      case Actions.Type.GET:
        return new Actions.Get(this.feature, payload);
      case Actions.Type.GET_FAILURE:
        return new Actions.GetFailure(this.feature, payload);
      case Actions.Type.GET_SUCCESS:
        return new Actions.GetSuccess<U>(this.feature, payload);
      case Actions.Type.GET_LIST:
        return new Actions.GetList(this.feature, payload);
      case Actions.Type.GET_LIST_FAILURE:
        return new Actions.GetListFailure(this.feature, payload);
      case Actions.Type.GET_LIST_SUCCESS:
        return new Actions.GetListSuccess(this.feature, payload);
      case Actions.Type.DELETE:
        return new Actions.Delete<U>(this.feature, payload);
      case Actions.Type.DELETE_FAILURE:
        return new Actions.DeleteFailure<U>(this.feature, payload);
      case Actions.Type.DELETE_SUCCESS:
        return new Actions.DeleteSuccess<U>(this.feature, payload);
      case Actions.Type.UPDATE:
        return new Actions.Update<U>(this.feature, payload);
      case Actions.Type.UPDATE_FAILURE:
        return new Actions.UpdateFailure<U>(this.feature, payload);
      case Actions.Type.UPDATE_SUCCESS:
        return new Actions.UpdateSuccess<U>(this.feature, payload);
      case Actions.Type.SELECT_ID:
        return new Actions.SelectId(this.feature, payload);
    }
  }

  createQueries(selectors = {}) {
    return {
      ...this.queries,
      ...selectors,
    };
  }

  protected createBaseSelectors(selectors = {}) {
    const { selectAll, selectTotal, selectIds, selectEntities } =
      this.adapter.getSelectors();
    const getDataList = createSelector(this.featureState, selectAll);
    const getDataTotal = createSelector(this.featureState, selectTotal);
    const getDataIds = createSelector(this.featureState, selectIds);
    const getDataMap = createSelector(this.featureState, selectEntities);
    const getGeneralError = createSelector(
      this.featureState,
      (state: T) => state.error,
    );
    const getSelectedId = createSelector(
      this.featureState,
      (state: T) => state.selectedId,
    );
    const isLoadingList = createSelector(
      this.featureState,
      (state: T) => state.loading,
    );
    const isLoadedList = createSelector(
      this.featureState,
      (state: T) => state.loaded,
    );
    const isCreating = createSelector(
      this.featureState,
      (state: T) => state.creating,
    );

    const getSelectedData = createSelector(
      getDataMap,
      getSelectedId,
      (dataMap: Dictionary<U>, id: string) => dataMap[id],
    );
    const getDataById = createSelector(
      getDataMap,
      (dataMap: Dictionary<U>, id: string) => dataMap[id],
    );

    this.queries = {
      getFeatureState: this.featureState,
      getDataList,
      getDataMap,
      getDataTotal,
      getDataIds,
      getGeneralError,
      getSelectedId,
      getSelectedData,
      getDataById,
      isLoadingList,
      isLoadedList,
      isCreating,
      ...selectors,
    };

    return this.queries;
  }

  protected createAdapter(
    idField: string,
    compareField: string,
  ): EntityAdapter<U> {
    return createEntityAdapter<U>({
      selectId: (data: U) => data[idField],
      sortComparer: (a: U, b: U) => {
        if (!a || !a[compareField] || !b || !b[compareField]) {
          return 0;
        }
        return a[compareField].localeCompare(b[compareField]);
      },
    });
  }

  protected createInitialState(initState: T): T {
    return this.adapter.getInitialState(initState);
  }
}
