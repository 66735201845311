import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ClearFetchedUser,
  FetchUserByEmailAction,
  FetchUserByIDAction,
  UserAcceptGDPRAction,
  UserGetGDPRAction,
} from './user.actions';
import { GDPR, OldUser, UserInfo, OldUserState } from './user.model';
import { userSelectors } from './user.reducer';

@Injectable()
export class UserFacade {
  state$: Observable<OldUserState> = this._store$.pipe(
    select(userSelectors.getUserState),
  );

  userInfo$: Observable<UserInfo> = this._store$.pipe(
    select(userSelectors.getUserInfo),
  );
  userInfoLoading$: Observable<boolean> = this._store$.pipe(
    select(userSelectors.isUserInfoLoading),
  );

  gdpr$: Observable<GDPR> = this._store$.pipe(select(userSelectors.getGDPR));
  gdprLoading$: Observable<boolean> = this._store$.pipe(
    select(userSelectors.isGDPRLoading),
  );
  gdprAcceptedVersion$: Observable<string> = this._store$.pipe(
    select(userSelectors.getGDPRAcceptedVersion),
  );
  gdprLatestVersion$: Observable<string> = this._store$.pipe(
    select(userSelectors.getGDPRLatestVersion),
  );

  isFetching$: Observable<boolean> = this._store$.pipe(
    select(userSelectors.isFetching),
  );
  fetchUser$: Observable<OldUser> = this._store$.pipe(
    select(userSelectors.fetchUser),
  );

  constructor(private _store$: Store<OldUserState>) {}

  getGDPRPolicy(): void {
    this._store$.dispatch(new UserGetGDPRAction());
  }

  acceptGDPRPolicy(latestVersionToAccept: string): void {
    this._store$.dispatch(new UserAcceptGDPRAction(latestVersionToAccept));
  }

  fetchUserByEmail(emailAddress: string): void {
    this._store$.dispatch(new FetchUserByEmailAction(emailAddress));
  }

  fetchUserById(id: string): void {
    this._store$.dispatch(new FetchUserByIDAction(id));
  }

  clearFetchedUser(): void {
    this._store$.dispatch(new ClearFetchedUser());
  }
}
