import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

/**
 * compute for the start and end date hour difference
 *
 * @param start - start date
 * @param end - end date
 */
@Pipe({ name: 'datestoHour' })
export class DatesToHourPipe implements PipeTransform {
  transform(start, end): number {
    if (start && end) {
      const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS+HHmm';
      start = moment(start).format(DEFAULT_DATE_FORMAT);
      end = moment(end).format(DEFAULT_DATE_FORMAT);

      const date1 = new Date(start).getTime();
      const date2 = new Date(end).getTime();
      const msec = Math.abs(date2 - date1);
      const diffDays = Math.floor(msec / 86400000) + 1;
      return diffDays * 24;
    }
    return 0;
  }
}
