import { curveStep } from 'd3-shape';
import { Coerce } from 'prosumer-app/core/utils';
import { BaseChartComponent, getKeys } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-shared/models';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { YearlyLineChartData } from './yearly-line-chart.model';

const DEFAULT_CHART_WIDTH = 800;
const DEFAULT_CHART_HEIGHT = 400;

@Component({
  selector: 'prosumer-yearly-line-chart',
  templateUrl: './yearly-line-chart.component.html',
  styleUrls: ['./yearly-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyLineChartComponent extends BaseChartComponent<
  Array<YearlyLineChartData>,
  YearlyValues
> {
  lineCurve = curveStep; // The line chart curve
  showLegend = false;

  /**
   * The dictionary of number values where the keys represent unique years
   */
  @Input() set yearlyValuesMap(value: YearlyValues) {
    this.chartData = this.mapToChartData(value);
  }

  /**
   * Maps the yearly values dictionary to a line-chart compatible data
   *
   * @param value - the yearly values
   */
  mapToChartData(value: YearlyValues): Array<YearlyLineChartData> {
    const segments = this.getSegments(value);
    return segments.map((series) => ({
      name: this.yAxisLabel,
      series: series.map((year) => ({
        value: this.getValue(value, year),
        name: year,
      })),
    }));
    // return [
    //   {
    //     name: this.yAxisLabel,
    //     series: getKeys(value).map((year) => ({
    //       name: year,
    //       value: this.getValue(value, year),
    //     })),
    //   },
    // ];
  }

  private getSegments(values: YearlyValues): [string, string][] {
    const sortedYearKeys = getKeys(values).sort();
    let segment = [];
    return sortedYearKeys.reduce((results, yearKey) => {
      const year = Number(yearKey);
      segment.push(yearKey);
      // compare with previous segment value
      if (this.isLastValueOfSegment(values, year)) {
        results.push(segment);
        segment = [];
      }
      return results;
    }, []);
    // return sortedYearKeys
    //   .map((item, index): [string, string] => {
    //     if (index === 0) {
    //       return ['', ''];
    //     }
    //     return [sortedYearKeys[index - 1], item];
    //   })
    //   .slice(1);
  }

  private isLastValueOfSegment(values: YearlyValues, year: number): boolean {
    const nextValue = values[year + 1];
    const currValue = values[year];
    return [
      !this.isNaN(nextValue),
      this.isNaN(nextValue) !== this.isNaN(currValue),
    ].some(Boolean);
  }
  private isNaN(value: string | number): boolean {
    return ![null, undefined, ''].includes(value as string);
  }

  calculateWidth() {
    return DEFAULT_CHART_WIDTH;
  }

  calculateHeight() {
    return DEFAULT_CHART_HEIGHT;
  }

  private getValue(
    yearlyData: Record<string, string>,
    year: string,
  ): number | string {
    const yearData = yearlyData[year];
    const value = Number.parseFloat(yearData);
    if (Number.isNaN(value)) {
      return Coerce.toNullishCoalescedString(yearData);
    }
    return value;
  }
}
