import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService, UserInfo } from 'prosumer-app/libs/eyes-shared';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ConfigService } from '../../../services/index';
import { AuthStore } from '../../auth';
import { RetrievalType, User } from '../models';

@Injectable()
export class UserAPIService extends ApiService<User> {
  clientInfo: UserInfo;
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _authStore: AuthStore,
  ) {
    super(_config.api.baseUrl);
    this._authStore.data$
      .pipe(filter((data) => !!data))
      .subscribe((authData) => (this.clientInfo = authData.userInfo));
  }

  /**
   * TODO: Actual create implementation
   *
   * @param user - User to be created?
   */
  create(user: User): Observable<User> {
    return of(user);
  }

  /**
   * TODO: Actual delete implementation
   *
   * @param user - User to be deleted
   */
  delete(user: User): Observable<any> {
    return of({});
  }

  /**
   * TODO: Actual getUsers implementation
   */
  getList(): Observable<Array<string>> {
    return of([]);
  }

  /**
   * TODO: Actual update implementation
   *
   * @param user - User to be updated
   */
  update(user: User): Observable<User> {
    return of(user);
  }

  /**
   * Fetch the user information from the user microservice given the UUID
   *
   * @param id - UUID of the User
   */
  get(id: string): Observable<User> {
    return this._http
      .get<any>(this.endpoint(this._config.api.endpoints.user.getUserById, id))
      .pipe(
        map((response) =>
          this.mapDataToFrontend({
            ...response,
            retrievalType: RetrievalType.ID,
          }),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  /**
   * Fetch the user information from the user microservice given the email
   * address
   *
   * @param email - email address of the user
   */
  getByEmail(email: string): Observable<User> {
    return this._http
      .get<any>(
        this.endpoint(this._config.api.endpoints.user.getUserByEmail, email),
      )
      .pipe(
        map((response) =>
          this.mapDataToFrontend({
            ...response,
            retrievalType: RetrievalType.EMAIL,
          }),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  /**
   * Properly construct a new instance of User with the correct mapping
   *
   * @param rawUser - raw JSON object representing a user (expected rsp from
   * backend)
   */
  mapDataToFrontend(rawUser: any): User {
    const isClient = (emailAddress) => emailAddress === this.clientInfo.email;
    return {
      id: rawUser.id,
      email: rawUser.email,
      firstName: rawUser.firstName,
      lastName: rawUser.lastName,
      retrievedUsing: rawUser.retrievalType,
      isClient: isClient(rawUser.email),
      userSessionId: rawUser.requestedBy,
      fullName: `${rawUser.firstName} ${rawUser.lastName}`,
      preferences: rawUser.preferences || {},
    };
  }

  /**
   * TODO: No backend receival as of the moment
   *
   * @param user - user instance from the frontend
   */
  mapDataToBackend(user: User): any {
    return { ...user };
  }
}
