import { ProsumerRoutePathService } from 'prosumer-app/core';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import { BaseComponent, ColumnDefinition } from 'prosumer-app/libs/eyes-shared';
import { startWith, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  signal,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ScenarioFacadeService } from '../../state';
import { scenarioUploadPageTokens } from './scenario-upload-page.token';

interface CaseProjectIdsI {
  projectId: string;
  caseId: string;
}

@Component({
  selector: 'prosumer-scenario-upload-page',
  templateUrl: './scenario-upload-page.component.html',
  styleUrls: ['./scenario-upload-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioUploadPageComponent
  extends BaseComponent
  implements OnDestroy
{
  private paramInfo: CaseProjectIdsI;
  private readonly gamsFiles = signal<File[]>([]);
  readonly formSubmitted = signal<boolean>(false);
  readonly uploading$ = this.scenarioFacade.uploading$.pipe(startWith(false));
  readonly params$ = this._route.params.pipe(
    tap((d) => this.registerProjectCaseIds(d)),
  );

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _logger: LoggerService,
    private readonly _route: ActivatedRoute,
    private readonly _routePath: ProsumerRoutePathService,
    private readonly scenarioFacade: ScenarioFacadeService,
  ) {
    super();
  }

  ngOnDestroy() {
    this.scenarioFacade.clear();
  }

  gamsFilesChanged(event: File[]) {
    this.gamsFiles.set(event);
  }

  onCancelClicked(): void {
    this._location.back();
  }

  onCreate(data: any[]): void {
    this._logger.info(data);
    this.formSubmitted.set(true);
    data.forEach((d) => this.triggerScenarioUpload(d));
  }

  onUploadsSuccessAll() {
    this.navToRunningPg();
  }

  get gamsColumnDef(): ColumnDefinition {
    return scenarioUploadPageTokens.gamsTableColumnDef;
  }

  get scenarioInputColumnDef(): ColumnDefinition {
    return scenarioUploadPageTokens.scenarioTableColumnDef;
  }

  private triggerScenarioUpload(d: any) {
    this.scenarioFacade.upload(
      {
        ...d,
        projectId: this.paramInfo.projectId,
        caseId: this.paramInfo.caseId,
      },
      d.fileInput,
      this.gamsFiles(),
    );
  }

  private registerProjectCaseIds(d: Params) {
    this.paramInfo = { projectId: d.projectId, caseId: d.caseId };
  }

  private navToRunningPg() {
    this._router.navigate(
      this._routePath.fromUploadScenariosRunning(
        this.paramInfo.projectId,
        this.paramInfo.caseId,
      ),
    );
  }
}
