import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { mergeMap, pluck, tap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { AUTH_CONFIG_TOKEN } from '../auth.tokens';
import { AuthConfig, AuthState } from '../models';

const initialState: AuthState = {
  loggedIn: false,
  loading: true,
  user: undefined,
};

/**
 * A service used for authentication purposes.
 */
@Injectable()
export class AuthService {
  protected checked = new Subject<boolean>();
  protected state = new BehaviorSubject<AuthState>(initialState);

  checked$ = this.checked.asObservable();
  state$ = this.state.asObservable();
  loggedIn$ = this.state$.pipe(pluck('loggedIn'));
  loading$ = this.state$.pipe(pluck('loading'));
  user$ = this.state$.pipe(pluck('user'));

  checkAuth$ = this.checkAuth().pipe(
    mergeMap((user) => {
      if (user) {
        this.setState({ loggedIn: true, loading: false, user });
        return of(true);
      }
      return this.checked$;
    }),
  );

  constructor(@Inject(AUTH_CONFIG_TOKEN) readonly authConfig: AuthConfig) {
    if (authConfig) {
      Auth.configure(authConfig);
      this.listenAuth();
    }
  }

  /**
   * Redirects to the hosted login page based on the auth configuration.
   */
  login(): void {
    window.location.assign(this.generateUrl());
  }

  /**
   * Logs the user out of the application, redirecting to the login callback page.
   */
  logout(): void {
    this.setState({ loggedIn: false, loading: true, user: undefined });
    Auth.signOut();
  }

  /**
   * Refreshes the token when the user access token expires.
   * @returns Observable of the user
   */
  refreshToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      tap((updatedSession) => {
        const userState = this.state.value.user;
        // update state by new session tokens
        this.setState({
          user: {
            ...userState,
            signInUserSession: {
              ...userState.signInUserSession,
              ...updatedSession,
            },
          },
        });
      }),
    );
  }

  /**
   * Sets the authentication state immutably.
   * @param changes - the state changes
   */
  protected setState(changes: AuthState): void {
    this.state.next({
      ...this.state.value,
      ...changes,
    });
  }

  /**
   * Checks the current authenticated user. Also refreshes the user session.
   * @returns Observable of the user
   */
  protected checkAuth(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }

  /**
   * Listens to authentication events to update the state.
   */
  protected listenAuth(): void {
    const authListener = ({ payload: { event } }: any) => {
      if (event === 'signIn') {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            this.checked.next(true);
            this.setState({ loggedIn: true, loading: false, user });
          })
          .catch((error) => {
            this.checked.error(error);
          })
          .finally(() => this.checked.complete());
      }
    };
    Hub.listen('auth', authListener);
  }

  /**
   * Generates the login url of the hosted login page.
   */
  protected generateUrl(): string {
    const {
      oauth,
      userPoolWebClientId,
      userPoolIdentityProvider,
    } = this.authConfig;
    const { domain, redirectSignIn, responseType } = oauth;
    return (
      `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=` +
      `${responseType}&client_id=${userPoolWebClientId}&identity_provider=${userPoolIdentityProvider}`
    );
  }
}
