import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { MatMenuTrigger } from '@angular/material/menu';
import { MenuButtonOption } from './menu-button.model';

@Component({
  selector: 'prosumer-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent<T> implements AfterViewInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('customMenuTrigger') customMenuTrigger: ElementRef;
  @Output() selectOption = new EventEmitter<T>();
  @Input() disabled: boolean;
  @Input() svgIcon: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() iconColor: ThemePalette;
  @Input() options: Array<MenuButtonOption<T>>;
  @Input() hasCustomTemplate = false;

  ngAfterViewInit() {
    this.registerCustomMenuTrigger(this.customMenuTrigger);
  }

  onClick(value: T) {
    this.selectOption.emit(value);
  }

  onMenuCustomTriggerClick(event: MouseEvent) {
    if (!this.disabled) {
      this.trigger.openMenu();
    }
    event.stopPropagation();
  }

  private registerCustomMenuTrigger(elRef: ElementRef) {
    if (elRef) {
      elRef.nativeElement.addEventListener(
        'click',
        this.onMenuCustomTriggerClick.bind(this),
      );
    }
  }
}
