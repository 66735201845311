import { contains, doNothing } from 'prosumer-app/libs/eyes-shared';

const isSameEnergyVectorId = (id, source, target) => {
  const sourceEVId = source.filter((item) => item['id'] === id)[0][
    'energyVector'
  ];
  const targetEVId = target.filter((item) => item['id'] === id)[0][
    'energyVector'
  ];
  return sourceEVId === targetEVId;
};

const hasSameNodesIds = (id, prevData, currData) => {
  const prevDataNodes = prevData.filter((data) => data.id === id)[0].nodes;
  const currDataNodes = currData.filter((data) => data.id === id)[0].nodes;
  return (
    prevDataNodes.every((node) => contains(currDataNodes, node)) ||
    contains(currDataNodes, 'ALL')
  );
};

/**
 * returns the list of editted items from the source array
 *
 * @param source - the original array
 * @param target - the array after deletion of an entry
 * @param idField - the indicator of the field to check from the object in the array
 * @param fields - the array of field names that needs to check for changes
 */

export const getEdittedLoads = <T>(
  source: Array<T>,
  target: Array<T>,
  idField: string = 'id',
) => {
  if (!source || source.length === 0) {
    return [];
  }

  if (!target || target.length === 0) {
    return [...source];
  }

  const editedIds = [];
  const sourceIds = source.map((item) => item[idField]);

  sourceIds.forEach((id) => {
    if (target.filter((item) => item['id'] === id).length !== 0) {
      if (
        isSameEnergyVectorId(id, source, target) &&
        hasSameNodesIds(id, source, target)
      ) {
        doNothing();
      } else {
        editedIds.push(id);
      }
    }
  });

  return source.filter((item) => contains(editedIds, item[idField]));
};
