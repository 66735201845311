import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  ConfigService,
  DialogService,
  ErrorService,
  HttpService,
  LoggerService,
  PermissionCheckerService,
  UpdateNotificationService,
  WebSocketService,
} from './services/index';
import { StoresModule } from './stores/index';

@NgModule({
  imports: [StoresModule.forRoot()],
  providers: [
    ConfigService,
    DialogService,
    HttpService,
    LoggerService,
    UpdateNotificationService,
    ErrorService,
    WebSocketService,
    PermissionCheckerService,
  ],
})
export class EyesCoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: EyesCoreModule) {
    if (parentModule) {
      throw new Error(
        'EyesCoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
