import { Coerce } from 'prosumer-app/core/utils';
import { BaseComponent, contains, toList } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-app/shared/models';
import { expandYearlyValuesIfApplicable } from 'prosumer-app/shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { YearlyChartDialogModel } from './yearly-chart-dialog.model';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const YearlyValuesUtilWrapper = {
  expandYearlyValuesIfApplicable,
};

@Component({
  selector: 'prosumer-yearly-chart-dialog',
  templateUrl: './yearly-chart-dialog.component.html',
  styleUrls: ['./yearly-chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyChartDialogComponent
  extends BaseComponent
  implements OnInit
{
  private readonly yearlyValuesCache = new BehaviorSubject<YearlyValues>({});
  readonly yearlyValues$ = this.yearlyValuesCache.asObservable();
  readonly sanitized$ = this.selectSanitizedYearlyValues();

  readonly disabled$ = this.selectDisabled();

  get startYear(): number {
    return Coerce.toNumber(this.dialogData.startYear);
  }

  get endYear(): number {
    return Coerce.toNumber(this.dialogData.endYear);
  }

  private get dialogData(): YearlyChartDialogModel {
    return Coerce.toObject(this.data);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YearlyChartDialogModel,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<YearlyChartDialogModel>,
    public formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.updateYearlyValuesUsingDialogData();
  }

  onConfirm() {
    this.dialogRef.close(this.yearlyValuesCache.value);
  }

  rangeValueChange(range: any) {
    this.yearlyValuesCache.next(range);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private selectSanitizedYearlyValues(): Observable<YearlyValues> {
    return this.yearlyValuesCache.pipe(
      map((values) => this.sanitizeYearlyValues(values)),
    );
  }

  private sanitizeYearlyValues(values: YearlyValues): YearlyValues {
    return YearlyValuesUtilWrapper.expandYearlyValuesIfApplicable(values, [
      this.startYear,
      this.endYear,
    ]);
  }

  private selectDisabled(): Observable<boolean> {
    return this.yearlyValuesCache.pipe(
      map((values) => {
        return [
          this.hasDefault(values),
          this.doesNotMeetRangeConstraints(values),
        ].some(Boolean);
      }),
      map((isValid) => [!this.data.notRequired, isValid].every(Boolean)),
    );
    // return combineLatest([
    //   this.yearlyValuesCache.pipe(map((values) => this.hasNull(values))),
    //   this.yearlyValuesCache.pipe(
    //     map((values) => this.doesNotMeetRangeConstraints(values)),
    //   ),
    // ]).pipe(
    //   map(
    //     ([hasNull, doesNotMeetRangeConstraints]) =>
    //       hasNull || doesNotMeetRangeConstraints,
    //   ),
    // );
  }

  private hasDefault(values: YearlyValues): boolean {
    return contains(toList(values), '');
  }

  private doesNotMeetRangeConstraints(values: YearlyValues): boolean {
    return !toList(values).every(
      (v) => Number(v) >= Number(this.data.minValue),
    );
  }

  private updateYearlyValuesUsingDialogData(): void {
    this.yearlyValuesCache.next(this.resolveYearlyValuesFromDialogData());
  }

  private resolveYearlyValuesFromDialogData(): YearlyValues {
    if (!!!this.data.yearlyValues) {
      const startYearStr = Coerce.toString(this.data.startYear);
      return this.getDefaultValue(startYearStr);
    }
    return this.data.yearlyValues;
  }

  private getDefaultValue(startYear: string): YearlyValues {
    let defaultValue: string | null = '0.0';
    if (!!this.data.notRequired) {
      defaultValue = null;
    }
    return { [startYear]: defaultValue };
  }
}
