<div
  *ngIf="!!data && !!data.overviewData && !!data.nodeViewData"
  fxLayout="column"
  fxLayoutGap="16px"
>
  <div fxLayout="row" fxLayoutAlign="center" class="container-options">
    <prosumer-select-new
      *ngIf="(yearsOption$ | ngrxPush).length"
      class="input-select-year"
      [label]="'Scenario.labels.simulationResults.year' | translate"
      [placeholder]="'Scenario.labels.simulationResults.year' | translate"
      [options]="yearsOption$ | ngrxPush"
      [value]="selectedYear$ | ngrxPush"
      (selectionChange)="handleSelectedYearChange($event)"
      data-testid="results-system-visualization-select-year"
    >
    </prosumer-select-new>
    <mat-button-toggle-group
      name="View"
      aria-label="Select view"
      [formControl]="activeView"
      data-testid="select-view-button"
    >
      <mat-button-toggle
        *ngFor="let option of options"
        tooltipPosition="center"
        tooltipPanelClass="eyes-spacer-top-half"
        [value]="option.value"
        [prosumerTooltip]="option.tooltip"
      >
        {{ option.label }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ng-container [ngSwitch]="activeView.value">
    <prosumer-overview-container
      *ngSwitchCase="0"
      [data]="data.overviewData"
      [@fadeIn]
      (elementTapped)="onElementTap($event)"
      fxFlex
    >
    </prosumer-overview-container>

    <prosumer-node-view-container
      *ngSwitchCase="1"
      [data]="data.nodeViewData"
      [@fadeIn]
      fxFlex
    >
    </prosumer-node-view-container>
  </ng-container>
</div>
