import { convertDataValuesToString } from 'prosumer-app/libs/eyes-shared';
import {
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared';

import {
  Converter,
  Efficiency,
  EfficiencyMatrix,
  EfficiencyPoint,
  Equipment,
  EquipmentForm,
  Generator,
  OperatingCost,
  Profile,
  RenewableEnergy,
  Station,
  Storage,
  Vehicle,
} from '../../models';

const LIBRARY_TYPE_CUSTOM = 'custom';
const LIBRARY_TYPE_LIBRARY = 'library';
const CONVERSION_EXCEPTIONS = [
  'depreciationTime',
  'technicalLife',
  'minDowntime',
  'minUptime',
  'minRunningTime',
];

export class EquipmentsMapperOld {
  static mapLoadProfilesToBackend = (equipment: Equipment): any => {
    if (equipment.profiles === undefined) {
      return undefined;
    }
    const loadProfile = { name: equipment.loadName, profiles: null };
    const profiles = (equipment.profiles || []).map((profile) =>
      mapYearlyProfileToBackend({
        ...profile,
        loadType: 'custom',
      }),
    );
    loadProfile.profiles = profiles;
    return loadProfile;
  };
  static mapToBackend = (equipmentsForm: EquipmentForm) =>
    !!equipmentsForm
      ? {
          ders: (equipmentsForm.equipments || []).map((equipment) =>
            convertDataValuesToString(
              EquipmentsMapperOld.mapEquipmentSourceToBackend(equipment, {
                id: equipment.id,
                name: equipment.name,
                derType: equipment.type,
                nodeId: { nodeIds: equipment.nodes || [] },
                scenarioVariation: mapVariationToBackend(equipment),
                outputEnergyVectorId: !!equipment.outputEnergyVector
                  ? equipment.outputEnergyVector
                  : undefined,
                profile: {
                  name: equipment.loadName,
                  isCustom: true,
                  localId: equipment.localId,
                },
                forcedInvestment: equipment.forcedInvestment,
                libraryId: (equipment.library || {}).id,
                capacityLoss: mapYearlyValuesToBackend(
                  equipment.yearlyCapacityLoss,
                ),
                minPower: mapYearlyValuesToBackend(equipment.yearlyMinPower),
                maxPower: mapYearlyValuesToBackend(equipment.yearlyMaxPower),
                buildCost: mapYearlyValuesToBackend(equipment.yearlyBuildCost),
                indivisibleCost: mapYearlyValuesToBackend(
                  equipment.yearlyIndivisibleCost,
                ),
                fOAndMCharge: mapYearlyValuesToBackend(
                  equipment.yearlyFOAndMCharge,
                ),
                fOAndMPerInstall: mapYearlyValuesToBackend(
                  equipment.yearlyFOAndMInstall,
                ),
                technicalLife: mapYearlyValuesToBackend(
                  equipment.yearlyTechnicalLife,
                ),
                depreciationTime: mapYearlyValuesToBackend(
                  equipment.yearlyDepreciationTime,
                ),
                existingAsset: equipment.existingAsset,
                capacityExpansion: equipment.capacityExpansion,
                buildEmissionsKw: mapYearlyValuesToBackend(
                  equipment.yearlyBuildEmissionsKw,
                ),
                buildEmissionsIndivisible: mapYearlyValuesToBackend(
                  equipment.yearlyBuildEmissionsIndivisible,
                ),
              }),
              CONVERSION_EXCEPTIONS,
            ),
          ),
        }
      : {};
  // TODO: Add end year to loads
  static mapLoadProfilesToFrontend = (equipment: any): Array<Profile> =>
    (equipment.profiles || []).map((profile) =>
      mapYearlyProfileToFrontend(profile, 'DER'),
    );
  static mapToFrontend = (
    scenario: any,
    startYear?: number,
    endYear?: number,
  ): EquipmentForm =>
    !!scenario
      ? {
          binToDelete: [],
          equipments: (scenario.ders || []).map((equipment) =>
            EquipmentsMapperOld.mapEquipmentSourceToFrontend(
              equipment,
              {
                id: equipment.id,
                name: equipment.name,
                nodes:
                  equipment.derType !== 'vehicle'
                    ? typeof equipment.nodeId === 'string'
                      ? [equipment.nodeId]
                      : equipment.nodeId.nodeIds || []
                    : undefined,
                type: equipment.derType,
                scenarioVariation: mapVariationToFrontend(equipment),
                outputEnergyVector: !!equipment.outputEnergyVectorId
                  ? equipment.outputEnergyVectorId
                  : equipment.derType === 'converter'
                    ? equipment.mainOutputId
                    : undefined,
                loadName: (equipment.profile || {}).name,
                forcedInvestment: equipment.forcedInvestment,
                library: !!equipment.libraryId
                  ? { id: equipment.libraryId }
                  : undefined,
                sourceType: equipment.libraryId
                  ? LIBRARY_TYPE_LIBRARY
                  : LIBRARY_TYPE_CUSTOM,
                yearlyCapacityLoss: mapYearlyValuesToFrontend(
                  equipment.capacityLoss,
                  startYear,
                  endYear,
                ),
                yearlyMinPower: mapYearlyValuesToFrontend(
                  equipment.minPower,
                  startYear,
                  endYear,
                ),
                yearlyMaxPower: mapYearlyValuesToFrontend(
                  equipment.maxPower,
                  startYear,
                  endYear,
                ),
                yearlyBuildCost: mapYearlyValuesToFrontend(
                  equipment.buildCost,
                  startYear,
                  endYear,
                ),
                yearlyIndivisibleCost: mapYearlyValuesToFrontend(
                  equipment.indivisibleCost,
                  startYear,
                  endYear,
                ),
                yearlyFOAndMCharge: mapYearlyValuesToFrontend(
                  equipment.fOAndMCharge,
                  startYear,
                  endYear,
                ),
                yearlyFOAndMInstall: mapYearlyValuesToFrontend(
                  equipment.fOAndMPerInstall,
                  startYear,
                  endYear,
                ),
                yearlyTechnicalLife: mapYearlyValuesToFrontend(
                  equipment.technicalLife,
                  startYear,
                  endYear,
                ),
                yearlyDepreciationTime: mapYearlyValuesToFrontend(
                  equipment.depreciationTime,
                  startYear,
                  endYear,
                ),
                existingAsset: equipment.existingAsset,
                capacityExpansion: equipment.capacityExpansion,
                yearlyBuildEmissionsKw: mapYearlyValuesToFrontend(
                  equipment.buildEmissionsKw,
                  startYear,
                  endYear,
                ),
                yearlyBuildEmissionsIndivisible: mapYearlyValuesToFrontend(
                  equipment.buildEmissionsIndivisible,
                  startYear,
                  endYear,
                ),
              },
              startYear,
              endYear,
            ),
          ),
        }
      : {};

  static mapEquipmentSourceToFrontend(
    source: any,
    equipment: Equipment,
    startYear?: number,
    endYear?: number,
  ): Equipment {
    switch (source.derType) {
      case 'vre':
        return {
          ...equipment,
          profiles: this.mapLoadProfilesToFrontend(source.profile || {}),
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            source.operatingCostProfiles || [],
          ),
        } as RenewableEnergy;
      case 'storage':
        return {
          ...equipment,
          energyVector:
            source.inputEnergyVectorId || source.outputEnergyVectorId,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            source.operatingCostProfiles || [],
          ),
          yearlySecondCapacityLoss: mapYearlyValuesToFrontend(
            source.capacityLoss2,
            startYear,
            endYear,
          ),
          yearlyRoundTripEfficiency: mapYearlyValuesToFrontend(
            source.efficiency,
            startYear,
            endYear,
          ),
          yearlyMaxDoD: mapYearlyValuesToFrontend(
            source.maxDod,
            startYear,
            endYear,
          ),
          yearlyMinEp: mapYearlyValuesToFrontend(
            source.minEp,
            startYear,
            endYear,
          ),
          yearlyMaxEp: mapYearlyValuesToFrontend(
            source.maxEp,
            startYear,
            endYear,
          ),
          yearlyChargeDischargeRateFactor: mapYearlyValuesToFrontend(
            source.chargingDischargingRateFactor,
            startYear,
            endYear,
          ),
          yearlyAgingFactor: mapYearlyValuesToFrontend(
            source.agingFactor,
            startYear,
            endYear,
          ),
          yearlyDissipationRate: mapYearlyValuesToFrontend(
            source.dissipationRate,
            startYear,
            endYear,
          ),
          yearlyMinEnergy: mapYearlyValuesToFrontend(
            source.minEnergy,
            startYear,
            endYear,
          ),
          yearlyMaxEnergy: mapYearlyValuesToFrontend(
            source.maxEnergy,
            startYear,
            endYear,
          ),
          yearlyFOAndMChargeKWh: mapYearlyValuesToFrontend(
            source.fOAndMChargeKwh,
            startYear,
            endYear,
          ),
          yearlySecondBuildCost: mapYearlyValuesToFrontend(
            source.buildCost2,
            startYear,
            endYear,
          ),
          yearlyBuildEmissionsKwh: mapYearlyValuesToFrontend(
            source.buildEmissionsKwh,
            startYear,
            endYear,
          ),
        } as Storage;
      case 'generator':
        return {
          ...equipment,
          energyVector: source.inputEnergyVectorId,
          inputEnergyVector: source.inputEnergyVectorId,
          efficiencyCurve: source.efficiencyCurve,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            source.operatingCostProfiles || [],
          ),
          /** Start of Yearly values */
          yearlyIndividualSize: mapYearlyValuesToFrontend(
            source.individualSize,
            startYear,
            endYear,
          ),
          yearlyPMin: mapYearlyValuesToFrontend(
            source.pmin,
            startYear,
            endYear,
          ),
          yearlyEfficiencyPMin: mapYearlyValuesToFrontend(
            source.efficiencyPmin,
            startYear,
            endYear,
          ),
          yearlyEfficiencyPMax: mapYearlyValuesToFrontend(
            source.efficiencyPmax,
            startYear,
            endYear,
          ),
          yearlyMinUpTime: mapYearlyValuesToFrontend(
            source.minUptime,
            startYear,
            endYear,
          ),
          yearlyMinDownTime: mapYearlyValuesToFrontend(
            source.minDowntime,
            startYear,
            endYear,
          ),
          yearlyMinRunningTime: mapYearlyValuesToFrontend(
            source.minRunningTime,
            startYear,
            endYear,
          ),
          yearlyStartUpLoss: mapYearlyValuesToFrontend(
            source.startUpLoss,
            startYear,
            endYear,
          ),
          yearlyIdleLoss: mapYearlyValuesToFrontend(
            source.idleLoss,
            startYear,
            endYear,
          ), // shutdown loss
          yearlyRunningCost: mapYearlyValuesToFrontend(
            source.runningCost,
            startYear,
            endYear,
          ),
          // profiles: this.mapLoadProfilesToFrontend(source.profile)
          /** End of Yearly values */
        } as Generator;
      case 'converter':
        return {
          ...equipment,
          // inputEnergyVector: source.mainInputId,
          secondOutputEnergyVector: source.outputEnergyVectorId2, // TODO Is this still used?
          efficiencyMatrix: EquipmentsMapperOld.mapEfficiencyMatrixToFrontend(
            source,
            startYear,
            endYear,
          ),
          energyVector: source.mainInputId,
          secondary: source.outputEnergyVectorId2, // TODO Is this still used?
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            source.operatingCostProfiles || [],
          ),
          // TODO Are the following still needed?
          yearlyEfficiency: mapYearlyValuesToFrontend(
            source.efficiency,
            startYear,
            endYear,
          ),
          yearlyEfficiency2nd: mapYearlyValuesToFrontend(
            source.efficiency2,
            startYear,
            endYear,
          ),
        } as Converter;
      case 'vehicle':
        return {
          ...equipment,
          energyVector: source.outputEnergyVectorId,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            source.operatingCostProfiles || [],
          ),
          yearlySecondCapacityLoss: mapYearlyValuesToFrontend(
            source.capacityLoss2,
            startYear,
            endYear,
          ),
          yearlyRoundTripEfficiency: mapYearlyValuesToFrontend(
            source.efficiency,
            startYear,
            endYear,
          ),
          yearlyEfficiency2nd: mapYearlyValuesToFrontend(
            source.efficiency2,
            startYear,
            endYear,
          ),
          yearlyMaxDoD: mapYearlyValuesToFrontend(
            source.maxDod,
            startYear,
            endYear,
          ),
          yearlyAgingFactor: mapYearlyValuesToFrontend(
            source.agingFactor,
            startYear,
            endYear,
          ),
          yearlyDissipationRate: mapYearlyValuesToFrontend(
            source.dissipationRate,
            startYear,
            endYear,
          ),
          yearlyMinEnergy: mapYearlyValuesToFrontend(
            source.minEnergy,
            startYear,
            endYear,
          ),
          yearlyMaxEnergy: mapYearlyValuesToFrontend(
            source.maxEnergy,
            startYear,
            endYear,
          ),
          yearlyFOAndMChargeKWh: mapYearlyValuesToFrontend(
            source.fOAndMChargeKwh,
            startYear,
            endYear,
          ),
          yearlySecondBuildCost: mapYearlyValuesToFrontend(
            source.buildCost2,
            startYear,
            endYear,
          ),
          yearlyBuildEmissionsKwh: mapYearlyValuesToFrontend(
            source.buildEmissionsKwh,
            startYear,
            endYear,
          ),
        } as Vehicle;
      case 'station':
        return {
          ...equipment,
          connectionCost: mapYearlyValuesToFrontend(
            source.connectionCost,
            startYear,
            endYear,
          ),
          maxConnections: mapYearlyValuesToFrontend(
            source.maxConnections,
            startYear,
            endYear,
          ),
          minConnections: mapYearlyValuesToFrontend(
            source.minConnections,
            startYear,
            endYear,
          ),
          numberOfConnections: mapYearlyValuesToFrontend(
            source.numberOfConnections,
            startYear,
            endYear,
          ),
          opexPerHour: mapYearlyValuesToFrontend(
            source.opexPerHour,
            startYear,
            endYear,
          ),
          outputEnergyVector: source.outputEnergyVectorId,
          powerChargingPerConnection: mapYearlyValuesToFrontend(
            source.powerChargingPerConnection,
            startYear,
            endYear,
          ),
          timeSlots: mapYearlyValuesToFrontend(
            source.timeSlots,
            startYear,
            endYear,
          ),
          vehicleToGrid: source.vehicleToGrid,
        } as Station;
    }
    return equipment;
  }

  static mapEfficiencyMatrixToFrontend = (
    source: any,
    startYear?: number,
    endYear?: number,
  ): EfficiencyMatrix => {
    if (!!!source) {
      return {
        mainEnergyVectorInput: undefined,
        mainEnergyVectorOutput: undefined,
        efficiencies: [],
      };
    }
    return {
      mainEnergyVectorInput: (source.mainInputId || '').toString(),
      mainEnergyVectorOutput: (source.mainOutputId || '').toString(),
      efficiencies: EquipmentsMapperOld.mapEfficienciesToFrontend(
        source,
        startYear,
        endYear,
      ),
    } as EfficiencyMatrix;
  };

  static mapEfficienciesToFrontend = (
    source: any,
    startYear?: number,
    endYear?: number,
  ): Array<Efficiency> =>
    !!source
      ? (source.evInputOutputPairs || []).map(
          (effPair) =>
            ({
              energyVectorIn: (
                (effPair || ({} as any)).inputId || ''
              ).toString(),
              energyVectorOut: (
                (effPair || ({} as any)).outputId || ''
              ).toString(),
              value: mapYearlyValuesToFrontend(
                (effPair || ({} as any)).efficiency,
                startYear,
                endYear,
              ),
            }) as Efficiency,
        )
      : [];

  static mapEfficiencyCurveToBackend = (effCurve: Array<EfficiencyPoint>) =>
    (effCurve || []).map(
      (point) =>
        ({
          percentageOfCapacity: (point.percentageOfCapacity || '0').toString(),
          consumption: (point.consumption || '0').toString(),
        }) as EfficiencyPoint,
    );

  static mapEfficienciesToBackend = (
    efficiencyMatrix: EfficiencyMatrix,
  ): Array<any> => {
    if (!!!efficiencyMatrix || !!!efficiencyMatrix.mainEnergyVectorInput) {
      return [];
    }
    return (efficiencyMatrix.efficiencies || []).map(
      (effPair) =>
        ({
          inputId: effPair.energyVectorIn,
          outputId: effPair.energyVectorOut,
          efficiency: mapYearlyValuesToBackend(effPair.value),
        }) as any,
    );
  };

  static mapEquipmentSourceToBackend(equipment: Equipment, data: any): any {
    switch (equipment.type) {
      case 'vre':
        return {
          ...data,
          profile: this.mapLoadProfilesToBackend(equipment),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as RenewableEnergy,
          ),
        };
      case 'converter':
        return {
          ...data,
          profile: undefined,
          mainInputId: !!(equipment as Converter).efficiencyMatrix
            ? (equipment as Converter).efficiencyMatrix.mainEnergyVectorInput
            : undefined,
          mainOutputId: !!(equipment as Converter).efficiencyMatrix
            ? (equipment as Converter).efficiencyMatrix.mainEnergyVectorOutput
            : undefined,
          evInputOutputPairs: EquipmentsMapperOld.mapEfficienciesToBackend(
            (equipment as Converter).efficiencyMatrix,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Converter,
          ),
        };
      case 'storage':
        return {
          ...data,
          inputEnergyVectorId: (equipment as Storage).energyVector,
          outputEnergyVectorId: (equipment as Storage).energyVector,
          capacityLoss2: mapYearlyValuesToBackend(
            (equipment as Storage).yearlySecondCapacityLoss,
          ),
          efficiency: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyRoundTripEfficiency,
          ),
          maxDod: mapYearlyValuesToBackend((equipment as Storage).yearlyMaxDoD),
          minEp: mapYearlyValuesToBackend((equipment as Storage).yearlyMinEp),
          maxEp: mapYearlyValuesToBackend((equipment as Storage).yearlyMaxEp),
          chargingDischargingRateFactor: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyChargeDischargeRateFactor,
          ),
          agingFactor: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyAgingFactor,
          ),
          dissipationRate: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyDissipationRate,
          ),
          buildCost2: mapYearlyValuesToBackend(
            (equipment as Storage).yearlySecondBuildCost,
          ),
          minEnergy: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyMinEnergy,
          ),
          maxEnergy: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyMaxEnergy,
          ),
          fOAndMChargeKwh: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyFOAndMChargeKWh,
          ),
          buildEmissionsKwh: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyBuildEmissionsKwh,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Storage,
          ),
          profile: undefined,
        };
      case 'generator':
        return {
          ...data,
          inputEnergyVectorId: (equipment as Generator).energyVector,
          individualSize: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyIndividualSize,
          ),
          pmin: mapYearlyValuesToBackend((equipment as Generator).yearlyPMin),
          efficiencyPmin: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyEfficiencyPMin,
          ),
          efficiencyPmax: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyEfficiencyPMax,
          ),
          startUpLoss: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyStartUpLoss,
          ),
          idleLoss: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyIdleLoss,
          ), // shutdown loss
          minUptime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinUpTime,
          ),
          minDowntime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinDownTime,
          ),
          minRunningTime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinRunningTime,
          ),
          profile: undefined,
          runningCost: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyRunningCost,
          ),
          efficiencyCurve: EquipmentsMapperOld.mapEfficiencyCurveToBackend(
            (equipment as Generator).efficiencyCurve || [],
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Generator,
          ),
        };
      case 'vehicle':
        return {
          ...data,
          agingFactor: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyAgingFactor,
          ),
          buildCost2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlySecondBuildCost,
          ),
          capacityLoss2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlySecondCapacityLoss,
          ),
          dissipationRate: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyDissipationRate,
          ),
          efficiency: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyRoundTripEfficiency,
          ),
          efficiency2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyEfficiency2nd,
          ),
          fOAndMChargeKwh: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyFOAndMChargeKWh,
          ),
          maxDod: mapYearlyValuesToBackend((equipment as Vehicle).yearlyMaxDoD),
          maxEnergy: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyMaxEnergy,
          ),
          maxEp: undefined,
          minEnergy: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyMinEnergy,
          ),
          minEp: undefined,
          nodeId: undefined,
          outputEnergyVectorId: (equipment as Vehicle).energyVector,
          profile: undefined,
          buildEmissionsKwh: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyBuildEmissionsKwh,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Vehicle,
          ),
        };
      case 'station':
        return {
          ...data,
          outputEnergyVectorId: (equipment as Station).outputEnergyVector,
          connectionCost: mapYearlyValuesToBackend(
            (equipment as Station).connectionCost,
          ),
          maxConnections: mapYearlyValuesToBackend(
            (equipment as Station).maxConnections,
          ),
          minConnections: mapYearlyValuesToBackend(
            (equipment as Station).minConnections,
          ),
          numberOfConnections: mapYearlyValuesToBackend(
            (equipment as Station).numberOfConnections,
          ),
          opexPerHour: mapYearlyValuesToBackend(
            (equipment as Station).opexPerHour,
          ),
          powerChargingPerConnection: mapYearlyValuesToBackend(
            (equipment as Station).powerChargingPerConnection,
          ),
          profile: undefined,
          timeSlots: mapYearlyValuesToBackend((equipment as Station).timeSlots),
          vehicleToGrid: (equipment as Station).vehicleToGrid,
        };
      default:
        return {
          ...data,
        };
    }
  }
  static mapOperatingCostProfilesToBackend = (equipment: any): any => {
    if (equipment.operatingCostProfiles === undefined) {
      return undefined;
    }
    const operatingCostProfiles = (equipment.operatingCostProfiles || []).map(
      (costProfile) => ({
        parameterType: costProfile.parameterType.toLowerCase(),
        energyVectorId: costProfile.energyVectorId,
        profile: {
          name: 'Cost Profile',
          profiles: (costProfile.profiles || []).map((p) =>
            mapYearlyProfileToBackend(p),
          ),
        },
      }),
    );
    return operatingCostProfiles;
  };
  static mapOperatingCostProfilesToFrontend = (
    operatingCostProfiles: any,
  ): Array<OperatingCost> => {
    const costProfiles = (operatingCostProfiles || []).map((costProfile) => ({
      parameterType: costProfile.parameterType.toUpperCase(),
      energyVectorId: costProfile.energyVectorId,
      profiles: ((costProfile.profile || {}).profiles || []).map((p) =>
        mapYearlyProfileToFrontend(p, 'DER_OPERATING_COSTS'),
      ),
    }));
    return costProfiles;
  };
}
