import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Node } from 'prosumer-app/+scenario';
import { Utils } from 'prosumer-app/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { map, switchMap } from 'rxjs/operators';
import { ScenarioDetailState } from '../scenario-detail';
import { NodeInfo } from './node.state';
import { NodeStore } from './node.store';

@Injectable({ providedIn: 'root' })
export class NodeQuery extends QueryEntity<ScenarioDetailState<NodeInfo>> {
  constructor(
    public store: NodeStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  private nodes$ = this.keeper.selectActive().pipe(
    switchMap(({ scenario }) =>
      this.selectAll({
        filterBy: (entity) => entity.scenarioUuid === scenario,
      }),
    ),
  );

  allNodes$ = this.selectAll();

  selectActiveNodes() {
    return this.nodes$.pipe(map((nodeInfo) => this.mapInfoToNode(nodeInfo)));
  }

  mapInfoToNode(nodes: NodeInfo[]): Node[] {
    return Utils.resolveToEmptyArray(nodes).map((node: NodeInfo) => ({
      name: node.name,
      value: node.nodeId,
      surface: node.surface,
    }));
  }

  mapNodeToInfo(node: Node): NodeInfo {
    return {
      name: node.name,
      surface: node.surface,
      nodeId: node.value,
    };
  }

  getNodes(): NodeInfo[] {
    return this.getAll().filter(
      (node) => node.scenarioUuid === this.keeper.getActiveEntities().scenario,
    );
  }

  getActiveOptions(): Node[] {
    return this.mapInfoToNode(this.getNodes());
  }
}
