import { Component, OnInit } from '@angular/core';
import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Utils } from 'prosumer-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ChartJSChart } from '../chartjs.base';
import { CustomLegend } from '../custom-legend/custom-legend.model';
import { MultipleLinesData, NameValue, SingleLine } from './line-chartjs.model';

@Component({
  selector: 'prosumer-line-chartjs',
  templateUrl: './line-chartjs.component.html',
  styleUrls: ['./line-chartjs.component.scss'],
})
export class LineChartjsComponent
  extends ChartJSChart<MultipleLinesData>
  implements OnInit
{
  private legendDataSubject = new BehaviorSubject<CustomLegend>(undefined);

  legendDetails$ = this.selectDelayedLegendDetails();

  ngOnInit(): void {
    this.isCustomLegend = true;
  }

  getDefaultID(): string {
    return 'line-chart';
  }
  getChartType(): ChartType {
    return 'line';
  }
  mapToChartJSData(data: MultipleLinesData): ChartData {
    const labels = this.buildTicksFromAllLines(data.lines);
    return {
      labels,
      datasets: data.lines.map((line) => this.mapToLineDataset(line, labels)),
    };
  }
  initializeCustomLegend(pieChart): void {
    this.legendDataSubject.next({
      legendItems:
        pieChart.options.plugins.legend.labels.generateLabels(pieChart),
      canvasId: pieChart.canvas.id,
    });
  }

  getAdditionalChartOptions(data: MultipleLinesData): Partial<ChartOptions> {
    return {
      scales: {
        x: { title: { display: true, text: data?.xAxisName } },
        y: { title: { display: true, text: data?.yAxisName } },
      },
    };
  }
  getDemoData(): MultipleLinesData {
    return {
      lines: [
        {
          name: 'Data 1',
          values: [
            { name: '2020', value: 0 },
            { name: '2021', value: 1 },
            { name: '2022', value: 2 },
          ],
        },
        {
          name: 'Data 2',
          values: [
            { name: '2024', value: 2 },
            { name: '2025', value: 1 },
            { name: '2026', value: 0 },
          ],
        },
      ],
    };
  }

  private buildTicksFromAllLines(lines: SingleLine[]): string[] {
    const reduced = lines.reduce(
      (acc, curr) => [...acc, ...this.buildTicksFromLine(curr)],
      [],
    );
    return Utils.removeDuplicates(reduced).sort();
  }

  private buildTicksFromLine(line: SingleLine): string[] {
    return line.values.map((value) => value.name);
  }

  private mapToLineDataset(single: SingleLine, ticks: string[]): ChartDataset {
    const data = ticks.map((year) =>
      this.findValueForYear(single.values, year),
    );
    const label = single.name;
    const color = this.resolveColor(label);
    return {
      label,
      data,
      borderColor: color,
      tension: 0.5,
      backgroundColor: color,
    };
  }

  private findValueForYear(from: NameValue[], year: string): number {
    return from.find((f) => f.name === year)?.value;
  }

  private selectDelayedLegendDetails(): Observable<CustomLegend> {
    return this.legendDataSubject.pipe(delay(0));
  }

  onLegendDataToggle({ index }) {
    this.chartInstance.setDatasetVisibility(
      index,
      !this.isDatasetVisible(index),
    );
    this.chartInstance.update();
  }
}
