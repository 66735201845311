import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { QuestionModule } from './components/question';
import { SpinnerModule } from './components/spinner/spinner.module';
import { UserSurveyFormComponent } from './user-survey-form.component';

@NgModule({
  declarations: [UserSurveyFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    QuestionModule,
    SpinnerModule,
    TranslateModule,
  ],
  exports: [UserSurveyFormComponent],
})
export class UserSurveyFormModule {}
