<div
  class="prosumer-section"
  fxLayout="column"
  fxLayoutGap="16px"
  *ngrxLet="scenario$ as scenario"
>
  <div
    data-testid="skelly"
    fxLayout="column"
    *ngIf="loading$ | ngrxPush; else loaded"
  >
    <div fxLayout="row">
      <ngx-skeleton-loader fxFlex="40"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row">
      <ngx-skeleton-loader fxFlex="20"></ngx-skeleton-loader>
    </div>
    <div fxLayout="row" fxLayoutGap="32px">
      <ngx-skeleton-loader fxFlex="25"></ngx-skeleton-loader>
      <ngx-skeleton-loader fxFlex="5"></ngx-skeleton-loader>
    </div>
  </div>

  <ng-template #loaded>
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      *ngrxLet="scenarioInfo$ as scenarioInfo"
    >
      <prosumer-scenario-name
        [editable]="true && (isViewOnly$ | async) !== true"
        [scenario]="mergedScenarioAndVariationDetails$ | async"
        data-testid="name"
        [infoStyle]="'header'"
        [allowScenarioNameClick]="true"
      ></prosumer-scenario-name>
      <span class="details-updated" data-testid="owner">
        {{
          'Scenario.details.owner'
            | translate
              : {
                  name:
                    scenarioInfo.createdBy ||
                    scenario.createdBy ||
                    (scenarioOwner$ | ngrxPush)?.fullName ||
                    'N/A'
                }
        }}
      </span>
      <!-- updated by and scenario status -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
        <span class="details-updated" data-testid="updated">
          {{
            'Scenario.details.updatedBy'
              | translate
                : {
                    name:
                      scenarioInfo.updatedBy ||
                      scenario.updatedBy ||
                      scenario.createdBy ||
                      (scenarioOwner$ | ngrxPush)?.fullName ||
                      'N/A',
                    date:
                      (scenarioInfo.updatedDate | date: 'medium') ||
                      (scenario.updatedDate | date: 'medium')
                  }
          }}
        </span>

        <div
          tooltipPosition="center"
          [prosumerTooltip]="thing.label | translate"
          *ngrxLet="statusIconThing$ as thing"
          [class]="thing.class"
          data-testid="status"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
        >
          <prosumer-simulation-status
            [status]="status$ | async"
          ></prosumer-simulation-status>
        </div>
        <div
          class="scenario-user-permission eyes-view-only"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
        >
          <mat-icon
            aria-hidden="false"
            data-testid="user-permission"
            *ngIf="isViewOnly$ | async"
            [prosumerTooltip]="
              'Scenario.details.viewMode'
                | translate
                  : { name: (project$ | ngrxPush)?.ownerName || 'someone else' }
            "
            >visibility</mat-icon
          >
        </div>
      </div>
    </div>
  </ng-template>
</div>
<router-outlet></router-outlet>
