import { StateFactory } from 'prosumer-app/libs/eyes-shared';

import { User } from '../models';
import { UserState } from './user-state.model';

export const userFeature = 'User';
export const userStateFactory = new StateFactory<UserState, User>(
  userFeature,
  'id',
  'lastName',
  {
    ids: [],
    entities: {},
  },
);
