import { InjectionToken } from '@angular/core';

export interface Data {
  name: string;
  rawData: any;
  metaData: Array<OverlayMetaData>;
}

export interface OverlayMetaData {
  label: string;
  key: string;
  format?: string;
}

export interface DetailsOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: Data;
}

export const DEFAULT_CONFIG: DetailsOverlayConfig = {
  hasBackdrop: false,
  // backdropClass: 'dark-backdrop',
  data: null,
};

export const DETAILS_OVERLAY_DATA = new InjectionToken<Data>(
  'DETAILS_OVERLAY_DATA',
);
