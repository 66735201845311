import { Coerce } from 'prosumer-app/core/utils';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';

import { TDBDataNewState, TDBDataNewSummary } from './tdb-data-new.state';
import { TDBDataStoreNew } from './tdb-data-new.store';

@Injectable({ providedIn: null })
export class TDBDataQueryNew extends QueryEntity<TDBDataNewState> {
  constructor(store: TDBDataStoreNew) {
    super(store);
  }

  selectAnyLoading(): Observable<boolean> {
    return this.select('loading').pipe(
      mergeMap(
        (loading: boolean): Observable<boolean> =>
          this.selectAll().pipe(
            map((entities: TDBDataNewSummary[]) =>
              [!!loading, ...entities.map((entity) => !!entity.loading)].some(
                Boolean,
              ),
            ),
          ),
      ),
    );
  }

  getActiveFilters(): Record<string, string | number> {
    return Coerce.toObject(this.getValue().activeFilters);
  }

  selectActiveFilters(): Observable<Record<string, string | number>> {
    return this.select('activeFilters');
  }

  selectTypes(): Observable<string[]> {
    return this.select('types').pipe(filterNilValue());
  }

  selectFilters(): Observable<Record<string, string[] | number[]>> {
    return this.select('filters').pipe(
      map((filters) => this.getFilters(false, filters)),
      filter((filters) => this.getFilterKeys(false, filters).length > 0),
    );
  }

  selectSliders(): Observable<Record<string, string[] | number[]>> {
    return this.select('filters').pipe(
      map((filters) => this.getFilters(true, filters)),
      filter((filters) => this.getFilterKeys(true, filters).length > 0),
    );
  }

  selectFilterValue(key: string): Observable<string[] | number[]> {
    return this.selectFilters().pipe(
      map((filters) => filters[key]),
      filter((filters) => !!filters),
    );
  }

  private getFilters(
    isFilterSlider: boolean,
    filters: Record<string, string[] | number[]>,
  ): Record<string, string[] | number[]> {
    return this.getFilterKeys(isFilterSlider, filters).reduce(
      (acc, curr) => ({ ...acc, [curr]: filters[curr] }),
      {},
    );
  }

  private getFilterKeys(
    isFilterSlider: boolean,
    filters: Record<string, string[] | number[]>,
  ): string[] {
    const sliderKeys = Coerce.toArray(this.getValue().filtersOfTypeSlider);
    return Object.keys(Coerce.toObject(filters)).filter(
      (item) => sliderKeys.includes(item) === isFilterSlider,
    );
  }
}
