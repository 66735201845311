import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { IconButtonToggleSelection } from './icon-button-toggle.model';

const SPINNER_COLOR = 'accent';
const SPINNER_DIAMETER = 20;

@Component({
  selector: 'prosumer-icon-button-toggle',
  templateUrl: './icon-button-toggle.component.html',
  styleUrls: ['./icon-button-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonToggleComponent {
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() selected = false;
  @Input() icon: string;
  @Input() label: string;
  @Input() value: string;
  @Input() layout = 'column';
  @Input() checked = false;
  @Input() showToggle = false;
  @Input() color: string;
  @Input() rangeFillOpacity: any;
  @Input() activeEntries: any;
  @Input() waitingResponse: Record<string, boolean> = {};

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<IconButtonToggleSelection<string>>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<MatSlideToggleChange>;
  @Output() primarySelected: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onClick(event: MouseEvent) {
    if (this.disabled || this.readonly) {
      return;
    }
    this.onSelect();
  }

  onKeyUp(event: KeyboardEvent) {
    if (this.disabled || this.readonly) {
      return;
    }
    if ((event && event.keyCode === ENTER) || event.key === ' ') {
      this.onSelect();
    }
  }

  onSelect() {
    this.select.emit({
      selected: !this.selected,
      value: this.value,
      label: this.label,
      icon: this.icon,
    });
  }

  onChange() {
    this.primarySelected.emit(this.value);
  }

  get spinnerColor(): string {
    return SPINNER_COLOR;
  }

  get spinnerDiameter(): number {
    return SPINNER_DIAMETER;
  }
}
