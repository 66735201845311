import { createSelector } from '@ngrx/store';

import { UserState } from './user-state.model';
import { userStateFactory } from './user.factory';

export const userQueries = {
  // TODO: Add in selectors?
  isEntityFetching: createSelector(
    userStateFactory.featureState,
    (state: UserState) => state.entityFetching,
  ),
};
