import { Pipe, PipeTransform } from '@angular/core';
import { LowerCasePipe } from '@angular/common';

import { EnergyVector } from 'prosumer-app/+scenario';

@Pipe({
  name: 'energyVectorIcon',
})
export class EnergyVectorIconPipe implements PipeTransform {
  constructor(private lowerCasePipe: LowerCasePipe) {}

  transform(value: EnergyVector): any {
    if (!!!value) {
      return value;
    }
    return value.custom
      ? this.lowerCasePipe.transform(value.type)
      : this.lowerCasePipe.transform(value.name);
  }
}
