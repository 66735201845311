import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { rowAnimation } from '../../animations';

@Component({
  selector: 'prosumer-table-message',
  templateUrl: './table-message.component.html',
  styleUrls: ['./table-message.component.scss'],
  animations: [rowAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMessageComponent {
  @Input() loading: boolean;
  @Input() error: any;
  @Input() dataLength: number;
  @Input() filteredDataLength: number;
  @Input() filter: string;
  @Input() noRecordsMessage = 'Generic.messages.noRecordsFound';
}
