const typeCache: { [label: string]: boolean } = {};

/**
 * used mostly in store definition of action types.
 * this util determines if developer has repeatedly used a specificic action definition.
 *
 * @param label - the label to be chacked.
 * for the latest prosumer approach, this is deprecated.
 */
export const type = <T>(label: T | ''): T => {
  if (typeCache[label as string]) {
    throw new Error(`Action type '${label}' is already used.`);
  }
  typeCache[label as string] = true;
  return label as T;
};
