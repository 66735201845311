import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { StarRatingInputModule } from '../star-rating-input';
import { QuestionComponent } from './question.component';

@NgModule({
  declarations: [QuestionComponent],
  imports: [
    CommonModule,
    StarRatingInputModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    TranslateModule,
  ],
  exports: [QuestionComponent],
})
export class QuestionModule {}
