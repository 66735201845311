<div style="padding: 24px">
  <div class="mat-body" mat-dialog-content>
    <div [innerHTML]="data?.message"></div>
  </div>
  <div mat-dialog-actions fxLayout="column" fxLayoutGap="10px">
    <mat-checkbox
      data-testid="policy-dialog-checkbox"
      type="checkbox"
      [checked]="accepted"
      (change)="checkValue($event)"
    >
      {{ 'User.labels.gdpr.checkLabel' | translate }}
    </mat-checkbox>
    <button
      data-testid="policy-dialog-submit"
      mat-flat-button
      color="primary"
      (click)="onConfirm()"
      [disabled]="!accepted"
    >
      {{ data?.confirm || 'OK' }}
    </button>
  </div>
</div>
