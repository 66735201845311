import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ChartTooltipData } from '../../models';

@Component({
  selector: 'prosumer-chart-tooltip',
  templateUrl: './chart-tooltip.component.html',
  styleUrls: ['./chart-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTooltipComponent<Y, X, V> {
  @Input() currentTooltipData: ChartTooltipData<Y, X, V>;
  @Input() tooltipDataList: Array<ChartTooltipData<Y, X, V>>;
  @Input() unit: string;
  @Input() titleFormat = (tooltipData: ChartTooltipData<Y, X, V>) =>
    (tooltipData || ({} as ChartTooltipData<Y, X, V>)).name;
}
