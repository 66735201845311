import { Injectable } from '@angular/core';
import { Coerce } from 'prosumer-app/core/utils';
import {
  NO_VARIATION,
  ScenarioVariationQuery,
} from 'prosumer-app/stores/scenario-variation';

@Injectable({ providedIn: 'root' })
export class VariationFinder {
  constructor(private readonly variations: ScenarioVariationQuery) {}

  getVariationName(id: string): string {
    return Coerce.toString(this.variations.getName(id), NO_VARIATION);
  }
}
