import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reference',
})
export class ReferencePipe implements PipeTransform {
  transform(value: string, dataSource: Array<any>, fieldName: string): any {
    return dataSource.find((item) => item[fieldName] === value);
  }
}
