import { FormFieldOption } from '../components';

/**
 * returns the list of the keys of a json object
 *
 * @param obj - any object json
 */
export const getKeys = (obj: any): Array<any> => Object.keys(obj);

/**
 * returns the list of values of a json object
 *
 * @param obj - the json object
 */
export const getValues = (obj: any): Array<any> => {
  if (obj === undefined) {
    return [];
  }
  return Object.values(obj);
};

/**
 * converts the array of values to its equivalent dictionary
 *
 * @param values - array of object
 * @param keyField - key of the json you want to use as dictionary id value
 * @param valueField - value for each dictionary
 */
export const toObject = (
  values: Array<any>,
  keyField: string = 'id',
  valueField: string = 'value',
) => {
  const obj = {};
  if (!!!values) {
    return obj;
  }
  values.forEach((value) => (obj[value[keyField]] = value[valueField]));
  return obj;
};

/**
 * returns the key related to the value in a json object
 *
 * @param obj - the json object
 * @param value - the value of the object you are looking for
 */
export const findKey = (obj: any, value: string): any =>
  getKeys(obj).find((key) => obj[key] === value);

/**
 * used to locate a specific item in an array, returns boolean type
 *
 * @param arr - the list
 * @param value - value of the item you want to find from the string
 */
export const contains = (arr: Array<any> | string, value: any): boolean => {
  if (typeof arr === 'string') {
    return arr.indexOf(value) !== -1;
  }
  return arr.indexOf(value) !== -1;
};

/* deprecated */
export const setDefault = (value: any, defaultValue: any): any => {
  if (value) {
    return value;
  }
  return defaultValue;
};

/**
 * converts the object to list
 *
 * @param obj - the json object to be converted to list
 */
export const toList = (obj: any): Array<any> => {
  if (obj) {
    return getKeys(obj).map((key) => obj[key]);
  }
  return [];
};

/**
 * a list of json object where user can specify the key to make it its dictionary equivalent
 *
 * @param list - list of json object
 * @param key - the key you want to use as dictionary that is found in your list of json
 */
export const toObj = (list: Array<any>, key: string): any => {
  const obj = {};
  if (list) {
    list.forEach((item) => {
      if (item[key]) {
        obj[item[key]] = { ...item };
      }
    });
  }
  return obj;
};

/**
 * returns the list of the keys in s json object
 *
 * @param obj - a json object
 */
export const getObjects = (obj: any) => (obj ? getKeys(obj) : []);

/**
 * used as an action for developer that will do nothing if a condition is met.
 * returns undefined.
 */
export const doNothing = () => {};

/**
 * converts the number values of the json object to string
 *
 * @param data - the json object
 * @param exceptions - the array of keys that does not need conversion
 */
export const convertDataValuesToString = (
  data: any,
  exceptions: Array<string> = [],
) => {
  if (!data) {
    return data;
  }

  for (const key in data) {
    if (typeof data[key] === 'number' && !exceptions.includes(key)) {
      const val = data[key];
      data[key] = String(val);
    }
  }
  return data;
};

/**
 * returns the list of deleted items from the source array
 *
 * @param source - the original array
 * @param target - the array after deletion of an entry
 * @param idField - the indicator of the field to check from the object in the array
 */
export const getDeletedItems = <T>(
  source: Array<T>,
  target: Array<T>,
  idField: string = 'id',
) => {
  if (!source || source.length === 0) {
    return [];
  }

  if (!target || target.length === 0) {
    return [...source];
  }

  const deletedIds = [];
  const sourceIds = source.map((item) => item[idField]);
  const targetIds = target.map((item) => item[idField]);

  sourceIds.forEach((id) =>
    contains(targetIds, id) ? doNothing() : deletedIds.push(id),
  );
  return source.filter((item) => contains(deletedIds, item[idField]));
};

/**
 * is used in loads input components. it simply converts user input new line to an array
 * of string that the backend would understand
 *
 * @param value - the string with '\n' separators
 * @param separator - the separator
 */
export const stringToArray = (value: string, separator: string = '\n') =>
  (value || '').split(separator).filter((data) => data && data.trim() !== '') ||
  [];

/**
 * is used to interpret the load profile from backend to ui.
 * the oposite function of stringToArray.
 *
 * @param value - the array of string
 * @param separator - the separator '\n'
 */
export const arrayToString = (
  value: Array<string>,
  separator: string = '\n',
) => {
  if (!!!value) {
    return '';
  }
  return value.join(separator);
};

/**
 * creates an array that with undefined default values.
 * the length of the array is the number passed
 *
 * @param num - the length of the array you want to create
 */
export const createArrayFromNumber = (num: number = 0) =>
  Array.from(Array(num));

/**
 * Converts an object with nested properties to an array of objects with tha target object properties.
 * The target object properties will correspond to the nested properties that will be used as values.
 * Each combination of nested properties will be an element of the resulting returned array.
 *
 * Example:
 * const nestedObj = {
 *  prop1: {
 *    prop11: {
 *      prop111: 'value1'
 *    },
 *    prop12: {
 *      prop121: 'value2'
 *    }
 *  },
 *  prop2: {
 *    prop21: {
 *      prop211: 'value3
 *    }
 *  }
 * };
 *
 * const result = convertNestedObjToFlattenedArray(nestedObj, ['a', 'b', 'c', 'd']);
 * console.log(result);
 *
 * // Result: [
 * //   { a: 'prop1', b: 'prop11', c: 'prop111', d: 'value1' },
 * //   { a: 'prop1', b: 'prop12', c: 'prop121', d: 'value2' },
 * //   { a: 'prop2', b: 'prop21', c: 'prop211', d: 'value3' }
 * // ]
 *
 * @param nestedObj - an object with nested properties
 * @param targetObjProps - the target object properties of the resulting array's objects
 * @param initObj - the initial object where the target object properties will be generated (default: {})
 * @param resultArray - the resulting array of T with properties defined on targetObjProps (default: [])
 * @param depth - the depth of the recursion that is incremented (default: 0)
 * @return the final resulting array of T objects, each with properties based on the initObj and targetObjProps
 */
export const convertNestedObjToFlattenedArray = <T>(
  nestedObj: any,
  targetObjProps: Array<string>,
  initObj: any = {},
  resultArray: Array<T> = [],
  depth: number = 0,
): Array<T> => {
  if (!!!nestedObj) {
    return [];
  }

  depth++;

  const nestObjKeys = Object.keys(nestedObj);
  nestObjKeys.forEach((key) => {
    const value = nestedObj[key];
    if (depth !== targetObjProps.length - 1) {
      const obj = { ...initObj, [targetObjProps[depth - 1]]: key };
      convertNestedObjToFlattenedArray(
        value,
        targetObjProps,
        obj,
        resultArray,
        depth,
      );
    } else {
      const finalObj = {
        ...initObj,
        [targetObjProps[depth - 1]]: key,
        [targetObjProps[depth]]: value,
      };
      resultArray.push(finalObj);
    }
  });

  return resultArray;
};

/**
 * Checks if each entry in the dictionary has different values
 *
 * @param value - the dictionary value to be checked
 */
export const hasMultipleValues = <T>(value: { [key: string]: T }) => {
  if (!!!value) {
    return false;
  }
  const valueSet = new Set(Object.values(value));
  return valueSet.size > 1;
};

/**
 * Returns true if the searched string contains the substring; Ignore case by default
 *
 * @param searchedString - the source string to be searched
 * @param substring      - the substsring to search
 * @param ignoreCase     - ignore case if true (default: true)
 */
export const containsSubstring = (
  searchedString: string,
  substring: string,
  ignoreCase: boolean = true,
) => {
  if (!!!searchedString || !!!substring) {
    return false;
  }

  if (!!ignoreCase) {
    return searchedString.toLowerCase().search(substring.toLowerCase()) !== -1;
  }
  return searchedString.search(substring) !== -1;
};

/**
 * Returns a list of values based on the key name parameter. Note that the source array is an array of objects.
 *
 * @param objArray - array of ojects
 * @param key - key name to be filtered
 */
export const collectItemsWithKey = (
  objArray: Array<any>,
  key: string = 'key',
) => {
  const arr = [];
  objArray.forEach((obj) => {
    arr.push(obj[key]);
  });
  return arr;
};

/**
 * Converts a list of string to lower case
 *
 * @param arr - array of string
 */
export const convertArrayItemsToLowerCase = (arr: Array<string>) => {
  const list = [];
  if (arr.length > 0) {
    arr.forEach((data) =>
      typeof data === 'string'
        ? list.push(data.toLocaleLowerCase())
        : doNothing(),
    );
  }
  return list;
};

/**
 * match items of array, if one item from arr1 matches arr2, return true
 *
 * @param arr1 - string array 1
 * @param arr2 - string array 2
 */
export const matchArrays = (
  arr1: Array<string>,
  arr2: Array<string>,
): boolean => {
  let match = false;
  arr1.forEach((item) => {
    if (contains(arr2, item)) {
      match = true;
      return;
    }
  });
  return match;
};

/**
 * Must return true only if all items of each array matches
 *
 * @param arr1 - string array 1
 * @param arr2 - string array 2
 */
export const compareArrays = (
  arr1: Array<string>,
  arr2: Array<string>,
): boolean => {
  let match = false;
  if (arr1 && arr2) {
    if (arr1.length === arr2.length) {
      arr1.forEach((item) => {
        if (contains(arr2, item)) {
          match = true;
        } else {
          match = false;
        }
      });
    }
  }
  return match;
};

/**
 * Finds the index of the string in an array where the strings need to be implicitly
 * trimmed and lower cased.
 *
 * @param arr - array of string
 * @param text - string to find
 */
export const findInArray = (arr: Array<string>, text: string): number => {
  let index = -1;
  let idx = 0;
  arr.forEach((item) => {
    if (item.toLowerCase().trim() === text.toLowerCase().trim()) {
      index = idx;
    }
    idx += 1;
  });
  return index;
};

export const findInFormFieldOptions = (
  options: FormFieldOption<string>[],
  value: string,
): FormFieldOption<string> => {
  let formField = { name: '', value: '' };
  options.forEach((option) => {
    if (option.value.toString().trim() === value.toString().trim()) {
      formField = option;
    }
  });
  return formField;
};

export const arrayToFormFieldOptions = (
  arrayItems: any[],
  name = 'name',
  value = 'id',
): FormFieldOption<string>[] => {
  if (arrayItems) {
    if (typeof arrayItems[0] === 'object') {
      return arrayItems.map((data) => ({
        name: data[name],
        value: data[value],
      }));
    } else {
      return arrayItems.map((data) => ({
        name: data,
        value: data,
      }));
    }
  }
  return undefined;
};
