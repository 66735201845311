import { Observable, pipe, throwError } from 'rxjs';
import { catchError, first, mergeMap, mergeMapTo } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AUTH_TYPE, MIME_TYPE } from '../auth.constants';
import { authUtil } from '../auth.util';
import { AuthState } from '../models';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    // get the auth state
    return this.authService.state$.pipe(
      this.updateHeaders(request, next),
      catchError((error: HttpErrorResponse) => {
        if (
          authUtil.isHttpErrorResponse(error) &&
          authUtil.isErrorTokenExpired(error) &&
          authUtil.isStatusCodeForRefreshToken(error.status)
        ) {
          return this.authService
            .refreshToken()
            .pipe(
              mergeMapTo(this.authService.state$),
              this.updateHeaders(request, next),
            );
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Returns a re-usable pipe that fetch the first emitted event of the AuthState source then updates the headers.
   *
   * @usageNotes the source observable should be an AuthState
   * @param request - the http request
   * @param next - the next http handler
   * @returns pipe-able operator
   */
  protected updateHeaders<T = unknown>(
    request: HttpRequest<T>,
    next: HttpHandler,
  ): any {
    return pipe(
      first<T | any>(),
      mergeMap((state: AuthState) =>
        next.handle(request.clone(this.getUpdatedHeaders(state, request))),
      ),
    );
  }

  /**
   * Gets the updated headers with the Authorization and Content-Type.
   * @param state - the auth state
   * @param request - the http request
   */
  protected getUpdatedHeaders(
    state: AuthState,
    request: HttpRequest<unknown>,
  ): { [key: string]: any } {
    const { loggedIn, user } = state;
    const { url } = request;
    const contentType = MIME_TYPE.APPLICATION.JSON;

    let headers = request.headers.set('Content-Type', contentType);

    if (loggedIn && !authUtil.isFileUpload(url)) {
      const authorization = `${AUTH_TYPE.BEARER} ${user.signInUserSession.idToken.jwtToken}`;
      headers = headers.set('Authorization', authorization);
    }

    return { headers };
  }
}
