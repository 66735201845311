import { Output, EventEmitter, Directive } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Options } from '@angular-slider/ngx-slider';

import { BaseComponent } from '../base-component';

@Directive()
export abstract class BaseSliderComponent extends BaseComponent {
  private sliderOptions = new BehaviorSubject<Options>({});

  sliderOptions$ = this.sliderOptions
    .asObservable()
    .pipe(this.takeUntilShare());

  @Output() startChange = new EventEmitter();
  @Output() endChange = new EventEmitter();

  onStartChange(value: number) {
    this.startChange.emit(value);
  }

  onEndChange(value: number) {
    this.endChange.emit(value);
  }

  setSliderOptions(options: Options) {
    this.sliderOptions.next(options);
  }
}
