import { EnergyVector, Line, Node } from 'prosumer-app/+scenario/models';
import { TopologyDialogPrepper } from 'prosumer-app/+scenario/services/dialog-data-preppers';
import { BOOLEAN_OPTIONS } from 'prosumer-app/app.references';
import { PipeUtils, Utils } from 'prosumer-app/core';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  containsSubstring,
  toObject,
} from 'prosumer-app/libs/eyes-shared';
import { DuplicationStarter } from 'prosumer-app/services/duplication-starter';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { LineInfo, LineQuery, LineStore } from 'prosumer-app/stores/line';
import { NodeQuery } from 'prosumer-app/stores/node';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  LinesFormDialogComponent,
  LinesFormDialogData,
} from '../lines-form-dialog';
import { LINES_COLUMN_DEF } from './topology-lines-table.tokens';

type ReferenceKey = 'nodes' | 'energyVectors' | 'bidirectional';
type IDNames = Record<ReferenceKey, Record<string, string>>;
@Component({
  selector: 'prosumer-topology-lines-table',
  templateUrl: './topology-lines-table.component.html',
  styleUrls: ['./topology-lines-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopologyLinesTableComponent {
  readonly columnsDef: ColumnDefinition = LINES_COLUMN_DEF;
  readonly data$ = this.query.selectAllForActiveScenario();
  readonly idNames$ = this.selectIDNames();
  readonly loading$ = this.query.selectLoading();

  @Input() viewOnly = false;

  constructor(
    private readonly query: LineQuery,
    private readonly dialog: DialogService,
    private readonly prepper: TopologyDialogPrepper,
    private readonly lineStore: LineStore,
    private readonly notification: NotificationsService,
    private readonly vectors: EnergyVectorQuery,
    private readonly nodes: NodeQuery,
    private readonly dupStarter: DuplicationStarter,
  ) {}

  isNameSubstring(data: Line, query: string): boolean {
    return containsSubstring(data.name, query);
  }

  onAdd(): void {
    this.dialog.openDialog(
      LinesFormDialogComponent,
      this.prepper.prepLineData(),
    );
  }

  onEdit(line: LineInfo): void {
    this.dialog.openDialog(LinesFormDialogComponent, this.buildEditData(line));
  }

  onDelete(line: LineInfo): void {
    this.lineStore.deleteLine(line.lineId).subscribe({
      error: (error) => {
        const errObj = Utils.resolveToEmptyObject(error.error);
        this.notification.showError(errObj['error'] ?? error.message);
      },
    });
  }

  onDuplicate(line: LineInfo) {
    this.dupStarter
      .start(ScenarioDetailType.line, { ...line, id: line.lineId })
      .pipe(PipeUtils.filterOutNullUndefined)
      .subscribe();
  }

  private selectIDNames(): Observable<IDNames> {
    return this.selectIDNamesTriggers().pipe(
      map(([bi, vex, nodes]) => ({
        bidirectional: this.objectify(bi),
        energyVectors: this.objectify(vex),
        nodes: this.objectify(nodes),
      })),
    );
  }

  private selectIDNamesTriggers(): Observable<
    [unknown[], unknown[], unknown[]]
  > {
    return combineLatest([
      this.selectBidirectional(),
      this.selectVectors(),
      this.selectNodes(),
    ]);
  }

  private selectBidirectional(): Observable<unknown[]> {
    return of(BOOLEAN_OPTIONS);
  }

  private selectVectors(): Observable<EnergyVector[]> {
    return this.vectors.energyVectors$;
  }

  private selectNodes(): Observable<Node[]> {
    return this.nodes.selectActiveNodes();
  }

  private objectify(data: unknown[]): Record<string, string> {
    return toObject(data, 'value', 'name');
  }

  private buildEditData(line: LineInfo): LinesFormDialogData {
    return {
      ...this.prepper.prepLineData(),
      lineData: line,
      mode: 'edit',
    };
  }
}
