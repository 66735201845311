import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { Credentials, Error } from 'prosumer-app/libs/eyes-shared';

import {
  AuthLoginAction,
  AuthLogoutAction,
  AuthRefreshTokenAction,
  AuthSetAsLoggedInAction,
  AuthSetRedirectURLAction,
} from './auth.actions';
import { AuthData, AuthState } from './auth.model';
import { authSelectors } from './auth.reducer';

@Injectable()
export class AuthStore {
  state$: Observable<AuthState> = this._store$.pipe(
    select(authSelectors.state),
  );

  authenticated$: Observable<boolean> = this._store$.pipe(
    select(authSelectors.authenticated),
  );
  loading$: Observable<boolean> = this._store$.pipe(
    select(authSelectors.loading),
  );
  data$: Observable<AuthData> = this._store$.pipe(select(authSelectors.data));
  error$: Observable<Error> = this._store$.pipe(select(authSelectors.error));
  redirectUrl$: Observable<string> = this._store$.pipe(
    select(authSelectors.redirectUrl),
  );

  constructor(private _store$: Store<AuthState>) {}

  login(credentials: Credentials): Observable<AuthState> {
    this._store$.dispatch(new AuthLoginAction(credentials));
    return this.state$;
  }

  refreshToken(data: AuthData): Observable<AuthData> {
    this._store$.dispatch(new AuthRefreshTokenAction(data));
    return this.data$;
  }

  setAsLoggedIn(data: AuthData): Observable<AuthData> {
    this._store$.dispatch(new AuthSetAsLoggedInAction(data));
    return this.data$;
  }

  setRedirectUrl(url: string): Observable<string> {
    this._store$.dispatch(new AuthSetRedirectURLAction(url));
    return this.redirectUrl$;
  }

  logout(): Observable<AuthState> {
    this._store$.dispatch(new AuthLogoutAction());
    return this.state$;
  }
}
