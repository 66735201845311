import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { contains, sortByDate } from 'prosumer-app/libs/eyes-shared';

import {
  NOTIFICATION_STORE_FEATURE,
  NotificationStoreActionTypes,
  NotificationStoreActions,
} from './notification-store.actions';
import { NotificationStoreState } from './notification-store.model';

export const notificationInitialState: NotificationStoreState = {
  loading: false,
  loaded: false,
  error: undefined,
  notifications: [],
  nonSilentNotifications: [],
};

export const notificationStoreReducer = (
  state: NotificationStoreState = notificationInitialState,
  action: NotificationStoreActions,
): NotificationStoreState => {
  switch (action.type) {
    case NotificationStoreActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.payload.notification,
        ].sort((a, b) => sortByDate('dateTimeSent', a, b)),
      };
    case NotificationStoreActionTypes.CLEAR:
      return notificationInitialState;
    case NotificationStoreActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: undefined,
      };
    case NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        notifications: [],
      };
    case NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NotificationStoreActionTypes.FETCH_NOTIFICATIONS:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: undefined,
      };
    case NotificationStoreActionTypes.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NotificationStoreActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: [...action.payload.notificationsList].sort((a, b) =>
          sortByDate('dateTimeSent', a, b),
        ),
      };
    case NotificationStoreActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
      };
    case NotificationStoreActionTypes.REMOVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: [
          ...state.notifications.filter(
            (notification) =>
              notification.id !== action.payload.notification.id,
          ),
        ],
      };
    case NotificationStoreActionTypes.REMOVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case NotificationStoreActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload.notifications],
      };
    case NotificationStoreActionTypes.TAG_NOTIFICATION:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: undefined,
      };
    case NotificationStoreActionTypes.TAG_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NotificationStoreActionTypes.TAG_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: [
          ...state.notifications.filter(
            (notification) =>
              notification.id !== action.payload.notification.id,
          ),
          action.payload.notification,
        ],
      };
    case NotificationStoreActionTypes.READ_NOTIFICATION:
      return { ...state };
    case NotificationStoreActionTypes.READ_NOTIFICATION_FAILURE:
      return { ...state };
    case NotificationStoreActionTypes.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [
          ...state.notifications
            .filter((notification) =>
              contains(action.payload.notification_uuids, notification.id),
            )
            .map((notification) => ({ ...notification, status: 'read' })),
          ...state.notifications.filter(
            (notification) =>
              !contains(action.payload.notification_uuids, notification.id),
          ),
        ],
      };
    default:
      return state;
  }
};

export const notificationStoreFeature: MemoizedSelector<
  Record<string, unknown>,
  NotificationStoreState
> = createFeatureSelector<NotificationStoreState>(NOTIFICATION_STORE_FEATURE);

export const notificationStoreSelectors: {
  [key: string]: MemoizedSelector<Record<string, unknown>, any>;
} = {
  state: notificationStoreFeature,
  loading: createSelector(
    notificationStoreFeature,
    (state: NotificationStoreState) => state.loading,
  ),
  error: createSelector(
    notificationStoreFeature,
    (state: NotificationStoreState) => state.error,
  ),
  loaded: createSelector(
    notificationStoreFeature,
    (state: NotificationStoreState) => state.loaded,
  ),
  notifications: createSelector(
    notificationStoreFeature,
    (state: NotificationStoreState) => state.notifications,
  ),
  nonSilentNotifications: createSelector(
    notificationStoreFeature,
    (state: NotificationStoreState) => [
      ...state.notifications.filter(
        (notification) => notification.type !== 'silent',
      ),
    ],
  ),
};
