import { AbstractControl, ValidatorFn } from '@angular/forms';

import { EMAIL_REGEXP } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

export class EmailValidator {
  static userEmailExistence =
    (): ValidatorFn =>
    (control: AbstractControl): { [key: string]: any } | null => {
      if (!!control && !!control.value && control.value instanceof Array) {
        const userObjects = [...control.value];
        if (userObjects.find((user) => user.error)) {
          return { userDoesntExist: userObjects };
        }
      }
      return null;
    };

  static emails =
    (): ValidatorFn =>
    (control: AbstractControl): { [key: string]: any } | null => {
      if (!!control && !!control.value && control.value instanceof Array) {
        const invalid = control.value.find(
          (user) => !EMAIL_REGEXP.test(user.email),
        );
        if (invalid) {
          return { invalidEmails: { value: control.value } };
        }
      }
      return null;
    };

  static userAlreadyShared =
    (sharedWith$: BehaviorSubject<any>): ValidatorFn =>
    (control: AbstractControl): { [key: string]: any } | null => {
      if (!!control && !!control.value && control.value instanceof Array) {
        const hasAlreadyShared = control.value.some((email) =>
          sharedWith$
            .getValue()
            .map((user) => user.email)
            .includes(email),
        );
        if (hasAlreadyShared) {
          return { hasAlreadyShared: true };
        }
      }
      return null;
    };
}
