<div class="to-overflow" [@fadeInOut]="">
  <div
    class="tooltip"
    [ngClass]="{ 'top-overflow': isTop }"
    [style.maxWidth]="maxWidth"
  >
    <p class="sentence" *ngFor="let sentence of splitted">
      {{ sentence }}
    </p>
  </div>
</div>
