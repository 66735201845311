import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'prosumer-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLinkComponent {
  copied = false;

  @Input() link = '';
  @Input() generating = false;
  @Input() error = '';

  constructor(readonly clipboard: Clipboard) {}

  onCopy(): void {
    this.copied = this.clipboard.copy(this.link);
  }
}
