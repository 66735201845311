import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'prosumer-shared';
import { StackedAreaChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BarAndAreaResultsComponent } from './bar-and-area-results.component';

@NgModule({
  declarations: [BarAndAreaResultsComponent],
  imports: [
    CommonModule,
    ChartsModule,
    TranslateModule,
    StackedAreaChartjsModule,
    StackedBarChartjsModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    FormsModule,
  ],
  exports: [BarAndAreaResultsComponent],
})
export class BarAndAreaResultsModule {}
