<prosumer-page>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
    fxFlex
    *ngIf="hasMeteomatic$ | async; else noMeteo"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="stretch stretch"
      fxLayoutGap="10px"
      fxFlex
    >
      <mat-tab-group color="accent" fxFlex>
        <!-- Solar Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span data-testid="renewable_profile_solar_tab">
              {{ 'RenewableProfile.labels.tabs.solar' | translate }}
            </span>

            <prosumer-tooltip-anchor
              [message]="'renewable_profile.solar'"
              data-testid="renewable-profile-solar-tab-tooltip"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <ng-template matTabContent>
            <prosumer-solar-tab></prosumer-solar-tab>
            <prosumer-latest-solar-profiles></prosumer-latest-solar-profiles>
          </ng-template>
        </mat-tab>
        <!-- Wind Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span data-testid="renewable_profile_wind_tab">
              {{ 'RenewableProfile.labels.tabs.wind' | translate }}
            </span>
            <prosumer-tooltip-anchor
              [message]="'renewable_profile.wind'"
              data-testid="renewable-profile-wind-tab-tooltip"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <ng-template matTabContent>
            <prosumer-wind-tab></prosumer-wind-tab>
            <prosumer-latest-wind-profiles></prosumer-latest-wind-profiles>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <prosumer-coordinates-map fxFlex></prosumer-coordinates-map>
    </div>
  </div>
  <ng-template #noMeteo>
    {{ 'RenewableProfile.messages.noMeteo' | translate }}
  </ng-template>
</prosumer-page>
