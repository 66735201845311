import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Project } from 'prosumer-app/+project';
import { ProjectSharer } from 'prosumer-app/shared/services/project-sharer/project-sharer';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, pluck, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'prosumer-project-share',
  templateUrl: './project-share.component.html',
  styleUrls: ['./project-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectShareComponent {
  private readonly projectCache = new BehaviorSubject<Project>(null);
  @Input() set project(proj: Project) {
    this.projectCache.next(proj);
  }

  readonly generating$ = this.sharer.selectLoading();
  readonly shareUrl$ = this.selectShareUrl();
  readonly shareUrlErr$ = this.selectShareUrlErr();

  constructor(private readonly sharer: ProjectSharer) {
    this.subscribeToProjectForShareTokenRequesting();
  }

  private selectShareUrlErr(): Observable<string> {
    return this.sharer.selectShareTokenError().pipe(pluck('error'));
  }

  private selectShareUrl(): Observable<string> {
    return this.selectTruthyProjectId().pipe(
      switchMap((id) => this.sharer.selectShareUrl(id)),
    );
  }

  private subscribeToProjectForShareTokenRequesting(): void {
    this.selectTruthyProjectId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => this.requestProjectShareToken(id));
  }

  private selectTruthyProjectId(): Observable<string> {
    return this.projectCache.pipe(
      filter((project) => !!project),
      pluck('id'),
    );
  }

  private requestProjectShareToken(id: string): void {
    this.sharer.requestShareToken(id).pipe(take(1)).subscribe();
  }
}
