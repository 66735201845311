import { createSelector } from '@ngrx/store';

import { RenewableProfileState } from './renewable-profile-state.model';
import { renewableProfileStateFactory } from './renewable-profile.factory';

export const renewableProfileQueries = {
  userProfilesList: createSelector(
    renewableProfileStateFactory.featureState,
    (state: RenewableProfileState) => state.userProfiles,
  ),
  renewableProfiles: createSelector(
    renewableProfileStateFactory.featureState,
    (state: RenewableProfileState) => state.renewableProfiles,
  ),
  downloading: createSelector(
    renewableProfileStateFactory.featureState,
    (state: RenewableProfileState) => state.downloading,
  ),
  submitting: createSelector(
    renewableProfileStateFactory.featureState,
    (state: RenewableProfileState) => state.submitting,
  ),
};
