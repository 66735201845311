@if (shouldDisplay) {
  <div>
    <mat-form-field
      [ngClass]="class"
      [appearance]="appearance"
      [hideRequiredMarker]="hideRequired"
      [floatLabel]="floatLabel"
      [hintLabel]="hintLabel"
    >
      <mat-label>{{ label }}</mat-label>
      <mat-select
        #select
        [required]="required"
        [formControl]="control"
        [placeholder]="placeholder"
        [attr.aria-label]="placeholder"
        [hideSingleSelectionIndicator]="true"
        (selectionChange)="onSelectionChange($event)"
        data-testid="select-component"
      >
        @for (option of options; track option.value) {
          <mat-option
            [value]="option?.value"
            [matTooltip]="option.name"
            [matTooltipPosition]="'right'"
            data-testid="select-component-option"
            >{{ option?.name }}</mat-option
          >
        }
      </mat-select>
      @if (tooltip) {
        <mat-icon
          matSuffix
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          [prosumerTooltip]="tooltip"
          >help</mat-icon
        >
      }
      <mat-hint>{{ hint }}</mat-hint>
      @for (error of getErrors(control?.errors); track error) {
        <mat-error [innerHtml]="_errorMessageMap[error]"> </mat-error>
      }
    </mat-form-field>
  </div>
}
