<div class="container">
  <header fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Logs</h2>
  </header>

  <div class="table-container">
    <table
      mat-table
      data-testid="view-logs-table"
      matSort
      class="eyes-table"
      [dataSource]="data$ | async"
    >
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
        <td mat-cell *matCellDef="let element">{{ element.timestamp }}</td>
      </ng-container>
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element">{{ element.message }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <p
      *ngIf="isFetchingLogs$ | async"
      class="table-message"
      data-testid="table-loading"
    >
      {{ 'Generic.messages.loadingData' | translate }}
    </p>
    <p
      *ngIf="
        (isFetchingLogs$ | async) !== true &&
        (hasData$ | async) !== true &&
        (hasError$ | async) !== true
      "
      class="table-message"
      data-testid="table-error"
    >
      {{ 'Generic.messages.noRecordsFound2' | translate }}
    </p>
    <p
      *ngIf="(isFetchingLogs$ | async) !== true && (hasError$ | async)"
      class="table-message"
    >
      {{ 'Generic.messages.errorLoadingData' | translate }}
    </p>
  </div>

  <div
    class="load-more-container"
    *ngIf="hasPreviousLogsToLoad$ | async"
    fxlayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="start center"
  >
    <mat-spinner
      *ngIf="isFetchingPreviousLogs$ | async"
      diameter="20"
      class=""
      color="accent"
    >
    </mat-spinner>
    <small>
      <button
        [disabled]="isFetchingPreviousLogs$ | async"
        (click)="getPreviousLogs()"
      >
        {{ 'Generic.labels.loadMore' | translate }}
      </button>
    </small>
  </div>

  <div class="action" fxLayout="row" fxLayoutAlign="space-between center">
    <button
      mat-flat-button
      mat-button-base
      type="button"
      color=""
      (click)="onClose()"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
    <button
      [disabled]="(isFetchingLogs$ | async) || (hasData$ | async) !== true"
      mat-flat-button
      type="button"
      color="primary"
      class="download"
      (click)="download()"
    >
      <mat-icon aria-hidden="false" matPrefix class="download-icon"
        >cloud_download</mat-icon
      >{{ 'Generic.labels.downloadLogs' | translate }}
    </button>
  </div>
</div>
