import { CloudWatchLogs } from 'aws-sdk';
import { Observable, throwError } from 'rxjs';
import { delay, expand, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  CloudWatchLogEvent,
  CloudWatchLoggerConfig,
} from './cloud-watch-logger.model';

export const DEFAULT_CLOUD_WATCH_LOGS_API_VERSION = '2014-03-28';

export const ERROR_INVALID_CONFIG =
  'ERROR: Invalid CloudWatchLogger configuration!';
export const ERROR_CLOUD_WATCH_NOT_INIT = 'ERROR: CloudWatch not initialized!';

/**
 * A service the integrates with AWS CloudWatch logs.
 *
 * @usageNotes
 * Provide the `CloudWatchLoggerService` to your component or module, depending on the use case.
 *
 * ```
 * import { CloudWatchLoggerService } from '@oculus/utils';
 *
 * @NgModule({
 *   ...
 *   providers: [CloudWatchLoggerService]
 * })
 * export class AppModule {}
 * ```
 *
 * Inject the service to your component, service, or directive, depending on the needs.
 * Subscribe to the `logs$` observable passing in the AWS CloudWatch logger configuration.
 *
 * ```
 * import { CloudWatchLoggerService } from '@oculus/utils';
 * ...
 * export class AppComponent implements OnInit {
 *   constructor(private cloudWatchLogger: CloudWatchLoggerService) { }
 *
 *   ngOnInit(): void {
 *     this.cloudWatchLogger
 *       .logs$({
 *         credentials: {
 *           accessKeyId: '<your-access-id>',
 *           secretAccessKey: '<your-secret-access-key>',
 *           sessionToken: <your-session-token>,
 *         },
 *         region: 'eu-central-1',
 *         logGroupName: '/aws/lambda/billing-d2-get_counter',
 *         logStreamName: '2021/07/09/[$LATEST]7c28ee52044b48f2a28ce457451c4ca9',
 *       })
 *       .subscribe(console.log);
 *   }
 * }
 * ```
 */
@Injectable()
export class CloudWatchLoggerService {
  cloudWatchLogs?: CloudWatchLogs; // AWS CloudWatch logs instance
  public startBackwardToken?: string; // token for previous log. The value is set on first request
  /**
   * An observable that will emit AWS CloudWatch log events.
   *
   * @param config - the AWS CloudWatch logger configuration
   * @param getLogsOnce - set true to only get logs once; otherwise logs will be fetched infinitely until unsubscribed (default: true)
   * @param getLogsDelay - the delay interval in milliseconds (ms) when fetching logs if getLogsOnce is false (default: 5000)
   * @param startFromHead - set false to return latest log events first. (default true)
   * @returns an observable of CloudWatch log events
   */
  logs$ = (
    config: CloudWatchLoggerConfig,
    getLogsOnce = true,
    getLogsDelay = 5000,
    startFromHead = true,
  ): Observable<Array<CloudWatchLogEvent>> => {
    if (!this.isConfigValid(config)) {
      return throwError(ERROR_INVALID_CONFIG);
    }

    this.initCloudWatchLogs(config);

    const { logGroupName, logStreamName } = config;
    const request: AWS.CloudWatchLogs.GetLogEventsRequest = {
      logGroupName,
      logStreamName,
      startFromHead,
    };

    return this.getLogEvents(request, getLogsOnce).pipe(
      // recursively call the observable with delay if takeUntilUnSubscribe flag is true
      expand(({ nextToken }) =>
        this.getLogEvents({ ...request, nextToken }, getLogsOnce).pipe(
          delay(getLogsOnce ? 0 : getLogsDelay),
        ),
      ),
      map(({ events }) => events),
    );
  };

  /**
   * Gets the AWS CloudWatch log events.
   *
   * @param request - the AWS CloudWatch log events request
   * @param getLogsOnce - set true to only get logs once; otherwise logs will be fetched infinitely until unsubscribed (default: true)
   * @param getPreviousLogs - set true to get previous logs. (default: false)
   * @returns an observable of events and nextToken
   */
  getLogEvents(
    request: AWS.CloudWatchLogs.GetLogEventsRequest,
    getLogsOnce = true,
    getPreviousLogs = false,
  ): Observable<{
    events: Array<CloudWatchLogEvent>;
    nextToken?: string;
  }> {
    return new Observable((observer) => {
      // check if cloudwatch is initialized
      if (!this.cloudWatchLogs) {
        observer.error(ERROR_CLOUD_WATCH_NOT_INIT);
        observer.complete();
        return;
      }

      this.cloudWatchLogs.getLogEvents(request, (error, data) => {
        // if an error occur, notify the error and complete the observable
        if (error) {
          observer.error(error.stack);
          observer.complete();
          return;
        }

        // if next token is the same as the next forward token, complete the observable
        const { nextForwardToken, nextBackwardToken, events = [] } = data;
        const nextToken = getPreviousLogs
          ? nextBackwardToken
          : nextForwardToken;
        if (request.nextToken === nextToken && getLogsOnce) {
          // emit last data before completion
          observer.next({
            events: events.map(({ message, timestamp }) => ({
              message,
              timestamp,
            })),
            nextToken,
          });
          observer.complete();
        }

        // set backward token on first request
        if (!this.startBackwardToken) {
          this.startBackwardToken = nextBackwardToken;
        }

        // emit the log events with message and timestamp
        observer.next({
          events: events.map(({ message, timestamp }) => ({
            message,
            timestamp,
          })),
          nextToken,
        });
      });
    });
  }

  /**
   * Initialize the AWS CloudWatch logs.
   *
   * @param param0 - the AWS CloudWatch logger configuration
   */
  initCloudWatchLogs({
    apiVersion,
    credentials,
    region,
  }: CloudWatchLoggerConfig) {
    this.cloudWatchLogs = new CloudWatchLogs({
      credentials,
      region,
      apiVersion: apiVersion || DEFAULT_CLOUD_WATCH_LOGS_API_VERSION,
    });
  }

  /**
   * Checks if the configuration is valid.
   *
   * @param config - the AWS CloudWatch logger configuration
   * @returns true if configuration is valid; otherwise false
   */
  isConfigValid(config: CloudWatchLoggerConfig) {
    return (
      !!config &&
      !!config.credentials &&
      !!config.credentials.accessKeyId &&
      !!config.credentials.secretAccessKey &&
      !!config.credentials.sessionToken &&
      !!config.logGroupName &&
      !!config.logStreamName &&
      !!config.region
    );
  }
}
