import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { SupportConfigService } from '../../../services';
import { generateEndpoint } from '../../../utilities';
import { Support } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SupportApiService {
  constructor(
    private _http: HttpClient,
    private _config: SupportConfigService,
    public _notification: NotificationsService,
    private _translate: TranslatePipe,
  ) {}

  private subject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.subject.asObservable();

  /**
   * Service to create request to backend
   *
   * @param support - support form value
   */
  create(support: Support): Observable<any> {
    this.subject.next(true);
    return this._http
      .post(
        generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.support.support,
        ),
        this.mapToBackend(support),
      )
      .pipe(
        map((response) => {
          this.success(response);
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          this.failure();
          return throwError(
            ((error || ({} as HttpErrorResponse)).error || {}).error,
          );
        }),
        finalize(() => this.subject.next(false)),
      );
  }

  /**
   * Mappers that is understood by backend
   *
   * @param support - support form value
   */
  mapToBackend(support: Support) {
    // add handling of issue type based on selected type of request
    let issue = this._config.support.ISSUE_TYPES.BUG;
    if (
      support.typeOfRequest === 'TR0001' ||
      support.typeOfRequest === 'TR0003'
    ) {
      issue = this._config.support.ISSUE_TYPES.SUPPORT;
    } else if (support.typeOfRequest !== 'TR0000') {
      issue = this._config.support.ISSUE_TYPES.TASK;
    }
    return {
      fields: {
        summary: support.summary,
        url: support.currentUrl || '',
        version: (this._config.version || {}).version
          ? `v${(this._config.version || {}).version}`
          : '',
        request_type: support.typeOfRequest,
        description: support.description,
        issuetype: issue,
      },
    };
  }

  /**
   * Function to handle success call to backend
   *
   * @param response - message
   */
  success(response: any) {
    const message =
      this._translate.transform('Support.message.success') + response.key;
    this._notification.showSuccess(message);
  }

  /**
   * calls the display error message
   */
  failure() {
    const message = this._translate.transform('Support.message.failure');
    this._notification.showError(message);
  }
}
