import { Pipe, PipeTransform } from '@angular/core';

export const OPTIMIZE_TOGGLE_OFF_ICON = 'toggle_off';
export const OPTIMIZE_TOGGLE_ON_ICON = 'toggle_on';

@Pipe({
  name: 'optimizeIconToggle',
})
export class OptimizeIconTogglePipe implements PipeTransform {
  transform(value: boolean): any {
    return value ? OPTIMIZE_TOGGLE_ON_ICON : OPTIMIZE_TOGGLE_OFF_ICON;
  }
}
