import { InjectionToken } from '@angular/core';

import {
  ApiConfig,
  AuthConfig,
  EnvConfig,
  Feature,
  HttpConfig,
  SupportConfig,
  Version,
  WebSocketConfig,
} from 'prosumer-app/libs/eyes-shared';

export const API_CONFIG = new InjectionToken<ApiConfig>('api-config');
export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth-config');
export const ENV_CONFIG = new InjectionToken<EnvConfig>('env-config');
export const HTTP_CONFIG = new InjectionToken<HttpConfig>('http-config');
export const FEATURES_CONFIG = new InjectionToken<Feature>('features');
export const PUBSUB_CONFIG = new InjectionToken<Feature>('pubsub');
export const VERSION_CONFIG = new InjectionToken<Version>('version');
export const WEBSOCKET_CONFIG = new InjectionToken<WebSocketConfig>(
  'websocket-config',
);
export const HOME_URL_REDIRECT_PROVIDER = new InjectionToken(
  'home-url-redirect',
);
export const LOGIN_URL_REDIRECT_PROVIDER = new InjectionToken(
  'login-url-redirect',
);
export const FAQ_URL_REDIRECT_PROVIDER = new InjectionToken('faq-url-redirect');
export const SUPPORT_CONFIG = new InjectionToken<SupportConfig>(
  'support-config',
);
