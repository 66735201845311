import { AbstractControl, ValidatorFn } from '@angular/forms';
import { EnergyGridLimitInterval } from 'prosumer-app/+scenario/models';

export class EnergyGridLimitValidator {
  static validIntervals(): ValidatorFn {
    return (control: AbstractControl) =>
      EnergyGridLimitValidator.areIntervalsValid(control.value)
        ? null
        : { invalidIntervals: true };
  }

  static areIntervalsValid(intervals: EnergyGridLimitInterval[]): boolean {
    return intervals.every((interval) =>
      EnergyGridLimitValidator.isIntervalValid(interval),
    );
  }

  private static isIntervalValid(interval: EnergyGridLimitInterval): boolean {
    const {
      maxCapacity,
      maxEnergy,
      maxPower,
      minCapacity,
      minEnergy,
      minPower,
    } = interval;
    return [
      maxCapacity,
      maxEnergy,
      maxPower,
      minCapacity,
      minEnergy,
      minPower,
    ].some((prop) => !EnergyGridLimitValidator.isFalsy(prop));
  }

  private static isFalsy(value: unknown): boolean {
    return [null, undefined, ''].some((falsyValue) => value === falsyValue);
  }
}
