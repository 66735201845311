<prosumer-page
  [formGroup]="form"
  prosumerDetailEntitiesGetter
  [step]="selectedTab$ | ngrxPush"
>
  <prosumer-scenario-navigation></prosumer-scenario-navigation>

  <div fxLayout="column" fxLayoutGap="8px">
    <div
      fxLayout="column"
      prosumerProjectDurationDetector
      (startYearChange)="durationStart = $event"
      (endYearChange)="durationEnd = $event"
    >
      <mat-progress-bar
        color="accent"
        mode="indeterminate"
        *ngIf="saving$ | async"
        [attr.data-testid]="'scenario-wizard-progress'"
      ></mat-progress-bar>
      <mat-horizontal-stepper
        [linear]="true"
        [ngClass]="{
          'create-mode': (mode$ | async) === 'create',
          'read-mode': (mode$ | async) === 'read',
          saving: saving$ | async
        }"
        (selectionChange)="handleChangeStep($event)"
      >
        <ng-container>
          <ng-template matStepperIcon="edit" let-index="index">
            {{ index + 1 }}.
          </ng-template>
          <ng-template matStepperIcon="number" let-index="index">
            {{ index + 1 }}.
          </ng-template>
        </ng-container>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="general"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.general.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_general'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-general-form
              formControlName="general"
              [loadFormData]="loadFormData$ | async"
              [saving]="saving$ | async"
              [loading]="loading$ | async"
              [mode]="mode$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              [predefinedEnergyVectors]="predefinedEnergyVectors$ | async"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-general-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="timeHorizon"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.time.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_time'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-time-form
              formControlName="time"
              [loadFormData]="loadFormData$ | async"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            ></prosumer-time-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          *ngIf="isMultiNode$ | async"
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="topology"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.topology.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_topology'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-topology-form
              formControlName="topology"
              [loadFormData]="loadFormData$ | async"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            ></prosumer-topology-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="loads"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.loads.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_loads'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-loads-form
              formControlName="loads"
              [loadFormData]="loadFormData$ | async"
              [isMultiNode]="isMultiNode$ | async"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [nodeOptions]="selectedNodes$ | async"
              [currentNetting]="selectedNettings$ | async"
              [evOptions]="selectedEnergyVectors$ | async"
              [scenarioVariationOptions]="selectedScenarioVariations$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [scenarioIdentity]="scenarioIdentity$ | async"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            ></prosumer-loads-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="equipment"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.ders.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_equipment'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-equipment-form
              formControlName="equipments"
              [loadFormData]="loadFormData$ | async"
              [isMultiNode]="isMultiNode$ | async"
              [hasMobilityFeature]="hasMobilityFeature$ | async"
              [scenarioIdentity]="scenarioIdentity$ | async"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [currentNetting]="selectedNettings$ | async"
              [evOptions]="selectedEnergyVectors$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-equipment-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="commodities"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.commodities.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_commodities'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-commodities-form
              formControlName="commodities"
              [loadFormData]="loadFormData$ | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [scenarioIdentity]="scenarioIdentity$ | async"
              [isMultiNode]="isMultiNode$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-commodities-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          *ngIf="hasMobilityFeature$ | async"
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
          data-testid="scenario-wizard-mobility"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="mobility"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.mobility.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_mobility'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-mobility-form
              [mode]="mode$ | async"
              [isMultiNode]="isMultiNode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [loading]="loading$ | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [scenarioIdentity]="scenarioIdentity$ | async"
              [saving]="saving$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            ></prosumer-mobility-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          *ngIf="isClientExpert$ | async"
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="reserves"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.frequencyControl.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_frequency_control'"
              data-testid="frequency-control-form-tooltip"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-frequency-control-form
              formControlName="frequencyControl"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [scenarioIdentity]="scenarioIdentity$ | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [isViewOnly]="isViewOnly() | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              [energyVectors]="selectedEnergyVectors$ | async"
              [equipments]="selectedEquipments$ | async"
              [markets]="selectedEnergyGridConnections$ | async"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-frequency-control-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          *ngIf="hasNettingFeature$ | async"
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="netting"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.netting.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_netting'"
              data-testid="netting-form-tooltip"
            >
            </prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-netting-form
              formControlName="netting"
              [saving]="saving$ | async"
              [loading]="loading$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nodeOptions]="selectedNodes$ | async"
              [evOptions]="selectedEnergyVectors$ | async"
              [isMultiNode]="isMultiNode$ | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              [energyVectors]="selectedEnergyVectors$ | async"
              [equipments]="selectedEquipments$ | async"
              [loads]="selectedLoads$ | async"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-netting-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
          data-testid="scenario-wizard-regulation"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="regulation"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.regulation.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_regulation'"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-regulation-form
              formControlName="regulations"
              [saving]="saving$ | async"
              [loading]="loading$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="(loading$ | async) || (saving$ | async)"
              [equipments]="selectedEquipments$ | async"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-regulation-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              forStep="optimizationCockpit"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.optimzationCockpit.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_optimization_cockpit'"
              data-testid="scenario-wizard-optimization-cockpit-tooltip"
            ></prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card>
            <prosumer-optimization-cockpit-form
              formControlName="optimizationCockpit"
              [startYear]="durationStart"
              [endYear]="durationEnd"
              [loading]="loading$ | async"
              [saving]="saving$ | async"
              [mode]="mode$ | async"
              [isViewOnly]="isViewOnly() | async"
              [scenarioIdentity]="scenarioIdentity$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="(loading$ | async) || (saving$ | async)"
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="
                (loading$ | async) ||
                (saving$ | async) ||
                (mode$ | async) === 'read'
              "
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-optimization-cockpit-form>
          </prosumer-progress-card>
        </mat-step>

        <mat-step
          [completed]="canChangeSteps(mode$ | async)"
          [editable]="canChangeSteps(mode$ | async)"
          prosumerCoherenceChecker
          (results)="coherenceResults = $event"
          (coherencing)="coherencing = $event"
          (haveErrors)="hasCoherenceErrs = $event"
        >
          <ng-template matStepLabel>
            <prosumer-filled-indicator
              data-testid="launch-filled-indicator"
              forStep="launch"
            ></prosumer-filled-indicator>
            {{ 'Scenario.labels.wizard.summary.step' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_step_names.wizard_step_launch'"
            >
            </prosumer-tooltip-anchor>
          </ng-template>
          <prosumer-progress-card [showProgress]="simulating$ | async">
            <prosumer-summary-form
              [validating]="coherencing"
              [coherenceResults]="coherenceResults"
              [hasCoherenceError]="hasCoherenceErrs"
              [mode]="mode$ | async"
              [checkingConflicts]="checkingConflicts$ | async"
              [previousLabel]="previousLabel$ | async | translate"
              [previousDisabled]="
                (loading$ | async) || (saving$ | async) || (simulating$ | async)
              "
              [nextColor]="'primary'"
              [nextLabel]="nextLabel$ | async | translate"
              [nextDisabled]="
                (loading$ | async) || (saving$ | async) || (simulating$ | async)
              "
              (previous)="onPrevious($event)"
              (next)="onNext($event)"
            >
            </prosumer-summary-form>
          </prosumer-progress-card>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</prosumer-page>
