import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
} from '@angular/core';

import { FluidConfigData } from '../../models';
import { EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'prosumer-diagram-fluids-legend',
  templateUrl: './diagram-fluids-legend.component.html',
  styleUrls: ['./diagram-fluids-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagramFluidsLegendComponent {
  _fluids: Array<FluidConfigData>;
  activeFluids: Array<FluidConfigData>;
  selection = new SelectionModel<any>(true);
  @Output() selectedChange = new EventEmitter<Array<string>>();

  /**
   * Maintain the original collection of fluids
   * Populate the activeFluids which will be shown
   * To maintain the current state of each fluid
   * Append new fluids if incoming fluids count is greater
   */
  @Input() set fluids(incomingFluids: Array<FluidConfigData>) {
    if (!!!this._fluids) {
      this._fluids = !!incomingFluids ? incomingFluids.sort() : null;
      this.selection.select(
        ...(!!this._fluids ? this._fluids.map((fluid) => fluid.name) : []),
      );
      this.activeFluids = this._fluids;
    } else if (this._fluids.length < incomingFluids.length) {
      const existingFluidNames = this._fluids.map((fluid) => fluid.name);
      const newFluids = incomingFluids.filter(
        (fluid) => !existingFluidNames.includes(fluid.name),
      );
      this._fluids = [].concat(...this._fluids, ...newFluids).sort(); // Do this to maintain state of existing fluids
      this.selection.select(...newFluids.map((fluid) => fluid.name));
      this.activeFluids = this._fluids;
      this.selectedChange.emit(this.selection.selected);
    } else if (
      this._fluids.length === incomingFluids.length &&
      !this.checkIfIdentical(this._fluids, incomingFluids)
    ) {
      const existingFluidNames = this._fluids.map((fluid) => fluid.name);
      const newFluids = incomingFluids.filter(
        (fluid) => !existingFluidNames.includes(fluid.name),
      );
      this._fluids = [].concat(...this._fluids, ...newFluids).sort(); // Do this to maintain state of existing fluids
      this.selection.select(...newFluids.map((fluid) => fluid.name));
      this.activeFluids = incomingFluids;
      this.selectedChange.emit(this.selection.selected);
    } else {
      const fluidNames = incomingFluids
        ? incomingFluids.map((fluid) => fluid.name)
        : [];
      this.activeFluids = this._fluids
        .filter((fluid) => fluidNames.includes(fluid.name))
        .sort();
    }
  }

  get fluids() {
    return this._fluids;
  }

  /**
   * Toggles the selected fluid selected/deselected
   * Also emits the currently selected fluids after toggling
   *
   * @param index Index from the list of fluids that is clicked
   */
  toggleLegend(index) {
    this.selection.toggle(this.activeFluids[index].name);
    this.selectedChange.emit(this.selection.selected);
  }

  /**
   * Checks if contains the same fluids
   */
  checkIfIdentical(
    existingFluids: Array<FluidConfigData>,
    incomingFluids: Array<FluidConfigData>,
  ) {
    const existing = existingFluids.map((_) => _.name).sort();
    const incoming = incomingFluids.map((_) => _.name).sort();

    return JSON.stringify(existing) === JSON.stringify(incoming);
  }

  /**
   * Gets all unique fluids
   */
  getUniqueFluids(
    existingFluids: Array<FluidConfigData>,
    incomingFluids: Array<FluidConfigData>,
  ) {
    const allFluids = [...existingFluids, ...incomingFluids].reduce(
      (collection, current) => (collection[current.name] = current),
      {},
    );
    return Object.keys(allFluids).map((key) => ({ ...allFluids[key] }));
  }
}
