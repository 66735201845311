import {
  StationVehicleAssoc,
  VehiclesDispatch,
} from 'prosumer-app/+scenario/models';
import { contains } from 'prosumer-app/libs/eyes-shared';

/**
 * Update the station/vehicle association using the same named vehicles dispatch
 *
 * If a deleted vehicle dispatch has another vehicle dispatch with the same name,
 * Update the associated station/vehicle association vehicleNames[] to reference the
 * remaining vehicle dispatch.
 *
 * @param stationVehicleAssoc   the initial station/vehicle association
 * @param currentVehiclesList   the previous vehicles dispatch list
 * @param remainingVehiclesList the remaining vehicles dispatch list
 * @return vehicle station associations with updated vechilesNames[]
 */
export const getUpdatedStationVehicleAssoc = (
  stationVehicleAssoc: Array<StationVehicleAssoc>,
  currentVehiclesList: Array<VehiclesDispatch>,
  remainingVehiclesList: Array<VehiclesDispatch>,
) => {
  const remainingVehiclesIds = remainingVehiclesList.map(
    (vehicle) => vehicle.id,
  );
  const updatedAssociation: StationVehicleAssoc[] = [];
  stationVehicleAssoc.forEach((assoc) => {
    const newVehicleNames = [];
    assoc.vehicleNames.forEach((vehicleName) => {
      if (contains(remainingVehiclesIds, vehicleName)) {
        newVehicleNames.push(vehicleName);
      } else {
        let name = '';
        const sameName = currentVehiclesList.filter(
          (vehicle) => vehicle.id === vehicleName,
        );
        if (sameName.length > 0) {
          name = sameName[0].vehicleName;
        }
        const checkSameName = currentVehiclesList.filter(
          (vehicle) =>
            vehicle.vehicleName === name && vehicle.id !== vehicleName,
        );
        if (checkSameName.length > 0) {
          newVehicleNames.push(checkSameName[0].id);
        } else {
          newVehicleNames.push(vehicleName);
        }
      }
    });
    updatedAssociation.push({
      ...assoc,
      vehicleNames: newVehicleNames,
    });
  });
  return updatedAssociation;
};
