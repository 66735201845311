import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Breadcrumb } from '../../models';

@Injectable()
export class BreadcrumbService {
  dynamicBreadCrumbs: Subject<Breadcrumb> = new Subject<Breadcrumb>();

  constructor() {}

  customTitle(title: string, component?) {
    const crumb: Breadcrumb = { label: title, component };
    this.dynamicBreadCrumbs.next(crumb);
  }
}
