import { Netting, TaxAndSubsidies } from 'prosumer-app/+scenario/models';
import { contains } from 'prosumer-app/libs/eyes-shared';

/** Return tax & subsidies after filtering all nettings affected by cascading delete
 *
 * @param taxAndSubsidies list of tax & subsidy items
 * @param remainingNettingIds ids of remaining netting items in the scenario
 * @return filtered taxAndSubsidies based on remaining netting
 */
export const cascadeFilterTaxAndSubsidiesByNetting = (
  taxAndSubsidies: Array<TaxAndSubsidies>,
  remainingNettingIds: Array<string>,
) => {
  if (!!!taxAndSubsidies || !!!remainingNettingIds) {
    return undefined;
  }

  return taxAndSubsidies.filter((taxAndSubsidy) =>
    contains(remainingNettingIds, taxAndSubsidy.netting),
  );
};

/** Return string containing list of affected tax & subsidies
 *
 * @param taxAndSubsidies list of tax & subsidy items
 * @param nettings list of tax & subsidy items
 * @return string containing list of affected tax & subsidies
 */
export const getAffectedTaxAndSubsidies = (
  taxAndSubsidies: Array<TaxAndSubsidies>,
  nettings: Array<Netting>,
) => {
  if (!!!taxAndSubsidies || !!!nettings) {
    return undefined;
  }

  return taxAndSubsidies
    .map((taxAndSubsidy) => {
      const nettingName = nettings.find(
        (netting) => netting.id === taxAndSubsidy.netting,
      ).name;
      return `Netting: ${nettingName}`;
    })
    .filter(
      (value, index, taxAndSubsidies) =>
        taxAndSubsidies.indexOf(value) === index,
    )
    .reduce((prev, next) => prev + `<li>${next}</li>`, '');
};
