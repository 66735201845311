import { ResultsData } from '@prosumer/results/models';
import { Utils } from 'prosumer-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  VisualizerActiveFilter,
  VisualizerFilter,
} from './results-visualizer.model';

export abstract class BaseResultsVisualizerService<T = ResultsData> {
  private activeFilterSubject = new BehaviorSubject<VisualizerActiveFilter>({});
  private secondaryFilterSubject = new BehaviorSubject<string[]>([]);

  abstract getFilterKeys(): string[];
  abstract getResultDataStream(): Observable<T[]>;
  abstract getAllYearsStream(): Observable<number[]>;

  setActiveFilter(key: string, active: string[]): void {
    const current = this.activeFilterSubject.value;
    current[key] = active;
    this.activeFilterSubject.next(current);
  }

  updateSecondaryFilter(value: string): void {
    const updated = this.resolveUpdatedSecondaryFilterList(
      this.secondaryFilterSubject.value,
      value,
    );
    this.secondaryFilterSubject.next(updated);
  }

  clearActiveFilters(): void {
    this.activeFilterSubject.next({});
  }

  clearSecondaryFilters(): void {
    this.secondaryFilterSubject.next([]);
  }

  getAvailableFiltersStream(): Observable<VisualizerFilter[]> {
    return this.getResultDataStream().pipe(
      map(this.buildFilterKeyAndOptionsDictionary.bind(this)),
      map(this.resolveFilterDictToVizFilters.bind(this)),
    ) as Observable<VisualizerFilter[]>;
  }

  private initializeActiveFilters(): VisualizerFilter[] {
    return this.getFilterKeys().map((label) => ({ label, options: [] }));
  }

  getFilteredRawDataStream(): Observable<T[]> {
    return combineLatest([
      this.activeFilterSubject,
      this.getResultDataStream(),
    ]).pipe(
      map(([active, raw]) =>
        this.shouldFilterData(active)
          ? raw.filter((single) => this.shouldIncludeRawSingle(active, single))
          : raw,
      ),
      map((data) => this.filterOutZeroValues(data)),
    );
  }

  filterOutZeroValues(data: T[]): T[] {
    // return data.filter((datum) => !!datum['value']);
    return data;
  }

  getSecondaryFilters$(): Observable<string[]> {
    return this.secondaryFilterSubject.asObservable();
  }

  private resolveUpdatedSecondaryFilterList(
    running: string[],
    value: string,
  ): string[] {
    return running.includes(value)
      ? running.filter((val) => val !== value)
      : [...running, value];
  }

  private buildFilterKeyAndOptionsDictionary(
    data: unknown[],
  ): VisualizerActiveFilter {
    return data.reduce((acc, curr) => {
      this.getFilterKeys().forEach((key) => {
        const current = acc[key] || [];
        const stringizedValue = String(curr[key]);
        acc[key] = [...current, stringizedValue];
      });
      return acc;
    }, {}) as VisualizerActiveFilter;
  }

  private resolveFilterDictToVizFilters(
    filterDict: VisualizerActiveFilter,
  ): VisualizerFilter[] {
    return !!Object.keys(filterDict).length
      ? Object.entries(filterDict).map(([label, options]) => ({
          label,
          options: this.removeDuplicatesAndEmptyStrings(options),
        }))
      : this.initializeActiveFilters();
  }

  private removeDuplicatesAndEmptyStrings(options: string[]): string[] {
    return Utils.removeEmptyStrings(Utils.removeDuplicates(options));
  }

  private shouldFilterData(activeFilter: VisualizerActiveFilter): boolean {
    return Object.values(activeFilter).some((values) => values.length);
  }

  private shouldIncludeRawSingle(
    active: VisualizerActiveFilter,
    single: T,
  ): boolean {
    return Object.entries(active)
      .filter(([, values]) => !!values.length)
      .every(([key, values]) => values.includes(String(single[key])));
  }
}
