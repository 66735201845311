<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelActivate)="onActivate($event, null)"
  (legendLabelDeactivate)="onDeactivate($event, null)"
  (legendLabelClick)="onClick($event)"
>
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g
      ngx-charts-grid-panel-series
      [xScale]="groupScale"
      [yScale]="valueScale"
      [data]="results"
      [dims]="dims"
      orient="vertical"
    ></svg:g>
    <svg:g
      ngx-charts-x-axis
      *ngIf="xAxis"
      [xScale]="groupScale"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      [xAxisOffset]="dataLabelMaxHeight.negative"
      (dimensionsChanged)="updateXAxisHeight($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="valueScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [tickFormatting]="yAxisTickFormatting"
      [ticks]="yAxisTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      *ngFor="let group of results; let index = index; trackBy: trackBy"
      [attr.transform]="groupTransform(group)"
      [@animationState]="'active'"
    >
      <svg:g
        ngx-charts-series-vertical
        *ngFor="
          let group1 of group.series;
          let index1 = index;
          trackBy: trackBy
        "
        [@animationState]="'active'"
        type="stacked"
        [attr.transform]="innerGroupTransform(group1)"
        [activeEntries]="activeEntries"
        [xScale]="innerScale"
        [yScale]="valueScale"
        [colors]="colors"
        [series]="getInnerSeries(group1, group.name)"
        [dims]="dims"
        [gradient]="gradient"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="dataLabelFormatting"
        [seriesName]="group1.name"
        [roundEdges]="roundEdges"
        [animations]="animations"
        (select)="onClick($event, group1.series)"
        (activate)="onActivate($event, group1.series)"
        (deactivate)="onDeactivate($event, group1.series)"
        (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index1)"
      />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
