import {
  Component,
  ChangeDetectionStrategy,
  Optional,
  Inject,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OverviewData, LineData, FluidConfig, Connector } from '../../models';
import { SystemVisualizationService } from '../../services';
import {
  SYS_VIS_FLUID_CONFIG,
  PREDEFINED_FLUID_LABEL_CONFIG,
  CUSTOM_FLUID_COLOR_PALETTE,
} from '../../system-visualization.token';

import { BaseContainerComponent } from '../base-container.component';

@Component({
  selector: 'prosumer-overview-container',
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewContainerComponent
  extends BaseContainerComponent<OverviewData>
  implements OnChanges
{
  // The filtered overview data observable for diagram component input
  data$ = combineLatest([this.showOptimized$, this.selectedFluids$]).pipe(
    map(([showOptimized, fluids]) => {
      const optimizedData = this.toggleOptimizedElements(
        this.data,
        showOptimized,
        'lines',
      );
      if (!!fluids) {
        // Only perform filter when fluids is instantiated
        return this.toggleElementsFromSelectedFluids(optimizedData, fluids);
      }

      return optimizedData;
    }),
    this.takeUntilShare(),
  );

  // The filtered fluids for legend component input
  fluids$ = this.data$.pipe(
    filter((data) => !!data),
    map((data) =>
      this.filterFluids(
        data.lines.filter(
          (line) =>
            !(line.hideable && line.hidden && this.showOptimized$.value),
        ),
        'energyVector',
      ),
    ),
    this.takeUntilShare(),
  );

  constructor(
    public systemVisualization: SystemVisualizationService,
    @Optional() @Inject(SYS_VIS_FLUID_CONFIG) public fluidConfig: FluidConfig,
    @Optional()
    @Inject(PREDEFINED_FLUID_LABEL_CONFIG)
    public fluidLabelConfig: { [key: string]: string },
    @Optional()
    @Inject(CUSTOM_FLUID_COLOR_PALETTE)
    public customFluidColorPalette: Array<string>,
  ) {
    super(fluidConfig, fluidLabelConfig, customFluidColorPalette);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      !!changes.data.currentValue &&
      !!changes.data.currentValue.lines
    ) {
      this.updateFluidConfigData(
        changes.data.currentValue.lines as Array<LineData>,
        'energyVector',
      );
    }
  }

  /**
   * Toggles the hidden property to true if it's Fluid property is in the fluids collection
   *
   * @param edges The lines connecting the nodes
   * @param fluids Currently selected/active fluids or energy vectors
   */
  toggleEdgeElements(edges: Array<Connector>, fluids: Array<string>) {
    return [
      ...edges.map((edge) => ({
        ...(edge as any),
        hidden: edge.hidden || !fluids.includes(edge.rawData.Fluid),
      })),
    ];
  }

  /**
   * Checks if a node is still has any connection by checking the active edges.
   * Returns all active nodes.
   *
   * @param nodes The node elements of the diagram to be checked if still connected
   * @param edges The active edges that links the nodes
   */
  // toggleNodeElements(nodes: Array<NodeData>, edges: Array<Connector>) {
  //   const enabledNodes = Array.from(new Set([].concat(...edges.filter(edge => !edge.hidden)
  //                                 .map(edge => [edge.rawData.origin_id, edge.rawData.destination_id]))));
  //   return nodes.map(node => ({...node, hidden: !enabledNodes.includes(node.id)}));
  // }

  toggleElementsFromSelectedFluids(
    data: OverviewData,
    fluids: Array<string>,
  ): OverviewData {
    const edges = this.toggleEdgeElements(data.lines, fluids);
    // const nodes = this.toggleNodeElements(data.nodes, edges);
    const newData = { ...(data as any) };
    newData.lines = edges;
    // newData.nodes = nodes;
    return { ...newData };
  }
}
