import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { AuthActionTypes } from './auth/index';
import { routerReducerExtended } from './router/index';

export const effects = [];
export const reducers: ActionReducerMap<any> = {
  router: routerReducerExtended,
};

export const debugReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    const nextState = reducer(state, action);
    console.group(action.type);
    console.log(
      `%c previous state`,
      `color: #9E9E9E; font-weight: bold`,
      state,
    );
    console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
    console.log(
      `%c next state`,
      `color: #4CAF50; font-weight: bold`,
      nextState,
    );
    console.groupEnd();
    return nextState;
  };

export const clearStatesReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === AuthActionTypes.LOGOUT_SUCCESS) {
      console.log(`%c Clearing states...`, `color: #FA113D; font-weight: bold`);
      state = undefined;
    }
    const nextState = reducer(state, action);
    return nextState;
  };

export const metaReducers: MetaReducer<any>[] = [
  debugReducer,
  clearStatesReducer,
];
