import { Injectable } from '@angular/core';
import {
  EmissionsData,
  EquipmentResult,
  TopologyResult,
} from '@prosumer/results/models';
import {
  AllYearsExtractor,
  BaseExtractor,
  CashflowExtractor,
  EmissionsExtractor,
  EnergyBalanceExtractor,
  EquipmentExtractor,
  TopologyExtractor,
} from '@prosumer/results/state';

import { PipeUtils, Utils } from 'prosumer-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ComparisonTray } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ComparisonPreparerService {
  private traySubject = new BehaviorSubject<ResultsTray>({});

  constructor() {}

  clearTray(): void {
    this.traySubject.next({});
  }

  addToTray(scenarioName: string, results: unknown): void {
    const current = this.traySubject.value;
    this.traySubject.next({ ...current, [scenarioName]: results });
  }

  getEquipmentTray$(): Observable<ComparisonTray<EquipmentResult>> {
    return this.createComparisonTrayFromResultsTray('equipment');
  }

  getTopologyTray$(): Observable<ComparisonTray<TopologyResult>> {
    return this.createComparisonTrayFromResultsTray('topology');
  }

  getEmissionTray$(): Observable<ComparisonTray<EmissionsData>> {
    return this.createComparisonTrayFromResultsTray('emissions');
  }

  getAllYears$(): Observable<number[]> {
    return this.createComparisonTrayFromResultsTray('allYears').pipe(
      map((yearsTray) =>
        this.reduceAllYearsToUniqueYears(
          Object.values(yearsTray) as number[][],
        ),
      ),
      map((years) => years.sort()),
    );
  }

  private reduceAllYearsToUniqueYears(allYears: number[][]): number[] {
    const reduced = allYears.reduce((acc, curr) => [...acc, ...curr], []);
    return Utils.removeDuplicates(reduced);
  }

  private createComparisonTrayFromResultsTray<T>(
    extractorKey: string,
  ): Observable<ComparisonTray<T>> {
    return this.getDataStream().pipe(
      map((tray) => this.mapEachResultsToComparisonData<T>(tray, extractorKey)),
    );
  }

  private mapEachResultsToComparisonData<T>(
    tray: ResultsTray,
    extractorKey: string,
  ): ComparisonTray<T> {
    return Object.entries(tray).reduce((acc, [scenario, results]) => {
      acc[scenario] = this.getExtractor<T>(extractorKey).extract(results);
      return acc;
    }, {});
  }

  private getDataStream(): Observable<ResultsTray> {
    return this.traySubject
      .asObservable()
      .pipe(PipeUtils.filterOutUndefined, debounceTime(100));
  }

  private getExtractor<T>(key: string): BaseExtractor<T> {
    const extractors = {
      equipment: EquipmentExtractor,
      cashflow: CashflowExtractor,
      topology: TopologyExtractor,
      emissions: EmissionsExtractor,
      energyBalance: EnergyBalanceExtractor,
      allYears: AllYearsExtractor,
    };
    return new extractors[key]();
  }
}

type ResultsTray = { [scenarioId: string]: unknown };
type NameAndId = { name: string; id: string };
