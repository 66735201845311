/**
 * This form service will serve as a helper service to maintain multiple
 * child form components that will help manage the form that control value
 * accessor should actually do but for some reason, it doesn't.
 */

import { Profile } from 'prosumer-app/+scenario';
import { collectItemsWithKey, contains } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { ProfileFormHelperData } from './profile-form-helper.service.model';

@Injectable()
export class ProfileFormHelperService {
  private _submitted$ = new Subject<void>();
  private _profile$ = new Subject<ProfileFormHelperData>();
  private _invalidProfiles = new BehaviorSubject<Set<number>>(new Set());

  // eslint-disable-next-line @typescript-eslint/member-ordering
  submitted$ = this._submitted$.asObservable(); // boolean trigger if a form was submitted

  // an object containing the index of the current profile, the array of profiles and the type of source the profile was loaded
  // eslint-disable-next-line @typescript-eslint/member-ordering
  profile$ = this._profile$.asObservable();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  invalidProfiles$ = this._invalidProfiles.asObservable(); // index of the invalid profiles

  constructor() {}

  /**
   * changes the behavior of submitted$ by simply calling it
   */
  setSubmitted(): void {
    this._submitted$.next();
  }

  /**
   * sets the value of the profile$ by passing the param p
   *
   * @param p - and object containing the index, array data and type of profile submitted
   */
  setProfile(p: ProfileFormHelperData): void {
    this._profile$.next(p);
  }

  /**
   * sets the value of the invalidProfiles$
   *
   * @param invalidProfiles - the set of invalid indexes that are made unique via Set
   */
  setInvalidProfiles(invalidProfiles: Set<number>) {
    this._invalidProfiles.next(invalidProfiles);
  }

  /**
   * returns the value of the invalid profiles
   */
  getInvalidProfiles() {
    return this._invalidProfiles.value;
  }

  /**
   * set from the component level where we pass the index of the invalid profile
   *
   * @param index the index of the invalid profile
   */
  addInvalidProfile(index: number) {
    this.setInvalidProfiles(this.getInvalidProfiles().add(index));
  }

  /**
   * set from the component level where we pass the index of the invalid profile to be deleted
   *
   * @param index the index of the invalid profile to be removed
   */
  removeInvalidProfile(index: number) {
    const invalidProfiles = this.getInvalidProfiles();
    invalidProfiles.delete(index);
    this.setInvalidProfiles(invalidProfiles);
  }

  /**
   * called from the component level to return true if the index is found, which means,
   * the profile with that index is still invalid
   *
   * @param index the index of the invalid profile you want to check for invalidness
   */
  isInvalid(index: number) {
    return this.getInvalidProfiles().has(index);
  }

  /**
   * clears the list of the invalid profiles
   */
  clearInvalidProfiles() {
    const invalidProfiles = this.getInvalidProfiles();
    invalidProfiles.clear();
    this.setInvalidProfiles(invalidProfiles);
  }

  /**
   * helper to check if the local id is new or old.
   * Once deemed to be old, return true and let the component proceed with binary download
   *
   * @param persistedProfiles - list of the current profiles of the certain load
   * @param localId - the local id of the profile to be checked
   */
  forBinDownload(persistedProfiles: Array<Profile>, localId: string) {
    const localIds = collectItemsWithKey(
      [...new Set(persistedProfiles)],
      'localId',
    );
    return contains(localIds, localId);
  }
}
