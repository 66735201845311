import moment from 'moment';
import { ConfigService, HttpService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, takeWhile, withLatestFrom } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CloudWatchLoggerConfig,
  CloudWatchLoggerService,
} from '@oculus/utils/services';

import { CloudWatchLogEvent } from './view-logs.models';

@Component({
  selector: 'prosumer-view-logs',
  templateUrl: './view-logs.component.html',
  styleUrls: ['./view-logs.component.scss'],
  providers: [CloudWatchLoggerService],
})
export class ViewLogsComponent implements OnInit {
  displayedColumns: string[] = ['timestamp', 'message'];
  data$ = new BehaviorSubject<Array<CloudWatchLogEvent>>([]);
  isFetchingLogs$ = new BehaviorSubject<boolean>(true);
  /**
   * flag for disabling load more button. (default: false)
   */
  isFetchingPreviousLogs$ = new BehaviorSubject<boolean>(false);
  hasPreviousLogsToLoad$ = new BehaviorSubject<boolean>(false);
  hasData$ = new BehaviorSubject<boolean>(false);
  hasError$ = new BehaviorSubject<boolean>(false);
  fetchCredentialsSubscription: Subscription;
  logsSubcription: Subscription;
  previousLogsSubs: Subscription;
  private isCompleteSimulation$ = new BehaviorSubject<boolean>(false);
  private currentBackwardToken: string;
  private cloudWatchConfig: CloudWatchLoggerConfig;

  private startFromHead = false;

  constructor(
    private matDialogRef: MatDialogRef<ViewLogsComponent>,
    private http: HttpService,
    private _config: ConfigService,
    public cloudWatchLoggerService: CloudWatchLoggerService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  initFetchCredentials() {
    const { projectId, caseId, scenarioId } = this.data;
    const endpoint = `projects/${projectId}/cases/${caseId}/scenarios/${scenarioId}/simulation_log`;
    this.fetchCredentialsSubscription = this.http
      .get(generateEndpoint(this._config.api.baseUrl, endpoint))
      .subscribe(this.initFetchLogs, this.error);
  }

  error = () => {
    this.isFetchingLogs$.next(false);
    this.hasError$.next(true);
  };

  initFetchLogs = ({
    AccessKeyId: accessKeyId,
    Region: region,
    SecretAccessKey: secretAccessKey,
    SessionToken: sessionToken,
    stream_name: logStreamName,
  }) => {
    this.cloudWatchConfig = {
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
      logStreamName,
      logGroupName: `/aws/batch/job`,
      region,
    };

    this.logsSubcription = this.cloudWatchLoggerService
      .logs$(this.cloudWatchConfig, false, 5000, true)
      .pipe(
        withLatestFrom(this.isCompleteSimulation$),
        map(([logs, isComplete]) => ({
          logs,
          isComplete,
        })),
        takeWhile(({ isComplete }) => !isComplete, true),
      )
      .subscribe(({ logs }) => {
        if (!!logs.length) {
          this.hasData$.next(true);
          this.hasPreviousLogsToLoad$.next(true);
        }
        this.isFetchingLogs$.next(false);
        const formattedData = this.formatLogs(logs);
        this.data$.next(formattedData.concat(this.data$.getValue()));
      }, this.error);
  };

  getPreviousLogs() {
    this.isFetchingPreviousLogs$.next(true);
    if (!this.cloudWatchConfig) {
      this.isFetchingPreviousLogs$.next(false);
      return;
    }

    if (
      !!this.currentBackwardToken &&
      this.cloudWatchLoggerService.startBackwardToken ===
        this.currentBackwardToken
    ) {
      // no more logs to retrieve, disable load more
      this.isFetchingPreviousLogs$.next(false);
      return;
    }

    // request logs to oculus using getLogEvents
    const { logGroupName, logStreamName } = this.cloudWatchConfig;
    this.previousLogsSubs = this.cloudWatchLoggerService
      .getLogEvents(
        {
          logGroupName,
          logStreamName,
          startFromHead: this.startFromHead,
          nextToken:
            this.currentBackwardToken ||
            this.cloudWatchLoggerService.startBackwardToken,
        },
        true,
        true,
      )
      .subscribe((logs) => {
        if (!logs) {
          this.isFetchingPreviousLogs$.next(false);
          return;
        }
        const formattedData = this.formatLogs(logs.events);
        if (this.currentBackwardToken !== logs.nextToken) {
          // update backward token for next call
          this.currentBackwardToken = logs.nextToken;
        } else {
          // hide load more button if tokens are already equal
          this.hasPreviousLogsToLoad$.next(false);
          this.previousLogsSubs.unsubscribe();
        }
        this.data$.next(this.data$.getValue().concat(formattedData));
        this.isFetchingPreviousLogs$.next(false);
      });
  }

  /**
   * Formats raw event logs retrieved from cloudwatch to readable logs
   * and inverts log entries to make the latest logs at the top
   *
   * @param rawEventLogs unformatted event logs retrieved from cloudwatch
   */
  formatLogs(rawEventLogs: any[]) {
    return rawEventLogs
      .map(({ timestamp, message }) => ({
        timestamp: this.formatTimestamp(timestamp),
        message: this.formatMessage(message),
      }))
      .reverse();
  }

  formatTimestamp(timeStamp) {
    if (timeStamp) {
      return moment(timeStamp).format('llll');
    }
  }

  formatMessage(message: string) {
    return message
      .replace(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3,} -/, '')
      .trim();
  }

  download() {
    const downloadFormatData = this.data$
      .getValue()
      .map((d) => `${d.timestamp} ${d.message} \n`);
    const blob = new Blob(downloadFormatData.reverse(), {
      type: 'text/plain',
      endings: 'native',
    });
    const resUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = 'log.txt';
    anchor.href = resUrl;
    anchor.click();
  }

  ngOnInit(): void {
    this.initFetchCredentials();
  }

  completeLogger() {
    this.isCompleteSimulation$.next(true);
  }

  onClose() {
    if (this.fetchCredentialsSubscription) {
      this.fetchCredentialsSubscription.unsubscribe();
    }
    if (this.logsSubcription) {
      this.logsSubcription.unsubscribe();
    }

    if (this.previousLogsSubs) {
      this.previousLogsSubs.unsubscribe();
    }

    this.matDialogRef.close();
  }
}
