import moment from 'moment';
import { Coerce } from 'prosumer-app/core/utils';

export const REFERENCE_DATE_FORMAT = 'DD-MMM-YYYY';

export const getReferenceStr = (
  record: Record<string, string | number> | undefined,
): string => {
  record = Coerce.toObject(record);
  if (!Object.values(record).length) return '';
  const { updatedAt, filterDate, ...filters } = record;
  const dateStr = moment(filterDate || updatedAt).format(REFERENCE_DATE_FORMAT);
  return Object.values(filters).join(' | ').concat(' at ').concat(dateStr);
};

export const hasUpdatedTDBFilters = (
  tdbFilters: Record<string, string | number>,
  hasProfilesForSaving: boolean,
): boolean =>
  [hasProfilesForSaving, Object.values(tdbFilters).length > 0].every(Boolean);
