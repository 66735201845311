import { Action } from '@ngrx/store';
import { type } from 'prosumer-app/libs/eyes-shared';
import {
  Notification,
  mapNotificationToBackend,
  mapNotificationToFrontend,
} from './notification-store.model';

export const NOTIFICATION_STORE_FEATURE = 'notification';

export const NotificationStoreActionTypes = {
  ADD_NOTIFICATION: type(
    `[${NOTIFICATION_STORE_FEATURE}] Add Notification (Local)`,
  ),
  CLEAR: type(
    `[${NOTIFICATION_STORE_FEATURE}] Clear Notification Store (Local)`,
  ),
  CLEAR_NOTIFICATIONS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Clear Notifications (Local)`,
  ),
  CLEAR_NOTIFICATIONS_SUCCESS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Clear Notification Success`,
  ),
  CLEAR_NOTIFICATIONS_FAILURE: type(
    `[${NOTIFICATION_STORE_FEATURE}] Clear Notification Failure`,
  ),
  FETCH_NOTIFICATIONS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Fetch Notifications`,
  ),
  FETCH_NOTIFICATIONS_SUCCESS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Fetch Notifications Success`,
  ),
  FETCH_NOTIFICATIONS_FAILURE: type(
    `[${NOTIFICATION_STORE_FEATURE}] Fetch Notifications Failure`,
  ),
  REMOVE_NOTIFICATION: type(
    `[${NOTIFICATION_STORE_FEATURE}] Remove Notification (Local)`,
  ),
  REMOVE_NOTIFICATION_SUCCESS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Remove Notification Success`,
  ),
  REMOVE_NOTIFICATION_FAILURE: type(
    `[${NOTIFICATION_STORE_FEATURE}] Remove Notification Failure`,
  ),
  SET_NOTIFICATIONS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Set Notifications (Local)`,
  ),
  TAG_NOTIFICATION: type(`[${NOTIFICATION_STORE_FEATURE}] Tag Notification`),
  TAG_NOTIFICATION_SUCCESS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Tag Notification Success`,
  ),
  TAG_NOTIFICATION_FAILURE: type(
    `[${NOTIFICATION_STORE_FEATURE}] Tag Notification Failure`,
  ),
  READ_NOTIFICATION: type(`[${NOTIFICATION_STORE_FEATURE}] Read Notification`),
  READ_NOTIFICATION_SUCCESS: type(
    `[${NOTIFICATION_STORE_FEATURE}] Read Notification Success`,
  ),
  READ_NOTIFICATION_FAILURE: type(
    `[${NOTIFICATION_STORE_FEATURE}] Read Notification Failure`,
  ),
};

export class NotificationStoreAddAction implements Action {
  readonly type = NotificationStoreActionTypes.ADD_NOTIFICATION;
  readonly payload: { notification: any };
  constructor(notification: any) {
    this.payload = { notification: mapNotificationToFrontend(notification) };
  }
}

export class NotificationStoreClearAction implements Action {
  readonly type = NotificationStoreActionTypes.CLEAR;
  readonly payload: Record<string, unknown>;
  constructor() {}
}

export class NotificationStoreClearListAction implements Action {
  readonly type = NotificationStoreActionTypes.CLEAR_NOTIFICATIONS;
  readonly payload: Record<string, unknown>;
  constructor() {}
}

export class NotificationStoreClearListSuccessAction implements Action {
  readonly type = NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_SUCCESS;
  readonly payload: { message: string; notify: boolean };
  constructor(message: string, notify: boolean) {
    this.payload = { message, notify };
  }
}

export class NotificationStoreClearListFailureAction implements Action {
  readonly type = NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class NotificationStoreFetchListAction implements Action {
  readonly type = NotificationStoreActionTypes.FETCH_NOTIFICATIONS;
  constructor() {}
}

export class NotificationStoreFetchListSuccessAction implements Action {
  readonly type = NotificationStoreActionTypes.FETCH_NOTIFICATIONS_SUCCESS;
  readonly payload: {
    notificationsList: Array<Notification>;
    message: string;
    notify: boolean;
  };
  constructor(notifications: Array<any>, message: string, notify: boolean) {
    this.payload = {
      notificationsList: (notifications || []).map((notification) =>
        mapNotificationToFrontend(notification),
      ),
      message,
      notify,
    };
  }
}

export class NotificationStoreFetchListFailureAction implements Action {
  readonly type = NotificationStoreActionTypes.FETCH_NOTIFICATIONS_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class NotificationStoreRemoveAction implements Action {
  readonly type = NotificationStoreActionTypes.REMOVE_NOTIFICATION;
  readonly payload: { notification: Notification };
  constructor(notification: Notification) {
    this.payload = { notification };
  }
}

export class NotificationStoreRemoveSuccessAction implements Action {
  readonly type = NotificationStoreActionTypes.REMOVE_NOTIFICATION_SUCCESS;
  readonly payload: {
    notification: Notification;
    message: string;
    notify: boolean;
  };
  constructor(notification: Notification, message: string, notify: boolean) {
    this.payload = { notification, message, notify };
  }
}

export class NotificationStoreRemoveFailureAction implements Action {
  readonly type = NotificationStoreActionTypes.REMOVE_NOTIFICATION_FAILURE;
  readonly payload: {
    notification: Notification;
    error: string;
  };
  constructor(notification: Notification, error: string) {
    this.payload = { notification, error };
  }
}

export class NotificationStoreSetListAction implements Action {
  readonly type = NotificationStoreActionTypes.SET_NOTIFICATIONS;
  readonly payload: { notifications: Array<Notification> };
  constructor(notifications: Array<Notification>) {
    this.payload = { notifications };
  }
}

export class NotificationStoreTagAction implements Action {
  readonly type = NotificationStoreActionTypes.TAG_NOTIFICATION;
  readonly payload: { id: string; notification: any };
  constructor(id: string, notification: Notification) {
    this.payload = { id, notification: mapNotificationToBackend(notification) };
  }
}

export class NotificationStoreTagSuccessAction implements Action {
  readonly type = NotificationStoreActionTypes.TAG_NOTIFICATION_SUCCESS;
  readonly payload: {
    notification: Notification;
    message: string;
    notify: boolean;
  };
  constructor(notification: any, message: string, notify: boolean) {
    this.payload = {
      notification: mapNotificationToFrontend(notification),
      message,
      notify,
    };
  }
}

export class NotificationStoreTagFailureAction implements Action {
  readonly type = NotificationStoreActionTypes.TAG_NOTIFICATION_FAILURE;
  readonly payload: { id: string; error: string };
  constructor(id: string, error: string) {
    this.payload = { id, error };
  }
}

export class NotificationStoreReadAction implements Action {
  readonly type = NotificationStoreActionTypes.READ_NOTIFICATION;
  readonly payload: { notification_uuids: Array<string> };
  constructor(notification_uuids: Array<string>) {
    this.payload = { notification_uuids };
  }
}

export class NotificationStoreReadSuccessAction implements Action {
  readonly type = NotificationStoreActionTypes.READ_NOTIFICATION_SUCCESS;
  readonly payload: { notification_uuids: Array<string>; message: string };
  constructor(notification_uuids: Array<string>, message: any) {
    this.payload = { notification_uuids, message };
  }
}

export class NotificationStoreReadFailureAction implements Action {
  readonly type = NotificationStoreActionTypes.READ_NOTIFICATION_FAILURE;
  readonly payload: { notification_uuids: Array<string>; error: any };
  constructor(notification_uuids: Array<string>, error: any) {
    this.payload = { notification_uuids, error };
  }
}

export type NotificationStoreActions =
  | any
  | NotificationStoreClearAction
  | NotificationStoreClearListAction
  | NotificationStoreClearListSuccessAction
  | NotificationStoreClearListFailureAction
  | NotificationStoreAddAction
  | NotificationStoreRemoveAction
  | NotificationStoreRemoveSuccessAction
  | NotificationStoreRemoveFailureAction
  | NotificationStoreSetListAction
  | NotificationStoreFetchListAction
  | NotificationStoreFetchListSuccessAction
  | NotificationStoreFetchListFailureAction
  | NotificationStoreTagAction
  | NotificationStoreTagSuccessAction
  | NotificationStoreTagFailureAction
  | NotificationStoreReadAction
  | NotificationStoreReadSuccessAction
  | NotificationStoreReadFailureAction;
