import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';

export class DetailsOverlayRef {
  private _beforeClose = new Subject<void>();
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this._beforeClose.next();
    this._beforeClose.complete();
    this.overlayRef.dispose();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
}
