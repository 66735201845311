import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from 'prosumer-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ResultsTabulatorData } from './results-tabulator.model';

type TableDataSource = MatTableDataSource<Partial<ResultsTabulatorData>>;
type TotalValues = { [year: number]: number };
@Component({
  selector: 'prosumer-results-tabulator',
  templateUrl: './results-tabulator.component.html',
  styleUrls: ['./results-tabulator.component.scss'],
})
export class ResultsTabulatorComponent implements OnInit {
  private dataSubject = new BehaviorSubject<ResultsTabulatorData[]>([]);
  private yearsSubject = new BehaviorSubject<number[]>([]);

  @ViewChild(MatSort) sort: MatSort;

  @Input() set data(data: ResultsTabulatorData[]) {
    this.dataSubject.next(data || []);
  }
  @Input() set years(years: number[]) {
    this.yearsSubject.next(years || []);
  }
  @Input() exportName = 'export';

  dataSource$: Observable<TableDataSource>;
  totalValues$: Observable<TotalValues>;
  yearColumns$: Observable<string[]>;
  columnDef$: Observable<string[]>;

  constructor() {}

  ngOnInit(): void {
    this.dataSource$ = this.getTableDataSourceStream();
    this.totalValues$ = this.getTotalValuesStream();
    this.yearColumns$ = this.getYearColumnsStream();
    this.columnDef$ = this.getColumnDefStream();
  }

  private getColumnDefStream(): Observable<string[]> {
    return this.getYearColumnsStream().pipe(map((years) => ['name', ...years]));
  }

  private getYearColumnsStream(): Observable<string[]> {
    return this.yearsSubject.pipe(
      map((years) => years.map((year) => year.toString())),
    );
  }

  private getTotalValuesStream(): Observable<TotalValues> {
    return combineLatest([this.dataSubject, this.yearsSubject]).pipe(
      map(([data, years]) => this.computeTotalValues(data, years)),
    );
  }

  private getTableDataSourceStream(): Observable<TableDataSource> {
    return this.dataSubject.pipe(
      debounceTime(100),
      map((data) => this.flattenDataValues(data)),
      map((data) => new MatTableDataSource(data)),
      tap((source) => (source.sort = this.sort)),
    );
  }

  private flattenDataValues(
    data: ResultsTabulatorData[],
  ): Partial<ResultsTabulatorData>[] {
    return data.map((datum) => ({ name: datum.name, ...datum.values }));
  }

  private computeTotalValues(
    data: ResultsTabulatorData[],
    years: number[],
  ): TotalValues {
    return years.reduce((acc, year) => {
      acc[year] = data.reduce(
        (sum, curr) => sum + Utils.resolveToZero(curr.values[year]),
        0,
      );
      return acc;
    }, {});
  }
}
