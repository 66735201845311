import {
  Converter,
  EnergyVector,
  EnergyVectorTemporaryModel,
  EVPartialTDBTechnologyDataModel,
  Library,
} from 'prosumer-app/+scenario/models';
import {
  EnergyVectorCascaderService,
  NodeCascaderService,
} from 'prosumer-app/+scenario/services';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { DER_TYPES } from 'prosumer-app/app.references';
import { Utils } from 'prosumer-app/core/utils';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  BaseDialogComponent,
  ColumnDefinition,
  contains,
  CustomValidators,
  DialogData,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  toList,
} from 'prosumer-app/libs/eyes-shared';
import { getTDBTechFilterFromData } from 'prosumer-app/shared/modules/tdb/mappers';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { LibraryService } from 'prosumer-app/stores';
import {
  EnergyVectorQuery,
  EnergyVectorStore,
} from 'prosumer-app/stores/energy-vector';
import {
  convertToYearlyValues,
  EfficienciesEVTypeMatcher,
  EfficiencyEfficiencyMatrixModel,
  EfficiencyMatrixControlModel,
  EfficiencyMatrixEnergyVectorMetadata,
  MainEVTypeMatcher,
  mapCustomEvIdToPredifinedId,
  mapEVNameToTemporaryEV,
  mapEVsToOnlyIds,
  mapTempToEnergyVectorReq,
  NameValidator,
  ReplacementEVEventModel,
} from 'prosumer-shared';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  mergeMap,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { EquipmentDialogComponent } from '../equipment-dialog.component';
import { EquipmentFormDialogData } from '../equipment-dialog.model';

const NON_LIBRARY_SOURCETYPES = ['custom', 'tdb'];
const FALSY_VALUES = [undefined, null, 0, '0', ''];

@UntilDestroy()
@Component({
  selector: 'prosumer-converter',
  templateUrl: './converter.component.html',
  styleUrls: ['./converter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    EnergyVectorCascaderService,
    NodeCascaderService,
    TitleCasePipe,
    ManagedDataService,
  ],
})
export class ConverterComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;

  submitted$ = new BehaviorSubject<boolean>(false);
  nodeWarningShown$ = new BehaviorSubject<boolean>(false);
  evWarningShown$ = new BehaviorSubject<boolean>(false);

  @Input() nodeOptions: Array<FormFieldOption<string>> = [];

  scenarioId: string;
  caseId: string;
  projectId: string;

  defaultValues = {
    existingAsset: false,
    capacityExpansion: false,
    efficiency: '1.0',
    yearlyCapacityLoss: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMinPower: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMaxPower: !!!this.data
      ? {}
      : convertToYearlyValues(
          '100000.0',
          this.data.startYear,
          this.data.endYear,
        ),
    yearlyBuildCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyIndivisibleCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMCharge: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMInstall: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyTechnicalLife: !!!this.data
      ? {}
      : convertToYearlyValues('20', this.data.startYear, this.data.endYear),
    yearlyDepreciationTime: !!!this.data
      ? {}
      : convertToYearlyValues('0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsKw: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsIndivisible: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFootprint: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfFinancing: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfDecommissioning: null,
  };

  converterForm: UntypedFormGroup = this.formBuilder.group({
    type: 'converter',
    /* General Parameters */
    name: '',
    nodes: [],
    scenarioVariation: 'basecase',
    inputEnergyVector: '',
    outputEnergyVector: '',
    energyVector: '',
    secondOutputEnergyVector: undefined,
    secondary: undefined,
    efficiency: this.defaultValues.efficiency,
    secondEfficiency: '',
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    yearlyCapacityLoss: [
      this.defaultValues.yearlyCapacityLoss,
      Validators.required,
    ],
    yearlyMinPower: [this.defaultValues.yearlyMinPower, Validators.required],
    yearlyMaxPower: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyBuildCost: [this.defaultValues.yearlyBuildCost, Validators.required],
    yearlyIndivisibleCost: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      this.defaultValues.yearlyFOAndMCharge,
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      this.defaultValues.yearlyFOAndMInstall,
      Validators.required,
    ],
    yearlyTechnicalLife: [
      this.defaultValues.yearlyTechnicalLife,
      Validators.required,
    ],
    yearlyDepreciationTime: [
      this.defaultValues.yearlyDepreciationTime,
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      this.defaultValues.yearlyBuildEmissionsKw,
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      this.defaultValues.yearlyBuildEmissionsIndivisible,
      Validators.required,
    ],
    yearlyFootprint: [this.defaultValues.yearlyFootprint, Validators.required],
    yearlyCostOfFinancing: [
      this.defaultValues.yearlyCostOfFinancing,
      Validators.required,
    ],
    yearlyCostOfDecommissioning: [
      this.defaultValues.yearlyCostOfDecommissioning,
    ],
    id: '',
    /* Library */
    sourceType: 'library',
    library: [{ value: '' }, Validators.required],
    /* EfficiencyMatrix */
    efficiencyMatrix: [],
    startYear: !!!this.data ? 0 : this.data.startYear,
    endYear: !!!this.data ? 0 : this.data.endYear,
    operatingCostProfiles: [null],
    tdbTechnologyFilter: [null],
  });
  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  inputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  outputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  scenarioEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  eVOptionsWithTemp$ = new BehaviorSubject<EnergyVectorTemporaryModel[]>([]);
  energyVectorsTemp$: Observable<EnergyVectorTemporaryModel[]>;

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    id: {
      name: 'ID',
      flex: '20',
      sortable: true,
    },
    description: {
      name: 'Description',
      flex: 'calc(30% - 50px)',
      sortable: true,
    },
    energyVectorNamesIn: {
      name: 'Input Vectors',
      flex: '12.5',
      sortable: true,
    },
    energyVectorNamesOut: {
      name: 'Output Vectors',
      flex: '12.5',
      sortable: true,
    },
    efficiency: {
      name: 'Main Efficiency [-]',
      flex: '12.5',
      sortable: true,
      alignment: 'flex-end',
    },
    buildCost: {
      name: 'Build Cost [€/kW]',
      flex: '12.5',
      sortable: true,
      alignment: 'flex-end',
    },
  };

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.converterForm?.controls?.inputEnergyVector?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  formType: string = this.converterForm.get('type').value;

  nameCtrl: UntypedFormControl = this.converterForm.get(
    'name',
  ) as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.converterForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');

  // nodeOptions: Array<FormFieldOption<string>> = [];
  sourceOptions: Array<FormFieldOption<ProfileType>> = [];

  library$: Observable<Array<Library>>;
  library: Array<Library> = [];
  viewInitialized: boolean; // Used for change issues in energy vector
  isMultiNode: boolean;
  filterForm = this.formBuilder.group({
    id: '',
    inputEnergyVector: '',
    outputEnergyVector: '',
  });

  /**
   * The selected library id used for confirmation purposes.
   */
  selectedLibraryId$ = new BehaviorSubject<string>(null);
  get selectedLibraryId() {
    return this.selectedLibraryId$.value;
  }
  set selectedLibraryId(value: string) {
    this.selectedLibraryId$.next(value);
  }

  /**
   * The subject that emits when delete energy vector is confirmed.
   */
  confirmDeleteEnergyVector$ =
    new Subject<EfficiencyMatrixEnergyVectorMetadata>();
  isFormLoading$ = new BehaviorSubject<boolean>(false);

  get isViewOnly() {
    return this.data.isViewOnly;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentFormDialogData<Converter>,
    public libraryService: LibraryService,
    public dialogRef: MatDialogRef<EquipmentDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _logger: LoggerService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private _evCascaderService: EnergyVectorCascaderService,
    private _nodeCascaderService: NodeCascaderService,
    private managedDataEV: ManagedDataService<EnergyVectorTemporaryModel>,
    private evQuery: EnergyVectorQuery,
    private evStore: EnergyVectorStore,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.initScenarioEntity();
    this.initValidators();
    this.initValueChanges();
    this.initInputOutputValueChanges();
    this.initNodeValueChange();
    this.initValues();
    this.initObservables();
  }

  private initObservables() {
    this.energyVectorsTemp$ = this.managedDataEV.listData$;
    combineLatest([this.evQuery.selectActiveVectors(), this.energyVectorsTemp$])
      .pipe(
        untilDestroyed(this),
        tap(([activeEVs, tempEvs]) =>
          this.scenarioEnergyVectorOptions$.next(activeEVs),
        ),
        map(([activeEVs, tempEvs]) => activeEVs.concat(tempEvs)),
      )
      .subscribe((energyVectors) => {
        this.eVOptionsWithTemp$.next(energyVectors);
      });
  }

  initScenarioEntity() {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  initValueChanges() {
    // Whenever the source type changes, reset the value of library field
    this.converterForm.controls.sourceType.valueChanges
      .pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.sourceType
            : 'library',
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((sourceType) => {
        this.patchSourceTypeToForm(sourceType);
      });

    this.converterForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.converterForm.controls.existingAsset.updateValueAndValidity(),
      );

    this.library$ = this.evQuery.selectActiveVectors().pipe(
      map((vectors) => vectors.map((ev) => mapCustomEvIdToPredifinedId(ev))),
      map((vectors) => mapEVsToOnlyIds(vectors)),
      mergeMap((ids) =>
        this.libraryService.getLibraryListForConverters$('converter', ids),
      ),
      this.takeUntilShare(),
    );

    this.library$.subscribe((data) => (this.library = data));

    this.converterForm.controls.library.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((library: Library) => {
        this.selectedLibraryId = library?.id;
        this.patchLibraryValuesToForm(library);
      });

    this.filterForm.valueChanges
      .pipe(startWith(this.filterForm.value), this.takeUntilShare())
      .subscribe((value) => this.filterChange(value));
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
  }

  initValues() {
    this.isMultiNode = this.data.isMultiNode;

    if (this.data.profileOptions) {
      this.sourceOptions.push(...this.data.profileOptions);
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }

    this.converterForm.patchValue({
      ...this.data.equipment,
      type: 'converter',
    });
  }

  initValidators(): void {
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());
    this.converterForm.controls.existingAsset.setValidators([
      Validators.required,
      CustomValidators.sameValue(this.converterForm.controls.forcedInvestment),
    ]);
    this.converterForm.controls.name.setValidators([
      NameValidator.validWithCore(),
    ]);
    this.converterForm.addValidators(this.unsavedEVsValidator());
  }

  filterChange(value: any) {
    this.library$.subscribe((data) => (this.library = data));
    const listData = toList(this.library);
    const input = this.generateArrays(value.inputEnergyVector);
    const output = this.generateArrays(value.outputEnergyVector);
    const filteredData = !listData
      ? []
      : listData.filter(
          (data) =>
            (value.id && value.id.length > 0
              ? contains(value.id, data.id)
              : true) &&
            (value.inputEnergyVector && value.inputEnergyVector.length === 1
              ? contains(
                  data.energyVectorsIn,
                  value.inputEnergyVector[0]['typeId'],
                )
              : true) &&
            (value.outputEnergyVector && value.outputEnergyVector.length === 1
              ? contains(
                  data.energyVectorsOut,
                  value.outputEnergyVector[0]['typeId'],
                )
              : true) &&
            (input && input.length > 1
              ? input.every((ev) => data.energyVectorsIn.includes(ev))
              : true) &&
            (output && output.length > 1
              ? output.every((ev) => data.energyVectorsOut.includes(ev))
              : true),
        );
    if (filteredData) {
      this.library = filteredData;
    }
  }

  generateArrays(dataSource: Array<any>): Array<string> {
    const generatedArray = [];
    if (dataSource) {
      dataSource.forEach((data) => generatedArray.push(data['typeId']));
      return generatedArray.filter(
        (item, index) => generatedArray.indexOf(item) === index,
      );
    }
    return generatedArray;
  }

  patchSourceTypeToForm(sourceType: any) {
    if (NON_LIBRARY_SOURCETYPES.includes(sourceType)) {
      this.converterForm.controls.library.clearValidators();
      this.converterForm.controls.library.patchValue(undefined);
    } else {
      this.converterForm.controls.library.setValidators(Validators.required);
      this.converterForm.controls.library.patchValue(
        (this.data.equipment || ({} as any)).library || undefined,
      );
    }
  }

  initInputOutputValueChanges() {
    this.converterForm
      .get('efficiencyMatrix')
      .valueChanges.pipe(
        untilDestroyed(this),
        filterNilValue(),
        debounceTime(300),
        tap((v) => this.removeTempEVsIfUnusedInEfficiencyMatrix(v)),
      )
      .subscribe((values) => {
        const selectedEfficiencyInputEnergyVector =
          this.getUniqueEfficiencyEnergyVectors(
            values?.efficiencies,
            'energyVectorIn',
          );
        const selectedEfficiencyOutputEnergyVector =
          this.getUniqueEfficiencyEnergyVectors(
            values?.efficiencies,
            'energyVectorOut',
          );

        const inputOptions = this.scenarioEnergyVectorOptions$
          .getValue()
          .filter(
            (options) =>
              selectedEfficiencyInputEnergyVector.indexOf(options.value) >= 0,
          );
        const outputOptions = this.scenarioEnergyVectorOptions$
          .getValue()
          .filter(
            (options) =>
              selectedEfficiencyOutputEnergyVector.indexOf(options.value) >= 0,
          );

        this.inputEnergyVectorOptions$.next(inputOptions);
        this.outputEnergyVectorOptions$.next(outputOptions);
      });
  }

  initNodeValueChange() {
    this.converterForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.converterForm.dirty) {
          return;
        }
        const currentNodes = (this.converterForm.value['nodes'] || {}).nodes;
        const isNettingAffected = this._nodeCascaderService.isNettingAffected(
          this.converterForm.value['id'],
          value.nodes,
          this.data.currentNetting,
        );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }
        this._nodeCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            // TODO: implement revert option
          });
      });
  }

  private getUniqueEfficiencyEnergyVectors(arr, key): string[] {
    if (!arr) {
      return [];
    }
    return Array.from(new Set(arr.map((d) => d[key])));
  }

  patchLibraryValuesToForm(library: any) {
    if (!library) {
      return;
    }
    this._logger.debug(library);
    const controls = this.converterForm.controls;
    // advanced-input
    const startYear = !!controls.startYear ? controls.startYear.value : 0;
    const endYear = !!controls.endYear ? controls.endYear.value : 0;
    this.patchControlWithValue(
      this.converterForm.controls.yearlyCapacityLoss,
      library.capacityLoss
        ? convertToYearlyValues(library.capacityLoss, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyCapacityLoss,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyMinPower,
      library.minPower
        ? convertToYearlyValues(library.minPower, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyMinPower,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyMaxPower,
      library.maxPower
        ? convertToYearlyValues(library.maxPower, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyMaxPower,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyBuildCost,
      library.buildCost
        ? convertToYearlyValues(library.buildCost, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyBuildCost,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyIndivisibleCost,
      library.indivisibleCost
        ? convertToYearlyValues(library.indivisibleCost, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyIndivisibleCost,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyFOAndMCharge,
      library.fOAndMCharge
        ? convertToYearlyValues(library.fOAndMCharge, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyFOAndMCharge,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyFOAndMInstall,
      library.fOAndMPerInstall
        ? convertToYearlyValues(library.fOAndMPerInstall, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyFOAndMInstall,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyTechnicalLife,
      library.technicalLife
        ? convertToYearlyValues(library.technicalLife, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyTechnicalLife,
    );
    this.patchControlWithValue(
      this.converterForm.controls.yearlyDepreciationTime,
      library.depreciationTime
        ? convertToYearlyValues(library.depreciationTime, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyDepreciationTime,
    );
    this.patchControlWithValue(
      this.converterForm.controls.efficiencyMatrix,
      library.efficiencyMatrix
        ? library.efficiencyMatrix
        : this.updateEfficiencyMatrix(library, startYear, endYear),
    );
    this.patchControlWithValue(
      this.converterForm.controls.efficiency,
      library.efficiency,
      this.defaultValues.efficiency,
    );
    this.patchControlWithValue(
      this.converterForm.controls.secondEfficiency,
      library.secondEfficiency,
      '',
    );
  }

  updateEfficiencyMatrix = (library, startYear, endYear) => {
    let mainEnergyVectorInput, mainEnergyVectorOutput;
    if (
      !!library &&
      !!library.energyVectorsIn &&
      library.energyVectorsIn.length > 0
    ) {
      mainEnergyVectorInput = library.energyVectorsIn[0];
    }
    if (
      !!library &&
      !!library.energyVectorsOut &&
      library.energyVectorsOut.length > 0
    ) {
      mainEnergyVectorOutput = library.energyVectorsOut[0];
    }
    return {
      mainEnergyVectorInput,
      mainEnergyVectorOutput,
      efficiencies: this.updateEfficiencies(
        library.efficiencies,
        startYear,
        endYear,
      ),
    };
  };

  updateEfficiencies = (efficiencies, startYear, endYear) => {
    const result = [];
    if (!efficiencies) {
      return result;
    }
    Object.keys(efficiencies).forEach((energyIn) => {
      if (efficiencies[energyIn]) {
        Object.keys(efficiencies[energyIn]).forEach((energyOut) => {
          result.push({
            energyVectorIn: energyIn,
            energyVectorOut: energyOut,
            value: convertToYearlyValues(
              efficiencies[energyIn][energyOut] || '0.00',
              startYear,
              endYear,
            ),
          });
        });
      }
    });
    return result;
  };

  updateFilter(data: any, formControl: UntypedFormControl) {
    formControl.patchValue(data);
  }

  onConfirm(): Converter {
    this.submit();
    this.submitted$.next(true);
    if (this.converterForm.valid) {
      this.converterForm.controls.energyVector.patchValue(
        this.converterForm.controls.efficiencyMatrix.value
          .mainEnergyVectorInput,
      );
      this.converterForm.controls.outputEnergyVector.patchValue(
        this.converterForm.controls.efficiencyMatrix.value
          .mainEnergyVectorOutput,
      );
      const convFormValue = this.converterForm.getRawValue();
      return {
        ...convFormValue,
        nodes: convFormValue['nodes']
          ? convFormValue['nodes']['nodes'] || convFormValue['nodes'] || []
          : convFormValue['nodes'],
        operatingCostProfiles: convFormValue?.operatingCostProfiles
          ? convFormValue.operatingCostProfiles['operatingCostProfiles']
          : null,
      };
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  /**
   * Updates the form's library when selecting a library from the table. Shows a confirmation dialog to warn about
   * operation cost cascading effect.
   *
   * @param selectedLibraryId - the library id selected
   */
  onSelect(selectedLibraryId: string): void {
    const previousLibraryId = this.selectedLibraryId;
    if (previousLibraryId === selectedLibraryId) {
      return;
    }

    // gets the source library list
    this.library$.pipe(take(1)).subscribe((libraries) => {
      const currentEnergyVectors = this.mapEnergyVectorFromEfficiency();
      if (
        !previousLibraryId ||
        (this.isOperatingCostProfilesEmpty() &&
          this.isNettingNotAffected(currentEnergyVectors))
      ) {
        this.updateFormLibrary(libraries, selectedLibraryId);
        return;
      }

      // the callback function to call when confirmation dialog is opened
      const callbackFn = (data: DialogData) => {
        // resets the selected library id to the previous value
        if (!data) {
          // need to clear the value then set a timeout before updating the value as a workaround for visual issue
          this.selectedLibraryId = null;
          setTimeout(() => (this.selectedLibraryId = previousLibraryId));
          return;
        }

        this.updateFormLibrary(libraries, selectedLibraryId);
      };

      // opens dialog confirmation if the library previously has value or operation cost profiles are not empty
      this.openConfirmationDialog(callbackFn);
    });
  }

  /**
   * Opens the confirmation dialog for operating cost warning.
   *
   * @param callbackFn - the callback function to execute after closing the dialog via confirm or cancel
   * @param type - the type of data for message purposes (default: 'library')
   * @param width - the width of the dialog (default: 400)
   * @param data - the data used to customize the dialog (default: `see data parameter`)
   */
  openConfirmationDialog(
    callbackFn: (data?: DialogData) => void,
    type = 'library',
    width = 400,
    data: DialogData = {
      title: this.translate.instant(
        `Scenario.dialog.${type}.operatingCostWarningTitle`,
      ),
      message: this.translate.instant(
        `Scenario.dialog.${type}.operatingCostWarningMessage`,
      ),
    },
  ): void {
    this.matDialog
      .open(BaseDialogComponent, {
        data: {
          ...data,
          confirm: this.translate.instant('Generic.labels.yes'),
          close: this.translate.instant('Generic.labels.no'),
          disableClose: true,
        },
        width: `${width}px`,
      })
      .afterClosed()
      .subscribe(callbackFn);
  }

  /**
   * Checks if operating cost profiles form control is empty.
   *
   * @returns true if operating cost profiles is falsy or its length is zero (0)
   */
  isOperatingCostProfilesEmpty(): boolean {
    const { operatingCostProfiles } = Utils.resolveToEmptyObject(
      this.converterForm.get('operatingCostProfiles').value,
    );
    return !operatingCostProfiles || (operatingCostProfiles as []).length === 0;
  }

  /**
   * Updates the converter form's library value based on the parameters. Marks the form dirty.
   *
   * @param libraries - the source library list
   * @param libraryId - the selected library id
   */
  updateFormLibrary(libraries: Library[], libraryId: string): void {
    const library = this.findLibrary(libraries, libraryId);
    this.updateSelectedLibrary(library);
    this.converterForm.markAsDirty();
  }

  /**
   * Finds the library with the id parameter from the libraries parameter
   *
   * @param libraries - the source library list to find the library id from
   * @param libraryId - the library id to find
   * @returns the library found; undefined if parameters are falsy or nothing is found
   */
  findLibrary(libraries: Library[], libraryId: string): Library {
    return libraries?.find(({ id }) => id === libraryId);
  }

  /**
   * Updates the converter form's library.
   *
   * @param library - the library to set
   */
  updateSelectedLibrary(library: Library): void {
    this.converterForm.get('library')?.patchValue(library);
  }

  submit(): void {
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }

  /**
   * Confirms the deletion of input energy vector.
   *
   * @param energyVectorId - the input energy vector id to delete
   */
  confirmInputEnergyVectorDelete(energyVectorId: string): void {
    this.confirmDeleteEnergyVector$.next({ energyVectorId, type: 'input' });
  }

  /**
   * Confirms the deletion of output energy vector.
   *
   * @param energyVectorId - the output energy vector id to delete
   */
  confirmOutputEnergyVectorDelete(energyVectorId: string): void {
    this.confirmDeleteEnergyVector$.next({ energyVectorId, type: 'output' });
  }

  onAssociateEVButtonClick(unsavedEV: EnergyVectorTemporaryModel): void {
    const evForSaving = { ...mapTempToEnergyVectorReq(unsavedEV) };
    this.evStore.createEnergyVector(evForSaving).subscribe({
      next: (res: any) => {
        this.managedDataEV.deleteByKey(res.energyVectorId, 'value');
      },
      error: (error: HttpErrorResponse) => this.managedDataEV.triggerListData(),
    });
  }

  /**
   * Event to bind when an input energy vector deletion is desired.
   *
   * @param energyVectorId - the input energy vector id to delete
   */
  onInputEnergyVectorDelete(energyVectorId: string): void {
    if (
      this.isOperatingCostProfilesEmpty() &&
      this.isNettingNotAffected([energyVectorId])
    ) {
      this.confirmInputEnergyVectorDelete(energyVectorId);
      return;
    }

    // the callback function to call when confirmation dialog is opened
    const callbackFn = (data: DialogData) => {
      if (!!data) {
        this.confirmInputEnergyVectorDelete(energyVectorId);
      }
    };

    this.openConfirmationDialog(callbackFn, 'efficiencyMatrix', 500);
  }

  /**
   * Event to bind when an output energy vector deletion is desired.
   *
   * @param energyVectorId - the ouput energy vector id to delete
   */
  onOutputEnergyVectorDelete(energyVectorId: string): void {
    if (
      this.isOperatingCostProfilesEmpty() &&
      this.isNettingNotAffected([energyVectorId])
    ) {
      this.confirmOutputEnergyVectorDelete(energyVectorId);
      return;
    }

    // the callback function to call when confirmation dialog is opened
    const callbackFn = (data: DialogData) => {
      if (!!data) {
        this.confirmOutputEnergyVectorDelete(energyVectorId);
      }
    };

    this.openConfirmationDialog(callbackFn, 'efficiencyMatrix', 500);
  }

  isNettingNotAffected(energyVectorIds: string[]) {
    // show cascading warning dialog once
    if (this.evWarningShown$.value) {
      return true;
    }
    return !this._evCascaderService.isNettingAffected(
      this.converterForm.value['id'],
      energyVectorIds,
      this.data.currentNetting,
    );
  }

  mapEnergyVectorFromEfficiency() {
    const evIn = this.getUniqueEfficiencyEnergyVectors(
      (this.converterForm.value['efficiencyMatrix'] || {}).efficiencies || [],
      'energyVectorIn',
    );
    const evOut = this.getUniqueEfficiencyEnergyVectors(
      (this.converterForm.value['efficiencyMatrix'] || {}).efficiencies || [],
      'energyVectorOut',
    );
    return Array.from(new Set([...evIn, ...evOut]));
  }

  get derTypes() {
    return DER_TYPES;
  }

  handleTechnologyFiltersLoading(isLoading: boolean) {
    this.isFormLoading$.next(isLoading);
  }

  get extraQueryParamsTDB(): Record<string, string> {
    return {
      startYear: String(this.data.startYear),
      endYear: String(this.data.endYear),
    };
  }

  handleTdbTechnologyData(data: Record<string, unknown>): void {
    // these inner methods to be moved to converter-helper.service - NEW file
    this.injectTDBEVsToEVOptions(data);
    this.injectEfficiencyMatrixToData(data);
    this.patchLibraryValuesToForm(data);
    this.patchTDBTechFilters(data);
  }

  private injectTDBEVsToEVOptions(
    data: EVPartialTDBTechnologyDataModel & { [key: string]: any },
  ) {
    const evNameList = this.extractEVsFromTDBData(data);
    const energyVectors = [...new Set(evNameList)]
      .map((evName) => {
        if (!this.findExistingEVOption(evName, true))
          return mapEVNameToTemporaryEV(evName);
      })
      .filter((val) => !!val);
    this.managedDataEV.setListData(energyVectors);
  }

  private extractEVsFromTDBData(
    data: EVPartialTDBTechnologyDataModel & { [key: string]: any },
  ): string[] {
    return [
      data.inputEnergyVector1,
      data.inputEnergyVector2,
      data.outputEnergyVector1,
      data.outputEnergyVector2,
    ].filter((value) => !FALSY_VALUES.includes(value));
  }

  private findExistingEVOption(
    evName: string,
    ignoreUnsaved = false,
  ): EnergyVectorTemporaryModel | undefined {
    return this.eVOptionsWithTemp$
      .getValue()
      .find(
        (ev) =>
          ev.name.toLowerCase() === evName.toLowerCase() &&
          !(ev.isUnsaved && ignoreUnsaved),
      );
  }

  private patchTDBTechFilters(data: Record<string, unknown>): void {
    this.patchControlWithValue(
      this.converterForm.controls.tdbTechnologyFilter,
      getTDBTechFilterFromData(data),
    );
  }

  private injectEfficiencyMatrixToData(
    data: EVPartialTDBTechnologyDataModel & { [key: string]: any },
  ): void {
    data['efficiencyMatrix'] = this.generateEfficiencyMatrixFromTDB(data);
  }

  private generateEfficiencyMatrixFromTDB(
    data: EVPartialTDBTechnologyDataModel & { [key: string]: any },
  ): EfficiencyMatrixControlModel {
    return {
      mainEnergyVectorInput: this.resolveTdbEvIdFromEvOptions(
        data.inputEnergyVector1 || data.inputEnergyVector2,
      ),
      mainEnergyVectorOutput: this.resolveTdbEvIdFromEvOptions(
        data.outputEnergyVector1 || data.outputEnergyVector2,
      ),
      efficiencies: this.generateEfficienciesFromTDBData(data),
    };
  }

  private resolveTdbEvIdFromEvOptions(
    evName: string | undefined,
  ): string | undefined {
    if (!evName) return undefined;
    return this.findExistingEVOption(evName).value;
  }

  private generateEfficienciesFromTDBData(
    data: EVPartialTDBTechnologyDataModel & { [key: string]: any },
  ): EfficiencyEfficiencyMatrixModel[] {
    const efficiencies = [];
    if (
      !FALSY_VALUES.includes(data.inputEnergyVector1) &&
      !FALSY_VALUES.includes(data.outputEnergyVector1)
    ) {
      efficiencies.push(
        this.generateEfficiencyItem(
          data.inputEnergyVector1,
          data.outputEnergyVector1,
          data.efficiency1,
        ),
      );
    }
    if (
      !FALSY_VALUES.includes(data.inputEnergyVector2) &&
      !FALSY_VALUES.includes(data.outputEnergyVector2)
    ) {
      efficiencies.push(
        this.generateEfficiencyItem(
          data.inputEnergyVector2,
          data.outputEnergyVector2,
          data.efficiency2,
        ),
      );
    }
    return efficiencies;
  }

  private generateEfficiencyItem(evNameIn, evNameOut, value) {
    return {
      energyVectorIn: this.resolveTdbEvIdFromEvOptions(evNameIn),
      energyVectorOut: this.resolveTdbEvIdFromEvOptions(evNameOut),
      value: value,
    };
  }

  private unsavedEVsValidator() {
    return () => {
      const isInvalid = this.findUnsavedEV();
      if (isInvalid) {
        return { unsavedEVError: true };
      } else {
        return null;
      }
    };
  }

  private findUnsavedEV() {
    return !!this.eVOptionsWithTemp$.getValue().find((ev) => ev.isUnsaved);
  }

  onReplaceEvSelection(data: ReplacementEVEventModel) {
    const currentCtrlValue: EfficiencyMatrixControlModel =
      this.converterForm.controls.efficiencyMatrix.value;
    this.replaceEfficiencyMatrixMainEvSelection(currentCtrlValue, data);
    this.replaceEfficiencyMatrixEfficiencyEvSelection(currentCtrlValue, data);
    this.converterForm.controls.efficiencyMatrix.patchValue(currentCtrlValue);
  }

  private replaceEfficiencyMatrixMainEvSelection(
    ctrlValue: EfficiencyMatrixControlModel,
    data: ReplacementEVEventModel,
  ) {
    if (ctrlValue[MainEVTypeMatcher[data.type]] === data.oldId) {
      ctrlValue[MainEVTypeMatcher[data.type]] = data.newId;
    }
  }

  private replaceEfficiencyMatrixEfficiencyEvSelection(
    ctrlValue: EfficiencyMatrixControlModel,
    data: ReplacementEVEventModel,
  ) {
    ctrlValue.efficiencies.map((efficiency) => {
      if (efficiency[EfficienciesEVTypeMatcher[data.type]] === data.oldId) {
        efficiency[EfficienciesEVTypeMatcher[data.type]] = data.newId;
      }
      return efficiency;
    });
  }

  private removeTempEVsIfUnusedInEfficiencyMatrix(
    ctrlValue: EfficiencyMatrixControlModel,
  ) {
    const tempEVs = this.managedDataEV.getListData();
    tempEVs.forEach((ev) => {
      const evId = ev.value;
      const notFound = ctrlValue.efficiencies.every((efficiency) => {
        if (efficiency.energyVectorIn === evId) return false;
        if (efficiency.energyVectorOut === evId) return false;
        return true;
      });
      if (notFound) {
        this.managedDataEV.deleteByKey(evId, 'value');
      }
    });
  }
}
