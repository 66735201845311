<section>
  <div *ngIf="loading$ | async">
    <div>
      <h1>
        {{ 'Generic.messages.shareProject' | translate }}
      </h1>
      <span fxFlex></span>
    </div>
    <div>
      {{ 'Generic.messages.loadingData' | translate }}
    </div>
  </div>
  <div *ngIf="sharedInfo$.getValue()">
    <h1>
      {{ 'Generic.messages.shareProjectSuccess' | translate }}
    </h1>
    <h2>Project: {{ sharedInfo$.getValue().projectName }}</h2>
    <div>{{ 'Generic.messages.shareProjectRedirect' | translate }}</div>
  </div>
  <div *ngIf="errorMsg$.getValue()">
    <div>
      <h1>
        {{ 'Generic.messages.shareProjectFailed' | translate }}
      </h1>
    </div>
    <mat-error>
      {{ errorMsg$.getValue() }}
    </mat-error>
  </div>
  <div class="button-container">
    <button mat-flat-button type="button" color="" (click)="goToDashboard()">
      Back to dashboard
    </button>
    <button
      mat-flat-button
      type="button"
      color="primary"
      (click)="viewProject()"
      [disabled]="!sharedInfo$.getValue()"
    >
      View Project
    </button>
  </div>
</section>
