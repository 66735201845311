import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { CaseModule } from '../+case';
import { ScenarioModule } from '../+scenario';
import { SharedModule } from '../shared';
import { ProjectFormComponent } from './components';
import {
  ProjectCreatePageComponent,
  ProjectDetailsPageComponent,
  ProjectListPageComponent,
} from './containers';
import { ProjectRoutingModule } from './project-routing.module';

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    ProjectRoutingModule,
    SharedModule,
    CaseModule,
    ScenarioModule,
  ],
  declarations: [
    ProjectFormComponent,
    ProjectListPageComponent,
    ProjectCreatePageComponent,
    ProjectDetailsPageComponent,
  ],
  exports: [
    ProjectFormComponent,
    ProjectListPageComponent,
    ProjectCreatePageComponent,
    ProjectDetailsPageComponent,
  ],
})
export class ProjectModule {}
