import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NodesResultEnhanced } from '@prosumer/results/models';

export class NodesFlowResultsEditorValidator {
  static unique(key: string, id: string): ValidatorFn {
    return (control: AbstractControl) => {
      const arrayCtrl = control?.parent?.parent?.getRawValue();
      if (!arrayCtrl) return null;
      return this.isUnique(arrayCtrl, control.value, id, key)
        ? null
        : { duplicate: true };
    };
  }

  private static isUnique(
    existing: any[],
    v: string,
    id: string,
    key: string,
  ): boolean {
    return !existing.find((e) => e.id !== id && e[key] === v);
  }
}
