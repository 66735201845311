import {
  CustomOptions,
  Limits,
  OptimizationCockpitForm,
  Options,
} from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core';

import { CompletionStrategy } from './completion-strategy';

export class OptimizationCockpitCompletion extends CompletionStrategy<OptimizationCockpitForm> {
  isFull(form: OptimizationCockpitForm): boolean {
    return this.getRequired(form).every(Boolean);
  }

  isHalf(form: OptimizationCockpitForm): boolean {
    return this.getRequired(form).some(Boolean);
  }

  private getRequired(form: OptimizationCockpitForm): boolean[] {
    return [
      ...this.getSectionCompletion(form.limits),
      ...this.getSectionCompletion(form.options),
      this.hasItems(form.customOptions),
    ];
  }

  private getSectionCompletion(customizations: Limits | Options): boolean[] {
    return Object.values(customizations).map((item) => item != null);
  }

  private hasItems(list: CustomOptions[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }
}
