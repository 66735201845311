import { RetrievalType } from '../models';
import { UserState } from './user-state.model';
import * as UserActions from './user.actions';
import { userStateFactory } from './user.factory';

export const userReducer = (
  state: UserState = userStateFactory.initialState,
  action: UserActions.All,
): UserState => {
  switch (action.type) {
    // case UserActions.ActionTypes.GET_BY_EMAIL:
    //     return userStateFactory.adapter.upsertOne({
    //         ...action.payload, loading: true
    //     }, state);
    // case UserActions.ActionTypes.GET_BY_EMAIL_SUCCESS:
    //     return userStateFactory.adapter.upsertOne(
    //         { ...action.payload }, state
    //     );
    case UserActions.ActionTypes.GET_BY_EMAIL:
      return { ...state, entityFetching: true };
    case UserActions.ActionTypes.GET_BY_EMAIL_SUCCESS:
      return userStateFactory.adapter.upsertOne(action.payload, {
        ...state,
        entityFetching: false,
      });
    case UserActions.ActionTypes.GET_BY_EMAIL_FAILURE:
      return userStateFactory.adapter.upsertOne(
        {
          id: action.payload.faultyEmail,
          error: action.payload.message,
          email: action.payload.faultyEmail,
          retrievedUsing: RetrievalType.EMAIL,
        },
        { ...state, entityFetching: false },
      );
    default:
      return userStateFactory.createReducer(state, action);
  }
};
