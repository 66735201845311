import { AbstractControl } from '@angular/forms';
import { BinaryDataToClone, Profile } from 'prosumer-app/+scenario';
import { generateShortUID } from 'prosumer-app/libs/eyes-shared';

type PatchValueFn = (value: BinaryDataToClone) => void;

export const getProfileId = (profile: Profile): string =>
  profile.originalLocalId || profile.localId;
export const patchProfileToCloneControl = (
  control: AbstractControl,
  profile: BinaryDataToClone,
) => control.patchValue([...control.value, profile]);

export const mapProfilesForDuplicate = (
  profiles: Array<Profile>,
  fn: PatchValueFn,
): Array<Profile> => {
  profiles = profiles.map((profile) => {
    const localId = getProfileId(profile);
    const newLocalId = generateShortUID();
    fn({ ...profile, localId, newLocalId });
    return {
      ...profile,
      localId: newLocalId,
      originalLocalId: localId,
    };
  });
  return profiles;
};

export const mapProfilesToDuplicateAndPatchToCloneControl = (
  profiles: Array<Profile> = [],
  control?: AbstractControl,
): Array<Profile> =>
  profiles.map((profile) => {
    if (!!profile.loadProfile && profile.loadProfile.length > 0) {
      return { ...profile, localId: generateShortUID() };
    }

    const localId = getProfileId(profile);
    const newLocalId = generateShortUID();

    if (!!control) {
      patchProfileToCloneControl(control, { ...profile, localId, newLocalId });
    }

    return {
      ...profile,
      localId: newLocalId,
      originalLocalId: localId,
    };
  });
