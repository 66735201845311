import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'prosumer-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressCardComponent implements OnInit {
  @Input() showProgress: boolean;
  @Input() bufferValue: number;
  @Input() value: number;
  @Input() color: ThemePalette = 'accent';
  @Input() mode: 'determinate' | 'indeterminate' | 'buffer' | 'query' =
    'indeterminate';

  constructor() {}

  ngOnInit() {}
}
