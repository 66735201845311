<section class="eyes-section" fxLayout="column" fxLayoutGap="10px">
  <ng-content select="[eyes-prefix-item]"></ng-content>
  <div class="page-header">
    <h1 class="mat-headline-6 eyes-title">{{ pageTitle }}</h1>
    <span fxFlex></span>
    <div
      class="eyes-button-container"
      fxLayout="row"
      fxLayoutAlign="stretch center"
      fxLayoutGap="10px"
    >
      <button
        *ngFor="let button of buttons"
        mat-raised-button
        [type]="button?.behavior"
        [color]="button?.color"
        [disabled]="button?.disabled"
        [routerLink]="button?.link"
        (click)="button?.eyesClick?.emit($event)"
      >
        {{ button?.label }}
      </button>
    </div>
  </div>
  <div class="eyes-page-content">
    <ng-content></ng-content>
  </div>
  <ng-content select="[eyes-suffix-item]"></ng-content>
</section>
