import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

@Component({
  selector: 'prosumer-tooltip-button',
  templateUrl: './tooltip-button.component.html',
  styleUrls: ['./tooltip-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipButtonComponent {
  @Input() disabledAddBtnMessage: string | undefined;
  @Input() isBtnDisabled: boolean;
  @Input() matIconLabel: string;
  @Input() buttonText: string;

  @Output() clickEvent = new EventEmitter();

  constructor() {}

  onClick() {
    this.clickEvent.emit();
  }
}
