import { Pipe, PipeTransform } from '@angular/core';

import { getValues } from '../utilities';

@Pipe({
  name: 'firstValue',
})
export class FirstValuePipe implements PipeTransform {
  transform(value: any): any {
    if (!!!value) {
      return null;
    }
    const values = getValues(value);
    if (values.length > 0) {
      return getValues(value)[0];
    }
    return null;
  }
}
