import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import { StackedAreaData } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import {
  NameValues,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';

import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import {
  ResultNameValue,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NgxChartUtils = {
  generateBarColorScheme,
  generatePieColorScheme,
  mapStackedAreaToChartJSs,
  mapStackedBarToChartJS,
};

type NgxChartScheme = { name; selectable; group; domain: string[] };

function generateBarColorScheme(
  data: VisualizerData[],
  colorMap: PerceptionMap,
): NgxChartScheme {
  return !!data.length
    ? spreadDefaultSchemeWithDomain(
        data[0].series.map((siri) => siri.name),
        colorMap,
      )
    : ENGIE_DEFAULT_SCHEME;
}

function resolveColor(
  legendName: string,
  colorMap: PerceptionMap,
  currentIdx: number,
): string {
  return (
    (colorMap || {})[legendName] || ENGIE_DEFAULT_SCHEME.domain[currentIdx]
  );
}

function generatePieColorScheme(
  data: ResultNameValue[],
  colorMap: PerceptionMap,
): NgxChartScheme {
  return !!data.length
    ? spreadDefaultSchemeWithDomain(
        data.map((siri) => siri.name),
        colorMap,
      )
    : ENGIE_DEFAULT_SCHEME;
}

function spreadDefaultSchemeWithDomain(
  names: string[],
  colorMap: PerceptionMap,
): NgxChartScheme {
  return {
    ...ENGIE_DEFAULT_SCHEME,
    domain: names.map((name, idx) => resolveColor(name, colorMap, idx)),
  };
}

function mapStackedAreaToChartJSs(data: VisualizerData[]): StackedAreaData {
  if (!!!data || data.length === 0) {
    return {
      xAxisTicks: [],
      stacks: [],
    };
  }
  return {
    xAxisTicks: data[0].series.map((siri) => siri.name),
    stacks: data.map((d) => ({
      label: d.name,
      points: d.series.map((siri) => siri.value),
    })),
  };
}

function mapStackedBarToChartJS(
  data: VisualizerData[],
  dataNames: string[],
): StackedBarMeta {
  return {
    axisTicks: data.map((d) => d.name),
    data: dataNames.map((name) => ({
      name,
      values: reduceValuesBasedOnDataName(data, name),
    })),
  };
}

function reduceValuesBasedOnDataName(
  data: VisualizerData[],
  dataName: string,
): NameValues {
  return data.reduce((acc, curr) => {
    const { name, series } = curr;
    acc[name] = findValueFromSeriesBasedOnDataName(series, dataName);
    return acc;
  }, {});
}

function findValueFromSeriesBasedOnDataName(
  series: ResultNameValue[],
  dataName: string,
): number {
  return series.find((siri) => siri.name === dataName)?.value;
}
