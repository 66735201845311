import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-app/shared';
import { ScenarioActionsModule } from '../scenario-actions';
import { ScenarioNameModule } from '../scenario-name';
import { ScenarioDetailsComponent } from './scenario-details.component';

@NgModule({
  declarations: [ScenarioDetailsComponent],
  imports: [
    EyesSharedModule.forFeature(),
    SharedModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    LetDirective,
    PushPipe,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    ScenarioActionsModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    RouterModule,
    ScenarioNameModule,
  ],
  exports: [ScenarioDetailsComponent],
})
export class ScenarioDetailsModule {}
