import { EquipmentInfo } from 'prosumer-app/stores/equipment';
import { YearlyValues } from 'prosumer-shared';

import { BinaryData, BinaryDelete, Profile } from './binary-data.model';
import { OperatingCost } from './operating-cost.model';
import { TdbActiveFilters } from './tdb-data.model';

export type EquipmentType =
  | 'vre'
  | 'converter'
  | 'storage'
  | 'generator'
  | 'vehicle'
  | 'station';

export const EQUIPS_WITH_BIN: Array<EquipmentType> = ['vre'];

export interface Equipment extends BinaryData {
  readonly name?: string;
  readonly type?: EquipmentType;
  readonly nodes?: Array<string>;
  readonly scenarioVariation?: string;
  readonly outputEnergyVector?: string;
  readonly loadName?: string;
  readonly profiles?: Array<Profile>;
  readonly capacityLoss?: string;
  readonly minPower?: string;
  readonly maxPower?: string;
  readonly forcedInvestment?: boolean;
  readonly buildCost?: string;
  readonly indivisibleCost?: string;
  readonly fOAndMCharge?: string;
  readonly fOAndMPerInstall?: string;
  readonly technicalLife?: number;
  readonly depreciationTime?: number;
  readonly id?: string;
  readonly library?: any;
  readonly sourceType?: string;
  readonly buildEmissionsKw?: string;
  readonly buildEmissionsIndivisible?: string;
  // Yearly Values
  readonly yearlyCapacityLoss?: YearlyValues;
  readonly yearlyMinPower?: YearlyValues;
  readonly yearlyMaxPower?: YearlyValues;
  readonly yearlyBuildCost?: YearlyValues;
  readonly yearlyIndivisibleCost?: YearlyValues;
  readonly yearlyFOAndMCharge?: YearlyValues;
  readonly yearlyFOAndMInstall?: YearlyValues;
  readonly yearlyTechnicalLife?: YearlyValues;
  readonly yearlyDepreciationTime?: YearlyValues;
  readonly startYear?: number;
  readonly endYear?: number;
  readonly existingAsset?: boolean;
  readonly capacityExpansion?: boolean;
  readonly yearlyBuildEmissionsKw?: YearlyValues;
  readonly yearlyBuildEmissionsIndivisible?: YearlyValues;
  readonly yearlyFootprint?: YearlyValues;
  // tdb related
  readonly tdbTechnologyFilter?: TdbActiveFilters;
  readonly tdbProfileFilters?: TdbActiveFilters;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RenewableEnergy extends Equipment, EquipmentInfo {
  readonly operatingCostProfiles?: Array<OperatingCost>;
  readonly yearlyCostOfDecommissioning?: YearlyValues;
  readonly yearlyCostOfFinancing?: YearlyValues;
}

export interface Converter extends Equipment, EquipmentInfo {
  readonly inputEnergyVector?: string;
  readonly secondOutputEnergyVector?: string;
  readonly efficiency?: string;
  readonly secondEfficiency?: string;
  readonly efficiencyMatrix?: EfficiencyMatrix;
  readonly operatingCostProfiles?: Array<OperatingCost>;
  // Please check the following if used
  readonly energyVector?: string;
  readonly secondary?: string;
  // Yearly Values
  readonly yearlyEfficiency?: YearlyValues;
  readonly yearlyEfficiency2nd?: YearlyValues;
  readonly yearlyCostOfFinancing?: YearlyValues;
  readonly yearlyCostOfDecommissioning?: YearlyValues;
}

export interface EfficiencyMatrix {
  readonly mainEnergyVectorInput?: string;
  readonly mainEnergyVectorOutput?: string;
  readonly efficiencies?: Array<Efficiency>;
}

export interface Efficiency {
  readonly energyVectorIn?: string;
  readonly energyVectorOut?: string;
  readonly value?: YearlyValues;
}

export interface Storage extends Equipment, EquipmentInfo {
  readonly energyVector?: string;
  readonly secondCapacityLoss?: string;
  readonly roundTripEfficiency?: string;
  readonly maxDoD?: string;
  readonly minEp?: string;
  readonly maxEp?: string;
  readonly minEnergy?: string;
  readonly maxEnergy?: string;
  readonly chargeDischargeRateFactor?: string;
  readonly dissipationRate?: string;
  readonly agingFactor?: string;
  readonly secondBuildCost?: string;
  readonly fOAndMChargeKwh?: string;
  readonly buildEmissionsKwh?: string;
  readonly operatingCostProfiles?: Array<OperatingCost>;
  // Yearly Values
  readonly yearlySecondCapacityLoss?: YearlyValues;
  readonly yearlyRoundTripEfficiency?: YearlyValues;
  readonly yearlyMaxDoD?: YearlyValues;
  readonly yearlyMinEp?: YearlyValues;
  readonly yearlyMaxEp?: YearlyValues;
  readonly yearlyChargeDischargeRateFactor?: YearlyValues;
  readonly yearlyAgingFactor?: YearlyValues;
  readonly yearlySecondBuildCost?: YearlyValues;
  readonly yearlyDissipationRate?: YearlyValues;
  readonly yearlyMinEnergy?: YearlyValues;
  readonly yearlyMaxEnergy?: YearlyValues;
  readonly yearlyFOAndMChargeKWh?: YearlyValues;
  readonly yearlyBuildEmissionsKwh?: YearlyValues;
  readonly yearlyCostOfDecommissioning?: YearlyValues;
  readonly yearlyCostOfFinancing?: YearlyValues;
}

export interface EfficiencyPoint {
  readonly percentageOfCapacity?: string;
  readonly consumption?: string;
}

export interface Generator extends Equipment, EquipmentInfo {
  readonly inputEnergyVector?: string;
  readonly individualSize?: string;
  readonly pmin?: string;
  readonly efficiencyPmin?: string;
  readonly efficiencyPmax?: string;
  readonly startUpLoss?: string;
  readonly idleLoss?: string;
  readonly minUpTime?: string;
  readonly minDownTime?: string;
  readonly minRunningTime?: string;
  readonly efficiencyCurve?: Array<EfficiencyPoint>;
  readonly operatingCostProfiles?: Array<OperatingCost>;
  // Please check the following if used
  readonly energyVector?: string;
  readonly runningCost?: string;
  // Yearly values
  readonly yearlyIndividualSize?: YearlyValues;
  readonly yearlyPMin?: YearlyValues;
  readonly yearlyEfficiencyPMin?: YearlyValues;
  readonly yearlyEfficiencyPMax?: YearlyValues;
  readonly yearlyMinUpTime?: YearlyValues;
  readonly yearlyMinDownTime?: YearlyValues;
  readonly yearlyMinRunningTime?: YearlyValues;
  readonly yearlyStartUpLoss?: YearlyValues;
  readonly yearlyIdleLoss?: YearlyValues; // shutdown loss
  readonly yearlyRunningCost?: YearlyValues;
  readonly yearlyCostOfFinancing?: YearlyValues;
  readonly yearlyCostOfDecommissioning?: YearlyValues;
}

export interface Vehicle extends Storage {
  // driving efficiency
  readonly yearlyEfficiency2nd?: YearlyValues;
}

export interface Station extends Equipment, EquipmentInfo {
  readonly numberOfConnections: YearlyValues;
  readonly powerChargingPerConnection: YearlyValues;
  readonly vehicleToGrid: boolean;
  readonly timeSlots: YearlyValues;
  readonly minConnections: YearlyValues;
  readonly maxConnections: YearlyValues;
  readonly connectionCost: YearlyValues;
  readonly opexPerHour: YearlyValues;
  // Please check the following if used
  readonly energyVector?: string;
  readonly yearlyFOAndMChargeKWh?: YearlyValues;
}
export interface EquipmentForm extends BinaryDelete {
  readonly equipments?: Array<
    | Generator
    | RenewableEnergy
    | Storage
    | Converter
    | Equipment
    | Vehicle
    | Station
  >;
}
