import {
  ChangeDetectorRef,
  Directive,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionary } from '@ngrx/entity';

import { BaseControlComponent } from '../base-control/index';
import { ColumnDefinition } from './base-table.model';

@Directive()
export abstract class BaseTableComponent<T> extends BaseControlComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() name: string;
  @Input() loading: boolean;
  @Input() searchInput: boolean;
  @Input() searchSelect: boolean;
  @Input() error: any;

  @Input() pageSize = 50;
  @Input() references: Dictionary<any> = {};
  @Input() columnsDef = this.defineColumns();
  @Input() dataSource = new MatTableDataSource<T>([]);

  @Input() searchPredicate: (data: T, filter: string) => boolean;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(ngControl, changeDetector);
  }

  refreshData(data: Array<T>) {
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!!this.searchPredicate) {
      this.dataSource.filterPredicate = this.searchPredicate;
    }
  }

  getReference(key: string) {
    return this.references[key];
  }

  addReference(key: string, value: any) {
    this.references[key] = value;
  }

  abstract defineColumns(): ColumnDefinition;

  onKeyIn(searchValue: string) {
    this.dataSource.filter = searchValue;
  }
}
