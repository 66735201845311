import {
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
} from 'prosumer-app/+scenario/models';
import { contains } from 'prosumer-app/libs/eyes-shared';

/** Returns list of energy grid limits after filtering all energy grid connections affected by cascading delete
 *
 * @param energyGridLimits list of energy grid limit items
 * @param remainingConnectionIds ids of remaining energy grid connection items in the scenario
 * @return filtered energyGridLimits based on remaining energy grid connections
 */
export const cascadeFilterLimitsByConnections = (
  energyGridLimits: Array<EnergyGridLimit>,
  remainingConnectionIds: Array<string>,
) => {
  if (!!!energyGridLimits || !!!remainingConnectionIds) {
    return undefined;
  }

  return energyGridLimits.filter((energyGridLimit) =>
    contains(remainingConnectionIds, energyGridLimit.market),
  );
};

/** Return string containing list of affected energy grid limits
 *
 * @param energyGridLimits list of energy grid limit items
 * @param energyGridConnections list of energy grid connection items
 * @return string containing list of affected energy grid limits
 */
export const getAffectedEnergyGridLimits = (
  energyGridLimits: Array<EnergyGridLimit>,
  energyGridConnections: Array<EnergyGridConnection>,
) => {
  if (!!!energyGridLimits || !!!energyGridConnections) {
    return undefined;
  }

  return energyGridLimits
    .map((energyGridLimit) => {
      const marketName = energyGridConnections.find(
        (energyGridConnection) =>
          energyGridConnection.id === energyGridLimit.market,
      ).marketName;
      return `Market: ${marketName}`;
    })
    .filter(
      (value, index, energyGridLimits) =>
        energyGridLimits.indexOf(value) === index,
    )
    .reduce((prev, next) => prev + `<li>${next}</li>`, '');
};

export const getAffectedEnergyGridEmissions = (
  energyGridEmissions: Array<EnergyGridConnectionsEmissions>,
  energyGridConnections: Array<EnergyGridConnection>,
) => {
  if (!!!energyGridEmissions || !!!energyGridConnections) {
    return undefined;
  }

  return energyGridEmissions
    .map((energyGridEmission) => {
      const marketName = energyGridConnections.find(
        (energyGridConnection) =>
          energyGridConnection.id === energyGridEmission.marketName,
      ).marketName;
      return `Market: ${marketName}`;
    })
    .filter(
      (value, index, energyGridEmissions) =>
        energyGridEmissions.indexOf(value) === index,
    )
    .reduce((prev, next) => prev + `<li>${next}</li>`, '');
};
