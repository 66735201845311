import { ProfileType } from 'prosumer-app/+scenario';

const LIBRARY_TYPE_CUSTOM = 'custom';
const LIBRARY_TYPE_LIBRARY = 'library';

const isCustom = (id: string): boolean =>
  [!id, id === LIBRARY_TYPE_CUSTOM].some(Boolean);

export const getLibraryObject = (id: string): unknown =>
  isCustom(id) ? undefined : { id };

export const getLibrarySourceType = (id: string): ProfileType =>
  isCustom(id) ? LIBRARY_TYPE_CUSTOM : LIBRARY_TYPE_LIBRARY;
