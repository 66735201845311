import { createSelector } from '@ngrx/store';

import { ProjectState } from './project-state.model';
import { projectStateFactory } from './project.factory';

export const projectQueries = {
  getSample: createSelector(
    projectStateFactory.featureState,
    (state: ProjectState) => state.sample,
  ),
};
