import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { exponentialBackoff } from 'prosumer-app/libs/eyes-shared';
import { Observable, Observer, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';

enum Method {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}

const DEFAULT_TIMEOUT_MS = 10000;
const DEFAULT_RETRY_TIMES = 2;
const DEFAULT_RETRY_SCALING_DURATION_MS = 1000;
const DEFAULT_RETRY_INCLUDED_STATUS_CODES = [];
const DEFAULT_REFRESH_TOKEN_INCLUDED_STATUS_CODES = [];

@Injectable()
export class HttpService {
  constructor(
    private _config: ConfigService,
    private _http: HttpClient,
    private _logger: LoggerService,
  ) {
    const httpConfig = this._config.http;
    if (httpConfig) {
      this._maxRetryAttempts =
        httpConfig.maxRetryAttempts === undefined
          ? DEFAULT_RETRY_TIMES
          : httpConfig.maxRetryAttempts;
      this._refreshTokenIncludedStatusCodes =
        httpConfig.refreshTokenIncludedStatusCodes ||
        DEFAULT_REFRESH_TOKEN_INCLUDED_STATUS_CODES;
      this._retryScalingDuration =
        httpConfig.retryScalingDuration || DEFAULT_RETRY_SCALING_DURATION_MS;
      this._retryIncludedStatusCodes =
        httpConfig.retryIncludedStatusCodes ||
        DEFAULT_RETRY_INCLUDED_STATUS_CODES;
      this._timeoutMs = httpConfig.timeoutMs || DEFAULT_TIMEOUT_MS;
    }
  }

  private _maxRetryAttempts = DEFAULT_RETRY_TIMES;
  private _timeoutMs = DEFAULT_TIMEOUT_MS;
  private _retryScalingDuration = DEFAULT_RETRY_SCALING_DURATION_MS;
  private _retryIncludedStatusCodes = DEFAULT_RETRY_INCLUDED_STATUS_CODES;
  private _refreshTokenIncludedStatusCodes =
    DEFAULT_REFRESH_TOKEN_INCLUDED_STATUS_CODES;
  private _interrupt$: Observable<any> = new Observable(
    (observer: Observer<any>) => observer.complete(),
  );
  private _errorCallback = (error: HttpErrorResponse) => throwError(error);

  setInterruptObservable(interrupt$: Observable<any>): void {
    this._interrupt$ = interrupt$;
  }

  setErrorCallback(callback): void {
    this._errorCallback = callback;
  }

  mock(url: string, options?: any): Observable<any> {
    return this.request(Method.GET, url, options);
  }

  get(url: string, options?: any): Observable<any> {
    return this.request(Method.GET, url, options);
  }

  post(url: string, body?: any, options?: any): Observable<any> {
    return this.request(Method.POST, url, body, options);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return this.request(Method.PUT, url, body, options);
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    return this.request(Method.PATCH, url, body, options);
  }

  delete(url: string, options?: any): Observable<any> {
    return this.request(Method.DELETE, url, options);
  }

  request(
    method: Method,
    url: string,
    body?: any,
    options?: any,
  ): Observable<any> {
    let httpRequest;
    switch (method) {
      case Method.GET:
        httpRequest = this._http.get(url, options);
        break;
      case Method.POST:
        httpRequest = this._http.post(url, body, options);
        break;
      case Method.PATCH:
        httpRequest = this._http.patch(url, body, options);
        break;
      case Method.PUT:
        httpRequest = this._http.put(url, body, options);
        break;
      case Method.DELETE:
        httpRequest = this._http.delete(url, options);
        break;
    }
    // return httpRequest.pipe(
    //   timeout(this._timeoutMs),
    //   retryWhen(this.retryStrategy()),
    //   takeUntil(this._interrupt$),
    //   shareReplay(1)
    // );
    return httpRequest;
  }

  retryStrategy = () => (attempts: Observable<any>) =>
    attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // If maximum number of retries have been met or response' status code is not included we will not retry but throw error
        if (
          retryAttempt > this._maxRetryAttempts ||
          !this.isStatusCodeForRetry(error.status)
        ) {
          return throwError(error);
        }

        const retryTimeout = exponentialBackoff(
          retryAttempt - 1,
          this._retryScalingDuration,
          1,
          1000,
        );
        this._logger.debug(
          `Attempt ${retryAttempt}: retrying in ${retryTimeout / 1000}s`,
        );
        return timer(retryTimeout);
      }),
      finalize(() => this._logger.debug('Done...')),
    );

  isStatusCodeForRetry(statusCode: number) {
    return this._retryIncludedStatusCodes.find(
      (statusCodeItem) => statusCodeItem === statusCode,
    );
  }

  isStatusCodeForRefreshToken(statusCode: number) {
    return this._refreshTokenIncludedStatusCodes.find(
      (statusCodeItem) => statusCodeItem === statusCode,
    );
  }
}
