import * as ChartJSTypes from 'chart.js';
import Chart from 'chart.js/auto';
import * as Color from 'color';
import { PipeUtils, Utils } from 'prosumer-core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';

import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { ResultsPerceptionScheme } from '../charts/utils';
import { ChartColors, ChartJSMeta } from './chartjs.model';

@Directive()
export abstract class ChartJSChart<T extends ChartJSMeta>
  implements AfterViewInit, OnDestroy
{
  private justDestroyed = new Subject();
  private dataState = new BehaviorSubject<T>(undefined);
  private colorsState = new BehaviorSubject<ChartColors>({});
  private legendState = new BehaviorSubject<boolean>(true);
  private refreshTrigger = new Subject();

  public chartInstance: Chart;
  public legendShowing$ = this.legendState.asObservable();
  public justDestroyed$ = this.justDestroyed.asObservable();

  @Input() set data(data: T) {
    this.dataState.next(data);
  }
  @Input() id: string = this.getDefaultID();
  @Input() set colors(colors: ChartColors) {
    this.colorsState.next(colors);
  }
  @Input() set showLegend(show: boolean) {
    this.legendState.next(show);
  }

  @Input() get isLegendShowing(): boolean {
    return this.legendState.getValue();
  }

  @Input() emitLegendClicks = true;
  @Input() scenarioName = 'scenario';
  @Input() resultsName = 'results';
  @Input() set demo(_: boolean) {
    this.dataState.next(this.getDemoData());
  }
  @Input() isCustomLegend = false;

  @Output() legendSelect = new EventEmitter<string>();

  abstract getDefaultID(): string;
  abstract getChartType(): ChartJSTypes.ChartType;
  abstract mapToChartJSData(data: T): ChartJSTypes.ChartData;
  abstract getAdditionalChartOptions(data: T): ChartJSTypes.ChartOptions;
  abstract getDemoData(): T;
  abstract initializeCustomLegend(chart: Chart): void;
  ngAfterViewInit(): void {
    this.subscribeForChartJSRefreshing();
    this.additionalAfterViewInit();
  }

  additionalAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.justDestroyed.next(undefined);
  }

  resolveColor(name: string): string {
    return Utils.resolveToEmptyObject(this.colorsState.value)[name];
  }

  resolveColorByIdx(idx: number): string {
    return ResultsPerceptionScheme.getColor(idx);
  }

  translucinizeColor(color: string): string {
    return `${color}BF`;
  }

  onLegendToggle(): void {
    const current = this.legendState.value;
    this.legendState.next(!current);
  }

  refresh(): void {
    this.refreshTrigger.next(undefined);
  }

  darkenColor(color: string): string {
    return Color(color).darken(0.1).hex();
  }

  lightenColor(color: string): string {
    return Color(color).lighten(0.1).hex();
  }

  private subscribeForChartJSRefreshing(): void {
    combineLatest([
      this.dataState.pipe(PipeUtils.filterOutUndefined),
      this.colorsState,
      this.legendState,
      this.refreshTrigger.pipe(startWith('')),
    ])
      .pipe(
        takeUntil(this.justDestroyed),
        tap(() => this.chartInstance?.destroy()),
      )
      .subscribe(([data, colors]) => {
        this.chartInstance = this.initializeChartJS({ ...data, colors });
        this.initializeCustomLegend(this.chartInstance);
      });
  }

  private initializeChartJS(data: T): ChartJSTypes.Chart {
    return new Chart(this.getChartContext(), this.getChartConfiguration(data));
  }

  private getChartConfiguration(data: T): ChartJSTypes.ChartConfiguration {
    return {
      type: this.getChartType(),
      data: this.mapToChartJSData(data),
      options: this.createChartOptions(data),
    };
  }

  private getChartContext(): CanvasRenderingContext2D {
    return (document.getElementById(this.id) as HTMLCanvasElement).getContext(
      '2d',
    );
  }

  private createChartOptions(data: T): ChartJSTypes.ChartOptions {
    return {
      plugins: {
        legend: this.createLegendOptions(),
        title: { text: data.name, display: true },
      },
      ...this.getAdditionalChartOptions(data),
    };
  }
  private createLegendOptions(): Partial<ChartJSTypes.LegendOptions> {
    return {
      display: this.isCustomLegend ? false : this.legendState.value,
      position: 'bottom',
      ...this.overrideLegendClickIfApplicable(),
    };
  }

  private overrideLegendClickIfApplicable(): Partial<ChartJSTypes.LegendOptions> {
    return this.emitLegendClicks
      ? {
          onClick: (e, item, legend) => {
            this.legendSelect.emit(item.text);
            this.defaultOnClick(e, item, legend);
          },
        }
      : {};
  }

  private defaultOnClick(
    e: ChartJSTypes.ChartEvent,
    item: ChartJSTypes.LegendItem,
    legend: ChartJSTypes.LegendElement,
  ): void {
    const index = item.datasetIndex;
    const ci = (legend as any).chart;
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      item.hidden = true;
    } else {
      ci.show(index);
      item.hidden = false;
    }
  }

  protected isDatasetVisible(index: number): boolean {
    return this.chartInstance.isDatasetVisible(index);
  }
}
