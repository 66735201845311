import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Case } from 'prosumer-app/+case';
import { ResultsPerceptionScheme } from 'prosumer-app/shared/modules/charts/utils';
import { Utils } from 'prosumer-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasePerceptionBank, PerceptionMap } from './results-perception.model';

@Injectable({ providedIn: 'root' })
export class ResultsPerceptionService {
  private casePerceptionBank = new BehaviorSubject<CasePerceptionBank>({});

  initializePerception(caseObj: Case): void {
    const storedPerception = caseObj.colorScheme
      ? caseObj.colorScheme
      : this.getStoredPerception(caseObj.id);
    this.casePerceptionBank.next({
      ...this.casePerceptionBank.value,
      [caseObj.id]: storedPerception,
    });
  }

  getPerceptionMapStream(caseId: string): Observable<PerceptionMap> {
    return this.casePerceptionBank.pipe(
      map((bank) => Utils.resolveToEmptyObject(bank[caseId])),
    );
  }

  registerLegendNames(caseId: string, names: string[]): void {
    const current = Utils.resolveToEmptyObject(
      this.casePerceptionBank.value[caseId],
    );
    const filtered = names.filter((name) => !(name in current));
    const newPerceptionMap = this.generatePerceptionMapForNewNames(filtered);

    this.updatePerception(caseId, newPerceptionMap);
  }

  updatePerception(caseId: string, perception: PerceptionMap): void {
    const current = Utils.resolveToEmptyObject(
      this.casePerceptionBank.value[caseId],
    );
    const merged = { ...current, ...perception };
    if (!_.isEqual(current, merged)) {
      this.casePerceptionBank.next({ [caseId]: merged });
    }
  }

  private generatePerceptionMapForNewNames(names: string[]): PerceptionMap {
    return names.reduce((acc, curr, index) => {
      acc[curr] = ResultsPerceptionScheme.getColor(index);
      return acc;
    }, {});
  }

  private getStoredPerception(forCaseId: string): PerceptionMap {
    return Utils.resolveToEmptyObject(this.casePerceptionBank.value[forCaseId]);
  }
}
