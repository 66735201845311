import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableDataLink',
})
export class TableDataLinkPipe implements PipeTransform {
  transform(baseLink: any, data?: any, dataFields?: Array<string>): any {
    if (!baseLink) {
      return [''];
    }

    const link = [baseLink];
    if (data && dataFields) {
      return link.concat(dataFields.map((field) => data[field]));
    }
    return link;
  }
}
