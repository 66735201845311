import { Scenario } from 'prosumer-app/+scenario/models';
import { Coerce } from 'prosumer-app/core';
import { ScenarioListExt } from 'prosumer-scenario/utils';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ScenarioBatchAction } from '../scenario-batch';
import { ScenarioListBase } from './scenario-list.component.base';
import { ScenarioListService } from './scenario-list.service';
import {
  SCENARIO_LIST_CONFIG,
  ScenarioListConfig,
} from './scenario-list.tokens';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-list',
  templateUrl: './scenario-list.component.html',
  styleUrls: ['./scenario-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScenarioListService],
})
export class ScenarioListComponent extends ScenarioListBase implements OnInit {
  private readonly isRowLoading = ({ downloading }: Scenario) => !!downloading;
  private readonly scenariosSubject = new BehaviorSubject<Scenario[]>([]);
  readonly selected = new BehaviorSubject<Scenario[]>([]);

  @Input() set scenarios(scenarios: Scenario[]) {
    this.scenariosSubject.next(Coerce.toArray(scenarios));
  }
  @Input() loading = false;
  @Input() allowEditScenarioName = false;
  @Input() isRowSelectable = false;

  @Output() nameClick = new EventEmitter<Scenario>();
  @Output() caseClick = new EventEmitter<Scenario>();
  @Output() projectClick = new EventEmitter<Scenario>();
  @Output() logClick = new EventEmitter<Scenario>();
  @Output() deleteSuccess = new EventEmitter<boolean>();

  readonly mergedScenarioList = signal<Scenario[]>([]);
  readonly selected$ = this.getSelectedWithDownloadState();

  rowClassFormater = { 'row-spinner': this.isRowLoading };
  rowSelectionFormater = { disabled: this.isRowLoading };
  columns: MtxGridColumn[] = [];

  constructor(
    @Inject(SCENARIO_LIST_CONFIG) private readonly config: ScenarioListConfig,
    private readonly service: ScenarioListService,
  ) {
    super();
    this.subscribeToServiceDeleteSuccessForPropagation();
    this.subscribeToScenariosForSelectedResetting();
    this.subscribeToListForMerge();
  }

  ngOnInit(): void {
    this.columns = this.createMtxGridColumns(this.config);
  }

  onSelectedChange(scenarios: Scenario[]): void {
    this.selected.next(scenarios);
  }

  onNameClick(scenario: Scenario): void {
    if (this.isScenarioClickable(scenario)) {
      this.nameClick.emit(scenario);
    }
  }

  onCaseClick(scenario: Scenario): void {
    if (!this.hasScenarioError(scenario)) {
      this.caseClick.emit(scenario);
    }
  }

  onProjectClick(scenario: Scenario): void {
    if (!this.hasScenarioError(scenario)) {
      this.projectClick.emit(scenario);
    }
  }

  onDelete(scenario: Scenario): void {
    this.service.batchScenarios(ScenarioBatchAction.delete, [scenario]);
  }

  onBatchAction(action: ScenarioBatchAction): void {
    this.service.batchScenarios(action, this.selected.value);
  }

  hasScenarioError(scenario: Scenario): boolean {
    return ScenarioListExt.hasScenarioError(scenario);
  }

  isScenarioClickable(scenario: Scenario): boolean {
    return ScenarioListExt.isScenarioClickable(scenario);
  }

  private subscribeToListForMerge(): void {
    combineLatest([
      this.scenariosSubject.asObservable(),
      this.service.selectScenarioFacadeList(),
    ])
      .pipe(
        untilDestroyed(this),
        map(([list, dataList]) =>
          list.map((item) => this.service.mergeScenario(dataList, item)),
        ),
      )
      .subscribe((list) => this.mergedScenarioList.set(list));
  }

  private subscribeToScenariosForSelectedResetting(): void {
    this.scenariosSubject
      .pipe(untilDestroyed(this))
      .subscribe(() => this.selected.next([]));
  }

  private subscribeToServiceDeleteSuccessForPropagation(): void {
    this.service.deleteSuccess$
      .pipe(untilDestroyed(this))
      .subscribe((success) => this.conditionallyHandleDeleteSuccess(success));
  }

  private conditionallyHandleDeleteSuccess(should: boolean): void {
    if (should) {
      this.deleteSuccess.emit(true);
    }
  }

  private getSelectedWithDownloadState(): Observable<boolean[]> {
    return this.selected
      .asObservable()
      .pipe(map((scenarios) => scenarios.map(this.getScenarioDownloadState)));
  }

  private getScenarioDownloadState(scenario: Scenario): boolean {
    return [
      !!Coerce.toObject(scenario.mergeResults).outputFile,
      !!Coerce.toObject(scenario.outputFile).key,
    ].some(Boolean);
  }
}
