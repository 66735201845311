import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'prosumer-shared';
import { CumulativeVisualizationComponent } from './cumulative-visualization.component';

@NgModule({
  declarations: [CumulativeVisualizationComponent],
  imports: [CommonModule, ChartsModule, FlexLayoutModule, TranslateModule],
  exports: [CumulativeVisualizationComponent],
})
export class CumulativeVisualizationModule {}
