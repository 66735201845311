import {
  Routes,
  StationVehicleAssoc,
  VehiclesDispatch,
} from 'prosumer-app/+scenario/models';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  contains,
  FormFieldOption,
  generateShortUID,
} from 'prosumer-app/libs/eyes-shared';
import { getUpdatedStationVehicleAssoc } from 'prosumer-shared';
import { BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ScenarioDetailType } from 'prosumer-app/stores';
import { MobilityRouteStore } from 'prosumer-app/stores/mobility-route';
import { MobilityFormService } from '../mobility-form.service';
import {
  MobilityRoutesFormDialogComponent,
  RoutesFormDialogData,
} from '../routes-form-dialog';

@Component({
  selector: 'prosumer-routes-form-component',
  templateUrl: './routes-form.component.html',
  styleUrls: ['./routes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutesFormComponent {
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;

  _nodes$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  @Input() set nodes(nodeOptions: FormFieldOption<string>[]) {
    this._nodes$.next(nodeOptions);
  }

  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  _vehicleDispatchList: Array<VehiclesDispatch> = [];
  @Input() set vehicleDispatchList(value: VehiclesDispatch[]) {
    this._vehicleDispatchList = value;
  }

  _stationVehicleAssocList: Array<StationVehicleAssoc> = [];
  @Input() set stationVehicleAssocList(value: StationVehicleAssoc[]) {
    this._stationVehicleAssocList = value;
  }

  @Output() routeDeleteEvent = new EventEmitter();

  tableMetaData: ColumnDefinition = {
    name: {
      name: 'Name',
      type: 'reference',
      referenceKey: 'name',
      toolTip: 'wizard_mobility.wizard_mobility_name',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      sortable: true,
    },
  };

  routesList$ = this.service.selectActiveMobilityRoutes();

  constructor(
    private dialog: DialogService,
    private service: MobilityFormService,
    private routes: MobilityRouteStore,
  ) {}

  generateRouteData(): RoutesFormDialogData {
    const dialogData = this.service.prepOptionsForMobilityRouteDialog();
    return {
      width: '90%',
      mode: 'add',
      name: '',
      id: undefined,
      disableClose: true,
      scenarioIdentity: this.scenarioIdentity,
      startYear: this.startYear,
      endYear: this.endYear,
      profileRoutesLocation: BINARY_LOCATIONS.MOBILITY_ROUTES,
      nodes$: this._nodes$.asObservable(),
      profileRoutes: [
        {
          startYear: this.startYear,
          endYear: this.endYear,
          forSaving: true,
          library: null,
          loadProfile: null,
          loadType: 'custom',
          localId: generateShortUID(),
          location: BINARY_LOCATIONS.MOBILITY_ROUTES,
          yearlyLoad: 1,
        },
      ],
      ...dialogData,
    };
  }

  onAddRoutes(): void {
    this.dialog
      .openDialog(MobilityRoutesFormDialogComponent, this.generateRouteData())
      .subscribe();
  }

  onEditRoutes(data: Routes) {
    this.routes
      .getSingle(ScenarioDetailType.mobilityRoute, data.id)
      .pipe(
        take(1),
        switchMap((route) =>
          this.dialog.openDialog(MobilityRoutesFormDialogComponent, {
            ...this.generateRouteData(),
            ...route,
            mode: 'edit',
            routeEditData: route,
            isViewOnly: this.isViewOnly,
          } as Routes),
        ),
      )
      .subscribe();
  }

  isVehiclesUsesRoutes(vehicles: Array<VehiclesDispatch>, route: Routes) {
    if (!!route) {
      const routesList = [];
      vehicles.map((vehicle) =>
        vehicle.routeIds.map((routeId) => {
          routesList.push(routeId);
          return routeId;
        }),
      );
      return contains(routesList, route.id);
    }
    return false;
  }

  getMobilityWithoutReferenceRoute(
    vehicles: Array<VehiclesDispatch>,
    stationVehicleAssoc: Array<StationVehicleAssoc>,
    route: Routes,
  ) {
    const remainingVehicles = vehicles.filter(
      (vehicle) => !contains(vehicle.routeIds, route.id),
    );
    const remainingVehiclesIds = remainingVehicles.map((vehicle) => vehicle.id);

    const updatedAssoc: StationVehicleAssoc[] = getUpdatedStationVehicleAssoc(
      stationVehicleAssoc,
      vehicles,
      remainingVehicles,
    );
    const filteredAssoc = updatedAssoc.filter(
      (assoc) =>
        assoc.vehicleNames.filter(
          (vehicle) => !contains(remainingVehiclesIds, vehicle),
        ).length === 0,
    );
    return {
      vehicles: remainingVehicles,
      stationVehicleAssoc: filteredAssoc,
    };
  }

  onDeleteRoutes(route: Routes) {
    this.routeDeleteEvent.emit(route);
  }
}
