<form
  fxLayout="row"
  fxLayoutAlign="space-around center"
  fxLayoutGap="15px"
  [formGroup]="searchForm"
  (ngSubmit)="onSearch()"
>
  <mat-form-field fxFlex appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      type="text"
      matInput
      formControlName="searchInput"
      autofocus
      [placeholder]="placeholder"
    />
    <mat-icon aria-hidden="false" matSuffix>search</mat-icon>
  </mat-form-field>
</form>
