import moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';
import { contains } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest, of, pipe } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  DEFAULT_DEBOUNCE_TIME,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
} from '../../../../app.references';
import { ChartData } from '../models';

export interface DateChartData extends ChartData<string, number> {
  moment: moment.Moment;
}

@Injectable()
export class DateFilterService {
  minDate$ = new BehaviorSubject<number>(1);
  maxDate$ = new BehaviorSubject<number>(7);
  year = 2019;
  defaultSliderOptions = {
    floor: 1,
    ceil: 365,
    step: 1,
    minRange: 0,
    maxRange: 31,
    draggableRange: true,
    showTicks: true,
  };

  constructor() {}

  dateChange$ = combineLatest([this.minDate$, this.maxDate$]).pipe(
    startWith([this.minDate$.value, this.maxDate$.value]),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    distinctUntilChanged(),
  );

  sliderOptions$ = of(this.defaultSliderOptions).pipe(
    map((sliderOptions) => this.getSliderOptions(this.year, sliderOptions)),
  );

  filter$ = (name: string, year: number = this.year) =>
    pipe(
      switchMap(([data, [minDay, maxDay]]) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const minDate = minDay
          ? moment({ y: year, M: 0, d: 1, h: 0, m: 0, s: 0, ms: 0 }).dayOfYear(
              minDay,
            )
          : undefined;
        const maxDate = maxDay
          ? // eslint-disable-next-line @typescript-eslint/naming-convention
            moment({
              y: year,
              M: 0,
              d: 1,
              h: 23,
              m: 59,
              s: 59,
              ms: 999,
            }).dayOfYear(maxDay)
          : undefined;
        return !data
          ? of([])
          : of([
              {
                name,
                series: data
                  .map((item, index) => this.mapDataToSeries(item, index, year))
                  .filter((seriesItem) =>
                    this.filter(seriesItem, minDate, maxDate),
                  ),
              },
            ]);
      }),
    );

  mapDataToSeries(
    item: string,
    index: number,
    year: number = this.year,
  ): DateChartData {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      name: moment({ y: year, M: 0, d: 1, h: 0, m: 0, s: 0, ms: 0 })
        .hour(index)
        .toISOString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      moment: moment({ y: year, M: 0, d: 1, h: 0, m: 0, s: 0, ms: 0 }).hour(
        index,
      ),
      value: Number.isNaN(Number.parseFloat(item))
        ? 0
        : Number.parseFloat(item),
    };
  }

  filter(
    seriesData: DateChartData,
    minDate: moment.Moment,
    maxDate: moment.Moment,
  ): boolean {
    return minDate && maxDate
      ? minDate.isSameOrBefore(seriesData.moment) &&
          maxDate.isSameOrAfter(seriesData.moment)
      : true;
  }

  getSliderOptions(
    year: number = this.year,
    sliderOptions = this.defaultSliderOptions,
  ): Options {
    const sliderTicks = [];
    const monthIndexes = Array.from(Array(12).keys());

    let accumulatedDays = 0;
    monthIndexes.forEach((value) => {
      const daysInMonth = moment().year(year).month(value).daysInMonth();
      const monthName = moment()
        .month(value)
        .format(RESULTS_DISPATCH_MONTH_FORMAT);
      const midDayInMonth = daysInMonth / 2;
      const position = accumulatedDays + midDayInMonth;
      accumulatedDays += daysInMonth;
      sliderTicks.push({
        value: accumulatedDays,
        legend: monthName,
        position: position.toFixed(0),
      });
    });

    return {
      ...sliderOptions,
      ceil: accumulatedDays,
      translate: (value: number): string => {
        const date = moment()
          .year(year)
          .dayOfYear(value)
          .format(
            `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT}`,
          );
        return date;
      },
      getLegend: (value: number): string => {
        const positions = sliderTicks.map((tick) => tick.position);
        if (contains(positions, '' + value)) {
          return sliderTicks.find((tick) => tick.position === '' + value)
            .legend;
        }
        return '';
      },
    };
  }
}
