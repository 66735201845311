import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { DetailsOverlayRef } from './details-overlay-ref';
import { DETAILS_OVERLAY_DATA, Data } from './details-overlay.tokens';

@Component({
  selector: 'prosumer-details-overlay',
  templateUrl: './details-overlay.component.html',
  styleUrls: ['./details-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsOverlayComponent {
  title: string;
  constructor(
    public dialogRef: DetailsOverlayRef,
    @Inject(DETAILS_OVERLAY_DATA) public data: Data,
  ) {
    if (!!data && !!data.rawData) {
      data.rawData = Object.keys(data.rawData).reduce(
        (c, k) => ((c[k.toLowerCase()] = data.rawData[k]), c),
        {},
      );
    }
    this.getTitle();
  }

  /**
   * This function updates the panel name removing the underscore
   */
  getTitle() {
    if (this.data && this.data.name) {
      this.title = this.data.name.replace('_', ' ');
    }
  }

  /**
   * @param key - metadata key
   * checks if the key is present in rawdata
   */
  checkIfExist(key: string): boolean {
    return key in this.data.rawData;
  }

  /**
   * closes the panel
   */
  close() {
    this.dialogRef.close();
  }
}
