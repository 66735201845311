import { StateFactory } from 'prosumer-app/libs/eyes-shared';

import { RenewableProfile } from '../models';
import { RenewableProfileState } from './renewable-profile-state.model';

export const renewableProfileFeature = 'RenewableProfile';
export const renewableProfileStateFactory = new StateFactory<
  RenewableProfileState,
  RenewableProfile
>(renewableProfileFeature, 'id', 'name', {
  ids: [],
  entities: {},
  userProfiles: [],
  renewableProfiles: [],
  submitting: false,
  downloading: false,
});
