import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'prosumer-item-link',
  templateUrl: './item-link.component.html',
  styleUrls: ['./item-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemLinkComponent {
  @Output() navigate = new EventEmitter();
  @Input() isDisabled = true;
  @Input() defaultLabel = '-';

  private _label: string;
  @Input() get label() {
    return this._label || this.defaultLabel;
  }
  set label(label) {
    this._label = label;
  }

  linkClick($event): void {
    if (this.label !== this.defaultLabel) {
      this.navigate.emit($event);
    }
  }
}
