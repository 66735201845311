import { DialogService } from 'prosumer-app/libs/eyes-core';
import { ActionInputComponent } from 'prosumer-app/libs/eyes-shared/components/action-input';
import { YearlyValues } from 'prosumer-app/shared/models/yearly-values.model';
import { YearlyLoadsValidator } from 'prosumer-app/shared/validators/yearly-loads';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { _isNumberValue, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { convertToYearlyValues } from '../../utils';
import { YearlyChartDialogComponent } from './yearly-chart-dialog';

@Component({
  selector: 'prosumer-yearly-chart-input',
  templateUrl: './yearly-chart-input.component.html',
  styleUrls: ['./yearly-chart-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyChartInputComponent
  extends ActionInputComponent
  implements AfterViewInit
{
  @Input() contextHelpMsg: string;
  @Input() errorMessage: string;
  @Input() startYear: string;
  @Input() endYear: string;
  @Input() smallerChart = false;
  @Input() notRequired = false;
  @Input() isNullable = false;
  @Input() isViewOnly = false;
  @Input() processing = false;
  @Input() minValue = 0;

  iconPrefix = 'assessment';
  inputValueControl: UntypedFormControl = this.formBuilder.control({
    value: null,
    disabled: false,
  });
  hasMultipleValues = false;

  @Input() inputLabel: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private _dialogService: DialogService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  /**
   * Clears all the value for each year.
   */
  clearValues() {
    if (this.hasMultipleValues) {
      this.hasMultipleValues = false;
      this.inputValueControl.enable({ onlySelf: true, emitEvent: true });
      this.inputValueControl.patchValue('');
      this.inputValueControl.markAsTouched({ onlySelf: true });
      this.inputValueControl.markAsDirty({ onlySelf: true });
    }
  }

  /**
   * This should open dialog box and perform action on data.
   */
  performAction() {
    this._dialogService
      .openDialog(YearlyChartDialogComponent, {
        yearlyValues: this.control.value,
        startYear: this.startYear,
        endYear: this.endYear,
        width: 'auto',
        disableClose: true,
        isViewOnly: this.isViewOnly,
        minValue: this.minValue,
        notRequired: this.isNullable,
      })
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        this.control.patchValue(data);
        this.control.markAsDirty();
      });
  }

  ngAfterViewInit() {
    this.initValidatorsOfControl();
    this.subToInputChanges();
  }

  private subToInputChanges() {
    this.inputValueControl.valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilShare())
      .subscribe((v) => {
        this.inputValueControl.markAsTouched();
        this.onChange(this.setConstantYearlyValues(v, this.control.value));
      });
  }

  private initValidatorsOfControl() {
    this.addRequiredValidator();
    this.addMinValidator();
    this.control.updateValueAndValidity();
  }

  private addRequiredValidator(): void {
    if ([!this.notRequired, !this.isNullable].every(Boolean)) {
      this.control.addValidators(Validators.required);
    }
  }
  private addMinValidator(): void {
    if (this.minValue !== undefined) {
      this.control.addValidators(
        YearlyLoadsValidator.yearlyValuesMin(this.minValue),
      );
    }
  }

  // private getValidatorKeys(): string[] {
  //   const validators = {
  //     required: !this.notRequired,
  //     min: this.minValue !== undefined,
  //   };
  //   return Object.entries(validators)
  //     .filter(([, canInclude]) => !canInclude)
  //     .map(([key]) => key);
  // }

  // private getValidators(keys: string[]): ValidatorFn[] | null {
  //   if (keys.length === 0) {
  //     return null;
  //   }
  //   const validators = {
  //     required: Validators.required,
  //     min: YearlyLoadsValidator.yearlyValuesMin(this.minValue),
  //   };
  //   return keys.map((key) => validators[key]);
  // }

  /**
   * This method sets the yearly values with the given new value.
   * Replaces the old value with the new value as long as the newYearlyVal param is valid.
   * Otherwise, returns null.
   *
   * @param newYearlyVal new yearly value from the input text field.
   * @param yearlyValDict old yearly-value map.
   * @return YearlValues with new value, null if the newYearlyVal is invalid.
   */
  setConstantYearlyValues(newYearlyVal: unknown, yearlyValDict): YearlyValues {
    if (!newYearlyVal) return null;
    let newYearlyValStr = String(newYearlyVal);
    if (
      newYearlyValStr !== 'null' &&
      newYearlyValStr !== 'undefined' &&
      newYearlyValStr.trim().length !== 0 &&
      (newYearlyValStr.length > 0 ? _isNumberValue(newYearlyValStr) : true)
    ) {
      let newYearlyValues: YearlyValues = {};
      newYearlyValStr = newYearlyValStr.trim();
      if (!!yearlyValDict && Object.keys(yearlyValDict).length >= 1) {
        const years: Array<string> = Object.keys(yearlyValDict);
        years.forEach((year) => {
          newYearlyValues[year] = newYearlyValStr;
        });
      } else {
        const stYear = coerceNumberProperty(this.startYear);
        const eYear = coerceNumberProperty(this.endYear);
        newYearlyValues = convertToYearlyValues(newYearlyValStr, stYear, eYear);
      }
      return newYearlyValues;
    }
    return null;
  }

  writeValue(value: YearlyValues) {
    this.determineInputValue(value);
    this.value = value;
    this.markForCheck();
    this.stateChanges.next();
  }

  /**
   * Sets the value of the inputValueControl and set the hasMultipleValues if needed
   *
   * @param value the yearly values from the control.
   */
  determineInputValue(value: YearlyValues) {
    if (!!value) {
      const valueSet = new Set(Object.values(value));
      if (valueSet.size > 1) {
        this.hasMultipleValues = true;
        this.inputValueControl.disable({ onlySelf: true, emitEvent: false });
        this.inputValueControl.patchValue(undefined, { emitEvent: false });
      } else {
        this.hasMultipleValues = false;
        const valueArr = Array.from(valueSet.values());
        this.inputValueControl.patchValue(
          !!valueArr && valueArr.length === 1 ? valueArr[0] : '',
          { emitEvent: false },
        );
        if (valueArr.length === 0) {
          this.onChange(this.setConstantYearlyValues(null, {}));
        }
        this.inputValueControl.enable({ onlySelf: true, emitEvent: false });
        this.inputValueControl.markAsTouched({ onlySelf: true });
        this.inputValueControl.markAsDirty({ onlySelf: true });
      }
    }
  }
}
