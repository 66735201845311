import { AllActions, Paging } from 'prosumer-app/libs/eyes-shared';
import { CoherenceResult, DownloadEntity, Scenario } from 'prosumer-scenario';

import { Dictionary } from '@ngrx/entity';
/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { BinaryData, ScenarioVariationMap } from '../models';

export enum ActionTypes {
  COPY_SCENARIO = '[Scenario List Page] Copy Scenario',
  COPY_SCENARIO_SUCCESS = '[Scenario API] Copy Scenario Success',
  COPY_SCENARIO_FAILURE = '[Scenario API] Copy Scenario Failure',
  REMOVE_SCENARIO = '[Scenario State] Remove Scenario',
  DOWNLOAD = '[Scenario List Page] Download File',
  DOWNLOAD_SUCCESS = '[Scenario API] Download Success',
  DOWNLOAD_FAILURE = '[Scenario API] Download Failure',
  DOWNLOAD_DRAFT_INPUT = '[Scenario API] Download Generated Input File of a Scenario',
  DOWNLOAD_DRAFT_INPUT_SUCCESS = '[Scenario API] Download Generated Input File of a Scenario Sucess',
  DOWNLOAD_DRAFT_INPUT_FAILURE = '[Scenario API] Download Generated Input File of a Scenario Failure',
  GET_DRAFT_INPUT = '[Scenario List Page] Get Input File of Draft Scenario',
  GET_DRAFT_INPUT_SUCCESS = '[Scenario API] Get Input File of Draft Scenario Success',
  GET_DRAFT_INPUT_FAILURE = '[Scenario API] Get input File of Draft Scenario Failure',
  UPLOAD = '[Scenario Upload Page] Upload Scenario File',
  UPLOAD_SUCCESS = '[Scenario API] Upload Success',
  UPLOAD_FAILURE = '[Scenario API] Upload Failure',
  LAUNCH = '[Scenario Wizard Simulation Page] Launch Simulation',
  LAUNCH_SUCCESS = '[Scenario API] Launch Success',
  LAUNCH_FAILURE = '[Scenario API] Launch Failure',
  VALIDATE_RESET = '[Scenario Wizard] Reset Scenario Coherence',
  VALIDATE = '[Scenario Wizard Summary Page] Validate Scenario Coherence',
  VALIDATE_SUCCESS = '[Scenario API] Validate Scenario Coherence Success',
  VALIDATE_FAILURE = '[Scenario API] Validate Scenario Coherence Failure',
  GET_CASE_DETAIL_SCENARIO_LIST = '[Dashboard Page] Get case detail scenario list',
  GET_CASE_DETAIL_SCENARIO_LIST_SUCCESS = '[Scenario API] Get case detail scenario list success',
  GET_CASE_DETAIL_SCENARIO_LIST_FAILURE = '[Scenario API] Get case detail scenario list failure',
  GET_LATEST_SIMULATIONS = '[Dashboard Page] Get latest simulations list',
  GET_LATEST_SIMULATIONS_SUCCESS = '[Scenario API] Get latest simulations list success',
  GET_LATEST_SIMULATIONS_FAILURE = '[Scenario API] Get latest simulations list failure',
  UPDATE_WITH_BINARY_HANDLING = '[Scenario Wizard Page] Update with Binary Data handling',
  UPDATE_WITH_BINARY_HANDLING_SUCCESS = '[Scenario Wizard Page] Update with Binary Data handling Success',
  UPDATE_WITH_BINARY_HANDLING_FAILURE = '[Scenario Wizard Page] Update with Binary Data handling Failure',
  UPLOAD_CLONE = '[Scenario Upload Page] Upload Clone Scenario File',
  UPLOAD_CLONE_SUCCESS = '[Scenario API] Upload Clone Success',
  UPLOAD_CLONE_FAILURE = '[Scenario API] Upload Clone Failure',
  GET_SCENARIO_VARIATION = '[Scenario List Page] Get a Scenario Variation of a Scenario',
  GET_SCENARIO_VARIATIONS = '[Scenario List Page] Get Scenario Variations of a Scenario',
  GET_SCENARIO_VARIATIONS_SUCCESS = '[Scenario API] Get Scenario Variations Success',
  GET_SCENARIO_VARIATIONS_FAILURE = '[Scenario API] Get Scenario Variations Failure',
  GET_SCENARIOS_AND_VARIATIONS = '[Scenario List Page] Get Scenario and Variations',
  GET_SCENARIOS_AND_VARIATIONS_SUCCESS = '[Scenario API] Get Scenario and Variations Success',
  GET_SCENARIOS_AND_VARIATIONS_FAILURE = '[Scenario API] Get Scenario and Variations Failure',
  SELECT_SCENARIO_VARIATION_ID = '[Scenario Variation ID] Select Scenario Variation ID',
  UPDATE_SCENARIO_FROM_CASE_VIEW = '[Scenario List Page] Update Scenario from Case View',
  UPDATE_SCENARIO_FROM_CASE_VIEW_SUCCESS = '[Scenario List Page] Update Scenario from Case View Success',
  UPDATE_SCENARIO_FROM_CASE_VIEW_FAILURE = '[Scenario List Page] Update Scenario from Case View Failure',
}

// Copy
export class CopyScenario implements Action {
  readonly type = ActionTypes.COPY_SCENARIO;
  constructor(
    public payload?: {
      data?: Scenario;
      name?: string;
      targetProjectId?: string;
      targetCaseId?: string;
    },
  ) {}
}

export class CopyScenarioSuccess implements Action {
  readonly type = ActionTypes.COPY_SCENARIO_SUCCESS;
  constructor(
    public payload?: {
      data: Scenario;
      name?: string;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class CopyScenarioFailure implements Action {
  readonly type = ActionTypes.COPY_SCENARIO_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      name?: string;
      targetProjectId?: string;
      targetCaseId?: string;
      message: string;
      notify: boolean;
    },
  ) {}
}

// Remove
export class RemoveScenario implements Action {
  readonly type = ActionTypes.REMOVE_SCENARIO;
  constructor(public payload?: { id: string }) {}
}

// Download
export class Download implements Action {
  readonly type = ActionTypes.DOWNLOAD;
  constructor(
    public payload?: {
      data: Scenario;
      downloadEntity: DownloadEntity;
      endpoint: string;
    },
  ) {}
}

export class DownloadSuccess implements Action {
  readonly type = ActionTypes.DOWNLOAD_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class DownloadFailure implements Action {
  readonly type = ActionTypes.DOWNLOAD_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      endpoint?: string;
      downloadEntity: DownloadEntity;
      message: string;
      notify: boolean;
    },
  ) {}
}

export class DownloadDraftInput implements Action {
  readonly type = ActionTypes.DOWNLOAD_DRAFT_INPUT;
  constructor(
    public payload?: {
      data: Scenario;
      downloadEntity: DownloadEntity;
    },
  ) {}
}

export class DownloadDraftInputSuccess implements Action {
  readonly type = ActionTypes.DOWNLOAD_DRAFT_INPUT_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class DownloadDraftInputFailure implements Action {
  readonly type = ActionTypes.DOWNLOAD_DRAFT_INPUT_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      message: string;
      notify: boolean;
      downloadEntity: DownloadEntity;
    },
  ) {}
}

export class GetDraftInput implements Action {
  readonly type = ActionTypes.GET_DRAFT_INPUT;
  constructor(
    public payload?: {
      data: Scenario;
      downloadEntity: DownloadEntity;
    },
  ) {}
}

export class GetDraftInputSuccess implements Action {
  readonly type = ActionTypes.GET_DRAFT_INPUT_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class GetDraftInputFailure implements Action {
  readonly type = ActionTypes.GET_DRAFT_INPUT_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      message: string;
      notify: boolean;
      downloadEntity: DownloadEntity;
    },
  ) {}
}

// Upload
export class Upload implements Action {
  readonly type = ActionTypes.UPLOAD;
  constructor(
    public payload?: { data: Scenario; file: File; gamsFiles: File[] },
  ) {}
}

export class UploadSuccess implements Action {
  readonly type = ActionTypes.UPLOAD_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class UploadFailure implements Action {
  readonly type = ActionTypes.UPLOAD_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      file?: File;
      message: any;
      notify: boolean;
    },
  ) {}
}

// Upload Clone
export class UploadClone implements Action {
  readonly type = ActionTypes.UPLOAD_CLONE;
  constructor(public payload?: { data: Scenario; file: File }) {}
}

export class UploadCloneSuccess implements Action {
  readonly type = ActionTypes.UPLOAD_CLONE_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class UploadCloneFailure implements Action {
  readonly type = ActionTypes.UPLOAD_CLONE_FAILURE;
  constructor(
    public payload?: {
      data: Scenario;
      file?: File;
      message: any;
      notify: boolean;
    },
  ) {}
}

// Launch
export class Launch implements Action {
  readonly type = ActionTypes.LAUNCH;
  constructor(public payload?: { data: Scenario }) {}
}

export class LaunchSuccess implements Action {
  readonly type = ActionTypes.LAUNCH_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

export class LaunchFailure implements Action {
  readonly type = ActionTypes.LAUNCH_FAILURE;
  constructor(
    public payload?: { data: Scenario; message: string; notify: boolean },
  ) {}
}

// Validate coherence
export class ValidateReset implements Action {
  readonly type = ActionTypes.VALIDATE_RESET;
  constructor(public payload?: { id: string }) {}
}

export class Validate implements Action {
  readonly type = ActionTypes.VALIDATE;
  constructor(public payload?: { data: Scenario }) {}
}

export class ValidateSuccess implements Action {
  readonly type = ActionTypes.VALIDATE_SUCCESS;
  constructor(public payload?: { data: Array<CoherenceResult> }) {}
}

export class ValidateFailure implements Action {
  readonly type = ActionTypes.VALIDATE_FAILURE;
  constructor(public payload?: { data: any; error: string }) {}
}

// Get scenario list
export class GetCaseDetailScenarioList implements Action {
  readonly type = ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST;
  constructor(
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
    },
  ) {}
}

export class GetCaseDetailScenarioListSuccess implements Action {
  readonly type = ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_SUCCESS;
  constructor(
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
      message?: string;
      notify?: boolean;
      results?: Array<Scenario>;
    },
  ) {}
}

export class GetCaseDetailScenarioListFailure implements Action {
  readonly type = ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_FAILURE;
  constructor(
    public payload?: {
      id?: string;
      params?: Dictionary<string>;
      paging?: Paging;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Get latest simulations
export class GetLatestSimulations implements Action {
  readonly type = ActionTypes.GET_LATEST_SIMULATIONS;
  constructor(public payload?: { data: any }) {}
}

export class GetLatestSimulationsSuccess implements Action {
  readonly type = ActionTypes.GET_LATEST_SIMULATIONS_SUCCESS;
  constructor(
    public payload?: { data: Array<string>; message: string; notify: boolean },
  ) {}
}

export class GetLatestSimulationsFailure implements Action {
  readonly type = ActionTypes.GET_LATEST_SIMULATIONS_FAILURE;
  constructor(
    public payload?: { data: any; message: string; notify: boolean },
  ) {}
}

export class UpdateWithBinaryHandling implements Action {
  readonly type = ActionTypes.UPDATE_WITH_BINARY_HANDLING;
  constructor(public payload?: { data: Scenario }) {}
}

export class UpdateWithBinaryHandlingSuccess implements Action {
  readonly type = ActionTypes.UPDATE_WITH_BINARY_HANDLING_SUCCESS;
  constructor(
    public payload?: {
      data: Scenario;
      binaryDataToDelete?: Array<BinaryData>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class UpdateWithBinaryHandlingFailure implements Action {
  readonly type = ActionTypes.UPDATE_WITH_BINARY_HANDLING_FAILURE;
  constructor(
    public payload?: { data: Scenario; message?: string; notify?: boolean },
  ) {}
}

/* // fetch single variation - for future implementation
export class GetScenarioVariation implements Action {
  readonly type = ActionTypes.GET_SCENARIO_VARIATION;
  constructor(public payload?: { id?: string; params?: Dictionary<string | string[]> }) {}
} */

export class GetScenarioVariations implements Action {
  readonly type = ActionTypes.GET_SCENARIO_VARIATIONS;
  constructor(
    public payload?: { id?: string; params?: Dictionary<string | string[]> },
  ) {}
}

export class GetScenarioVariationsSuccess implements Action {
  readonly type = ActionTypes.GET_SCENARIO_VARIATIONS_SUCCESS;
  constructor(
    public payload?: {
      id?: string;
      datalist?: Array<ScenarioVariationMap>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetScenarioVariationsFailure implements Action {
  readonly type = ActionTypes.GET_SCENARIO_VARIATIONS_FAILURE;
  constructor(
    public payload?: { id?: string; message?: string; notify?: boolean },
  ) {}
}

export class GetScenariosAndVariations implements Action {
  readonly type = ActionTypes.GET_SCENARIOS_AND_VARIATIONS;
  constructor(public payload?: { params?: Dictionary<string | string[]> }) {}
}

export class GetScenariosAndVariationsSuccess implements Action {
  readonly type = ActionTypes.GET_SCENARIOS_AND_VARIATIONS_SUCCESS;
  constructor(
    public payload?: {
      datalist?: Array<Scenario | ScenarioVariationMap>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetScenariosAndVariationsFailure implements Action {
  readonly type = ActionTypes.GET_SCENARIOS_AND_VARIATIONS_FAILURE;
  constructor(public payload?: { message?: string; notify?: boolean }) {}
}

export class SelectScenarioVariationId implements Action {
  readonly type = ActionTypes.SELECT_SCENARIO_VARIATION_ID;
  constructor(public payload?: { id: string }) {}
}

export class UpdateScenarioFromCaseView implements Action {
  readonly type = ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW;
  constructor(public payload?: { data: Scenario }) {}
}

export class UpdateScenarioFromCaseViewFailure implements Action {
  readonly type = ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_FAILURE;
  constructor(
    public payload?: { data: Scenario; message?: string; notify?: boolean },
  ) {}
}

export class UpdateScenarioFromCaseViewSuccess implements Action {
  readonly type = ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_SUCCESS;
  constructor(
    public payload?: { data: Scenario; message?: string; notify?: boolean },
  ) {}
}

// Actions Union
export type All =
  | AllActions<Scenario>
  | CopyScenario
  | CopyScenarioSuccess
  | CopyScenarioFailure
  | RemoveScenario
  | Download
  | DownloadSuccess
  | DownloadFailure
  | DownloadDraftInput
  | DownloadDraftInputSuccess
  | DownloadDraftInputFailure
  | GetDraftInput
  | GetDraftInputSuccess
  | GetDraftInputFailure
  | Upload
  | UploadSuccess
  | UploadFailure
  | UploadClone
  | UploadCloneSuccess
  | UploadCloneFailure
  | Launch
  | LaunchSuccess
  | LaunchFailure
  | ValidateReset
  | Validate
  | ValidateSuccess
  | ValidateFailure
  | GetCaseDetailScenarioList
  | GetCaseDetailScenarioListSuccess
  | GetCaseDetailScenarioListFailure
  | GetLatestSimulations
  | GetLatestSimulationsSuccess
  | GetLatestSimulationsFailure
  | UpdateWithBinaryHandling
  | UpdateWithBinaryHandlingSuccess
  | UpdateWithBinaryHandlingFailure
  | GetScenarioVariations
  | GetScenarioVariationsSuccess
  | GetScenarioVariationsFailure
  | GetScenariosAndVariations
  | GetScenariosAndVariationsSuccess
  | GetScenariosAndVariationsFailure
  | SelectScenarioVariationId
  | UpdateScenarioFromCaseView
  | UpdateScenarioFromCaseViewFailure
  | UpdateScenarioFromCaseViewSuccess;
