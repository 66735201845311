import { EdgeDefinition, NodeDefinition, Stylesheet } from 'cytoscape';

import { NODE_VIEW_JSON_PROPS } from '../mappers';
import { Connector, Node } from '../models';

export class VizDiagramUtils {
  static nodeToDiagramNode(asset: Node): NodeDefinition {
    return {
      data: {
        ...asset,
        name: this.createNodeName(asset),
      },
    };
  }

  private static createNodeName(asset: Node): string {
    let nodeName = asset.name;

    if (!asset.hidden && asset.showInfo) {
      const sizeKwKey = NODE_VIEW_JSON_PROPS.asset.sizeKw;
      const sizeKwhKey = NODE_VIEW_JSON_PROPS.asset.sizeKwh;

      if (asset.rawData[sizeKwKey] && !asset.rawData[sizeKwhKey]) {
        nodeName = `${nodeName}\n\n${asset.rawData[sizeKwKey] + 'kW'}`;
      } else if (asset.rawData[sizeKwKey] && asset.rawData[sizeKwhKey]) {
        nodeName = `${nodeName}\n\n${
          asset.rawData[sizeKwKey] + 'kW/ ' + asset.rawData[sizeKwhKey] + 'kWh'
        }`;
      }
    }

    return nodeName;
  }

  static connectorToDiagramEdge(
    line: Connector,
    color: string,
  ): EdgeDefinition {
    return {
      data: {
        id: line.id,
        name: line.name,
        source: line.sourceId,
        target: line.targetId,
        rawData: line.rawData,
        hidden: line.hidden,
        color,
      },
    };
  }

  static getNodeStylesheet(): Stylesheet {
    return {
      selector: 'node',
      css: {
        label: 'data(name)',
        color: '#43425D',
        height: 100,
        width: 100,
        'background-color': '#eee',
        'text-halign': 'center',
        'text-valign': 'center',
        'text-wrap': 'wrap',
        'text-overflow-wrap': 'anywhere',
        'text-max-width': '80px',
        'font-size': '9px',
        'font-weight': 'bold',
        'border-style': 'solid',
        'border-width': 5,
        'border-color': '#43425D',
        'active-bg-opacity': 0.4,
        'z-index': 10,
      },
    };
  }

  static getSelectedNodeStylesheet(): Stylesheet {
    return {
      selector: 'node:selected',
      css: {
        color: '#43425D',
        'z-index': 50,
      },
    };
  }

  static getEdgeStylesheet(): Stylesheet {
    return {
      selector: 'edge',
      css: {
        width: 2,
        'overlay-opacity': 0.2,
        'overlay-padding': 3,
        'line-cap': 'round',
        'line-style': 'dashed',
        'line-fill': 'solid',
        'curve-style': 'taxi',
        'line-dash-pattern': [10, 8],
        'line-dash-offset': 10,
        'target-arrow-shape': 'triangle-backcurve',
        'arrow-scale': 1.5,
        'line-color': 'data(color)',
        'overlay-color': 'data(color)',
        'target-arrow-color': 'data(color)',
        'z-index': 10,
      },
    };
  }

  static getSelectedEdgeStylesheet(): Stylesheet {
    return {
      selector: 'edge:selected',
      css: {
        'overlay-opacity': 0.6,
        'z-index': 50,
      },
    };
  }

  static getFadedNodeStylesheet(): Stylesheet {
    return {
      selector: '.faded',
      css: {
        color: '#ccc',
        'border-color': '#ccc',
        'z-index': 0,
      },
    };
  }

  static getFadedEdgeStylesheet(): Stylesheet {
    return {
      selector: 'edge.faded',
      css: {
        'overlay-opacity': 0.05,
        opacity: 0.05,
        'z-index': 0,
      },
    };
  }

  static getHiddenNodeStyle(): Stylesheet {
    return {
      selector: 'node[?hidden]',
      css: {
        'overlay-opacity': 0,
        opacity: 0,
        visibility: 'hidden',
      },
    };
  }

  static getHiddenEdgeStyle(): Stylesheet {
    return {
      selector: 'edge[?hidden]',
      css: {
        'overlay-opacity': 0,
        opacity: 0,
        visibility: 'hidden',
      },
    };
  }
}
