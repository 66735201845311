import { CompletionStrategy } from './completion-strategy';
import { LoadsForm } from '../../models';
import { Utils } from 'prosumer-app/core';

export class LoadsCompletion extends CompletionStrategy<LoadsForm> {
  isFull(form: LoadsForm): boolean {
    return this.getFields(form).every(Boolean);
  }

  isHalf(form: LoadsForm): boolean {
    return this.getFields(form).some(Boolean);
  }

  getFields(form: LoadsForm): boolean[] {
    return [this.hasLoads(form)];
  }

  private hasLoads(form: LoadsForm): boolean {
    return [Utils.resolveToEmptyArray(form.loads).length].some(Boolean);
  }
}
