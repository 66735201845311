<div class="mat-card" fxLayout="column" fxLayoutGap="10px">
  <mat-progress-bar
    *ngIf="showProgress"
    [color]="color"
    [mode]="mode"
    [bufferValue]="bufferValue"
    [value]="value"
  >
  </mat-progress-bar>
  <ng-content></ng-content>
</div>
