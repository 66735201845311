import Chart from 'chart.js/auto';

import { Directive, HostListener, Input } from '@angular/core';

import { ChartExporterService } from './chart-exporter.service';
import { ChartExportType } from './export-chart.model';
import { BookType } from 'xlsx/types';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[exportChart]',
  providers: [ChartExporterService],
})
export class ExportChartDirective {
  @Input() showLegend = false;
  @Input() exportChart: Chart;
  @Input() allData: any;
  @Input() format: ChartExportType = 'png';
  @Input() name = 'export_chart';

  @HostListener('click') onMouseClick(): void {
    this.showLegend = JSON.parse(`${this.showLegend}`);
    switch (this.format) {
      case 'png':
        this.service.exportAsPng(this.exportChart, this.name, this.showLegend);
        break;
      case 'xlsx':
        this.exportWorkbook();
        break;
      default:
        this.service.exportAsSvg(this.exportChart, this.name, this.showLegend);
    }
  }

  private exportWorkbook() {
    const data = !!this.allData
      ? this.formatAllData()
      : this.formatWorkbookData();
    this.service.exportAsWorkbook(data, this.name, this.format as BookType);
  }

  private formatWorkbookData(): string[][] {
    const data = [];

    if (!!this.exportChart) {
      data.push(['', ...this.exportChart.config._config.data.labels]);
      const datasets = this.exportChart.getSortedVisibleDatasetMetas();
      datasets.forEach((dataset) => {
        const datasetValues = dataset.data.map(
          (element) => element.$context.raw ?? 0,
        );
        data.push([dataset.label, ...datasetValues]);
      });
    }

    return data;
  }

  private formatAllData(): string[][] {
    let data = [];

    if (this.allData.dispatchData && this.allData.dispatchData.length > 0) {
      data = this.formatDispatchData();
    }

    return data;
  }

  private formatDispatchData(): string[][] {
    const data = [this.getDispatchLabelsRow()];
    const datasets = this.exportChart.getSortedVisibleDatasetMetas();

    datasets.forEach((dataset) => {
      const datasetValues = dataset.data.map(
        (element) => element.$context.raw ?? 0,
      );
      const dispatchData = this.allData.dispatchData.find(
        ({ der }) => der === dataset.label,
      );
      data.push([
        dispatchData?.der,
        dispatchData?.energyVector,
        dispatchData?.node,
        dispatchData?.type,
        dispatchData?.year,
        ...datasetValues,
      ]);
    });

    return data;
  }

  private getDispatchLabelsRow(): string[] {
    return [
      'Asset',
      'Fluid',
      'Node',
      'Variable',
      'Year',
      ...this.exportChart.config._config.data.labels,
    ];
  }

  constructor(private service: ChartExporterService) {}
}
