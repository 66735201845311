<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
    [formGroup]="computationOptionForm"
  >
    <prosumer-select
      [placeholder]="
        'RenewableProfile.placeholders.computationOption' | translate
      "
      [label]="'RenewableProfile.labels.computationOption' | translate"
      [options]="computationOptions"
      [control]="computationOptionForm?.controls?.type"
      [required]="true"
      [tooltip]="'renewable_profile.solar_computation_option'"
      fxFlex
      [attr.data-testid]="'computation-option-select'"
    >
    </prosumer-select>

    <div *ngIf="(type$ | async) === 'Historical'; else notHistorical">
      <!-- List of Years -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="10px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="5px"
          [formGroup]="computationOptionForm"
          fxFlex="40%"
        >
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>
              {{ 'RenewableProfile.labels.year' | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="year"
              [placeholder]="'RenewableProfile.placeholders.year' | translate"
              [attr.data-testid]="'historical-year-input'"
            />
            <mat-error
              *ngFor="let error of getErrors(year?.errors)"
              [innerHtml]="yearErrorsMessages[error]"
            >
            </mat-error>
          </mat-form-field>
          <div class="custom-div-button" fxFlex="5%">
            <button
              type="button"
              mat-icon-button
              color="primary"
              (click)="onAddYear()"
              [disabled]="
                year?.value === '' || getErrors(year?.errors)?.length > 0
              "
              [attr.data-testid]="'historical-year-add-button'"
            >
              <mat-icon aria-hidden="false" matPrefix>add_circle</mat-icon>
            </button>
          </div>
        </div>

        <div
          fxFlex="50%"
          *ngIf="(yearsList$ | async)?.length > 0"
          data-testid="historical-year-list"
        >
          <mat-label [attr.data-testid]="'historical-year-list-label'">
            {{ 'RenewableProfile.labels.listOfYears' | translate }}
          </mat-label>

          <prosumer-sparkline-table
            [columnsDef]="yearColumnDef"
            [data]="yearsList$ | async"
            [hideAdd]="true"
            [hideEdit]="true"
            (delete)="onDeleteYear($event)"
          >
          </prosumer-sparkline-table>
        </div>
      </div>
    </div>

    <ng-template #notHistorical>
      <div
        *ngIf="(type$ | async) === 'TMY'; else notTMY"
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
      >
        <prosumer-input
          fxFlex
          type="number"
          [placeholder]="'RenewableProfile.labels.numberOfYears' | translate"
          [label]="'RenewableProfile.labels.numberOfYears' | translate"
          [control]="computationOptionForm?.controls?.numberOfYears"
          [inputTooltip]="
            'renewable_profile.solar_computation_option_number_of_years'
          "
          [errorMessageMap]="errorMessages['numberOfYears']"
          [attr.data-testid]="'computation-number-of-years'"
        >
        </prosumer-input>

        <prosumer-input
          fxFlex
          type="number"
          [placeholder]="
            'RenewableProfile.labels.lastHistoricalYear' | translate
          "
          [label]="'RenewableProfile.labels.lastHistoricalYear' | translate"
          [control]="computationOptionForm?.controls?.lastHistoricalYear"
          [inputTooltip]="'renewable_profile.solar_last_historical_year'"
          [errorMessageMap]="errorMessages['lastHistoricalYear']"
          [attr.data-testid]="'computation-last-historical-year'"
        >
        </prosumer-input>
      </div>
      <ng-template #notTMY>
        <!-- List of Percentiles -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="10px"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="5px"
            [formGroup]="computationOptionForm"
            fxFlex="40%"
          >
            <mat-form-field class="eyes-maximize-width" appearance="outline">
              <mat-label>
                {{ 'RenewableProfile.labels.percentile' | translate }}
              </mat-label>
              <input
                matInput
                [formControl]="percentile"
                [placeholder]="
                  'RenewableProfile.placeholders.percentile' | translate
                "
                [attr.data-testid]="'percentile-input'"
              />
              <mat-error
                *ngFor="let error of getErrors(percentile?.errors)"
                [innerHtml]="percentileErrorsMessages[error]"
              >
              </mat-error>
            </mat-form-field>
            <div class="custom-div-button" fxFlex="5%">
              <button
                type="button"
                mat-icon-button
                color="primary"
                (click)="onAddPercentile()"
                [disabled]="
                  percentile?.value === '' ||
                  getErrors(percentile?.errors)?.length > 0
                "
                [attr.data-testid]="'percentile-add-button'"
              >
                <mat-icon aria-hidden="false" matPrefix>add_circle</mat-icon>
              </button>
            </div>
          </div>

          <div
            fxFlex="50%"
            *ngIf="(percentilesList$ | async)?.length > 0"
            data-testid="percentile-list"
          >
            <mat-label data-testid="percentile-list-label">
              {{ 'RenewableProfile.labels.listOfPercentiles' | translate }}
            </mat-label>

            <prosumer-sparkline-table
              [columnsDef]="percentileColumnDef"
              [data]="percentilesList$ | async"
              [hideAdd]="true"
              [hideEdit]="true"
              (delete)="onDeletePercentile($event)"
            >
            </prosumer-sparkline-table>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
