import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export const DEFAULT_LANG = 'en';

@Injectable()
export class I18NService {
  constructor(private _translate: TranslateService) {}

  initTranslation(): void {
    this._translate.setDefaultLang(DEFAULT_LANG);
    this._translate.use(DEFAULT_LANG);
  }

  getTranslation(): Observable<any> {
    return this._translate.getTranslation(DEFAULT_LANG);
  }

  translate(path: string): any {
    return this._translate.instant(path);
  }
}
