import { StateFactory } from 'prosumer-app/libs/eyes-shared';

import { Project } from '../models';
import { ProjectState } from './project-state.model';

export const projectFeature = 'Project';
export const projectStateFactory = new StateFactory<ProjectState, Project>(
  projectFeature,
  'id',
  'name',
  {
    ids: [],
    entities: {},
    sample: 'Test',
  },
);
