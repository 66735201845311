<div [formGroup]="dateForm">
  <input
    #month
    class="month"
    formControlName="month"
    size="2"
    maxLength="2"
    mask="00"
  />
  <span>/</span>
  <input
    #day
    class="day"
    formControlName="day"
    size="2"
    maxLength="2"
    mask="00"
    tabindex="-1"
  />
  <span>/</span>
  <input
    #year
    class="year"
    formControlName="year"
    size="4"
    maxLength="4"
    mask="0000"
    tabindex="-1"
  />
</div>
