import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { sortByDate } from 'prosumer-app/libs/eyes-shared';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  NotificationStoreAddAction,
  NotificationStoreClearAction,
  NotificationStoreClearListAction,
  NotificationStoreFetchListAction,
  NotificationStoreReadAction,
  NotificationStoreRemoveAction,
  NotificationStoreSetListAction,
  NotificationStoreTagAction,
} from './notification-store.actions';
import {
  Notification,
  NotificationStoreState,
} from './notification-store.model';
import { notificationStoreSelectors } from './notification-store.reducer';

@Injectable()
export class NotificationStoreService {
  state$: Observable<NotificationStoreState> = this._store$.pipe(
    select(notificationStoreSelectors.state),
  );

  loading$: Observable<boolean> = this._store$.pipe(
    select(notificationStoreSelectors.loading),
  );
  loaded$: Observable<boolean> = this._store$.pipe(
    select(notificationStoreSelectors.loaded),
  );
  notifications$: Observable<Array<Notification>> = this._store$.pipe(
    select(notificationStoreSelectors.notifications),
    mergeMap((data: Array<Notification>) =>
      of(data.sort((a, b) => sortByDate('dateTimeSent', a, b))),
    ),
  );
  error$: Observable<any> = this._store$.pipe(
    select(notificationStoreSelectors.error),
  );
  nonSilentNotifications$: Observable<any> = this._store$.pipe(
    select(notificationStoreSelectors.nonSilentNotifications),
    mergeMap((data: Array<Notification>) =>
      of(data.sort((a, b) => sortByDate('dateTimeSent', a, b))),
    ),
  );

  constructor(private _store$: Store<NotificationStoreState>) {}

  addNotification(notification: any): Observable<Array<Notification>> {
    this._store$.dispatch(new NotificationStoreAddAction(notification));
    return this.notifications$;
  }

  clearStore(): Observable<NotificationStoreState> {
    this._store$.dispatch(new NotificationStoreClearAction());
    return this.state$;
  }

  clearNotifications(): Observable<Array<Notification>> {
    this._store$.dispatch(new NotificationStoreClearListAction());
    return this.notifications$;
  }

  fetchNotifications(): Observable<Array<Notification>> {
    this._store$.dispatch(new NotificationStoreFetchListAction());
    return this.notifications$;
  }

  removeNotification(
    notification: Notification,
  ): Observable<Array<Notification>> {
    this._store$.dispatch(new NotificationStoreRemoveAction(notification));
    return this.notifications$;
  }

  setNotifications(
    notifications: Array<Notification>,
  ): Observable<Array<Notification>> {
    this._store$.dispatch(new NotificationStoreSetListAction(notifications));
    return this.notifications$;
  }

  tagNotification(notification: Notification): Observable<Array<Notification>> {
    this._store$.dispatch(
      new NotificationStoreTagAction(notification.id, notification),
    );
    return this.notifications$;
  }

  readNotifications(notificationUuids: Array<string>) {
    this._store$.dispatch(new NotificationStoreReadAction(notificationUuids));
    return this.notifications$;
  }
}
