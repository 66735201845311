<div
  *ngIf="isBtnDisabled && !!disabledAddBtnMessage"
  class="container-tooltip-helper"
  data-testid="tooltip-disabled-button-container"
>
  <div
    class="overlay-tooltip-helper"
    [prosumerTooltip]="disabledAddBtnMessage"
  ></div>
  <prosumer-tooltip-anchor></prosumer-tooltip-anchor>
  <button mat-button type="button" color="primary" [disabled]="true">
    <mat-icon aria-hidden="false" matPrefix>{{ matIconLabel }}</mat-icon
    ><b>{{ buttonText }}</b>
  </button>
</div>

<button
  *ngIf="!(isBtnDisabled && disabledAddBtnMessage)"
  mat-button
  type="button"
  color="primary"
  [disabled]="isBtnDisabled"
  (click)="onClick()"
  data-testid="non-tooltip-button"
>
  <mat-icon aria-hidden="false" matPrefix>{{ matIconLabel }}</mat-icon
  ><b>{{ buttonText }}</b>
</button>
