import {
  BaseFormComponent,
  collectItemsWithKey,
  contains,
  convertArrayItemsToLowerCase,
  FormFieldErrorMessageMap,
  FormFieldOption,
  getDeletedItems,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'prosumer-generic-filterchip-component',
  templateUrl: './generic-filterchip.component.html',
  styleUrls: ['./generic-filterchip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFilterchipComponent
  extends BaseFormComponent
  implements OnInit, AfterViewInit
{
  _genericOptions: Array<FormFieldOption<string>> = [];
  @Input() set genericOptions(genericOptions: Array<FormFieldOption<string>>) {
    // initialization
    this.selectedGenerics = [];
    if (this.enableSelectAll) {
      this._genericOptions = [...genericOptions, { name: 'All', value: 'ALL' }];
    } else {
      this._genericOptions = [...genericOptions];
    }
    this.genericSelection$.next(this._genericOptions);
  }
  get genericOptions(): Array<FormFieldOption<string>> {
    return this._genericOptions;
  }

  @Input() set setSubmitted(submitted: boolean) {
    // istanbul ignore next
    if (submitted) {
      this.submitted$.next(submitted);
      if (
        [this.required, !this.selectedGenerics.length].every((cond) => cond)
      ) {
        this.selectedGenerics$.next(undefined);
      }
    }
  }

  @Input() set setCustomErrorMessage(message: string) {
    this.customErrorMessage$.next(message);
  }

  @Input() errorMessage: FormFieldErrorMessageMap;
  @Input() tooltip: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() enableSelectAll: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  submitted$ = new BehaviorSubject<boolean>(false);
  customErrorMessage$ = new BehaviorSubject<string>('');
  inputGenericControl = new UntypedFormControl();
  genericSelection$ = new BehaviorSubject<FormFieldOption<string>[]>([]); // generic options list dropdown
  selectedGenerics = []; // filter chips list
  selectedGenerics$ = new BehaviorSubject<string[]>([]); // list of id's selected
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('genericInput') genericInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public ngControl: NgControl,
    public changeDetectorRef: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(ngControl, changeDetectorRef, formBuilder);
  }

  defineForm() {
    return {
      generics: [[], Validators.required],
    };
  }

  writeValue(value: any) {
    if (value) {
      this.controls.generics.setValue(value, { emitEvent: false });
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
    // on user input, handle autocomplete filter
    // handle 'string' only values, it sometimes catches obj format value when user 'ENTER's value from options
    this.inputGenericControl.valueChanges
      .pipe(
        debounceTime(300),
        filter((value) => typeof value === 'string'),
      )
      .subscribe((value) => this.filter(value));
  }

  ngAfterViewInit() {
    this.selectedGenerics$.pipe().subscribe((generics) => {
      // check if need to update selected generics
      // istanbul ignore else
      if (
        JSON.stringify(generics) !==
        JSON.stringify(this.controls.generics.value.generics)
      ) {
        this.controls.generics.patchValue([...new Set(generics)]);
      }
    });
  }

  init() {
    // on edit mode, add pre-loaded generics to selectedGenerics array
    if (this.controls.generics.value.generics) {
      const ids = collectItemsWithKey(this.genericOptions, 'value');
      this.controls.generics.value.generics.forEach((id) => {
        const i = ids.indexOf(id);
        this.toggleSelection(this.genericOptions[i]);
      });
    }
  }

  /**
   * Function to handle user input when looking for generics
   *
   * @param value - user input value
   */
  private filter(value: string) {
    const filterValue = value.toLowerCase();
    // simple finder of string from original list of generics
    const list = this.genericOptions
      .filter((generic: unknown) => !this.selectedGenerics.includes(generic))
      .filter(
        (generic: any) => generic.name.toLowerCase().indexOf(filterValue) === 0,
      );
    this.genericSelection$.next(list);
  }

  /**
   * When user clicks on generic options list
   *
   * @param event - mouse click from generic options list
   * @param option - value of option clicked
   */
  optionSelected(event: Event, option: FormFieldOption<string>) {
    this.toggleSelection(option);
  }

  /**
   * When user selects an option and hits ENTER key
   *
   * @param event - keyboard user event on autocomplete
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.toggleSelection(event.option.value);
    this.inputGenericControl.setValue(null);
  }

  /**
   * Handles all events and process the final data for the generic filterchip form
   *
   * @param generic - obj name value pair
   */
  toggleSelection(generic: FormFieldOption<string>) {
    // Do nothing if "All generics" is already selected
    if (
      this.enableSelectAll &&
      this.selectedGenerics &&
      this.selectedGenerics.length &&
      this.selectedGenerics[0].value === 'ALL'
    ) {
      this.genericSelection$.next([]);
    } else if (generic?.value === 'ALL') {
      this.selectedGenerics = [generic];
      this.genericSelection$.next([]);
    } else {
      this.selectedGenerics.push(generic);
      this.selectedGenerics = [...new Set(this.selectedGenerics)];
      const filter = getDeletedItems(
        this.genericOptions,
        this.selectedGenerics,
        'value',
      );
      this.genericSelection$.next(filter);
    }
    // avoid undefined read for genericInput
    if (this.genericInput) {
      this.genericInput.nativeElement.value = '';
    }
    this.collectGenericIds();
  }

  /**
   * When user keys a generic name and hits a match regardless of font case
   *
   * @param event - when user 'ENTER's an input value and did not select from the options
   */
  add(event: MatChipInputEvent): void {
    if (
      this.enableSelectAll &&
      !contains(this.selectedGenerics$.value, 'ALL')
    ) {
      const value = event.value;
      const genericNames = convertArrayItemsToLowerCase(
        collectItemsWithKey(this.genericOptions, 'name'),
      );
      if ((value || '').trim()) {
        if (contains(genericNames, value.toLowerCase())) {
          const i = genericNames.indexOf(value);
          this.toggleSelection(this.genericOptions[i]);
        }
      }
    } else {
      this.genericSelection$.next([]);
    }
    // clear input fields
    event.input.value = '';
    this.inputGenericControl.setValue(null);
  }

  /**
   * Handle all selected generics deletion
   *
   * @param generic - to be deleted value
   */
  remove(generic: FormFieldOption<string>) {
    if (this.enableSelectAll && generic.value === 'ALL') {
      this.genericSelection$.next(this.genericOptions);
      this.selectedGenerics = [];
    } else {
      this.selectedGenerics = this.selectedGenerics.filter(
        (userData) => userData !== generic,
      );
      this.genericSelection$.next([...this.genericSelection$.value, generic]);
    }
    this.collectGenericIds();
  }

  collectGenericIds() {
    const arr = [];
    this.selectedGenerics.forEach((generic) => arr.push(generic.value));
    this.selectedGenerics$.next(arr);
  }

  /**
   * Control error collector
   *
   * @param errorObj - control errors
   */
  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
