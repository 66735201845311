import { AllActions } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { Project, User } from '../models';

export enum ActionTypes {
  COPY_PROJECT = '[Project List Page] Copy Project',
  COPY_PROJECT_FAILURE = '[Project API] Copy Project Failure',
  COPY_PROJECT_SUCCESS = '[Project API] Copy Project Success',
  SHARE_PROJECT = '[Project List Page] Share Project',
  SHARE_PROJECT_FAILURE = '[Project API] Share Project Failure',
  SHARE_PROJECT_SUCCESS = '[Project API] Share Project Success',
  UNSHARE_PROJECT = '[Project List Page] Unshare Project',
  UNSHARE_PROJECT_FAILURE = '[Project API] Unshare Project Failure',
  UNSHARE_PROJECT_SUCCESS = '[Project API] Unshare Project Success',
  REMOVE_PROJECT = '[Project State] Remove Project',
  GET_PROJECT_LIST_WITH_DETAILS = '[Project API] Get Project List Details',
  GET_PROJECT_LIST_WITH_DETAILS_SUCCESS = '[Project API] Get Project List Details Success',
  GET_PROJECT_LIST_WITH_DETAILS_FAILED = '[Project API] Get Project List Details Failed',
}

// Copy
export class CopyProject implements Action {
  readonly type = ActionTypes.COPY_PROJECT;
  constructor(public payload?: { data?: Project; name?: string }) {}
}

export class CopyProjectFailure implements Action {
  readonly type = ActionTypes.COPY_PROJECT_FAILURE;
  constructor(
    public payload?: {
      data?: Project;
      name?: string;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class CopyProjectSuccess implements Action {
  readonly type = ActionTypes.COPY_PROJECT_SUCCESS;
  constructor(
    public payload?: {
      data?: Project;
      name?: string;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Share
export class ShareProject implements Action {
  readonly type = ActionTypes.SHARE_PROJECT;
  constructor(public payload?: { data?: Project; userIds?: Array<User> }) {}
}

export class ShareProjectFailure implements Action {
  readonly type = ActionTypes.SHARE_PROJECT_FAILURE;
  constructor(
    public payload?: {
      data?: Project;
      userIds?: Array<User>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class ShareProjectSuccess implements Action {
  readonly type = ActionTypes.SHARE_PROJECT_SUCCESS;
  constructor(
    public payload?: {
      data?: Project;
      userIds?: Array<User>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

// Unshare
export class UnshareProject implements Action {
  readonly type = ActionTypes.UNSHARE_PROJECT;
  constructor(public payload?: { data?: Project; userIds?: Array<string> }) {}
}

export class UnshareProjectFailure implements Action {
  readonly type = ActionTypes.UNSHARE_PROJECT_FAILURE;
  constructor(
    public payload?: {
      data?: Project;
      userIds?: Array<string>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class UnshareProjectSuccess implements Action {
  readonly type = ActionTypes.UNSHARE_PROJECT_SUCCESS;
  constructor(
    public payload?: {
      data?: Project;
      userIds?: Array<string>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class RemoveProject implements Action {
  readonly type = ActionTypes.REMOVE_PROJECT;
  constructor(public payload?: { id?: string }) {}
}

export class GetProjectListWithDetails implements Action {
  readonly type = ActionTypes.GET_PROJECT_LIST_WITH_DETAILS;
  constructor(public payload?: { id?: string }) {}
}

export class GetProjectListWithDetailsSuccess implements Action {
  readonly type = ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_SUCCESS;
  constructor(
    public payload?: {
      id?: string;
      results?: Array<Project>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetProjectListWithDetailsFailure implements Action {
  readonly type = ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_FAILED;
  constructor(
    public payload?: { id?: string; message?: string; notify?: boolean },
  ) {}
}

/* Actions Union */

export type All =
  | AllActions<Project>
  | CopyProject
  | CopyProjectFailure
  | CopyProjectSuccess
  | ShareProject
  | ShareProjectFailure
  | ShareProjectSuccess
  | UnshareProject
  | UnshareProjectFailure
  | UnshareProjectSuccess
  | RemoveProject
  | GetProjectListWithDetails
  | GetProjectListWithDetailsSuccess
  | GetProjectListWithDetailsFailure;
