import { nanoid } from 'nanoid';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { PipeUtils } from 'prosumer-core';
import { ChartJSMeta } from 'prosumer-shared/modules/chartjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Directive, Input } from '@angular/core';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';

@Directive()
export abstract class DispatchAdapter<
  T,
  U extends ChartJSMeta,
> extends BaseComponent {
  private localPerception: ResultsPerceptionService;
  private lastRegisteredSubject = new BehaviorSubject<string>('');
  readonly randomChartID = nanoid();
  chartData$: Observable<U>;
  colors$: Observable<PerceptionMap>;

  @Input() caseId: string;

  constructor(perception: ResultsPerceptionService) {
    super();
    this.localPerception = perception;
  }

  abstract getChartName(): string;
  abstract getNgxChartsDataStream(): Observable<T>;
  abstract mapToChartJSChart(from: T): U;
  abstract formatTicks(chartJSData: U): U;
  abstract getLegendNames(data: T): string[];
  abstract injectAxisNames(data: U): U;

  initializeChartDataAndColors(): void {
    this.chartData$ = this.getChartDataStream();
    this.colors$ = this.localPerception.getPerceptionMapStream(this.caseId);
  }

  tapToRegisterLegendNames($: Observable<T>): Observable<T> {
    return $.pipe(
      tap((data) => {
        const names = this.getLegendNames(data);
        const hash = this.buildLegendHash(names);
        if (this.isLegendHashDifferentAsBefore(hash)) {
          this.lastRegisteredSubject.next(hash);
          this.localPerception.registerLegendNames(this.caseId, names);
        }
      }),
      takeUntil(this.componentDestroyed$),
    );
  }

  private getChartDataStream(): Observable<U> {
    return this.getNgxChartsDataStream().pipe(
      PipeUtils.filterOutUndefined,
      this.tapToRegisterLegendNames.bind(this),
      map((data) => this.mapToChartJSChart(data as T)),
      map((data) => ({
        ...data,
        name: this.getChartName(),
      })),
      map((data) => this.formatTicks(data)),
      map(this.injectAxisNames.bind(this)),
      takeUntil(this.componentDestroyed$),
    ) as Observable<U>;
  }

  private buildLegendHash(from: string[]): string {
    return from.sort().join('');
  }

  private isLegendHashDifferentAsBefore(hash: string): boolean {
    return this.lastRegisteredSubject.value !== hash;
  }
}
