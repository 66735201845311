/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RUN_STATUS_ICON_MAP } from 'prosumer-app/app.references';
import { StatusMap, contains } from 'prosumer-app/libs/eyes-shared';

@Component({
  selector: 'prosumer-simulation-status',
  templateUrl: './simulation-status.component.html',
  styleUrls: ['./simulation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationStatusComponent {
  statusMap: StatusMap = RUN_STATUS_ICON_MAP;
  @Input() status: string;

  isCustomIcon(): boolean {
    const {
      succeeded,
      failed,
      succeeded_with_unsatisfied_constraints,
      succeeded_with_warnings,
      impossible_simulation,
    } = RUN_STATUS_ICON_MAP;

    const customIcons = [
      succeeded,
      failed,
      succeeded_with_unsatisfied_constraints,
      succeeded_with_warnings,
      impossible_simulation,
    ].map((statusIcon) => statusIcon.icon);

    return contains(customIcons, this.status);
  }

  get statusObj() {
    return (
      RUN_STATUS_ICON_MAP[this.status] || { class: '', icon: '', label: '' }
    );
  }
}
