import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TooltipAnchorComponent } from './tooltip-anchor/index';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    MatIconModule,
    MatIconModule,
  ],
  declarations: [TooltipComponent, TooltipDirective, TooltipAnchorComponent],
  exports: [TooltipComponent, TooltipDirective, TooltipAnchorComponent],
})
export class TooltipModule {}
