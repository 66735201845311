import { FluidConfig, FluidConfigData } from '../models';
import { DEFAULT_CUSTOM_FLUID_COLOR_PALETTE } from '../system-visualization.token';

/**
 * Lighten the given color with the given amount in percentage.
 *
 * @param color e.g '#ffb74d'
 * @param amt e.g 0.1, 0.2 etc
 *
 */
const _lightenColor = (color: string, amt: number): string => {
  if (color.indexOf('#') !== -1) {
    let rgb = '#';
    for (let i = 0; i < 3; i++) {
      const colorInt = parseInt(color.slice(1).substr(i * 2, 2), 16);
      const colorStr = Math.round(
        Math.min(Math.max(0, colorInt + colorInt * amt), 255),
      ).toString(16);
      rgb += ('00' + colorStr).substr(colorStr.length);
    }
    return rgb;
  }
  return color;
};

/**
 * Get color from the CUSTOM_EV_COLOR_PALETTE
 * or produces new color if cannot found in the palette.
 *
 * @param index index number - e.g 0  or 1 or 2...
 * @param colorPalette - the color palette with default value
 */
export const getColor = (
  index: number,
  colorPalette = DEFAULT_CUSTOM_FLUID_COLOR_PALETTE,
): string => {
  index = Math.abs(index);
  if (index > 19 && index < 40) {
    const newIdx = index - 20;
    return _lightenColor(colorPalette[newIdx], 0.1);
  } else if (index > -1 && index <= 19) {
    return colorPalette[index];
  } else {
    // this is unlikely to happen
    return _lightenColor(colorPalette[Math.floor(Math.random() * 20)], -0.2);
  }
};

/**
 * Returns the list of fluidConfigData for the given custom energy vector set.
 * This assumes that the given custom energy vectors are real custom vectors and does not match
 * to any of the '7' basic energy vectors.
 *
 * @param customEnergyVectors custom energy vectors.
 * @param colorPalette - the color palette with default value
 * @return list of FluidConfigData.
 */
export const getFluidConfigForCustomVectors = (
  customEnergyVectors: Set<string>,
  colorPalette = DEFAULT_CUSTOM_FLUID_COLOR_PALETTE,
): Array<FluidConfigData> => {
  if (!!customEnergyVectors) {
    const fluidConfig: Array<FluidConfigData> = [];
    Array.from(customEnergyVectors.values())
      .sort()
      .forEach((cv, index) => {
        const color = getColor(index, colorPalette);
        fluidConfig.push({
          name: cv,
          color,
        });
      });
    return fluidConfig;
  }
  return [];
};

/**
 * Returns FluidConfig for customEnergyVector.
 *
 * @param customEnergyVectors set of custom energy vectors.
 * @param colorPalette - the color palette with default value
 */
export const getFluidConfigDataForCustomVectors = (
  customEnergyVectors: Set<string>,
  colorPalette = DEFAULT_CUSTOM_FLUID_COLOR_PALETTE,
): FluidConfig => {
  const fluidConfigArr: Array<FluidConfigData> = getFluidConfigForCustomVectors(
    customEnergyVectors,
    colorPalette,
  );

  if (!!fluidConfigArr) {
    const fluidConfig = {};
    fluidConfigArr.forEach((fd) => {
      const fluidName = fd.name;
      const fluidColor = fd.color;
      fluidConfig[fluidName] = { name: fluidName, color: fluidColor };
    });
    return fluidConfig;
  }
  return {} as FluidConfig;
};

/**
 * Checks if the given n number is between the given min and max number.
 *
 * @param n number to check
 * @param min min number
 * @param max max number
 */
export const between = (n: number, min: number, max: number) =>
  n >= min && n <= max;
