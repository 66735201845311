import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { Observable, of, from } from 'rxjs';
import {
  mergeMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  toArray,
  mapTo,
} from 'rxjs/operators';

import { SnackbarService, ApiService } from '../interfaces/index';
import { BaseEntityState, BaseEntity } from '../models/index';
import { doNothing } from '../utilities/index';

import { StateFacadeService } from './state.facade';
import { StateFactory } from './state.factory';
import * as StateActions from './state.actions';

export type StateMessageMap = Readonly<{
  getSuccess: string;
  getFailure: string;
  getListSuccess: string;
  getListFailure: string;
  createSuccess: string;
  createFailure: string;
  updateSuccess: string;
  updateFailure: string;
  deleteSuccess: string;
  deleteFailure: string;
  retry: string;
}>;

export abstract class StateEffects<
  T extends BaseEntityState<U>,
  U extends BaseEntity,
> {
  private messageMap: StateMessageMap;

  constructor(
    public actions$: Actions,
    public api: ApiService<U>,
    public notification: SnackbarService,
    public stateFacade: StateFacadeService<T, U>,
    public stateFactory: StateFactory<T, U>,
    public translate: TranslateService,
  ) {
    this.messageMap = this.getMessageMap();
  }

  // Get

  get$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.Get>(
      StateActions.ActionTypes(this.stateFactory.feature).GET,
    ),
    withLatestFrom(this.stateFacade.dataMap$),
    mergeMap(([action, dataList]) =>
      !!dataList[action.payload.id] &&
      dataList[action.payload.id].loaded &&
      !action.payload.forced
        ? of(
            this.stateFactory.createAction(StateActions.Type.GET_SUCCESS, {
              id: action.payload.id,
              params: action.payload.params,
              message: this.translate.instant(this.messageMap.getSuccess),
              resultData: dataList[action.payload.id],
            }),
          )
        : this.api.get(action.payload.id, action.payload.params).pipe(
            map((data) =>
              this.stateFactory.createAction(StateActions.Type.GET_SUCCESS, {
                id: action.payload.id,
                params: action.payload.params,
                message: this.translate.instant(this.messageMap.getSuccess),
                resultData: data,
              }),
            ),
            catchError((message) =>
              of(
                this.stateFactory.createAction(StateActions.Type.GET_FAILURE, {
                  id: action.payload.id,
                  params: action.payload.params,
                  message:
                    message ||
                    this.translate.instant(this.messageMap.getFailure),
                }),
              ),
            ),
          ),
    ),
  );

  getFailure$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.GetFailure>(
      StateActions.ActionTypes(this.stateFactory.feature).GET_FAILURE,
    ),
    mergeMap((action) =>
      this.notification
        .showError(
          action.payload.message,
          this.translate.instant(this.messageMap.retry),
        )
        .onAction()
        .pipe(
          tap(() =>
            this.stateFacade.get(action.payload.id, action.payload.params),
          ),
          map(() => action),
        ),
    ),
  );

  getSuccess$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.GetSuccess<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).GET_SUCCESS,
    ),
    tap((action) =>
      action.payload.notify
        ? this.notification.showSuccess(action.payload.message)
        : doNothing(),
    ),
  );

  // Get List

  getList$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.GetList>(
      StateActions.ActionTypes(this.stateFactory.feature).GET_LIST,
    ),
    mergeMap((action) =>
      this.api
        .getList(
          action.payload.id,
          action.payload.params,
          action.payload.paging,
        )
        .pipe(
          map((dataList) =>
            this.stateFactory.createAction(StateActions.Type.GET_LIST_SUCCESS, {
              message: this.translate.instant(this.messageMap.getListSuccess),
              id: action.payload.id,
              params: action.payload.params,
              resultIds: dataList,
            }),
          ),
          catchError((message) =>
            of(
              this.stateFactory.createAction(
                StateActions.Type.GET_LIST_FAILURE,
                {
                  id: action.payload.id,
                  params: action.payload.params,
                  message:
                    message ||
                    this.translate.instant(this.messageMap.getListFailure),
                },
              ),
            ),
          ),
        ),
    ),
  );

  getListFailure$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.GetListFailure>(
      StateActions.ActionTypes(this.stateFactory.feature).GET_LIST_FAILURE,
    ),
    mergeMap((action) =>
      this.notification
        .showError(
          action.payload.message,
          this.translate.instant(this.messageMap.retry),
        )
        .onAction()
        .pipe(
          tap(() => this.stateFacade.getList()),
          map(() => action),
        ),
    ),
  );

  getListSuccess$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.GetListSuccess>(
      StateActions.ActionTypes(this.stateFactory.feature).GET_LIST_SUCCESS,
    ),
    withLatestFrom(this.stateFacade.dataList$),
    mergeMap(([action, dataList]) =>
      from(
        (dataList || []).filter((data) => !data.loaded).map((data) => data.id),
      ).pipe(
        mergeMap((id) =>
          this.stateFacade.get(id, { projectId: action.payload.id }),
        ),
        toArray(),
        mapTo(action),
      ),
    ),
    tap((action) =>
      action.payload.notify
        ? this.notification.showSuccess(action.payload.message)
        : doNothing(),
    ),
  );

  // Create

  create$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.Create<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).CREATE,
    ),
    mergeMap((action) =>
      this.api.create(action.payload.data, action.payload.params).pipe(
        map((data) =>
          this.stateFactory.createAction(StateActions.Type.CREATE_SUCCESS, {
            params: action.payload.params,
            data,
            message: this.translate.instant(this.messageMap.createSuccess),
          }),
        ),
        catchError((message) =>
          of(
            this.stateFactory.createAction(StateActions.Type.CREATE_FAILURE, {
              params: action.payload.params,
              data: action.payload.data,
              message:
                message ||
                this.translate.instant(this.messageMap.createFailure),
            }),
          ),
        ),
      ),
    ),
  );

  createFailure$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.CreateFailure<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).CREATE_FAILURE,
    ),
    mergeMap((action) =>
      this.notification
        .showError(
          action.payload.message,
          this.translate.instant(this.messageMap.retry),
        )
        .onAction()
        .pipe(
          tap(() => this.stateFacade.create(action.payload.data)),
          map(() => action),
        ),
    ),
  );

  createSuccess$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.CreateSuccess<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).CREATE_SUCCESS,
    ),
    tap((action) => this.notification.showSuccess(action.payload.message)),
  );

  // Update

  update$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.Update<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).UPDATE,
    ),
    mergeMap((action) =>
      this.api.update(action.payload.data, action.payload.params).pipe(
        map((data) =>
          this.stateFactory.createAction(StateActions.Type.UPDATE_SUCCESS, {
            params: action.payload.params,
            data,
            message: this.translate.instant(this.messageMap.updateSuccess),
          }),
        ),
        catchError((message) =>
          of(
            this.stateFactory.createAction(StateActions.Type.UPDATE_FAILURE, {
              params: action.payload.params,
              data: action.payload.data,
              message:
                message ||
                this.translate.instant(this.messageMap.updateFailure),
            }),
          ),
        ),
      ),
    ),
  );

  updateFailure$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.UpdateFailure<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).UPDATE_FAILURE,
    ),
    mergeMap((action) =>
      this.notification
        .showError(
          action.payload.message,
          this.translate.instant(this.messageMap.retry),
        )
        .onAction()
        .pipe(
          tap(() => this.stateFacade.update(action.payload.data)),
          map(() => action),
        ),
    ),
  );

  updateSuccess$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.UpdateSuccess<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).UPDATE_SUCCESS,
    ),
    tap((action) => this.notification.showSuccess(action.payload.message)),
  );

  // Delete

  delete$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.Delete<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).DELETE,
    ),
    mergeMap((action) =>
      this.api.delete(action.payload.data, action.payload.params).pipe(
        map(() =>
          this.stateFactory.createAction(StateActions.Type.DELETE_SUCCESS, {
            params: action.payload.params,
            data: action.payload.data,
            message: this.translate.instant(this.messageMap.deleteSuccess),
          }),
        ),
        catchError((message) =>
          of(
            this.stateFactory.createAction(StateActions.Type.DELETE_FAILURE, {
              params: action.payload.params,
              data: action.payload.data,
              message:
                message ||
                this.translate.instant(this.messageMap.deleteFailure),
            }),
          ),
        ),
      ),
    ),
  );

  deleteFailure$: Observable<StateActions.AllActions<U>> = this.actions$.pipe(
    ofType<StateActions.DeleteFailure<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).DELETE_FAILURE,
    ),
    mergeMap((action) =>
      this.notification
        .showError(
          action.payload.message,
          this.translate.instant(this.messageMap.retry),
        )
        .onAction()
        .pipe(
          tap(() => this.stateFacade.delete(action.payload.data)),
          map(() => action),
        ),
    ),
  );

  deleteSuccess$: Observable<any> = this.actions$.pipe(
    ofType<StateActions.DeleteSuccess<U>>(
      StateActions.ActionTypes(this.stateFactory.feature).DELETE_SUCCESS,
    ),
    tap((action) => this.notification.showSuccess(action.payload.message)),
  );

  abstract getMessageMap(): StateMessageMap;
}
