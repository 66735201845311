<prosumer-page [formGroup]="solarForm">
  <mat-expansion-panel
    [expanded]="true"
    data-testid="solar-profile-expansion-panel"
  >
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
      <mat-panel-title data-testid="solar-profile-title">
        {{ 'RenewableProfile.labels.solarProfile' | translate }}
        <prosumer-tooltip-anchor
          [message]="'renewable_profile.solar_profile_section'"
          [attr.data-testid]="'renewable-profile-solar-profile-tooltip'"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
          <prosumer-select
            [placeholder]="
              'RenewableProfile.placeholders.trackingType' | translate
            "
            [label]="'RenewableProfile.labels.trackingType' | translate"
            [options]="trackingTypeOptions"
            [control]="solarForm?.controls?.trackingType"
            [required]="true"
            [tooltip]="'renewable_profile.solar_tracking_type'"
            fxFlex
            [attr.data-testid]="'solar-profile-tracking-type'"
          >
          </prosumer-select>

          <prosumer-input
            fxFlex
            type="number"
            [placeholder]="'RenewableProfile.labels.panelTilt' | translate"
            [label]="'RenewableProfile.labels.panelTilt' | translate"
            [control]="solarForm?.controls?.panelTilt"
            [errorMessageMap]="errorMessages['panelTilt']"
            [inputTooltip]="'renewable_profile.solar_panel_tilt'"
            [attr.data-testid]="'solar-profile-panel-tilt'"
          >
          </prosumer-input>

          <prosumer-input
            fxFlex
            type="number"
            [placeholder]="
              'RenewableProfile.labels.panelOrientation' | translate
            "
            [label]="'RenewableProfile.labels.panelOrientation' | translate"
            [control]="solarForm?.controls?.panelOrientation"
            [errorMessageMap]="errorMessages['panelOrientation']"
            [inputTooltip]="'renewable_profile.solar_panel_orientation'"
            [attr.data-testid]="'solar-profile-panel-orientation'"
          >
          </prosumer-input>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px"> -->
        <!-- <prosumer-input
            fxFlex
            type="number"
            [placeholder]="'RenewableProfile.labels.criticalSnowDepth' | translate"
            [label]="'RenewableProfile.labels.criticalSnowDepth' | translate"
            [control]="solarForm?.controls?.criticalSnowDepth"
            [errorMessageMap]="errorMessages['criticalSnowDepth']"
            [inputTooltip]="'TBD'"
          >
          </prosumer-input> -->
        <!-- </div> -->
      </div>

      <prosumer-profile-computation-option
        (computation)="computationHandler($event)"
      ></prosumer-profile-computation-option>

      <mat-error *ngIf="clickedSubmitted$ | async">
        <div
          *ngIf="
            !solarForm?.controls?.longitude.valid ||
            !solarForm?.controls?.latitude.valid
          "
          [innerHtml]="
            'RenewableProfile.messages.location.required' | translate
          "
        ></div>
        <div
          *ngIf="!solarForm?.controls?.historicalYears.valid"
          [innerHtml]="
            'RenewableProfile.messages.listOfYears.required' | translate
          "
        ></div>
        <div
          *ngIf="!solarForm?.controls?.numberOfYears.valid"
          [innerHtml]="
            'RenewableProfile.messages.numberOfYears.required' | translate
          "
        ></div>
        <div
          *ngIf="!solarForm?.controls?.lastHistoricalYear.valid"
          [innerHtml]="
            'RenewableProfile.messages.lastHistoricalYear.required' | translate
          "
        ></div>
        <div
          *ngIf="!solarForm?.controls?.percentiles.valid"
          [innerHtml]="
            'RenewableProfile.messages.listOfPercentiles.required' | translate
          "
        ></div>
      </mat-error>

      <div fxLayout="column" fxLayoutAlign="end end" fxLayoutGap="10px">
        <button
          class="eyes-button"
          color="primary"
          mat-flat-button
          (click)="onSubmit()"
          [disabled]="solarForm?.pristine"
          [@fadeIn]
          [attr.data-testid]="'solar-profile-submit'"
        >
          {{ 'Submit' }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</prosumer-page>
