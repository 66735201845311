import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { contains } from '../../../../utilities/index';
import { InputComponent } from '../input.component';

@Component({
  selector: 'prosumer-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteInputComponent
  extends InputComponent
  implements OnInit
{
  @Input() autocompleteOptions: Array<any> = [];
  @Input() displayField: string;

  @Input() filteredOptions$: Observable<any[]>;

  autocompleteDisplay = (option?: any): string | undefined => {
    if (this.displayField) {
      return option ? option[this.displayField] : undefined;
    }
    return option;
  };

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.control) {
      return;
    }

    if (!this.filteredOptions$) {
      this.filteredOptions$ = this.control.valueChanges.pipe(
        startWith<string | any>(''),
        map((value) =>
          value
            ? typeof value === 'string'
              ? value
              : value[this.displayField]
            : undefined,
        ),
        map((value) =>
          value
            ? this.autocompleteFilter(value)
            : this.autocompleteOptions && this.autocompleteOptions.slice(),
        ),
      );
    }
  }

  autocompleteFilter(value: string): any[] {
    const filterValue = value.toLowerCase();

    if (this.autocompleteOptions) {
      return this.autocompleteOptions.filter((option) =>
        this.displayField
          ? contains(option[this.displayField].toLowerCase(), filterValue)
          : contains(option.toLowerCase(), filterValue),
      );
    }
    return this.autocompleteOptions;
  }
}
