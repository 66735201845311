import { HttpErrorResponse } from '@angular/common/http';
import { Directive, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Query } from '@datorama/akita';

import { Coerce } from 'prosumer-app/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  Duplicable,
  DuplicateDetailData,
  ScenarioDetailStore,
} from 'prosumer-app/stores/scenario-detail';
import { take } from 'rxjs/operators';

@Directive()
export abstract class DetailEntityDuplicator<T = Duplicable> {
  readonly loading$ = this.detailQuery.selectLoading();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DuplicateDetailData<T>,
    public details: ScenarioDetailStore<unknown>,
    public detailQuery: Query<unknown>,
    public dialogRef: MatDialogRef<unknown>,
    public notifs: NotificationsService,
  ) {}

  onDuplicate(data: T): void {
    this.details
      .duplicate(this.getOriginId(), this.toBE(data))
      .pipe(take(1))
      .subscribe(
        (response) => this.dialogRef.close(response),
        (error) => this.notifs.showError(this.getErrMsg(error)),
      );
  }

  toBE(data: T): unknown {
    return data;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private getOriginId(): string {
    return this.data.origin.id;
  }

  private getErrMsg(from: HttpErrorResponse): string {
    return Coerce.toObject(from.error)['error'];
  }
}
