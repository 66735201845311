import { EXECUTION_STATUS } from 'prosumer-app/app.references';
import { Coerce, Utils } from 'prosumer-app/core';
import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiService } from 'prosumer-app/libs/eyes-shared';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';

import { ProsumerView, Scenario, ScenarioVariationMap } from '../models';
import {
  CoherenceResultMapper,
  CommoditiesMapper,
  EquipmentsMapperOld,
  FrequencyControlMapper,
  GeneralMapper,
  LoadsMapper,
  NettingMapper,
  OptimizationCockpitMapper,
  RegulationsMapper,
  RunMapMapper,
  TimeMapper,
  TopologyMapper,
} from './mappers';
import { MobilityMapper } from './mappers/mobility.mapper';

type FileModel = {
  name: string;
  url: string;
};
type SignedUrlResponse = {
  run: { inputFile: { name: string; url: string; gams: FileModel[] } };
};

@Injectable()
export class ScenarioApiService extends ApiService<Scenario> {
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
  ) {
    super(_config.api.baseUrl);
  }

  create(data: Scenario): Observable<Scenario> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.create, data.caseId, {
          projectId: data.projectId,
        }),
        this.mapDataToBackend(data),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  delete(data: Scenario, params?: Dictionary<string>): Observable<any> {
    return this._http
      .delete<any>(
        this.endpoint(this._config.api.endpoints.scenario.delete, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  copy(
    data: Scenario,
    name: string,
    targetProjectId?: string,
    targetCaseId?: string,
  ): Observable<any> {
    return this._http
      .post<any>(
        this.endpoint(this._config.api.endpoints.scenario.copy, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        {
          name,
          target_project_uuid: targetProjectId,
          target_case_uuid: targetCaseId,
        },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  get(id: string, params?: Dictionary<string>): Observable<Scenario> {
    return this._http
      .get<any>(
        this.endpoint(this._config.api.endpoints.scenario.get, id, {
          projectId: params.projectId,
          caseId: params.caseId,
        }),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getList(id: string, params?: Dictionary<string>): Observable<Array<string>> {
    return this._http
      .get<Array<string>>(
        this.endpoint(this._config.api.endpoints.scenario.list, id, {
          projectId: params.projectId,
        }),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  getCaseDetailScenarioList(
    id: string,
    params?: Dictionary<string>,
  ): Observable<Array<Scenario>> {
    return this._http
      .get<Array<Scenario>>(
        this.endpoint(this._config.api.endpoints.scenario.listWithDetails, id, {
          projectId: params.projectId,
        }),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  getScenarioNamesList(
    id: string,
    params?: Dictionary<string>,
  ): Observable<Array<string>> {
    return this._http
      .get<Array<string>>(
        this.endpoint(this._config.api.endpoints.scenario.listNames, id, {
          projectId: params.projectId,
        }),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  update(data: Scenario, params?: Dictionary<string>): Observable<Scenario> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.update, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
        this.mapDataToBackend(data),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  updateScenarioFromCaseView(data: Scenario): Observable<Scenario> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.update, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
        { view: ProsumerView.case, name: data.name },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  // Custom services
  download(data: Scenario, endpointName: string): Observable<any> {
    return this._http
      .get(
        this.endpoint(
          this._config.api.endpoints.scenario[endpointName],
          data.id,
          {
            projectId: data.projectId,
            caseId: data.caseId,
            scenarioId: data.id ? data.id : undefined,
            simulationId: data.simulationId ? data.simulationId : undefined,
          },
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this._http.get(downloadSignedUrl.url, { responseType: 'blob' }).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'application/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              const name =
                data && data.name ? data.name : downloadSignedUrl.name;
              anchor.download = this.setDownloadFilename(
                endpointName,
                downloadSignedUrl,
                name,
              );
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
            catchError((error) => throwError(this.handleError(error))),
          ),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getDraftInput(data: any): Observable<any> {
    return this._http
      .get(
        this.endpoint(
          this._config.api.endpoints.scenario.getDraftInput,
          data.id,
          {
            projectId: data.projectId,
            caseId: data.caseId,
            scenarioId: data.id,
          },
        ),
      )
      .pipe(
        map((response: any) => response),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  upload(data: any, file: File, gamsFiles: File[] = []): Observable<Scenario> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.create, data.caseId, {
          projectId: data.projectId,
        }),
        data,
      )
      .pipe(
        catchError((error) => throwError(this.handleError(error))),
        mergeMap((uploadResponse: any) =>
          this.requestExcelUpload(uploadResponse, file).pipe(
            catchError((error) => throwError(this.handleError(error))),
            map((resp) => uploadResponse),
            delay(1000),
          ),
        ),
        mergeMap((uploadResponse) =>
          !gamsFiles.length
            ? of(uploadResponse)
            : combineLatest([
                ...gamsFiles.map((file) =>
                  this.requestGAMSUpload(uploadResponse, file),
                ),
              ]).pipe(
                catchError((error) => throwError(this.handleError(error))),
                map((resp) => uploadResponse),
              ),
        ),
        mergeMap((uploadResponse) =>
          this._http
            .post(
              this.endpoint(
                this._config.api.endpoints.scenario.simulate,
                uploadResponse.scenarioUuid,
                {
                  projectId: data.projectId,
                  caseId: data.caseId,
                },
              ),
              {},
            )
            .pipe(
              catchError((error) => throwError(this.handleError(error))),
              map((response) => this.mapDataToFrontend(response)),
            ),
        ),
      );
  }

  requestExcelUpload(
    data: Record<string, string>,
    file: File,
  ): Observable<unknown> {
    return this._http
      .get(
        this.endpoint(
          this._config.api.endpoints.scenario.upload,
          data['scenarioUuid'],
          {
            projectId: data['projectUuid'],
            caseId: data['caseUuid'],
            scenarioId: data['scenarioUuid'],
            filename: file.name,
          },
        ),
      )
      .pipe(
        mergeMap((uploadSignedUrl: SignedUrlResponse) =>
          this._http.put(uploadSignedUrl.run.inputFile.url, file),
        ),
      );
  }

  requestGAMSUpload(
    data: Record<string, string>,
    file: File,
  ): Observable<unknown> {
    return this._http
      .get(
        this.endpoint(
          this._config.api.endpoints.scenario.upload,
          data['scenarioUuid'],
          {
            projectId: data['projectUuid'],
            caseId: data['caseUuid'],
            scenarioId: data['scenarioUuid'],
            filename: file.name,
          },
        ),
      )
      .pipe(
        map((uploadSignedUrl: SignedUrlResponse) => {
          const gamsList = Coerce.toArray(uploadSignedUrl.run.inputFile.gams);
          return gamsList.filter((gams) => gams.name === file.name);
        }),
        filter((matchedFiles: FileModel[]) => matchedFiles.length > 0),
        mergeMap((matchedFiles: FileModel[]) =>
          this._http.put(matchedFiles[0].url, file),
        ),
      );
  }

  uploadClone(data: any, file: File): Observable<Scenario> {
    const { projectId, caseId, ...rest } = data;
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.create, data.caseId, {
          projectId: data.projectId,
        }),
        data,
      )
      .pipe(
        mergeMap((uploadResponse: any) =>
          this._http
            .get(
              this.endpoint(
                this._config.api.endpoints.scenario.uploadClone,
                uploadResponse.scenarioUuid,
                {
                  projectId,
                  caseId,
                  scenarioId: uploadResponse.scenarioUuid,
                  filename: file.name,
                },
              ),
            )
            .pipe(
              mergeMap((uploadSignedUrl: any) =>
                this._http.put(uploadSignedUrl.run.inputFile.url, file),
              ),
              mergeMap(() =>
                this._http.get(
                  this.endpoint(
                    this._config.api.endpoints.scenario.startImport,
                    uploadResponse.scenarioUuid,
                    {
                      projectId: data.projectId,
                      caseId: data.caseId,
                    },
                  ),
                ),
              ),
              map(() =>
                this.mapDataToFrontend({
                  scenarioUuid: uploadResponse.scenarioUuid,
                  projectUuid: uploadResponse.projectUuid,
                  caseUuid: uploadResponse.caseUuid,
                  ...rest,
                }),
              ),
              catchError((error) => throwError(this.handleError(error))),
            ),
        ),
      );
  }

  launch(data: any): Observable<any> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.launch, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
        undefined,
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  validate(data: Scenario): Observable<any> {
    return this._http
      .get(
        this.endpoint(this._config.api.endpoints.scenario.validate, data.id, {
          projectId: data.projectId,
          caseId: data.caseId,
        }),
        undefined,
      )
      .pipe(
        map((response: any) =>
          response.issues.map((issue) =>
            CoherenceResultMapper.mapToFrontend(issue),
          ),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getLatestSimulations(): Observable<Array<string>> {
    return this._http
      .get<
        Array<string>
      >(this.endpoint(this._config.api.endpoints.scenario.latestSimulations))
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  getScenarioVariations(
    id: string,
    params?: Dictionary<string | string[]>,
  ): Observable<Array<ScenarioVariationMap>> {
    return this._http
      .get<Array<ScenarioVariationMap>>(
        this.endpoint(
          this._config.api.endpoints.scenario.getScenarioVariations,
          id,
          {
            projectId: params.projectId as string,
            caseId: params.caseId as string,
          },
        ),
        { params: this.getMultiValueParams(params, ['status']) },
      )
      .pipe(
        map((scenarioVariations) =>
          scenarioVariations.map(
            (variation) =>
              this.mapScenarioVariationToFrontend(
                variation,
              ) as ScenarioVariationMap,
          ),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getScenariosAndVariations(
    params?: Dictionary<string | string[]>,
  ): Observable<Array<Scenario | ScenarioVariationMap>> {
    return this._http
      .get<Array<any>>(
        this.endpoint(
          this._config.api.endpoints.scenario.getScenariosAndVariations,
          undefined,
          {
            projectId: params.projectId as string,
            caseId: params.caseId as string,
          },
        ),
        { params: this.getMultiValueParams(params, ['status']) },
      )
      .pipe(
        map((data) =>
          data.map(
            (datum) =>
              this.mapScenariosAndVariationToFrontend(datum) as
                | Scenario
                | ScenarioVariationMap,
          ),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  // Custom service helpers
  setDownloadFilename(endpoint: string, getSignedRsp: any, name: string): any {
    let newfilename = getSignedRsp.name;
    const splitname: Array<string> = newfilename.split('.');
    const FILE_EXT = splitname[splitname.length - 1];
    switch (endpoint) {
      case 'getSigned': {
        newfilename = `${name}.${FILE_EXT}`;
        break;
      }
      case 'getOutputSigned': {
        newfilename = `${name}_out.${FILE_EXT}`;
        break;
      }
    }
    return newfilename;
  }

  /** Mapping to Backend */
  mapDataToBackend(data: Scenario): any {
    return {
      // Identities
      scenarioUuid: data.id,
      projectUuid: data.projectId,
      caseUuid: data.caseId,

      // Common Fields
      scenarioType: data.scenarioType,
      owner: data.owner,
      description: data.description,
      createdFrom: data.createdFrom,
      createdAt: data.createdDate,
      updatedAt: data.updatedDate,
      inputFileGenerated: !!data.inputFileGenerated,

      // For Conflict Handling
      updatedBy: data.updatedBy,
      updatedById: data.updatedById,

      // Wizard
      ...GeneralMapper.mapToBackend(data.general),
      ...TimeMapper.mapToBackend(data.time),
      ...LoadsMapper.mapToBackend(data.loads),
      ...EquipmentsMapperOld.mapToBackend(data.equipments),
      ...TopologyMapper.mapToBackend(data.topology),
      ...RegulationsMapper.mapToBackend(data.regulations),
      ...CommoditiesMapper.mapToBackend(data.commodities),
      ...FrequencyControlMapper.mapToBackend(data.frequencyControl),
      ...MobilityMapper.mapToBackend(data.mobility),
      ...OptimizationCockpitMapper.mapToBackend(data.optimizationCockpit),
      ...NettingMapper.mapToBackend(data.netting),

      // Others
      ...RunMapMapper.mapToBackend(data.run),
    };
  }

  /** Mapping to Frontend */
  mapDataToFrontend(data: any): Scenario {
    if (!data) {
      return {};
    }

    const startYear = (data.projectDuration || {}).startYear;
    const endYear =
      (data.projectDuration || {}).startYear +
      (data.projectDuration || {}).duration -
      1;

    return {
      // Identities
      id: data.scenarioUuid,
      projectId: data.projectUuid,
      caseId: data.caseUuid,

      // Common Fields
      scenarioType: data.scenarioType,
      owner: data.owner,
      description: data.description,
      createdFrom: data.createdFrom,
      createdDate: data.createdAt,
      updatedDate: data.updatedAt,
      inputFileGenerated: !!data.inputFileGenerated,

      // Wizard
      general: GeneralMapper.mapToFrontend(data),
      time: TimeMapper.mapToFrontend(data),
      topology: TopologyMapper.mapToFrontend(data, startYear, endYear),
      loads: LoadsMapper.mapToFrontend(data),
      equipments: EquipmentsMapperOld.mapToFrontend(data, startYear, endYear),
      regulations: RegulationsMapper.mapToFrontend(data, startYear, endYear),
      commodities: CommoditiesMapper.mapToFrontend(data, startYear, endYear),
      mobility: MobilityMapper.mapToFrontend(data.mobility),
      frequencyControl: FrequencyControlMapper.mapToFrontend(
        data,
        startYear,
        endYear,
      ),
      optimizationCockpit: OptimizationCockpitMapper.mapToFrontend(
        data,
        startYear,
        endYear,
      ),
      netting: NettingMapper.mapToFrontEnd(data),

      // Others
      copyStatus: data.duplicateStatus,
      run: RunMapMapper.mapToFrontend(data),
      outputFile: Utils.resolveToEmptyObject(data.run).outputFile,
      mergeResults: data.mergedResults,

      // For Sorting
      name: data.name,
      status:
        !(data.run || {}).status ||
        data.run.status === EXECUTION_STATUS.NOT_READY
          ? EXECUTION_STATUS.DRAFT
          : (data.run || {}).status,
      launchDate: data.launchDate,
      duration: data.run,

      // For Conflict Handling
      updatedBy: data.updatedBy,
      updatedById: data.updatedById,
    };
  }

  mapScenarioVariationToFrontend(data: any): ScenarioVariationMap {
    return {
      variationId: data.id,
      name: data.name,
      run: RunMapMapper.mapToFrontend(data),
    };
  }

  mapScenariosAndVariationToFrontend(
    data: any,
  ): Scenario | ScenarioVariationMap {
    if (!data) {
      return {};
    }

    return {
      id: data.scenarioUuid,
      variationId: data.dataType === 'variation' ? data.id : undefined,
      projectId: data.projectUuid,
      caseId: data.caseUuid,
      name: data.name,
    };
  }

  getMultiValueParams(
    params: Dictionary<string | string[]>,
    props: string[],
  ): Dictionary<string | string[]> {
    const multiValueParams = {};
    props.forEach((prop) => {
      if (params[prop]) {
        multiValueParams[prop] = params[prop];
      }
    });
    return multiValueParams;
  }
}
