import cytoscape from 'cytoscape';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

import {
  Directive,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';

import { Base, FluidConfig, NodeViewData, OverviewData } from '../models';
import {
  CUSTOM_FLUID_COLOR_PALETTE,
  DEFAULT_CUSTOM_FLUID_COLOR_PALETTE,
  DEFAULT_PREDEFINED_FLUID_LABEL_CONFIG,
  DEFAULT_SYS_VIS_FLUID_CONFIG,
  PREDEFINED_FLUID_LABEL_CONFIG,
  SYS_VIS_FLUID_CONFIG,
} from '../system-visualization.token';
import { getFluidConfigDataForCustomVectors } from '../utils';

@Directive()
export abstract class BaseContainerComponent<
  T extends OverviewData | NodeViewData,
> extends BaseComponent {
  // The input data with setter/getter
  _data: T;
  @Input() set data(value: T) {
    this._data = value;
  }
  get data() {
    return this._data;
  }

  // Emit event when an element is tapped
  @Output() elementTapped = new EventEmitter<
    cytoscape.EdgeDataDefinition | cytoscape.NodeDataDefinition
  >();

  fluidConfigData: FluidConfig = {}; // The fluid configuration data for colors, icons, and label

  showOptimized$ = new BehaviorSubject<boolean>(true); // Show optimized filter
  selectedFluids$ = new BehaviorSubject<Array<string>>(undefined); // Selected fluids from the legends component

  constructor(
    @Optional()
    @Inject(SYS_VIS_FLUID_CONFIG)
    public fluidConfig: FluidConfig = DEFAULT_SYS_VIS_FLUID_CONFIG,
    @Optional()
    @Inject(PREDEFINED_FLUID_LABEL_CONFIG)
    public fluidLabelConfig: {
      [key: string]: string;
    } = DEFAULT_PREDEFINED_FLUID_LABEL_CONFIG,
    @Optional()
    @Inject(CUSTOM_FLUID_COLOR_PALETTE)
    public customFluidColorPalette: Array<string> = DEFAULT_CUSTOM_FLUID_COLOR_PALETTE,
  ) {
    super();
  }

  /**
   * Get the fluidConfig for predefined and custom energy vectors.
   *
   * @param lines - list of LineData
   * @param field - the field representing the fluid
   */
  getFluidConfig<U>(
    lines: Array<U>,
    field: 'energyVector' | 'fluid' = 'fluid',
  ) {
    if (!lines || lines.length === 0) {
      return this.fluidConfig;
    }
    const customEvSet = new Set<string>(
      lines
        .map((line) => line[field])
        .filter((ev) => !!!this.fluidLabelConfig[ev]),
    );
    return {
      ...this.fluidConfig,
      ...getFluidConfigDataForCustomVectors(
        customEvSet,
        this.customFluidColorPalette,
      ),
    };
  }

  /**
   * Filters the fluids based on the data and configuration
   *
   * @param lines - the list of lines
   * @param field - the field representing the fluid
   */
  filterFluids<U>(lines: Array<U>, field: 'energyVector' | 'fluid' = 'fluid') {
    if (!lines || !field) {
      return [];
    }

    const predefinedEvSet = new Set(
      lines
        .map((line) => line[field])
        .filter((ev) => !!this.fluidLabelConfig[ev]),
    );
    const customEvSet = new Set(
      lines
        .map((line) => line[field])
        .filter((ev) => !this.fluidLabelConfig[ev]),
    );
    return [...predefinedEvSet, ...customEvSet].map(
      (name) => this.fluidConfigData[name],
    );
  }

  /**
   * Returns T elements that are hideable
   *
   * @param elements - the elements to filter
   */
  filterNonOptimizedElements<U extends Base>(elements: Array<U>): Array<U> {
    if (!elements) {
      return elements;
    }
    return elements.filter((line) => line.hideable);
  }

  /**
   * Returns T elements that are not hideable
   *
   * @param elements - the elements to filter
   */
  filterOptimizedElements<U extends Base>(elements: Array<U>): Array<U> {
    if (!elements) {
      return elements;
    }
    return elements.filter((line) => !line.hideable);
  }

  /**
   * Tags hideable T elements to be hidden
   *
   * @param elements - the elements to transform
   */
  hideNonOptimizedElements<U extends Base>(elements: Array<U>): Array<U> {
    if (!elements) {
      return elements;
    }
    return [
      ...this.filterOptimizedElements(elements),
      ...this.filterNonOptimizedElements(elements).map((element) => ({
        ...(element as any),
        hidden: true,
      })),
    ];
  }

  /**
   * Tags hideable T elements to be shown
   *
   * @param elements - the elements to transform
   */
  showNonOptimizedElements<U extends Base>(elements: Array<U>): Array<U> {
    if (!elements) {
      return elements;
    }
    return [
      ...this.filterOptimizedElements(elements),
      ...this.filterNonOptimizedElements(elements).map((element) => ({
        ...(element as any),
        hidden: false,
      })),
    ];
  }

  /**
   * Toggles the optimized elements by hiding or showing them based on the parameters
   *
   * @param data - the overview data
   * @param showOptimized - the flag to show or hide the optimized lines
   * @param field - the field representing the list of elements from the data
   */
  toggleOptimizedElements(
    data: T,
    showOptimized: boolean,
    field: 'lines' | 'assets' | 'assetLinks',
  ): T {
    if (!data || !field) {
      return data;
    }
    const elements = showOptimized
      ? this.hideNonOptimizedElements(data[field])
      : this.showNonOptimizedElements(data[field]);
    const newData = { ...(data as any) };
    newData[field] = elements;
    return { ...newData };
  }

  /**
   * Toggles the optimized solution flag
   */
  onToggleOptimizedSolution() {
    this.showOptimized$.next(!this.showOptimized$.value);
  }

  /**
   * Emits selected fluids based on the parameter
   *
   * @param fluids - the fluids
   */
  onSelectedFluidsChange(fluids: Array<string>) {
    this.selectedFluids$.next(fluids);
  }

  /**
   * Updates the fluid configuration data
   *
   * @param lines - the array of lines
   */
  updateFluidConfigData<U>(
    lines: Array<U>,
    field: 'energyVector' | 'fluid' = 'fluid',
  ) {
    this.fluidConfigData = this.getFluidConfig(lines, field);
  }
}
