<div class="slide-toogle-container" fxLayout="row" fxLayoutAlign="start center">
  <div class="label">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-progress-bar
      class="slider-progress-bar"
      color="accent"
      mode="indeterminate"
      *ngIf="loading"
    >
    </mat-progress-bar>
  </div>
  <mat-icon
    aria-hidden="false"
    *ngIf="tooltipMessage"
    class="eyes-input-tooltip-anchor"
    matSuffix
    [prosumerTooltip]="tooltipMessage | translate"
    data-testid="prosumer-slide-toggle-tooltip"
  >
    help
  </mat-icon>
  <mat-slide-toggle
    [hideIcon]="true"
    [formControl]="control"
  ></mat-slide-toggle>
</div>
<mat-error
  *ngFor="let error of getErrors(control?.errors)"
  [innerHtml]="_errorMessageMap[error]"
>
</mat-error>
