import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { TooltipModule } from '../../../libs/eyes-shared/modules/tooltip/tooltip.module';
import { TooltipButtonComponent } from './tooltip-button.component';

@NgModule({
  declarations: [TooltipButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, TooltipModule],
  exports: [TooltipButtonComponent],
})
export class TooltipButtonModule {}
