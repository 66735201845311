<button
  class="eyes-button"
  mat-flat-button
  [type]="behavior"
  [color]="color"
  [disabled]="disabled"
  [routerLink]="link"
  (click)="onClick($event)"
  [matTooltip]="tooltip"
  [matTooltipShowDelay]="tooltipDelay"
>
  {{ label }}
</button>
