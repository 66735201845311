import { Injectable } from '@angular/core';
import { filter, from } from 'rxjs';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';

@Injectable()
export class UpdateNotificationService {
  constructor(private _update: SwUpdate) {}

  availableUpdate$ = this._update.versionUpdates.pipe(
    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
  );

  forceUpdate() {
    return from(this._update.activateUpdate());
  }

  checkUpdate() {
    return from(this._update.checkForUpdate());
  }
}
