import { Utils } from 'prosumer-app/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioInfoService } from './scenario-info';

@Injectable()
export class ScenarioStepperResolverService {
  constructor(
    private readonly keeper: ActiveKeeperService,
    private readonly info: ScenarioInfoService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { scenarioId } = Utils.resolveToEmptyObject(route.parent.params);
    const { scenarioTab } = Utils.resolveToEmptyObject(route.queryParams);
    this.keeper.setActive({ scenario: scenarioId });
    this.keeper.setActiveTab(scenarioTab);
    this.info.getScenario().subscribe();
  }
}
