import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteHistoryService {
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event: NavigationEnd) => {
      this.routerEventsHandler(event);
    });
  }

  private routerEventsHandler(event: any) {
    if (event instanceof NavigationEnd) {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    }
  }

  get previousRoute(): string | undefined {
    return this.previousUrl;
  }
}
