import { User } from 'prosumer-app/+project/models';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  CustomValidators,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { UserPermissions } from 'prosumer-shared/models';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@oculus/auth/amplify';

import { ProjectFacadeService } from '../../../../+project/state/project-facade.service';
import { EmailValidator } from '../../../validators';

export const MAX_SHARED_WITH_EMAILS = 30;
@Component({
  selector: 'prosumer-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDialogComponent extends BaseComponent implements OnInit {
  public CAN_VIEW = UserPermissions.CAN_VIEW;
  public CAN_EDIT = UserPermissions.CAN_EDIT;
  readonly _shareTo = signal<any[]>([]);
  private readonly _sharedWith$ = new BehaviorSubject<any>([]);
  private _shareToPermissions$ = new BehaviorSubject<UserPermissions>(
    this.CAN_VIEW,
  );
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE, SEMICOLON];

  sharingForm = this._formService.initForm({ emailAddresses: [[]] });

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  showSharedWithList = false;

  loading$ = this._userService.entityFetching$.pipe(this.takeUntilShare());

  shareTo$ = toObservable(this._shareTo).pipe(this.takeUntilShare());

  sharedToLength$ = this.shareTo$.pipe(
    map((sharedTo) => ((sharedTo as Array<any>) || []).length),
    this.takeUntilShare(),
  );

  hasSharedTo$ = this.sharedToLength$.pipe(
    map((sharedToLength) => sharedToLength > 0),
    this.takeUntilShare(),
  );

  sharedWith$ = this._sharedWith$.asObservable().pipe(this.takeUntilShare());

  sharedWithLength$ = this.sharedWith$.pipe(
    map((sharedWith) => ((sharedWith as Array<any>) || []).length),
    this.takeUntilShare(),
  );

  sharedWithPhrase$ = this.sharedWith$.pipe(
    map((sharedWith) =>
      (sharedWith as Array<any>).map((item) => item.fullName).join(', '),
    ),
    takeUntil(this.componentDestroyed$),
    shareReplay(1),
  );

  emailUsers$ = this._userService.emailUsers$.pipe(this.takeUntilShare());
  availableUsers$ = this._userService.dataList$.pipe(this.takeUntilShare());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ShareDialogComponent>,
    private _formService: FormService,
    private authService: AuthService,
    private _userService: UserFacadeService,
    private _projectFacade: ProjectFacadeService,
  ) {
    super();
  }

  userEmail: string;
  inputEmail: string;

  ngOnInit() {
    const sharedTo: Array<User> =
      ((this.data || {}).project || {}).sharedTo || [];
    // check user input
    this.authService.user$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.userEmail = data?.attributes?.email || '';
      });

    // Request user info for every single UUID!
    sharedTo.forEach((user) => this._userService.get(user.id));
    // this._sharedWith$.next(sharedTo);

    // TODO Add async validator for checking if emails are valid from backend
    this.sharingForm.controls.emailAddresses.setValidators([
      CustomValidators.emails(),
      CustomValidators.maxCountReached(MAX_SHARED_WITH_EMAILS, sharedTo.length),
      EmailValidator.userEmailExistence(),
      CustomValidators.emailUsage(this.userEmail, this.inputEmail),
      EmailValidator.userAlreadyShared(this._sharedWith$),
    ]);

    this.shareTo$.subscribe((shareTo) => {
      const emails = shareTo.map((user) => user.email);
      this.sharingForm.controls.emailAddresses.patchValue(emails);
    });

    this.loading$.subscribe((loading) => {
      if (loading) {
        this.sharingForm.disable();
      } else {
        this.sharingForm.enable();
      }
    });

    this.emailUsers$.subscribe((emailUsers) => {
      const inputtedEmails = this._shareTo().map((user: User) => user.email);
      this._shareTo.set(
        emailUsers.filter((emailUser) =>
          inputtedEmails.includes(emailUser.email),
        ),
      );
    });

    this.availableUsers$.subscribe((idUsers) => {
      const sharedWith = this._sharedWith$
        .getValue()
        .map((user: User) => user.id);
      const currentlySharingIDs = sharedWith.length
        ? sharedWith
        : sharedTo.map((user: User) => user.id);
      const userWithPermission = idUsers
        .filter((idUser) => currentlySharingIDs.includes(idUser.id))
        .map((users: User) => ({
          ...users,
          permissions: this.getDataSharedToPermission(users.id),
        }));
      this._sharedWith$.next(userWithPermission);
    });
  }

  private sharedUserMapper(users = []) {
    return users.map((user: User) => ({
      id: user.id,
      permissions: user?.permissions || this.CAN_VIEW,
    }));
  }

  getNameInitials({ firstName, lastName }) {
    return firstName && lastName ? `${firstName[0]}${lastName[0]}` : '';
  }

  getDataSharedToPermission(userId) {
    const { sharedTo } = this.data.project;
    return (
      sharedTo.find((data) => data.id === userId)?.permissions || this.CAN_VIEW
    );
  }

  addEmail(event: MatChipInputEvent): void {
    /**
     * Function to handle when a user successfully inputs an email address once
     * a delimiter has been pressed
     */
    const value = (event.value || '').trim();
    if (value) {
      this.inputEmail = value;
      this._shareTo.update((users) => [...users, { email: value }]);
      this._userService.getByEmail(value);
    }
    event.chipInput.clear();
  }

  private projectShare(users) {
    this._projectFacade.share(this.data.project, users);
    this.sharingForm.controls.emailAddresses.setValue([]);
    this._shareTo.set([]);
  }

  shareToUsers() {
    const shareTo = this._shareTo().map((data: User) => ({
      ...data,
      permissions: this._shareToPermissions$.getValue(),
    }));
    const sharedWith = this._sharedWith$.getValue();
    this._sharedWith$.next([...shareTo, ...sharedWith]);
    this.projectShare([
      ...this.sharedUserMapper(shareTo),
      ...this.sharedUserMapper(sharedWith),
    ]);
  }

  removeEmail(email: string): void {
    /**
     * Function to handle when a user removes an inputted email address when
     * the cancel was clicked
     */
    this._shareTo.update((users) =>
      users.filter((user) => user.email != email),
    );
  }

  onCloseOrCancel() {
    this._dialogRef.close();
  }

  onClickSharedToPhrase() {
    this.showSharedWithList = true;
  }

  onChangePermission(userId, permissions) {
    const currentlySharedWith = this._sharedWith$.getValue().map((item) => {
      if (item.id === userId) {
        return {
          ...item,
          permissions,
        };
      }
      return item;
    });

    this._sharedWith$.next(currentlySharedWith);
    this.projectShare(this.sharedUserMapper(currentlySharedWith));
  }

  shareToChangePermission(permissions) {
    this._shareToPermissions$.next(permissions);
  }

  onUnshare(user: any) {
    const currentlySharedWith = this._sharedWith$
      .getValue()
      .filter((item) => item.id !== user.id);
    this._sharedWith$.next([...currentlySharedWith]);
    this.projectShare(this.sharedUserMapper(currentlySharedWith));
  }
}
