import { Action } from '@ngrx/store';
import { Credentials, Error, type } from 'prosumer-app/libs/eyes-shared';

import { AuthData } from './auth.model';

export const authFeatureName = 'auth';

export const AuthActionTypes = {
  LOGIN: type(`[${authFeatureName}] Login`),
  LOGIN_FAILURE: type(`[${authFeatureName}] Login Failure`),
  LOGIN_SUCCESS: type(`[${authFeatureName}] Login Success`),
  LOGOUT: type(`[${authFeatureName}] Logout`),
  LOGOUT_FAILURE: type(`[${authFeatureName}] Logout Failure`),
  LOGOUT_SUCCESS: type(`[${authFeatureName}] Logout Success`),
  SET_REDIRECT_URL: type(`[${authFeatureName}] Set Redirect URL`),
  SET_AS_LOGGED_IN: type(`[${authFeatureName}] Set as Logged In`),
  SET_AS_LOGGED_IN_FAILURE: type(
    `[${authFeatureName}] Set as Logged In Failure`,
  ),
  SET_AS_LOGGED_IN_SUCCESS: type(
    `[${authFeatureName}] Set as Logged In Success`,
  ),
  REFRESH_TOKEN: type(`[${authFeatureName}] Refresh Token`),
  REFRESH_TOKEN_FAILURE: type(`[${authFeatureName}] Refresh Token Failure`),
  REFRESH_TOKEN_SUCCESS: type(`[${authFeatureName}] Refresh Token Success`),
};

export class AuthLoginAction implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: Credentials) {}
}

export class AuthLoginFailureAction implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: Error) {}
}

export class AuthLoginSuccessAction implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: AuthData) {}
}

export class AuthLogoutAction implements Action {
  readonly type = AuthActionTypes.LOGOUT;
  constructor(public payload?: AuthData) {}
}

export class AuthLogoutFailureAction implements Action {
  readonly type = AuthActionTypes.LOGOUT_FAILURE;
  constructor(public payload: Error) {}
}

export class AuthLogoutSuccessAction implements Action {
  readonly type = AuthActionTypes.LOGOUT_SUCCESS;
}

export class AuthSetRedirectURLAction implements Action {
  readonly type = AuthActionTypes.SET_REDIRECT_URL;
  constructor(public payload: string) {}
}

export class AuthSetAsLoggedInAction implements Action {
  readonly type = AuthActionTypes.SET_AS_LOGGED_IN;
  constructor(public payload: AuthData) {}
}

export class AuthSetAsLoggedInFailureAction implements Action {
  readonly type = AuthActionTypes.SET_AS_LOGGED_IN_FAILURE;
  constructor(public payload: AuthData) {}
}

export class AuthSetAsLoggedInSuccessAction implements Action {
  readonly type = AuthActionTypes.SET_AS_LOGGED_IN_SUCCESS;
  constructor(public payload: AuthData) {}
}

export class AuthRefreshTokenAction implements Action {
  readonly type = AuthActionTypes.REFRESH_TOKEN;
  constructor(public payload: AuthData) {}
}

export class AuthRefreshTokenFailureAction implements Action {
  readonly type = AuthActionTypes.REFRESH_TOKEN_FAILURE;
  constructor(public payload: AuthData) {}
}

export class AuthRefreshTokenSuccessAction implements Action {
  readonly type = AuthActionTypes.REFRESH_TOKEN_SUCCESS;
  constructor(public payload: AuthData) {}
}

export type AuthActions =
  | any
  | AuthLoginAction
  | AuthLoginFailureAction
  | AuthLoginSuccessAction
  | AuthLogoutAction
  | AuthLogoutFailureAction
  | AuthLogoutSuccessAction
  | AuthSetAsLoggedInAction
  | AuthSetAsLoggedInFailureAction
  | AuthSetAsLoggedInSuccessAction
  | AuthSetRedirectURLAction
  | AuthRefreshTokenAction
  | AuthRefreshTokenFailureAction
  | AuthRefreshTokenSuccessAction;
