import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/* Angular Specific Third-party Modules */
@NgModule({
  exports: [
    TranslateModule,
    NgxChartsModule,
    NgxSliderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSkeletonLoaderModule,
  ],
  imports: [NgxMaskDirective, NgxMaskPipe],
  providers: [provideNgxMask()],
})
export class NgxModuleModule {}
