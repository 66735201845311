import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  FormFieldErrorMessageMap,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';

@Component({
  selector: 'prosumer-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() tooltip = 'TBD';
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessageMap) {
    this._errorMessageMap = errorMessageMap;
    if (!this._errorMessageMap) {
      this._errorMessageMap = {};
    }
  }
  _errorMessageMap: FormFieldErrorMessageMap = {};
  onChange = (value: boolean) => {};

  writeValue(value: any): void {
    this.control.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}

  ngOnInit() {
    this.control.valueChanges.subscribe((v) => {
      this.onChange(v);
    });
  }

  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
