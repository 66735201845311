import moment from 'moment';
import { CompareDispatchResultItem } from 'prosumer-app/+scenario/models';
import { STORAGE_TYPE } from 'prosumer-app/app.references';
import { dayUtils } from 'prosumer-app/shared';
import { PipeUtils } from 'prosumer-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchComparison } from '../adapters';
import {
  CompareDispatchChartData,
  CompareDispatchChartSeriesData,
  CompareDispatchData,
} from '../compare-dispatch.model';
import { groupDataSetByDayAndHour } from '../utils';

@Component({
  selector: 'prosumer-compare-weekly-storage',
  templateUrl: './weekly-storage.component.html',
  styleUrls: ['./weekly-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareWeeklyStorageComponent
  extends StorageDispatchComparison
  implements AfterViewInit, OnInit
{
  options: string[]; // The equipent control options
  equipmentControl = new UntypedFormControl();
  data$ = new BehaviorSubject<CompareDispatchData>(null); // the dispatch data

  @Input() customColors: any;

  /**
   * Setter for data where the equipment options and the chart data will be derived
   *
   * @param value - the compare dispatch data
   */

  @Input() set data(value: CompareDispatchData) {
    if (!!value && !!value.dataList) {
      this.options = this.getEquipmentOptions(value.dataList);
      this.data$.next(value);
    }
  }

  // Observable for the scenario name derived from the dispatch data
  name$ = this.data$.pipe(
    filter((data) => !!data),
    pluck('name'),
    this.takeUntilShare(),
  );

  // Observable for the chart data derived from the dispatch data and the selected equipment
  ngxChartData$ = combineLatest([
    this.data$,
    this.equipmentControl.valueChanges,
  ]).pipe(
    filter(([data, equipment]) => !!data && !!equipment),
    map(([data, equipment]) =>
      this.mapToChartData(data.dataList, data.start, equipment),
    ),
    this.takeUntilShare(),
  );

  chartDataLength$ = this.ngxChartData$.pipe(
    filter((chartData) => !!chartData),
    pluck('length'),
    this.takeUntilShare(),
  );

  tooltipDisabled$ = this.chartDataLength$.pipe(
    map((length) => length <= 0),
    this.takeUntilShare(),
  );

  constructor(
    private _translate: TranslateService,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngAfterViewInit(): void {
    if (!!this.options && this.options.length > 0) {
      this.equipmentControl.patchValue(this.options[0]);
    }
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  /**
   * Filter the resultItems parameter by equipment parameter then map to the chart data
   *
   * @param resultItems - the list of dispatch result items
   * @param equipment   - the equipment for filtering the resultItems
   * @param resultType  - the type of result (default: {@link STORAGE_TYPE})
   * @returns list of mapped chart-compatible data {@link CompareDispatchChartData}
   */
  mapToChartData(
    resultItems: CompareDispatchResultItem[],
    start: number,
    equipment: string,
    resultType: string = STORAGE_TYPE,
  ): CompareDispatchChartData[] {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }

    // Filter by equipment and result type
    const storageData = resultItems.find(
      (value) =>
        value.equipment === equipment && value.resultType === resultType,
    );

    if (
      !storageData ||
      !storageData.values ||
      storageData.values.length === 0
    ) {
      return [];
    }

    // Generate the series data list based on the values and year
    const seriesDataList = this.generateSeriesDataList(
      storageData.values,
      start,
      storageData.year,
    );

    // Group the generated series data list by day and hour
    const groupedSeriesDataList = groupDataSetByDayAndHour(seriesDataList);
    const series = [];
    Array.from(Array(168).keys()).forEach((i) => {
      const weeklyDataSet = groupedSeriesDataList.filter(
        (data) => data && data.newIndex === i + 1,
      );
      if (weeklyDataSet.length > 0) {
        const weeklyData = weeklyDataSet
          .map((data) => ({
            ...data,
            name: '' + data.newIndex,
          }))
          .reduce((prev, curr) => ({ ...curr }));
        series.push({
          ...weeklyData,
          series: [this.mapSeriesDataToChart(weeklyData.average)],
        });
      } else {
        series.push({
          hour: i,
          average: 0.0,
          day: Math.trunc((i + 1) / 24),
          name: (i + 1).toString(),
          newIndex: i + 1,
          series: [this.mapSeriesDataToChart(0.0)],
        });
      }
    });

    return series;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  mapSeriesDataToChart(value: number): {} {
    return {
      name: value < 0 ? 'Storage Discharge' : 'Storage Charge',
      value,
    };
  }

  /**
   * Get the equipment unique options based on the result items
   *
   * @param resultItems - the result items
   * @returns list of string resembling equipment options
   */
  getEquipmentOptions(resultItems: CompareDispatchResultItem[]): string[] {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }
    return [
      ...new Set(
        resultItems
          .map((mapValue) => mapValue.equipment)
          .filter((filterValue) => !!filterValue),
      ),
    ];
  }

  generateSeriesDataList(
    values: number[],
    start?: number,
    year?: string,
  ): CompareDispatchChartSeriesData[] {
    if (!values || values.length === 0) {
      return [];
    }
    const output = values.map((value, index) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hour: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .hour(index + (start - 1) * 24)
        .get('h'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      moment: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      }).hour(index),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      day: moment({
        y: coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .hour(index + (start - 1) * 24)
        .get('d'),
      value: value * -1, // Negate value as per requirement based on PR-2845
    }));

    return output;
  }

  formatXAxisTickLabel = (value: any) => value;

  formatTooltipTitle = (obj: any) =>
    obj
      ? `${this._translate.instant('Result.labels.hour')} ${
          (obj || {}).series || ''
        }`.trim()
      : null;

  formatTick(tick: string): string {
    return dayUtils.prependDay(Number(tick));
  }
  getChartName(): string {
    return `Weekly Storage Dispatch - ${this.equipmentControl.value} (${this.data$.value?.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.ngxChartData$.pipe(PipeUtils.filterOutEmpty) as Observable<
      VisualizerData[]
    >;
  }
}
