import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { getKeys } from '../../../utilities';
import { FormFieldComponent } from '../form-field.component';
import { FormFieldErrorMessageMap, FormFieldOption } from '../form-field.model';

@Component({
  selector: 'prosumer-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends FormFieldComponent implements OnInit {
  @Input() validateInOptions: boolean = false;
  @Input() emitPatchValue: boolean = false;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() hint: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() placeholder: string;
  @Input() shouldDisplaySingle = true;
  @Input() options: Array<FormFieldOption<any>> = [];
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessageMap) {
    this._errorMessageMap = errorMessageMap;
    if (!this._errorMessageMap) {
      this._errorMessageMap = {};
    }
  }

  @Input() set disabled(disabled: boolean) {
    if (!this.control) {
      return;
    }
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  @Input() set value(value: string) {
    if (
      this.validateInOptions &&
      !this.options.find((option) => option.value == value)
    ) {
      this.control.reset();
      return;
    }
    if (this.control) {
      this.emitPatchValue
        ? this.control.patchValue(value, { emitEvent: true, onlySelf: false })
        : this.control.patchValue(value, { emitEvent: false });
    }
  }
  shouldDisplay = true;

  @Output() eyesSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  _errorMessageMap: FormFieldErrorMessageMap = {};

  constructor(@Optional() private _changeDetector?: ChangeDetectorRef) {
    super();
    this.floatLabel = 'auto';
    this.appearance = 'outline';
  }

  ngOnInit() {
    if (this.control) {
      this.addSubscription(
        this.control.statusChanges.subscribe(() =>
          this._changeDetector ? this._changeDetector.markForCheck() : true,
        ),
      );
    }

    if (!this.shouldDisplaySingle && this.options.length === 1) {
      this.shouldDisplay = this.shouldDisplaySingle;
      this.control.patchValue(this.options[0].value, { emitEvent: false });
    } else {
      this.shouldDisplay = true; //  Always true when options.length is more than 1
    }
  }

  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }

  onSelectionChange(change: MatSelectChange) {
    this.eyesSelectionChange.emit(change.value);
  }
}
