import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minsTimeConvert',
})
export class MinsTimeConvertPipe implements PipeTransform {
  transform(mins: number): string {
    if (mins) {
      const hours = mins / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);

      return `${rhours || 0}h ${rminutes || 0}m`;
    }

    return undefined;
  }
}
