import { MarkdownModule } from 'ngx-markdown';
import { CaseResolverService } from 'prosumer-app/+case/services/case-resolver.service';
import { ProjectResolverService } from 'prosumer-app/+project/services/project-resolver.service';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-shared';
import { EntityUpsertButtonModule } from 'prosumer-shared/components/entity-upsert-button';
import { UnsavedEntityBannerModule } from 'prosumer-shared/components/unsaved-entity-banner/unsaved-entity-banner.module';
import {
  TdbCommodityFiltersModule,
  TdbEmissionFactorsModule,
  TdbTechnologyFiltersModule,
} from 'prosumer-shared/modules/tdb/components';
import { TDBReferencePipeModule } from 'prosumer-shared/pipes/tdb-reference';

import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  ConverterComponent,
  EnergyGridConnectionsEmissionsFormDialogComponent,
  EnergyGridConnectionsFormDialogComponent,
  EnergyGridLimitDialogComponent,
  EnergyGridLimitIntervalComponent,
  EnergyGridLimitIntervalDialogComponent,
  EnergyVectorDialogComponent,
  EquipmentDialogComponent,
  FuelsFormDialogComponent,
  GeneratorComponent,
  LinesFormDialogComponent,
  LoadsDialogComponent,
  MessagesDialogComponent,
  MessagesLogTableComponent,
  MeteringDialogComponent,
  MobilityRoutesFormDialogComponent,
  NettingFormDialogComponent,
  OperatingCostDialogComponent,
  OperatingCostTableComponent,
  ReserveActivationFormDialogComponent,
  ReserveEquipmentFormDialogComponent,
  ReserveMarginFormDialogComponent,
  ReserveMarketFormDialogComponent,
  ReserveNetworkFormDialogComponent,
  ScenarioCompareDialogComponent,
  ScenarioDuplicateDialogComponent,
  SCEquipmentFormDialogComponent,
  ScPowerNeededFormDialogComponent,
  StationComponent,
  StorageComponent,
  TaxSubsidyFormDialogComponent,
  TaxSubsidyIntervalComponent,
  TaxSubsidyIntervalDialogComponent,
  TdbCommodityPricesModule,
  TimeHorizonDialogComponent,
  VehicleComponent,
  VehiclesDispatchFormDialogComponent,
  VehicleStationFormDialogComponent,
  VREComponent,
} from './components';
import { EgcUpsertTriggerModule } from './components/egc-upsert-trigger';
import { MarketLimitUpsertTriggerModule } from './components/market-limit-upsert-trigger';
import { NettingUpsertTriggerModule } from './components/netting-form/netting-upsert-trigger';
import { TaxubUpsertTriggerModule } from './components/netting-form/taxub-upsert-trigger/taxub-upsert-trigger.module';
import { OpcostsUpsertTriggerModule } from './components/opcosts-upsert-trigger';
import { TdbRenewablesModule } from './components/tdb-renewables';
import { OpCostsGetterModule } from './directives/op-costs-getter';
import { OpCostsListerModule } from './directives/op-costs-lister';
import { ProjectDurationDetectorModule } from './directives/project-duration-detector';
import { ScenarioApiService, ScenarioCompareApiService } from './services';
import { ScenarioResolverService } from './services/scenario-resolver.service';
import { ScenarioStepperResolverService } from './services/scenario-stepper-resolver.service';
import {
  ScenarioCompareEffects,
  ScenarioCompareFacadeService,
  scenarioCompareFeature,
  scenarioCompareReducer,
  ScenarioEffects,
  ScenarioFacadeService,
  scenarioFeature,
  scenarioReducer,
} from './state';

const moduleMembers = [
  TimeHorizonDialogComponent,
  EnergyVectorDialogComponent,
  LinesFormDialogComponent,
  LoadsDialogComponent,
  MeteringDialogComponent,
  EquipmentDialogComponent,
  VREComponent,
  ConverterComponent,
  StorageComponent,
  GeneratorComponent,
  VehicleComponent,
  VehiclesDispatchFormDialogComponent,
  VehicleStationFormDialogComponent,
  StationComponent,
  /* Commodities */
  EnergyGridLimitDialogComponent,
  EnergyGridLimitIntervalComponent,
  EnergyGridLimitIntervalDialogComponent,
  EnergyGridConnectionsFormDialogComponent,
  EnergyGridConnectionsEmissionsFormDialogComponent,
  FuelsFormDialogComponent,
  /* Frequency Control */
  // Short Circuit
  ScPowerNeededFormDialogComponent,
  SCEquipmentFormDialogComponent,
  // Spinning Reserves
  ReserveMarginFormDialogComponent,
  ReserveActivationFormDialogComponent,
  ReserveEquipmentFormDialogComponent,
  ReserveMarketFormDialogComponent,
  ReserveNetworkFormDialogComponent,
  MobilityRoutesFormDialogComponent,
  /* Simulation Message */
  MessagesDialogComponent,
  MessagesLogTableComponent,
  /* Scenario Compare */
  ScenarioCompareDialogComponent,
  ScenarioDuplicateDialogComponent,
  /* Operating Cost */
  OperatingCostTableComponent,
  OperatingCostDialogComponent,
  /*Netting */
  NettingFormDialogComponent,
  TaxSubsidyIntervalComponent,
  TaxSubsidyFormDialogComponent,
  TaxSubsidyIntervalDialogComponent,
];

@NgModule({
  imports: [
    StoreModule.forFeature(scenarioFeature, scenarioReducer),
    StoreModule.forFeature(scenarioCompareFeature, scenarioCompareReducer),
    EffectsModule.forFeature([ScenarioEffects, ScenarioCompareEffects]),
    EyesSharedModule.forFeature(),
    SharedModule,
    UnsavedEntityBannerModule,
    MarkdownModule.forChild(),
    OpCostsGetterModule,
    OpCostsListerModule,
    OpcostsUpsertTriggerModule,
    LetDirective,
    PushPipe,
    EgcUpsertTriggerModule,
    MarketLimitUpsertTriggerModule,
    NettingUpsertTriggerModule,
    TaxubUpsertTriggerModule,
    EntityUpsertButtonModule,
    ProjectDurationDetectorModule,
    TdbCommodityPricesModule,
    TdbRenewablesModule,
    TdbEmissionFactorsModule,
    TdbTechnologyFiltersModule,
    TDBReferencePipeModule,
    TdbCommodityFiltersModule,
  ],
  declarations: [...moduleMembers, ScenarioCompareDialogComponent],
  providers: [
    ScenarioApiService,
    ScenarioFacadeService,
    ScenarioCompareApiService,
    ScenarioCompareFacadeService,
    ScenarioResolverService,
    ProjectResolverService,
    CaseResolverService,
    ScenarioStepperResolverService,
  ],
})
export class ScenarioRootModule {}
