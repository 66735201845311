export enum ProsumerView {
  case = 'case',
  general = 'scenario_general',
  timeHorizon = 'scenario_timeHorizon',
  topology = 'scenario_topology',
  loads = 'scenario_loads',
  equipments = 'scenario_equipment',
  commodities = 'scenario_commodities',
  mobility = 'scenario_mobility',
  reserves = 'scenario_reserves',
  netting = 'scenario_netting',
  regulation = 'scenario_regulation',
  optimizationCockpit = 'scenario_optimizationCockpit',
  launch = 'scenario_launch',
}
