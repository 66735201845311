<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="false"
  [animations]="animations"
>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g>
      <svg:g *ngFor="let series of results; trackBy: trackBy">
        <svg:g
          [class.stroke-none]="
            series.series[0].value === '' || series.series[1].value === ''
          "
          ngx-charts-line-series
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [animations]="animations"
        />
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
