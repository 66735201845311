import { UserEntity } from './user-entity.model';

export enum RetrievalType {
  ID,
  EMAIL,
}

export interface User extends UserEntity {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
  readonly imageUrl?: string;
  readonly retrievedUsing?: RetrievalType;
  readonly isClient?: boolean;
  readonly userSessionId?: string;
  readonly fullName?: string;
  readonly preferences?: any;
}
