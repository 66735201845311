import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  ProfileComputationType,
  RenewableProfileComputation,
} from 'prosumer-app/+renewableprofile/models';
import {
  BaseComponent,
  ColumnDefinition,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  generateUuid,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prosumer-profile-computation-option',
  templateUrl: './profile-computation-option.component.html',
  styleUrls: ['./profile-computation-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComputationOptionComponent
  extends BaseComponent
  implements AfterViewInit, OnInit
{
  type$ = new BehaviorSubject<ProfileComputationType>(
    ProfileComputationType.HISTORICAL,
  );

  @Output() computation = new EventEmitter<RenewableProfileComputation>();

  computationOptionForm: UntypedFormGroup = this.formBuilder.group({
    type: [ProfileComputationType.HISTORICAL, Validators.required],
    numberOfYears: [undefined, [Validators.required, Validators.min(1)]],
    lastHistoricalYear: [undefined, Validators.required],
    historicalYears: [],
    percentiles: [],
  });

  year: UntypedFormControl = this.formBuilder.control('');
  percentile: UntypedFormControl = this.formBuilder.control('');

  computationOptions: Array<FormFieldOption<any>> = [
    {
      name: ProfileComputationType.HISTORICAL,
      value: ProfileComputationType.HISTORICAL,
    },
    {
      name: ProfileComputationType.TMY,
      value: ProfileComputationType.TMY,
    },
    {
      name: ProfileComputationType.PERCENTILE,
      value: ProfileComputationType.PERCENTILE,
    },
  ];

  errorMessages: FormFieldErrorMessageMap = {
    listOfYears: {
      required: this.translate.instant(
        'RenewableProfile.messages.listOfYears.required',
      ),
    },
    numberOfYears: {
      required: this.translate.instant(
        'RenewableProfile.messages.numberOfYears.required',
      ),
      min: this.translate.instant(
        'RenewableProfile.messages.numberOfYears.min',
      ),
    },
    lastHistoricalYear: {
      required: this.translate.instant(
        'RenewableProfile.messages.lastHistoricalYear.required',
      ),
    },
    listOfPercentiles: {
      required: this.translate.instant(
        'RenewableProfile.messages.listOfPercentiles.required',
      ),
    },
  };

  yearErrorsMessages: FormFieldErrorMessageMap = {
    alreadyExists: this.translate.instant(
      'RenewableProfile.messages.yearExist',
    ),
    notANumber: this.translate.instant('RenewableProfile.messages.notANumber'),
    min: this.translate.instant('RenewableProfile.messages.year.min'),
    max: this.translate.instant('RenewableProfile.messages.year.max'),
    pattern: this.translate.instant('RenewableProfile.messages.year.pattern'),
  };

  percentileErrorsMessages: FormFieldErrorMessageMap = {
    validRange: this.translate.instant(
      'RenewableProfile.messages.percentile.betweenZeroAndOne',
    ),
    alreadyExists: this.translate.instant(
      'RenewableProfile.messages.percentile.percentileExist',
    ),
  };

  yearColumnDef: ColumnDefinition = {
    name: { name: 'Year', sortable: true },
    actions: { name: 'Actions', type: 'action' },
  };

  percentileColumnDef: ColumnDefinition = {
    name: { name: 'Percentile', sortable: true },
    actions: { name: 'Actions', type: 'action' },
  };

  yearsList$ = this.yearsDataService.listData$;
  percentilesList$ = this.percentilesDataService.listData$;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject('historicalYears')
    public yearsDataService: ManagedDataService<Years>,
    @Inject('percentiles')
    public percentilesDataService: ManagedDataService<Percentiles>,
  ) {
    super();
  }

  ngOnInit() {
    const historicalYears = this.yearsDataService.getListData();
    if (historicalYears.length > 0) {
      historicalYears.forEach((l) => this.yearsDataService.delete(l));
    }
    const percentiles = this.percentilesDataService.getListData();
    if (percentiles.length > 0) {
      percentiles.forEach((l) => this.percentilesDataService.delete(l));
    }

    this.year.setValidators([
      Validators.min(1970),
      Validators.max(new Date().getFullYear() - 1),
      Validators.pattern(/^[\d]*$/),
      this.yearConflictValidator(),
      CustomValidators.numberValidator,
    ]);

    this.percentile.setValidators([
      this.percentileRangeValidator(),
      this.percentileConflictValidator(),
    ]);

    this.computationOptionForm.controls.type.valueChanges.subscribe((type) => {
      this.type$.next(type);
    });
  }

  private emitForm(form: any) {
    const {
      type,
      numberOfYears,
      lastHistoricalYear,
      historicalYears,
      percentiles,
    } = form;
    this.computation.emit({
      type,
      numberOfYears:
        type === ProfileComputationType.TMY ? numberOfYears : undefined,
      lastHistoricalYear:
        type === ProfileComputationType.TMY ? lastHistoricalYear : undefined,
      historicalYears:
        type === ProfileComputationType.HISTORICAL
          ? historicalYears
            ? historicalYears.map((y) => y.name).join(';')
            : undefined
          : undefined,
      percentiles:
        type === ProfileComputationType.PERCENTILE
          ? percentiles
            ? percentiles.map((p) => p.name).join(';')
            : undefined
          : undefined,
    });
  }

  onAddYear(): void {
    const year: string = this.year.value;
    if (year) {
      const item = { name: year, value: generateUuid().substr(-5, 5) };
      this.yearsDataService.add(item);
    }
    this.year.setValue('');
  }

  onAddPercentile(): void {
    const percentile: string = this.percentile.value;
    if (percentile) {
      const item = { name: percentile, value: generateUuid().substr(-5, 5) };
      this.percentilesDataService.add(item);
    }
    this.percentile.setValue('');
  }

  onDeleteYear(year: Years) {
    this.yearsDataService.delete(year);
  }

  onDeletePercentile(percentile: Percentiles) {
    this.percentilesDataService.delete(percentile);
  }

  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }

  ngAfterViewInit() {
    this.yearsList$.subscribe((list: any) => {
      this.computationOptionForm.controls.historicalYears.patchValue(list);
    });
    this.percentilesList$.subscribe((list: any) => {
      this.computationOptionForm.controls.percentiles.patchValue(list);
    });
    this.computationOptionForm.valueChanges.subscribe((form) =>
      this.emitForm(form),
    );
  }

  yearConflictValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let doesItExist = false;
      if (this.computationOptionForm) {
        const name = this.year.value.toLowerCase().replace(/\s/g, '');
        if (
          this.computationOptionForm &&
          this.computationOptionForm.controls.historicalYears &&
          this.computationOptionForm.controls.historicalYears.value
        ) {
          for (const year of this.computationOptionForm.controls.historicalYears
            .value) {
            const yearname = year.name.toLowerCase().replace(/\s/g, '');
            if (yearname === name) {
              doesItExist = true;
              break;
            }
          }
        }
      }
      return doesItExist ? { alreadyExists: { value: control.value } } : null;
    };
  }

  percentileRangeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let invalidRange = false;
      if (this.computationOptionForm) {
        const percentile = this.percentile.value
          .toLowerCase()
          .replace(/\s/g, '');
        if (percentile) {
          invalidRange = 0 >= Number(percentile) || 1 <= Number(percentile);
        }
      }
      return invalidRange ? { validRange: { value: control.value } } : null;
    };
  }

  percentileConflictValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let doesItExist = false;
      if (this.computationOptionForm) {
        const name = this.percentile.value.toLowerCase().replace(/\s/g, '');
        if (
          name &&
          this.computationOptionForm &&
          this.computationOptionForm.controls.percentiles &&
          this.computationOptionForm.controls.percentiles.value
        ) {
          for (const year of this.computationOptionForm.controls.percentiles
            .value) {
            const percentileName = year.name.toLowerCase().replace(/\s/g, '');
            if (Number(percentileName) === Number(name)) {
              doesItExist = true;
              break;
            }
          }
        }
      }
      return doesItExist ? { alreadyExists: { value: control.value } } : null;
    };
  }
}
interface Years {
  name: string;
  value: string;
}
interface Percentiles {
  name: string;
  value: string;
}
