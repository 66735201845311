import { BaseEntityState } from 'prosumer-app/libs/eyes-shared';
import { IconButtonToggle } from 'prosumer-app/shared/components/icon-button-toggle';
import { CoherenceResult, Scenario } from 'prosumer-scenario';

export interface ScenarioState extends BaseEntityState<Scenario> {
  predefinedEnergyVectors?: Array<IconButtonToggle<string>>;
  uploading?: boolean;
  validating?: boolean;
  coherenceResults?: Array<CoherenceResult>;
  simulating?: boolean;
  launched?: boolean;
  selectedScenarioVariationId?: string;
  uploadingEntitiesStatus?: UploadingEntitiesStatusI;
}

export interface UploadingEntitiesStatusI {
  [name: string]: UploadingStatusEnum;
}

export enum UploadingStatusEnum {
  QUEUED = 'queued',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
