import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'prosumer-notification-toolbar',
  templateUrl: './notification-toolbar.component.html',
  styleUrls: ['./notification-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationToolbarComponent implements OnInit {
  @Input() notifData$: Observable<Array<any>>;
  @Output() markAsReadNotifs: EventEmitter<any> = new EventEmitter();
  @Output() seeMoreNotifs: EventEmitter<any> = new EventEmitter();
  @Output() notificationRouter: EventEmitter<any> = new EventEmitter();

  totalNotifCount = 0;
  unreadNotifCount = 0;
  dateTimeCurrent: number = Date.now();
  notifications: Array<any> = [];
  isBadgeVisible = false;
  notifsToShow: Array<any> = [];

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.notifData$.subscribe((data) => {
      this.notifications = data;
      this.totalNotifCount = this.notifications ? this.notifications.length : 0;
      this.getNotificationsToShow(data);
      this.countUnreadNotifications(data);
      this.updateBadge(this.unreadNotifCount);
      this._changeDetector.detectChanges();
    });
  }

  countUnreadNotifications(data: Array<any>) {
    this.unreadNotifCount = 0;
    let notifTemp = [];
    notifTemp = [...data].filter((item) => item.status === 'unread');
    this.unreadNotifCount = notifTemp.length;
  }

  getNotificationsToShow(data: Array<any>) {
    this.notifsToShow = [];
    let itemsTemp = [];
    itemsTemp = [...data].filter((item) => item.status === 'unread');
    if (itemsTemp.length > 0) {
      itemsTemp.slice(0, 5).map((item) => this.notifsToShow.push(item));
    } else {
      const notification = {
        body: 'You have no unread notifications.',
        dateTimeReceived: this.dateTimeCurrent,
        subject: 'System Info',
      };
      this.notifsToShow.push(notification);
    }
  }

  updateBadge(count: number) {
    this.isBadgeVisible = false;
    if (count > 0) {
      this.isBadgeVisible = true;
    }
  }

  markAsRead(event: UIEvent, notification: any, uuid: string) {
    this.markAsReadNotifs.emit([notification.id]);
    this.notificationRouter.emit([notification, uuid]);
  }

  markAllAsRead(event: UIEvent, notifications: Array<any>) {
    event.stopPropagation();
    this.markAsReadNotifs.emit(notifications.map((notif) => notif.id));
  }

  goToNotifsList(event: UIEvent): void {
    this.seeMoreNotifs.emit();
  }
}
