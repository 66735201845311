import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, map, tap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ProjectSharer } from 'prosumer-app/shared/services/project-sharer/project-sharer';
import {
  ShareViaLinkData,
  ShareViaLinkRsp,
} from 'prosumer-app/shared/services/project-sharer';
import { ProsumerRoutePathService } from 'prosumer-app/core/services/prosumer-route-path/prosumer-route-path.service';
import { FEATURES } from 'prosumer-app/app-routing.module';

const VIEW_DELAY = 10000;

@UntilDestroy()
@Component({
  selector: 'prosumer-shared-landing-page',
  templateUrl: './shared-landing-page.component.html',
  styleUrls: ['./shared-landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLandingPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private sharer: ProjectSharer,
    public routePath: ProsumerRoutePathService,
    public router: Router,
  ) {}

  loading$ = this.sharer.selectLoading().pipe(untilDestroyed(this));
  sharedInfo$ = new BehaviorSubject<ShareViaLinkRsp>(undefined);
  errorMsg$ = new BehaviorSubject<string>('');

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data) => data.query),
        switchMap((data: ShareViaLinkData) =>
          this.sharer.shareViaLink(data.projectId, data.token),
        ),
        tap((data) => {
          if (data.newlyGranted) {
            this.viewProjectDelay(data.projectUuid, VIEW_DELAY);
          } else {
            this.viewProjectDelay(data.projectUuid);
          }
        }),
        catchError((error) => {
          this.errorMsg$.next(error.error?.error ?? error.message);
          return of(undefined);
        }),
        take(1),
      )
      .subscribe((data) => {
        this.sharedInfo$.next(data);
      });
  }

  viewProject(): void {
    this.router.navigate(
      this.routePath.projectDetail(this.sharedInfo$.getValue().projectUuid),
    );
  }

  goToDashboard(): void {
    this.router.navigateByUrl(FEATURES.dashboard.path);
  }

  private viewProjectDelay(projectId: string, delay: number = 0): void {
    setTimeout(
      () => this.router.navigate(this.routePath.projectDetail(projectId)),
      delay,
    );
  }
}
