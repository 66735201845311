import { SCENARIO_NAME_DEFAULT_ID } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core/utils';

export const getVariation = (value: string): string =>
  value || SCENARIO_NAME_DEFAULT_ID;

export const mapVariationToFrontend = (model: unknown): string =>
  getVariation(model['scenarioVariation']);

export const mapVariationToBackend = (model: unknown): string => {
  if (model['scenarioVariation'] !== SCENARIO_NAME_DEFAULT_ID) {
    return model['scenarioVariation'];
  }
  return undefined;
};

export const buildEntityId = (
  scenarioId: string,
  variationId: string = undefined,
) =>
  variationId
    ? `${scenarioId}_${Utils.resolveToEmptyString(variationId)}`
    : scenarioId;

export const getScenarioIdFrom = (id: string): string =>
  id.indexOf('_') !== -1 ? id.substring(0, id.indexOf('_')) : id;

export const getScenarioVariationIdFrom = (id: string) =>
  id.indexOf('_') !== -1
    ? id.substring(id.indexOf('_') + 1, id.length)
    : undefined;

export const isVariation = (variationId: string) =>
  getVariation(variationId) !== SCENARIO_NAME_DEFAULT_ID;
