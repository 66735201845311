import {
  fadeInAnimation,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-shared/models';
import { convertToYearlyValues } from 'prosumer-shared/utils';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

interface IntervalData {
  readonly startYear: number;
  readonly endYear: number;
  readonly value: number;
}
interface SummaryData {
  [year: number]: { endYear: string; value: string };
}

@Component({
  selector: 'prosumer-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeInputComponent implements OnInit, AfterViewInit {
  @Input() start: number;
  @Input() end: number;
  @Input() yearlyValues: YearlyValues<string | null>;
  @Input() isViewOnly = false;
  @Input() minValue = undefined;
  @Input() notRequired = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() result = new EventEmitter<YearlyValues>();

  form = this.formBuilder.group({
    start: 0,
    end: 0,
    result: [{}],
    intervalList: this.formBuilder.array([]),
  });

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.controls.result.patchValue(this.yearlyValues);
    this.form.controls.start.patchValue(this.start);
    this.form.controls.end.patchValue(this.end);
    this.populateIntervalList(this.summarizeInput(this.yearlyValues));
    this.form.valueChanges.subscribe((form) => this.result.emit(form.result));
  }

  ngAfterViewInit(): void {
    // for every value change, patch the value to the result list from the interval list
    this.form.controls.intervalList.valueChanges.subscribe((result) => {
      const _result = result
        .map((data) =>
          convertToYearlyValues(data.value, data.startYear, data.endYear),
        )
        .reduce((prev, next) => ({ ...prev, ...next }), {});
      this.form.controls.result.patchValue(_result);
    });
  }

  /**
   * This function is triggered by adding a new interval from UI.
   * This function will recreate the interval list to add the new range.
   */
  addRange(): void {
    const index = this.intervalList.value.length - 1;
    const _interval = this.form.controls.intervalList.value;
    const _endYear = _interval[index].endYear;
    _interval[index].endYear = _interval[index].startYear;
    const _newYearlyRange = convertToYearlyValues(
      null,
      _interval[index].startYear + 1,
      _endYear,
    );
    const summary = this.summarizeInput(_newYearlyRange);
    const rangeOptions = this.createRangeOptions(summary);
    rangeOptions.map((option) => {
      _interval.push(this.createInterval(option).value);
    });
    this.updateResultAndIntervalList(_interval);
  }

  /**
   * This function deletes the interval based on index.
   * Once the interval is deleted, this function adjusts the intervals accordingly.
   *
   * @param index - index of the deleted interval
   */
  deleteRange(index: number): void {
    const _interval = this.form.controls.intervalList.value;
    const _startYear = _interval[index].startYear;
    const _endYear = _interval[index].endYear;
    _interval.splice(index, 1);
    if (index !== _interval.length) {
      _interval[index].startYear = _startYear;
    } else {
      _interval[index - 1].endYear = _endYear;
    }
    this.updateResultAndIntervalList(_interval);
  }

  /**
   * Triggered by change of selected startYear from UI
   *
   * @param index - the index of the range value being updated
   * @param newValue - the new value the user selected from UI
   */
  updateRange(index: number, newValue: number): void {
    const _interval = this.form.controls.intervalList.value;
    _interval[index - 1].endYear = newValue - 1;
    _interval[index].startYear = newValue;
    this.updateResultAndIntervalList(_interval);
  }

  /**
   * This function updates the result control and populates the
   * interval list based on the new interval list created for every CRUD action
   * of user.
   *
   * @param _interval - the modified interval list
   */
  updateResultAndIntervalList(_interval: IntervalData[]): void {
    const newResult = _interval
      .map((data) =>
        convertToYearlyValues(data.value, data.startYear, data.endYear),
      )
      .reduce((prev, next) => ({ ...prev, ...next }), {});
    this.form.controls.result.patchValue(newResult);
    this.populateIntervalList(this.mapIntervalsToInput(_interval));
  }

  /**
   * returns the intervalList form array
   */
  get intervalList(): UntypedFormArray {
    return this.form.get('intervalList') as UntypedFormArray;
  }

  /**
   * Returns the form field options for startYear
   *
   * @param control - the form control
   */
  getStartYearOptions(control: UntypedFormControl): Array<unknown> {
    return control.value.startYearOptions;
  }

  /**
   * Returns the form field options for yearEnd
   *
   * @param control - the form control
   */
  getEndYearOptions(control: UntypedFormControl): Array<unknown> {
    return control.value.endYearOptions;
  }

  /**
   * Returns the value of the control, 'startYear'
   *
   * @param control - the form control
   */
  getStartYearControl(control: UntypedFormControl): UntypedFormControl {
    return (control.get('startYear') as UntypedFormControl).value;
  }

  /**
   * Returns the value of the control, 'endYear'
   *
   * @param control - the form control
   */
  getEndYearControl(control: UntypedFormControl): UntypedFormControl {
    return (control.get('endYear') as UntypedFormControl).value;
  }

  /**
   * This function updates the value of the interval list, thus
   * having the range reflected in UI
   *
   * @param summary - the result from summarizeInput function
   */
  populateIntervalList(summary: SummaryData): void {
    this.clearFormArray();
    const intervalList = this.intervalList;

    const rangeOptions = this.createRangeOptions(summary);
    rangeOptions.map((option) => {
      intervalList.push(this.createInterval(option));
    });
  }

  /**
   * This function recreates the start year options based on the changes
   * from CRUD operations from user
   *
   * @param summary - result of the summarizedInput function
   */
  createRangeOptions(summary: SummaryData): unknown[] {
    const range = this.convertToNameValueFormat(
      this.createRange(this.start, this.end),
    );
    let prevYear;
    return Object.keys(summary)
      .filter((_) => !!_)
      .map((year) => {
        const rangeStart = prevYear ? prevYear : year;
        const { endYear, value } = summary[year];
        prevYear = parseInt(year, 10) + 1;
        return {
          disabled: false,
          startYearOptions: this.convertToNameValueFormat(
            this.createRange(parseInt(rangeStart, 10), parseInt(endYear, 10)),
          ),
          endYearOptions: range,
          startYear: parseInt(year, 10),
          endYear: parseInt(endYear, 10),
          value,
        };
      });
  }

  /**
   * This function creates the body of the intervalList.
   *
   * @param option - options created from createRangeOption function
   */
  createInterval(option: unknown): UntypedFormGroup {
    const form = this.formBuilder.group({
      startYear: 0,
      endYear: 0,
      startYearOptions: [[], Validators.required],
      endYearOptions: [[], Validators.required],
      disabled: [true, Validators.required],
      value: null,
    });

    if (option) {
      form.patchValue(option);
    }

    this.addRequiredValidator(form);
    this.addMinValidator(form);
    form.controls.value.updateValueAndValidity();
    // if (this.minValue !== undefined) {
    //   form.controls.value.setValidators([Validators.required]);
    //   form.controls.value.updateValueAndValidity();
    // }

    return form;
  }

  /**
   * This function creates the format form field option for the select component.
   *
   * @param arr - the result of the createRange function.
   */
  convertToNameValueFormat(
    arr: ReadonlyArray<number>,
  ): Array<FormFieldOption<number>> {
    return arr.map((item) => ({ name: item.toString(), value: item }));
  }

  /**
   * This function recreates the selct option range based on the current selected options.
   * The options should decrease as the user continues adding a new range.
   *
   * @param start - start year
   * @param end - end year
   */
  createRange(start: number, end: number): ReadonlyArray<number> {
    const size = end - start + 1; // make range inclusive
    return [...Array(size).keys()].map((i) => i + start);
  }

  /**
   * This function recreates a model for the select options to easily
   * manipulate the options
   *
   * @param result - the result list dictionary
   * the sample return value:
   *  (e.g. summary: 2010: {end: 2014, value: "3"} )
   */
  summarizeInput(result: YearlyValues): SummaryData {
    const original = {};
    if (result) {
      let previous: number;
      let prevYear: string;
      Object.keys(result)
        .sort()
        .map((year) => {
          const value = Number(result[year]);
          if (value === previous) {
            // it means it is in the same range so update end year
            original[prevYear] = { ...original[prevYear], endYear: year };
          } else {
            original[year] = {
              endYear: year,
              value: result[year],
            };
            prevYear = year;
          }
          previous = value;
        });
    }
    return original;
  }

  mapIntervalsToInput(intervals: IntervalData[]): SummaryData {
    return intervals.reduce(
      (result, { startYear, endYear, value }) => ({
        ...result,
        [startYear]: { endYear, value },
      }),
      {},
    );
  }

  /**
   * Clears the data of the form array.
   */
  clearFormArray(): void {
    Array(this.intervalList.length)
      .fill(0)
      .forEach((_) => this.removeItem(_));
  }

  /**
   * This function removes the item from the form array.
   *
   * @param index - index of the item to be removed from form array
   */
  removeItem(index = 0): void {
    this.intervalList.removeAt(index);
  }

  /**
   * This function prevents user to save or add an interval
   * when a field is empty.
   */
  isDisabled(): boolean {
    // const arr = toList(this.form.controls.result.value);
    // return contains(arr, null) || !arr.every((v) => Number(v) >= 0);
    return this.form.errors !== null;
  }

  /**
   * This function checks if the start year drop down list is exhausted
   * and that there are no options left.
   * If true, the add interval button is hidden.
   */
  isEnd(): boolean {
    const _interval = this.form.controls.intervalList.value;
    return _interval[_interval.length - 1]?.startYear === this.end;
  }

  private addRequiredValidator(interval: UntypedFormGroup): void {
    if (!this.notRequired) {
      interval.controls.value.addValidators(Validators.required);
    }
  }
  private addMinValidator(interval: UntypedFormGroup): void {
    if ([!this.notRequired, this.minValue !== undefined].every(Boolean)) {
      interval.controls.value.addValidators(Validators.min(this.minValue));
    }
  }
}
