<form
  class="matrix-container"
  [formGroup]="form"
  fxLayout="column"
  fxLayoutGap="10px"
>
  <div
    class="column-headers-placeholder"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="start center"
  >
    <div
      class="matrix-label"
      fxLayoutGap="10px"
      [fxFlex]="cellFlex"
      data-testid="efficiency-matrix-label"
    >
      Efficiency Matrix
      <mat-icon
        aria-hidden="false"
        class="eyes-input-tooltip-anchor"
        matSuffix
        tooltipPosition="left"
        [prosumerTooltip]="
          'wizard_converter.wizard_converter_efficiency_matrix'
        "
      >
        help
      </mat-icon>
    </div>

    <div
      *ngIf="(outputEnergyVectors$ | async)?.length > 0"
      class="column-header-placeholder"
    >
      <prosumer-ev-toggle-group
        formControlName="mainEnergyVectorOutput"
        [isViewOnly]="isViewOnly"
        [energyVectors]="outputEnergyVectors$ | async"
        [layout]="'row'"
        [evOptions]="outputEVOptions$ | async"
        (remove)="onRemoveOutputEnergyVector($event)"
        (select)="onSelectMainOutput($event)"
        (replace)="onReplaceEV($event, evType.output)"
        data-testid="ev-toggle-group-output"
      >
      </prosumer-ev-toggle-group>
    </div>

    <prosumer-menu-button
      *ngIf="!isViewOnly"
      icon="add"
      color="primary"
      label="Output Vector"
      [options]="outputEVOptions$ | async"
      [disabled]="allOutputEVOptionSelected$ | async"
      (selectOption)="onSelectOutputEVOption($event)"
      data-testid="output-vector"
    >
    </prosumer-menu-button>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
    <div
      class="row-header-placeholder"
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <div
        *ngIf="(inputEnergyVectors$ | async)?.length > 0"
        class="row-header-placeholder"
      >
        <prosumer-ev-toggle-group
          formControlName="mainEnergyVectorInput"
          [isViewOnly]="isViewOnly"
          [energyVectors]="inputEnergyVectors$ | async"
          [layout]="'column'"
          [evOptions]="inputEVOptions$ | async"
          (remove)="onRemoveInputEnergyVector($event)"
          (select)="onSelectMainInput($event)"
          (replace)="onReplaceEV($event, evType.input)"
          data-testid="ev-toggle-group-input"
        >
        </prosumer-ev-toggle-group>
      </div>

      <prosumer-menu-button
        *ngIf="!isViewOnly"
        icon="add"
        color="primary"
        label="Input Vector"
        [disabled]="allInputEVOptionSelected$ | async"
        [options]="inputEVOptions$ | async"
        (selectOption)="onSelectInputEVOption($event)"
        data-testid="input-vector"
      >
      </prosumer-menu-button>
    </div>

    <div formGroupName="efficiencies">
      <div
        *ngIf="insufficientEVSelected$ | async"
        class="efficiency-placeholder"
      ></div>

      <div
        formArrayName="energyVectorInArray"
        fxLayout="column"
        fxLayoutGap="5px"
        fxLayoutAlign="center center"
      >
        <div
          *ngFor="
            let row of energyVectorInArray?.controls;
            let rowIndex = index
          "
          [formGroupName]="rowIndex"
        >
          <div
            formArrayName="energyVectorOutArray"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="center center"
          >
            <div
              *ngFor="
                let column of row?.controls?.energyVectorOutArray?.controls;
                let columnIndex = index
              "
              [formGroupName]="columnIndex"
            >
              <prosumer-yearly-chart-input
                formControlName="value"
                [isViewOnly]="isViewOnly"
                [fxFlex]="cellFlex"
                [startYear]="startYear"
                [endYear]="endYear"
                [errorMessage]="'Scenario.messages.der.efficiency'"
                [contextHelpMsg]="
                  'wizard_converter.wizard_converter_efficiency'
                "
                [inputLabel]="
                  'Scenario.labels.technical.efficiency' | translate
                "
                data-testid="efficiency"
              >
              </prosumer-yearly-chart-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-error
    *ngIf="form?.errors?.required"
    class="eyes-error eyes-spacer-top-half"
    [innerHTML]="'Scenario.messages.der.mainEnergyVector.required' | translate"
  >
  </mat-error>
</form>
